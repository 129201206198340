import {
  addGroupLoginAction,
  deleteMerchantFromGroupAction,
  getGroupAction,
  getGroupLoginsAction,
  getGroupMerchantsAction,
} from "actions/groups";
import { Progress } from "antd";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination/index";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table/index";
import ability from "config/ability";
import { parseResponse } from "helpers/parseResponse";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import Content from "views/Content";
import { getGroupChangeHistoryAction } from "../../actions/reasons";
import { getAllRoles } from "../../actions/roles";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import AddLogin from "../AddLogin";
import EditLogin from "../EditLogin";
import RowAddMerchant from "../RowAddUser";
import Alert from "../UI/Alert";
import { TableAudit } from "../Audit/UsersTables/common/Table";
import { Link } from "react-router-dom";

class GroupDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLoginsPage: 1,
      currentMerchantsPage: 1,
      pageSize: 10,
      currentPageHistory: 1,
      pageSizeHistory: 10,
    };
  }

  columnsGroupMerchants = [
    { path: "merchant_guid", label: "Guid" },
    { path: "merchant_name", label: "Name" },
    ability.can("DELETE", "MERCHANTFROMPGROUP") && {
      key: "delete",
      align: "center",
      content: (merchant) => (
        <i
          className="far fa-trash-alt icon red"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDeleteMerchant(merchant.merchant_guid, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete from the group",
      colWidth: 10,
    },
  ];

  columnsGroupLogins = [
    { path: "username", label: "Username" },
    { path: "email", label: "Email" },
    { path: "role.name", label: "Role" },
    { path: "first_name", label: "First name" },
    { path: "last_name", label: "Last name" },
    { path: "company_name", label: "Company name" },
    { path: "company_address", label: "Company address" },
    {
      key: "enabled",
      content: (login) => (
        <i
          className={
            login.enabled ? "icon-success green icon" : "icon-failed red icon"
          }
        />
      ),
      label: "Status",
      align: "center",
      colWidth: 4,
    },
    ability.can("REPORT", "REASONSLOGIN") && {
      label: "History",
      key: "history",
      align: "center",
      content: (login) => (
        <Link className="link" to={`/about/audit/login/${login.guid}`}>
          <button type="button" className="btn btn-table">
            Show
          </button>
        </Link>
      ),
      colWidth: 5,
    },
    ability.can("EXECUTE", "GROUPLOGIN") &&
      ability.can("READ", "ROLES") && {
      key: "edit",
      align: "center",
      content: (groupLogin) => (
        <Modal
          dialogClassName="modal-creator"
          header="Edit group login"
          button={
            <i
              className="icon-edit icon gray"
              style={{ cursor: "pointer" }}
            />
          }
          content={EditLogin}
          contentProps={{
            id: this.props.match.params.id,
            data: groupLogin,
            type: "group",
            update: this.update,
          }}
        />
      ),
      label: "Edit",
      colWidth: 4,
    },
    ability.can("DELETE", "GROUPLOGIN") && {
      key: "delete",
      align: "center",
      content: (groupLogin) => (
        <i
          className="far fa-trash-alt red icon"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDelete(groupLogin.guid, groupLogin.role_guid, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
      colWidth: 4,
    },
  ];

  rows = [
    {
      label: "Group name",
      path: "name",
    },
    {
      label: "Group type",
      path: "type",
    },
    {
      label: "Partner name",
      path: "partner_name",
    },
    {
      label: "Monthly amount limit",
      path: "monthly_amount_limit",
    },
  ];

  componentDidMount = async () => {
    await this.props.getGroupLoginsAction(
      this.props.match.params.id,
      1,
      this.state.pageSize
    );
    await this.props.getGroupMerchantsAction(
      this.props.match.params.id,
      1,
      this.state.pageSize
    );
    await this.props.getGroupAction(this.props.match.params.id);
    ability.can("AUDIT", "GROUPS") &&
      (await this.props.getGroupChangeHistoryAction(
        this.props.match.params.id,
        1,
        this.state.pageSizeHistory
      ));
    const isAdmin = localStorage.getItem("isAdmin");
    this.setState({ isAdmin });
    ability.can("READ", "ROLES") &&
      (await this.props.getAllRoles({ type: "group" }));
  };

  handleLoginsPageChange = async (page) => {
    await this.props.getGroupLoginsAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      currentLoginsPage: page,
    });
  };

  handleMerchantsPageChange = async (page) => {
    await this.props.getGroupMerchantsAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      currentMerchantsPage: page,
    });
  };

  handleHistoryPageChange = async (page) => {
    await this.props.getGroupChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.pageSizeHistory
    );
    this.setState({
      currentPageHistory: page,
    });
  };

  handleDelete = async (loginid, roleid, reason) => {
    try {
      await this.props.addGroupLoginAction(this.props.match.params.id, {
        login_guid: loginid,
        role_guid: roleid,
        delete: true,
        reason,
      });
      await this.props.getGroupLoginsAction(this.props.match.params.id);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleDeleteMerchant = async (guid, reason) => {
    try {
      await this.props.deleteMerchantFromGroupAction(
        { guid, reason },
        this.state.currentMerchantsPage,
        this.state.pageSize,
        this.props.match.params.id
      );
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  update = async (id) => {
    await this.props.getGroupAction(id);
    await this.props.getGroupMerchantsAction(id);
    await this.props.getGroupLoginsAction(id);
  };

  getLoginsPagedData = () => {
    const { loginsCount } = this.props;
    let { pageSize } = this.state;
    const loginsPagesCount = loginsCount
      ? loginsCount / pageSize + (1 && !!(loginsCount % pageSize))
      : 0;
    return { loginsPagesCount };
  };

  getMerchantsPagedData = () => {
    const { merchantsCount } = this.props;
    let { pageSize } = this.state;
    const merchantsPagesCount = merchantsCount
      ? merchantsCount / pageSize + (1 && !!(merchantsCount % pageSize))
      : 0;
    return { merchantsPagesCount };
  };

  historygetPagedData = () => {
    const { groupHistoryCount } = this.props;
    let { pageSizeHistory } = this.state;
    const historyPagesCount =
      groupHistoryCount / pageSizeHistory +
      (1 && !!(groupHistoryCount % pageSizeHistory));
    return { historyPagesCount };
  };

  render() {
    const { currentLoginsPage, currentMerchantsPage, currentPageHistory } =
      this.state;
    const { group } = this.props;
    const { loginsPagesCount } = this.getLoginsPagedData();
    const { merchantsPagesCount } = this.getMerchantsPagedData();
    const { groupLogins, groupMerchants, groupHistory } = this.props;

    if (
      this.props.groupLoading ||
      this.props.groupLoginsLoading ||
      this.props.groupMerchantsLoading
    )
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5">
                {group ? group.group_name : null}
              </Card.Header>
              <Card.Body>
                <Card.Title>Info</Card.Title>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Group GUID:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    {this.props.match.params.id}
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Group Type:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <span>{group.group_type}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Partner:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <span>{group.partner_guid}</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Used amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {group.used_amount}
                      {" (" + group.used_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right"></Col>
                  <Col md={2} sm={2} xs={2} className="left">
                    <Progress percent={group.used_percent} size="small" />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Available amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {group.unused_amount}
                      {" (" + group.unused_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <span>
                      {group.created_at !== null
                        ? moment(group.created_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <span>{group.created_by_username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <span>
                      {group.updated_at !== null
                        ? moment(group.updated_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <span>{group.updated_by_username}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Body>
                <Card.Title>Merchants</Card.Title>
                <Table
                  columns={this.columnsGroupMerchants}
                  data={groupMerchants ? groupMerchants : []}
                  keyPath="merchant_guid"
                  disableSearch
                  disableSort
                  columnsComponent={createColComponentForTable(
                    this.columnsGroupMerchants
                  )}
                />
                <Pagination
                  pagesCount={merchantsPagesCount}
                  currentPage={currentMerchantsPage}
                  onPageChange={this.handleMerchantsPageChange}
                  pageSize={this.state.pageSize}
                  count={this.props.merchantsCount}
                />
                {ability.can("EXECUTE", "USERMERCHANT") && (
                  <RowAddMerchant
                    id={group.group_guid}
                    type="merchant"
                    update={this.update}
                  />
                )}
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Body>
                <Card.Title>Logins</Card.Title>

                <Table
                  columns={this.columnsGroupLogins}
                  data={groupLogins ? groupLogins : []}
                  keyPath="guid"
                  disableSearch
                  disableSort
                  columnsComponent={createColComponentForTable(
                    this.columnsGroupLogins
                  )}
                />
                <Pagination
                  pagesCount={loginsPagesCount}
                  currentPage={currentLoginsPage}
                  onPageChange={this.handleLoginsPageChange}
                  pageSize={this.state.pageSize}
                  count={this.props.loginsCount}
                />
                {ability.can("EXECUTE", "GROUPLOGIN") &&
                  ability.can("READ", "ROLES") && (
                  <Row>
                    <Col md={5} sm={5} xs={5} />
                    <Col md={2} sm={2} xs={2}>
                      <Modal
                        header="Add login"
                        dialogClassName="modal-creator"
                        content={AddLogin}
                        contentProps={{
                          type: "group",
                          id: this.props.match.params.id,
                          update: this.update,
                        }}
                        button={
                          <button type="button" className="btn btn-fill">
                              Add login
                          </button>
                        }
                      />
                    </Col>
                    <Col md={5} sm={5} xs={5} />
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            {ability.can("AUDIT", "GROUPS") && (
              <Card>
                <Card.Body>
                  <Card.Title>History</Card.Title>
                  {this.props.groupHistoryloading ? (
                    <Content>
                      <Spinner />
                    </Content>
                  ) : (
                    <Row>
                      <Col md={12} sm={12} xs={12}>
                        {/* <Table
                          columns={this.columnsHistory}
                          data={groupHistory ? groupHistory : []}
                          disableSearch
                          disableSort
                        />
                        <Pagination
                          pagesCount={historyPagesCount}
                          currentPage={currentPageHistory}
                          onPageChange={this.handleHistoryPageChange}
                          pageSize={this.state.pageSizeHistory}
                          count={this.props.groupHistoryCount}
                        /> */}

                        <TableAudit
                          data={groupHistory ? groupHistory : []}
                          count={this.props.groupHistoryCount}
                          handleChangePage={this.handleHistoryPageChange}
                          page={currentPageHistory}
                          pageSize={this.state.pageSizeHistory}
                          rows={this.rows}
                        />
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            )}
          </Row>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    group: state.groups.group,
    groupLogins: state.groups.groupLogins,
    loginsCount: state.groups.loginsCount,
    groupMerchants: state.groups.groupMerchants,
    merchantsCount: state.groups.merchantsCount,
    groupLoading: state.groups.groupLoading,
    rolesLoading: state.roles.rolesLoading,
    groupLoginsLoading: state.groups.groupLoginsLoading,
    groupMerchantsLoading: state.groups.groupMerchantsLoading,
    groupHistory: state.reasons.groupHistory,
    groupHistoryloading: state.reasons.loading,
    groupHistoryCount: state.reasons.count,
  };
};

export default connect(mapStateToProps, {
  getGroupAction,
  getGroupLoginsAction,
  addGroupLoginAction,
  getGroupMerchantsAction,
  deleteMerchantFromGroupAction,
  getAllRoles,
  getGroupChangeHistoryAction,
})(GroupDetail);

GroupDetail.propTypes = {
  addGroupLoginAction: PropTypes.func,
  deleteMerchantFromGroupAction: PropTypes.func,
  getAllRoles: PropTypes.func,
  getGroupAction: PropTypes.func,
  getGroupChangeHistoryAction: PropTypes.func,
  getGroupLoginsAction: PropTypes.func,
  getGroupMerchantsAction: PropTypes.func,
  group: PropTypes.object,
  groupHistory: PropTypes.array,
  groupHistoryCount: PropTypes.number,
  groupHistoryloading: PropTypes.bool,
  groupLoading: PropTypes.bool,
  groupLogins: PropTypes.array,
  groupLoginsLoading: PropTypes.bool,
  groupMerchants: PropTypes.array,
  groupMerchantsLoading: PropTypes.bool,
  loginsCount: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  merchantsCount: PropTypes.number,
};
