import { getChargebackServiceLogsAction } from "actions/logs";
import { reset, searchInPartnersLogs } from "actions/search";
import Modal from "components/UI/Modal";
import AbstractComponent from "factories/Main";
import { parseResponse } from "helpers/parseResponse.js";
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { resetPage, setNewTable } from "../../../../actions/store";
import { getСhargebackServiceLogs } from "../../../../services/paymentBackendAPI/logs/logs";
import Alert from "../../../UI/Alert";

const tryStringToJson = (str) => {
  try {
    let value = JSON.parse(str);
    return JSON.stringify(value, null, "\t");
  } catch (error) {
    const parsedError = parseResponse(error);
    Alert({ type: "error", message: parsedError.message });
  }
};

const Params = ({ data }) => {
  return (
    <Card>
      <Card.Body>
        <div className="param-container">
          <span style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            {tryStringToJson(data)}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

const columns = [
  {
    path: "time",
    label: "Time",
    content: (log) =>
      moment(log.createdAt).utcOffset(0).format("DD.MM.YYYY HH:mm:ss"),
    align: "center",
  },
  {
    path: "action",
    label: "Action",
    key: "action_log",
  },
  {
    path: "info",
    label: "Info",
    key: "Info",
    align: "center",
    content: (log) =>
      log.info && log.info !== "{}" ? (
        <Modal
          content={Params}
          contentProps={{ data: log.info }}
          buttonName="Show"
          button={
            <button type="button" className="btn btn-table">
              Show
            </button>
          }
        />
      ) : null,
    textContent: (log) => JSON.stringify(log.info),
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.logs.chargebackServiceLogs,
    count: state.logs.chargebackServiceLogsCount,
    table: state.store.table,
    loading: state.logs.loading,
    searchData: state.search.partnersLogsSearch,
    isSearch: state.search.isSearch,
    name: "Service logs chargeback",
    keyPath: "createdAt",
    columns,
    notAllSizePage: true,
    exportFunction: getСhargebackServiceLogs,
  };
};

export default connect(mapStateToProps, {
  get: getChargebackServiceLogsAction,
  search: searchInPartnersLogs,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
