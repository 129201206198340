import { backendManagement } from "../backendPlatform";

export const getMerchants = (args) => {
  return backendManagement.get("users/merchants", { params: { ...args } });
};

export const createMerchant = data => {
  return backendManagement.post("users/merchants", data);
};

export const getMerchant = guid => {
  return backendManagement.get(`users/merchants/${guid}`);
};
// with guid
export const updateMerchant = data => {
  return backendManagement.post("users/merchants", data);
};

export const deleteMerchant = ({ guid, reason }) => {
  return backendManagement.post("users/merchants", { merchant_guid: guid, delete: true, reason });
};

export const addMerchantToGroup = (merchant_guid, group_guid, reason) => {
  return backendManagement.post("users/merchants", { merchant_guid, group_guid, reason });
};

export const deleteMerchantFromGroup = ({ guid, reason }) => {
  return backendManagement.post("users/merchants", { merchant_guid: guid, group_guid: "", reason });
};

export const getMerchantLogins = (guid, page, items) => {
  return backendManagement.get(`/merchants/${guid}/logins`, { params: { items, page } });
};

export const addMerchantLogin = (guid, data) => {
  return backendManagement.post(`/merchants/${guid}/logins`, data);
};

export const getMerchantShops = (guid, page, items) => {
  return backendManagement.get(`/merchants/${guid}/shops`, { params: { items, page } });
};
