import * as types from "../constants/actionTypes";
import {
  calculateStatement,
  getStatementTransactions,
  getStatements,
  getStatement,
  changeStatementStatus,
  deleteStatement,
  getStatementCurrency,
  mergeStatements
} from "../services/paymentBackendAPI/management/statements";
import { loadTable } from "./store";

export const calculateStatementAction = (data) => {
  return async (dispatch) => {
    const response = await calculateStatement(data);
    dispatch({
      type: types.CALCULATE_STATEMENT,
      statement: response.data
    });
  };
};

export const getStatementTransactionsAction = (data) => {
  return async (dispatch) => {
    const response = await getStatementTransactions(data);
    dispatch({
      type: types.GET_STATEMENT_TRANSACTIONS,
      data: response.data
    });
  };
};

export const getAllStatements = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_STATEMENTS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getStatements(args);
      dispatch({
        type: types.GET_STATEMENTS_SUCCEED,
        data: response.data,
        currentPage: args && args.page,
        pageSize: args && args.items
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_STATEMENTS_FAILED
      });
    }
  };
};

export const getAllMerchantsStatements = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANT_STATEMENTS
    });
    try {
      const response = await getStatements(args);
      dispatch({
        type: types.GET_MERCHANT_STATEMENTS_SUCCEED,
        data: response.data,
        currentPage: args && args.page,
        pageSize: args && args.items
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_MERCHANT_STATEMENTS_FAILED
      });
    }
  };
};

export const getStatementAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_STATEMENT
    });
    try {
      const response = await getStatement(id);
      return dispatch({
        type: types.GET_STATEMENT_SUCCEED,
        statement: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_STATEMENT_FAILED
      });
    }
  };
};

export const changeStatementStatusAction = (data) => {
  return async (dispatch) => {
    const response = await changeStatementStatus(data);
    dispatch({
      type: types.CHANGE_STATEMENT_STATUS,
      data: response.data
    });
  };
};

export const deleteStatementAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteStatement(data);
    return dispatch(getAllStatements({ page, items, ...search }));
  };
};

export const getStatementCurrencyAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_STATEMENT_CURRENCY
    });
    try {
      const response = await getStatementCurrency(data);
      return dispatch({
        type: types.GET_STATEMENT_CURRENCY_SUCCEED,
        statement: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_STATEMENT_CURRENCY_FAILED
      });
    }
  };
};

export const mergeStatementsAction = (data) => {
  return async (dispatch) => {
    const response = await mergeStatements(data);
    dispatch({
      type: types.MERGE_STATEMENT,
      statement: response.data
    });
  };
};
