import React from "react";
import { connect } from "react-redux";
import AbstractComponent from "../../../factories/Main";
import ability from "config/ability";
import {
  getAllRateTemplatesAction,
  deleteRateTemplateAction,
} from "../../../actions/rates";
import { searchInRates, reset } from "../../../actions/search";
import { Link } from "react-router-dom";
import Modal from "components/UI/Modal";
import RateTemplateEditor from "./Editor";
import { getRateTemplates } from "../../../services/paymentBackendAPI/management/rates";
import { resetPage, setNewTable } from "../../../actions/store";

const columns = [
  {
    key: "name",
    path: "name",
    label: "Name",
    content: (rate) => (
      <Link className="link" to={`/about/rate/template/${rate.guid}`}>
        {rate.name}
      </Link>
    ),
    textContent: (rate) => rate.name,
  },
  ability.can("EXECUTE", "RATES") && {
    key: "edit",
    content: (rate) => (
      <Modal
        header="Edit rate"
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        content={RateTemplateEditor}
        contentProps={{ guid: rate.guid }}
      />
    ),
    label: "Edit",
    align: "center",
  },
  ability.can("DELETE", "RATES") && {
    key: "delete",
    label: "Delete",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.rates.rateTemplatesList,
    count: state.rates.count,
    table: state.store.table,
    loading: state.rates.rateTemplatesLoading,
    searchData: state.search.ratesSearch,
    isSearch: state.search.isSearch,
    keyPath: "guid",
    name: "rates",
    columns,
    exportFunction: getRateTemplates,
  };
};

export default connect(mapStateToProps, {
  get: getAllRateTemplatesAction,
  delete: deleteRateTemplateAction,
  search: searchInRates,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
