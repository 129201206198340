import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILED,
  GET_EMAIL_MESSAGE,
  CHECK_RECOVERY_TOKEN,
  SET_NEW_PASSWORD,
} from "../constants/actionTypes";

const initialState = {
  status: "",
  statusToken: "",
  statusPassword: "",
  loading: false,
  error: false
};

export default function auth(state = initialState, action) {
  switch(action.type) {
    case LOG_IN_SUCCESS:
      return {
        ...state,
        response: action.data,
        error: ""
      };
    case LOG_IN_FAILED: {
      const errorMessage = action.error.response ? action.error.response.data.description.message : "Server error";
      return {
        ...state,
        error: errorMessage,
        response: ""
      };
    }
    case GET_EMAIL_MESSAGE:
      return { ...state, status: action.status };
    case CHECK_RECOVERY_TOKEN:
      return { ...state, statusToken: action.statusToken };
    case SET_NEW_PASSWORD:
      return { ...state, statusPassword: action.status };
    default:
      return state;
  }
}