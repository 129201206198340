import React, { Component } from "react";
import { connect } from "react-redux";
import { logOut } from "actions/auth";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";

class LogOut extends Component {
  async componentDidMount() {
    await this.props.logOut();
  }

  render() {
    return (
      <div className="LoginPage">
        <div className="login-page">
          <Spinner />
        </div>
      </div>
    );
  }
}
export default connect(null, {
  logOut,
})(LogOut);

LogOut.propTypes = {
  logOut: PropTypes.func,
};
