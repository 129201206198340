import * as types from "../constants/actionTypes";
import {
  addPrivilegeToRole,
  createRole,
  getRoles,
  getRole,
  getPrivilegesFromRole,
  deleteRole,
  upsertPrivilegeToRole,
  updateRole
} from "../services/paymentBackendAPI/management/roles";
import { loadTable } from "./store";

export const getAllRoles = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ROLES
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getRoles(args);
      return dispatch({
        type: types.GET_ROLES_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_ROLES_FAILED
      });
    }
  };
};


export const getRoleAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ROLE
    });
    try {
      const response = await getRole(id);
      return dispatch({
        type: types.GET_ROLE_SUCCEED,
        role: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_ROLE_FAILED
      });
    }
  };
};

export const getRolePrivilegesAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ROLE_PRIVILEGES
    });
    try {
      const response = await getPrivilegesFromRole(id, page, items);
      return dispatch({
        type: types.GET_ROLE_PRIVILEGES_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_ROLE_PRIVILEGES_FAILED
      });
      throw (error);
    }
  };
};

export const addRoleAction = (data, page, items, search) => {
  return async (dispatch) => {
    const response = await createRole(data);
    dispatch({
      type: types.ADD_ROLE,
      role: response.data
    });
    dispatch(getAllRoles({ page, items, ...search }));
  };
};

export const updateRoleAction = (data) => {
  return async (dispatch) => {
    const response = await updateRole(data);
    dispatch({
      type: types.UPDATE_ROLE,
      role: response.data
    });
  };
};

export const upsertPrivilegeToRoleAction = (data) => {
  return async (dispatch) => {
    await upsertPrivilegeToRole(data.guid, data.privileges);
    dispatch({
      type: types.UPSERT_ROLE_PRIVILEGES,
      data: data
    });
  };
};

export const addRolePrivilegeAction = (id, data) => {
  return async (dispatch) => {
    const response = await addPrivilegeToRole(id, data);
    dispatch({
      type: types.ADD_ROLE_PRIVILEGE,
      rolePrivilege: response.data
    });
  };
};

export const deleteRoleAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteRole(data);
    return dispatch(getAllRoles({ page, items, ...search }));
  };
};

export const upsertChangedPrivilegeAction = (privilege) => {
  return (dispatch) => {
    dispatch({
      type: types.UPSERT_ROLE_CHANGED_PRIVILEGE,
      privilege: privilege
    });
  };
};

