import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import { Col, Row, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import Alert from "../UI/Alert";
import { editAdditionalFeeAction } from "actions/additionalFee";

class CurrencyEditor extends Component {
  state = {
    name: "",
    reason: "",
    enabled: false,

    oldData: {
      name: "",
      enabled: false,
    },
    errors: {},

    nameValidation: false,
  };

  schema = {
    name: Joi.string().required().min(0).max(100).label("Name"),
    enabled: Joi.boolean().label("Active"),
  };

  async componentDidMount() {
    this.setState({
      name: this.props.fee.fee_name,
      enabled: this.props.fee.enabled,

      oldData: {
        name: this.props.fee.fee_name,
        enabled: this.props.fee.enabled,
      },
      nameValidation: this.props.fee.fee_name ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = {
        name: this.state.name,
        enabled: this.state.enabled,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        enabled: this.state.enabled,
        name: this.state.name,
      };
      schema = this.schema;

      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState(
      {
        [input.name]: input.value,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleChangeActive = () => {
    this.setState({ enabled: !this.state.enabled }, () => this.showReason());
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });
          await this.props.editAdditionalFeeAction({
            guid: this.props.guid,
            fee_name: this.state.name,
            enabled: this.state.enabled,
            reason: this.state.needReason ? this.state.reason : undefined,
          });
          swal({
            title: "Currency is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;
    if (this.props.isLoading) return <Spinner />;
    else
      return (
        <React.Fragment>
          <Form autoComplete="off">
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Name* </Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="name"
                    type="string"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  {errors.name && (
                    <span className="validate-error">{errors.name}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <label>Active*</label>
              </Col>
              <Col md={1} sm={1} xs={1}>
                <input
                  type="checkbox"
                  id="enabled"
                  checked={this.state.enabled}
                  onChange={this.handleChangeActive}
                />
              </Col>
            </Row>

            {this.state.needReason && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Reason</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      placeholder="Enter reason"
                      name="reason"
                      value={this.state.reason}
                      onChange={this.handleChangeReason}
                    />
                    {errors.reason && (
                      <span className="validate-error">{errors.reason}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Form>
          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.nameValidation &&
                  this.state.codeValidation &&
                  this.state.numberValidation &&
                  this.state.rateValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </React.Fragment>
      );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  editAdditionalFeeAction,
  showModal,
})(CurrencyEditor);

CurrencyEditor.propTypes = {
  editAdditionalFeeAction: PropTypes.func,
  getCurrencyAction: PropTypes.func,
  guid: PropTypes.string,
  isLoading: PropTypes.bool,
  showModal: PropTypes.func,
};
