import React from "react";
import Content from "../Content";
import UserAccountMain from "components/UserAccount/Main";

const UserAccount = () => {
  return (
    <Content>
      <UserAccountMain />
    </Content>
  );
};

export default UserAccount;
