import { getTransactionRatesAction } from "actions/transactions";
import Spinner from "components/UI/Spinner";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Table } from "react-bootstrap";
import { connect } from "react-redux";

class RatesDetail extends Component {
  async componentDidMount() {
    await this.props.getTransactionRatesAction(this.props.guid);
  }

  render() {
    const rates = this.props.rates;
    if (this.props.loading) return <Spinner />;
    else
      return (
        <>
          <Card>
            <Card.Header as="h5">Transaction: {this.props.guid}</Card.Header>
            <Card.Body>
              <Card.Title>Rates</Card.Title>

              {Object.keys(rates).length !== 0 ? (
                <Table responsive className="detailInfo">
                  <tbody>
                    <tr>
                      <th>Base amount:</th>
                      <td>{rates.base_amount}</td>
                    </tr>
                    <tr>
                      <th>To processor (%):</th>
                      <td>{rates.to_processor_pct}</td>
                    </tr>
                    <tr>
                      <th>To processor (fixed):</th>
                      <td>{rates.to_processor_fixed}</td>
                    </tr>
                    <tr>
                      <th>To bank (%):</th>
                      <td>{rates.to_bank_pct}</td>
                    </tr>
                    <tr>
                      <th>To bank (fixed):</th>
                      <td>{rates.to_bank_fixed}</td>
                    </tr>
                    <tr>
                      <th>To client:</th>
                      <td>{rates.to_client}</td>
                    </tr>
                    <tr>
                      <th>Hold:</th>
                      <td>{rates.hold}</td>
                    </tr>
                    <tr>
                      <th>Hold flag:</th>
                      <td>
                        <i
                          className={
                            rates.hold_flag
                              ? "icon-success green"
                              : "icon-failed red"
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Created at:</th>
                      <td>{rates.created_at}</td>
                    </tr>
                    <tr>
                      <th>Updated at:</th>
                      <td>{rates.updated_at}</td>
                    </tr>
                    <tr>
                      <th>Updated by:</th>
                      <td>{rates.updated_by_username}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <div>
                  <b>Sorry, this transaction doesn`t have rates</b>
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    rates: state.transactions.transactionRates,
    loading: state.transactions.transactionLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionRatesAction,
})(RatesDetail);

RatesDetail.propTypes = {
  getTransactionRatesAction: PropTypes.func,
  guid: PropTypes.string,
  loading: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),

  rates: PropTypes.shape({
    base_amount: PropTypes.string,
    to_processor_pct: PropTypes.string,
    to_processor_fixed: PropTypes.string,
    to_bank_pct: PropTypes.string,
    to_bank_fixed: PropTypes.string,
    to_client: PropTypes.string,
    hold: PropTypes.string,
    hold_flag: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    updated_by_username: PropTypes.string,
  }),
};
