import { getAllCurrencies } from "actions/currencies";
import {
  deleteGatewayCurrencyAction,
  deleteGatewayPropsAction,
  getGatewayAction,
  getGatewayPropsAction,
} from "actions/gateways";
import { getTerminalsByGatewayAction } from "actions/shops";
import Modal from "components/UI/Modal";
import Spinner from "components/UI/Spinner/index";
import ability from "config/ability";
import { parseResponse } from "helpers/parseResponse";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Content from "views/Content";
import { getGatewayChangeHistoryAction } from "../../actions/reasons";
import Alert from "../UI/Alert";
import Pagination from "../UI/Pagination/index";
import Table from "../UI/Table/index";
import PropertyCreator from "./Property/Creator";
import RowAddCurrency from "./RowAddCurrency";

export class GatewayDetail extends Component {
  state = {
    gatewayInfo: {},
    terminals: [],
    count: "",
    currentPage: 1,
    pageSize: 10,
    currentPageHistory: 1,
    pageSizeHistory: 10,
    loading: false,
  };

  columnsTerminals = [
    ability.can("READ", "SHOPTERMINALDETAIL")
      ? {
          path: "guid",
          label: "Guid",
          content: (terminal) => (
            <Link
              className="link"
              to={`/about/${terminal.shop_guid}/terminal/${terminal.guid}`}
            >
              {terminal.guid}
            </Link>
          ),
        }
      : { path: "guid", label: "Guid" },
    { path: "shop_name", label: "Shop" },
    { path: "currency_code", label: "Currency", align: "center" },
    {
      key: "enabled",
      content: (terminal) => (
        <i
          className={
            terminal.enabled
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Enabled",
      align: "center",
    },
  ];

  columnsProperties = [
    { path: "number", label: "№" },
    { path: "label", label: "Name" },
    {
      path: "bytea_flag",
      label: "Encrypt",
      content: (property) => (property.bytea_flag ? "true" : "false"),
      align: "center",
    },
    ability.can("DELETE", "GATEWAYS") && {
      key: "delete",
      content: (property) => (
        <i
          className="far fa-trash-alt red icon"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text:
                "Once deleted, you will not be able to recover this. Type reason below .",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDelete(property.name, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
      align: "center",
    },
  ];

  columnsCurrencies = [
    { path: "guid", label: "Guid" },
    { path: "code", label: "Code" },
    ability.can("DELETE", "GATEWAYCURENCIES") && {
      key: "delete",
      content: (currency) => (
        <i
          className="far fa-trash-alt red icon"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text:
                "Once deleted, you will not be able to recover this. Type reason below .",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDeleteCurrency(currency.guid, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete from gateway",
      align: "center",
    },
  ];

  columnsHistory = [
    { path: "name", label: "Name", key: "name" },
    { path: "description", label: "Description", key: "description" },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const gatewayGuid = this.props.match.params.id;
    await this.props.getGatewayAction(gatewayGuid);
    await this.props.getTerminalsByGatewayAction(
      gatewayGuid,
      1,
      this.state.pageSize
    );
    await this.props.getGatewayPropsAction(gatewayGuid);
    await this.props.getAllCurrencies();
    ability.can("AUDIT", "GATEWAYS") &&
      (await this.props.getGatewayChangeHistoryAction(
        gatewayGuid,
        1,
        this.state.pageSizeHistory
      ));
    this.setState({
      terminals: this.props.terminals,
      loading: false,
    });
  }

  handlePageChange = async (page) => {
    await this.props.getTerminalsByGatewayAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      terminals: this.props.terminals,
      currentPage: page,
    });
  };

  handleHistoryPageChange = async (page) => {
    await this.props.getGatewayChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.pageSizeHistory
    );
    this.setState({
      currentPageHistory: page,
    });
  };

  handleDelete = async (propName, reason) => {
    try {
      const data = {
        name: propName,
        delete: "true",
        reason,
      };
      await this.props.deleteGatewayPropsAction(
        this.props.match.params.id,
        data
      );
      swal({
        title: "Property successfully deleted",
        icon: "success",
        button: false,
        timer: 2000,
      });
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleDeleteCurrency = async (currencyGuid, reason) => {
    try {
      await this.props.deleteGatewayCurrencyAction(
        this.props.match.params.id,
        currencyGuid,
        reason
      );
      swal({
        title: "Currency successfully deleted",
        icon: "success",
        button: false,
        timer: 2000,
      });
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  getPagedData = () => {
    const { terminalsCount } = this.props;
    let { pageSize } = this.state;
    const pagesCount = terminalsCount
      ? terminalsCount / pageSize + (1 && !!(terminalsCount % pageSize))
      : 0;
    return { pagesCount };
  };

  historygetPagedData = () => {
    const { gatewayHistoryCount } = this.props;
    let { pageSizeHistory } = this.state;
    const historyPagesCount =
      gatewayHistoryCount / pageSizeHistory +
      (1 && !!(gatewayHistoryCount % pageSizeHistory));
    return { historyPagesCount };
  };

  render() {
    if (
      this.props.gatewayPropsLoading ||
      this.props.gatewayLoading ||
      this.props.gatewayTerminalsLoading ||
      this.state.loading
    )
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else {
      const gateway = this.props.gatewayInfo;
      const { currentPage } = this.state;
      const { pagesCount } = this.getPagedData();
      const { terminals } = this.state;
      const { gatewayHistory } = this.props;
      const { currentPageHistory } = this.state;
      const { historyPagesCount } = this.historygetPagedData();
      return (
        <Content style={{ overflow: "auto" }}>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5"> {gateway.name}</Card.Header>
              <Card.Body>
                <Card.Title>Detailed info</Card.Title>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>GUID: </label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{gateway.guid}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Description: </label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{gateway.description}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Created at: </label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>
                      {gateway.created_at !== null
                        ? moment(gateway.created_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Created by: </label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{gateway.created_by_username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Updated at: </label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>
                      {gateway.updated_at !== null
                        ? moment(gateway.updated_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Updated by: </label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{gateway.updated_by_username}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Connected terminals</Card.Title>
                <Row>
                  <Col md={12}>
                    <div className="table">
                      <Table
                        columns={this.columnsTerminals}
                        data={terminals}
                        keyPath="guid"
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={pagesCount}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                        pageSize={this.state.pageSize}
                        count={this.props.terminalsCount}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

          </Row>

          <Row>
            <Card>
              <Card.Body>
                <Card.Title>Gateway properties</Card.Title>
                <div className="table">
                  <Table
                    columns={this.columnsProperties}
                    data={this.props.gatewayProps}
                    keyPath="number"
                    disableSearch
                    disableSort
                  />

                </div>
                {ability.can("EXECUTE", "GATEWAYS") && (
                  <div>
                    <Modal
                      header="Add gateway property"
                      action="Save"
                      content={
                        PropertyCreator
                      }
                      contentProps={{
                        guid: gateway.guid,
                        type: "gateway",
                      }}
                      button={
                        <Button className="btn btn-fill">Add property</Button>
                      }
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Currencies</Card.Title>
                <div className="table">
                  <Table
                    columns={this.columnsCurrencies}
                    data={
                      this.props.gatewayInfo.currencies !== null
                        ? this.props.gatewayInfo.currencies
                        : null
                    }
                    keyPath="guid"
                    disableSearch
                    disableSort
                  />
                </div>
                {ability.can("EXECUTE", "GATEWAYCURENCIES") && (
                  <RowAddCurrency
                    gatewayCurrencies={this.props.gatewayInfo.currencies}
                    currencies={this.props.currencies}
                    gatewayGuid={this.props.match.params.id}
                  />
                )}

              </Card.Body>
            </Card>
          </Row>




          {
            ability.can("AUDIT", "GATEWAYS") && (
              <React.Fragment>
                {this.props.gatewayHistoryloading || this.state.loading ? (
                  <Content>
                    <Spinner />
                  </Content>
                ) : (
                  <Card>
                    <Card.Body>
                      <Card.Title>History</Card.Title>
                      <Table
                        columns={this.columnsHistory}
                        data={gatewayHistory ? gatewayHistory : []}
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={historyPagesCount}
                        currentPage={currentPageHistory}
                        onPageChange={this.handleHistoryPageChange}
                        pageSize={this.state.pageSizeHistory}
                        count={this.props.gatewayHistoryCount}
                      />

                    </Card.Body>
                  </Card>

                )}
              </React.Fragment>
            )
          }
        </Content >
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    gatewayInfo: state.gateways.gateway,
    terminals: state.shops.gatewayTerminals,
    terminalsCount: state.shops.terminalsCount,
    gatewayProps: state.gateways.gatewayProps,
    gatewayPropsLoading: state.gateways.propsLoading,
    gatewayPropsCount: state.gateways.gatewayPropsCount,
    currencies: state.currencies.currenciesList,
    gatewayLoading: state.gateways.gatewayLoading,
    gatewayTerminalsLoading: state.shops.gatewayTerminalsLoading,
    gatewayHistory: state.reasons.gatewayHistory,
    gatewayHistoryloading: state.reasons.loading,
    gatewayHistoryCount: state.reasons.count,
  };
};

export default connect(mapStateToProps, {
  getTerminalsByGatewayAction,
  getGatewayPropsAction,
  getGatewayAction,
  getAllCurrencies,
  deleteGatewayPropsAction,
  deleteGatewayCurrencyAction,
  getGatewayChangeHistoryAction,
})(GatewayDetail);

GatewayDetail.propTypes = {
  currencies: PropTypes.array,
  deleteGatewayCurrencyAction: PropTypes.func,
  deleteGatewayPropsAction: PropTypes.func,
  gatewayHistory: PropTypes.array,
  gatewayHistoryCount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  gatewayHistoryloading: PropTypes.bool,
  gatewayInfo: PropTypes.object,
  gatewayLoading: PropTypes.bool,
  gatewayProps: PropTypes.array,
  gatewayPropsLoading: PropTypes.bool,
  gatewayTerminalsLoading: PropTypes.bool,
  getAllCurrencies: PropTypes.func,
  getGatewayAction: PropTypes.func,
  getGatewayChangeHistoryAction: PropTypes.func,
  getGatewayPropsAction: PropTypes.func,
  getTerminalsByGatewayAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  terminals: PropTypes.array,
  terminalsCount: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};
