import * as types from "../constants/actionTypes";

const initialState = {
  group_guid: "",
  groupLogins: [],
  groupsList: [],
  groupMerchants: [],
  topGroups: [],
  count: 0,
  group: {
    guid: "",
    name: "",
    type: "",
    partner_guid: "",
    partner_name: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: "",
    merchants: []
  },
  groupLoading: true,
  groupsLoading: true,
  error: false,
  groupMerchantsLoading: true,
  groupMerchantsLoadingError: false,
  groupLoginsLoading: true
};

export default function groups(state = initialState, action) {
  let newGroupsList = [];
  switch (action.type) {
    case types.ADD_GROUP:
      return { ...state, group_guid: action.group.guid };
    case types.ADD_GROUP_LOGIN:
      return { ...state, groupLogins: [ action.groupLogin ] };
    case types.GET_GROUPS:
      return {
        ...state,
        groupsLoading: true,
        error: false
      };
    case types.GET_GROUPS_SUCCEED:
      return {
        ...state,
        groupsList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        groupsLoading: false,
        error: false
      };
    case types.GET_GROUPS_FAILED:
      return {
        ...state,
        groupsLoading: false,
        error: true
      };
    case types.GET_GROUP:
      return {
        ...state,
        groupLoading: true,
        error: false
      };
    case types.GET_GROUP_SUCCEED:
      return {
        ...state,
        group: action.group,
        groupLoading: false,
        error: false
      };
    case types.GET_GROUP_FAILED:
      return {
        ...state,
        groupLoading: false,
        error: true
      };
    case types.DELETE_GROUP:
      newGroupsList = state.groupsList ? state.groupsList.filter(group => group.group_guid !== action.group.group_guid) : [];
      return { ...state, groupsList: newGroupsList };
    case types.EDIT_GROUP: {
      newGroupsList = state.groupsList ? state.groupsList : [];
      newGroupsList = newGroupsList.map(group => {
        if (group.group_guid === action.group.group_guid)
          return { ...group, ...action.group };
        else
          return group;
      });
      let changedMerchants = [];
      return { ...state, groupsList: newGroupsList, changedMerchants };
    }
    case types.GET_GROUP_LOGINS:
      return {
        ...state,
        groupLoginsLoading: true,
      };
    case types.GET_GROUP_LOGINS_SUCCEED:
      return {
        ...state,
        groupLogins: action.data.data,
        loginsCount: action.data.count,
        groupLoginsLoading: false
      };
    case types.GET_GROUP_LOGINS_FAILED:
      return {
        ...state,
        groupLoginsLoading: false
      };
    case types.GET_GROUP_MERCHANTS:
      return {
        ...state,
        groupMerchantsLoading: true,
        groupMerchantsLoadingError: false
      };
    case types.GET_GROUP_MERCHANTS_SUCCEED:
      return {
        ...state,
        groupMerchants: action.data.data,
        merchantsCount: action.data.count,
        groupMerchantsLoading: false,
        groupMerchantsLoadingError: false
      };
    case types.GET_GROUP_MERCHANTS_FAILED:
      return {
        ...state,
        groupMerchantsLoading: false,
        groupMerchantsLoadingError: true
      };
    case types.DELETE_MERCHANT_FROM_GROUP: {
      let newMerchantsList = state.groupMerchants ? state.groupMerchants : [];
      newMerchantsList = newMerchantsList.filter(merchant => merchant.guid !== action.merchant.guid);
      return { ...state, groupMerchants: newMerchantsList };
    }
    case types.UPSERT_GROUP_CHANGED_MERCHANT: {
      let groupMerchants = state.groupMerchants;
      let changedMerchants = state.changedMerchants;
      if (typeof (changedMerchants) === "undefined")
        changedMerchants = [];
      if (action.merchant.delete) {
        groupMerchants = groupMerchants.filter(merchant => merchant.guid !== action.merchant.guid);
        const newChangedMerchants = changedMerchants.filter(merchant => merchant.guid !== action.merchant.guid);
        if (newChangedMerchants.length === changedMerchants.length)
          changedMerchants = [ ...changedMerchants, action.merchant ];
        else
          changedMerchants = newChangedMerchants;
      }
      else {
        groupMerchants = groupMerchants.filter(merchant => !(merchant.guid === action.merchant.guid && merchant.delete));
        changedMerchants = changedMerchants.filter(merchant => !(merchant.guid === action.merchant.guid && merchant.delete));
        groupMerchants = [ ...groupMerchants, action.merchant ];
        changedMerchants = [ ...changedMerchants, action.merchant ];
      }
      return { ...state, groupMerchants, changedMerchants };
    }
    case types.GET_AMOUNT_GROUPS_FOR_PARTNER:
      return { ...state, count: action.count };
    case types.GET_TOP_GROUPS:
      return {
        ...state,
        topGroupsLoading: true
      };
    case types.GET_TOP_GROUPS_SUCCEED:
      return {
        ...state,
        topGroups: action.data,
        topGroupsLoading: false
      };
    case types.GET_TOP_GROUPS_FAILED:
      return {
        ...state,
        topGroupsLoading: false
      };
    case types.RESET_GROUP_MANAGEMENT_DATA:
      return {
        ...state,
        groupMerchants: [],
        changedMerchants: []
      };
    default:
      return state;
  }
}
