import React from "react";

export const createColComponentForTable = (columns, maxWidth = 100) => {
  let width = 0;
  let withoutWidth = 0;

  columns.forEach((element) => {
    if (element)
      if (element.colWidth) width += element.colWidth;
      else ++withoutWidth;
  });
  let colWidth = (maxWidth - width) / withoutWidth;

  let columnsComponent = columns.map((element) => {
    if (element)
      if (element.colWidth) return <col width={element.colWidth + "%"} />;
      else return <col width={colWidth + "%"} />;
    return null;
  });

  return columnsComponent;
};
