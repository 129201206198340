import React from "react";

export const Footer = () => {
  return (
    <div>
      <footer className="footer" style={{ textAlign: "right" }}>
        <p className="copyright">
          {new Date().getFullYear()} &copy; Ideal7 Limited
          {/* <a href="URL" >Ideal7 Limited</a> */}
        </p>
      </footer>
    </div>
  );
};
