// import Can from "components/Can";
import CurrencyCreator from "components/Currency/Creator";
import CurrencyMain from "components/Currency/Main";
import Modal from "components/UI/Modal";
import React from "react";
import Content from "../Content";
import ability from "config/ability";

const Currency = () => {
  return (
    <Content>
      <CurrencyMain
        modalComponent={
          // <Can do="EXECUTE" on="CURRENCIES">
          <Modal
            allowed={ability.can("EXECUTE", "CURRENCIES")}
            header="Create currency"
            content={CurrencyCreator}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create currency
              </button>
            }
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default Currency;
