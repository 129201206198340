import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { getAllMerchants } from "actions/merchants";
import { getAllCurrencies, getCurrencyRatesAction } from "actions/currencies";
import {
  mergeStatementsAction,
  getAllMerchantsStatements,
  getStatementCurrencyAction,
} from "actions/statements";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Content from "views/Content";
import ReactLoading from "react-loading";
import { parseResponse } from "helpers/parseResponse";
import swal from "sweetalert";
import Button from "components/UI/CustomButton/CustomButton";
import ability from "config/ability";
import TableRates from "./Tables/TableRates";
import TablePayableSummary from "./Tables/TablePayableSummary";
import Summary from "./Summary";
import Export from "./Export";
import MergeForm from "./MergeForm";
import Alert from "../../UI/Alert";
class MergedStatementDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statements: [],
      currencies: [],
      selectedStatements: [],
      statementsValidation: false,
      statementsLoading: false,

      editStatement: false,

      merchants: [],
      merchantGuid: "",
      merchantName: "",

      setCustomName: false,
      name: "",
      setCurrencies: false,
      selectedCurrencies: [],
      selected_statement_currency: {},
      selectedCurrenciesRates: [],
      reason: "",
      bank_wire_fee: "0",
      additional_fees: [],

      summary: [],

      errors: {},

      statementLoaded: false,
      merchantsLoading: false,
      exportLoading: false,

      merchantValidation: false,
      bank_wire_feeValidation: false,
    };
  }

  componentDidMount = async () => {
    try {
      if (this.props.statement) {
        let selected_statement_currency = {};
        let currencies = [];
        let currenciesRates = [];
        if (ability.can("EXECUTE", "STATEMENTS")) {
          await this.props.getAllCurrencies();
          currencies = this.props.currencies;
          currencies.forEach((currency) => {
            currency.name = currency.code;
          });
          selected_statement_currency = currencies.filter(
            (currency) =>
              currency.code === this.props.statement.statement_currency_code
          )[0];
          currenciesRates = currencies.map((currency) => {
            return {
              guid: currency.guid,
              bank_exchange_rate: currency.rate_to_eur,
              processor_exchange_rate: currency.isFlat ? +currency.rate_to_eur + +currency.exchange_markup_value : currency.rate_to_eur * (currency.exchange_markup_value / 100 + 1),
              isFlat: currency.isFlat,
              exchange_markup_value: currency.exchange_markup_value,
              code: currency.code,
            };
          });
          this.props.statement.currency_rates.forEach(statementRateCurrency => {
            let changedRateIndex = currenciesRates.indexOf(
              currenciesRates.find((currency) => statementRateCurrency.code === currency.code)
            );
            currenciesRates[changedRateIndex] = {
              ...currenciesRates[changedRateIndex],
              bank_exchange_rate: statementRateCurrency.bank_exchange_rate,
              processor_exchange_rate: statementRateCurrency.processor_exchange_rate,
            };
          });

        }

        let keys = Object.keys(this.props.statement.entityData);
        keys = keys
          .map((key) => (key.length === 3 || key === "Summary" ? key : null))
          .filter((key) => key !== null).map(key => ({ name: key }));

        this.setState({

          keys,
          currencies,
          selected_statement_currency,
          currenciesRates,
          merchantsLoading: false,
        });

        await this.props.getAllMerchantsStatements({
          merchant_guid: this.props.statement.merchant_guid,
          status: "Payment Due",
          merge_statement_flag: false,
        });
        let statements = this.props.statements.map((statement) => {
          return {
            name: statement.name,
            guid: statement.guid,
            label: statement.name,
            value: statement.guid,
          };
        });
        let selectedStatements = this.props.statement.statementData;
        selectedStatements = selectedStatements.map((statement) => {
          return {
            name: statement.name,
            guid: statement.guid,
            label: statement.name,
            value: statement.guid,
          };
        });

        selectedStatements.splice(
          selectedStatements.indexOf(
            selectedStatements.filter(
              (statement) => statement.guid === undefined
            )
          ),
          1
        );

        this.setState({
          summary: this.props.statement,
          statementLoaded: true,
          statementData: this.props.statement.statementData,
          additional_fees: this.props.statement.additional_fees
            ? this.props.statement.additional_fees.map((item) => ({
              ...item,
              currency: currencies.filter(
                (currency) => currency.code === item.currency
              )[0],
            }))
            : [],
          selectedStatements,
          selectedCurrenciesRates: currenciesRates,
          statements,
          statementsLoading: false,
        });
      }
    } catch (error) {
      this.setState({ isLoading: false, transactionsLoading: false });
      const parsedError = parseResponse(error);
      swal({
        title: parsedError.error,
        text: parsedError.message,
        icon: "error",
      });
    }
  };

  handleClickEdit = () => {
    this.setState({
      editStatement: !this.state.editStatement,
    });
  };

  // validateCurrencyRate = (name, value) => {
  //   const errors = { ...this.state.errors };
  //   const obj = { currency: value };
  //   const schema = { currency: Joi.number().min(0.1).max(10000).not().empty() };
  //   const { error } = Joi.validate(obj, schema);
  //   const errorMessage = error ? error.details[0].message : null;
  //   if (errorMessage) errors[name] = errorMessage;
  //   else delete errors[name];
  //   this.setState({
  //     errors,
  //     [name + "Validation"]: errorMessage ? false : true,
  //   });
  // };

  save = async (data) => {
    try {
      this.setState({
        isLoading: true,
        statementLoaded: false,
      });
      await this.props.mergeStatementsAction(data);
      swal({
        title: "Statement is update",
        icon: "success",
        text: this.props.warning ? this.props.warning : "",
        button: false,
        timer: 2000,
      });
      this.setState({
        isLoading: false,
        statementLoaded: true,
      });

    } catch (error) {
      this.setState({ isLoading: false, statementLoaded: true, });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    let { summary } = this.state;
    if (!this.state.statementLoaded || this.props.statementLoading)
      return (
        <Content>
          <Spinner />
        </Content>
      );
    else
      return (
        this.state.statementLoaded && (
          <Content>
            <Card>
              <Card.Header as="h5">{summary.name}</Card.Header>
              <Card.Body>

                {ability.can("EXECUTE", "STATEMENTS") && (
                  <Button
                    className="btn btn-fill"
                    onClick={this.handleClickEdit}
                  >
                    {this.state.editStatement ? "Close Editing" : "Edit"}
                  </Button>
                )}

                {this.state.editStatement && (
                  <div>
                    <MergeForm
                      statement={this.props.statement}
                      guid={this.props.guid}
                      // calculate={this.calculate}
                      save={this.save}
                      isLoading={this.state.isLoading}
                    />
                  </div>
                )}

                <Export
                  guid={this.props.guid}
                  name={summary.name}
                />

              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                {this.state.isLoading ? (

                  <div>
                    <ReactLoading
                      type="cylon"
                      color="grey"
                      height={50}
                      width={50}
                    />
                  </div>

                ) : (
                  <>
                    <Card.Title>Info</Card.Title>
                    <Summary
                      guid={this.props.guid}
                      summary={summary}
                      selectedCurrenciesRates={this.state.selectedCurrenciesRates}
                      statement_currency_code={this.props.statement.statement_currency_code}
                    />

                    <TablePayableSummary
                      statementData={this.props.statement.statementData}
                    />

                    <TableRates
                      additional_fees_names={this.props.statement.additional_fees_names}
                      currencies={this.state.keys}
                      statement_currency_code={this.props.statement.statement_currency_code}
                      entityData={this.props.statement.entityData}
                    />

                  </>
                )}
              </Card.Body>
            </Card >
          </Content >
        )
      );
  }
}

const mapStateToProps = (state) => {
  return {
    statement: state.statements.statement,
    count: state.statements.count,
    statementLoading: state.statements.statementLoading,

    merchants: state.merchants.merchantsList,
    currencies: state.currencies.currenciesList,
    currencyRates: state.currencies.currencyRates,

    statements: state.statements.merchantStatements,
    statementCurrencies: state.statements.statementCurrencies,
  };
};

export default connect(mapStateToProps, {
  getAllMerchants,
  getAllCurrencies,
  getCurrencyRatesAction,
  getAllMerchantsStatements,
  mergeStatementsAction,
  getStatementCurrencyAction,
})(MergedStatementDetail);

MergedStatementDetail.propTypes = {
  currencies: PropTypes.array,
  currencyRates: PropTypes.object,
  getAllCurrencies: PropTypes.func,
  getAllMerchants: PropTypes.func,
  getAllMerchantsStatements: PropTypes.func,
  getCurrencyRatesAction: PropTypes.func,
  getStatementCurrencyAction: PropTypes.func,
  guid: PropTypes.string,
  merchants: PropTypes.array,
  mergeStatementsAction: PropTypes.func,
  statement: PropTypes.object,
  statementCurrencies: PropTypes.object,
  statementLoading: PropTypes.bool,
  statements: PropTypes.array,
  warning: PropTypes.string,
};
