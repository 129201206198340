import * as types from "../constants/actionTypes";
import { getAdmins, createAdmin, getAdmin, deleteAdmin, getAdminRoles, addAdminRole, deleteAdminRole } from "../services/paymentBackendAPI/management/admins";
import { loadTable } from "./store";

export const getAllAdmins = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ADMINS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAdmins(args);
      return dispatch({
        type: types.GET_ADMINS_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_ADMINS_FAILED
      });
    }
  };
};

export const addAdminAction = (data, page, items, search) => {
  return async (dispatch) => {
    const response = await createAdmin(data);
    dispatch({
      type: types.ADD_ADMIN,
      admin: response.data
    });
    dispatch(getAllAdmins({ page, items, ...search }));
  };
};

export const getAdminAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ADMIN
    });
    try {
      const response = await getAdmin(id);
      return dispatch({
        type: types.GET_ADMIN_SUCCEED,
        admin: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_ADMIN_FAILED
      });
    }
  };
};

export const deleteAdminAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteAdmin(data);
    return dispatch(getAllAdmins({ page, items, ...search }));
  };
};

export const getAdminRolesAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ADMIN_ROLES
    });
    try {
      const response = await getAdminRoles(id);
      return dispatch({
        type: types.GET_ADMIN_ROLES_SUCCEED,
        roles: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_ADMIN_ROLES_FAILED
      });
    }
  };
};

export const addAdminRoleAction = (guid, data) => {
  return async (dispatch) => {
    const response = await addAdminRole(guid, data);
    dispatch({
      type: types.ADD_ADMIN_ROLE,
      admin: response.data
    });
    dispatch(getAdminRolesAction(guid));
  };
};

export const deleteAdminRoleAction = (guid, roleGuid, reason) => {
  return async (dispatch) => {
    await deleteAdminRole(guid, roleGuid, reason);
    return dispatch(getAdminRolesAction(guid));
  };
};