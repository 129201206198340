import Select from "components/UI/MultiSelect";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

class ActionSelector extends Component {
  state = {
    options: [],
  };

  async componentDidMount() {
    this.setState({
      options: this.props.options,
    });
  }

  onSelect = async (option) => {
    await this.props.search({
      [this.props.value]: option.name,
    });
    if (
      this.props.searchData[this.props.value] &&
      this.props.searchData[this.props.value].length !== 0
    ) {
      await this.props.get({
        page: 1,
        items: 100,
        ...this.props.searchData,
      });
    }
  };

  render() {
    let option = this.props.searchData[this.props.value] || "";
    return (
      <div
        id="search-wrapper"
        className={
          this.props.isSearch
            ? "searchOpen search search-input type-select"
            : "searchClosed search search-input type-select"
        }
      >
        <Select
          name="action_select"
          value={option ? { value: option, label: option } : undefined}
          multi={false}
          onSelect={this.onSelect}
          options={this.state.options}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSearch: state.search.isSearch,
  };
};

export default connect(mapStateToProps, {})(ActionSelector);

ActionSelector.propTypes = {
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  options: PropTypes.array,
  search: PropTypes.func,
  searchData: PropTypes.object,
  value: PropTypes.string,
};
