import * as types from "../constants/actionTypes";
import auth from "../services/paymentBackendAPI/auth/auth";

export const logIn = (data) => {
  return async (dispatch) => {
    try {
      const response = await auth.login(data);
      return dispatch({
        type: types.LOG_IN_SUCCESS,
        data: response.data
      });
    }
    catch(error) {
      return dispatch({
        type: types.LOG_IN_FAILED,
        error: error
      });
    }
  };
};

export const logOut = () => {
  return (dispatch) => {
    return auth.logout()
      .then(() => {
        dispatch({
          type: types.LOG_OUT,
        });
      })
      .catch(error => {
        throw (error);
      });
  };
};

export const getEmailMessageAction = (data) => {
  return async (dispatch) => {
    const response = await auth.getEmailMessage(data);
    dispatch({
      type: types.GET_EMAIL_MESSAGE,
      status: response.data.status
    });
  };
};

export const checkRecoveryTokenAction = data => {
  return async (dispatch) => {
    const response = await auth.checkRecoveryToken(data);
    dispatch({
      type: types.CHECK_RECOVERY_TOKEN,
      statusToken: response.data.status
    });
  };
};

export const setNewPasswordAction = data => {
  return async (dispatch) => {
    const response = await auth.setNewPassword(data);
    dispatch({
      type: types.SET_NEW_PASSWORD,
      status: response.data.status
    });
  };
};

