import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";

export default class PieChart extends Component {
  state = {
    legend: [],
    lineThrough: false,
  };

  componentDidMount() {
    const component = this.pie;
    let lineThrough = component.chartInstance.legend.legendItems.map(() => {
      return false;
    });
    this.setState({
      legend: component.chartInstance.legend.legendItems,
      lineThrough,
    });
  }

  onClickLegend(index) {
    const component = this.pie;
    component.chartInstance.getDatasetMeta(0).data[index].hidden =
      !component.chartInstance.getDatasetMeta(0).data[index].hidden;
    component.chartInstance.update();
    let lineThrough = this.state.lineThrough;

    lineThrough[index] = !lineThrough[index];
    this.setState({ lineThrough });
  }

  listItemStyle = {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    fontSize: "12",
    fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  };

  data = {
    labels: this.props.labels,
    datasets: this.props.datasets,
  };

  render() {
    const { legend } = this.state;
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: "5px",
          }}
        >
          {legend.length &&
            legend.map((item) => {
              return (
                <div
                  key={item.text}
                  style={this.listItemStyle}
                  onClick={() => {
                    this.onClickLegend(item.index);
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                      marginLeft: "5px",
                      width: "30px",
                      height: "15px",
                      backgroundColor: item.fillStyle,
                    }}
                  />
                  <div
                    style={
                      this.state.lineThrough[item.index]
                        ? { textDecoration: "line-through" }
                        : {}
                    }
                  >
                    {item.text} ({this.props.datasets[0].data[item.index]})
                  </div>
                </div>
              );
            })}
        </div>
        <Pie
          ref={(ref) => (this.pie = ref)}
          data={this.data}
          height={180}
          options={{
            maintainAspectRation: false,
            legend: {
              display: false,
            },
          }}
        />
      </>
    );
  }
}

PieChart.propTypes = {
  datasets: PropTypes.array,
  labels: PropTypes.array,
};
