import * as types from "../constants/actionTypes";

const initialState = {
  accountsList: [],
  account: {},
  accountLoading: false,
  loading: false,
  error: false,
};

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case types.GET_ACCOUNTS:
      return {
        ...state,
        accountsList: [],
        loading: true,
        error: false
      };
    case types.GET_ACCOUNTS_SUCCEED:
      return {
        ...state,
        accountsList: action.data,
        loading: false,
        error: false
      };
    case types.GET_ACCOUNTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.EDIT_ACCOUNT: {
      let newAccountsList = state.accountsList;
      newAccountsList = newAccountsList.map(account => {
        if (account.guid === action.account.guid)
          return action.account;
        else
          return account;
      });
      return { ...state, accountsList: newAccountsList };
    }
    case types.ADD_ACCOUNT:
      return { ...state };
    case types.DELETE_ACCOUNT: {
      let newAccountsList = state.accountsList.filter(account => account.guid !== action.account.guid);
      return { ...state, accountsList: newAccountsList };
    }
    default:
      return state;
  }
}