import React, { Component } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Can from "../../Can";
import GroupsTable from "./Groups/Main";
import LoginsTable from "./Logins/Main";
import MerchantsTable from "./Merchants/Main";
import PartnersTable from "./Partners/Main";

class UsersAuditMain extends Component {
  constructor() {
    super();

    this.state = {
      type: "Merchants",
      isUpdate: false,
      labelButton: "Merchants",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e) {
    this.setState({
      type: e,
      labelButton: e
    });
  }

  render() {
    return (
      <div>
        <DropdownButton
          as="s"
          key={this.state.labelButton}
          id={`dropdown-variants-${this.state.labelButton}`}
          title={this.state.labelButton}
        >
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Merchants">Merchants</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Groups">Groups</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Partners">Partners</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Logins">Logins</Dropdown.Item>
        </DropdownButton>
        {
          {
            "Merchants": <Can do="AUDIT" on="MERCHANTS">
              <MerchantsTable />
            </Can>,
            "Groups": <Can do="AUDIT" on="GROUPS">
              <GroupsTable />
            </Can>,
            "Partners": <Can do="AUDIT" on="PARTNERS">
              <PartnersTable />
            </Can>,
            "Logins": <Can do="AUDIT" on="LOGINS">
              <LoginsTable />
            </Can>,

          }[this.state.type]
        }
      </div>
    );
  }
}

export default UsersAuditMain;
