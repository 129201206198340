import React, { Component } from "react";
import { connect } from "react-redux";
import Search from "../../Search/index";
import { Col, Row, Form } from "react-bootstrap";
class SearchPanel extends Component{

  render() {
    const searchColumns = this.props.searchColumns;
    if(this.props.isSearch && searchColumns)
      return (
              
        <table className="table">
          <thead>
            {searchColumns.map((sc, index) => (
              <tr key={index} className="search-row">
                <th className="searchOpen">
                  <Row>
                    <Col md={1} sm={2} xs={2}>
                      <Form.Label>{sc.label}</Form.Label>
                    </Col>
                    <Col md={2} sm={3} xs={3}>
                      <Search
                        style={{ padding: "15px" }}
                        value={sc.path || sc.key}
                        search={this.props.search}
                        get={this.props.get}
                        searchData={this.props.searchData}
                        updateData={this.props.updateData}
                        updateCurrentPage={this.props.updateCurrentPage}
                      />
                    </Col>
                  </Row>
                </th>   
              </tr>
            ))}
          </thead>
        </table>
      );
    else    
      return (<div></div>);
  }
}

const mapStateToProps = (state) => {
  return {
    isSearch: state.search.isSearch
  };
};
  
export default connect(mapStateToProps)(SearchPanel);