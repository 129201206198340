import * as types from "../constants/actionTypes";

export const loadTable = (page, size, filters) => {
  return async (dispatch) => {
    dispatch({
      type: types.LOAD_TABLE,
      page,
      size,
      filters
    });
  };
};

export const setNewTable = (name) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_NEW_TABLE,
      name,
    });
  };
};

export const resetPage = () => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_PAGE,
    });
  };
};
