import { backendLogs } from "../backendPlatform";


export const getUsersLogs = (args) => {
  return backendLogs.get("/users", { params: { ...args } }); 
};

export const getAdminsLogs = (args) => {
  return backendLogs.get("/admins", { params: { ...args } }); 
};

export const getMerchantsLogs = (args) => {
  return backendLogs.get("/merchants", { params: { ...args } }); 
};

export const getGoupsLogs = (args) => {
  return backendLogs.get("/groups", { params: { ...args } }); 
};

export const getPartnerLogs = (args) => {
  return backendLogs.get("/partners", { params: { ...args } }); 
};


// (request_id, partner_guid, partner_name, group_guid, group_name, merchant_guid, merchant_name, transaction_guid, step_info, shop_guid, shop_name, from_date, to_date, items, page)
export const getTransactionsLogs = (args) => {
  return backendLogs.get("/transactions", { params: { ...args } });
};

// (guid, status, type, transaction_processing_guid, transaction_type, partner_guid, partner_name, group_guid, group_name, merchant_guid, merchant_name, shop_guid, shop_name, account_guid, account_number, from_date, to_date, days, page, items)
export const getTransactionsStepsLogs = ( args ) => {
  return backendLogs.get("/step_logs", { params: { ...args } });
};

// ()
export const getDectaRecotServiceLogs = ( args ) => {
  return backendLogs.get("/services/decta_recot", { params: { ...args } });
};

// ()
export const getСhargebackServiceLogs = ( args ) => {
  return backendLogs.get("/services/chargeback", { params: { ...args } });
};

// ()
export const getLoginExpiredServiceLogs = ( args ) => {
  return backendLogs.get("/services/login_expired", { params: { ...args } });
};

// ()
export const getCurrencyExchangeServiceLogs = ( args ) => {
  return backendLogs.get("/services/currency_exchange", { params: { ...args } });
};

// ()
export const getDeleteCardDataServiceLogs = ( args ) => {
  return backendLogs.get("/services/delete_card_data", { params: { ...args } });
};


// ()
export const getRecurringServiceLogs = ( args ) => {
  return backendLogs.get("/services/recurring", { params: { ...args } });
};

// don't use
// (guid, status, type, partner_guid, partner_name, group_guid, group_name, merchant_guid, merchant_name, shop_guid, shop_name, account_guid, account_number, from_date, to_date, days, page, items)
export const getTransactionsLogsPSQL = ( args ) => {
  return backendLogs.get("/transaction_logs", { params: { ...args } });
};
