import React from "react";
import AdminMain from "components/Admin/Main";
import Content from "../Content";
import Modal from "components/UI/Modal";
// import Can from "components/Can";
import AdminCreator from "components/Admin/Creator";
import ability from "config/ability";

const Admin = () => {
  return (
    <Content>
      <AdminMain
        modalComponent={
          // <Can do="EXECUTE" on="USERADMIN">
          <Modal
            allowed={ability.can("EXECUTE", "USERADMIN")}
            header="Create admin"
            content={AdminCreator}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create admin
              </button>
            }
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default Admin;
