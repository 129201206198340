import * as types from "../constants/actionTypes";

export default function logs(state = [], action) {
  switch (action.type) {
    case types.GET_USERS_LOGS:
      return {
        ...state,
        usersLogsLoading: true,
        error:false
      };
    case types.GET_USERS_LOGS_SUCCEED:
      return {
        ...state,
        usersLogs: action.data.data,
        usersLogsCount: action.data.count,
        usersLogsLoading: false,
        error:false
      };
    case types.GET_USERS_LOGS_FAILED:
      return {
        ...state,
        usersLogsLoading: false,
        error: true
      };

    case types.GET_ADMINS_LOGS:
      return {
        ...state,
        usersLogsLoading: true,
        error:false
      };
    case types.GET_ADMINS_LOGS_SUCCEED:
      return {
        ...state,
        adminsLogs: action.data.data,
        admisLogsCount: action.data.count,
        usersLogsLoading: false,
        error:false
      };
    case types.GET_ADMINS_LOGS_FAILED:
      return {
        ...state,
        usersLogsLoading: false,
        error: true
      };

    case types.GET_MERCHANTS_LOGS:
      return {
        ...state,
        usersLogsLoading: true,
        error:false
      };
    case types.GET_MERCHANTS_LOGS_SUCCEED:
      return {
        ...state,
        merchantsLogs: action.data.data,
        merchantsLogsCount: action.data.count,
        usersLogsLoading: false,
        error:false
      };
    case types.GET_MERCHANTS_LOGS_FAILED:
      return {
        ...state,
        usersLogsLoading: false,
        error: true
      };


    case types.GET_GROUP_LOGS:
      return {
        ...state,
        usersLogsLoading: true,
        error:false
      };
    case types.GET_GROUP_LOGS_SUCCEED:
      return {
        ...state,
        groupsLogs: action.data.data,
        groupsLogsCount: action.data.count,
        usersLogsLoading: false,
        error:false
      };
    case types.GET_GROUP_LOGS_FAILED:
      return {
        ...state,
        usersLogsLoading: false,
        error: true
      };

    case types.GET_PARTNER_LOGS:
      return {
        ...state,
        usersLogsLoading: true,
        error:false
      };
    case types.GET_PARTNER_LOGS_SUCCEED:
      return {
        ...state,
        partnersLogs: action.data.data,
        partnersLogsCount: action.data.count,
        usersLogsLoading: false,
        error:false
      };
    case types.GET_PARTNER_LOGS_FAILED:
      return {
        ...state,
        usersLogsLoading: false,
        error: true
      };

    case types.GET_TRANSACTIONS_LOGS:
      return {
        ...state,
        transactionsLogsLoading: true,
        error: false
      };
    case types.GET_TRANSACTIONS_LOGS_SUCCEED:
      return {
        ...state,
        transactionsLogs: action.data.data,
        transactionsLogsCount: action.data.count,
        transactionsLogsLoading: false,
        error: false
      };
    case types.GET_TRANSACTIONS_LOGS_FAILED:
      return {
        ...state,
        transactionsLogsLoading: false,
        error: true
      };
    case types.GET_TRANSACTIONS_STEPS_LOGS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_TRANSACTIONS_STEPS_LOGS_SUCCEED:
      return {
        ...state,
        transactionsStepsLogs: action.data.data,
        transactionsStepsLogsCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_TRANSACTIONS_STEPS_LOGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.GET_DECTA_RECOT_SERVICE_LOGS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_DECTA_RECOT_SERVICE_LOGS_SUCCEED:
      return {
        ...state,
        dectaRecotServiceLogs: action.data.data,
        dectaRecotServiceLogsCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_DECTA_RECOT_SERVICE_LOGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.GET_CHARGEBACK_SERVICE_LOGS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_CHARGEBACK_SERVICE_LOGS_SUCCEED:
      return {
        ...state,
        chargebackServiceLogs: action.data.data,
        chargebackServiceLogsCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_CHARGEBACK_SERVICE_LOGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.GET_LOGIN_EXPIRED_SERVICE_LOGS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_LOGIN_EXPIRED_SERVICE_LOGS_SUCCEED:
      return {
        ...state,
        loginExpiredServiceLogs: action.data.data,
        loginExpiredServiceLogsCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_LOGIN_EXPIRED_SERVICE_LOGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.GET_CURRENCY_EXCHANGE_SERVICE_LOGS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_CURRENCY_EXCHANGE_SERVICE_LOGS_SUCCEED:
      return {
        ...state,
        currencyExchangeServiceLogs: action.data.data,
        currencyExchangeServiceLogsCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_CURRENCY_EXCHANGE_SERVICE_LOGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case types.GET_DELETE_CARD_DATA_SERVICE_LOGS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_DELETE_CARD_DATA_SERVICE_LOGS_SUCCEED:
      return {
        ...state,
        deleteCardDataServiceLogs: action.data.data,
        deleteCardDataServiceLogsCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_DELETE_CARD_DATA_SERVICE_LOGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_RECURRING_SERVICE_LOGS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_RECURRING_SERVICE_LOGS_SUCCEED:
      return {
        ...state,
        recurringServiceLogs: action.data.data,
        recurringServiceLogsCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_RECURRING_SERVICE_LOGS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}