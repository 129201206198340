import React from "react";
import Content from "../Content";
import MerchantLogsMain from "components/Log/Merchant/Main";

const MerchantsLogs = () => {
  return (
    <Content>
      <MerchantLogsMain />
    </Content>
  );
};

export default MerchantsLogs;
