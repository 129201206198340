import React from "react";
import RolePrivilegeMain from "components/RolePrivilege/Main";
import Content from "../Content";

const RolePrivelege = () => {
  return (
    <Content>
      <RolePrivilegeMain />
    </Content>
  );
};

export default RolePrivelege;
