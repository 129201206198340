import Spinner from "components/UI/Spinner/index";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Content from "views/Content";
import {
  getAuditMerchantTablesAction,
  getAuditMerchantAction,
} from "../../../../actions/audit";
import ability from "../../../../config/ability";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableAudit } from "../common/Table";

const MerchantAuditDetail = () => {
  const [ page, setPage ] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();
  const history = useParams();
  const { count, data, loading } = useSelector(({ audit }) => {
    return {
      count: audit.merchantsTablesCount,
      data: audit.merchantsTables,
      loading: audit.loading,
    };
  });
  const { merchant } = useSelector(({ audit }) => ({
    merchant: audit.merchants,
  }));
  const [ merchantData, setMerchantData ] = useState({});

  useEffect(() => {
    if (merchant && merchant[0]) {
      setMerchantData(merchant[0]);
    }
  }, [ merchant ]);

  const rows = [
    {
      label: "Merchant name",
      path: "name",
    },
    {
      label: "Merchant type",
      path: "type",
    },
    {
      label: "Group name",
      path: "group_name",
    },
    {
      label: "Group guid",
      path: "group_guid",
    },
    {
      label: "Monthly fee",
      path: "monthly_fee",
    },
    {
      label: "Monthly fee currency",
      path: "monthly_fee_currency",
    },
    {
      label: "Monthly fee date",
      path: "monthly_fee_date",
    },
    {
      label: "Monthly amount limit",
      path: "monthly_amount_limit",
    },
    {
      label: "Custom amount limit",
      path: "custom_amount_limit",
    },
    {
      label: "Custom days limit",
      path: "custom_days_limit",
    },
  ];

  const loadData = async (page) => {
    dispatch(
      getAuditMerchantTablesAction({
        guid: history.id,
        page,
        items: pageSize,
      })
    );
  };

  useEffect(() => {
    dispatch(getAuditMerchantAction({ guid: history.id }));
    loadData(page, pageSize);
  }, []);

  const handleChangePage = async (page) => {
    setPage(page);
    loadData(page);
  };

  return (
    <Content style={{ overflow: "auto" }}>
      <Row style={{ marginLeft: "0px" }}>
        <Card>
          <Card.Header as="h5">{merchant.merchant_name}</Card.Header>
          <Card.Body>
            <Card.Title>Info</Card.Title>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Merchant GUID:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{history.id}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Merchant Name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchantData.merchant_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Merchant Type:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchantData.merchant_type}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>merchant status:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchantData.merchant_status}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>last_change:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchantData.last_change}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>change_count:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchantData.change_count}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>author_name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchantData.author_name}</span>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>

      {ability.can("AUDIT", "MERCHANTS") && (
        <Card>
          <Card.Body>
            <Card.Title>History</Card.Title>
            {loading ? (
              <Spinner />
            ) : (
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <TableAudit
                    data={data}
                    count={count}
                    handleChangePage={handleChangePage}
                    page={page}
                    pageSize={pageSize}
                    rows={rows}
                  />
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      )}
    </Content>
  );
};

export default MerchantAuditDetail;
