import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { addAdminAction } from "../../actions/admins";
import { getAllRoles } from "actions/roles";
import { checkUserExistsAction } from "../../actions/users";
import { showModal } from "../../actions/modal";
import { connect } from "react-redux";
import { parseResponse } from "helpers/parseResponse";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import PhoneInput from "../UI/PhoneInput";
import MultiSelect from "components/UI/MultiSelect/index";
import Joi from "joi-browser";
import ability from "config/ability";
import PropTypes from "prop-types";
import Alert from "../UI/Alert";

class AdminCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: "",
        firstName: "",
        lastName: "",
      },
      authType: false,
      language: "en",
      phone: "",
      role_guid: "",
      password: "",
      sendMail: true,
      enabled: true,
      showPassword: false,

      roles: [],

      isLoading: false,

      errorEmailExists: "",
      errorPhoneExists: "",
      errorsExists: {},
      errors: {},

      emailValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      roleValidation: false,
      phoneValidation: false,
      passwordValidation: false,
    };
  }

  schema = {
    email: Joi.string().required().email().label("Email"),
    firstName: Joi.string().optional().label("First name"),
    lastName: Joi.string().optional().label("Last name"),
    phone: Joi.string().required().min(5).label("Phone"),
    role: Joi.string().required().label("Role"),
    authType: Joi.any().required(),
    language: Joi.string().required().label("Language"),
  };

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        ...this.state.data,
        authType: this.state.authType,
        phone: this.state.phone,
        role: this.state.role_guid,
        language: this.state.language,
      },
      this.schema,
      options
    );

    if (!this.state.sendMail) {
      let schema = {
        password: Joi.string()
          .required()
          .regex(
            /(?=^.{12,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
          )
          .error(() => {
            return {
              message:
                "Password must be at least 12 characters, including a number, a special, and  an uppercase letter.",
            };
          })
          .label("Password"),
      };

      let passwordError = Joi.validate(
        { password: this.state.password },
        schema,
        options
      );
      if (
        passwordError &&
        passwordError.error &&
        passwordError.error.details[0]
      ) {
        let arrayErrors = error ? error.details : [];
        arrayErrors.push(passwordError.error.details[0]);
        error = { ...error, details: arrayErrors };
      }
    }

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (name === "email") {
      if (
        this.state.errors[name] ||
        this.state.data[name] === "" ||
        this.state[name] === "" ||
        this.state.errorEmailExists
      )
        return "error";
      else return "success";
    } else if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  componentDidMount = async () => {
    await this.props.getAllRoles({ type: "admin" });
    this.setState({ roles: this.props.roles });
  };

  onChangeHandler = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name: "phone", value: value });
    if (errorMessage) errors.phone = errorMessage;
    else delete errors.phone;

    this.setState({
      phone: value,
      errors,
      phoneValidation: errorMessage ? false : true,
    });

    if (value.length > 4)
      await this.props.checkUserExistsAction({ phone: value });

    this.setState({
      phoneExists: this.props.phoneExists,
    });

    if (this.state.phoneExists)
      this.setState({
        errorPhoneExists: `Phone ${value} exists`,
      });
    else
      this.setState({
        errorPhoneExists: "",
      });
  };

  onChangeEmail = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });

    const errorsExists = this.validate();
    this.setState({ errorsExists: errorsExists || {} });

    try {
      if (
        input.value.length > 8 &&
        this.state.errorsExists.email === undefined &&
        input.value.indexOf(".") !== -1
      )
        await this.props.checkUserExistsAction({ email: input.value });
    } catch {
      this.setState({
        errorEmailExists: "Email must be a valid",
      });
    }

    this.setState({
      emailExists: this.props.emailExists,
    });

    if (this.state.emailExists)
      this.setState({
        errorEmailExists: "Email exists",
        emailValidation: false,
      });
    else
      this.setState({
        errorEmailExists: "",
        emailValidation: errorMessage ? false : true,
      });
  };

  onChangePassword = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    let errorMessage;

    if (this.state.sendMail)
      errorMessage = "Password is not allowed to be empty";
    if (input.value.length < 8)
      errorMessage = "Minimum password length must be 12 characters!";
    if (!/[a-z]/.test(input.value))
      errorMessage = "Password must contain at least 1 small letter!";
    if (!/[A-Z]/.test(input.value))
      errorMessage = "Password must contain at least 1 uppercase letter!";
    if (!/[0-9]/.test(input.value))
      errorMessage = "Password must contain at least 1 number!";
    if (!/[!#$%&()*+,-./:;<=>?@[\]^_`{|}~]/.test(input.value))
      errorMessage = "Password must contain at least 1 special symbol!";

    if (errorMessage) {
      this.setState({ passwordValidation: false });
      errors[input.name] = errorMessage;
    } else {
      this.setState({ passwordValidation: true });
      delete errors[input.name];
    }

    this.setState({ password: input.value, errors });
  };

  handleSelectRole = (option) => {
    const errors = { ...this.state.errors };
    delete errors.role;
    this.setState({
      role_guid: option.guid,
      errors,
      roleValidation: true,
    });
  };

  handleEnabledCheckbox = () => {
    this.setState({
      enabled: !this.state.enabled,
    });
  };

  handleAuthTypeCheckbox = () => {
    this.setState({
      authType: !this.state.authType,
    });
  };

  handleSendMailCheckbox = () => {
    this.setState({
      sendMail: !this.state.sendMail,
    });
  };

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  onSelectLanguage = (option) => {
    this.setState({
      language: option.name,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      const admin = this.state;
      try {
        await this.props.addAdminAction({
          email: admin.data.email,
          first_name: admin.data.firstName,
          last_name: admin.data.lastName,
          auth_type: admin.authType ? "login-password-mail" : "login-password",
          password: admin.sendMail ? undefined : admin.password,
          language: admin.language,
          send_mail: admin.sendMail === true ? 1 : 0,
          enabled: admin.enabled === true ? 1 : 0,
          role_guid: admin.role_guid,
          phone: admin.phone,
        });
        swal({
          title: "Admin is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        this.setState({ isLoading: false });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const roles = this.state.roles;
    const { errors } = this.state;

    return (
      <Form autoComplete="off">
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Email*</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group validationState={this.formValidation("email")}>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={(e) => this.onChangeEmail(e)}
              />
              {errors.email && (
                <span className="validate-error">{errors.email}</span>
              )}
              {this.state.errorEmailExists && (
                <span className="validate-error">
                  {this.state.errorEmailExists}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>First name*</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group validationState={this.formValidation("firstName")}>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="Enter first name"
                value={this.state.firstName}
                onChange={(e) => this.onChangeHandler(e)}
              />
              {errors.firstName && (
                <span className="validate-error">{errors.firstName}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Last name*</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group validationState={this.formValidation("lastName")}>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Enter last name"
                value={this.state.lastName}
                onChange={(e) => this.onChangeHandler(e)}
              />
              {errors.lastName && (
                <span className="validate-error">{errors.lastName}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Phone*</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group>
              <PhoneInput
                name="phone"
                placeholder="Enter phone number"
                value={this.state.phone}
                onChange={(e) => this.onChangeNumber(e)}
              />
              {this.state.errorPhoneExists && (
                <span className="validate-error">
                  {this.state.errorPhoneExists}
                </span>
              )}
              {errors.phone && (
                <span className="validate-error">{errors.phone}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Role*</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group>
              <MultiSelect
                name="role_guid"
                options={roles}
                placeholder="Select role"
                multi={false}
                onSelect={this.handleSelectRole}
              />
              {errors.role && (
                <span className="validate-error">{errors.role}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <label>Enable*</label>
          </Col>
          <Col md={1} sm={1} xs={1}>
            <input
              type="checkbox"
              id="enabledCheckbox"
              checked={this.state.enabled}
              onChange={this.handleEnabledCheckbox}
            />
          </Col>
        </Row>

        {ability.can("EXECUTE", "USERADMINAUTHTYPE") && (
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <label>Two-factor authentication*</label>
            </Col>
            <Col md={1} sm={1} xs={1}>
              <input
                type="checkbox"
                id="enabledCheckbox"
                checked={this.state.authType}
                onChange={this.handleAuthTypeCheckbox}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <label>Send mail*</label>
            <i className="far fa-question-circle help-tip">
              <p>
                We will send your generated username and password to your email.
              </p>
            </i>
          </Col>
          <Col md={1} sm={1} xs={1}>
            <input
              type="checkbox"
              id="sendMailCheckbox"
              checked={this.state.sendMail}
              onChange={this.handleSendMailCheckbox}
            />
          </Col>
        </Row>

        {!this.state.sendMail && (
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Password*</Form.Label>
            </Col>
            <Col md={8} sm={7} xs={7}>
              <Form.Group validationState={this.formValidation("password")}>
                <Form.Control
                  name="password"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  placeholder="Enter password"
                  onChange={this.onChangePassword}
                />
                {errors.password && (
                  <span className="validate-error">{errors.password}</span>
                )}
              </Form.Group>
            </Col>
            <Button className="eye" onClick={this.handleShowPassword}>
              {this.state.showPassword ? (
                <span className="fa fa-eye-slash" />
              ) : (
                <span className="fa fa-eye" />
              )}
            </Button>
          </Row>
        )}
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Language</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group>
              <MultiSelect
                name="Language"
                options={[
                  { name: "en", guid: "en" },
                  { name: "ru", guid: "ru" },
                ]}
                multi={false}
                defaultValue={this.state.language}
                onSelect={this.onSelectLanguage}
              />
            </Form.Group>
          </Col>
        </Row>

        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className={
                this.state.phoneValidation &&
                this.state.roleValidation &&
                this.state.emailValidation &&
                this.state.firstNameValidation &&
                this.state.lastNameValidation &&
                (this.state.sendMail ? true : this.state.passwordValidation)
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
              onClick={this.handleSubmit}
            >
              Create Admin
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailExists: state.users.users.email_exists,
    phoneExists: state.users.users.phone_exists,
    roles: state.roles.rolesList,
    loginGuid: state.logins.loginGuid,
    pageSize: state.logins.pageSize,
    currentPage: state.logins.currentPage,

    loginsSearch: state.search.loginsSearch,
  };
};

export default connect(mapStateToProps, {
  addAdminAction,
  checkUserExistsAction,
  getAllRoles,
  showModal,
})(AdminCreator);

AdminCreator.propTypes = {
  addAdminAction: PropTypes.func,
  checkUserExistsAction: PropTypes.func,
  emailExists: PropTypes.bool,
  getAllRoles: PropTypes.func,
  phoneExists: PropTypes.bool,
  roles: PropTypes.array,
  showModal: PropTypes.func,
};
