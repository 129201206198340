import React from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";

export const LineChart = React.memo((props) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  return (
    <>
      <Line
        data={data}
        height={100}
        options={{ maintainAspectRation: false, ...props.options }}
      />
    </>
  );
});

LineChart.propTypes = {
  datasets: PropTypes.array,
  labels: PropTypes.array,
  options: PropTypes.object,
};
