import React from "react";
import ShopMain from "components/Shop/Main";
import Content from "../Content";
import Modal from "components/UI/Modal";
// import Can from "components/Can";
import ShopCreator from "components/Shop/Creator";
import ability from "config/ability";

const Shop = () => {
  return (
    <Content>
      <ShopMain
        modalComponent={
          <Modal
            allowed={ability.can("EXECUTE", "SHOPS")}
            header="Create shop"
            content={ShopCreator}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create shop
              </button>
            }
          />
        }
      />
    </Content>
  );
};

export default Shop;
