import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "components/UI/Spinner/index";
import { addAccountAction } from "actions/accounts";
import { showModal } from "actions/modal";
import { getMerchantShopsAction } from "actions/shops";
import { parseResponse } from "helpers/parseResponse";
import { Col, Row, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import MultiSelect from "components/UI/MultiSelect";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import Joi from "joi-browser";

class AccountEditor extends Component {
  state = {
    shops: [],
    data: {
      guid: "",
      number: "",
      shop_guid: "",
      holder_name: "",
      holder_country: "",
      bank_name: "",
      bank_address: "",
    },
    oldData: {
      number: "",
      shop_guid: "",
      holder_name: "",
      holder_country: "",
      bank_name: "",
      bank_address: "",
    },
    shopOption: {},
    needReason: false,
    reason: "",
    merchant_guid: "",
    errors: [],
    isLoading: false,
    loading: false,
    numberValidation: false,
    shop_guidValidation: false,
    holder_nameValidation: false,
    holder_countryValidation: false,
    bank_nameValidation: false,
    bank_addressValidation: false,
  };

  schema = {
    guid: Joi.string().required().label("Guid"),
    number: Joi.string().required().label("Number"),
    shop_guid: Joi.string().required().label("Shop"),
    holder_name: Joi.string().required().label("Holder name"),
    holder_country: Joi.string().required().max(10).label("Holder country"),
    bank_name: Joi.string().required().label("Bank name"),
    bank_address: Joi.string().required().label("Bank address"),
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const token = getAuthData().userPayload;
    let merchant_guid;
    if (token.merchant) {
      merchant_guid = token.merchant.merchant_guid;
      this.setState({ merchant_guid: token.merchant.merchant_guid });
    } else {
      merchant_guid = this.props.guidMerchant;
      this.setState({ merchant_guid: this.props.guidMerchant });
    }

    const account = this.props.account;
    await this.props.getMerchantShopsAction(merchant_guid);
    this.setState({
      shops: this.props.shops,
    });
    let shopOption = account.shop_guid
      ? this.props.shops.filter(
        (option) => option.guid === account.shop_guid
      )[0]
      : "";
    this.setState({
      data: {
        guid: account.guid,
        number: account.number,
        shop_guid: account.shop_guid,
        holder_name: account.holder_name,
        holder_country: account.holder_country,
        bank_name: account.bank_name,
        bank_address: account.bank_address,
      },
      oldData: {
        number: account.number,
        shop_guid: account.shop_guid,
        holder_name: account.holder_name,
        holder_country: account.holder_country,
        bank_name: account.bank_name,
        bank_address: account.bank_address,
      },

      shopOption,

      numberValidation: account.number ? true : false,
      shop_guidValidation: account.shop_guid ? true : false,
      holder_nameValidation: account.holder_name ? true : false,
      holder_countryValidation: account.holder_country ? true : false,
      bank_nameValidation: account.bank_name ? true : false,
      bank_addressValidation: account.bank_address ? true : false,
      loading: false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };

    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = {
        guid: this.state.data.guid,
        number: this.state.data.number,
        shop_guid: this.state.data.shop_guid,
        holder_name: this.state.data.holder_name,
        holder_country: this.state.data.holder_country,
        bank_name: this.state.data.bank_name,
        bank_address: this.state.data.bank_address,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        guid: this.state.data.guid,
        number: this.state.data.number,
        shop_guid: this.state.data.shop_guid,
        holder_name: this.state.data.holder_name,
        holder_country: this.state.data.holder_country,
        bank_name: this.state.data.bank_name,
        bank_address: this.state.data.bank_address,
      };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state.data[name] === "") return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state.data };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  onSelectshops = (option) => {
    let data = { ...this.state.data };
    data.shop_guid = option.guid;
    this.setState({ data }, () => this.showReason());
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const property = input.name;

    let data = { ...this.state.data };
    data[property] = input.value;
    this.setState(
      { data, errors, [property + "Validation"]: errorMessage ? false : true },
      () => this.showReason()
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });

          await this.props.addAccountAction(this.state.merchant_guid, {
            guid: this.state.data.guid,
            number: this.state.data.number,
            shop_guid: this.state.data.shop_guid,
            holder_name: this.state.data.holder_name,
            holder_country: this.state.data.holder_country,
            bank_name: this.state.data.bank_name,
            bank_address: this.state.data.bank_address,
            reason: this.state.reason,
          });
          swal({
            title: "Account is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        swal({
          title: parsedError.error,
          text: parsedError.message,
          icon: "error",
        });
      }
    }
  };

  render() {
    if (this.state.loading) return <Spinner />;
    else
      return (
        <Form autoComplete="off">
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Number* </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("number")}>
                <Form.Control
                  name="number"
                  type="number"
                  value={this.state.data.number}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Holder name* </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("holder_name")}>
                <Form.Control
                  name="holder_name"
                  type="string"
                  value={this.state.data.holder_name}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Holder country* </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group
                validationState={this.formValidation("holder_country")}
              >
                <Form.Control
                  name="holder_country"
                  type="string"
                  value={this.state.data.holder_country}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Bank name* </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("bank_name")}>
                <Form.Control
                  name="bank_name"
                  type="string"
                  value={this.state.data.bank_name}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Bank address* </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("bank_address")}>
                <Form.Control
                  name="bank_address"
                  type="string"
                  value={this.state.data.bank_address}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Shops</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group>
                <MultiSelect
                  name="Shops"
                  options={this.props.shops ? this.props.shops : []}
                  multi={false}
                  onSelect={this.onSelectshops}
                  defaultValue={this.state.shopOption}
                />
              </Form.Group>
            </Col>
          </Row>
          {this.state.needReason && (
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Reason</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("reason")}>
                  <Form.Control
                    placeholder="Enter reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChangeReason}
                  />
                  {/* {errors.reason && <span className="validate-error">{errors.reason}</span>} */}
                </Form.Group>
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.numberValidation &&
                  this.state.shop_guidValidation &&
                  this.state.holder_nameValidation &&
                  this.state.holder_countryValidation &&
                  this.state.bank_nameValidation &&
                  this.state.bank_addressValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shops.shopsList,
  };
};

export default connect(mapStateToProps, {
  getMerchantShopsAction,
  addAccountAction,
  showModal,
})(AccountEditor);

AccountEditor.propTypes = {
  account: PropTypes.object,
  addAccountAction: PropTypes.func,
  getMerchantShopsAction: PropTypes.func,
  guidMerchant: PropTypes.string,
  shops: PropTypes.array,
  showModal: PropTypes.func,
};
