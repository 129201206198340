import * as types from "../constants/actionTypes";

export const inverseSearch = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INVERSE_SEARCH,
      data
    });
  };
};

export const reset = () => {
  return async (dispatch) => {
    dispatch({
      type: types.RESET_SEARCH
    });
  };
};

export const searchInAdminsLogs = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_ADMINS_LOGS,
      data
    });
  };
};

export const searchInMerchantsLogs = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_MERCHANTS_LOGS,
      data
    });
  };
};

export const searchInGroupsLogs = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_GROUPS_LOGS,
      data
    });
  };
};

export const searchInPartnersLogs = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_PARTNERS_LOGS,
      data
    });
  };
};

export const searchInServicesLogs = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_SERVICE_LOGS,
      data
    });
  };
};

export const searchInTransactionsLogs = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_TRANSACTIONS_LOGS,
      data
    });
  };
};

export const searchInAccounts = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_ACCOUNTS,
      data
    });
  };
};

export const searchInBlacklist = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_BLACKLIST,
      data
    });
  };
};

export const searchInGlobalBlacklist = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_GLOBAL_BLACKLIST,
      data
    });
  };
};

export const searchInMerchantsBlacklist = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_MERCHANTS_BLACKLIST,
      data
    });
  };
};

export const searchInCurrencies = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_CURRENCIES,
      data
    });
  };
};

export const searchInChargebacks = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_CHARGEBACKS,
      data
    });
  };
};

export const searchInGateways = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_GATEWAYS,
      data
    });
  };
};

export const searchInGroups = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_GROUPS,
      data
    });
  };
};

export const searchInAdmins = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_ADMINS,
      data
    });
  };
};

export const searchInMerchants = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_MERCHANTS,
      data
    });
  };
};

export const searchInPartners = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_PARTNERS,
      data
    });
  };
};

export const searchInPrivileges = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_PRIVILEGES,
      data
    });
  };
};

export const searchInRates = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_RATES,
      data
    });
  };
};

export const searchInRoles = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_ROLES,
      data
    });
  };
};

export const searchInShops = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_SHOPS,
      data
    });
  };
};

export const searchInTransactions = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_TRANSACTIONS,
      data
    });
  };
};

export const searchInTransactionsTemplates = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_TRANSACTIONS_TEMPLATES,
      data
    });
  };
};

export const searchInMerchantTransactions = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_MERCHANT_TRANSACTIONS,
      data
    });
  };
};

export const searchInOrders = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_ORDERS,
      data
    });
  };
};

export const searchInStatements = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_STATEMENTS,
      data
    });
  };
};

export const searchInMerchantsAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_MERCHANTS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInGroupsAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_GROUPS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInPartnersAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_PARTNERS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInLoginsAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_LOGINS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInShopsAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_SHOPS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInTerminalsAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_TERMINALS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInGatewaysAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_GATEWAYS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInAccountsAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_ACCOUNTS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInCurrenciesAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_CURRENCIES_AUDIT_TABLE,
      data
    });
  };
};

export const searchInTransactionOverviewsAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_TRANSACTION_OVERVIEWS_AUDIT_TABLE,
      data
    });
  };
};

export const searchInTransactionProcessingAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_TRANSACTION_PROCESSING_AUDIT_TABLE,
      data
    });
  };
};

export const searchInTransactionDataAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_TRANSACTION_DATA_AUDIT_TABLE,
      data
    });
  };
};

export const searchInServiceAuditTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_SERVICE_AUDIT_TABLE,
      data
    });
  };
};

export const searchInMismatchTransactionsTable = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.INPUT_SEARCH_IN_MISMATCH_TRANSACTION_TABLE,
      data
    });
  };
};

export const setCurrentSearchData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_SEARCH_DATA,
      data
    });
  };
};

