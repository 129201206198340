import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { mergeStatementsAction } from "../../actions/statements";
import Alert from "../UI/Alert";
import TableRates from "./Components/Tables/TableRates";
import TablePayableSummary from "./Components/Tables/TablePayableSummary";
import MergeForm from "./Components/MergeForm";
class Merge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      statementLoaded: false,
      statement: {},
      keys: []
    };
  }

  save = async (data) => {
    try {
      this.setState({
        isLoading: true,
        statementLoaded: false,
      });
      await this.props.mergeStatementsAction(data);
      swal({
        title: "Statement is created",
        icon: "success",
        text: this.props.warning ? this.props.warning : "",
        button: false,
        timer: 2000,
      });
      this.setState({
        isLoading: false,
      });
      window.location.replace(
        process.env.PUBLIC_URL + "#/transactions/statements" ||
        "#/transactions/statements"
      );
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  calculate = async (data) => {
    try {
      this.setState({
        statementLoaded: false,
        isLoading: true,
      });
      await this.props.mergeStatementsAction(data);
      let keys = Object.keys(this.props.statement.entityData);
      keys = keys
        .map((key) => (key.length === 3 || key === "Summary" ? key : null))
        .filter((key) => key !== null).map(key => ({ name: key }));

      swal({
        title: "Statement is calculated",
        icon: "success",
        text: this.props.warning ? this.props.warning : "",
        button: false,
        timer: 2000,
      });

      this.setState({
        statement: this.props.statement,
        keys,
        statementLoaded: true,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Card>
          <Card.Body>
            <Card.Title>Payable statement</Card.Title>
            <MergeForm
              calculate={this.calculate}
              save={this.save}
              isLoading={this.state.isLoading}
            />
          </Card.Body>
        </Card>
        {this.state.statementLoaded && (
          <Card>
            <Card.Body>
              <TablePayableSummary
                statementData={this.props.statement.statementData}
              />
              <TableRates
                additional_fees_names={this.props.statement.additional_fees_names}
                currencies={this.state.keys}
                statement_currency_code={this.props.statement.statement_currency_code}
                entityData={this.props.statement.entityData}
              />
            </Card.Body>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statement: state.statements.statement,
  };
};

export default connect(mapStateToProps, {
  mergeStatementsAction,
})(Merge);

Merge.propTypes = {
  mergeStatementsAction: PropTypes.func,
  statement: PropTypes.object,
};
