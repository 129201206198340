import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import Content from "../Content";
import PieChartTransactionsStatusesCount from "components/PieChart/TransactionsStatusesCount";
import PieChartShopTransactionsStatusesCount from "components/PieChart/ShopTransactionsStatusesCount";
import LineChartTransactionsAmount from "components/LineChart/TransactionsAmount";
import LineChartShopTransactionsAmount from "components/LineChart/ShopTransactionsAmount";
import TotalProcessed from "components/TotalProcessed";
import Holds from "components/Holds";
import ShopTotalProcessed from "components/ShopTotalProcessed";
import { getAllShops } from "../../actions/shops";
import PropTypes from "prop-types";
import ShopHolds from "../../components/ShopHolds";

class MerchantDashboard extends Component {
  componentDidMount = async () => {
    await this.props.getAllShops();
  };

  render() {
    return (
      <Content>
        <Row>
          <h2 style={{ margin: "10px 0 5px 20px" }}>Total</h2>
        </Row>
        <Row>
          <Col lg={3} md={4} sm={12}>
            <Card>
              <Card.Header as="h5">Transactions statuses</Card.Header>
              <Card.Body>
                <PieChartTransactionsStatusesCount />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={8} sm={12}>
            <Card>
              <Card.Header as="h5">Transactions amount</Card.Header>
              <Card.Body>
                <LineChartTransactionsAmount />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={12} sm={12}>
            <Card>
              <Card.Header as="h5">Total</Card.Header>
              <Card.Body>
                <TotalProcessed className={"card-small-content"} />
              </Card.Body>
            </Card>
            <Card>
              <Card.Header as="h5">Hold</Card.Header>
              <Card.Body>
                <Holds className={"card-small-content"} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {this.props.count !== 0 ? (
          <React.Fragment>
            <Row>
              <h2 style={{ margin: "10px 0 5px 20px" }}>Shops</h2>
            </Row>
            <Row>
              <Tabs
                defaultActiveKey={0}
                transition={false}
                style={{ marginLeft: "23px", fontSize: "13px" }}
              >
                <br />
                {this.props.shops.map((shop, index) => {
                  return (
                    <Tab
                      eventKey={index}
                      key={shop.name}
                      title={shop.name}
                      mountOnEnter={true}
                      style={{ position: "inline-block" }}
                      id={shop.guid}
                    >
                      <Row>
                        <Col
                          lg={3}
                          md={4}
                          sm={12}
                          style={{ paddingLeft: "0", paddingRight: "30px" }}
                        >
                          <Card>
                            <Card.Header as="h5">
                              Transactions statuses
                            </Card.Header>
                            <Card.Body>
                              <PieChartShopTransactionsStatusesCount
                                shopGuid={shop.guid}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col
                          lg={6}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "0", paddingRight: "20px" }}
                        >
                          <Card>
                            <Card.Header as="h5">
                              Amount of transactions
                            </Card.Header>
                            <Card.Body>
                              <LineChartShopTransactionsAmount
                                shopGuid={shop.guid}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col
                          lg={3}
                          md={12}
                          sm={12}
                          style={{ paddingLeft: "10px" }}
                        >
                          <Card>
                            <Card.Header as="h5">
                              Processed transactions amount
                            </Card.Header>
                            <Card.Body>
                              <ShopTotalProcessed
                                className={"card-small-content"}
                                shopGuid={shop.guid}
                              />
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Header as="h5">Holds</Card.Header>
                            <Card.Body>
                              <ShopHolds
                                className={"card-small-content"}
                                shopGuid={shop.guid}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab>
                  );
                })}
              </Tabs>
            </Row>
          </React.Fragment>
        ) : (
          <Row>
            <h2 style={{ margin: "10px 0 5px 20px" }}>There are no shops</h2>
          </Row>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shops.shopsList,
    count: state.shops.count,
  };
};

export default connect(mapStateToProps, {
  getAllShops,
})(MerchantDashboard);

MerchantDashboard.propTypes = {
  count: PropTypes.number,
  getAllShops: PropTypes.func,
  shops: PropTypes.array,
};
