import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Form, Button } from "react-bootstrap";
import { getAllGateways, getGatewayAction } from "actions/gateways";
import { addShopTerminalAction } from "actions/shops";
import MultiSelect from "components/UI/MultiSelect";
import PropTypes from "prop-types";
import { showModal } from "actions/modal";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import Joi from "joi-browser";
import { getAllRates } from "../../../actions/rates";
import Alert from "../../UI/Alert";

class TerminalCreator extends Component {
  state = {
    name: "",
    billing_descriptor: "",
    routing_string: "",
    payment_amount_limit: "",
    monthly_amount_limit: "",
    transaction_count_limit: "",
    supported_brands: "",
    supported_brandsOption: "",
    currency: "",
    currencyOption: "",
    gateway_guid: "",
    rate_guid: "",
    gatewayOption: "",
    enabled: false,
    three_d: false,
    enable_checkout: false,
    checkout_method: "",
    antifraud_monitor: false,
    antifraud_monitor_value: "",
    currencies: [],
    isLoading: false,
    errors: {},

    gateway_guidValidation: false,
    nameValidation: false,
    currencyValidation: false,
    billing_descriptorValidation: false,
    routing_stringValidation: false,
    payment_amount_limitValidation: false,
    monthly_amount_limitValidation: false,
    supported_brandsValidation: false,
    checkout_methodValidation: false,
    antifraud_monitor_valueValidation: false,
    transaction_count_limitValidation: false
  };

  schema = {
    name: Joi.string().required().label("Name"),
    gateway_guid: Joi.string().required().label("Gateway"),
    rate_guid: Joi.string().required().label("Rate"),
    currency: Joi.string().required().label("Currency"),
    billing_descriptor: Joi.string().required().label("Billing descriptor"),
    routing_string: Joi.string().required().label("Routing string"),
    payment_amount_limit: Joi.string().required().label("Payment amount limit"),
    monthly_amount_limit: Joi.string().required().label("Monthly amount limit"),
    supported_brands: Joi.string().required().label("Supported brands"),
    checkout_method: Joi.string().required().label("Checkout method"),
    antifraud_monitor_value: Joi.number()
      .max(100)
      .min(0)
      .required()
      .label("Antifraud monitor value"),
    transaction_count_limit: Joi.number()
      .required()
      .label("Transaction count limit")
  };

  componentDidMount = async () => {
    await this.props.getAllGateways();
  };

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        name: this.state.name,
        rate_guid: this.state.rate_guid,
        gateway_guid: this.state.gateway_guid,
        currency: this.state.currency,
        billing_descriptor: this.state.billing_descriptor,
        routing_string: this.state.routing_string,
        payment_amount_limit: this.state.payment_amount_limit,
        monthly_amount_limit: this.state.monthly_amount_limit,
        transaction_count_limit: this.state.transaction_count_limit,
        supported_brands: this.state.supported_brands,
        checkout_method: this.state.checkout_method,
        antifraud_monitor_value: this.state.antifraud_monitor_value
      },
      this.schema,
      options
    );

    if (
      !error &&
      this.state.payment_amount_limit <= this.state.monthly_amount_limit
    )
      return null;

    const errors = {};
    if (this.state.payment_amount_limit > this.state.monthly_amount_limit) {
      errors.payment_amount_limit =
          "\"Payment amount limit\" must be less than Monthly amount limit";
    }

    if (error) {
      let errorsArray = [ ...error.details ];
      for (let item of errorsArray) errors[item.path[0]] = item.message;
    }
   
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = name => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  onCurrencySelect = async option => {
    const errors = { ...this.state.errors };
    delete errors.currency;

    await this.setState({
      currency: option.guid,
      currencyOption: option,
      currencyValidation: true,
      errors
    });
  };

  onGatewaySelect = async option => {
    const errors = { ...this.state.errors };
    delete errors.gateway_guid;

    await this.setState({
      gateway_guid: option.guid,
      gateway_guidValidation: true,
      gatewayOption: option,
      rateOption: {},
      currencyOption: {},
      errors
    });
    await this.props.getAllRates({ gateway_guid: option.guid });

    await this.props.getGatewayAction(option.guid);
    this.setState({
      currencies: this.props.gateway.currencies
        ? this.props.gateway.currencies
        : []
    });
  };

  onRateSelect = async option => {
    const errors = { ...this.state.errors };
    delete errors.rate_guid;

    await this.setState({
      rate_guid: option.guid,
      rate_guidValidation: true,
      rateOption: option,
      errors
    });
  };

  onBrandsSelect = async options => {
    const errors = { ...this.state.errors };
    if (options) {
      delete errors.supported_brands;
    }
    let supported_brands = "";
    await options.forEach(option => {
      supported_brands += option.name + " ";
    });

    this.setState({
      supported_brands,
      supported_brandsOption: options,
      supported_brandsValidation: errors.supported_brands ? false : true,
      errors
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      [input.name]: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true
    });
  };

  handleChangeLimit = ({ currentTarget: input }) => {
    const property = input.name;
    let value = input.value;
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[property] = errorMessage;
    else delete errors[property];

    value = value.replace(".", "");
    let start = value.substr(0, value.length - 2);
    let middle = ".";
    let finish = value.substr(value.length - 2, value.length);
    value = start + middle + finish;
    switch (value.length) {
      case 2: {
        value = value[value.length - 1] + ".00";
        break;
      }
      case 3: {
        value = "0" + value;
        break;
      }
      default: {
        if (
          property.substr(property.length - 7, property.length) === "percent" &&
          +value >= 100
        ) {
          value = "99.99";
        }
        if (value[0] === "0") {
          value = value.substr(1, value.length - 1);
        }
        if (input.value.length === 0) {
          value = "";
        }
        break;
      }
    }

    this.setState({
      [property]: value,
      errors,
      [property + "Validation"]: errorMessage ? false : true
    });
  };

  handleCheck = async e => {
    await this.setState({
      [e.target.name]: e.target.checked ? true : false
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      const enabled = this.state.enabled ? true : false;
      let data = {
        name: this.state.name,
        billing_descriptor: this.state.billing_descriptor,
        routing_string: this.state.routing_string,
        payment_amount_limit: this.state.payment_amount_limit.replace(".", ""),
        monthly_amount_limit: this.state.monthly_amount_limit.replace(".", ""),
        transaction_count_limit: this.state.transaction_count_limit,
        supported_brands: this.state.supported_brands,
        currency_guid: this.state.currency,
        gateway_guid: this.state.gateway_guid,
        rate_guid: this.state.rate_guid,
        enabled: enabled,
        three_d: this.state.three_d,
        enable_checkout: this.state.enable_checkout,
        checkout_method: this.state.checkout_method,
        antifraud_monitor: this.state.antifraud_monitor,
        antifraud_monitor_value: this.state.antifraud_monitor_value
      };
      try {
        await this.props.addShopTerminalAction(this.props.guid, data);
        swal({
          title: "Terminal is created",
          icon: "success",
          button: false,
          timer: 2000
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;

    const gateways = this.props.gateways ? this.props.gateways : [];
    const rates = this.props.rates ? this.props.rates : [];

    let currencies;
    if (this.state.currencies !== []) currencies = this.state.currencies;
    else currencies = [];
    currencies.forEach(currency => {
      currency.name = currency.code;
    });
    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off">
        <Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Name: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group validationState={this.formValidation("name")}>
                  <Form.Control
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  {errors.name && (
                    <span className="validate-error">{errors.name}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Gateway: *</Form.Label>
              </Col>
              <Col md={7}>
                {this.props.gatewaysLoading ? (
                  <div>
                    <ReactLoading type="cylon" color="grey" width="50px" />
                  </div>
                ) : (
                  <Form.Group>
                    <MultiSelect
                      name="gateways"
                      options={gateways}
                      value={this.state.gatewayOption}
                      multi={false}
                      onSelect={this.onGatewaySelect}
                      // defaultValue={this.state.gateway_guid}
                    />
                    {errors.gateway_guid && (
                      <span className="validate-error">
                        {errors.gateway_guid}
                      </span>
                    )}
                  </Form.Group>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Rate: *</Form.Label>
              </Col>
              <Col md={7}>
                {this.props.ratesLoading || this.props.gatewayLoading ? (
                  <div>
                    <ReactLoading type="cylon" color="grey" width="50px" />
                  </div>
                ) : (
                  <Form.Group>
                    <MultiSelect
                      name="rate"
                      options={rates}
                      value={this.state.rateOption}
                      multi={false}
                      onSelect={this.onRateSelect}
                    />
                    {errors.rate_guid && (
                      <span className="validate-error">{errors.rate_guid}</span>
                    )}
                  </Form.Group>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Currency: *</Form.Label>
              </Col>
              <Col md={7}>
                {this.props.ratesLoading || this.props.gatewayLoading ? (
                  <div>
                    <ReactLoading type="cylon" color="grey" width="50px" />
                  </div>
                ) : (
                  <Form.Group>
                    <MultiSelect
                      name="currencies"
                      options={currencies}
                      value={this.state.currencyOption}
                      multi={false}
                      onSelect={this.onCurrencySelect}
                      // defaultValue={this.state.currency}
                    />
                    {errors.currency && (
                      <span className="validate-error">{errors.currency}</span>
                    )}
                  </Form.Group>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>3d: *</Form.Label>
              </Col>
              <Col md={1}>
                <Form.Group>
                  <input
                    name="three_d"
                    type="checkbox"
                    checked={this.state.three_d}
                    onChange={this.handleCheck}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Payment amount limit: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group
                  validationState={this.formValidation("payment_amount_limit")}
                >
                  <Form.Control
                    name="payment_amount_limit"
                    type="number"
                    value={this.state.payment_amount_limit}
                    onChange={this.handleChangeLimit}
                  />
                  {errors.payment_amount_limit && (
                    <span className="validate-error">
                      {errors.payment_amount_limit}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Monthly amount limit: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group
                  validationState={this.formValidation("monthly_amount_limit")}
                >
                  <Form.Control
                    name="monthly_amount_limit"
                    type="number"
                    value={this.state.monthly_amount_limit}
                    onChange={this.handleChangeLimit}
                  />
                  {errors.monthly_amount_limit && (
                    <span className="validate-error">
                      {errors.monthly_amount_limit}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Transaction count limit: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group
                  validationState={this.formValidation(
                    "transaction_count_limit"
                  )}
                >
                  <Form.Control
                    name="transaction_count_limit"
                    type="number"
                    value={this.state.transaction_count_limit}
                    onChange={this.handleChange}
                  />
                  {errors.transaction_count_limit && (
                    <span className="validate-error">
                      {errors.transaction_count_limit}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Col>

          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Billing descriptor: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group
                  validationState={this.formValidation("billing_descriptor")}
                >
                  <Form.Control
                    name="billing_descriptor"
                    type="text"
                    value={this.state.billing_descriptor}
                    onChange={this.handleChange}
                  />
                  {errors.billing_descriptor && (
                    <span className="validate-error">
                      {errors.billing_descriptor}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Routing string: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group
                  validationState={this.formValidation("routing_string")}
                >
                  <Form.Control
                    name="routing_string"
                    type="text"
                    value={this.state.routing_string}
                    onChange={this.handleChange}
                  />
                  {errors.routing_string && (
                    <span className="validate-error">
                      {errors.routing_string}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Supported brands: *</Form.Label>
              </Col>
              <Col md={7}>
                {/* <Form.Group validationState={this.formValidation(this.state.supported_brands)}>
                        <Form.Control
                          name="supported_brands"
                          type="text"
                          value={this.state.supported_brands}
                          onChange={this.handleChange}
                        />
                      </Form.Group> */}
                <Form.Group>
                  <MultiSelect
                    options={[
                      { name: "Visa", guid: "Visa" },
                      { name: "MasterCard", guid: "MasterCard" },
                      { name: "MIR", guid: "MIR" }
                    ]}
                    multi={true}
                    onSelect={this.onBrandsSelect}
                    name="supported_brands"
                    value={this.state.supported_brandsOption}
                  />
                  {errors.supported_brands && (
                    <span className="validate-error">
                      {errors.supported_brands}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Enabled: *</Form.Label>
              </Col>
              <Col md={1}>
                <Form.Group>
                  <input
                    name="enabled"
                    type="checkbox"
                    checked={this.state.enabled}
                    onChange={this.handleCheck}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Checkout method: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group
                  validationState={this.formValidation("checkout_method")}
                >
                  <Form.Control
                    name="checkout_method"
                    type="text"
                    value={this.state.checkout_method}
                    onChange={this.handleChange}
                  />
                  {errors.checkout_method && (
                    <span className="validate-error">
                      {errors.checkout_method}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Enabled checkout: *</Form.Label>
              </Col>
              <Col md={1}>
                <Form.Group>
                  <input
                    name="enable_checkout"
                    type="checkbox"
                    checked={this.state.enable_checkout}
                    onChange={this.handleCheck}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Antifraud monitor value: *</Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group
                  validationState={this.formValidation(
                    "antifraud_monitor_value"
                  )}
                >
                  <Form.Control
                    name="antifraud_monitor_value"
                    type="text"
                    value={this.state.antifraud_monitor_value}
                    onChange={this.handleChange}
                  />
                  {errors.antifraud_monitor_value && (
                    <span className="validate-error">
                      {errors.antifraud_monitor_value}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>Antifraud monitor: *</Form.Label>
              </Col>
              <Col md={1}>
                <Form.Group>
                  <input
                    name="antifraud_monitor"
                    type="checkbox"
                    checked={this.state.antifraud_monitor}
                    onChange={this.handleCheck}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className={
                this.state.gateway_guidValidation &&
                this.state.currencyValidation &&
                this.state.billing_descriptorValidation &&
                this.state.routing_stringValidation &&
                this.state.payment_amount_limitValidation &&
                this.state.monthly_amount_limitValidation &&
                this.state.supported_brandsValidation &&
                this.state.checkout_methodValidation &&
                this.state.antifraud_monitor_valueValidation &&
                this.state.transaction_count_limitValidation
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
            >
              Save
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    gateway: state.gateways.gateway,
    gateways: state.gateways.gatewaysList,
    rates: state.rates.ratesList,
    gatewaysLoading: state.gateways.gatewaysLoading,
    gatewayLoading: state.gateways.gatewayLoading,
    ratesLoading: state.rates.ratesLoading
  };
};

export default connect(mapStateToProps, {
  getGatewayAction,
  getAllGateways,
  addShopTerminalAction,
  getAllRates,
  showModal
})(TerminalCreator);

TerminalCreator.propTypes = {
  addShopTerminalAction: PropTypes.func,
  gateway: PropTypes.object,
  gatewayLoading: PropTypes.bool,
  gateways: PropTypes.array,
  gatewaysLoading: PropTypes.bool,
  getAllGateways: PropTypes.func,
  getAllRates: PropTypes.func,
  getGatewayAction: PropTypes.func,
  guid: PropTypes.string,
  rates: PropTypes.array,
  ratesLoading: PropTypes.bool,
  showModal: PropTypes.func
};
