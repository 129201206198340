import React, { Component } from "react";
import { connect } from "react-redux";
import { getStatementAction } from "actions/statements";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Content from "views/Content";
import { parseResponse } from "helpers/parseResponse";
import MergedStatementDetail from "./Components/MergedStatementDetail";
import StatementDetail from "./Components/StatementDetail";
import Alert from "../UI/Alert";

class StatementCommonDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statementLoaded: false,
    };
  }

  componentDidMount = async () => {
    try {
      const guid = this.props.match.params.id;
      await this.props.getStatementAction(guid);

      this.setState({
        statementLoaded: true,
      });
    } catch (error) {
      this.setState({ isLoading: false, transactionsLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    if (!this.state.statementLoaded || this.props.statementLoading)
      return (
        <Content>
          <Spinner />
        </Content>
      );
    else
      return (
        this.state.statementLoaded && (
          <>
            {this.props.statement.is_payable_statement_flag ? (
              <MergedStatementDetail
                statement
                guid={this.props.match.params.id}
              />
            ) : (
              <StatementDetail statement guid={this.props.match.params.id} />
            )}
          </>
        )
      );
  }
}

const mapStateToProps = (state) => {
  return {
    statement: state.statements.statement,
    statementLoading: state.statements.statementLoading,
  };
};

export default connect(mapStateToProps, {
  getStatementAction,
})(StatementCommonDetail);

StatementCommonDetail.propTypes = {
  getStatementAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  statement: PropTypes.object,
  statementLoading: PropTypes.bool,
};
