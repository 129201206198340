import React, { Component } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { showModal } from "actions/modal";
import { addRateAction, getRateAction } from "actions/rates";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import { getTransactionTemplatesAction } from "actions/transactions";
import Spinner from "components/UI/Spinner";
import PropTypes from "prop-types";
import MultiSelect from "components/UI/MultiSelect";
import { getAllGateways } from "actions/gateways";
import Joi from "joi-browser";

class RateEditor extends Component {
  state = {
    name: "",
    gateway_guid: "",
    isLoading: false,
    reason: "",
    errors: {},
    needReason: false,
    oldData: {},
  };

  schema = {
    name: Joi.string().required().label("Name"),
    gateway_guid: Joi.string().required().label("Gateway"),
    reason: Joi.string().required().label("Reason"),
  };

  componentDidMount = async () => {
    await this.props.getRateAction(this.props.rate_guid);
    this.setState({
      name: this.props.rate.name,
      gateway_guid: this.props.rate.gateway_guid,
      oldData: {
        name: this.props.rate.name,
        gateway_guid: this.props.rate.gateway_guid,
      },
    });

    await this.props.getAllGateways();
    this.setState({
      gatewayOption: this.props.gateways.filter(
        (gateway) => gateway.guid === this.props.rate.gateway_guid
      ),
    });
  };

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        name: this.state.name,
        gateway_guid: this.state.gateway_guid,
        reason: this.state.reason,
      },
      this.schema,
      options
    );

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleChangeName = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({ [input.name]: input.value, errors }, () =>
      this.showReason()
    );
  };

  onGatewaySelect = async (option) => {
    const errors = { ...this.state.errors };
    delete errors.gateway_guid;
    this.setState(
      {
        gateway_guid: option.guid,
        gateway_guidValidation: true,
        gatewayOption: option,
        currencyOption: {},
        errors,
      },
      () => this.showReason()
    );
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = {
      name: this.state.name,
      gateway_guid: this.state.gateway_guid,
    };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      guid: this.props.rate.guid,
      gateway_guid: this.state.gateway_guid,
      name: this.state.name,
      reason: this.state.reason,
    };
    let errors = this.validate();
    this.setState({ errors: { ...this.state.errors, ...errors } || {} });
    errors = { ...this.state.errors, ...errors };
    if (Object.keys(errors).length !== 0) {
      swal({
        title: "Something wrong. Please, fill in all the fields",
        icon: "error",
        button: false,
        timer: 2000,
      });
    } else {
      this.setState({ isLoading: true });
      try {
        await this.props.addRateAction(
          data
          // this.props.currentPage,
          // this.props.pageSize,
          // this.props.ratesSearch
        );
        swal({
          title: "Rate is updated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        swal({
          title: parsedError.error,
          text: parsedError.message,
          icon: "error",
        });
      }
    }
  };

  render() {
    const gateways = this.props.gateways ? this.props.gateways : [];

    if (this.props.rateLoading || this.props.gatewaysLoading)
      return <Spinner />;
    else
      return (
        <Form autoComplete="off" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Gateway*:</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group>
                <MultiSelect
                  name="gateways"
                  options={gateways}
                  value={this.state.gatewayOption}
                  multi={false}
                  onSelect={this.onGatewaySelect}
                />
              </Form.Group>
              {this.state.errors.gateway_guid && (
                <span className="validate-error">
                  {this.state.errors.gateway_guid}
                </span>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Name*:</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("name")}>
                <Form.Control
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.handleChangeName}
                />
              </Form.Group>
              {this.state.errors.name && (
                <span className="validate-error">{this.state.errors.name}</span>
              )}
            </Col>
          </Row>

          {this.state.needReason && (
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Reason*:</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("reason")}>
                  <Form.Control
                    name="reason"
                    type="text"
                    value={this.state.reason}
                    onChange={this.handleChangeReason}
                  />
                </Form.Group>
                {this.state.errors.reason && (
                  <span className="validate-error">
                    {this.state.errors.reason}
                  </span>
                )}
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button className="btn btn-fill" type="submit">
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    templatesList: state.transactions.templatesList,
    templatesLoading: state.transactions.templatesLoading,
    currentPage: state.rates.currentPage,
    pageSize: state.rates.pageSize,
    rate: state.rates.rate,
    rates: state.rates.ratesList,
    ratesSearch: state.search.ratesSearch,
    rateLoading: state.rates.rateLoading,
    gateways: state.gateways.gatewaysList,
    gatewaysLoading: state.gateways.gatewaysLoading,
  };
};

export default connect(mapStateToProps, {
  addRateAction,
  getRateAction,
  showModal,
  getTransactionTemplatesAction,
  getAllGateways,
})(RateEditor);

RateEditor.propTypes = {
  addRateAction: PropTypes.func,
  currentPage: PropTypes.number,
  gateways: PropTypes.array,
  gatewaysLoading: PropTypes.bool,
  getAllGateways: PropTypes.func,
  getRateAction: PropTypes.func,
  getTransactionTemplatesAction: PropTypes.func,
  pageSize: PropTypes.number,
  rate: PropTypes.object,
  rateLoading: PropTypes.bool,
  rate_guid: PropTypes.string,
  ratesSearch: PropTypes.string,
  showModal: PropTypes.func,
  templatesList: PropTypes.array,
  templatesLoading: PropTypes.bool,
};
