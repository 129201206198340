import PropTypes from "prop-types";
import React, { Component } from "react";
import TableBody from "./Body";
import TableEmptyBody from "./EmptyBody";
import TableHeader from "./Header/index";
import SearchPanel from "./SearchPanel/index";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.data !== this.props.data)
  //     setTimeout(() => this.setState({ loading: false }), 500);
  // }

  deleteEmptyColumns = (columns) => {
    return columns.filter((column) => column);
  };

  render() {
    const columns = this.deleteEmptyColumns(this.props.columns);
    const searchColumns = this.props.searchColumns;
    const { data } = this.props;
    const { foot, tfootData, keyPath, collapsedHeader, collapse, tableWidth } =
      this.props;
    const {
      sortKey,
      isSortReverse,
      onSort,
      search,
      handleSortReset,
      disableSearch,
      get,
      searchData,
      updateData,
      updateCurrentPage,
      disableSort,
    } = this.props;

    if ((data && !data.length) || !data)
      return (
        <>
          <SearchPanel
            style={tableWidth ? { width: tableWidth + "%" } : undefined}
            searchColumns={searchColumns}
            search={search}
            get={get}
            searchData={searchData}
            updateData={updateData}
            updateCurrentPage={updateCurrentPage}
          />
          <table
            className="table"
            style={tableWidth ? { width: tableWidth + "%" } : undefined}
          >
            <TableHeader
              columns={columns}
              onSort={onSort}
              sortKey={sortKey}
              isSortReverse={isSortReverse}
              search={search}
              disableSearch={disableSearch}
              get={get}
              searchData={searchData}
              updateData={updateData}
              updateCurrentPage={updateCurrentPage}
              disableSort={disableSort}
              collapsedHeader={collapsedHeader}
            />
          </table>
          <TableEmptyBody />
        </>
      );
    return (
      <React.Fragment>
        <SearchPanel
          style={tableWidth ? { width: tableWidth + "%" } : undefined}
          searchColumns={searchColumns}
          search={search}
          get={get}
          searchData={searchData}
          updateData={updateData}
          updateCurrentPage={updateCurrentPage}
        />
        <table
          className="table"
          style={tableWidth ? { width: tableWidth + "%" } : undefined}
        >
          {this.props.columnsComponent ? (
            <colgroup>{this.props.columnsComponent}</colgroup>
          ) : null}
          <TableHeader
            columns={columns}
            onSort={onSort}
            handleSortReset={handleSortReset}
            sortKey={sortKey}
            isSortReverse={isSortReverse}
            search={search}
            disableSearch={disableSearch}
            get={get}
            searchData={searchData}
            updateData={updateData}
            updateCurrentPage={updateCurrentPage}
            disableSort={disableSort}
            collapsedHeader={collapsedHeader}
          />
          <TableBody
            columns={columns}
            data={data}
            foot={foot}
            tfootData={tfootData}
            keyPath={keyPath}
            collapse={collapse}
          />
        </table>
      </React.Fragment>
    );
  }
}

export default Table;

Table.propTypes = {
  collapse: PropTypes.bool,
  collapsedHeader: PropTypes.array,
  columns: PropTypes.array,
  columnsComponent: PropTypes.array,
  data: PropTypes.array,
  disableSearch: PropTypes.bool,
  disableSort: PropTypes.bool,
  foot: PropTypes.bool,
  get: PropTypes.func,
  isSortReverse: PropTypes.bool,
  keyPath: PropTypes.string,
  onSort: PropTypes.func,
  search: PropTypes.func,
  searchData: PropTypes.object,
  sortKey: PropTypes.string,
  tfootData: PropTypes.array,
  updateCurrentPage: PropTypes.func,
  updateData: PropTypes.func,
};
