import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

const activePath = (path, location) => {
  return location.includes(path) ? "active" : "";
};

const SidebarItem = ({ item, key, isHide, location }) => {
  const hideClass = isHide ? "slideOut" : "slideIn";
  return (
    <li
      className={activePath(item.path, location) + " nav-item " + hideClass}
      key={key}
    >
      <NavLink
        title={isHide ? item.name : ""}
        to={item.path}
        className={isHide ? "nav-link slideOut" : "nav-link slideIn"}
        activeClassName="active"
        //id sidebar-item
        // style={{ height: "50px", width: isHide ? "50px" : "" }}
      >
        {/* <i
          id="sidebar-icon"
          className={isHide ? `${item.icon} slideOut` : `${item.icon} slideIn`}
        /> */}

        <i
          className={
            isHide
              ? item.icon + " menu-icon slideOut"
              : item.icon + " menu-icon slideIn"
          }
        />
        <span className={isHide ? "menu-title slideOut" : "menu-title slideIn"}>
          {item.name}
        </span>
      </NavLink>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    isHide: state.sidebar.isHide,
    location: window.location.hash,
  };
};

export default connect(mapStateToProps, null)(SidebarItem);

SidebarItem.propTypes = {
  isHide: PropTypes.bool,
  item: PropTypes.object,
  key: PropTypes.string,
  location: PropTypes.string,
};
