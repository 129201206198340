import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";

function StatementCurrencySelect({
  currencies = [],
  value,
  callback }) {


  const onSelect = (options) => {
    callback && callback(options);
  };

  return (
    <Row >
      <Col md={3} sm={4} xs={4} className="form-label">
        <Form.Label>Statement currency*</Form.Label>
      </Col>
      <Col md={8} sm={7} xs={7}>
        <Form.Group>
          <MultiSelect
            name="statement_currency"
            value={value}
            options={currencies}
            multi={false}
            onSelect={onSelect}
            placeholder="Select statement currency"
          />
          {/* {errors.statement_currency && (
            <span className="validate-error">
              {errors.statement_currency}
            </span>
          )} */}
        </Form.Group>
      </Col>
    </Row>
  );
}

export default StatementCurrencySelect;