import * as types from "../constants/actionTypes";

const initialState = {
  newLoginsCount: 0,
  login: {
    guid: "",
    username: "",
    username_canonical: "",
    email: "",
    email_canonical: "",
    enabled: false,
    last_login: "",
    locked: false,
    expired: false,
    expires_at: "",
    credentials_expired: false,
    credentials_expire_at: "",
    phone: "",
    enabled_api: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: ""
  },
  loginsLoading: true,
  userProfile: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    note: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: ""
  },
  loginsList: [],
  freeLoginsList: [],
  loginRoles: []
};

export default function logins(state = initialState, action) {
  switch (action.type) {
    case types.GET_LOGINS:
      return {
        ...state, 
        loginsLoading: true,
        error: false
      };
    case types.GET_LOGINS_SUCCEED:
      return {
        ...state, 
        loginsList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        loginsLoading: false,
        error: false
      };
    case types.GET_LOGINS_FAILED:
      return {
        ...state, 
        loginsLoading: false,
        error: true
      };
    case types.GET_FREE_LOGINS:
      return {
        ...state, 
        freeLoginsLoading: true,
        error: false
      };
    case types.GET_FREE_LOGINS_SUCCEED:
      return {
        ...state, 
        freeLoginsList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        freeLoginsLoading: false,
        error: false
      };
    case types.GET_FREE_LOGINS_FAILED:
      return {
        ...state, 
        freeLoginsLoading: false,
        error: true
      };
    case types.GET_LOGIN:
      return {
        ...state,
        loginLoading: true,
        error: false
      };
    case types.GET_LOGIN_SUCCEED:
      return {
        ...state,
        login: action.login,
        loginLoading: false,
        error: false
      };
    case types.GET_LOGIN_FAILED:
      return {
        ...state,
        loginLoading: false,
        error: true
      };
    case types.GET_LOGIN_USER_PROFILE:
      return {
        ...state,
        userProfileLoading: true,
        error: false
      };
    case types.GET_LOGIN_USER_PROFILE_SUCCEED:
      return {
        ...state,
        userProfile: action.userProfile,
        userProfileLoading: false,
        error: false
      };
    case types.GET_LOGIN_USER_PROFILE_FAILED:
      return {
        ...state,
        userProfileLoading: false,
        error: true
      };
    case types.GET_LOGIN_ROLES:
      return { 
        ...state, 
        loginRolesLoading: true 
      };
    case types.GET_LOGIN_ROLES_SUCCEED:
      return { 
        ...state, 
        loginRoles: action.data.data, 
        loginRolesCount: action.data.count,
        loginRolesLoading: false 
      };
    case types.GET_LOGIN_ROLES_FAILED:
      return { 
        ...state, 
        loginRolesLoading: false 
      };
    case types.DELETE_LOGIN:{
      let newLoginsList = state.loginsList.filter(login => login.guid !== action.login.guid);
      return { ...state, loginsList: newLoginsList };}
    case types.ADD_LOGIN:
      return { ...state, loginGuid: action.login.guid };
    case types.ADD_LOGIN_ROLE:
      return { ...state, loginRoles: [ ...state.loginRoles, action.loginRole ] };
    case types.EDIT_LOGIN:{
      let newLoginsList = state.loginsList;
      newLoginsList = newLoginsList.map(login => {
        if (login.guid === action.login.guid)
          return action.login;
        else
          return login;
      });
      return { ...state, loginsList: newLoginsList };}
    case types.GET_NEW_LOGINS_COUNT:
      return { 
        ...state, 
        newLoginsCountLoading: true 
      };
    case types.GET_NEW_LOGINS_COUNT_SUCCEED:
      return { 
        ...state, 
        newLoginsCount: action.data.count,
        newLoginsCountLoading: false 
      };
    case types.GET_NEW_LOGINS_COUNT_FAILED:
      return { 
        ...state, 
        newLoginsCountLoading: false 
      };
    default:
      return state;
  }
}