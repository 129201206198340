import * as types from "../constants/actionTypes";

export default (state = [], action) => {
  switch (action.type) {
    case types.SHOW_MANAGER_MODAL:
      return {
        ...state,
        modalManagerState: action.managerModalState,
        type: action.type
      };
    case types.SHOW_MODAL:
      return {
        ...state,
        modalState: action.modalState,
        type: action.type
      };
    default:
      return state;
  }
};