import { backendManagement } from "../backendPlatform";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";

const token = getAuthData();

export const getBlackListItems = (args) => {
  return backendManagement.get("/blacklists", { params: { ...args } });
};

export const getBlackListItem = (guid) => {
  return backendManagement.get(`/blacklists/${guid}`);
};

export const createBlackListRule = (data) => {
  return backendManagement.post("/blacklists/values", data);
};

export const createBlackListItem = data => {
  return backendManagement.post("/blacklists", data);
};

export const updateBlackListItem = data => {
  return backendManagement.post("/blacklists", data);
};

export const deleteBlackListItem = ({ guid, reason }) => {
  return backendManagement.post("/blacklists", { guid, delete: true, reason });
};

export const getBlackListGlobal = (args) => {
  return backendManagement.get("/blacklists/global", { params: { ...args } });
};

export const getMerchantsBlackList = (args) => {
  if (token && token.userPayload && token.userPayload.merchant)
    return backendManagement.get(`merchants/${token.userPayload.merchant.merchant_guid}/blacklists`, { params: { ...args } });
  else
    return backendManagement.get("/blacklists/merchants", { params: { ...args } });
};

export const createItemGlobalBlackList = data => {
  return backendManagement.post("/blacklists/global", data);
};

export const deleteItemGlobalBlackList = ({ guid, reason }) => {
  return backendManagement.post("/blacklists/global", { blacklist_rule_guid: guid, delete: true, reason });
};

export const createItemMerchantsBlackList = data => {
  if (token && token.userPayload && token.userPayload.merchant) {
    return backendManagement.post(`merchants/${token.userPayload.merchant.merchant_guid}/blacklists`, data);
  }   
  else
    return backendManagement.post("/blacklists/merchants", data);
};

export const deleteItemMerchantsBlackList = (guid, reason) => {
  return backendManagement.post("/blacklists/merchants", { guid, delete: true, reason });
};