import * as types from "../constants/actionTypes";

export function showManagerModal(managerModalState) {
  return { type: types.SHOW_MANAGER_MODAL, managerModalState };
}

export function showModal(modalState) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MODAL, modalState });
    if (!modalState) {
      // dispatch({ type: types.RESET_GROUP_MANAGEMENT_DATA });
      // dispatch({ type: types.RESET_MERCHANT_MANAGEMENT_DATA });
    }
  };
}

export function resetModalData() {
  return { type: types.RESET_MODAL_DATA };
}
