import React, { Component } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { getAllCurrencies } from "../../actions/currencies";
import { showModal } from "../../actions/modal";
import { addGatewayAction } from "../../actions/gateways";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Alert from "../UI/Alert";

class GatewayCreator extends Component {
  state = {
    data: {
      name: "",
      description: "",
    },
    errors: {},
    isLoading: false,
  };

  schema = {
    name: Joi.string().required().min(0).trim().label("Name"),
    description: Joi.string().required().min(0).trim().label("Description"),
  };

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        ...this.state.data,
      },
      this.schema,
      options
    );

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  onChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  doSubmit = async () => {
    this.setState({ isLoading: true });
    const { name, description } = this.state.data;
    try {
      await this.props.addGatewayAction(
        {
          name,
          description,
        },
        this.props.currentPage,
        this.props.pageSize
      );
      swal({
        title: "Gateway is created",
        icon: "success",
        button: false,
        timer: 2000,
      });
      await this.props.handleClose();
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <Form autoComplete="off" onSubmit={this.handleSubmit}>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Name*</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("name")}>
              <Form.Control
                name="name"
                value={this.state.data.name}
                onChange={(e) => this.onChange(e)}
              />
              {errors.name && (
                <span className="validate-error">{errors.name}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Description*</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("description")}>
              <Form.Control
                name="description"
                value={this.state.data.description}
                onChange={(e) => this.onChange(e)}
              />
              {errors.description && (
                <span className="validate-error">{errors.description}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button
                  className="btn btn-fill btn-success"
                  type="submit"
                  onClick={this.doSubmit}
                >
                  Add
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageSize: state.gateways.pageSize,
    currentPage: state.gateways.currentPage,
  };
};

export default connect(mapStateToProps, {
  getAllCurrencies,
  showModal,
  addGatewayAction,
})(GatewayCreator);

GatewayCreator.propTypes = {
  addGatewayAction: PropTypes.func,
  showModal: PropTypes.func,
};
