import { getPartnerLogsAction } from "actions/logs";
import { reset, searchInPartnersLogs } from "actions/search";
import Modal from "components/UI/Modal";
import AbstractComponent from "factories/Main";
import { parseResponse } from "helpers/parseResponse.js";
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { resetPage, setNewTable } from "../../../actions/store";
import { cutGuid } from "../../../helpers/cutGuid";
import { getPartnerLogs } from "../../../services/paymentBackendAPI/logs/logs";
import Alert from "../../UI/Alert";
import { Copy } from "../../UI/CopyToClipboard";

const tryStringToJson = (str) => {
  try {
    return JSON.stringify(str, null, "\t");
  } catch (error) {
    const parsedError = parseResponse(error);
    Alert({ type: "error", message: parsedError.message });
  }
};

const Params = (data) => {
  return (
    <Card>
      <Card.Body>
        <div className="param-container">
          <span style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            {tryStringToJson(data)}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

const columns = [
  {
    path: "request_id",
    label: "Request guid",
    content: (log) => (
      <>
        <Copy text={log.request_id} />
        {cutGuid(log.request_id)}
      </>
    ),
  },
  {
    path: "createdAt",
    label: "Created at",
    content: (log) =>
      moment(log.createdAt).utcOffset(0).format("DD.MM.YYYY HH:mm:ss"),
    align: "center",
  },
  {
    path: "ip",
    label: "IP",
    align: "center",
  },
  {
    path: "guid",
    label: "Partner guid",
    content: (log) => (
      <>
        <Copy text={log.guid} />
        {cutGuid(log.guid)}
      </>
    ),
  },
  {
    path: "url",
    label: "URL",
    content: (log) => log.url.slice(7),
  },
  {
    path: "headers",
    label: "Headers",
    key: "headers",
    align: "center",
    content: (log) =>
      log.headers && log.headers !== "{}" ? (
        <Modal
          content={Params}
          contentProps={{ data: log.headers }}
          buttonName="Show"
          button={
            <button type="button" className="btn btn-table">
              Show
            </button>
          }
        />
      ) : null,
  },
  {
    path: "parameters",
    label: "Parameters",
    key: "parameters",
    align: "center",
    content: (log) =>
      log.parameters && log.parameters !== "{}" ? (
        <Modal
          content={Params}
          contentProps={{ data: log.parameters }}
          buttonName="Show"
          button={
            <button type="button" className="btn btn-table">
              Show
            </button>
          }
        />
      ) : null,
  },
  {
    path: "reqBody",
    label: "Request",
    key: "request",
    align: "center",
    content: (log) =>
      log.reqBody && log.reqBody !== "{}" ? (
        <Modal
          content={Params}
          contentProps={{ data: log.reqBody }}
          buttonName="Show"
          button={
            <button type="button" className="btn btn-table">
              Show
            </button>
          }
        />
      ) : null,
  },
  {
    path: "resBody",
    label: "Response",
    key: "response",
    align: "center",
    content: (log) =>
      log.resBody && log.resBody !== "{}" ? (
        <Modal
          content={Params}
          contentProps={{ data: log.resBody }}
          button={
            <button type="button" className="btn btn-table">
              Show
            </button>
          }
        />
      ) : null,
  },
  {
    path: "resStatusCode",
    label: "Status code",
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.logs.partnersLogs,
    count: state.logs.partnersLogsCount,
    table: state.store.table,
    loading: state.logs.usersLogsLoading,
    searchData: state.search.partnersLogsSearch,
    isSearch: state.search.isSearch,
    name: "Partners logs",
    keyPath: "request_id",
    columns,
    notAllSizePage: true,
    exportFunction: getPartnerLogs,
  };
};

export default connect(mapStateToProps, {
  get: getPartnerLogsAction,
  search: searchInPartnersLogs,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
