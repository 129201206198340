import React from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const SelectNumber = ({
  name,
  label,
  options,
  error,
  formValidation,
  ...rest
}) => {
  return (
    <Col className="col">
      <Form.Label>{label}</Form.Label>
      <Form.Group validationState={formValidation(error)}>
        <Form.Control {...rest} name={name} id={name} componentClass="select">
          <option value="" />
          {options
            ? options.map((option) => {
              return (
                <option key={option.guid} value={option.guid}>
                  {option.number}
                </option>
              );
            })
            : []}
        </Form.Control>
        {error && <span className="validate-error">{error}</span>}
      </Form.Group>
    </Col>
  );
};

export default SelectNumber;

SelectNumber.propTypes = {
  error: PropTypes.string,
  formValidation: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.node,
  options: PropTypes.array,
};
