import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./Input/index";
import Select from "./Select/index";
import SelectNumber from "./SelectNumber/index";

export default class CustomForm extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (errors, name) => {
    if (errors || this.state.data[name] === "") return "error";
    else return "success";
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  renderButton(label) {
    const validateButton = this.validate() ? true : false;
    return (
      <button
        className={
          !validateButton ? "btn btn-fill btn-success" : "btn btn-fill"
        }
      >
        {label}
      </button>
    );
  }

  renderLabel(label) {
    return <h3>{label}</h3>;
  }

  renderTextarea(name, label, type = "text", componentClass = "textarea") {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        componentClass={componentClass}
        formValidation={this.formValidation}
      />
    );
  }

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        formValidation={this.formValidation}
      />
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        formValidation={this.formValidation}
      />
    );
  }

  renderSelectNumber(name, label, options) {
    const { data, errors } = this.state;

    return (
      <SelectNumber
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        formValidation={this.formValidation}
      />
    );
  }
}
