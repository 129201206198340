import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import moment from "moment";
import MerchantSelect from "./FormComponents/MerchantSelect";
import ShopSelect from "./FormComponents/ShopSelect";
import TerminalSelect from "./FormComponents/TerminalSelect";
import DatePicker from "./FormComponents/DatePicker";
import NameInput from "./FormComponents/NameInput";
import CurrencyFilter from "./FormComponents/CurrencyFilter";
import { getAllMerchants } from "actions/merchants";
import { getMerchantShopsAction, getShopTerminalsAction } from "actions/shops";
import { getAllCurrencies, getCurrencyRatesAction } from "actions/currencies";
import { getAllAdditionalFees } from "actions/additionalFee";
import StatementCurrencySelect from "./FormComponents/StatementCurrencySelect";
import CurrencyRatesSelector from "./CurrencyRatesSelector";
import Input from "./FormComponents/Input";
import AdditionalFees from "./FormComponents/AdditionalFees";
import { schema } from "./schema";
import Joi from "joi-browser";
import ReactLoading from "react-loading";
import { numberFormatter } from "../../../helpers/numberFormatter";

class StatementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantsLoading: false,
      merchants: [],
      selectedMerchant: {},
      shopsLoading: false,
      selectedShops: [],
      terminalsLoading: false,
      selectedTerminals: [],
      selectedCurrencies: [],
      name: "",
      statementCurrency: {},
      currencyLoading: false,
      additional_fees: [],
      bank_wire_fee: 0,
      reason: "",
      from_date: moment()
        .format("YYYY-MM-DDTHH:mm:ss")
        .replace(/\D\d{2}\w/, "-01T"),
      to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      errors: {},
    };
  }

  schema = this.props.guid
    ? {
        ...schema,
        reason: Joi.string().required().not().empty().label("Reason"),
      }
    : schema;

  validate = () => {
    const options = { abortEarly: false };
    let data = {
      terminals: this.state.selectedTerminals,
      merchant_guid: this.state.selectedMerchant.guid,
      to_date: this.state.to_date,
      from_date: this.state.from_date,
      statementCurrency: this.state.statementCurrency.name,
      bank_wire_fee: this.state.bank_wire_fee,
      additional_fees: this.state.additional_fees.map((item) => ({
        ...item,
        currency: item.currency.code,
        name: item.name.fee_name,
      })),
      reason: this.props.guid ? this.state.reason : undefined,
    };
    if (this.props.guid) data.reason = this.state.reason;
    else delete data.reason;
    let { error } = Joi.validate(data, this.schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  async componentDidMount() {
    this.setState({ merchantsLoading: true });
    await this.props.getAllMerchants();
    let merchants = this.props.merchants.map((item) => {
      item.name = item.merchant_name;
      item.guid = item.merchant_guid;
      return item;
    });
    merchants = merchants.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.guid === item.guid)
    );

    await this.props.getAllAdditionalFees({ enabled: true });
    await this.props.getAllCurrencies();
    let currencies = this.props.currencies;
    currencies.forEach((currency) => {
      currency.name = currency.code;
    });
    let currenciesRates = currencies.map((currency) => {
      if ("EUR" !== currency.code) {
        return {
          guid: currency.guid,
          bank_exchange_rate: currency.rate_to_eur,
          processor_exchange_rate: currency.isFlat
            ? numberFormatter(
              +currency.rate_to_eur + +currency.exchange_markup_value,
              4
            )
            : numberFormatter(
              currency.rate_to_eur *
                  (currency.exchange_markup_value / 100 + 1),
              4
            ),
          isFlat: currency.isFlat,
          exchange_markup_value: currency.exchange_markup_value,
          code: currency.code,
        };
      } else {
        return {
          guid: currency.guid,
          bank_exchange_rate: numberFormatter(currency.rate_to_eur),
          processor_exchange_rate: numberFormatter(currency.rate_to_eur),
          isFlat: currency.isFlat,
          exchange_markup_value: currency.exchange_markup_value,
          code: currency.code,
        };
      }
    });
    this.setState({
      merchants,
      additionalFeesNames: this.props.additionalFeesNames.map((fee) => ({
        ...fee,
        name: fee.fee_name,
      })),
      currencies,
      currenciesRates,
      selectedCurrenciesRates: currenciesRates,
      statementCurrency: currencies.filter((cur) => cur.code === "EUR")[0],
      merchantsLoading: false,
    });

    if (this.props.statement) {
      this.setState({
        name: this.props.statement.name,
        selectedMerchant: {
          label: this.props.statement.merchant_name,
          name: this.props.statement.merchant_name,
          value: this.props.statement.merchant_guid,
          guid: this.props.statement.merchant_guid,
        },
        shopsLoading: true,
        terminalsLoading: true,
      });

      let additionalFees = [];
      this.props.statement.additional_fees_names.forEach((name) => {
        additionalFees.push({ name, value: "", currency: "" });
      });
      let keys = Object.keys(this.props.statement.entityData);
      keys = keys.filter(
        (key) => !key.includes("Summary") && key.includes("processor")
      );
      additionalFees = additionalFees.map((item) => {
        keys.forEach((cur) => {
          if (
            this.props.statement.entityData[cur].additional_fees[item.name] !==
            0
          ) {
            item = {
              ...item,
              // name: this.state.additionalFeesNames.filter(name => name.name === item.name)[0],
              value:
                this.props.statement.entityData[cur].additional_fees[item.name],
              currency: currencies.filter(
                (currency) => currency.name === cur.substring(0, 3)
              )[0],
            };
          }
        });
        return item;
      });
      currenciesRates = currencies.map((currency) => {
        return {
          guid: currency.guid,
          bank_exchange_rate: currency.rate_to_eur,
          processor_exchange_rate: currency.isFlat
            ? numberFormatter(
              +currency.rate_to_eur + +currency.exchange_markup_value,
              4
            )
            : numberFormatter(
              currency.rate_to_eur *
                  (currency.exchange_markup_value / 100 + 1),
              4
            ),
          isFlat: currency.isFlat,
          exchange_markup_value: currency.exchange_markup_value,
          code: currency.code,
        };
      });
      let selectedShops = this.props.statement.shops;
      selectedShops = selectedShops.map((shop) => {
        return {
          name: shop.name,
          guid: shop.guid,
          label: shop.name,
          value: shop.guid,
        };
      });
      await this.props.getMerchantShopsAction(
        this.props.statement.merchant_guid
      );
      let loadedTerminals = this.props.statement.shops;
      let selectedTerminals = [];
      loadedTerminals.forEach((shop) => {
        let terminals = shop.terminals.map((terminal) => {
          return {
            name: terminal.name + " / " + terminal.guid,
            guid: terminal.guid,
            label:
              terminal.name +
              " / " +
              terminal.guid +
              " (" +
              terminal.currency_code +
              ")",
            value: terminal.guid,
          };
        });
        selectedTerminals = [ ...selectedTerminals, ...terminals ];
      });
      this.setState({
        selectedCurrenciesRates: currenciesRates,
        selectedShops: selectedShops,
        selectedTerminals: selectedTerminals,
        from_date: this.props.statement.from_date,
        to_date: this.props.statement.to_date,
        shops: this.props.shops,
        bank_wire_fee: this.props.statement.bank_wire_fee,
        additional_fees: additionalFees,
        shopsLoading: false,
        terminalsLoading: false,
      });
    }
  }

  onSelectMerchant = async (option) => {
    this.setState({
      shopsLoading: true,
      selectedMerchant: option,
    });
    const errors = { ...this.state.errors };
    delete errors.merchant_guid;
    await this.props.getMerchantShopsAction(option.guid);

    this.setState({
      selectedShops: [],
      selectedTerminals: [],
      errors,
      shopsLoading: false,
      terminalsLoading: false,
    });
  };

  onSelectShop = async (options) => {
    this.setState(
      {
        selectedShops: options,
      },
      this.loadTerminals
    );
  };

  loadTerminals = async () => {
    this.setState({
      terminalsLoading: true,
    });
    let selectedShops = this.state.selectedShops;
    let selectedCurrencies = this.state.selectedCurrencies;
    let terminals = [];

    let loadedTerminals = [];
    await Promise.all(
      selectedShops.map(async (shop) => {
        await this.props.getShopTerminalsAction(shop.guid);
        loadedTerminals = loadedTerminals.concat(
          this.props.terminals.map((element) => {
            return {
              name:
                element.name +
                " (" +
                element.guid +
                ") " +
                element.currency_code,
              guid: element.guid,
              currency_guid: element.currency_guid,
            };
          })
        );
      })
    );
    terminals = loadedTerminals.length ? loadedTerminals : terminals;

    let filteredTerminals = [];
    await Promise.all(
      selectedCurrencies.map(async (currency) => {
        let currencyTerminals = terminals.filter((terminal) => {
          return terminal.currency_guid === currency.guid;
        });
        filteredTerminals = filteredTerminals.concat(currencyTerminals);
      })
    );

    if (!filteredTerminals.length && selectedCurrencies.length) {
      terminals = [];
    } else terminals = filteredTerminals.length ? filteredTerminals : terminals;
    this.setState({
      terminalsLoading: false,
      terminals,
    });
  };

  onSelectTerminal = async (option) => {
    await this.setState({
      selectedTerminals: option,
    });
  };

  onDateChange = async (start, end) => {
    const errors = { ...this.state.errors };
    const errorMessageStart = this.validateProperty({
      name: "from_date",
      value: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
    });
    const errorMessageEnd = this.validateProperty({
      name: "to_date",
      value: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
    });

    if (errorMessageStart) errors.from_date = errorMessageStart;
    else delete errors.from_date;
    if (errorMessageEnd) errors.to_date = errorMessageEnd;
    else delete errors.to_date;

    this.setState({
      to_date: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
      from_date: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
      errors,
    });
  };

  handleChange = (input) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({
      [input.name]: input.value,
      errors,
    });
  };

  onSelectCurrencyFilter = async (options) => {
    await this.setState(
      {
        selectedCurrencies: options,
      },
      this.loadTerminals
    );
  };

  onSelectStatementCurrency = async (option) => {
    this.setState({
      statementCurrency: option,
      currencyLoading: true,
    });

    let currenciesRates = this.state.currenciesRates;
    let rates = [];
    await Promise.all(
      currenciesRates.map(async (currency) => {
        let a = await this.loadCurrencyRates(
          currency.code,
          option.name,
          currenciesRates
        );
        rates = rates.concat([ a ]);
      })
    );

    currenciesRates = rates.length ? rates : currenciesRates;
    let selectedCurrenciesRates = this.state.selectedCurrenciesRates;
    selectedCurrenciesRates = selectedCurrenciesRates.map((currency) => {
      return currenciesRates.filter((curr) => curr.guid === currency.guid)[0];
    });
    if (
      !selectedCurrenciesRates.filter(
        (currency) => currency.code === option.code
      ).length
    ) {
      selectedCurrenciesRates = [
        ...selectedCurrenciesRates,
        ...currenciesRates.filter((curr) => curr.code === option.code),
      ];
    }
    this.setState({
      currencyLoading: false,
      currenciesRates,
      selectedCurrenciesRates,
    });
  };

  handleProcessorExchangeChangeselectedCurrenciesRates = ({
    currentTarget: input,
  }) => {
    let rates = this.state.selectedCurrenciesRates;
    let changedRateIndex = rates.indexOf(
      rates.find((currency) => currency.guid === input.name)
    );
    rates[changedRateIndex] = {
      ...rates[changedRateIndex],
      processor_exchange_rate: numberFormatter(input.value, 4),
    };

    this.setState({ selectedCurrenciesRates: rates });
  };

  handleChangeBankExchangeselectedCurrenciesRates = ({
    currentTarget: input,
  }) => {
    let rates = this.state.selectedCurrenciesRates;
    let changedRateIndex = rates.indexOf(
      rates.find((currency) => currency.guid === input.name)
    );
    rates[changedRateIndex] = {
      ...rates[changedRateIndex],
      bank_exchange_rate: numberFormatter(input.value, 4),
    };
    if (this.state.statementCurrency.name !== rates[changedRateIndex].code) {
      rates[changedRateIndex].processor_exchange_rate = rates[changedRateIndex]
        .isFlat
        ? numberFormatter(
          +input.value + +rates[changedRateIndex].exchange_markup_value,
          4
        )
        : numberFormatter(
          input.value *
              (rates[changedRateIndex].exchange_markup_value / 100 + 1),
          4
        );
    }

    this.setState({ selectedCurrenciesRates: rates });
  };

  loadCurrencyRates = async (
    from,
    to = this.state.statementCurrency.name,
    currencyList = this.state.currenciesRates
  ) => {
    await this.props.getCurrencyRatesAction(from, to);
    let selectedCurrenciesRates = currencyList.filter(
      (currency) => currency.code === from
    )[0];
    selectedCurrenciesRates = {
      ...selectedCurrenciesRates,
      bank_exchange_rate: this.props.currencyRates.exchange_rate,
      processor_exchange_rate: this.props.currencyRates.exchange_rate,
    };

    if (this.state.statementCurrency.name !== selectedCurrenciesRates.code) {
      selectedCurrenciesRates.processor_exchange_rate =
        selectedCurrenciesRates.isFlat
          ? numberFormatter(
            +this.props.currencyRates.exchange_rate +
                +selectedCurrenciesRates.exchange_markup_value,
            4
          )
          : numberFormatter(
            this.props.currencyRates.exchange_rate *
                (selectedCurrenciesRates.exchange_markup_value / 100 + 1),
            4
          );
    }
    return selectedCurrenciesRates;
  };

  addAdditionalFee = () => {
    this.setState({
      additional_fees: [
        ...this.state.additional_fees,
        { name: "", value: "", currency: "" },
      ],
    });
  };

  deleteAdditionalFees = (index) => {
    let additional_fees = this.state.additional_fees;
    let additionalFeesNames = [ ...this.props.additionalFeesNames ].map(
      (fee) => ({ ...fee, name: fee.fee_name })
    );
    additional_fees.splice(index, 1);
    additional_fees.forEach((i) => {
      additionalFeesNames = additionalFeesNames.filter(
        (n) => n.name !== i.name.name
      );
    });
    this.setState({
      additional_fees,
      additionalFeesNames,
    });
  };

  handleChangeAdditionalFee = (index, name, e) => {
    let value = e.currentTarget ? e.currentTarget.value : e;
    let additional_fees = this.state.additional_fees;
    let additionalFeesNames = [ ...this.state.additionalFeesNames ];
    additional_fees[index] = { ...additional_fees[index], [name]: value };
    if (name === "name") {
      additionalFeesNames = [ ...this.props.additionalFeesNames ].map((fee) => ({
        ...fee,
        name: fee.fee_name,
      }));
      additional_fees.forEach((i) => {
        additionalFeesNames = additionalFeesNames.filter(
          (n) => n.name !== i.name.name
        );
      });
    }

    this.setState({ additional_fees, additionalFeesNames });
  };

  handleSubmitCalculate = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      let bank_wire_fee =
        this.state.bank_wire_fee.toString().replace(/^0+/, "") + "00";
      if (bank_wire_fee.includes(".")) {
        let index = bank_wire_fee.indexOf(".");
        bank_wire_fee =
          bank_wire_fee.slice(0, index) + bank_wire_fee.slice(index, index + 3);
        bank_wire_fee = bank_wire_fee.replace(".", "");
      }
      const data = {
        merchant_guid: this.state.selectedMerchant.guid,
        terminals: this.state.selectedTerminals.map((element) => {
          return element.guid;
        }),
        from_date: this.state.from_date,
        to_date: this.state.to_date,
        statement_currency: this.state.statementCurrency.name,
        currency_rates: this.state.selectedCurrenciesRates,
        bank_wire_fee: bank_wire_fee === "00" ? "0" : bank_wire_fee,
        additional_fees: this.state.additional_fees.map((item) => ({
          ...item,
          currency: item.currency.code,
          name: item.name.fee_name,
        })),
        save: 0,
        is_payable_statement: 0,
      };
      await this.props.calculate(data);
    }
  };

  handleSubmitSave = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      let bank_wire_fee =
        this.state.bank_wire_fee.toString().replace(/^0+/, "") + "00";
      if (bank_wire_fee.includes(".")) {
        let index = bank_wire_fee.indexOf(".");
        bank_wire_fee =
          bank_wire_fee.slice(0, index) + bank_wire_fee.slice(index, index + 3);
        bank_wire_fee = bank_wire_fee.replace(".", "");
      }

      const data = {
        guid: this.props.guid ? this.props.guid : undefined,
        merchant_guid: this.state.selectedMerchant.guid,
        terminals: this.state.selectedTerminals.map((element) => {
          return element.guid;
        }),
        from_date: this.state.from_date,
        to_date: this.state.to_date,
        name: this.state.name ? this.state.name : undefined,
        statement_currency: this.state.statementCurrency.name,
        currency_rates: this.state.selectedCurrenciesRates,
        bank_wire_fee: bank_wire_fee === "00" ? "0" : bank_wire_fee,
        additional_fees: this.state.additional_fees.map((item) => ({
          ...item,
          currency: item.currency.code,
          name: item.name.fee_name,
        })),
        save: 1,
        is_payable_statement: 0,
        reason: this.props.guid ? this.state.reason : undefined,
      };
      await this.props.save(data);
    }
  };

  render() {
    let errors = { ...this.state.errors };
    return (
      <Form>
        <Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <MerchantSelect
              merchantsLoading={this.state.merchantsLoading}
              merchants={this.state.merchants}
              value={this.state.selectedMerchant}
              callback={this.onSelectMerchant}
              errors={errors}
            />

            <ShopSelect
              shopsLoading={this.state.shopsLoading}
              shops={this.props.shops}
              value={this.state.selectedShops}
              callback={this.onSelectShop}
            />

            <TerminalSelect
              terminalsLoading={this.state.terminalsLoading}
              terminals={this.state.terminals}
              value={this.state.selectedTerminals}
              callback={this.onSelectTerminal}
              errors={errors}
            />
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <DatePicker
              start={this.state.from_date}
              end={this.state.to_date}
              callback={this.onDateChange}
            />
            <NameInput value={this.state.name} callback={this.handleChange} />
            <CurrencyFilter
              currencies={this.state.currencies}
              callback={this.onSelectCurrencyFilter}
            />
            <StatementCurrencySelect
              value={this.state.statementCurrency}
              currencies={this.state.currencies}
              callback={this.onSelectStatementCurrency}
            />

            <CurrencyRatesSelector
              currencyLoading={this.state.currencyLoading}
              selectedCurrenciesRates={this.state.selectedCurrenciesRates}
              handleProcessorExchangeChangeselectedCurrenciesRates={
                this.handleProcessorExchangeChangeselectedCurrenciesRates
              }
              handleChangeBankExchangeselectedCurrenciesRates={
                this.handleChangeBankExchangeselectedCurrenciesRates
              }
              errors={errors}
              selectedCurrencyName={this.state.statementCurrency.name}
            />

            <Input
              label="Bank wire fee*"
              name="bank_wire_fee"
              type="number"
              value={this.state.bank_wire_fee}
              callback={this.handleChange}
              errors={errors}
            />

            {this.props.guid && (
              <Input
                label="Reason*"
                name="reason"
                value={this.state.reason}
                callback={this.handleChange}
                errors={errors}
              />
            )}

            <AdditionalFees
              addAdditionalFee={this.addAdditionalFee}
              deleteAdditionalFees={this.deleteAdditionalFees}
              handleChangeAdditionalFee={this.handleChangeAdditionalFee}
              additional_fees={this.state.additional_fees}
              additionalFeesNames={this.state.additionalFeesNames}
              currencies={this.state.currencies}
              errors={errors}
            />
          </Col>
        </Row>
        <div>
          {this.props.isLoading ||
          this.state.merchantsLoading ||
          this.state.terminalsLoading ||
          this.state.currencyLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <>
                  {!this.props.guid && (
                    <Button
                      className={
                        this.state.terminalsValidation
                          ? "btn btn-fill btn-success"
                          : "btn btn-fill"
                      }
                      type="submit"
                      onClick={this.handleSubmitCalculate}
                    >
                  Calculate
                    </Button>
                  )}
                  <Button
                    className={
                      this.state.terminalsValidation
                        ? "btn btn-fill btn-success"
                        : "btn btn-fill"
                    }
                    style={{ marginLeft: "15px" }}
                    type="submit"
                    onClick={this.handleSubmitSave}
                  >
                Save
                  </Button>
                </>
              )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    currencies: state.currencies.currenciesList,
    currencyRates: state.currencies.currencyRates,
    additionalFeesNames: state.additionalFees.additionalFees,
  };
};

export default connect(mapStateToProps, {
  getAllMerchants,
  getMerchantShopsAction,
  getShopTerminalsAction,
  getAllCurrencies,
  getCurrencyRatesAction,
  getAllAdditionalFees,
})(StatementForm);
