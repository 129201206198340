export const parseResponse = (error) => {
  try {
    if (!error.response) {
      return {
        error: "Server is not available",
        message: "please try again later",
      };
    }
    if (typeof error.response.data.description.message === "object") {
      let message = "";
      error.response.data.description.message.forEach((obj) => {
        message += obj;
        message += ", ";
      });

      message = message.slice(0, message.length - 2);
      return {
        error: error.response.data.error,
        message: message,
      };
    } else
      return {
        error: error.response.data.error,
        message: error.response.data.description.message,
      };
  } catch (error) {
    return {
      error: "Server is not available",
      message: "please try again later",
    };
  }
};
