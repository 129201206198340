import * as types from "../constants/actionTypes";
import {
  createGroup,
  getGroups,
  getGroup,
  updateGroup,
  deleteGroup,
  getGroupLogins,
  getGroupMerchants,
  addGroupLogin
} from "../services/paymentBackendAPI/management/groups";
import {
  getTopGroups
} from "../services/paymentBackendAPI/reports/entities";

import {
  deleteMerchantFromGroup,
  addMerchantToGroup
} from "../services/paymentBackendAPI/management/merchants";
import { getAmountGroups } from "../services/paymentBackendAPI/reports/entities";
import { loadTable } from "./store";

export const getAllGroups = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GROUPS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getGroups(args);
      return dispatch({
        type: types.GET_GROUPS_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    } catch (error) {
      return dispatch({
        type: types.GET_GROUPS_FAILED
      });
    }
  };
};

export const getGroupAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GROUP
    });
    try {
      const response = await getGroup(id);
      return dispatch({
        type: types.GET_GROUP_SUCCEED,
        group: response.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_GROUP_FAILED
      });
    }
  };
};

export const editGroupAction = (data) => {
  return async (dispatch) => {
    const response = await updateGroup(data);
    return dispatch({
      type: types.EDIT_GROUP,
      group: response.data
    });
  };
};

export const deleteGroupAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteGroup(data);
    return dispatch(getAllGroups({ page, items, ...search }));
  };
};

export const addMerchantToGroupAction = ({ merchant_guid, group_guid, reason }) => {
  return async () => {
    await addMerchantToGroup(merchant_guid, group_guid, reason);
  };
};

export const getGroupLoginsAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GROUP_LOGINS
    });
    try {
      const response = await getGroupLogins(id, page, items);
      return dispatch({
        type: types.GET_GROUP_LOGINS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_GROUP_LOGINS_FAILED
      });
      throw (error);
    }
  };
};

export const getGroupMerchantsAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GROUP_MERCHANTS
    });
    try {
      const response = await getGroupMerchants(id, page, items);
      return dispatch({
        type: types.GET_GROUP_MERCHANTS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_GROUP_MERCHANTS_FAILED
      });
    }
  };
};

export const deleteMerchantFromGroupAction = (data, page, items, groupGuid) => {
  return async (dispatch) => {
    await deleteMerchantFromGroup(data);
    return dispatch(getGroupMerchantsAction(groupGuid, page, items));
  };
};

export const addGroupAction = (data, page, items, search) => {
  return async (dispatch) => {
    const response = await createGroup(data);
    dispatch({
      type: types.ADD_GROUP,
      group: response.data
    });
    dispatch(getAllGroups({ page, items, ...search }));
  };
};

export const addGroupLoginAction = (id, data) => {
  return async (dispatch) => {
    const response = await addGroupLogin(id, data);
    return dispatch({
      type: types.ADD_GROUP_LOGIN,
      groupLogin: response.data
    });
  };
};

export const upsertChangedMerchantAction = (merchant) => {
  return (dispatch) => {
    return dispatch({
      type: types.UPSERT_GROUP_CHANGED_MERCHANT,
      merchant: merchant
    });
  };
};

export const getTopGroupsAction = (days, count) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TOP_GROUPS,
    });
    try {
      const response = await getTopGroups(days, count);
      return dispatch({
        type: types.GET_TOP_GROUPS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TOP_GROUPS_FAILED,
      });
      throw (error);
    }
  };
};

export const getAmountGroupsForPartner = () => {
  return async (dispatch) => {
    const response = await getAmountGroups();
    dispatch({
      type: types.GET_AMOUNT_GROUPS_FOR_PARTNER,
      count: response.data.count
    });
  };
};


