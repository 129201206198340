import React, { useEffect, useState } from "react";
import Table from "components/UI/Table/index";
import { columnsTotal } from "./columnsAndRows";
import { Card } from "react-bootstrap";

function TableSummary({ currencies, statement_currency_code }) {
  let [ data, setData ] = useState([]);
  let [ fData, setFData ] = useState([]);

  useEffect(() => {
    let currenciesStatement = [ ...currencies ];
    let sumCurrencies = currenciesStatement.splice(
      currenciesStatement.indexOf(
        currenciesStatement.filter(
          (currency) => currency.name === "Summary"
        )
      ),
      1
    );
    sumCurrencies[0] = {
      ...sumCurrencies[0],
      name:
        "Summary (" + statement_currency_code + ")",
    };

    setData(currenciesStatement);
    setFData(sumCurrencies);
  }, [ currencies ]);


  return (
    <>
      <Card.Title style={{ marginTop: "15px" }}>Report summary</Card.Title>
      <Table
        columns={columnsTotal}
        data={data}
        disableSearch
        disableSort
        keyPath="name"
        foot={true}
        tfootData={fData}
      />
    </>
  );
}

export default TableSummary;
