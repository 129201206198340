import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteAdminAction, getAllAdmins } from "../../actions/admins";
import { reset, searchInAdmins } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import { getAdmins } from "../../services/paymentBackendAPI/management/admins";
import AdminCreator from "./Creator";
import AdminEditor from "./Editor";

const columns = [
  {
    path: "username",
    label: "Username",
    content: (admin) => (
      <Link className="link" to={`/about/admin/${admin.guid}`}>
        {admin.username}
      </Link>
    ),
    textContent: (admin) => admin.username,
  },
  { path: "email", label: "Email" },
  {
    key: "enabled",
    content: (login) => (
      <i
        className={
          login.enabled ? "icon-success icon green" : "icon-failed icon red"
        }
      />
    ),
    label: "Status",
    textContent: (login) => (login.enabled ? "Enabled" : "Disabled"),
    align: "center",
    colWidth: 4,
  },

  ability.can("REPORT", "REASONSLOGIN") && {
    label: "History",
    key: "history",
    align: "center",
    content: (login) => (
      <Link className="link" to={`/about/audit/login/${login.guid}`}>
        <button type="button" className="btn btn-table">
          Show
        </button>
      </Link>
    ),
    colWidth: 5,
  },
  ability.can("EXECUTE", "USERADMIN") && {
    key: "edit",
    content: (admin) => (
      <Modal
        header="Edit admin"
        content={AdminEditor}
        contentProps={{ guid: admin.guid }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "USERADMIN") && {
    key: "delete",
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.admins.adminsList,
    count: state.admins.count,
    table: state.store.table,
    searchData: state.search.adminsSearch,
    isSearch: state.search.isSearch,
    loading: state.admins.adminsLoading,
    name: "USERADMIN",
    columns,
    columnsComponent: createColComponentForTable(columns, 50),
    tableWidth: 50,
    keyPath: "guid",
    modal: <AdminCreator />,
    exportFunction: getAdmins,
  };
};

export default connect(mapStateToProps, {
  get: getAllAdmins,
  delete: deleteAdminAction,
  search: searchInAdmins,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
