import MultiSelect from "components/UI/MultiSelect";
import Spinner from "components/UI/Spinner";
import { parseResponse } from "helpers/parseResponse";
import Joi from "joi-browser";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import swal from "sweetalert";
import { showModal } from "../../../actions/modal";
import {
  editTransactiontAction,
  getTransactionAction,
} from "../../../actions/transactions";
import Alert from "../../UI/Alert";

class TransactionEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      date: "",
      bin_country: "",
      amount: "",
      // card_type: "",
      to_processor_pct: "",
      to_processor_fixed: "",
      to_bank_pct: "",
      to_bank_fixed: "",
      to_client: "",
      hold: "",
      hold_date: "",
      hold_flag: "",
      needChangeRates: false,
      selectedStatus: "",

      oldData: {
        status: "",
        date: "",
        bin_country: "",
        // card_type: "",
        amount: "",
        to_processor: "",
        to_bank: "",
        to_client: "",
        hold: "",
        hold_flag: "",
        hold_date: "",
      },
      needReason: false,
      reason: "",
      isLoading: false,
      errors: {},

      statusValidation: false,
      dateValidation: false,
      bin_countryValidation: false,
      amountValidation: false,
      to_processorValidation: false,
      to_bankValidation: false,
      to_clientValidation: false,
      holdValidation: false,
      hold_dateValidation: false,
      reasonValidation: false,
    };
  }

  schema_without_rate = {
    status: Joi.string().required().label("Status"),
    date: Joi.string().required().label("Date"),
    bin_country: Joi.string()
      .required()
      .regex(/^[A-Z]{2}$/)
      .error(() => {
        return {
          message: "Country must be at Alpha-2 code.",
        };
      })
      .label("BIN country"),
    amount: Joi.string().required().label("Amount"),
    // card_type: Joi.string().required().label("Card type"),
  };

  schema = {
    status: Joi.string().required().label("Status"),
    date: Joi.string().required().label("Date"),
    bin_country: Joi.string()
      .required()
      .regex(/^[A-Z]{2}$/)
      .error(() => {
        return {
          message: "Country must be at Alpha-2 code.",
        };
      })
      .label("BIN country"),
    amount: Joi.string().required().label("Amount"),
    // card_type: Joi.string().required().label("Card type"),
    to_processor_pct: Joi.string().required().label("To processor (%)"),
    to_processor_fixed: Joi.string().required().label("To processor (fixed)"),
    to_bank_pct: Joi.string().required().label("To bank ()%"),
    to_bank_fixed: Joi.string().required().label("To bank (fixed)"),
    to_client: Joi.string().required().label("To client"),
    hold: Joi.string().required().label("Hold"),
    hold_date: Joi.string().required().label("Hold date"),
  };

  async componentDidMount() {
    await this.props.getTransactionAction(this.props.data.guid);

    const transaction = this.props.transaction;

    this.setState({
      status: transaction.status,
      date: transaction.date_time ?? moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
      bin_country: transaction.bin_country,
      amount: transaction.amount,
      to_processor_pct: transaction.rate.to_processor_pct
        ? transaction.rate.to_processor_pct
        : "0",
      to_processor_fixed: transaction.rate.to_processor_fixed
        ? transaction.rate.to_processor_fixed
        : "0",
      to_bank_pct: transaction.rate.to_bank_pct
        ? transaction.rate.to_bank_pct
        : "0",
      to_bank_fixed: transaction.rate.to_bank_fixed
        ? transaction.rate.to_bank_fixed
        : "0",
      to_client: transaction.rate.to_client ? transaction.rate.to_client : "0",
      hold: transaction.rate.hold,
      hold_date: transaction.rate.hold_date,
      hold_flag: transaction.rate.hold_flag,
      oldData: {
        status: transaction.status,
        date: transaction.date_time,
        bin_country: transaction.bin_country,
        amount: transaction.amount,
        // card_type: transaction.card_type,
        to_processor_pct: transaction.rate.to_processor_pct
          ? transaction.rate.to_processor_pct
          : "0",
        to_processor_fixed: transaction.rate.to_processor_fixed
          ? transaction.rate.to_processor_fixed
          : "0",
        to_bank_pct: transaction.rate.to_bank_pct
          ? transaction.rate.to_bank_pct
          : "0",
        to_bank_fixed: transaction.rate.to_bank_fixed
          ? transaction.rate.to_bank_fixed
          : "0",
        to_client: transaction.rate.to_client
          ? transaction.rate.to_client
          : "0",
        hold: transaction.rate.hold ? transaction.rate.hold : "0",
        hold_date: transaction.rate.hold_date,
        hold_flag: transaction.rate.hold_flag,
      },

      statusValidation: transaction.status ? true : false,
      dateValidation: transaction.date_time ? true : false,
      bin_countryValidation: transaction.bin_country ? true : false,
      amountValidation: transaction.amount ? true : false,
      // to_processor_pctValidation: transaction.rate.to_processor_pct
      //   ? true
      //   : false,
      // to_processor_fixedValidation: transaction.rate.to_processor_fixed
      //   ? true
      //   : false,
      // to_bank_pctValidation: transaction.rate.to_bank_pct ? true : false,
      // to_bank_fixedValidation: transaction.rate.to_bank_fixed ? true : false,
      // to_clientValidation: transaction.rate.to_client ? true : false,
      // holdValidation: transaction.rate.hold ? true : false,
      // hold_dateValidation: transaction.rate.hold_date ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      if (this.state.needChangeRates) {
        data = {
          status: this.state.status,
          date: this.state.date,
          bin_country: this.state.bin_country,
          amount: this.state.amount,
          to_processor_pct: this.state.to_processor_pct,
          to_processor_fixed: this.state.to_processor_fixed,
          to_bank_pct: this.state.to_bank_pct,
          to_bank_fixed: this.state.to_bank_fixed,
          to_client: this.state.to_client,
          hold: this.state.hold,
          hold_date: this.state.hold_date,
          reason: this.state.reason,
        };
        schema = this.schema;
      } else {
        data = {
          status: this.state.status,
          date: this.state.date,
          bin_country: this.state.bin_country,
          amount: this.state.amount,
          reason: this.state.reason,
        };
        schema = this.schema_without_rate;
      }
      schema.reason = Joi.string().required().label("Reason");
    } else {
      if (this.state.needChangeRates) {
        data = {
          status: this.state.status,
          date: this.state.date,
          bin_country: this.state.bin_country,
          amount: this.state.amount,
          to_processor_pct: this.state.to_processor_pct,
          to_processor_fixed: this.state.to_processor_fixed,
          to_bank_pct: this.state.to_bank_pct,
          to_bank_fixed: this.state.to_bank_fixed,
          to_client: this.state.to_client,
          hold: this.state.hold,
          hold_date: this.state.hold_date,
        };
        schema = this.schema;
      } else {
        data = {
          status: this.state.status,
          date: this.state.date,
          bin_country: this.state.bin_country,
          amount: this.state.amount,
        };
        schema = this.schema_without_rate;
      }
      if (schema.reason) {
        delete schema.reason;
      }
    }
    if (
      this.props.transaction.transaction_type !== "Payment" ||
      this.props.transaction.transaction_type !== "Authorization"
    ) {
      delete schema.bin_country;
      delete data.bin_country;
    }

    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    let changedData = false;
    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== this.state[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState(
      {
        [input.name]: input.value,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  onSelectStatus = (option) => {
    const errors = { ...this.state.errors };
    delete errors.status;
    this.setState(
      {
        status: option.name,
        errors,
        statusValidation: true,
      },
      () => this.showReason()
    );
  };

  handleTransactionTimeChange = (date) => {
    let value = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({ name: "date", value });
    if (errorMessage) errors.date = errorMessage;
    else delete errors.date;

    // value = moment(value).format("YYYY-MM-DDTHH:mm:ss");
    this.setState(
      {
        date: value,
        errors,
        dateValidation: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleHoldTimeChange = (date) => {
    let value = moment(date).format("YYYY-MM-DDTHH:mm:ss");

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({ name: "hold_date", value });
    if (errorMessage) errors.hold_date = errorMessage;
    else delete errors.hold_date;
    this.setState(
      {
        hold_date: value,
        errors,
        hold_dateValidation: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleCheckbox = () => {
    this.setState({
      needChangeRates: !this.state.needChangeRates,
    });
  };

  handleHoldFlagCheckbox = () => {
    this.setState(
      {
        hold_flag: !this.state.hold_flag,
      },
      () => this.showReason()
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        this.setState({ isLoading: true });
        const data = this.state;
        if (data.needReason) {
          let time = moment(data.date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          let req = {
            guid: this.props.data.guid,
            reason: data.reason,
            status:
              data.status !== data.oldData.status ? data.status : undefined,
            date_time:
              data.date !== data.oldData.date ? time.toString() : undefined,
            bin_country:
              data.bin_country !== data.oldData.bin_country
                ? data.bin_country
                : undefined,
            amount:
              data.amount !== data.oldData.amount ? data.amount : undefined,
          };
          if (data.needChangeRates) {
            let holdTime = moment(data.hold_date).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            );
            req.rate = {
              to_processor_pct: data.to_processor_pct,
              to_processor_fixed: data.to_processor_fixed,
              to_bank_pct: data.to_bank_pct,
              to_bank_fixed: data.to_bank_fixed,
              to_client: data.to_client,
              hold: data.hold,
              hold_flag: data.hold_flag,
              hold_date: holdTime.toString(),
            };
          }
          await this.props.editTransactiontAction(req);
          // this.setState({ data });
          swal({
            title: "Transaction is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;
    const statuses = [
      { name: "Success", guid: "1", label: "Success", value: "1" },
      { name: "Failed", guid: "2", label: "Failed", value: "2" },
      { name: "Pending", guid: "3", label: "Pending", value: "3" },
      { name: "3Dwaiting", guid: "4", label: "3Dwaiting", value: "4" },
    ];
    if (this.props.transactionLoading) return <Spinner />;
    else
      return (
        <React.Fragment>
          {this.state.oldData.amount ? (
            <Form autoComplete="off">
              <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                  <Row>
                    <Col md={3} sm={4} xs={4} className="form-label">
                      <Form.Label>Status:</Form.Label>
                    </Col>
                    <Col md={8}>
                      <Form.Group>
                        <MultiSelect
                          name="status"
                          options={statuses}
                          multi={false}
                          onSelect={this.onSelectStatus}
                          value={statuses.filter(
                            (option) => option.name === this.state.status
                          )}
                        />
                        {errors.status && (
                          <span className="validate-error">
                            {errors.status}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3} sm={4} xs={4} className="form-label">
                      <Form.Label>Date:</Form.Label>
                    </Col>
                    <Col md={8}>
                      <Form.Group>
                        <DateRangePicker
                          onCallback={this.handleTransactionTimeChange}
                          initialSettings={{
                            singleDatePicker: true,
                            timePicker: true,
                            startDate: this.state.date
                              ? moment(this.state.date)
                                .utc()
                                .format("DD.MM.YYYY HH:mm'")
                              : undefined,
                            locale: {
                              format: "DD.MM.YYYY HH:mm",
                            },
                            timePicker24Hour: true,
                          }}
                        >
                          <input type="text" className="form-control" />
                        </DateRangePicker>
                        {errors.date && (
                          <span className="validate-error">{errors.date}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {this.props.transaction.transaction_type === "Payment" ||
                  this.props.transaction.transaction_type ===
                    "Authorization" ? (
                        <Row>
                          <Col md={3} sm={4} xs={4} className="form-label">
                            <Form.Label>BIN country:</Form.Label>
                          </Col>
                          <Col md={8}>
                            <Form.Group
                              validationState={this.formValidation("bin_country")}
                            >
                              <Form.Control
                                name="bin_country"
                                type="text"
                                value={this.state.bin_country}
                                onChange={this.handleChange}
                              />
                              {errors.bin_country && (
                                <span className="validate-error">
                                  {errors.bin_country}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      ) : null}

                  {this.state.oldData.amount && (
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Amount:</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group
                          validationState={this.formValidation("amount")}
                        >
                          <Form.Control
                            name="amount"
                            type="number"
                            value={this.state.amount}
                            onChange={this.handleChange}
                          />
                          {errors.amount && (
                            <span className="validate-error">
                              {errors.amount}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {/* {this.state.oldData.card_type
                    ? (
                      <Row >
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>Card type:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation("card_type")}
                          >
                            <Form.Control
                              name="card_type"
                              type="text"
                              value={this.state.card_type}
                              onChange={this.handleChange}
                            />
                            {errors.card_type && (
                              <span className="validate-error">
                                {errors.card_type}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : null} */}

                  {this.state.needReason && (
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Reason*:</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group
                          validationState={this.formValidation("reason")}
                        >
                          <Form.Control
                            name="reason"
                            type="text"
                            value={this.state.reason}
                            onChange={this.handleChange}
                          />
                          {errors.reason && (
                            <span className="validate-error">
                              {errors.reason}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                  {this.state.oldData.amount && (
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <label>Change rates</label>
                      </Col>
                      <Col md={1}>
                        <input
                          type="checkbox"
                          id="enabledCheckbox"
                          checked={this.state.needChangeRates}
                          onChange={this.handleCheckbox}
                        />
                      </Col>
                    </Row>
                  )}

                  {this.state.needChangeRates && (
                    <React.Fragment>
                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>Hold:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation("hold")}
                          >
                            <Form.Control
                              name="hold"
                              type="number"
                              value={this.state.hold}
                              onChange={this.handleChange}
                            />
                            {errors.hold && (
                              <span className="validate-error">
                                {errors.hold}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>Hold date:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation("hold_date")}
                          >
                            <DateRangePicker
                              onCallback={this.handleHoldTimeChange}
                              initialSettings={{
                                singleDatePicker: true,
                                timePicker: true,
                                startDate: this.state.hold_date
                                  ? moment(this.state.hold_date)
                                    .utc()
                                    .format("DD.MM.YYYY HH:mm'")
                                  : undefined,
                                locale: {
                                  format: "DD.MM.YYYY HH:mm",
                                },
                                timePicker24Hour: true,
                              }}
                            >
                              <input type="text" className="form-control" />
                            </DateRangePicker>
                            {errors.hold_date && (
                              <span className="validate-error">
                                {errors.hold_date}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>Hold flag :</Form.Label>
                        </Col>
                        <Col md={8}>
                          <div style={{ textAlign: "left" }}> </div>
                          {/* <i className={this.state.hold_flag ? "icon-success green" : "icon-failed red"} style={{ float: "left" }} /> */}
                          <input
                            type="checkbox"
                            id="holdFlag"
                            checked={this.state.hold_flag}
                            onChange={this.handleHoldFlagCheckbox}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>To processor (%)*:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation(
                              "to_processor_pct"
                            )}
                          >
                            <Form.Control
                              name="to_processor_pct"
                              type="number"
                              value={this.state.to_processor_pct}
                              onChange={this.handleChange}
                            />
                            {errors.to_processor_pct && (
                              <span className="validate-error">
                                {errors.to_processor_pct}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>To processor (fixed)*:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation(
                              "to_processor_fixed"
                            )}
                          >
                            <Form.Control
                              name="to_processor_fixed"
                              type="number"
                              value={this.state.to_processor_fixed}
                              onChange={this.handleChange}
                            />
                            {errors.to_processor_fixed && (
                              <span className="validate-error">
                                {errors.to_processor_fixed}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>To bank (%)*:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation("to_bank_pct")}
                          >
                            <Form.Control
                              name="to_bank_pct"
                              type="number"
                              value={this.state.to_bank_pct}
                              onChange={this.handleChange}
                            />
                            {errors.to_bank_pct && (
                              <span className="validate-error">
                                {errors.to_bank_pct}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>To bank (fixed)*:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation(
                              "to_bank_fixed"
                            )}
                          >
                            <Form.Control
                              name="to_bank_fixed"
                              type="number"
                              value={this.state.to_bank_fixed}
                              onChange={this.handleChange}
                            />
                            {errors.to_bank_fixed && (
                              <span className="validate-error">
                                {errors.to_bank_fixed}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3} sm={4} xs={4} className="form-label">
                          <Form.Label>To client*:</Form.Label>
                        </Col>
                        <Col md={8}>
                          <Form.Group
                            validationState={this.formValidation("to_client")}
                          >
                            <Form.Control
                              name="to_client"
                              type="number"
                              value={this.state.to_client}
                              onChange={this.handleChange}
                            />
                            {errors.to_client && (
                              <span className="validate-error">
                                {errors.to_client}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>

              <div className="modal-buttons">
                {this.state.isLoading ? (
                  <ReactLoading type="cylon" color="grey" />
                ) : (
                  <Button
                    className={
                      this.state.statusValidation &&
                      this.state.dateValidation &&
                      (this.props.transaction.transaction_type !== "Payment" ||
                        this.props.transaction.transaction_type !==
                          "Authorization" ||
                        this.state.bin_countryValidation) &&
                      this.state.amountValidation &&
                      // this.state.to_processor_pctValidation &&
                      // this.state.to_processor_fixedValidation &&
                      // this.state.to_bank_pctValidation &&
                      // this.state.to_bank_fixedValidation &&
                      // this.state.to_clientValidation &&
                      // this.state.holdValidation &&
                      // this.state.hold_dateValidation &&
                      (!this.state.needReason || this.state.reasonValidation)
                        ? "btn btn-fill btn-success"
                        : "btn btn-fill"
                    }
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Form>
          ) : (
            <div>
              <b>Sorry, you can`t change this transaction</b>
            </div>
          )}
        </React.Fragment>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transactions.transaction,
    transactionLoading: state.transactions.transactionLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionAction,
  editTransactiontAction,
  showModal,
})(TransactionEditor);

TransactionEditor.propTypes = {
  data: PropTypes.shape({
    guid: PropTypes.string,
  }),
  editTransactiontAction: PropTypes.func,
  getTransactionAction: PropTypes.func,
  showModal: PropTypes.func,
  transaction: PropTypes.object,
  transactionLoading: PropTypes.bool,
};
