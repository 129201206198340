import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";

function NameInput({ value, callback }) {
  let [ checked, setChecked ] = useState(false);

  const onCheck = () => {
    setChecked(!checked);
  };

  const handleChange = ({ currentTarget: input }) => {
    callback && callback(input);
  };

  return (
    <>
      <Row  >
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Set custom name</Form.Label>
        </Col>
        <Col md={1} sm={1} xs={1}>
          <Form.Group>
            <input
              type="checkbox"
              id="setCustomName"
              checked={checked}
              onChange={onCheck}
            />
          </Form.Group>
        </Col>

      </Row>

      {checked && (
        <Row >
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Name*</Form.Label>
          </Col>
          <Col md={8} sm={7} xs={7}>
            <Form.Group>
              <Form.Control
                name="name"
                type="text"
                value={value}
                placeholder="Enter name"
                onChange={handleChange}
              />
              {/* {errors.name && (
                <span className="validate-error">{errors.name}</span>
              )} */}
            </Form.Group>
          </Col>
        </Row>

      )
      }
    </>
  );
}

export default NameInput;