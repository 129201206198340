import * as types from "../constants/actionTypes";
import {
  getAuditMerchantTables,
  getAuditGroupTables,
  getAuditPartnerTables,
  getAuditAdminTables,
  getAuditLoginTables,
  getAuditTerminalTables,
  getAuditTransactionTables,
  getAuditShopTables,
  getAuditAccountTables,
  getAuditGatewayTables,
  getAuditCurrencyTables,
  getAuditTransactionProcessingTables,
  getAuditTransactionDataTables,
  getAuditTransactionOverviewsTables,
  getAuditServiceTables,
  getAuditMerchants,
  getAuditGroups,
  getAuditPartners,
  getAuditLogins,
} from "../services/paymentBackendAPI/audits/audit";
import { loadTable } from "./store";

export const getAuditMerchantTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_MERCHANT_TABLES,
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditMerchantTables(args);
      return dispatch({
        type: types.GET_AUDIT_MERCHANT_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_MERCHANT_TABLES_FAILED,
      });
    }
  };
};

export const getAuditMerchantAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_MERCHANT,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditMerchants(args);
      return dispatch({
        type: types.GET_AUDIT_MERCHANT_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_MERCHANT_FAILED,
      });
    }
  };
};

export const getAuditGroupTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_GROUP_TABLES,
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditGroupTables(args);
      return dispatch({
        type: types.GET_AUDIT_GROUP_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_GROUP_TABLES_FAILED,
      });
    }
  };
};

export const getAuditGroupAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_GROUPS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditGroups(args);
      return dispatch({
        type: types.GET_AUDIT_GROUPS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_GROUPS_FAILED,
      });
    }
  };
};

export const getAuditPartnerTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_PARTNER_TABLES,
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditPartnerTables(args);
      return dispatch({
        type: types.GET_AUDIT_PARTNER_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_PARTNER_TABLES_FAILED,
      });
    }
  };
};

export const getAuditPartnerAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_PARTNERS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditPartners(args);
      return dispatch({
        type: types.GET_AUDIT_PARTNERS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_PARTNERS_FAILED,
      });
    }
  };
};

export const getAuditAdminTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_ADMIN_TABLES,
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditAdminTables(args);
      return dispatch({
        type: types.GET_AUDIT_ADMIN_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_ADMIN_TABLES_FAILED,
      });
    }
  };
};

export const getAuditLoginTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_LOGIN_TABLES,
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditLoginTables(args);
      return dispatch({
        type: types.GET_AUDIT_LOGIN_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_LOGIN_TABLES_FAILED,
      });
    }
  };
};

export const getAuditLoginsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_LOGINS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditLogins(args);
      return dispatch({
        type: types.GET_AUDIT_LOGINS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_LOGINS_FAILED,
      });
    }
  };
};

export const getAuditTerminalsTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_TERMINAL_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditTerminalTables(args);
      return dispatch({
        type: types.GET_AUDIT_TERMINAL_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_TERMINAL_TABLES_FAILED,
      });
    }
  };
};

export const getAuditShopsTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_SHOP_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditShopTables(args);
      return dispatch({
        type: types.GET_AUDIT_SHOP_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_SHOP_TABLES_FAILED,
      });
    }
  };
};

export const getAuditTransactionsTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_TRANSACTION_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditTransactionTables(args);
      return dispatch({
        type: types.GET_AUDIT_TRANSACTION_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_TRANSACTION_TABLES_FAILED,
      });
    }
  };
};

export const getAuditCurrencyTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_CURRENCY_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditCurrencyTables(args);
      return dispatch({
        type: types.GET_AUDIT_CURRENCY_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_CURRENCY_TABLES_FAILED,
      });
    }
  };
};

export const getAuditGatewayTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_GATEWAY_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditGatewayTables(args);
      return dispatch({
        type: types.GET_AUDIT_GATEWAY_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_GATEWAY_TABLES_FAILED,
      });
    }
  };
};

export const getAuditAccountTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_ACCOUNT_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditAccountTables(args);
      return dispatch({
        type: types.GET_AUDIT_ACCOUNT_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_ACCOUNT_TABLES_FAILED,
      });
    }
  };
};

export const getAuditTransactionProcessingTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_TRANSACTION_PROCESSING_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditTransactionProcessingTables(args);
      return dispatch({
        type: types.GET_AUDIT_TRANSACTION_PROCESSING_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_TRANSACTION_PROCESSING_TABLES_FAILED,
      });
    }
  };
};

export const getAuditTransactionDataTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_TRANSACTION_DATA_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditTransactionDataTables(args);
      return dispatch({
        type: types.GET_AUDIT_TRANSACTION_DATA_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_TRANSACTION_DATA_TABLES_FAILED,
      });
    }
  };
};

export const getAuditTransactionOverviewsTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditTransactionOverviewsTables(args);
      return dispatch({
        type: types.GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_FAILED,
      });
    }
  };
};

export const getAuditServiceTablesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_AUDIT_SERVICE_TABLES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAuditServiceTables(args);
      return dispatch({
        type: types.GET_AUDIT_SERVICE_TABLES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUDIT_SERVICE_TABLES_FAILED,
      });
    }
  };
};
