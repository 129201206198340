import * as types from "../constants/actionTypes";

const initialState = {
  users: {}
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case types.CHECK_USER_EXISTS:
      return { ...state, users: action.users };
    default:
      return state;
  }
}