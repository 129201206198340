import { backendManagement } from "../backendPlatform";
//merge this
export const calculateStatement = data => {
  return backendManagement.post("/statements", data);
};

export const mergeStatements = (data) => {
  return backendManagement.post("/statements", data);
};

export const getStatementTransactions = data => {
  return backendManagement.post("/statements/transactions", data);
};

export const getStatements = args => {
  return backendManagement.get("/statements", { params: { ...args } });
};

export const getStatement = guid => {
  return backendManagement.get(`/statements/${guid}`);
};

export const changeStatementStatus = data => {
  return backendManagement.post("/statements/status", data);
};

export const deleteStatement = ({ guid, reason }) => {
  return backendManagement.post("/statements", { guid, delete: true, reason });
};

export const exportStatement = ({ guid, type }) => {
  return backendManagement.get(`/statements/${guid}/export/${type}`);
};

export const getStatementCurrency = (data) => {
  return backendManagement.post("/statements/currencies", data);
};

