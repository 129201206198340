import React from "react";
import { connect } from "react-redux";
import PieChart from "components/UI/PieChart";
import Spinner from "components/UI/Spinner";
import PropTypes from "prop-types";

class PieChartPassability extends React.Component {
  state = {
    daysCount: 7,
    loading: true,
    data: {},
  };

  render() {
    let object = this.props.typesData;
    let data = [];
    let success = 0;
    let failed = 0;

    Object.keys(this.props.typesData).forEach(function (key) {
      object[key].forEach((item) => {
        success += parseInt(item.success, 10);
        failed += parseInt(item.failed, 10);
      });
      data.push(success);
      data.push(failed);
    });

    const datasetsPie =
      success > 0 || failed > 0
        ? [
            {
              data: [ success, failed ],
              backgroundColor: [ "rgba(75,192,192,1)", "rgba(255,99,132,1)" ],
            },
          ]
        : [
            {
              data: [ 1 ],
              backgroundColor: "#CCC",
            },
          ];

    const labelsPie =
      datasetsPie[0].data.length !== 1
        ? [ "Success", "Failed" ]
        : [ "There are no transactions yet" ];
    if (this.props.loading) return <Spinner />;
    else
      return (
        <PieChart
          name="Passability"
          labels={labelsPie}
          datasets={datasetsPie}
        />
      );
  }
}

const mapStateToProps = (state) => {
  return {
    typesData: state.transactions.types,
    loading: state.transactions.transactionTypesLoading,
  };
};

export default connect(mapStateToProps, {})(PieChartPassability);

PieChartPassability.propTypes = {
  loading: PropTypes.bool,
  typesData: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
};
