import React from "react";

import { Col, Form, Row } from "react-bootstrap";
import MultiSelect from "../UI/MultiSelect";

export const FormSelect = ({ options, field, form, label }) => (
  <Row>
    <Col md={3} sm={4} xs={4} className="form-label">
      <Form.Label htmlFor={field.name}>{label}</Form.Label>
    </Col>
    <Col md={8}>
      <Form.Group>
        <MultiSelect
          options={options}
          name={field.name}
          value={field.value}
          onChange={(option) => form.setFieldValue(field.name, option)}
          onBlur={field.onBlur}
        />
      </Form.Group>
    </Col>
  </Row>
);
