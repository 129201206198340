import * as types from "../constants/actionTypes";

const initialState = {
  transactions: [],
  statements: [],
  merchantStatements: [],
  statementCurrencies: [],
  statement: {},
  count: "",
  data: {},
  statementLoading: false,
  merchantStatementLoading: false,
  statementCurrenciesLoading: false,
};
let newStatementsList = [];
export default function statements(state = initialState, action) {
  switch (action.type) {
    case types.CALCULATE_STATEMENT:
      return { ...state, statement: action.statement };
    case types.MERGE_STATEMENT:
      return { ...state, statement: action.statement };
    case types.GET_STATEMENT_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data.data,
        count: action.data.count,
      };

    case types.GET_STATEMENTS:
      return {
        ...state,
        statementLoading: true,
        error: false
      };
    case types.GET_STATEMENTS_SUCCEED:
      return {
        ...state,
        statements: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        statementLoading: false,
        error: false,
      };
    case types.GET_STATEMENTS_FAILED:
      return {
        ...state,
        statementLoading: false,
        error: true
      };

    case types.GET_MERCHANT_STATEMENTS:
      return {
        ...state,
        merchantStatementLoading: true,
        error: false
      };
    case types.GET_MERCHANT_STATEMENTS_SUCCEED:
      return {
        ...state,
        merchantStatements: action.data.data,
        merchantStatementLoading: false,
        error: false,
      };
    case types.GET_MERCHANT_STATEMENTS_FAILED:
      return {
        ...state,
        merchantStatementLoading: false,
        error: true
      };

    case types.GET_STATEMENT:
      return {
        ...state,
        statementLoading: true,
        error: false
      };
    case types.GET_STATEMENT_SUCCEED:
      return {
        ...state,
        statement: action.statement,
        statementLoading: false,
        error: false
      };
    case types.GET_STATEMENT_FAILED:
      return {
        ...state,
        statementLoading: false,
        error: true
      };
    case types.CHANGE_STATEMENT_STATUS:
      newStatementsList = state.statements || [];
      newStatementsList = newStatementsList.map(statement => {
        if (statement.guid === action.data.statement_guid)
          return { ...statement, ...action.data };
        else
          return statement;
      });
      newStatementsList = newStatementsList.map(statement => {
        if (statement.parent_guid === action.data.statement_guid && action.data.status === "Paid" )
          return { ...statement, status: "Paid" };
        else
          return statement;
      });
      if (newStatementsList.length !== 0)
        return { ...state, statements: newStatementsList };
      return state;

    case types.GET_STATEMENT_CURRENCY:
      return {
        ...state,
        statementCurrenciesLoading: true,
        error: false
      };
    case types.GET_STATEMENT_CURRENCY_SUCCEED:
      return {
        ...state,
        statementCurrencies: action.statement,
        statementCurrenciesLoading: false,
        error: false
      };
    case types.GET_STATEMENT_CURRENCY_FAILED:
      return {
        ...state,
        statementCurrenciesLoading: false,
        error: true
      };

    default:
      return state;
  }
}
