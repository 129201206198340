import moment from "moment";
import { connect } from "react-redux";
import { getAuditTransactionOverviewsTablesAction } from "../../../../actions/audit";
import {
  reset,
  searchInTransactionOverviewsAuditTable,
} from "../../../../actions/search";
import { resetPage, setNewTable } from "../../../../actions/store";
import AbstractComponent from "../../../../factories/Main";
import { cutGuid } from "../../../../helpers/cutGuid";
import { getAuditTransactionOverviewsTables } from "../../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../../UI/CopyToClipboard";
import React from "react";

const columns = [
  {
    path: "transaction_processing_guid",
    label: "Transaction processing guid",
    key: "transaction_processing_guid",
    content: (transaction) => {
      if (transaction.transaction_processing_guid) {
        return (
          <>
            {cutGuid(transaction.transaction_processing_guid)}
            <Copy text={transaction.transaction_processing_guid} />
          </>
        );
      } else return null;
    },
  },
  {
    path: "base_amount",
    label: "Base amount",
    key: "base_amount",
    align: "right",
  },
  {
    path: "to_bank_pct",
    label: "To bank (%)",
    key: "to_bank_pct",
    align: "right",
  },
  {
    path: "to_bank_fixed",
    label: "To bank (fixed)",
    key: "to_bank_fixed",
    align: "right",
  },
  { path: "to_client", label: "To client", key: "to_client", align: "right" },
  {
    path: "to_processor_pct",
    label: "To processor (%)",
    key: "to_processor_pct",
    align: "right",
  },
  {
    path: "to_processor_fixed",
    label: "To processor (fixed)",
    key: "to_processor_fixed",
    align: "right",
  },
  { path: "hold", label: "Hold", key: "hold", align: "right" },
  { path: "hold_date", label: "Hold date", key: "hold_date", align: "center" },
  { path: "hold_flag", label: "Hold flag", key: "hold_flag", align: "center" },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (group) =>
      moment(group.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.transactionOverviewsTables,
    count: state.audit.transactionOverviewsTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.transactionOverviewAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_transaction_overviews",
    keyPath: "guid",
    columns,
    exportFunction: getAuditTransactionOverviewsTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditTransactionOverviewsTablesAction,
  search: searchInTransactionOverviewsAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
