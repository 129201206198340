import React, { Component } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

class PasswordStrengthMeter extends Component {
  hasNumber = (value) => {
    return new RegExp(/[0-9]/).test(value);
  };
  hasMixed = (value) => {
    return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
  };
  hasSpecial = (value) => {
    return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
  };

  strengthIndicator = (value) => {
    let strengths = 0;
    if (value.length > 5) strengths++;
    if (value.length > 7) strengths++;
    if (this.hasNumber(value)) strengths++;
    if (this.hasSpecial(value)) strengths++;
    if (this.hasMixed(value)) strengths++;
    return strengths;
  };

  createPasswordLabel = (result) => {
    switch (result) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Strong";
    }
  };

  render() {
    const strength = this.strengthIndicator(this.props.value);
    const { password } = this.props;

    return (
      <div className="password-strength-meter">
        <Form.Control
          type="password"
          value={this.props.value}
          onChange={this.props.onChange}
        />
        {password && (
          <React.Fragment>
            <progress
              className={`password-strength-meter-progress strength-${this.createPasswordLabel(
                strength
              )}`}
              value={strength}
              max="4"
            />
            <br />
            <label className="password-strength-meter-label">
              <>
                <strong>Password strength:</strong>
                {this.createPasswordLabel(strength)}
              </>
            </label>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default PasswordStrengthMeter;

PasswordStrengthMeter.propTypes = {
  onChange: PropTypes.func,
  password: PropTypes.string,
  value: PropTypes.string,
};
