import React from "react";
import PieChart from "components/UI/PieChart";
import { getTransactionsCurrenciesDailyAction } from "actions/transactions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import { backgroundColors } from "constants/charts/colors";

class PieChartCurrencies extends React.Component {
  componentDidMount() {
    this.props.getTransactionsCurrenciesDailyAction({ days: 1 });
  }

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.params !== this.props.params) {
      this.props.getTransactionsCurrenciesDailyAction({ ...nextProps.params });
    }
    return true;
  };

  render() {
    if (this.props.loading) return <Spinner />;
    else {
      let datasetsPie = [];
      let labelsPie = [];
      let data = [];
      let colors = [];
      this.props.data.forEach((item, index) => {
        data.push(item.count);
        colors.push(backgroundColors[index]);
        datasetsPie = [ { data: data, backgroundColor: colors } ];
        labelsPie = [ ...labelsPie, item.currency ];
      });

      return (
        <PieChart
          name="Currencies"
          labels={
            labelsPie.length !== 0 ? labelsPie : [ "There are no currencies yet" ]
          }
          datasets={
            datasetsPie.length !== 0
              ? datasetsPie
              : [
                  {
                    data: [ 1 ],
                    backgroundColor: "#CCC",
                  },
                ]
          }
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.transactions.transactionCurrency,
    loading: state.transactions.transactionCurrencyLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionsCurrenciesDailyAction,
})(PieChartCurrencies);

PieChartCurrencies.propTypes = {
  data: PropTypes.array,
  getTransactionsCurrenciesDailyAction: PropTypes.func,
  loading: PropTypes.bool,
  params: PropTypes.object,
};
