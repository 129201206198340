import React from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";

function AdditionalFees({
  additional_fees = [],
  currencies = [],
  addAdditionalFee,
  handleChangeAdditionalFee,
  deleteAdditionalFees,
  additionalFeesNames = [],
  errors }) {
  return (
    <>
      <Row >
        <Col md={12} sm={12} xs={12}>
          <div style={{ paddingBottom: "15px" }}>
            <Button
              className={"btn "}
              onClick={addAdditionalFee}
            >
              Add additional fee
            </Button>
          </div>
        </Col>
      </Row>
      {additional_fees.length !== 0 ? (
        <>
          {additional_fees.map((fee, index) => (
            <Row key={index} >
              <Col md={1} sm={1} xs={1}>
                <Form.Label>{index + 1}.</Form.Label>
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Form.Group style={{ paddingLeft: "15px" }}>
                  {/* <Form.Control
                    name={"additional_fee_name_" + index}
                    type="text"
                    value={additional_fees[index].name}
                    placeholder="Enter name"
                    onChange={(e) =>
                      handleChangeAdditionalFee(index, "name", e)
                    }
                  /> */}

                  <MultiSelect
                    name={"additional_fee_name" + index}
                    value={fee.name}
                    options={additionalFeesNames}
                    multi={false}
                    onSelect={(e) =>
                      handleChangeAdditionalFee(index, "name", e)
                    }
                    placeholder="Select name"
                  />

                </Form.Group>
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Form.Group style={{ paddingLeft: "15px" }}>
                  <Form.Control
                    name={"additional_fee_value" + index}
                    type="number"
                    value={fee.value}
                    placeholder="Enter value"
                    onChange={(e) =>
                      handleChangeAdditionalFee(index, "value", e)
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Form.Group style={{ paddingLeft: "15px" }}>
                  <MultiSelect
                    name={"additional_fee_currency" + index}
                    value={fee.currency}
                    options={currencies}
                    multi={false}
                    onSelect={(e) =>
                      handleChangeAdditionalFee(index, "currency", e)
                    }
                    placeholder="Select currency"
                  />
                </Form.Group>
              </Col>
              <Col md={1} sm={1} xs={1}>
                <Form.Group>
                  <i
                    className="far fa-trash-alt"
                    style={{
                      cursor: "pointer",
                      color: "red",
                      fontSize: "20px",
                      paddingTop: "10px",
                    }}
                    onClick={() => {
                      deleteAdditionalFees(index);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
          {errors.additional_fees && (
            <span className="validate-error">
              {errors.additional_fees}
            </span>
          )}
        </>
      ) : null}


    </>
  );
}

export default AdditionalFees;