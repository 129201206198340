import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";

function CurrencyFilter({ currencies = [], callback }) {
  let [ checked, setChecked ] = useState(false);
  let [ selectedCurrencies, setSelectedCurrencies ] = useState([]);

  const onCheck = () => {
    setChecked(!checked);
  };

  const onSelect = (options) => {
    setSelectedCurrencies(options);
    callback && callback(options);
  };

  return (
    <>
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Filter terminals by currencies</Form.Label>
        </Col>
        <Col md={1} sm={1} xs={1}>
          <Form.Group>
            <input
              type="checkbox"
              id="setCurrencies"
              checked={checked}
              onChange={onCheck}
            />
          </Form.Group>
        </Col>

      </Row>

      {checked && (
        <Row >
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Currency</Form.Label>
          </Col>
          <Col md={8} sm={7} xs={7}>
            <Form.Group>
              <MultiSelect
                name="currencies"
                options={currencies}
                value={selectedCurrencies}
                multi={true}
                onSelect={onSelect}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
}

export default CurrencyFilter;