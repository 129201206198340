import React from "react";
import Content from "../Content";
import RateTemplateMain from "components/Rate/Template/Main";
import Modal from "components/UI/Modal";
// import Can from "components/Can";
import RateTemplateCreator from "components/Rate/Template/Creator";
import ability from "config/ability";

const RateTemplate = () => {
  return (
    <Content>
      <RateTemplateMain
        modalComponent={
          // <Can do="EXECUTE" on="RATES">
          <Modal
            allowed={ability.can("EXECUTE", "RATES")}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create template
              </button>
            }
            content={RateTemplateCreator}
            header="Create template"
            // dialogClassName="modal-creator"
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default RateTemplate;
