import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";

function Summary({ guid, summary, selectedCurrenciesRates, statement_currency_code }) {
  return (
    <>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Statement GUID:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{guid}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Merchant name:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.merchant_name}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Group:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.group_name}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Partner:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.partner_name}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Status:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.status}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Summary amount:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.summary_amount}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Summary count:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.summary_count}</span>
        </Col>
      </Row>
      {summary.parent_guid && (
        <Row>
          <Col md={6} sm={6} xs={6} className="right">
            <label>Parent:</label>
          </Col>
          <Col md={6} sm={6} xs={6} className="left">
            <span>{summary.parent_name}</span>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>From date:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>
            {moment(summary.from_date)
              .utcOffset(0)
              .format("D MMM YYYY")}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>To date:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>
            {moment(summary.to_date)
              .utcOffset(0)
              .format("D MMM YYYY")}
          </span>
        </Col>
      </Row>
      {selectedCurrenciesRates.map((currency) =>
        currency.code !== "EUR" ? (
          <Row key={currency.code + "bank_exchange_rate"}>
            <Col md={6} sm={6} xs={6} className="right">
              <label>
                bank exchange rate to {currency.code}:
              </label>
            </Col>
            <Col md={6} sm={6} xs={6} className="left">
              <span>
                {currency.bank_exchange_rate}
                {statement_currency_code}
              </span>
            </Col>
          </Row>
        ) : null
      )}
      {selectedCurrenciesRates.map((currency) =>
        currency.code !== "EUR" ? (
          <Row
            key={currency.code + "processor_exchange_rate"}
          >
            <Col md={6} sm={6} xs={6} className="right">
              <label>
                processor exchange rate to {currency.code}:
              </label>
            </Col>
            <Col md={6} sm={6} xs={6} className="left">
              <span>
                {currency.processor_exchange_rate}
                {statement_currency_code}
              </span>
            </Col>
          </Row>
        ) : null
      )}
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Created at:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>
            {summary.created_at !== null
              ? moment(summary.created_at)
                .utcOffset(0)
                .format("D MMM YYYY")
              : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Created by:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.created_by_username}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Updated at:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>
            {summary.updated_at !== null
              ? moment(summary.updated_at)
                .utcOffset(0)
                .format("D MMM YYYY")
              : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={6} className="right">
          <label>Updated by:</label>
        </Col>
        <Col md={6} sm={6} xs={6} className="left">
          <span>{summary.updated_by_username}</span>
        </Col>
      </Row>
    </>
  );
}

export default Summary;