import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import MultiSelect from "components/UI/MultiSelect";
import { connect } from "react-redux";
import { getAllMerchants } from "../../actions/merchants";
import { getAllGroups } from "../../actions/groups";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import { addMerchantToGroupAction } from "../../actions/groups";
import { addGroupToPartnerAction } from "../../actions/partners";
import Alert from "../UI/Alert";

class RowAddUser extends Component {
  state = {
    selectedUser: "",
    users: [],
    needReason: false,
    userGuid: "",
    reason: "",
  };

  componentDidMount = async () => {
    if (this.props.type === "merchant") {
      await this.props.getAllMerchants();
      const notGroupMerchants = this.props.merchants
        .filter((merchant) => merchant.group_guid === null)
        .map((item) => {
          item.name = item.merchant_name;
          item.guid = item.merchant_guid;
          return item;
        });
      this.setState({
        users: notGroupMerchants,
      });
    } else if (this.props.type === "group") {
      await this.props.getAllGroups();
      const notPartnerGroup = this.props.groups
        .filter((group) => group.partner_guid === null)
        .map((item) => {
          item.name = item.group_name;
          item.guid = item.group_guid;
          return item;
        });
      this.setState({
        users: notPartnerGroup,
      });
    }
  };

  handleUserSelect = (option) => {
    this.setState({
      selectedUser: option.guid,
      needReason: true,
    });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    this.setState({ reason: input.value });
  };

  handleSubmit = async () => {
    if (
      this.state.selectedMerchant === "" ||
      (this.state.reason === "" && this.state.needReason)
    )
      swal({
        title: "Please, enter information in every required field",
        icon: "warning",
      });
    else {
      let data;
      try {
        switch (this.props.type) {
          case "merchant":
            data = {
              merchant_guid: this.state.selectedUser,
              group_guid: this.props.id,
              reason: this.state.reason,
            };
            await this.props.addMerchantToGroupAction(data);
            swal({
              title: "Merchant is added",
              icon: "success",
              button: false,
              timer: 2000,
            });
            await this.props.update(this.props.id);
            break;
          case "group":
            data = {
              group_guid: this.state.selectedUser,
              partner_guid: this.props.id,
              reason: this.state.reason,
            };
            await this.props.addGroupToPartnerAction(data);
            swal({
              title: "Group is added",
              icon: "success",
              button: false,
              timer: 2000,
            });
            await this.props.update(this.props.id);
            break;
          default:
            break;
        }
      } catch (error) {
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { users } = this.state;
    return (
      <div style={{ textAlign: "center" }}>
        <label>
          {this.props.type !== "merchant"
            ? this.props.type === "group"
              ? "Group"
              : ""
            : "Merchant"}
        </label>
        <MultiSelect
          options={users ? users : []}
          multi={false}
          onSelect={this.handleUserSelect}
        />
        {this.state.needReason && (
          <div>
            <label>Reason</label>
            <Form.Group>
              <Form.Control
                placeholder="Enter reason"
                name="reason"
                value={this.state.reason}
                onChange={this.handleChangeReason}
              />
            </Form.Group>
          </div>
        )}
        <Button
          className={
            this.state.selectedUser === "" ||
              (this.state.reason === "" && this.state.needReason)
              ? "btn btn-fill"
              : "btn btn-fill btn-success"
          }
          onClick={this.handleSubmit}
          style={{ margin: "15px" }}
        >
          Add
          {this.props.type !== "merchant"
            ? this.props.type === "group"
              ? "group"
              : ""
            : "merchant"}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    groups: state.groups.groupsList,
  };
};

export default connect(mapStateToProps, {
  getAllMerchants,
  getAllGroups,
  addMerchantToGroupAction,
  addGroupToPartnerAction,
})(RowAddUser);

RowAddUser.propTypes = {
  addGroupToPartnerAction: PropTypes.func,
  addMerchantToGroupAction: PropTypes.func,
  getAllGroups: PropTypes.func,
  getAllMerchants: PropTypes.func,
  groups: PropTypes.array,
  id: PropTypes.string,
  merchants: PropTypes.array,
  type: PropTypes.string,
  update: PropTypes.func,
};
