import React from "react";
import Content from "../../Content";
import ServiceAuditMain from "components/Audit/ServiceTable/Main";

const ServiceAudit = () => {
  return (
    <Content>
      <ServiceAuditMain />
    </Content>
  );
};

export default ServiceAudit;
