import * as types from "../constants/actionTypes";
import {
  getBlackListItems,
  createBlackListItem,
  getBlackListItem,
  updateBlackListItem,
  deleteBlackListItem,
  createBlackListRule,
  getBlackListGlobal,
  getMerchantsBlackList,
  createItemGlobalBlackList,
  deleteItemGlobalBlackList,
  createItemMerchantsBlackList,
  deleteItemMerchantsBlackList,
} from "../services/paymentBackendAPI/management/blacklists";
import { loadTable } from "./store";

export const getAllBlackListItems = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_BLACKLIST_ITEMS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getBlackListItems(args);
      dispatch({
        type: types.GET_BLACKLIST_ITEMS_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page,
      });
    } catch (error) {
      dispatch({
        type: types.GET_BLACKLIST_ITEMS_FAILED,
      });
    }
  };
};

export const getBlackListItemAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_BLACKLIST_ITEM,
    });
    try {
      const response = await getBlackListItem(id);
      return dispatch({
        type: types.GET_BLACKLIST_ITEM_SUCCEED,
        blackItem: response.data,
      });
    } catch (error) {
      return dispatch({
        type: types.GET_BLACKLIST_ITEM_FAILED,
      });
    }
  };
};

export const addBlackListItemAction = (data, page, items, search) => {
  return async (dispatch) => {
    let { data: response } = await createBlackListItem(data);
    dispatch(getAllBlackListItems({ page, items, ...search }));
    return response;
  };
};

export const addBlackListRuleAction = (data) => {
  return async (dispatch) => {
    await createBlackListRule(data);
    dispatch({
      type: types.ADD_BLACKLIST_RULE,
      rule: data.value,
    });
  };
};

export const deleteBlackListRuleAction = (data) => {
  return async (dispatch) => {
    const response = await createBlackListRule(data);
    dispatch({
      type: types.DELETE_BLACKLIST_RULE,
      rule: response.data.value,
    });
  };
};

export const deleteBlackListItemAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteBlackListItem(data);
    return dispatch(getAllBlackListItems({ page, items, ...search }));
  };
};

export const updateBlackListItemAction = (data) => {
  return async (dispatch) => {
    const response = await updateBlackListItem(data);
    dispatch({
      type: types.UPDATE_BLACKLIST_ITEM,
      blackItem: response.data,
    });
  };
};

export const getBlackListGlobalAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_BLACKLIST_GLOBAL,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getBlackListGlobal(args);
      dispatch({
        type: types.GET_BLACKLIST_GLOBAL_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page,
      });
    } catch (error) {
      dispatch({
        type: types.GET_BLACKLIST_GLOBAL_FAILED,
      });
    }
  };
};

export const getMerchantsBlackListAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_BLACKLIST_MERCHANTS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getMerchantsBlackList(args);
      dispatch({
        type: types.GET_BLACKLIST_MERCHANTS_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page,
      });
    } catch (error) {
      dispatch({
        type: types.GET_BLACKLIST_MERCHANTS_FAILED,
      });
    }
  };
};

export const addBlackListMerchantItemAction = (data, page, items, search) => {
  return async (dispatch) => {
    await createBlackListItem(data);
    return dispatch(getMerchantsBlackListAction({ page, items, ...search }));
  };
};

export const createItemGlobalBlackListAction = (data, page, items, search) => {
  return async (dispatch) => {
    await createItemGlobalBlackList(data);
    return dispatch(getBlackListGlobalAction({ page, items, ...search }));
  };
};

export const deleteItemGlobalBlackListAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteItemGlobalBlackList(data);
    return dispatch(getBlackListGlobalAction({ page, items, ...search }));
  };
};

export const createItemMerchantsBlackListAction = (
  data,
  page,
  items,
  search
) => {
  return async (dispatch) => {
    await createItemMerchantsBlackList(data);
    return dispatch(getMerchantsBlackListAction({ page, items, ...search }));
  };
};

export const deleteItemMerchantsBlackListAction = (
  data,
  page,
  items,
  search
) => {
  return async (dispatch) => {
    await deleteItemMerchantsBlackList(data.guid, data.reason);
    return dispatch(getMerchantsBlackListAction({ page, items, ...search }));
  };
};
