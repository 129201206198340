import React from "react";
import SidebarItem from "../Item";
import SidebarCollapseItem from "../CollapseItem";
import PropTypes from "prop-types";

const SidebarWrapper = ({ routes }) => {
  return (
    <div className="sidebar-wrapper">
      <ul className="nav">
        {routes.map((item, key) => {
          if (!item.redirect && !item.collapse)
            return <SidebarItem key={key} item={item} />;
          else if (item.collapse)
            return <SidebarCollapseItem key={key} item={item} />;
          return null;
        })}
      </ul>
    </div>
  );
};

export default SidebarWrapper;

SidebarWrapper.propTypes = {
  routes: PropTypes.array,
};
