import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllChargebacks } from "../../actions/chargebacks";
import { reset, searchInChargebacks } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { cutGuid } from "../../helpers/cutGuid";
import { getChargebacks } from "../../services/paymentBackendAPI/management/chargeback";
import { Copy } from "../UI/CopyToClipboard";

const columns = [
  {
    path: "guid",
    label: "Chargeback ID",
    content: (chargeback) => (
      <>
        <Link className="link" to={`/about/chargeback/${chargeback.guid}`}>
          {cutGuid(chargeback.guid)}
        </Link>
        <Copy text={chargeback.guid} />
      </>
    ),
    textContent: (chargeback) => chargeback.guid,
    style: { minWidth: "120px" },
  },
  {
    path: "created_at",
    label: "Created at",
    key: "chargeback_created_at",
    content: (chargeback) =>
      moment(chargeback.created_at)
        .utcOffset(0)
        .format("DD.MM.YYYY | HH:mm:ss"),
    align: "center",
  },
  {
    path: "transaction_processing_guid",
    label: "Transaction ID",
    key: "transaction_processing_guid",
    content: (order) => (
      <>
        <Copy text={order.transaction_processing_guid} />
        <Link
          className="link"
          to={`/about/order/${order.transaction_processing_guid}`}
        >
          {cutGuid(order.transaction_processing_guid)}
        </Link>
      </>
    ),
    textContent: (order) => order.transaction_processing_guid,
    style: { minWidth: "120px" },
  },
  { path: "card_type", label: "Card type", key: "card_type_chargeback" },
  {
    path: "reason_code",
    label: "Reason code",
    key: "reason_code",
    align: "center",
  },
  {
    path: "merchant_name",
    label: "Merchant name",
    key: "chargeback_merchant_name",
    content: (merchant) => (
      <Link className="link" to={`/about/merchant/${merchant.merchant_guid}`}>
        {merchant.merchant_name}
      </Link>
    ),
    textContent: (merchant) => merchant.merchant_name,
  },
  {
    path: "shop_name",
    label: "Shop",
    key: "shop_name",
    content: (shop) => (
      <Link className="link" to={`/about/shop/${shop.shop_guid}`}>
        {shop.shop_name}
      </Link>
    ),
    textContent: (shop) => shop.shop_name,
  },
  {
    path: "terminal_guid",
    label: "Terminal",
    key: "terminal_guid",
    content: (terminal) => (
      <>
        <Copy text={terminal.terminal_guid} />
        <Link
          className="link"
          to={`/about/${terminal.shop_guid}/terminal/${terminal.terminal_guid}`}
        >
          {cutGuid(terminal.terminal_guid)}
        </Link>
      </>
    ),
    textContent: (terminal) => terminal.terminal_guid,
    style: { minWidth: "120px" },
  },
  {
    path: "chb_amount",
    label: "Chargeback amount",
    key: "chb_amount",
    align: "right",
  },
  {
    path: "chb_proc_date",
    label: "Chargeback process date",
    key: "chb_proc_date",
    align: "center",
  },
  {
    path: "tr_amount",
    label: "Transaction amount",
    key: "tr_amount",
    align: "right",
  },
  {
    path: "tr_date_time",
    label: "Transaction process date",
    key: "tr_date_time",
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.chargebacks.chargebacksList,
    count: state.chargebacks.count,
    table: state.store.table,
    loading: state.chargebacks.chargebacksLoading,
    searchData: state.search.chargebacksSearch,
    isSearch: state.search.isSearch,
    name: "chargeback",
    keyPath: "guid",
    columns,
    exportFunction: getChargebacks,
  };
};

export default connect(mapStateToProps, {
  get: getAllChargebacks,
  search: searchInChargebacks,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
