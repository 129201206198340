import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { closeWorkingDayDectaAction } from "actions/services";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import Alert from "../UI/Alert";

export class CloseWorkingDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantId: "",

      isLoading: false,
      errors: {},

      merchantIdValidation: false,
    };
  }

  schema = {
    merchantId: Joi.string().required().label("merchantId"),
  };

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        merchantId: this.state.merchantId,
      },
      this.schema,
      options
    );

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      [input.name]: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      let data = {
        merchantId: this.state.merchantId,
      };
      try {
        await this.props.closeWorkingDayDectaAction(data);
        swal({
          title: "Working day is closed",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off">
        <Row>
          <Col className="col-md-3">
            <Form.Label>merchantId*:</Form.Label>
          </Col>
          <Col className="col-md-9">
            <Form.Group validationState={this.formValidation("merchantId")}>
              <Form.Control
                name="merchantId"
                type="text"
                value={this.state.merchantId}
                onChange={this.handleChange}
              />
              {errors.merchantId && (
                <span className="validate-error">{errors.merchantId}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className={
                this.state.merchantIdValidation
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
            >
              Send
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  closeWorkingDayDectaAction,
  showModal,
})(CloseWorkingDay);

CloseWorkingDay.propTypes = {
  closeWorkingDayDectaAction: PropTypes.func,
  showModal: PropTypes.func,
};
