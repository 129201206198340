import React, { Component } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Button from "components/UI/CustomButton/CustomButton";
import MultiSelect from "components/UI/MultiSelect";
import PhoneInput from "../UI/PhoneInput";
import { connect } from "react-redux";
import { addMerchantLoginAction } from "../../actions/merchants";
import { checkUserExistsAction } from "../../actions/users";
import { getAllRoles } from "../../actions/roles";
import { addGroupLoginAction } from "../../actions/groups";
import { addPartnerLoginAction } from "../../actions/partners";
import { showModal } from "../../actions/modal";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import Joi from "joi-browser";
import Spinner from "components/UI/Spinner/index";
import ability from "config/ability";
import Alert from "../UI/Alert";

class EditLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        login_guid: "",
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        companyName: "",
        companyAddress: "",

        language: "",
        role_guid: "",
        phone: "",
        enabled: true,
        authType: false,
      },
      oldData: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        companyName: "",
        companyAddress: "",
        language: "",
        phone: "",
        role_guid: "",
        enabled: "",
        authType: false,
      },
      needReason: false,
      reason: "",

      phoneExists: "",
      emailExists: "",
      usernameExists: "",
      errorPhoneExists: "",
      errorEmailExists: "",
      errorUsernameExists: "",
      errorsExists: {},
      errors: {},
      warning: "",

      token: {},
      isLoading: false,
      loginLoading: false,
      isAdmin: "",

      usernameValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      roleValidation: false,
      phoneValidation: false,
      companyNameValidation: false,
      companyAddressValidation: false,
      reasonValidation: false,
    };
  }

  schema = {
    login_guid: Joi.string().required(),
    username: Joi.string().required().min(5).label("Username"),
    email: Joi.string().required().email().label("Email"),
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    companyName: Joi.string().required().label("Company name"),
    companyAddress: Joi.string().required().label("Company address"),
    phone: Joi.string().required().min(5).label("Phone"),
    role_guid: Joi.string().required().label("Role"),
    language: Joi.string().required().label("Language"),
    enabled: Joi.boolean().required().label("Enabled"),
    authType: Joi.any().required(),
  };

  async componentDidMount() {
    this.setState({ loginLoading: true });
    const token = getAuthData().userPayload;
    this.setState({ token });
    await this.props.getAllRoles({ type: this.props.type });
    this.setState({
      data: {
        login_guid: this.props.data.guid,
        firstName: this.props.data.first_name,
        lastName: this.props.data.last_name,
        email: this.props.data.email,
        username: this.props.data.username,
        companyName: this.props.data.company_name,
        companyAddress: this.props.data.company_address,
        language: this.props.data.language,
        phone: this.props.data.phone,
        enabled: this.props.data.enabled,
        role_guid: this.props.data.role.guid,
        authType: this.props.data.auth_type === "login-password-mail",
      },
      oldData: {
        firstName: this.props.data.first_name,
        lastName: this.props.data.last_name,
        companyName: this.props.data.company_name,
        companyAddress: this.props.data.company_address,
        username: this.props.data.username,
        email: this.props.data.email,
        language: this.props.data.language,
        phone: this.props.data.phone,
        role_guid: this.props.data.role.guid,
        enabled: this.props.data.enabled,
        authType: this.props.data.auth_type === "login-password-mail",
      },
      warning: this.props.data.warning || undefined,
      loginLoading: false,
      roleOption: this.props.roles.filter(
        (role) => this.props.data.role.guid === role.guid
      )[0],
      languageOption: {
        name: this.props.data.language,
        label: this.props.data.language,
        value: this.props.data.language,
        guid: this.props.data.language,
      },
      usernameValidation: this.props.data.username ? true : false,
      firstNameValidation: this.props.data.first_name ? true : false,
      lastNameValidation: this.props.data.last_name ? true : false,
      roleValidation: this.props.data.role ? true : false,
      phoneValidation: this.props.data.phone ? true : false,
      companyNameValidation: this.props.data.company_name ? true : false,
      companyAddressValidation: this.props.data.company_address ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = { ...this.state.data, reason: this.state.reason };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = { ...this.state.data };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (name === "username" && this.state.errorUsernameExists) return "error";
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== this.state.data[prop];
    }
    this.setState({ needReason: changedData });
    if (!changedData) {
      let errors = { ...this.state.errors };
      delete errors.reason;
      this.setState({ errors, reason: "" });
    }
  };

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState(
      {
        data,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );

    if (
      input.name === "username" &&
      input.value !== "" &&
      input.value !== this.state.oldData.username &&
      input.value.length > 4
    ) {
      await this.props.checkUserExistsAction({ username: input.value });

      this.setState({
        usernameExists: this.props.usernameExists,
      });

      if (this.state.usernameExists)
        this.setState({
          errorUsernameExists: "Username exists",
          usernameValidation: false,
        });
      else
        this.setState({
          errorUsernameExists: "",
          usernameValidation: errorMessage ? false : true,
        });
    }
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name: "phone", value: value });
    if (errorMessage) errors.phone = errorMessage;
    else delete errors.phone;

    let data = { ...this.state.data };
    data.phone = value;
    this.setState(
      { data, errors, phoneValidation: errorMessage ? false : true },
      () => this.showReason()
    );

    if (value.length > 4 && value !== this.state.oldData.phone)
      await this.props.checkUserExistsAction({ phone: value });

    this.setState({
      phoneExists: this.props.phoneExists,
    });

    if (this.state.phoneExists)
      this.setState({
        errorPhoneExists: `Phone ${value} exists`,
      });
    else
      this.setState({
        errorPhoneExists: "",
      });
  };

  onChangeEmail = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors }, () => this.showReason());

    const errorsExists = this.validate();
    this.setState({ errorsExists: errorsExists || {} });

    try {
      if (
        input.value.length > 8 &&
        input.value !== this.state.oldData.email &&
        this.state.errorsExists.email === undefined &&
        input.value.indexOf(".") !== -1
      )
        await this.props.checkUserExistsAction({ email: input.value });
    } catch {
      this.setState({
        errorEmailExists: "Email must be a valid",
      });
    }

    this.setState({
      emailExists: this.props.emailExists,
    });

    if (this.state.emailExists)
      this.setState({
        errorEmailExists: "Email exists",
        emailValidation: false,
      });
    else
      this.setState({
        errorEmailExists: "",
        emailValidation: errorMessage ? false : true,
      });
  };

  onSelectRole = (option) => {
    let data = { ...this.state.data };
    data.role_guid = option.guid;

    this.setState(
      {
        data,
        role: option.name,
        roleValidation: true,
      },
      () => this.showReason()
    );
  };

  onSelectLanguage = (option) => {
    let data = { ...this.state.data };
    data.language = option.name;

    this.setState(
      {
        data,
      },
      () => this.showReason()
    );
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleEnabledCheckbox = () => {
    let data = { ...this.state.data };
    data.enabled = !this.state.data.enabled;

    this.setState({ data }, () => this.showReason());
  };

  handleAuthTypeCheckbox = () => {
    let data = { ...this.state.data };
    data.authType = !this.state.data.authType;
    this.setState({ data }, () => this.showReason());
  };

  renderInput = (label, value, name, type = "text") => {
    const { errors } = this.state;
    return (
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>{label}*</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group validationState={this.formValidation(name)}>
            <Form.Control
              placeholder={`Enter ${label.toLowerCase()}`}
              type={type}
              name={name}
              value={value}
              onChange={this.handleChange}
            />
            {errors[name] && (
              <span className="validate-error">{errors[name]}</span>
            )}
            {name === "username" && this.state.errorUsernameExists && (
              <span className="validate-error">
                {this.state.errorUsernameExists}
              </span>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });

      const data = {
        login_guid: this.state.data.login_guid,
        email: this.state.data.email,
        username: this.state.data.username,
        phone: this.state.data.phone,
        first_name: this.state.data.firstName,
        last_name: this.state.data.lastName,
        company_name: this.state.data.companyName,
        company_address: this.state.data.companyAddress,
        role_guid: this.state.data.role_guid,
        language: this.state.data.language,
        reason: this.state.needReason ? this.state.reason : undefined,
        enabled: this.state.data.enabled === true ? 1 : 0,
        auth_type: this.state.data.authType
          ? "login-password-mail"
          : "login-password",
      };
      if (this.state.needReason) {
        try {
          switch (this.props.type) {
            case "merchant": {
              await this.props.addMerchantLoginAction(this.props.id, data);
              swal({
                title: "Login is updated",
                icon: "success",
                button: false,
                timer: 2000,
              });
              await this.props.update(this.props.id);
              break;
            }
            case "group": {
              await this.props.addGroupLoginAction(this.props.id, data);
              swal({
                title: "Login is updated",
                icon: "success",
                button: false,
                timer: 2000,
              });
              await this.props.update(this.props.id);
              break;
            }
            case "partner": {
              await this.props.addPartnerLoginAction(this.props.id, data);
              swal({
                title: "Login is updated",
                icon: "success",
                button: false,
                timer: 2000,
              });
              await this.props.update(this.props.id);
              break;
            }
            default:
              break;
          }
          await this.props.handleClose();
        } catch (error) {
          this.setState({ isLoading: false });
          const parsedError = parseResponse(error);
          Alert({ type: "error", message: parsedError.message });
        }
      } else await this.props.handleClose();
    }
  };

  render() {
    let roles = this.props.roles ? this.props.roles : [];

    const { errors } = this.state;

    if (this.state.loginLoading) {
      return <Spinner />;
    } else
      return (
        <Form>
          <Row>
            <Col md={6} sm={6} xs={6}>
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Email*</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      name="email"
                      value={this.state.data.email}
                      onChange={(e) => this.onChangeEmail(e)}
                      readOnly={
                        this.props.type === "partner" &&
                        ability.can("EXECUTE", "PARTNERLOGINEMAIL")
                          ? false
                          : this.props.type === "group" &&
                            ability.can("EXECUTE", "GROUPLOGINEMAIL")
                            ? false
                            : this.props.type === "merchant" &&
                            ability.can("EXECUTE", "MERCHANTLOGINEMAIL")
                              ? false
                              : true
                      }
                    />
                    {errors.email && (
                      <span className="validate-error">{errors.email}</span>
                    )}
                    {this.state.errorEmailExists && (
                      <span className="validate-error">
                        {this.state.errorEmailExists}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {this.renderInput(
                "Username",
                this.state.data.username,
                "username"
              )}
              {this.renderInput(
                "First name",
                this.state.data.firstName,
                "firstName"
              )}
              {this.renderInput(
                "Last name",
                this.state.data.lastName,
                "lastName"
              )}
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Role*</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <MultiSelect
                      name="Roles"
                      options={roles}
                      multi={false}
                      onSelect={this.onSelectRole}
                      defaultValue={this.state.roleOption}
                      placeholder="Select role"
                    />
                    {errors.role && (
                      <span className="validate-error">{errors.role}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={6} sm={6} xs={6}>
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Phone*</Form.Label>
                </Col>
                <Col md={8} sm={8} xs={7}>
                  <Form.Group>
                    <PhoneInput
                      name="phone"
                      placeholder="Enter phone number"
                      value={this.state.data.phone}
                      onChange={(e) => this.onChangeNumber(e)}
                    />
                    {this.state.errorPhoneExists && (
                      <span className="validate-error">
                        {this.state.errorPhoneExists}
                      </span>
                    )}
                    {errors.phone && (
                      <span className="validate-error">{errors.phone}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {this.renderInput(
                "Company name",
                this.state.data.companyName,
                "companyName"
              )}
              {this.renderInput(
                "Company address",
                this.state.data.companyAddress,
                "companyAddress"
              )}
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Enable*</Form.Label>
                </Col>
                <Col md={1} sm={1} xs={1}>
                  <Form.Group>
                    <input
                      type="checkbox"
                      id="enabledCheckbox"
                      checked={this.state.data.enabled}
                      onChange={this.handleEnabledCheckbox}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Two-factor authentication*</Form.Label>
                </Col>
                <Col md={1} sm={1} xs={1}>
                  <Form.Group>
                    <input
                      type="checkbox"
                      id="enabledCheckbox"
                      disabled={
                        !ability.can(
                          "EXECUTE",
                          this.props.type.toUpperCase() + "LOGINAUTHTYPE"
                        )
                      }
                      checked={this.state.data.authType}
                      onChange={this.handleAuthTypeCheckbox}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Language</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <MultiSelect
                      name="Language"
                      options={[
                        { name: "en", guid: "en" },
                        { name: "ru", guid: "ru" },
                      ]}
                      multi={false}
                      defaultValue={this.state.languageOption}
                      onSelect={this.onSelectLanguage}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {this.state.needReason && (
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Reason*</Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Group>
                      <Form.Control
                        placeholder="Enter reason"
                        name="reason"
                        value={this.state.reason}
                        onChange={this.handleChangeReason}
                      />
                      {errors.reason && (
                        <span className="validate-error">{errors.reason}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {this.state.warning && (
            <Row>
              <Col md={8} sm={8} xs={8}>
                <span className="validate-error">{this.state.warning}</span>
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.usernameValidation &&
                  this.state.firstNameValidation &&
                  this.state.lastNameValidation &&
                  this.state.roleValidation &&
                  this.state.phoneValidation &&
                  this.state.companyNameValidation &&
                  this.state.companyAddressValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            )}
          </div>
          <Col sm={1} />
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    emailExists: state.users.users.email_exists,
    phoneExists: state.users.users.phone_exists,
    roles: state.roles.rolesList,
    usernameExists: state.users.users.username_exists,
  };
};

export default connect(mapStateToProps, {
  getAllRoles,
  addMerchantLoginAction,
  addGroupLoginAction,
  addPartnerLoginAction,
  checkUserExistsAction,
  showModal,
})(EditLogin);

EditLogin.propTypes = {
  addGroupLoginAction: PropTypes.func,
  addMerchantLoginAction: PropTypes.func,
  addPartnerLoginAction: PropTypes.func,
  checkUserExistsAction: PropTypes.func,
  data: PropTypes.object,
  emailExists: PropTypes.bool,
  getAllRoles: PropTypes.func,
  id: PropTypes.string,
  phoneExists: PropTypes.bool,
  roles: PropTypes.array,
  showModal: PropTypes.func,
  type: PropTypes.string,
  update: PropTypes.func,
  usernameExists: PropTypes.bool,
};
