import * as types from "../constants/actionTypes";

const initialState = {
  chargebacksList: [],

  chargeback: {},
  chargebackLoading: false,
  chargebacksLoading: false,
  error: false,
};

export default function chargebacks(state = initialState, action) {
  switch (action.type) {
    case types.GET_CHARGEBACKS:
      return {
        ...state,
        chargebacksList: [],
        chargebacksLoading: true,
        error: false
      };
    case types.GET_CHARGEBACKS_SUCCEED:
      return {
        ...state,
        chargebacksList: action.data,
        count: action.count,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        chargebacksLoading: false,
        error: false
      };
    case types.GET_CHARGEBACKS_FAILED:
      return {
        ...state,
        chargebacksLoading: false,
        error: true
      };

    case types.GET_CHARGEBACK:
      return {
        ...state,
        chargeback: {},
        chargebackLoading: true,
        error: false
      };
    case types.GET_CHARGEBACK_SUCCEED:
      return {
        ...state,
        chargeback: action.data,
        chargebackLoading: false,
        error: false
      };
    case types.GET_CHARGEBACK_FAILED:
      return {
        ...state,
        chargebackLoading: false,
        error: true
      };
    default:
      return state;
  }
}