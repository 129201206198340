export const countries = [
  { guid: "1", name: "AU" },
  { guid: "2", name: "AT" },
  { guid: "3", name: "AZ" },
  { guid: "4", name: "AL" },
  { guid: "5", name: "DZ" },
  { guid: "6", name: "AI" },
  { guid: "7", name: "AO" },
  { guid: "8", name: "AD" },
  { guid: "9", name: "AQ" },
  { guid: "10", name: "AG" },
  { guid: "11", name: "AN" },
  { guid: "12", name: "AR" },
  { guid: "13", name: "AM" },
  { guid: "14", name: "AW" },
  { guid: "15", name: "AF" },
  { guid: "16", name: "BS" },
  { guid: "17", name: "BD" },
  { guid: "18", name: "BB" },
  { guid: "19", name: "BH" },
  { guid: "20", name: "BY" },
  { guid: "21", name: "BZ" },
  { guid: "22", name: "BE" },
  { guid: "23", name: "BJ" },
  { guid: "24", name: "BM" },
  { guid: "25", name: "BV" },
  { guid: "26", name: "BG" },
  { guid: "27", name: "BO" },
  { guid: "28", name: "BA" },
  { guid: "29", name: "BW" },
  { guid: "30", name: "BR" },
  { guid: "31", name: "BN" },
  { guid: "32", name: "BF" },
  { guid: "33", name: "BI" },
  { guid: "34", name: "BT" },
  { guid: "35", name: "VU" },
  { guid: "36", name: "VA" },
  { guid: "37", name: "GB" },
  { guid: "38", name: "HU" },
  { guid: "39", name: "VE" },
  { guid: "40", name: "VG" },
  { guid: "41", name: "VI" },
  { guid: "42", name: "AS" },
  { guid: "43", name: "TP" },
  { guid: "44", name: "VN" },
  { guid: "45", name: "GA" },
  { guid: "46", name: "HT" },
  { guid: "47", name: "GY" },
  { guid: "48", name: "GM" },
  { guid: "49", name: "GH" },
  { guid: "50", name: "GP" },
  { guid: "51", name: "GT" },
  { guid: "52", name: "GN" },
  { guid: "53", name: "GW" },
  { guid: "54", name: "DE" },
  { guid: "55", name: "GI" },
  { guid: "56", name: "HN" },
  { guid: "57", name: "HK" },
  { guid: "58", name: "GD" },
  { guid: "59", name: "GL" },
  { guid: "60", name: "GR" },
  { guid: "61", name: "GE" },
  { guid: "62", name: "GU" },
  { guid: "63", name: "DK" },
  { guid: "64", name: "CD" },
  { guid: "65", name: "DJ" },
  { guid: "66", name: "DM" },
  { guid: "67", name: "DO" },
  { guid: "68", name: "EG" },
  { guid: "69", name: "ZM" },
  { guid: "70", name: "EH" },
  { guid: "71", name: "ZW" },
  { guid: "72", name: "IL" },
  { guid: "73", name: "IN" },
  { guid: "74", name: "ID" },
  { guid: "75", name: "JO" },
  { guid: "76", name: "IQ" },
  { guid: "77", name: "IR" },
  { guid: "78", name: "IE" },
  { guid: "79", name: "IS" },
  { guid: "80", name: "ES" },
  { guid: "81", name: "IT" },
  { guid: "82", name: "YE" },
  { guid: "83", name: "CV" },
  { guid: "84", name: "KZ" },
  { guid: "85", name: "KY" },
  { guid: "86", name: "KH" },
  { guid: "87", name: "CM" },
  { guid: "88", name: "CA" },
  { guid: "89", name: "QA" },
  { guid: "90", name: "KE" },
  { guid: "91", name: "CY" },
  { guid: "92", name: "KG" },
  { guid: "93", name: "KI" },
  { guid: "94", name: "CN" },
  { guid: "95", name: "CC" },
  { guid: "96", name: "CO" },
  { guid: "97", name: "KM" },
  { guid: "98", name: "CG" },
  { guid: "99", name: "CR" },
  { guid: "100", name: "CI" },
  { guid: "101", name: "CU" },
  { guid: "102", name: "KW" },
  { guid: "103", name: "CK" },
  { guid: "104", name: "LA" },
  { guid: "105", name: "LV" },
  { guid: "106", name: "LS" },
  { guid: "107", name: "LR" },
  { guid: "108", name: "LB" },
  { guid: "109", name: "LY" },
  { guid: "110", name: "LT" },
  { guid: "111", name: "LI" },
  { guid: "112", name: "LU" },
  { guid: "113", name: "MU" },
  { guid: "114", name: "MR" },
  { guid: "115", name: "MG" },
  { guid: "116", name: "YT" },
  { guid: "117", name: "MO" },
  { guid: "118", name: "MK" },
  { guid: "119", name: "MW" },
  { guid: "120", name: "MY" },
  { guid: "121", name: "ML" },
  { guid: "122", name: "MV" },
  { guid: "123", name: "MT" },
  { guid: "124", name: "MA" },
  { guid: "125", name: "MQ" },
  { guid: "126", name: "MH" },
  { guid: "127", name: "MX" },
  { guid: "128", name: "FM" },
  { guid: "129", name: "MZ" },
  { guid: "130", name: "MD" },
  { guid: "131", name: "MC" },
  { guid: "132", name: "MN" },
  { guid: "133", name: "MS" },
  { guid: "134", name: "MM" },
  { guid: "135", name: "NA" },
  { guid: "136", name: "NR" },
  { guid: "137", name: "NP" },
  { guid: "138", name: "NE" },
  { guid: "139", name: "NG" },
  { guid: "140", name: "NL" },
  { guid: "141", name: "NI" },
  { guid: "142", name: "NU" },
  { guid: "143", name: "NZ" },
  { guid: "144", name: "NC" },
  { guid: "145", name: "NO" },
  { guid: "146", name: "NF" },
  { guid: "147", name: "AE" },
  { guid: "148", name: "OM" },
  { guid: "149", name: "PK" },
  { guid: "150", name: "PW" },
  { guid: "151", name: "PS" },
  { guid: "152", name: "PA" },
  { guid: "153", name: "PG" },
  { guid: "154", name: "PY" },
  { guid: "155", name: "PE" },
  { guid: "156", name: "PN" },
  { guid: "157", name: "PL" },
  { guid: "158", name: "PT" },
  { guid: "159", name: "PR" },
  { guid: "160", name: "RE" },
  { guid: "161", name: "CX" },
  { guid: "162", name: "RU" },
  { guid: "163", name: "RW" },
  { guid: "164", name: "RO" },
  { guid: "165", name: "SV" },
  { guid: "166", name: "WS" },
  { guid: "167", name: "SM" },
  { guid: "168", name: "ST" },
  { guid: "169", name: "SA" },
  { guid: "170", name: "SZ" },
  { guid: "171", name: "SJ" },
  { guid: "172", name: "SH" },
  { guid: "173", name: "KP" },
  { guid: "174", name: "MP" },
  { guid: "175", name: "SC" },
  { guid: "176", name: "VC" },
  { guid: "177", name: "PM" },
  { guid: "178", name: "SN" },
  { guid: "179", name: "KN" },
  { guid: "180", name: "LC" },
  { guid: "181", name: "SG" },
  { guid: "182", name: "SY" },
  { guid: "183", name: "SK" },
  { guid: "184", name: "SI" },
  { guid: "185", name: "US" },
  { guid: "186", name: "SB" },
  { guid: "187", name: "SO" },
  { guid: "188", name: "SD" },
  { guid: "189", name: "SR" },
  { guid: "190", name: "SL" },
  { guid: "191", name: "TJ" },
  { guid: "192", name: "TH" },
  { guid: "193", name: "TW" },
  { guid: "194", name: "TZ" },
  { guid: "195", name: "TC" },
  { guid: "196", name: "TG" },
  { guid: "197", name: "TK" },
  { guid: "198", name: "TO" },
  { guid: "199", name: "TT" },
  { guid: "200", name: "TV" },
  { guid: "201", name: "TN" },
  { guid: "202", name: "TM" },
  { guid: "203", name: "TR" },
  { guid: "204", name: "UG" },
  { guid: "205", name: "UZ" },
  { guid: "206", name: "UA" },
  { guid: "207", name: "WF" },
  { guid: "208", name: "UY" },
  { guid: "209", name: "FO" },
  { guid: "210", name: "FJ" },
  { guid: "211", name: "PH" },
  { guid: "212", name: "FI" },
  { guid: "213", name: "FK" },
  { guid: "214", name: "FR" },
  { guid: "215", name: "GF" },
  { guid: "216", name: "PF" },
  { guid: "217", name: "HM" },
  { guid: "218", name: "HR" },
  { guid: "219", name: "CF" },
  { guid: "220", name: "TD" },
  { guid: "221", name: "CZ" },
  { guid: "222", name: "CL" },
  { guid: "223", name: "CH" },
  { guid: "224", name: "SE" },
  { guid: "225", name: "LK" },
  { guid: "226", name: "EC" },
  { guid: "227", name: "GQ" },
  { guid: "228", name: "ER" },
  { guid: "229", name: "EE" },
  { guid: "230", name: "ET" },
  { guid: "231", name: "YU" },
  { guid: "232", name: "ZA" },
  { guid: "233", name: "GS" },
  { guid: "234", name: "KR" },
  { guid: "235", name: "JM" },
  { guid: "236", name: "JP" },
  { guid: "237", name: "TF" },
  { guid: "238", name: "IO" },
  { guid: "239", name: "UM" },
  { guid: "240", name: "BQ" },
];
