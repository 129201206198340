
import * as types from "../constants/actionTypes";
import {
  getTerminals
} from "../services/paymentBackendAPI/management/terminals";

export const getTerminalsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TERMINALS
    });
    try {
      //  args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getTerminals(args);
      dispatch({
        type: types.GET_TERMINALS_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TERMINALS_FAILED
      });
    }
  };
};