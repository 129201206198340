import { logIn } from "actions/auth";
import background from "assets/img/background_5.png";
import logo from "assets/img/logo.png";
import Loading from "components/UI/Spinner";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
      otp: "",
      selectedType: "login-password",
      step: 0,
      showPassword: false,
      showOtp: false,
      authFail: false,
      error: "",
      note: "",
      auth: false,
      loading: false,
      logoutError: localStorage.getItem("error") ? localStorage.getItem("error") : "",
    };

    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleShowOtp = () => {
    this.setState({
      showOtp: !this.state.showOtp,
    });
  };

  handleLoginChange = (event) => {
    this.setState({
      login: event.target.value,
      authFail: false,
      note: "",
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
      authFail: false,
      note: "",
    });
  };

  handleOtpChange = (event) => {
    this.setState({
      otp: event.target.value,
      authFail: false,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
      note: "",
      error: "",
      logoutError: ""
    });
    localStorage.setItem("error", "");
    let request = {
      username: this.state.login,
      type: this.state.selectedType,
    };
    if (this.state.selectedType.includes("password"))
      request.password = this.state.password;
    if (this.state.step === 1) request.otp = this.state.otp;

    await this.props.logIn(request);

    if (this.props.response && this.props.response.first_time_login) {
      this.setState({ auth: true });
      window.location.replace(
        process.env.PUBLIC_URL + "#/first_time_login" || "#/first_time_login"
      );
    } else if (
      this.props.response &&
      this.props.response.credentials_expire_after &&
      this.props.response.credentials_expired === false
    ) {
      this.setState({ auth: true });
      window.location.replace(
        process.env.PUBLIC_URL + "#/expires_password" || "#/expires_password"
      );
    } else if (this.props.response && this.props.response.credentials_expired) {
      this.setState({ auth: true });
      window.location.replace(
        process.env.PUBLIC_URL + "#/expired_password" || "#/expired_password"
      );
    } else if (
      this.props.response &&
      this.props.response.accessToken &&
      this.props.response.refreshToken
    ) {
      this.setState({ auth: true });
      window.location.replace(process.env.PUBLIC_URL || "/");
    } else if (
      this.props.response &&
      this.props.response.status.includes("Success")
    ) {
      this.setState({
        step: 1,
        loading: false,
        note: this.props.response.status,
        error: "",
      });
    } else {
      let state = {
        authFail: true,
        error: JSON.stringify(this.props.error),
        loading: false,
        otp: "",
        note: "",
      };
      if (
        [ "Code is expired", "Requests limit exceeded" ].includes(
          this.props.error
        )
      )
        state = {
          ...state,
          step: 0,
        };
      this.setState({
        ...state,
      });
    }
  };

  validateForm() {
    if (this.state.step === 0)
      return this.state.login.length > 0 && this.state.password.length > 0;
    else
      return (
        this.state.login.length > 0 &&
        this.state.password.length > 0 &&
        this.state.otp.length > 0
      );
  }

  handleButtonRender() {
    return this.state.loading ? (
      <button className="button-submit loading" disabled>
        <Loading />
      </button>
    ) : (
      <button
        className="button-submit"
        onClick={this.handleSubmit}
        disabled={!this.validateForm()}
      >
        LOGIN
      </button>
    );
  }

  render() {
    return (
      <div className="login-page">
        {this.state.step === 0 && (
          <div className="login-container">
            <div className="" style={{ backgroundImage: `url(${background})` }}>
              <img
                className="logo"
                src={logo}
                alt="logo"
                style={{ margin: "30px 0 0 30px" }}
              />
            </div>
            <div className="form">
              <form autoComplete="off">
                <p className="header-login">Login</p>
                <>
                  <div className="input-container">
                    <i className="icon-user icon" />
                    <input
                      type="text"
                      placeholder="Enter your email"
                      onChange={this.handleLoginChange}
                      name="login"
                      value={this.state.login}
                    />
                  </div>
                  <div className="input-container">
                    <i className={"icon-lock icon"} />
                    <input
                      type={this.state.showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      onChange={this.handlePasswordChange}
                      name="password"
                      value={this.state.password}
                      autoComplete="on"
                    />
                    <span
                      className={
                        this.state.showPassword
                          ? "icon-eye-stroke login-page-eye"
                          : "icon-eye login-page-eye"
                      }
                      onClick={this.handleShowPassword}
                    />
                  </div>
                </>
                <div>
                  <Link className="link" to={"/forgot"} tabIndex="4">
                    <span>Password Recovery</span>
                  </Link>
                </div>
                {this.handleButtonRender()}
                {this.state.authFail && (
                  <p className="access-denied">{this.state.error}</p>
                )}
                <p className="access-denied">{this.state.logoutError}</p>
              </form>
            </div>
          </div>
        )
        }

        {
          this.state.step === 1 && (
            <div className="login-card">
              <p className="header-login">Login</p>
              <p className="otp-status">{this.state.note}</p>
              <div className="form-container">
                <div className="form">
                  <div className="input-container">
                    <i className="icon-lock icon" />
                    <input
                      type={this.state.showOtp ? "text" : "password"}
                      placeholder="Verification code"
                      onChange={this.handleOtpChange}
                      value={this.state.otp}
                      name="otp"
                    />
                    <span
                      className={
                        this.state.showOtp
                          ? "icon-eye-stroke login-page-eye"
                          : "icon-eye login-page-eye"
                      }
                      onClick={this.handleShowOtp}
                    />
                  </div>
                  {this.handleButtonRender()}
                  {this.state.authFail && (
                    <p className="access-denied">{this.state.error}</p>
                  )}
                </div>
              </div>
            </div>
          )
        }
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.auth.response,
    error: state.auth.error,
    privileges: state.roles.rolePrivileges,
  };
};

export default connect(mapStateToProps, {
  logIn,
})(LoginPage);

LoginPage.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  logIn: PropTypes.func,
  response: PropTypes.string,
};
