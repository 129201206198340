import React from "react";
import { Table } from "react-bootstrap";

export const Params = ({ rows, data }) => {
  let green = { color: "#5BB795" };
  let red = { color: "#EC5E6F" };
  let azure = { background: "#f7ffff" };
  console.log(rows);
  console.log(data);
  return (
    <div>
      <Table responsive className="detailInfo">
        <thead>
          <tr>
            <th></th>
            <th style={red}>Old:</th>
            <th style={green}>New:</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr style={data[row.path].changed ? azure : {}} key={index}>
              {console.log(data[row.path]?.new)}
              <th>{row.label}:</th>
              <td style={data[row.path].changed ? red : {}}>
                {data[row.path]?.old ?? ""}
              </td>
              <td style={data[row.path].changed ? green : {}}>
                {data[row.path]?.new ?? ""}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
