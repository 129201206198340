import { backendAudits } from "../backendPlatform";

export const getLoginChangeHistory = (guid, page, items) => {
  return backendAudits.get("/login", { params: { guid, items, page } });
};

export const getMerchantChangeHistory = (guid, page, items) => {
  return backendAudits.get("/merchant", { params: { guid, items, page } });
};

export const getGroupChangeHistory = (guid, page, items) => {
  return backendAudits.get("/group", { params: { guid, items, page } });
};

export const getPartnerChangeHistory = (guid, page, items) => {
  return backendAudits.get("/partner", { params: { guid, items, page } });
};

export const getTerminalChangeHistory = (guid, page, items) => {
  return backendAudits.get("/terminals", { params: { guid, items, page } });
};

export const getShopChangeHistory = (guid, page, items) => {
  return backendAudits.get("/shops", { params: { guid, items, page } });
};

export const getGatewayChangeHistory = (guid, page, items) => {
  return backendAudits.get("/gateways", { params: { guid, items, page } });
};

export const getCurrencyChangeHistory = (guid, page, items) => {
  return backendAudits.get("/currencies", { params: { guid, items, page } });
};

//
// export const getTransactionChangeHistory = (guid, tr_page,  tr_items, trd_page, trd_items,  tro_items, tro_page ) => {
//   return backendAudits.get("/transactions", { params: { guid, tr_page,  tr_items, trd_page, trd_items,  tro_items, tro_page  } });
// };

export const getAuditTransactionProcessingChangeHistory = (
  guid,
  page,
  items
) => {
  return backendAudits.get("/transaction_processings", {
    params: { guid, items, page },
  });
};

export const getAuditTransactionDataChangeHistory = (guid, page, items) => {
  return backendAudits.get("/transaction_data", {
    params: { guid, items, page },
  });
};

export const getAuditTransactionOverviewsChangeHistory = (
  guid,
  page,
  items
) => {
  return backendAudits.get("/transaction_overviews", {
    params: { guid, items, page },
  });
};

export const getAuditServiceChangeHistory = (name, page, items) => {
  return backendAudits.get("/service_settings", {
    params: { name, items, page },
  });
};
