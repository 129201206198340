import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const MultiSelect = (props) => {
  const customStyles = {
    control: (base) => ({
      ...base,

      "min-height": "39px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    multiValue: (base) => ({
      ...base,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  };

  const convertOptions = (options) => {
    return options.map((item) => {
      item.label = item.name;
      item.value = item.guid;
      return item;
    });
  };

  convertOptions(props.options);

  if (props.defaultValue) {
    return (
      <div>
        <Select
          isMulti={props.multi}
          components={props.components}
          options={props.options}
          className={props.name}
          classNamePrefix="select"
          defaultValue={props.options.filter(
            (option) => option.label === props.defaultValue
          )}
          onChange={props.onSelect}
          styles={customStyles}
          placeholder={props.placeholder || "Select..."}
          hideSelectedOptions={props.hideSelectedOptions}
          closeMenuOnSelect={props.closeMenuOnSelect}
          {...props}
        />
      </div>
    );
  }

  if (props.value || props.value == null) {
    return (
      <div>
        <Select
          isMulti={props.multi}
          components={props.components}
          options={props.options}
          className={props.name}
          classNamePrefix="select"
          value={props.value}
          onChange={props.onSelect}
          styles={customStyles}
          placeholder={props.placeholder || "Select..."}
          hideSelectedOptions={props.hideSelectedOptions}
          closeMenuOnSelect={props.closeMenuOnSelect}
          {...props}
        />
      </div>
    );
  } else
    return (
      <div>
        <Select
          isMulti={props.multi}
          selectOption={props.options[0]}
          components={props.components}
          options={props.options}
          className={props.name}
          classNamePrefix="select"
          onChange={props.onSelect}
          styles={customStyles}
          isSearchable={props.isSearchable}
          placeholder={props.placeholder || "Select..."}
          hideSelectedOptions={props.hideSelectedOptions}
          closeMenuOnSelect={props.closeMenuOnSelect}
          {...props}
        />
      </div>
    );
};

export default MultiSelect;

MultiSelect.propTypes = {
  multi: PropTypes.bool,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.array,
};
