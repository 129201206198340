import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import MultiSelect from "components/UI/MultiSelect";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import { getAllRoles } from "../../actions/roles";
import { addAdminRoleAction } from "../../actions/admins";
import Alert from "../UI/Alert";

export class RowAddRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: "",
      needReason: false,
      roleGuid: "",
      reason: "",
    };
    this.deleteExistingRoles = this.deleteExistingRoles.bind(this);
  }

  handleRoleSelect = (option) => {
    this.setState({
      selectedRole: option,
      roleGuid: option.guid,
      needReason: true,
    });
  };

  componentDidMount = async () => {
    await this.props.getAllRoles({ type: this.props.type });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    this.setState({ reason: input.value });
  };

  handleSubmit = async () => {
    if (
      this.state.roleGuid === "" ||
      (this.state.reason === "" && this.state.needReason)
    )
      swal({
        title: "Please, enter information in every required field",
        icon: "warning",
      });
    else {
      try {
        const data = {
          guid: this.state.roleGuid,
          reason: this.state.reason,
        };
        await this.props.addAdminRoleAction(this.props.guid, data);
        swal({
          title: "Role is added",
          icon: "success",
          button: false,
          timer: 2000,
        });

        await this.setState({
          selectedRole: "",
          roleGuid: "",
          reason: "",
          needReason: false,
        });
      } catch (error) {
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  deleteExistingRoles = () => {
    let adminRoles = this.props.adminRoles ? this.props.adminRoles : [];
    let roles = this.props.roles;
    if (adminRoles.length !== 0) {
      for (let i = 0, len = adminRoles.length; i < len; i++) {
        roles = roles.filter((role) => role.guid !== adminRoles[i].guid);
      }
    }
    return roles;
  };

  render() {
    let roles = this.deleteExistingRoles();
    return (
      <div style={{ textAlign: "center" }}>
        <div>
          <label>Role</label>
          <MultiSelect
            options={roles ? roles : []}
            multi={false}
            onSelect={this.handleRoleSelect}
            defaultValue=""
          />
          {this.state.needReason && (
            <div>
              <label>Reason</label>
              <Form.Group>
                <Form.Control
                  placeholder="Enter reason"
                  name="reason"
                  value={this.state.reason}
                  onChange={this.handleChangeReason}
                />
              </Form.Group>
            </div>
          )}
          <Button
            className={
              this.state.roleGuid === "" ||
                (this.state.reason === "" && this.state.needReason)
                ? "btn btn-fill"
                : "btn btn-fill btn-success"
            }
            onClick={this.handleSubmit}
            style={{ margin: "15px" }}
          >
            Add role
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.roles.rolesList,
    adminRoles: state.admins.adminRoles,
  };
};

export default connect(mapStateToProps, {
  addAdminRoleAction,
  getAllRoles,
})(RowAddRole);

RowAddRole.propTypes = {
  addAdminRoleAction: PropTypes.func,
  adminRoles: PropTypes.array,
  getAllRoles: PropTypes.func,
  guid: PropTypes.string,
  roles: PropTypes.array,
  type: PropTypes.array,
};
