import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Tabs, Card } from "react-bootstrap";
import Content from "../Content";
import { getAmountGroupsForPartner } from "../../actions/groups";
import { getAmountMerchantsForPartner } from "../../actions/merchants";
import { getAmountShopsForPartner } from "../../actions/shops";
import { getTransactionHistoryAction } from "../../actions/transactions";
import LineChartTransactionsAmount from "components/LineChart/TransactionsAmount";
import LineChartTransactionsCurrencies from "components/LineChart/TransactionsCurrencies";
import HorizontalBarChartTopMerchants from "components/HorizontalBarChart/TopMerchants";
import HorizontalBarChartTopGroups from "components/HorizontalBarChart/TopGroups";
import HorizontalBarChartTopShops from "components/HorizontalBarChart/TopShops";
import TotalProcessed from "components/TotalProcessed";
import PropTypes from "prop-types";

class PartnerDashboard extends Component {
  componentDidMount = () => {
    this.props.getAmountGroupsForPartner();
    this.props.getAmountMerchantsForPartner();
    this.props.getAmountShopsForPartner();
    this.props.getTransactionHistoryAction({ days: 7 });
  };

  render() {
    return (
      <Content>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <Card>
              <Card.Header as="h5">Amount of groups</Card.Header>
              <Card.Body>
                <p
                // className="card-small-content"
                >
                  {this.props.groupsAmount}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Card>
              <Card.Header as="h5">Amount of merchants</Card.Header>
              <Card.Body>
                <p>{this.props.merchantsAmount}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Card>
              <Card.Header as="h5">Amount of shops</Card.Header>
              <Card.Body>
                <p>{this.props.shopsAmount}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Card>
              <Card.Header as="h5">Total</Card.Header>
              <Card.Body>
                <TotalProcessed className={"card-small-content"} />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Card>
              <Card.Header as="h5">Total transactions</Card.Header>
              <Card.Body>
                <p>{this.props.transactionsAmount}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Card>
              <Card.Header as="h5">Transaction amount</Card.Header>
              <Card.Body>
                <LineChartTransactionsAmount />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Card>
              <Card.Header as="h5">Transaction currency</Card.Header>
              <Card.Body>
                <LineChartTransactionsCurrencies />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Tabs defaultActiveKey="topMerchants" style={{ marginLeft: "9px" }}>
          <Tab
            eventKey="topMerchants"
            title="Top merchants"
            style={{ marginLeft: "-9px" }}
          >
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top merchants</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopMerchants />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="topGroups"
            title="Top groups"
            id="top-for-partner-dashboard"
            style={{ marginLeft: "-9px" }}
          >
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top groups</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopGroups />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="topShops"
            title="Top shops"
            style={{ marginLeft: "-9px" }}
          >
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top shops</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopShops />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchantsAmount: state.merchants.count,
    shopsAmount: state.shops.count,
    groupsAmount: state.groups.count,
    transactionsAmount: state.transactions.transactionHistoryCount,
  };
};

export default connect(mapStateToProps, {
  getAmountGroupsForPartner,
  getAmountMerchantsForPartner,
  getAmountShopsForPartner,
  getTransactionHistoryAction,
})(PartnerDashboard);

PartnerDashboard.propTypes = {
  getAmountGroupsForPartner: PropTypes.func,
  getAmountMerchantsForPartner: PropTypes.func,
  getAmountShopsForPartner: PropTypes.func,
  getTransactionHistoryAction: PropTypes.func,
  groupsAmount: PropTypes.number,
  merchantsAmount: PropTypes.number,
  shopsAmount: PropTypes.number,
  transactionsAmount: PropTypes.number,
};
