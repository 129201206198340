import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

class BoolSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      value: "",
    };
  }

  componentDidMount() {
    let nameId = this.props.value + "Id";
    this.setState({
      [nameId]: "",
    });
  }

  parseValue = (key) => {
    if (key === "true") {
      return true;
    } else {
      return false;
    }
  };

  handleClick = async (e) => {
    if (
      this.props.searchData[this.props.value] === this.parseValue(e.target.id)
    ) {
      await this.props.search({
        [this.props.value]: undefined,
      });
      await this.props.get({
        page: 1,
        items: 100,
      });
      this.props.updateCurrentPage();
      return 0;
    }
    let nameId = this.props.value + "Id";
    if (this.state[nameId] !== e.target.id) {
      this.setState({
        [nameId]: e.target.id,
      });
      await this.props.search({
        [this.props.value]: this.parseValue(e.target.id),
      });
      await this.props.get({
        page: 1,
        items: 100,
        ...this.props.searchData,
      });
      this.props.updateCurrentPage();
    } else {
      this.setState({
        [nameId]: "",
      });
      this.props.search({
        [this.props.value]: "",
      });
    }
  };

  render() {
    return (
      <div
        className={
          this.props.isSearch
            ? "searchOpen search align-center"
            : "searchClosed search align-center"
        }
      >
        <i
          id="true"
          className="icon-success status-icon-success"
          style={
            this.props.searchData[this.props.value] === 1 ||
            this.props.searchData[this.props.value] === true
              ? {
                  color: "#5BB795",
                  WebkitTransform: "scale(1.5)",
                  msTransform: "scale(1.5)",
                  transform: "scale(1.5)",
                }
              : null
          }
          onClick={this.handleClick}
        />
        <i
          id="false"
          className="icon-failed status-icon-failed"
          style={
            this.props.searchData[this.props.value] === 0 ||
            this.props.searchData[this.props.value] === false
              ? {
                  color: "#EC5E6F",
                  WebkitTransform: "scale(1.5)",
                  msTransform: "scale(1.5)",
                  transform: "scale(1.5)",
                }
              : null
          }
          onClick={this.handleClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.search.reset,
    isSearch: state.search.isSearch,
  };
};

export default connect(mapStateToProps, {})(BoolSelector);

BoolSelector.propTypes = {
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  search: PropTypes.func,
  searchData: PropTypes.object,
  updateCurrentPage: PropTypes.func,
  value: PropTypes.string,
};
