import React, { Component } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect/index";
import { connect } from "react-redux";
import { addRoleAction, addRolePrivilegeAction } from "actions/roles";
import { getPrivilegesWithType } from "../../actions/privileges";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import Alert from "../UI/Alert";

class RoleCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      selectedPrivileges: [],
      selectedType: "",
      isLoading: false,
      errors: {},

      nameValidation: false,
      typeValidation: false,
      descriptionValidation: false,
      privilegesValidation: false,
    };
  }

  schema = {
    name: Joi.string().required().label("Name"),
    type: Joi.object().min(1).required().label("Type"),
    description: Joi.string().required().label("Description"),
    privileges: Joi.array().min(1).required().label("Privileges"),
  };

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        name: this.state.name,
        type: this.state.selectedType,
        description: this.state.description,
        privileges: this.state.selectedPrivileges,
      },
      this.schema,
      options
    );

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      [input.name]: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  onSelectPrivilege = (option) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: "privileges",
      value: option,
    });
    if (errorMessage) errors.privileges = errorMessage;
    else delete errors.privileges;

    this.setState({
      selectedPrivileges: option,
      errors,
      privilegesValidation: option.length ? true : false,
    });
  };

  onSelectType = async (option) => {
    const errors = { ...this.state.errors };
    delete errors.type;

    this.setState({
      selectedType: option,
      errors,
      typeValidation: true,
    });
    await this.props.getPrivilegesWithType({ type: option.name });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });

      const { name, description, selectedPrivileges, selectedType } =
        this.state;
      if (selectedPrivileges.length > 0) {
        try {
          await this.props.addRoleAction(
            {
              name,
              type: selectedType.name,
              description,
            },
            this.props.currentPage,
            this.props.pageSize,
            this.props.rolesSearch
          );
          const roleGuid = this.props.role_guid;
          let data = [];
          for (let i = 0; i < selectedPrivileges.length; i += 1) {
            const privilege = {
              guid: selectedPrivileges[i].guid,
            };
            data = [ ...data, privilege ];
          }
          await this.props.addRolePrivilegeAction(roleGuid, data);
          swal({
            title: "Role is created",
            icon: "success",
            button: false,
            timer: 2000,
          });
          await this.props.handleClose();
        } catch (error) {
          this.setState({ isLoading: false });
          const parsedError = parseResponse(error);
          Alert({ type: "error", message: parsedError.message });
        }
      } else {
        this.setState({ isLoading: false });
        swal({
          title: "Please, enter information in every required field",
          icon: "warning",
        });
      }
    }
  };

  render() {
    const { privileges } = this.props;

    const types = [
      {
        guid: "0",
        name: "merchant",
      },
      {
        guid: "1",
        name: "group",
      },
      {
        guid: "2",
        name: "partner",
      },
      {
        guid: "3",
        name: "admin",
      },
    ];
    const { errors } = this.state;
    return (
      <Form>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Name*</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation(this.state.name)}>
              <Form.Control
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
              />
              {errors.name && (
                <span className="validate-error">{errors.name}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Type*</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group>
              <MultiSelect
                name="type"
                options={types}
                multi={false}
                onSelect={this.onSelectType}
              />
              {errors.type && (
                <span className="validate-error">{errors.type}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Description*</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group
              validationState={this.formValidation(this.state.description)}
            >
              <Form.Control
                name="description"
                type="text"
                value={this.state.description}
                onChange={this.handleChange}
              />
              {errors.description && (
                <span className="validate-error">{errors.description}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        {this.state.selectedType && (
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Privileges*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group>
                <MultiSelect
                  name="Roles"
                  options={privileges ? privileges : []}
                  multi={true}
                  onSelect={this.onSelectPrivilege}
                />
                {errors.privileges && (
                  <span className="validate-error">{errors.privileges}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className={
                this.state.nameValidation &&
                this.state.typeValidation &&
                this.state.descriptionValidation &&
                this.state.privilegesValidation
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
              onClick={this.handleSubmit}
              style={{ marginTop: "10px" }}
            >
              Add
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role_guid: state.roles.role_guid,
    privileges: state.privileges.privilegesList,
    currentPage: state.roles.currentPage,
    pageSize: state.roles.pageSize,

    rolesSearch: state.search.rolesSearch,
  };
};

export default connect(mapStateToProps, {
  showModal,
  addRoleAction,
  addRolePrivilegeAction,
  getPrivilegesWithType,
})(RoleCreator);

RoleCreator.propTypes = {
  addRoleAction: PropTypes.func,
  addRolePrivilegeAction: PropTypes.func,
  currentPage: PropTypes.number,
  getPrivilegesWithType: PropTypes.func,
  pageSize: PropTypes.number,
  privileges: PropTypes.array,
  role_guid: PropTypes.string,
  rolesSearch: PropTypes.object,
  showModal: PropTypes.func,
};
