import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import { editMerchantAction, getMerchantAction } from "../../actions/merchants";
import { getGroupAction, getAllGroups } from "actions/groups";
import { showModal } from "../../actions/modal";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse.js";
import Spinner from "components/UI/Spinner/index";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import MultiSelect from "components/UI/MultiSelect";
import Joi from "joi-browser";
import moment from "moment";
import Alert from "../UI/Alert";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getAllCurrencies } from "actions/currencies";
import { numberFormatter } from "../../helpers/numberFormatter";

class MerchantEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      data: {
        merchant_name: "",
        merchant_type: "",
        monthly_amount_limit: "",
        monthly_fee_date: "",
        monthly_fee: "",
        monthly_fee_currency: "",
        group_guid: undefined,
        custom_amount_limit: "0",
        custom_days_limit: 0,
      },
      oldData: {
        merchant_name: "",
        merchant_type: "",
        monthly_amount_limit: "",
        monthly_fee_date: "",
        monthly_fee: "",
        monthly_fee_currency: "",
        group_guid: undefined,
        custom_amount_limit: "0",
        custom_days_limit: 0,
      },
      select: [],
      selectedValue: [],
      token: {},
      isAdmin: "",
      isLoading: false,
      errors: {},
      merchantLoading: false,
      needReason: false,
      reason: "",

      merchant_nameValidation: false,
      merchant_typeValidation: false,
      monthly_feeValidation: false,
      monthly_amount_limitValidation: false,
      monthly_fee_dateValidation: false,
      custom_amount_limitValidation: false,
      custom_days_limitValidation: false,
      reasonValidation: false,
    };
  }

  schema = {
    merchant_name: Joi.string().required().label("Merchant name"),
    merchant_type: Joi.string().required().label("Merchant type"),
    group_guid: Joi.label("Group"),
    group_name: Joi.label("Group"),
    monthly_amount_limit: Joi.number().required().label("Monthly amount limit"),
    monthly_fee: Joi.number().required().label("Monthly fee"),
    monthly_fee_currency: Joi.string().required().label("Monthly fee currency"),
    monthly_fee_date: Joi.string().required().label("Monthly fee date"),
    custom_amount_limit: Joi.number().required().label("Merchant amount limit"),
    custom_days_limit: Joi.number()
      .required()
      .max(1000)
      .label("Merchant period limit (days)"),
  };

  async componentDidMount() {
    this.setState({ merchantLoading: true });
    const token = getAuthData().userPayload;
    this.setState({ token });
    const isAdmin = localStorage.getItem("isAdmin");
    this.setState({ isAdmin });

    await this.props.getMerchantAction(this.props.guid);
    if (token.partner || isAdmin) {
      await this.props.getAllGroups();
      this.props.groups.map((item) => {
        item.name = item.group_name;
        item.guid = item.group_guid;
        return item;
      });
      this.props.groups.unshift({ name: "Without group", guid: "1" });
      this.setState({ groups: this.props.groups });
    }
    const { merchant } = this.props;
    let groupOption =
      merchant.group_guid && this.state.groups
        ? this.state.groups.filter(
          (option) => option.guid === merchant.group_guid
        )[0]
        : "";

    await this.props.getAllCurrencies();
    let currencies = this.props.currencies;
    currencies.forEach((currency) => {
      currency.name = currency.code;
    });
    const data = {
      merchant_name: merchant.merchant_name,
      merchant_type: merchant.merchant_type,
      monthly_amount_limit: merchant.monthly_amount_limit,
      monthly_fee_date: merchant.monthly_fee_date,
      monthly_fee: merchant.monthly_fee / 100,
      monthly_fee_currency: currencies.filter(
        (cur) => cur.code === merchant.monthly_fee_currency
      )[0],
      group_guid: merchant.group_guid,
      group_name: groupOption.name,
      custom_amount_limit: merchant.custom_amount_limit,
      custom_days_limit: merchant.custom_days_limit,
    };
    this.setState({
      data,
      oldData: {
        merchant_name: merchant.merchant_name,
        merchant_type: merchant.merchant_type,
        monthly_amount_limit: merchant.monthly_amount_limit,
        monthly_fee_date: merchant.monthly_fee_date,
        monthly_fee: merchant.monthly_fee / 100,
        monthly_fee_currency: currencies.filter((cur) => cur.code === "EUR")[0],
        group_guid: merchant.group_guid,
        custom_amount_limit: merchant.custom_amount_limit,
        custom_days_limit: merchant.custom_days_limit,
      },
      groupOption,
      currencies,
      merchant_nameValidation: merchant.merchant_name ? true : false,
      merchant_typeValidation: merchant.merchant_type ? true : false,
      monthly_feeValidation: merchant.monthly_fee.toString() ? true : false,
      monthly_amount_limitValidation: merchant.monthly_amount_limit.toString()
        ? true
        : false,
      monthly_fee_dateValidation: merchant.monthly_fee_date.toString()
        ? true
        : false,
      custom_amount_limitValidation: merchant.custom_amount_limit.toString()
        ? true
        : false,
      custom_days_limitValidation: merchant.custom_days_limit.toString()
        ? true
        : false,
    });
    this.setState({ merchantLoading: false });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = {
        ...this.state.data,
        monthly_amount_limit: this.state.data.monthly_amount_limit,
        monthly_fee_date: this.state.data.monthly_fee_date,
        monthly_fee: this.state.data.monthly_fee,
        monthly_fee_currency: this.state.data.monthly_fee_currency
          ? this.state.data.monthly_fee_currency.code
          : undefined,
        custom_amount_limit: this.state.data.custom_amount_limit,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        ...this.state.data,
        monthly_amount_limit: this.state.data.monthly_amount_limit,
        monthly_fee_date: this.state.data.monthly_fee_date,
        monthly_fee: this.state.data.monthly_fee,
        monthly_fee_currency: this.state.data.monthly_fee_currency
          ? this.state.data.monthly_fee_currency.code
          : undefined,
        custom_amount_limit: this.state.data.custom_amount_limit,
      };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state.data };
    let changedData = false;
    data.custom_days_limit = parseInt(this.state.data.custom_days_limit, 10);

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    let { data } = this.state;
    data[input.name] = input.value;
    this.setState(
      {
        data,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleChangeNumber = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = numberFormatter(input.value);
    this.setState({ data, errors }, () => this.showReason());
  };

  onSelectPartnerGroup = (option) => {
    const errors = { ...this.state.errors };
    delete errors.group;

    let { data } = this.state;
    data.group_guid = option.guid;
    data.group_name = option.name;
    this.setState({ data, errors }, () => this.showReason());
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleTimeChange = (event) => {
    let value = event.target.value;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: event.target.name,
      value,
    });
    if (errorMessage) errors[event.target.name] = errorMessage;
    else delete errors[event.target.name];

    value = moment(value).format("YYYY-MM-DDTHH:mm:ss");
    let data = this.state.data;
    data[event.target.name] = value;
    this.setState(
      {
        data,
        errors,
        [event.target.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  onSelectCurrency = (option) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          monthly_fee_currency: option,
        },
      },
      () => this.showReason()
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({
            isLoading: true,
          });
          let monthly_fee = +this.state.data.monthly_fee * 100;
          let monthly_amount_limit =
            +this.state.data.monthly_amount_limit * 100;
          let custom_amount_limit = +this.state.data.custom_amount_limit * 100;
          let data = {
            merchant_guid: this.props.guid,
            merchant_name: this.state.data.merchant_name,
            merchant_type: this.state.data.merchant_type,
            monthly_amount_limit: this.state.isAdmin
              ? monthly_amount_limit.toString()
              : undefined,
            monthly_fee: this.state.isAdmin ? monthly_fee : undefined,
            monthly_fee_currency: this.state.isAdmin
              ? this.state.data.monthly_fee_currency.name
              : undefined,
            monthly_fee_date: this.state.isAdmin
              ? this.state.data.monthly_fee_date
              : undefined,
            custom_amount_limit: this.state.isAdmin
              ? custom_amount_limit.toString()
              : undefined,
            custom_days_limit: this.state.isAdmin
              ? this.state.data.custom_days_limit
              : undefined,
            reason: this.state.needReason ? this.state.reason : undefined,
          };

          if (this.state.data.group_guid && this.state.data.group_guid !== "1")
            data.group_guid = this.state.data.group_guid;
          else if (this.state.data.group_guid === "1") data.group_guid = "";
          else data.group_guid = undefined;
          await this.props.editMerchantAction(data);
          swal({
            title: "Merchant is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          this.setState({ isLoading: false });
          // this.state.token.group && await this.props.getGroupAction(this.state.token.group.group_guid);
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        const parsedError = parseResponse(error);
        this.setState({ isLoading: false });
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { data } = this.state;
    const { errors, currencies } = this.state;

    if (this.state.merchantLoading || this.props.merchantLoading)
      return <Spinner />;
    else
      return (
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Row>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Row>
                <Col md={3} className="form-label">
                  <Form.Label>Merchant name</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group
                    validationState={this.formValidation("merchant_name")}
                  >
                    <Form.Control
                      name="merchant_name"
                      type="text"
                      value={data.merchant_name}
                      onChange={(e) => this.handleChange(e)}
                    />
                    {errors.merchant_name && (
                      <span className="validate-error">
                        {errors.merchant_name}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} className="form-label">
                  <Form.Label>Merchant type</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group
                    validationState={this.formValidation("merchant_type")}
                  >
                    <Form.Control
                      name="merchant_type"
                      type="text"
                      value={this.state.data.merchant_type}
                      onChange={(e) => this.handleChange(e)}
                    />
                    {errors.merchant_type && (
                      <span className="validate-error">
                        {errors.merchant_type}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {(this.state.token.partner || this.state.isAdmin) && (
                <Row>
                  <Col md={3} className="form-label">
                    <Form.Label>Group</Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Group>
                      <MultiSelect
                        name="Groups"
                        options={this.state.groups ? this.state.groups : []}
                        multi={false}
                        onSelect={this.onSelectPartnerGroup}
                        defaultValue={this.state.groupOption}
                        // styles={{
                        //   menu: (styles) => ({ ...styles, zIndex: 999 }),
                        // }}
                      />
                      {errors.group_guid && (
                        <span className="validate-error">
                          {errors.group_guid}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              {this.state.isAdmin === "true" && (
                <>
                  <Row>
                    <Col md={3} className="form-label">
                      <Form.Label>Monthly fee date </Form.Label>
                    </Col>
                    <Col md={8}>
                      <Form.Group
                        validationState={this.formValidation(
                          "monthly_fee_date"
                        )}
                      >
                        <DateRangePicker
                          onCallback={this.handleTimeChange}
                          initialSettings={{
                            singleDatePicker: true,
                            locale: {
                              format: "DD.MM.YYYY",
                            },
                            startDate: this.state.monthly_fee_date
                              ? moment(this.state.monthly_fee_date).format(
                                "DD.MM.YYYY"
                              )
                              : undefined,
                          }}
                        >
                          <input
                            type="text"
                            className="text-input form-control"
                          />
                        </DateRangePicker>
                        {errors.monthly_fee_date && (
                          <span className="validate-error">
                            {errors.monthly_fee_date}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} className="form-label">
                      <Form.Label>Monthly fee</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        validationState={this.formValidation("monthly_fee")}
                      >
                        <Form.Control
                          name="monthly_fee"
                          type="number"
                          step="0.01"
                          value={this.state.data.monthly_fee}
                          onChange={(e) => this.handleChangeNumber(e)}
                        />

                        {errors.monthly_fee && (
                          <span className="validate-error">
                            {errors.monthly_fee}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        validationState={this.formValidation(
                          "monthly_fee_currency"
                        )}
                        style={{ paddingLeft: "25px" }}
                      >
                        <MultiSelect
                          name="monthly_fee_currency"
                          options={currencies}
                          multi={false}
                          onSelect={this.onSelectCurrency}
                          placeholder="Select currency"
                          value={this.state.data.monthly_fee_currency}
                        />

                        {errors.monthly_fee_currency && (
                          <span className="validate-error">
                            {errors.monthly_fee_currency}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} className="form-label">
                      <Form.Label>Merchant period limit (days)</Form.Label>
                    </Col>
                    <Col md={8}>
                      <Form.Group
                        validationState={this.formValidation(
                          "custom_days_limit"
                        )}
                      >
                        <Form.Control
                          name="custom_days_limit"
                          type="number"
                          placeholder="Enter сustom days limit"
                          value={this.state.data.custom_days_limit}
                          onChange={this.handleChange}
                        />

                        {errors.custom_days_limit && (
                          <span className="validate-error">
                            {errors.custom_days_limit}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3} className="form-label">
                      <Form.Label>Merchant amount limit*</Form.Label>
                    </Col>
                    <Col md={8}>
                      <Form.Group
                        validationState={this.formValidation(
                          "custom_amount_limit"
                        )}
                      >
                        <InputGroup>
                          <Form.Control
                            name="custom_amount_limit"
                            type="number"
                            placeholder="Enter custom amount limit"
                            value={this.state.data.custom_amount_limit}
                            onChange={this.handleChangeNumber}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="custom_amount_limit">
                              €
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        {errors.custom_amount_limit && (
                          <span className="validate-error">
                            {errors.custom_amount_limit}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} className="form-label">
                      <Form.Label>Monthly amount limit</Form.Label>
                    </Col>
                    <Col md={8}>
                      <Form.Group
                        validationState={this.formValidation(
                          "monthly_amount_limit"
                        )}
                      >
                        <InputGroup>
                          <Form.Control
                            name="monthly_amount_limit"
                            type="number"
                            step="0.01"
                            value={this.state.data.monthly_amount_limit}
                            onChange={(e) => this.handleChangeNumber(e)}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="custom_amount_limit">
                              €
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        {errors.monthly_amount_limit && (
                          <span className="validate-error">
                            {errors.monthly_amount_limit}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              {this.state.needReason && (
                <Row>
                  <Col md={3} className="form-label">
                    <Form.Label>Reason</Form.Label>
                  </Col>
                  <Col md={8}>
                    <Form.Group>
                      <Form.Control
                        placeholder="Enter reason"
                        name="reason"
                        value={this.state.reason}
                        onChange={this.handleChangeReason}
                      />
                      {errors.reason && (
                        <span className="validate-error">{errors.reason}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <div className="modal-buttons">
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.merchant_nameValidation &&
                  this.state.merchant_typeValidation &&
                  this.state.connection_feeValidation &&
                  this.state.monthly_amount_limitValidation &&
                  this.state.monthly_feeValidation &&
                  this.state.custom_amount_limitValidation &&
                  this.state.custom_days_limitValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchants.merchant,
    merchantLoading: state.merchants.merchantLoading,
    groups: state.groups.groupsList,
    currencies: state.currencies.currenciesList,
  };
};

export default connect(mapStateToProps, {
  getMerchantAction,
  editMerchantAction,
  showModal,
  getGroupAction,
  getAllGroups,
  getAllCurrencies,
})(MerchantEditor);

MerchantEditor.propTypes = {
  editMerchantAction: PropTypes.func,
  getAllGroups: PropTypes.func,
  getGroupAction: PropTypes.func,
  getMerchantAction: PropTypes.func,
  groups: PropTypes.array,

  guid: PropTypes.string,
  merchant: PropTypes.object,
  merchantLoading: PropTypes.bool,
  showModal: PropTypes.func,
};
