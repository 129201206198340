import { backendAudits } from "../backendPlatform";

export const getAuditMerchantTables = (args) => {
  return backendAudits.get("/merchant", { params: { ...args } });
};

export const getAuditGroupTables = (args) => {
  return backendAudits.get("/group", { params: { ...args } });
};

export const getAuditPartnerTables = (args) => {
  return backendAudits.get("/partner", { params: { ...args } });
};

export const getAuditAdminTables = (args) => {
  return backendAudits.get("/login", {
    params: { ...args },
  });
};

export const getAuditLoginTables = (args) => {
  return backendAudits.get("/logins", { params: { ...args } });
};

export const getAuditTerminalTables = (args) => {
  return backendAudits.get("/terminals", { params: { ...args } });
};

export const getAuditTransactionTables = (args) => {
  return backendAudits.get("/transactions", { params: { ...args } });
};

export const getAuditShopTables = (args) => {
  return backendAudits.get("/shops", { params: { ...args } });
};

export const getAuditAccountTables = (args) => {
  return backendAudits.get("/accounts", { params: { ...args } });
};

export const getAuditGatewayTables = (args) => {
  return backendAudits.get("/gateways", { params: { ...args } });
};

export const getAuditCurrencyTables = (args) => {
  return backendAudits.get("/currencies", { params: { ...args } });
};

export const getAuditTransactionProcessingTables = (args) => {
  return backendAudits.get("/transaction_processings", { params: { ...args } });
};

export const getAuditTransactionDataTables = (args) => {
  return backendAudits.get("/transaction_data", { params: { ...args } });
};

export const getAuditTransactionOverviewsTables = (args) => {
  return backendAudits.get("/transaction_overviews", { params: { ...args } });
};

export const getAuditServiceTables = (args) => {
  return backendAudits.get("/service_settings", { params: { ...args } });
};

export const getAuditMerchants = (args) => {
  return backendAudits.get("/merchant_list", { params: { ...args } });
};

export const getAuditGroups = (args) => {
  return backendAudits.get("/group_list", { params: { ...args } });
};

export const getAuditPartners = (args) => {
  return backendAudits.get("/partner_list", { params: { ...args } });
};

export const getAuditLogins = (args) => {
  return backendAudits.get("/login_list", { params: { ...args } });
};
