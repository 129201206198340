import ability from "config/ability";

const checkRoute = (route) => {
  if (!route.alias) {
    return route;
  }
  if (typeof route.alias === "object") {
    if (route.report) {
      route.alias.some((priv) => {
        if (!ability.can("REPORT", priv.toUpperCase())) return undefined;
        return priv;
      });
    } else {
      route.alias.some((priv) => {
        if (!ability.can("READ", priv.toUpperCase())) return undefined;
        return priv;
      });
    }
    return route;
  }
  if (route.report)
    if (ability.can("REPORT", route.alias.toUpperCase())) return route;
    else return undefined;
  if (route.audit)
    if (ability.can("AUDIT", route.alias.toUpperCase())) return route;
    else return undefined;
  if (route.log)
    if (ability.can("LOG", route.alias.toUpperCase())) return route;
    else return undefined;
  if (route.execute)
    if (ability.can("EXECUTE", route.alias.toUpperCase())) return route;
    else return undefined;
  if (route.form)
    if (ability.can("FORM", route.alias.toUpperCase())) return route;
    else return undefined;

  if (route.redirect || ability.can("READ", route.alias.toUpperCase()))
    return route;
  if (ability.can("READ", route.alias.toUpperCase())) return route;
  else return undefined;
};

export const isRoute = (route) => {
  if (route.views) {
    const newViews = route.views.filter((childRoute) => checkRoute(childRoute));
    if (newViews.length)
      return {
        ...route,
        views: newViews,
      };
  } else return checkRoute(route);
};
