import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import moment from "moment";
import MerchantSelect from "./FormComponents/MerchantSelect";
import {
  getAllMerchantsStatements,
  getStatementCurrencyAction,
} from "../../../actions/statements";
import NameInput from "./FormComponents/NameInput";
import { getAllMerchants } from "actions/merchants";

import { getAllCurrencies, getCurrencyRatesAction } from "actions/currencies";
import StatementCurrencySelect from "./FormComponents/StatementCurrencySelect";
import CurrencyRatesSelector from "./CurrencyRatesSelector";
import Input from "./FormComponents/Input";
import { schemaMerge } from "./schema";
import Joi from "joi-browser";
import ReactLoading from "react-loading";
import StatementSelect from "./FormComponents/StatementSelect";
import { numberFormatter } from "../../../helpers/numberFormatter";

class MergeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantsLoading: false,
      merchants: [],
      selectedMerchant: {},

      statementsLoading: false,
      selectedTerminals: [],
      selectedCurrencies: [],
      name: "",
      statementCurrency: {},
      currencyLoading: false,
      additional_fees: [],
      bank_wire_fee: 0,
      reason: "",
      from_date: moment()
        .format("YYYY-MM-DDTHH:mm:ss")
        .replace(/\D\d{2}\w/, "-01T"),
      to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      errors: {},
    };
  }

  schema = this.props.guid
    ? {
        ...schemaMerge,
        reason: Joi.string().required().not().empty().label("Reason"),
      }
    : schemaMerge;

  validate = () => {
    const options = { abortEarly: false };
    let data = {
      selectedStatements: this.state.selectedStatements,
      merchant_guid: this.state.selectedMerchant.guid,
      // statementCurrency: this.state.statementCurrency.name,
      bank_wire_fee: this.state.bank_wire_fee,
    };
    if (this.props.guid) data.reason = this.state.reason;
    else delete data.reason;
    let { error } = Joi.validate(data, this.schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  async componentDidMount() {
    this.setState({ merchantsLoading: true });
    await this.props.getAllMerchants();
    let merchants = this.props.merchants.map((item) => {
      item.name = item.merchant_name;
      item.guid = item.merchant_guid;
      return item;
    });
    merchants = merchants.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.guid === item.guid)
    );

    await this.props.getAllCurrencies();
    let currencies = this.props.currencies;
    currencies.forEach((currency) => {
      currency.name = currency.code;
    });
    let currenciesRates = currencies.map((currency) => {
      if ("EUR" !== currency.code) {
        return {
          guid: currency.guid,
          bank_exchange_rate: currency.rate_to_eur,
          processor_exchange_rate: currency.isFlat
            ? numberFormatter(
              +currency.rate_to_eur + +currency.exchange_markup_value,
              4
            )
            : numberFormatter(
              currency.rate_to_eur *
                  (currency.exchange_markup_value / 100 + 1),
              4
            ),
          isFlat: currency.isFlat,
          exchange_markup_value: currency.exchange_markup_value,
          code: currency.code,
        };
      } else {
        return {
          guid: currency.guid,
          bank_exchange_rate: numberFormatter(currency.rate_to_eur, 4),
          processor_exchange_rate: numberFormatter(currency.rate_to_eur, 4),
          isFlat: currency.isFlat,
          exchange_markup_value: currency.exchange_markup_value,
          code: currency.code,
        };
      }
    });
    this.setState({
      merchants,
      currencies,
      currenciesRates,
      selectedCurrenciesRates: currenciesRates,
      statementCurrency: currencies.filter((cur) => cur.code === "EUR")[0],
      merchantsLoading: false,
    });

    if (this.props.statement) {
      this.setState({
        name: this.props.statement.name,
        selectedMerchant: {
          label: this.props.statement.merchant_name,
          name: this.props.statement.merchant_name,
          value: this.props.statement.merchant_guid,
          guid: this.props.statement.merchant_guid,
        },
      });

      currenciesRates = currencies.map((currency) => {
        return {
          guid: currency.guid,
          bank_exchange_rate: currency.rate_to_eur,
          processor_exchange_rate: currency.isFlat
            ? numberFormatter(
              +currency.rate_to_eur + +currency.exchange_markup_value,
              4
            )
            : numberFormatter(
              currency.rate_to_eur *
                  (currency.exchange_markup_value / 100 + 1),
              4
            ),
          isFlat: currency.isFlat,
          exchange_markup_value: currency.exchange_markup_value,
          code: currency.code,
        };
      });

      await this.props.getAllMerchantsStatements({
        merchant_guid: this.props.statement.merchant_guid,
        status: "Payment Due",
        merge_statement_flag: false,
      });
      let statements = this.props.statements.map((statement) => {
        return {
          name: statement.name,
          guid: statement.guid,
          label: statement.name,
          value: statement.guid,
        };
      });
      let selectedStatements = this.props.statement.statementData;
      selectedStatements = selectedStatements.map((statement) => {
        return {
          name: statement.name,
          guid: statement.guid,
          label: statement.name,
          value: statement.guid,
        };
      });

      selectedStatements.splice(
        selectedStatements.indexOf(
          selectedStatements.filter((statement) => statement.guid === undefined)
        ),
        1
      );

      this.setState({
        selectedCurrenciesRates: currenciesRates,
        selectedStatements,
        statements,
        bank_wire_fee: this.props.statement.bank_wire_fee,
      });
    }
  }

  onSelectMerchant = async (option) => {
    this.setState({
      selectedMerchant: option,
      statementsLoading: true,
    });
    const errors = { ...this.state.errors };
    delete errors.merchant_guid;
    await this.props.getAllMerchantsStatements({
      merchant_guid: option.guid,
      status: "Payment Due",
      merge_statement_flag: false,
    });

    this.setState({
      selectedStatements: [],
      statements: this.props.statements,
      statementsLoading: false,
      errors,
    });
  };

  onSelectStatements = async (options) => {
    await this.setState({
      selectedStatements: options,
    });
    // await this.loadStatementCurrencies();
  };

  // loadStatementCurrencies = async () => {
  //   this.setState({
  //     currencyLoading: true,
  //   });
  //   let statements = this.state.selectedStatements;
  //   statements = statements.map((statement) => statement.guid);
  //   await this.props.getStatementCurrencyAction({ statements });

  //   let currencies = this.props.statementCurrencies.currencies;
  //   let statements_currency = currencies.filter(
  //     (currency) =>
  //       currency.bank_exchange_rate === 1 &&
  //       currency.processor_exchange_rate === 1
  //   )[0];
  //   this.setState({
  //     selectedCurrenciesRates: statements_currency
  //       ? statements_currency.code ===
  //         this.state.statementCurrency.code
  //         ? this.props.statementCurrencies.currencies
  //         : this.state.selectedCurrenciesRates
  //       : this.state.currenciesRates,
  //     currencyLoading: false,
  //   });
  // };

  handleChange = (input) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({
      [input.name]: input.value,
      errors,
    });
  };

  onSelectStatementCurrency = async (option) => {
    this.setState({
      statementCurrency: option,
      currencyLoading: true,
    });

    let currenciesRates = this.state.currenciesRates;
    let rates = [];
    await Promise.all(
      currenciesRates.map(async (currency) => {
        let a = await this.loadCurrencyRates(
          currency.code,
          option.name,
          currenciesRates
        );
        rates = rates.concat([ a ]);
      })
    );

    currenciesRates = rates.length ? rates : currenciesRates;
    let selectedCurrenciesRates = this.state.selectedCurrenciesRates;
    selectedCurrenciesRates = selectedCurrenciesRates.map((currency) => {
      return currenciesRates.filter((curr) => curr.guid === currency.guid)[0];
    });
    if (
      !selectedCurrenciesRates.filter(
        (currency) => currency.code === option.code
      ).length
    ) {
      selectedCurrenciesRates = [
        ...selectedCurrenciesRates,
        ...currenciesRates.filter((curr) => curr.code === option.code),
      ];
    }
    this.setState({
      currencyLoading: false,
      currenciesRates,
      selectedCurrenciesRates,
    });
  };

  handleProcessorExchangeChangeselectedCurrenciesRates = ({
    currentTarget: input,
  }) => {
    let rates = this.state.selectedCurrenciesRates;
    let changedRateIndex = rates.indexOf(
      rates.find((currency) => currency.guid === input.name)
    );
    rates[changedRateIndex] = {
      ...rates[changedRateIndex],
      processor_exchange_rate: numberFormatter(input.value, 4),
    };

    this.setState({ selectedCurrenciesRates: rates });
  };

  handleChangeBankExchangeselectedCurrenciesRates = ({
    currentTarget: input,
  }) => {
    let rates = this.state.selectedCurrenciesRates;
    let changedRateIndex = rates.indexOf(
      rates.find((currency) => currency.guid === input.name)
    );
    rates[changedRateIndex] = {
      ...rates[changedRateIndex],
      bank_exchange_rate: numberFormatter(input.value, 4),
    };
    if (this.state.statementCurrency.name !== rates[changedRateIndex].code) {
      rates[changedRateIndex].processor_exchange_rate = rates[changedRateIndex]
        .isFlat
        ? numberFormatter(
          +input.value + +rates[changedRateIndex].exchange_markup_value,
          4
        )
        : numberFormatter(
          input.value *
              (rates[changedRateIndex].exchange_markup_value / 100 + 1),
          4
        );
    }

    this.setState({ selectedCurrenciesRates: rates });
  };

  loadCurrencyRates = async (
    from,
    to = this.state.statementCurrency.name,
    currencyList = this.state.currenciesRates
  ) => {
    await this.props.getCurrencyRatesAction(from, to);
    let selectedCurrenciesRates = currencyList.filter(
      (currency) => currency.code === from
    )[0];
    selectedCurrenciesRates = {
      ...selectedCurrenciesRates,
      bank_exchange_rate: this.props.currencyRates.exchange_rate,
      processor_exchange_rate: this.props.currencyRates.exchange_rate,
    };

    if (this.state.statementCurrency.name !== selectedCurrenciesRates.code) {
      selectedCurrenciesRates.processor_exchange_rate =
        selectedCurrenciesRates.isFlat
          ? numberFormatter(
            +this.props.currencyRates.exchange_rate +
                +selectedCurrenciesRates.exchange_markup_value,
            4
          )
          : numberFormatter(
            this.props.currencyRates.exchange_rate *
                (selectedCurrenciesRates.exchange_markup_value / 100 + 1),
            4
          );
    }
    return selectedCurrenciesRates;
  };

  // addAdditionalFee = () => {
  //   this.setState({
  //     additional_fees: [
  //       ...this.state.additional_fees,
  //       { name: "", value: "", currency: "" },
  //     ],
  //   });
  // };

  // deleteAdditionalFees = (index) => {
  //   let additional_fees = this.state.additional_fees;
  //   additional_fees.splice(index, 1);
  //   this.setState({
  //     additional_fees: additional_fees,
  //   });
  // };

  // handleChangeAdditionalFee = (index, name, e) => {
  //   let value = e.currentTarget ? e.currentTarget.value : e;
  //   let additional_fees = this.state.additional_fees;
  //   additional_fees[index] = { ...additional_fees[index], [name]: value };
  //   this.setState({ additional_fees });
  // };

  handleSubmitCalculate = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      let statements = this.state.selectedStatements;
      statements = statements.map((statement) => statement.guid);
      let bank_wire_fee =
        this.state.bank_wire_fee.toString().replace(/^0+/, "") + "00";
      if (bank_wire_fee.includes(".")) {
        let index = bank_wire_fee.indexOf(".");
        bank_wire_fee =
          bank_wire_fee.slice(0, index) + bank_wire_fee.slice(index, index + 3);
        bank_wire_fee = bank_wire_fee.replace(".", "");
      }
      const data = {
        merchant_guid: this.state.selectedMerchant.guid,
        statements: statements,
        statement_currency: this.state.statementCurrency.name,
        currency_rates: this.state.selectedCurrenciesRates,
        bank_wire_fee: bank_wire_fee === "00" ? "0" : bank_wire_fee,
        save: 0,
        is_payable_statement: 1,
      };
      await this.props.calculate(data);
    }
  };

  handleSubmitSave = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      let statements = this.state.selectedStatements;
      statements = statements.map((statement) => statement.guid);
      let bank_wire_fee =
        this.state.bank_wire_fee.toString().replace(/^0+/, "") + "00";
      if (bank_wire_fee.includes(".")) {
        let index = bank_wire_fee.indexOf(".");
        bank_wire_fee =
          bank_wire_fee.slice(0, index) + bank_wire_fee.slice(index, index + 3);
        bank_wire_fee = bank_wire_fee.replace(".", "");
      }

      const data = {
        guid: this.props.guid ? this.props.guid : undefined,
        merchant_guid: this.state.selectedMerchant.guid,
        statements,
        name: this.state.name ? this.state.name : undefined,
        statement_currency: this.state.statementCurrency.name,
        currency_rates: this.state.selectedCurrenciesRates,
        bank_wire_fee: bank_wire_fee === "00" ? "0" : bank_wire_fee,
        save: 1,
        is_payable_statement: 1,
        reason: this.props.guid ? this.state.reason : undefined,
      };
      await this.props.save(data);
    }
  };

  render() {
    let errors = { ...this.state.errors };
    return (
      <Form>
        <Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <MerchantSelect
              merchantsLoading={this.state.merchantsLoading}
              merchants={this.state.merchants}
              value={this.state.selectedMerchant}
              callback={this.onSelectMerchant}
              errors={errors}
            />

            <StatementSelect
              statements={this.state.statements}
              statementsLoading={this.state.statementsLoading}
              value={this.state.selectedStatements}
              callback={this.onSelectStatements}
            />
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <NameInput value={this.state.name} callback={this.handleChange} />

            <StatementCurrencySelect
              value={this.state.statementCurrency}
              currencies={this.state.currencies}
              callback={this.onSelectStatementCurrency}
            />

            <CurrencyRatesSelector
              currencyLoading={this.state.currencyLoading}
              selectedCurrenciesRates={this.state.selectedCurrenciesRates}
              handleProcessorExchangeChangeselectedCurrenciesRates={
                this.handleProcessorExchangeChangeselectedCurrenciesRates
              }
              handleChangeBankExchangeselectedCurrenciesRates={
                this.handleChangeBankExchangeselectedCurrenciesRates
              }
              errors={errors}
              selectedCurrencyName={this.state.statementCurrency.name}
            />

            <Input
              label="Bank wire fee*"
              name="bank_wire_fee"
              type="number"
              value={this.state.bank_wire_fee}
              callback={this.handleChange}
              errors={errors}
            />

            {this.props.guid && (
              <Input
                label="Reason*"
                name="reason"
                value={this.state.reason}
                callback={this.handleChange}
                errors={errors}
              />
            )}

            {/* <AdditionalFees
              addAdditionalFee={this.addAdditionalFee}
              deleteAdditionalFees={this.deleteAdditionalFees}
              handleChangeAdditionalFee={this.handleChangeAdditionalFee}
              additional_fees={this.state.additional_fees}
              currencies={this.state.currencies}
            /> */}
          </Col>
        </Row>
        <div>
          {this.props.isLoading ||
          this.state.merchantsLoading ||
          this.state.statementsLoading ||
          this.state.currencyLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <>
                  {!this.props.guid && (
                    <Button
                      className={
                        this.state.terminalsValidation
                          ? "btn btn-fill btn-success"
                          : "btn btn-fill"
                      }
                      type="submit"
                      onClick={this.handleSubmitCalculate}
                    >
                  Calculate
                    </Button>
                  )}
                  <Button
                    className={
                      this.state.terminalsValidation
                        ? "btn btn-fill btn-success"
                        : "btn btn-fill"
                    }
                    style={{ marginLeft: "15px" }}
                    type="submit"
                    onClick={this.handleSubmitSave}
                  >
                Save
                  </Button>
                </>
              )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    currencies: state.currencies.currenciesList,
    currencyRates: state.currencies.currencyRates,
    statements: state.statements.merchantStatements,
    statementCurrencies: state.statements.statementCurrencies,
  };
};

export default connect(mapStateToProps, {
  getAllMerchants,
  getAllCurrencies,
  getCurrencyRatesAction,
  getStatementCurrencyAction,
  getAllMerchantsStatements,
})(MergeForm);
