import React from "react";
import { connect } from "react-redux";
import { LineChart } from "components/UI/LineChart";
import { getTransactionTypesAction } from "actions/transactions";
import { lastNDays, daysSinceTo } from "services/dateTime/dateTime";
import { borderColors, backgroundColors } from "constants/charts/colors";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import moment from "moment";
import MultiSelect from "components/UI/MultiSelect";

class LineChartTransactionsHistory extends React.Component {
  state = {
    loading: true,
    labels: lastNDays(7),
    type: { value: "Count", label: "Count" },
  };

  componentDidMount = () => {
    let labels;
    labels = daysSinceTo(
      new Date(moment(this.props.data.from_date).format("YYYY-MM-DDTHH:mm:ss")),
      new Date(moment(this.props.data.to_date).format("YYYY-MM-DDTHH:mm:ss"))
    );
    this.setState({ labels });
    this.props.getTransactionTypesAction(this.props.data);
  };

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.data !== this.props.data) {
      let labels;
      if (nextProps.data.from_date && nextProps.data.to_date) {
        labels = daysSinceTo(
          new Date(
            moment(nextProps.data.from_date).format("YYYY-MM-DDTHH:mm:ss")
          ),
          new Date(moment(nextProps.data.to_date).format("YYYY-MM-DDTHH:mm:ss"))
        );
        // labels.shift();
        // labels.push(moment(nextProps.data.to_date).format("DD.MM"));
      } else {
        labels = lastNDays(7);
      }
      this.setState({ labels });
      this.props.getTransactionTypesAction(nextProps.data);
    }
    return true;
  };

  onSelectType = (option) => {
    this.setState({ type: option });
  };

  render() {
    const labels = this.state.labels;
    let datasetsPie = [];
    if (this.props.loading) return <Spinner />;
    else {
      let transactionTypes = Object.keys(this.props.reportTransactionType);
      let datasets = [];
      transactionTypes.forEach((transactionType, index) => {
        datasets[index] = [];
        if (this.state.type.label === "Count") {
          this.props.reportTransactionType[transactionType].forEach((item) => {
            const findIndex = this.state.labels.findIndex(
              (itemD) => itemD === moment(item.date).format("DD.MM")
            );
            if (findIndex !== -1) datasets[index][findIndex] = item.success;
          });
        } else {
          this.props.reportTransactionType[transactionType].forEach((item) => {
            const findIndex = this.state.labels.findIndex(
              (itemD) => itemD === moment(item.date).format("DD.MM")
            );
            if (findIndex !== -1) datasets[index][findIndex] = item.amount;
          });
        }

        for (let i = 0; i < this.state.labels.length; i++) {
          if (!datasets[index][i]) datasets[index][i] = 0;
        }
      });
      datasetsPie = datasets.map((dataset, index) => {
        return {
          label: transactionTypes[index],
          fill: false,
          lineTension: 0.1,
          backgroundColor: backgroundColors[index],
          borderColor: borderColors[index],
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: borderColors[index],
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: borderColors[index],
          pointHoverBorderColor: "black",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataset,
        };
      });
    }

    return (
      <div style={{ padding: 30 }}>
        <div className="header">Transactions</div>
        {this.props.loading ? (
          <Spinner />
        ) : (
          <>
            <MultiSelect
              name="type"
              options={[
                { guid: "Count", name: "Count" },
                { guid: "Amount", name: "Amount" },
              ]}
              multi={false}
              onSelect={this.onSelectType}
              placeholder="Select type"
              value={this.state.type}
            />
            <LineChart
              labels={labels}
              datasets={datasetsPie}
              name="Transactions"
              options={{
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reportTransactionType: state.transactions.types,
    loading: state.transactions.transactionTypesLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionTypesAction,
})(LineChartTransactionsHistory);

LineChartTransactionsHistory.propTypes = {
  data: PropTypes.object,
  getTransactionTypesAction: PropTypes.func,
  loading: PropTypes.bool,
  reportTransactionType: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};
