import React from "react";
import Content from "../Content";
import AdminDashboardComponent from "../../components/LineChart/AdminDashboardComponent";
import MerchantTotalBar from "../../components/HorizontalBarChart/MerchantTotalBar";
import MerchantLimitBar from "../../components/HorizontalBarChart/MerchantLimitBar";

export default class AdminDashboard extends React.Component {
  render() {
    return (
      <Content>
        <MerchantTotalBar />
        <MerchantLimitBar />
        <AdminDashboardComponent />
      </Content>
    );
  }
}
