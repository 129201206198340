import React from "react";
import PieChart from "components/UI/PieChart";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { backgroundColors } from "constants/charts/colors";
import Spinner from "components/UI/Spinner";

class PieChartTransactionTypes extends React.Component {
  state = {
    loading: true,
  };

  render() {
    let datasetsPie = [];
    let labelsPie = [];
    let data = [];
    let colors = [];
    let object = this.props.data;
    Object.keys(object).forEach(function (key, index) {
      let count = 0;
      object[key].forEach((item) => {
        count += parseInt(item.success, 10);
      });
      data.push(count);
      colors.push(backgroundColors[index]);
      datasetsPie = [ { data: data, backgroundColor: colors } ];
      labelsPie = [ ...labelsPie, key ];
    });
    if (this.props.loading) return <Spinner />;
    else
      return (
        <PieChart
          labels={
            labelsPie.length !== 0
              ? labelsPie
              : [ "There are no transactions type yet" ]
          }
          datasets={
            datasetsPie.length !== 0
              ? datasetsPie
              : [
                  {
                    data: [ 1 ],
                    backgroundColor: "#CCC",
                  },
                ]
          }
        />
      );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.transactions.types,
    loading: state.transactions.transactionTypesLoading,
  };
};

export default connect(mapStateToProps, {})(PieChartTransactionTypes);

PieChartTransactionTypes.propTypes = {
  data: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
  loading: PropTypes.bool,
};
