import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAuditLoginsAction } from "../../../../actions/audit";
import { reset, searchInLoginsAuditTable } from "../../../../actions/search";
import { resetPage, setNewTable } from "../../../../actions/store";
import AbstractComponent from "../../../../factories/Main";
import { cutGuid } from "../../../../helpers/cutGuid";
import { getAuditLogins } from "../../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../../UI/CopyToClipboard";

const columns = [
  {
    path: "login_guid",
    label: "Guid",
    key: "guid_audit",
    content: (el) => (
      <>
        <Copy text={el.login_guid} />
        <Link className="link" to={`/about/audit/login/${el.login_guid}`}>
          {cutGuid(el.login_guid)}
        </Link>
      </>
    ),
  },
  {
    path: "username",
    label: "Username",
    key: "username",
  },
  { path: "change_count", label: "count", key: "count" },
  { path: "login_status", label: "status", key: "statusaudit" },
  {
    path: "update_date",
    label: "last update date",
    key: "last update date",
    content: (login) =>
      moment(login.last_change).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.logins,
    count: state.audit.loginsCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.loginAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_login",
    keyPath: "guid",
    columns,
    exportFunction: getAuditLogins,
  };
};

export default connect(mapStateToProps, {
  get: getAuditLoginsAction,
  search: searchInLoginsAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
