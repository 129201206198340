/* eslint-disable no-unused-vars */
import { AbilityBuilder } from "casl";
import { getPermissions } from "../services/paymentBackendAPI/backendPlatform";

const getComponentsNames = () => {
  const permissions = getPermissions();
  if(permissions) {
    let set = new Set();
    permissions.forEach(permission => {
      let name = permission.split("_")[1].toUpperCase();
      set.add(name);
    });
    let arr = [];
    set.forEach(item => arr = [ ...arr, item ]);
    return arr;
  }
  else {
    return null;
  }

};

// this function is used in getFuncString below
const getComponentPermissions = componentName => {
  const permissions = getPermissions();
  if(permissions) {
    let arr = [];
    permissions.forEach(permission => {
      if(permission.split("_")[1].toUpperCase() === componentName.toUpperCase())
        arr = [ ...arr, permission.split("_")[0] ];
    });
    return arr;
  }
  else return [ "null" ];
};

const getFuncsString = () => {
  let str = "";
  const names = getComponentsNames();
  if(names) {
    names.forEach(name => {
      str += `can(getComponentPermissions('${name}'), '${name}'); `;
    });
    return str;
  }
  else
    return null;
};

export default AbilityBuilder.define(async(can) => {
  await eval(getFuncsString());
});