import React, { Component } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Can from "../../Can";
import TransactionDataTable from "./TransactionData/Main";
import TransactionOverviewsTable from "./TransactionOverviews/Main";
import TransactionProcessingTable from "./TransactionProcessing/Main";

class TransactionAuditMain extends Component {
  constructor() {
    super();

    this.state = {
      type: "Transaction processing",
      isUpdate: false,
      labelButton: "Transaction processing",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  async handleSelect(e) {
    await this.setState({
      type: e,
      labelButton: e
    });
  }

  render() {
    return (
      <div>
        <DropdownButton
          as="s"
          key={this.state.labelButton}
          id={`dropdown-variants-${this.state.labelButton}`}
          title={this.state.labelButton}
        >
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Transaction processing">Transaction processing</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Transaction data">Transaction data</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Transaction overviews">Transaction overviews</Dropdown.Item>
        </DropdownButton>
        {
          {
            "Transaction processing": <Can do="AUDIT" on="MERCHANTS">
              <TransactionProcessingTable />
            </Can>,
            "Transaction data": <Can do="AUDIT" on="GROUPS">
              <TransactionDataTable />
            </Can>,
            "Transaction overviews": <Can do="AUDIT" on="PARTNERS">
              <TransactionOverviewsTable />
            </Can>,
          }[this.state.type]
        }
      </div>
    );
  }
}

export default TransactionAuditMain;
