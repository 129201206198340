import { hideSidebar, showSidebar } from "actions/sidebar";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { adminDashboardRoutes } from "routes/dashboard.jsx";
import SidebarHeader from "./Header";
import SidebarWrapper from "./Wrapper";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    if (window.innerWidth <= 991) {
      this.props.hideSidebar();
    } else {
      this.props.showSidebar();
    }
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <>
        <div
          id="sidebar"
          className={this.props.isHide ? "slideOut" : "slideIn"}
        >
          <SidebarHeader />
          <SidebarWrapper routes={adminDashboardRoutes} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isHide: state.sidebar.isHide,
  };
};

export default connect(mapStateToProps, {
  hideSidebar,
  showSidebar,
})(Sidebar);

Sidebar.propTypes = {
  hideSidebar: PropTypes.func,
  isHide: PropTypes.bool,
  showSidebar: PropTypes.func,
};
