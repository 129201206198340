import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { adminRoutes } from "./routes/index.jsx";
import { getPermissions } from "./services/paymentBackendAPI/backendPlatform";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/icon.css";
import LoginPage from "components/UI/LoginPage";
import Logout from "components/UI/Logout";
import ConfirmEmail from "components/UI/ForgotPassword";
import RecoveryPassword from "components/UI/RecoveryPassword";
import ExpiredPassword from "components/UI/ExpiredPassword";
import ExpiresPassword from "components/UI/ExpiresPassword";
import FirstTimeLogin from "components/UI/FirstTimeLogin";
import { Redirect } from "react-router-dom";
import "assets/css/content.css";
import Loading from "components/UI/Spinner";
import auth from "./services/paymentBackendAPI/auth/auth";
import { parseResponse } from "helpers/parseResponse";
import Alert from "components/UI/Alert";
import "./assets/css/gotham.css";
import config from "./config";
import "antd/dist/antd.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      user: "",
      isTokenPresented: localStorage.getItem("isLoggedIn"),
      isTokenChecked: false,
    };
  }

  componentDidMount = async () => {
    document.title = `${config.name} gateway - ${window.location.hostname}`;
    if (this.state.isTokenPresented) {
      try {
        await auth.validate();
      } catch (error) {
        const parsedError = parseResponse(error);
        // swal({
        //   title: parsedError.error,
        //   text: parsedError.message,
        //   icon: "error",
        // });
        Alert({ type: "error", message: parsedError.message });
      }
      this.setState({
        role: window.sessionStorage.getItem("Role"),
        permissions: getPermissions(),
        isTokenChecked: true,
      });
    }
  };

  render() {
    if (this.state.redirectErrorUrl)
      return (
        <HashRouter>
          <Redirect to="/error" />
        </HashRouter>
      );

    if (this.state.isTokenPresented && !this.state.isTokenChecked)
      return <Loading />;

    const LoginRoute = ({ ...rest }) => (
      <Route
        {...rest}
        render={() =>
          localStorage.getItem("isLoggedIn") === "false" ||
          !localStorage.getItem("isLoggedIn") ? (
                <Route path="/login" component={LoginPage} />
              ) : (
                <Redirect to="/" />
              )
        }
      />
    );

    const ConfirmEmailRoute = ({ ...rest }) => (
      <Route
        {...rest}
        render={() => <Route path="/forgot" component={ConfirmEmail} />}
      />
    );

    const RecoveryPasswordRoute = ({ ...rest }) => (
      <Route
        {...rest}
        render={() => (
          <Route
            path="/recovery_password/:token"
            component={RecoveryPassword}
          />
        )}
      />
    );

    const ExpiredPasswordRoute = ({ ...rest }) => (
      <Route
        {...rest}
        render={() =>
          localStorage.getItem("isLoggedIn") === "true" &&
          localStorage.getItem("firstTimeLogin") !== "true" ? (
                <Route path="/expired_password" component={ExpiredPassword} />
              ) : (
                <Redirect to="/login" />
              )
        }
      />
    );

    const ExpiresPasswordRoute = ({ ...rest }) => (
      <Route
        {...rest}
        render={() =>
          localStorage.getItem("isLoggedIn") === "true" &&
          localStorage.getItem("firstTimeLogin") !== "true" ? (
                <Route path="/expires_password" component={ExpiresPassword} />
              ) : (
                <Redirect to="/login" />
              )
        }
      />
    );

    const FirstTimeLoginRoute = ({ ...rest }) => (
      <Route
        {...rest}
        render={() =>
          localStorage.getItem("isLoggedIn") === "true" &&
          localStorage.getItem("firstTimeLogin") === "true" ? (
                <Route path="/first_time_login" component={FirstTimeLogin} />
              ) : (
                <Redirect to="/login" />
              )
        }
      />
    );

    const PrivateRoute = ({ ...rest }) => {
      if (localStorage.getItem("isLoggedIn") !== "true") {
        return <Redirect to="/login" />
      }
      if (localStorage.getItem("expiredPassword") === "true") {
        return <Redirect to="/expired_password" />
      }
      return <Route
        {...rest}
        render={(props) =>
          <Component {...props} />
        }
      />
      };

    let route;
    route = adminRoutes.map((prop, key) => {
      return (
        <PrivateRoute
          to={`/admin/${prop.path}`}
          component={prop.component}
          key={key}
        />
      );
    });

    return (
      <HashRouter>
        <Switch>
          {<LoginRoute path="/login" component={LoginPage} />}
          {<ConfirmEmailRoute path="/forgot" component={ConfirmEmail} />}
          {
            <RecoveryPasswordRoute
              path="/recovery_password/:token"
              component={RecoveryPassword}
            />
          }
          {
            <ExpiredPasswordRoute
              path="/expired_password"
              component={ExpiredPassword}
            />
          }
          {
            <ExpiresPasswordRoute
              path="/expires_password"
              component={ExpiresPassword}
            />
          }
          {
            <FirstTimeLoginRoute
              path="/first_time_login"
              component={FirstTimeLogin}
            />
          }
          {<PrivateRoute path="/logout" component={Logout} />}
          {route}
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
