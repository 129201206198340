import moment from "moment";
import { connect } from "react-redux";
import { getAuditGroupAction } from "../../../../actions/audit";
import { reset, searchInGroupsAuditTable } from "../../../../actions/search";
import { resetPage, setNewTable } from "../../../../actions/store";
import AbstractComponent from "../../../../factories/Main";
import { cutGuid } from "../../../../helpers/cutGuid";
import { getAuditGroups } from "../../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../../UI/CopyToClipboard";
import React from "react";
import { Link } from "react-router-dom";

const columns = [
  {
    path: "group_guid",
    label: "Guid",
    key: "guid_audit",
    content: (el) => (
      <>
        <Copy text={el.guid} />
        <Link className="link" to={`/about/audit/group/${el.group_guid}`}>
          {cutGuid(el.group_guid)}
        </Link>
      </>
    ),
  },
  {
    path: "group_name",
    label: "Name",
    key: "name",
  },
  { path: "change_count", label: "count", key: "count" },
  { path: "group_status", label: "status", key: "statusaudit" },
  {
    path: "update_date",
    label: "last update date",
    key: "last update date",
    content: (group) =>
      moment(group.last_change).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.groups,
    count: state.audit.groupsCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.groupAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_group",
    keyPath: "guid",
    columns,
    exportFunction: getAuditGroups,
  };
};

export default connect(mapStateToProps, {
  get: getAuditGroupAction,
  search: searchInGroupsAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
