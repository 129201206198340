import React, { Component } from "react";
import { connect } from "react-redux";
import { getTotalProcessedAction } from "../../actions/transactions";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Can from "components/Can";
import { Badge } from "react-bootstrap";
import { parseString } from "helpers/parseString";

class TotalProcessed extends Component {
  state = {
    totalProcessed: "",
    daysCount: 7,
    width: 0,
  };

  componentDidMount = async () => {
    await this.props.getTotalProcessedAction({ days: this.state.daysCount });
    this.setState({ totalProcessed: this.props.totalProcessed });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    const totalLength = this.props.totalProcessed
      ? this.props.totalProcessed.length
      : 0;
    let { totalProcessed } = this.state;

    const total = parseString(totalProcessed, totalLength);

    this.setState({
      totalProcessed: total,
    });
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { loading, classname } = this.props;
    const { totalProcessed } = this.state;

    if (loading) return <Spinner />;
    else if (totalProcessed !== undefined)
      return (
        <div>
          <Can do="REPORT" on="TOTALPROCESSED">
            <div className={classname}>
              € {totalProcessed ? totalProcessed : 0}
            </div>
          </Can>
          <Can not do="REPORT" on="TOTALPROCESSED">
            {this.state.width > 1200 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(30%)",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : this.state.width > 767 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(40%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                    No privileges
              </Badge>
            )}
          </Can>
        </div>
      );
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    totalProcessed: state.transactions.totalProcessed,
    loading: state.transactions.totalProcessedLoading,
  };
};

export default connect(mapStateToProps, {
  getTotalProcessedAction,
})(TotalProcessed);

TotalProcessed.propTypes = {
  classname: PropTypes.string,
  getTotalProcessedAction: PropTypes.func,
  loading: PropTypes.bool,
  totalProcessed: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};
