import * as types from "../constants/actionTypes";
import {
  getCardTypes,
  getCardTypesDaily,
} from "services/paymentBackendAPI/reports/transactions";

export const getReportCardTypeAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_CARD_TYPES,
    });
    try {
      const response = await getCardTypes(params);
      return dispatch({
        type: types.GET_TRANSACTION_CARD_TYPES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_CARD_TYPES_FAILED,
      });
      throw error;
    }
  };
};

export const getReportCardTypeDailyAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_CARD_TYPES,
    });
    try {
      const response = await getCardTypesDaily(params);
      return dispatch({
        type: types.GET_TRANSACTION_CARD_TYPES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_CARD_TYPES_FAILED,
      });
      throw error;
    }
  };
};
