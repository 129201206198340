import React from "react";
import Content from "../Content";
import GroupLogsMain from "components/Log/Group/Main";

const GroupsLogs = () => {
  return (
    <Content>
      <GroupLogsMain />
    </Content>
  );
};

export default GroupsLogs;
