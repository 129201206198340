import * as types from "../constants/actionTypes";
import {
  getShops,
  getShop,
  createShop,
  deleteShop,
  updateShop,
  getTerminalsByGateway,
  getShopGatewayProps,
  editShopGatewayProps,
  getShopTerminals,
  getShopTerminal,
  createShopTerminals,
  generateTerminalCredentials
} from "../services/paymentBackendAPI/management/shops";
import {
  getMerchantShops
} from "../services/paymentBackendAPI/management/merchants";
import {
  getAmountShops,
  getTopShops
} from "../services/paymentBackendAPI/reports/entities";
import {
  getShopTotals
} from "../services/paymentBackendAPI/reports/transactions";
import { loadTable } from "./store";

export const getAllShops = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOPS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getShops(args);
      dispatch({
        type: types.GET_SHOPS_SUCCEED,
        data: response.data,
        currentPage: args && args.page,
        pageSize: args && args.items
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_SHOPS_FAILED
      });
    }
  };
};

export const getMerchantShopsAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOPS
    });
    try {
      const response = await getMerchantShops(id, page, items);
      dispatch({
        type: types.GET_SHOPS_SUCCEED,
        data: response.data,
        currentPage: page,
        pageSize: items
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_SHOPS_FAILED
      });
    }
  };
};


export const getTerminalsByGatewayAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TERMINALS_BY_GATEWAY
    });
    try {
      const response = await getTerminalsByGateway(id, page, items);
      return dispatch({
        type: types.GET_TERMINALS_BY_GATEWAY_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TERMINALS_BY_GATEWAY_FAILED
      });
      throw (error);
    }
  };
};


export const getShopAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOP
    });
    try {
      const response = await getShop(id);
      return dispatch({
        type: types.GET_SHOP_SUCCEED,
        shop: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_SHOP_FAILED
      });
    }
  };
};

export const deleteShopAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteShop(data);
    return dispatch(getAllShops({ page, items, ...search }));
  };
};

export const addShopAction = (data, page, items, search) => {
  return async (dispatch) => {
    const response = await createShop(data);
    dispatch({
      type: types.ADD_SHOP,
      shop: response.data
    });
    dispatch(getAllShops({ page, items, ...search }));
  };
};

export const editShopAction = (data) => {
  return async (dispatch) => {
    const response = await updateShop(data);
    dispatch({
      type: types.EDIT_SHOP,
      shop: response.data
    });
  };
};

export const getShopTerminalsAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOP_TERMINALS
    });
    try {
      const response = await getShopTerminals(data);
      return dispatch({
        type: types.GET_SHOP_TERMINALS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_SHOP_TERMINALS_FAILED
      });
    }
  };
};

export const getShopTerminalAction = (shopId, id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOP_TERMINAL
    });
    try {
      const response = await getShopTerminal(shopId, id);
      return dispatch({
        type: types.GET_SHOP_TERMINAL_SUCCEED,
        shopTerminal: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_SHOP_TERMINAL_FAILED
      });
    }
  };
};

export const addShopTerminalAction = (id, data, items, search) => {
  return async (dispatch) => {
    const response = await createShopTerminals(id, data);
    dispatch({
      type: types.ADD_SHOP_TERMINAL,
      shopTerminal: response.data
    });
    dispatch(getShopTerminalsAction(id, items, search));
  };
};

export const generateTerminalCredentialsAction = (id) => {
  return async (dispatch) => {
    const response = await generateTerminalCredentials(id);
    dispatch({
      type: types.GENERATE_TERMINAL_CREDENTIALS,
      data: response.data
    });
  };
};

export const getAmountShopsForGroup = () => {
  return async (dispatch) => {
    const response = await getAmountShops();
    dispatch({
      type: types.GET_AMOUNT_SHOPS_FOR_GROUP,
      count: response.data.count
    });
  };
};

export const getAmountShopsForPartner = () => {
  return async (dispatch) => {
    const response = await getAmountShops();
    dispatch({
      type: types.GET_AMOUNT_SHOPS_FOR_PARTNER,
      count: response.data.count
    });
  };
};

export const getShopGatewayPropsAction = (guid, gatewayGuid) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOP_GATEWAY_PROPS
    });
    try {
      const response = await getShopGatewayProps(guid, gatewayGuid);
      return dispatch({
        type: types.GET_SHOP_GATEWAY_PROPS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_SHOP_GATEWAY_PROPS_FAILED
      });
    }
  };
};


export const editShopGatewayPropsAction = (guid, gatewayGuid, data) => {
  return async (dispatch) => {
    await editShopGatewayProps(guid, gatewayGuid, data);
    return dispatch(getShopGatewayPropsAction(guid, gatewayGuid));
  };
};

export const getShopTotalsAction = (page, items, from_date, to_date) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_REPORT_SHOP_TOTALS
    });
    try {
      const response = await getShopTotals(page, items, from_date, to_date);
      return dispatch({
        type: types.GET_REPORT_SHOP_TOTALS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_REPORT_SHOP_TOTALS_FAILED
      });
      throw (error);
    }
  };
};

export const getTopShopsAction = (days, count) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TOP_SHOPS
    });
    try {
      const response = await getTopShops(days, count);
      return dispatch({
        type: types.GET_TOP_SHOPS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TOP_SHOPS_FAILED
      });
      throw (error);
    }
  };
};
