import React, { Component } from "react";
import { Col, Row, Form, Button, Badge } from "react-bootstrap";
import RolePrivilegesManagement from "./PrivilegesManagement";
import { connect } from "react-redux";
import {
  getRoleAction,
  updateRoleAction,
  upsertPrivilegeToRoleAction,
  getRolePrivilegesAction,
} from "actions/roles";
import { getPrivilegesWithType } from "actions/privileges";
import { showModal } from "../../actions/modal";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import Alert from "../UI/Alert";

class RoleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        guid: "",
        name: "",
        description: "",
        type: "",
        reason: "",
        changedPrivileges: [],
      },

      oldData: {
        guid: "",
        name: "",
        description: "",
        type: "",
        changedPrivileges: [],
      },
      selectedType: {},
      isLoading: false,
      errors: {},

      nameValidation: false,
      typeValidation: false,
      descriptionValidation: false,
    };
  }

  schema = {
    name: Joi.string().required().label("Name"),
    type: Joi.string().required().label("Type"),
    description: Joi.string().required().label("Description"),
    privileges: Joi.any(),
    reason: Joi.string().required().label("Reason"),
  };

  componentDidMount = async () => {
    const roleGuid = this.props.role_guid;
    await this.props.getRoleAction(roleGuid);
    await this.props.getRolePrivilegesAction(roleGuid);
    await this.props.getPrivilegesWithType({ type: this.props.role.type });
    this.setState({
      data: this.props.role,
      nameValidation: this.props.role.name ? true : false,
      typeValidation: this.props.role.type ? true : false,
      descriptionValidation: this.props.role.description ? true : false,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  validate = () => {
    const options = { abortEarly: false };
    let data = {
      name: this.state.data.name,
      reason: this.state.data.reason,
      type: this.state.data.type,
      description: this.state.data.description,
      privileges: this.state.data.changedPrivileges,
    };
    let { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleSave = async (changedPrivileges) => {
    const data = { ...this.state.data };
    data.changedPrivileges = await changedPrivileges;
    this.setState({ data });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data.reason = input.value;
    this.setState({
      data,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });

      const { data } = this.state;

      try {
        await this.props.updateRoleAction({
          guid: data.guid,
          name: data.name,
          type: data.type,
          reason: data.reason,
          description: data.description,
        });
        data.changedPrivileges = await this.props.rolePrivileges;
        this.setState({ data });
        if (data.changedPrivileges) {
          const req = {
            guid: data.guid,
            privileges: data.changedPrivileges,
          };
          await this.props.upsertPrivilegeToRoleAction(req);
        }
        swal({
          title: "Role is updated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { data } = this.state;
    const { errors } = this.state;

    if (
      this.props.roleLoading ||
      this.props.rolePrivilegesLoading ||
      this.props.privilegesLoading
    ) {
      return <Spinner />;
    } else
      return (
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Row>
            <Col md={3} lg={3} xs={3}>
              <Form.Label>Name:</Form.Label>
            </Col>
            <Col md={9} lg={9} xs={9}>
              <Form.Group validationState={this.formValidation("name")}>
                <Form.Control
                  name="name"
                  type="text"
                  value={data.name}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.name && (
                  <span className="validate-error">{errors.name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} lg={3} xs={3}>
              <Form.Label>Type:</Form.Label>
            </Col>
            <Col md={2} lg={2} xs={2}>
              <Form.Group validationState={this.formValidation("type")}>
                <Badge className="badge-role-type">
                  {this.state.data.type.toUpperCase()}
                </Badge>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} lg={3} xs={3}>
              <Form.Label>Description:</Form.Label>
            </Col>
            <Col md={9} lg={9} xs={9}>
              <Form.Group validationState={this.formValidation("description")}>
                <Form.Control
                  name="description"
                  type="text"
                  value={data.description}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.description && (
                  <span className="validate-error">{errors.description}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} lg={3} xs={3}>
              <Form.Label>Privileges:</Form.Label>
            </Col>
            <Col md={9} lg={9} xs={9}>
              <RolePrivilegesManagement
                roleGuid={this.props.role_guid}
                //role={this.props.role}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3} lg={3} xs={3}>
              <Form.Label>Reason</Form.Label>
            </Col>
            <Col md={9} lg={9} xs={9}>
              <Form.Group>
                <Form.Control
                  placeholder="Enter reason"
                  name="reason"
                  value={this.state.data.reason}
                  onChange={this.handleChangeReason}
                />
                {errors.reason && (
                  <span className="validate-error">{errors.reason}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.nameValidation &&
                  this.state.typeValidation &&
                  this.state.descriptionValidation
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                style={{ margin: "10px" }}
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.roles.role,
    rolePrivileges: state.roles.changedPrivileges,
    roleLoading: state.roles.roleLoading,
    rolePrivilegesLoading: state.roles.rolePrivilegesLoading,
    privilegesLoading: state.privileges.privilegesLoading,
  };
};

export default connect(mapStateToProps, {
  getRoleAction,
  updateRoleAction,
  upsertPrivilegeToRoleAction,
  showModal,
  getRolePrivilegesAction,
  getPrivilegesWithType,
})(RoleEditor);

RoleEditor.propTypes = {
  getPrivilegesWithType: PropTypes.func,
  getRoleAction: PropTypes.func,
  getRolePrivilegesAction: PropTypes.func,
  privilegesLoading: PropTypes.bool,
  role: PropTypes.object,
  roleLoading: PropTypes.bool,
  rolePrivileges: PropTypes.array,
  rolePrivilegesLoading: PropTypes.bool,
  role_guid: PropTypes.string,
  showModal: PropTypes.func,
  updateRoleAction: PropTypes.func,
  upsertPrivilegeToRoleAction: PropTypes.func,
};
