import * as types from "../constants/actionTypes";

const initialState = {
  currenciesList: [],
  currencyReport: [],
  dailyCurrencyReport: [],
  currency: {},
  currencyRates: {},
  loading: false,
  error: false,
  currencyRatesLoading: false
};

export default function currencies(state = initialState, action) {
  switch (action.type) {
    case types.GET_CURRENCIES:
      return {
        ...state,
        currenciesList: [],
        currenciesLoading: true,
        error: false
      };
    case types.GET_CURRENCIES_SUCCEED:
      return {
        ...state,
        currenciesList: action.data.data,
        count: action.data.count,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        currenciesLoading: false,
        error: false
      };
    case types.GET_CURRENCIES_FAILED:
      return {
        ...state,
        currenciesLoading: false,
        error: true
      };
    case types.GET_CURRENCY:
      return {
        ...state,
        currency: {},
        currencyLoading: true,
        error: false
      };
    case types.GET_CURRENCY_SUCCEED:
      return {
        ...state,
        currency: action.data,
        currencyLoading: false,
        error: false
      };
    case types.GET_CURRENCY_FAILED:
      return {
        ...state,
        currencyLoading: false,
        error: true
      };
    case types.EDIT_CURRENCY: {
      let newCurrenciesList = state.currenciesList;
      newCurrenciesList = newCurrenciesList.map(currency => {
        if (currency.guid === action.currency.guid)
          return action.currency;
        else
          return currency;
      });
      return { ...state, currenciesList: newCurrenciesList };
    }
    case types.ADD_CURRENCY:
      return { ...state };
    case types.DELETE_CURRENCY: {
      let newCurrenciesList = state.currenciesList.filter(currency => currency.guid !== action.currency.guid);
      return { ...state, currenciesList: newCurrenciesList };
    }
    case types.GET_REPORT_CURRENCY:
      return {
        ...state,
        currencyReportLoading: true
      };
    case types.GET_REPORT_CURRENCY_SUCCEED:
      return {
        ...state,
        currencyReport: action.data,
        currencyReportLoading: false
      };
    case types.GET_REPORT_CURRENCY_FAILED:
      return {
        ...state,
        currencyReportLoading: false
      };
    case types.GET_REPORT_DAILY_CURRENCY:
      return {
        ...state,
        dailyCurrencyReportLoading: true
      };
    case types.GET_REPORT_DAILY_CURRENCY_SUCCEED:
      return {
        ...state,
        dailyCurrencyReport: action.data,
        dailyCurrencyReportLoading: false
      };
    case types.GET_REPORT_DAILY_CURRENCY_FAILED:
      return {
        ...state,
        dailyCurrencyReportLoading: false
      };

    case types.GET_CURRENCY_RATES:
      return {
        ...state,
        currencyRatesLoading: true
      };
    case types.GET_CURRENCY_RATES_SUCCEED:
      return {
        ...state,
        currencyRates: action.data,
        currencyRatesLoading: false
      };
    case types.GET_CURRENCY_RATES_FAILED:
      return {
        ...state,
        currencyRatesLoading: false
      };
    default:
      return state;
  }
}