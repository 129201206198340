import React from "react";
import Content from "../Content";
import PartnerLogsMain from "components/Log/Partner/Main";

const PartnersLogs = () => {
  return (
    <Content>
      <PartnerLogsMain />
    </Content>
  );
};

export default PartnersLogs;
