import React from "react";
import PieChart from "components/UI/PieChart";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import { backgroundColors } from "constants/charts/colors";

class PieChartTransactionsTypes extends React.Component {
  render() {
    if (this.props.loading) return <Spinner />;
    else {
      let datasetsPie = [];
      let labelsPie = this.props.data.length === 0 ? [] : {};
      let data = {};
      let datasets = [];
      let colors = [];
      this.props.data.forEach((item) => {
        labelsPie[item.type] = "";
      });
      labelsPie = Object.keys(labelsPie);
      labelsPie.forEach((item) => {
        data[item] = 0;
      });
      this.props.data.forEach((item) => {
        data[item.type] += parseInt(item.success, 10);
      });

      labelsPie.forEach((item, index) => {
        datasets = [ ...datasets, data[item] ];
        colors.push(backgroundColors[index]);
      });
      datasetsPie = [ { data: datasets, backgroundColor: colors } ];

      return (
        <PieChart
          name="Transaction types"
          labels={
            labelsPie.length !== 0
              ? labelsPie
              : [ "There are no transactions yet" ]
          }
          datasets={
            datasetsPie[0].data.length !== 0
              ? datasetsPie
              : [
                  {
                    data: [ 1 ],
                    backgroundColor: "#CCC",
                  },
                ]
          }
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.transactions.dailyTransaction,
    loading: state.transactions.dailyTransactionLoading,
  };
};

export default connect(mapStateToProps, {})(PieChartTransactionsTypes);

PieChartTransactionsTypes.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
