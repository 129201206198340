import React from "react";

import { Card } from "react-bootstrap";
import Content from "../Content";
import { getAllMerchants } from "actions/merchants";
import {
  getAllShops,
  getMerchantShopsAction,
  getShopTerminalsAction,
} from "actions/shops";
import { getGeographicReportAction } from "actions/transactions";
import moment from "moment";
import { connect } from "react-redux";
import Table from "components/UI/Table/index";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import ReportForm from "../../components/Report/ReportForm";
import { ExportButton } from "../../components/ExportButton";
import { exportGeoReport } from "../../services/paymentBackendAPI/reports/transactions";

class GeographicReport extends React.Component {
  state = {
    data: {
      merchant_guid_array: [],
      group_guid_array: [],
      partner_guid_array: [],
      terminal_guid_array: [],
      shop_guid_array: [],
      from_date: moment()
        .format("YYYY-MM-DDTHH:mm:ss")
        .replace(/\D\d{2}\w/, "-01T"),
      to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    },
    response: [],
    maxPercent: 100,
  };

  componentDidMount = async () => {
    await this.loadData();
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      await this.props.getGeographicReportAction(this.state.data);

      let response = this.props.response.data || [];

      this.setState({ response, maxPercent: this.props.response.max_percent });
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  handleClick = async (data) => {
    this.setState({ data }, async () => {
      await this.loadData();
    });
  };

  export = async (data) => {
    this.setState({ exportLoading: true });
    let res;
    let a;
    let file;
    switch (data.export_document_format) {
      case "xls":
        res = await exportGeoReport(data);
        a = document.createElement("a");
        file = new Blob([ res.data ], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        a.href = URL.createObjectURL(file);
        a.download = "basic-report.xlsx";
        a.click();
        break;
      case "pdf":
        res = await exportGeoReport(data);
        a = document.createElement("a");
        a.href = res.data;
        a.download = "basic-report.pdf";
        a.click();
        break;
      case "csv":
        res = await exportGeoReport(data);
        a = document.createElement("a");
        file = new Blob([ res.data ], { type: "text/plain" });
        a.href = URL.createObjectURL(file);
        a.download = "basic-report.csv";
        a.click();
        break;

      default:
        break;
    }

    this.setState({ exportLoading: false });
  };

  render() {
    const colorScale = scaleLinear()
      .domain([ 0, this.state.maxPercent ])
      .range([ "#ffedea", "#ff5233" ]);
    const geoUrl =
      "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

    return (
      <Content>
        <ReportForm
          handleSubmit={this.handleClick}
          isSelectCurrency={false}
          isExport
          exportHandler={this.export}
        />

        <Card>
          <Card.Body>
            <div>
              <ComposableMap
                projectionConfig={{
                  scale: 120,
                  rotation: [ -10, 0, 0 ],
                }}
                width={800}
                height={400}
                style={{ width: "100%", height: "auto" }}
              >
                <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
                <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
                {this.state.response.length > 0 && (
                  <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                      geographies.map((geo) => {
                        const d = this.state.response.find(
                          (s) => s.country_code === geo.properties.ISO_A2
                        );
                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill={d ? colorScale(d.percent) : "#F5F4F6"}
                          />
                        );
                      })
                    }
                  </Geographies>
                )}
              </ComposableMap>
            </div>
            <ExportButton
              name="geo_report"
              data={this.props.response.data || []}
              columns={[
                { path: "country_code", label: "Country code" },
                { path: "transaction_count", label: "Transaction count" },
              ]}
            />
            <Table
              columns={[
                {
                  path: "country_code",
                  label: "Country code",
                  content: (item) =>
                    `${item.country_name} (${item.country_code})`,
                },
                {
                  path: "transaction_count",
                  label: "Transaction count",
                  content: (item) =>
                    `${item.transaction_count} (${item.percent}%)`,
                },
              ]}
              data={this.props.response.data || []}
              keyPath="err_code"
              disableSearch
              disableSort
            />
          </Card.Body>
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    response: state.transactions.geographicReport,
  };
};

export default connect(mapStateToProps, {
  getGeographicReportAction,
  getAllMerchants,
  getMerchantShopsAction,
  getAllShops,
  getShopTerminalsAction,
})(GeographicReport);
