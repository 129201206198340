import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { showModal } from "../../actions/modal";
import PropTypes from "prop-types";
import Pagination from "components/UI/Pagination";
import Table from "components/UI/Table";
import Spinner from "components/UI/Spinner/index";
import { getServiceChangeHistoryAction } from "../../actions/reasons";
import Content from "views/Content";
import moment from "moment";
import { parseResponse } from "helpers/parseResponse.js";
import Alert from "../UI/Alert";

class ServiceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      history: [],
      columnsHistory: [],
    };
  }

  tryObjToStr = (obj) => {
    try {
      return JSON.stringify(obj, true, 4);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  columnsHistory = [
    { path: "name", label: "Name", key: "name" },
    { path: "start_time", label: "Start time", key: "start_time_audit" },
    {
      path: "options",
      label: "options",
      key: "options",
      content: (item) => (item.options ? this.tryObjToStr(item.options) : null),
    },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];

  async componentDidMount() {
    await this.props.getServiceChangeHistoryAction(
      this.props.name,
      1,
      this.state.pageSize
    );
    if (this.props.error) {
      this.setState({
        history: [],
      });
    } else
      this.setState({
        history: this.props.serviceHistory,
      });
  }

  componentWillUnmount() {
    this.setState({
      history: [],
    });
  }

  handleLoginsPageChange = async (page) => {
    await this.props.getServiceChangeHistoryAction(
      this.props.name,
      page,
      this.state.pageSize
    );
    this.setState({
      currentPage: page,
      history: this.props.serviceHistory,
    });
  };

  getPagedData = () => {
    const { count } = this.props;
    let { pageSize } = this.state;
    const pagesCount = count / pageSize + (1 && !!(count % pageSize));
    return { pagesCount };
  };

  render() {
    const { currentPage } = this.state;
    const { history } = this.state;
    const { pagesCount } = this.getPagedData();
    if (this.props.serviceHistoryloading) {
      return <Spinner />;
    } else
      return (
        <Card>
          <Card.Body>
            <Card.Title>History</Card.Title>

            {this.props.serviceHistoryloading ? (
              <Content>
                <Spinner />
              </Content>
            ) : (
              <React.Fragment>
                {history.length !== 0 ? (
                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      <Table
                        columns={this.columnsHistory}
                        data={history ? history : []}
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={pagesCount}
                        currentPage={currentPage}
                        onPageChange={this.handleLoginsPageChange}
                        pageSize={this.state.pageSize}
                        count={this.props.count}
                      />
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <b>Sorry, this currency doesn`t have history</b>
                  </div>
                )}
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceHistory: state.reasons.serviceHistory,
    serviceHistoryloading: state.reasons.loading,
    count: state.reasons.count,
    error: state.reasons.error,
  };
};

export default connect(mapStateToProps, {
  getServiceChangeHistoryAction,
  showModal,
})(ServiceHistory);

ServiceHistory.propTypes = {
  count: PropTypes.number,
  error: PropTypes.bool,
  getServiceChangeHistoryAction: PropTypes.func,
  name: PropTypes.string,
  serviceHistory: PropTypes.array,
  serviceHistoryloading: PropTypes.bool,
};
