import React from "react";

import { Card, Row, Col } from "react-bootstrap";
import Content from "../Content";
import { getTransactionTypesAction } from "actions/transactions";
import { getAllMerchants } from "actions/merchants";
import {
  getAllShops,
  getMerchantShopsAction,
  getShopTerminalsAction,
} from "actions/shops";
import { getErrorReportAction } from "actions/transactions";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import PassabilityMain from "components/Report/Basic/Passability";
import PieChart from "../../components/UI/PieChart";
import { HorizontalBarChart } from "../../components/UI/HorizontalBarChart";
import { backgroundColors } from "../../constants/charts/colors";
import Table from "components/UI/Table/index";
import Spinner from "components/UI/Spinner/index";

import ReportFrom from "../../components/Report/ReportForm";
import { ExportButton } from "../../components/ExportButton";
class ErrorReport extends React.Component {
  state = {
    data: {
      merchant_guid_array: [],
      group_guid_array: [],
      partner_guid_array: [],
      terminal_guid_array: [],
      shop_guid_array: [],
      from_date: moment()
        .format("YYYY-MM-DDTHH:mm:ss")
        .replace(/\D\d{2}\w/, "-01T"),
      to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    },

    datasets: [],
    datasetsPie: [],
    labels: [],
    loading: true,
  };

  componentDidMount = async () => {
    await this.loadData();
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      await this.props.getErrorReportAction(this.state.data);
      let response = this.props.response.data || [];
      let labels = [];
      let data = [];
      let datasetsPie = [];

      let colors = [];

      response.forEach((item, index) => {
        labels.push("Error: " + item.err_code);
        colors.push(backgroundColors[index]);
        data.push(parseInt(item.err_count, 10));
      });

      datasetsPie = data.length
        ? [ { data: data, backgroundColor: colors } ]
        : [
            {
              data: [ 1 ],
              backgroundColor: "#CCC",
            },
          ];

      labels = labels.length ? labels : [ "There are no errors" ];

      let datasets = [
        {
          label: labels,
          backgroundColor: backgroundColors,
          borderColor: backgroundColors,
          borderWidth: 1,
          hoverBackgroundColor: backgroundColors,
          hoverBorderColor: backgroundColors,
          data: data,
        },
      ];

      this.setState({ labels, datasets, datasetsPie });
      await this.props.getTransactionTypesAction(this.state.data);
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  handleClick = (data) => {
    this.setState({ data }, async () => {
      await this.loadData();
    });
  };

  render() {
    return (
      <Content>
        <ReportFrom handleSubmit={this.handleClick} isSelectCurrency={false} />

        <Card>
          <Card.Body>
            {this.state.loading ? (
              <Spinner />
            ) : (
              <>
                <Row>
                  <Col md={6} sm={6} xs={6}>
                    <div className="header">Errors</div>
                    <PieChart
                      name="error"
                      labels={this.state.labels}
                      datasets={this.state.datasetsPie}
                    />
                    {/* <Pie
                  // ref={(ref) => (this.pie = ref)}
                  data={{
                    labels: this.state.labels,
                    datasets: this.state.datasetsPie,
                  }}
                  height={100}
                  options={{
                    maintainAspectRation: false,
                    legend: {
                      display: true,
                    },
                  }}
                /> */}
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <div className="header">Passability</div>
                    <PassabilityMain />
                  </Col>
                </Row>

                <HorizontalBarChart
                  name="error"
                  labels={this.state.labels}
                  datasets={this.state.datasets}
                  options={{
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem) {
                          return tooltipItem.xLabel;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                  height={50}
                />
                <ExportButton
                  name="error_report"
                  data={this.props.response.data || []}
                  columns={[
                    {
                      path: "err_code",
                      label: "Error",
                      content: (cell) =>
                        cell.err_code ? cell.err_code : "null",
                    },
                    { path: "err_count", label: "Count" },
                    { path: "err_message", label: "Description" },
                  ]}
                />
                <Table
                  columns={[
                    {
                      path: "err_code",
                      label: "Error",
                      content: (cell) =>
                        cell.err_code ? cell.err_code : "null",
                    },
                    { path: "err_count", label: "Count" },
                    { path: "err_message", label: "Description" },
                  ]}
                  data={this.props.response.data || []}
                  keyPath="err_code"
                  disableSearch
                  disableSort
                />
              </>
            )}
          </Card.Body>
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    response: state.transactions.errorReport,
  };
};

export default connect(mapStateToProps, {
  getErrorReportAction,
  getAllMerchants,
  getMerchantShopsAction,
  getAllShops,
  getShopTerminalsAction,
  getTransactionTypesAction,
})(ErrorReport);

ErrorReport.propTypes = {
  getErrorReportAction: PropTypes.func,
};
