import moment from "moment";
import { connect } from "react-redux";
import { getAuditPartnerAction } from "../../../../actions/audit";
import { reset, searchInPartnersAuditTable } from "../../../../actions/search";
import { resetPage, setNewTable } from "../../../../actions/store";
import AbstractComponent from "../../../../factories/Main";
import { cutGuid } from "../../../../helpers/cutGuid";
import { getAuditPartners } from "../../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../../UI/CopyToClipboard";
import React from "react";
import { Link } from "react-router-dom";

const columns = [
  {
    path: "partner_guid",
    label: "Guid",
    key: "guid_audit",
    content: (el) => (
      <>
        <Copy text={el.guid} />
        <Link className="link" to={`/about/audit/partner/${el.partner_guid}`}>
          {cutGuid(el.partner_guid)}
        </Link>
      </>
    ),
  },
  {
    path: "partner_name",
    label: "Name",
    key: "name",
  },
  { path: "change_count", label: "count", key: "count" },
  { path: "partner_status", label: "status", key: "statusaudit" },
  {
    path: "update_date",
    label: "last update date",
    key: "last update date",
    content: (partner) =>
      moment(partner.last_change)
        .utcOffset(0)
        .format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.partners,
    count: state.audit.partnersCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.partnerAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_partner",
    keyPath: "guid",
    columns,
    exportFunction: getAuditPartners,
  };
};

export default connect(mapStateToProps, {
  get: getAuditPartnerAction,
  search: searchInPartnersAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
