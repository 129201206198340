import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Overlay, Tooltip } from "react-bootstrap";

export const Copy = (props) => {
  const [ copySuccess, setCopySuccess ] = useState(false);
  const target = useRef(null);

  return (
    <>
      <CopyToClipboard
        text={props.text}
        onCopy={() => {
          setCopySuccess(true);
          setTimeout(function () {
            setCopySuccess(false);
          }, 1000);
        }}
      >
        <i
          className="icon-copy icon gray"
          ref={target}
          style={{ float: "left", cursor: "pointer", paddingRight: "5px" }}
        ></i>
      </CopyToClipboard>
      <Overlay target={target.current} show={copySuccess} placement="right">
        <Tooltip id="overlay-example">Copied</Tooltip>
      </Overlay>
    </>
  );
};

Copy.propTypes = {
  text: PropTypes.string,
};
