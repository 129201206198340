import React, { useState, useCallback } from "react";
import { Col, Row, Form } from "react-bootstrap";
import ReactLoading from "react-loading";

function renderCurrencyInput(
  label,
  value,
  name,
  handler,
  rateType,
  errors,
  selectedCurrencyName,
  type = "number",
) {
  return (
    <Row >
      <Col md={3}>
        <Form.Label>{label}*</Form.Label>
      </Col>
      <Col md={8}>
        <Form.Group>
          <Form.Control
            placeholder={`Enter ${label.toLowerCase()}`}
            type={type}
            name={name}
            value={value}
            onChange={handler}
            disabled={
              label === selectedCurrencyName
                ? true
                : false
            }
          />
          {errors[name + rateType] && (
            <span className="validate-error">{errors[name + rateType]}</span>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}

function CurrencyRatesSelector(
  props
) {
  const [ isBankExchangeCurrenciesRatesActive, setBankExchangeCurrenciesRates ] = useState(false);
  const [ isProcessorExchangeCurrenciesRatesActive, setProcessorExchangeCurrenciesRates ] = useState(false);

  const handleSetBankExchangeCurrenciesRates = useCallback(() => {
    setBankExchangeCurrenciesRates(!isBankExchangeCurrenciesRatesActive);
  }, []);

  const handleSetProcessorExchangeCurrenciesRates = useCallback(() => {
    setProcessorExchangeCurrenciesRates(!isProcessorExchangeCurrenciesRatesActive);
  }, []);

  return (
    <>
      <Row >
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Set bank exchange currencies rates</Form.Label>
        </Col>
        <Col md={1} sm={1} xs={1}>
          <Form.Group>
            <input
              type="checkbox"
              id="setBankExchangeCurrenciesRates"
              checked={isBankExchangeCurrenciesRatesActive}
              onChange={handleSetBankExchangeCurrenciesRates}
            />
          </Form.Group>
        </Col>

      </Row>
      {isBankExchangeCurrenciesRatesActive && (
        <>
          {props.currencyLoading ? (
            <div>
              <ReactLoading type="cylon" color="grey" />
            </div>
          ) : (
            props.selectedCurrenciesRates.map((currency) =>
              renderCurrencyInput(
                currency.code,
                currency.bank_exchange_rate,
                currency.guid,
                props.handleChangeBankExchangeselectedCurrenciesRates,
                "bank_exchange_rate",
                props.errors,
                props.selectedCurrencyName
              )
            )
          )}
        </>
      )}
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Set processor exchange currencies rates</Form.Label>
        </Col>
        <Col md={1} sm={1} xs={1}>
          <Form.Group>
            <input
              type="checkbox"
              id="setProcessorExchangeCurrenciesRates"
              checked={isProcessorExchangeCurrenciesRatesActive}
              onChange={
                handleSetProcessorExchangeCurrenciesRates
              }
            />
          </Form.Group>
        </Col>

      </Row>
      {isProcessorExchangeCurrenciesRatesActive && (
        <>
          {props.currencyLoading ? (
            <div>
              <ReactLoading type="cylon" color="grey" />
            </div>
          ) : (
            <div>
              {props.selectedCurrenciesRates.map((currency) =>
                renderCurrencyInput(
                  currency.code,
                  currency.processor_exchange_rate,
                  currency.guid,
                  props.handleProcessorExchangeChangeselectedCurrenciesRates,
                  "processor_exchange_rate",
                  props.errors,
                  props.selectedCurrencyName
                )
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CurrencyRatesSelector;