import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { reset } from "../../actions/search";
import { getAllServices } from "../../actions/services";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { getServices } from "../../services/paymentBackendAPI/management/services";
import ServiceEditor from "./Editor";
import ServiceHistory from "./History";
import ServiceProperty from "./Property";

const columns = [
  { path: "name", label: "Name", key: "serviceName" },
  { path: "start_time", label: "Start Time", key: "serviceStartTime" },
  ability.can("AUDIT", "SERVICESETTINGS") && {
    label: "History",
    key: "history",
    align: "center",
    content: (service) => (
      <Modal
        content={ServiceHistory}
        contentProps={{ name: service.name }}
        buttonName="Show"
        dialogClassName="modal-creator"
        button={
          <button type="button" className="btn btn-table">
            Show
          </button>
        }
      />
    ),
  },
  ability.can("READ", "SERVICES") && {
    key: "detail_service",
    content: (service) => (
      <Modal
        header="Detail"
        content={ServiceProperty}
        contentProps={{ name: service.name, type: "service" }}
        button={
          <button type="button" className="btn btn-table">
            Show
          </button>
        }
      />
    ),
    label: "Detail",
    align: "center",
  },
  ability.can("EXECUTE", "SERVICES") && {
    key: "edit",
    content: (service) => (
      <Modal
        header="Edit service"
        content={ServiceEditor}
        contentProps={{ name: service.name, type: "service" }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
      />
    ),
    label: "Edit",
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.services.servicesList,
    count: state.services.count,
    table: state.store.table,
    loading: state.services.servicesLoading,
    searchData: state.search.servicesSearch,
    isSearch: state.search.isSearch,
    name: "services",
    keyPath: "name",
    columns,
    exportFunction: getServices,
  };
};

export default connect(mapStateToProps, {
  get: getAllServices,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
