import React from "react";
import Table from "components/UI/Table/index";
import { columnsMergeRates, } from "./columnsAndRows";
import { Card } from "react-bootstrap";

function TablePayableSummary({ statementData }) {

  return (
    <>
      <Card.Title style={{ marginTop: "15px" }}>Report summary</Card.Title>
      <Table
        columns={columnsMergeRates}
        data={statementData}
        disableSearch
        disableSort
      />
    </>
  );
}

export default TablePayableSummary;
