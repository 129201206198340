import React from "react";
import ShopTotalsMain from "components/Report/Basic/ShopTotals";
import TotalsMain from "components/Report/Basic/Totals";
import { Card, Row, Col } from "react-bootstrap";
import Content from "../Content";
import CurrencyMain from "components/Report/Basic/Currency";
import TransactionTypeMain from "components/Report/Basic/TransactionType";
import PassabilityMain from "components/Report/Basic/Passability";
import PieChartCardsTypes from "components/Report/Basic/CardTypes";
import TransactionsMain from "components/Report/Basic/TransactionTypeHistory";
import Orders from "components/Report/Basic/Orders";

import { getAllMerchants } from "actions/merchants";
import {
  getAllShops,
  getMerchantShopsAction,
  getShopTerminalsAction,
} from "actions/shops";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import ReportForm from "../../components/Report/ReportForm";
import Alert from "../../components/UI/Alert";
import { parseResponse } from "../../helpers/parseResponse";
import { getTransactionTypesAction } from "actions/transactions";
import { exportBasicReport } from "../../services/paymentBackendAPI/reports/transactions";
class ShopTotals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        merchant_guid_array: [],
        group_guid_array: [],
        partner_guid_array: [],
        terminal_guid_array: [],
        shop_guid_array: [],
        from_date: moment()
          .format("YYYY-MM-DDTHH:mm:ss")
          .replace(/\D\d{2}\w/, "-01T"),
        to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      },
    };
  }

  onSubmitCalculate = async (data) => {
    this.setState({ isLoading: true });
    try {
      this.setState({
        data,
      });
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  export = async (data) => {
    this.setState({ exportLoading: true });
    let res;
    let a;
    let file;
    switch (data.export_document_format) {
      case "xls":
        res = await exportBasicReport(data);
        a = document.createElement("a");
        file = new Blob([ res.data ], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        a.href = URL.createObjectURL(file);
        a.download = "basic-report.xlsx";
        a.click();
        break;
      case "pdf":
        res = await exportBasicReport(data);
        a = document.createElement("a");
        a.href = res.data;
        a.download = "basic-report.pdf";
        a.click();
        break;
      case "csv":
        res = await exportBasicReport(data);
        a = document.createElement("a");
        file = new Blob([ res.data ], { type: "text/plain" });
        a.href = URL.createObjectURL(file);
        a.download = "basic-report.csv";
        a.click();
        break;

      default:
        break;
    }

    this.setState({ exportLoading: false });
  };

  render() {
    return (
      <Content>
        <ReportForm
          handleSubmit={this.onSubmitCalculate}
          isSelectCurrency={false}
          isExport
          exportHandler={this.export}
        />

        <div className="card-large-report ">
          <Row>
            <Col md={12} sm={12} xs={12}>
              <TransactionsMain data={this.state.data} />
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={3} md={6} sm={12} xs={12}>
            <div className="card-large-report">
              <div className="header">Passability</div>
              <PassabilityMain />
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <div className="card-large-report">
              <div className="header">Currency</div>
              <CurrencyMain data={this.state.data} />
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <div className="card-large-report">
              <div className="header">Transactions Type</div>
              <TransactionTypeMain />
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <div className="card-large-report">
              <div className="header">Card type</div>
              <PieChartCardsTypes data={this.state.data} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} xs={12}>
            {/* <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Totals<b className="caret"></b>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <TotalsMain />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Shop Totals<b className="caret"></b>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <ShopTotalsMain />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Orders<b className="caret"></b>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Orders />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion> */}

            <Card>
              <Card.Header>Totals</Card.Header>
              <Card.Body>
                <TotalsMain data={this.state.data} />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>Shop Totals</Card.Header>
              <Card.Body>
                <ShopTotalsMain data={this.state.data} />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>Orders</Card.Header>
              <Card.Body>
                <Orders data={this.state.data} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    reportTransactionType: state.transactions.types,
    transactionTypesLoading: state.transactions.transactionTypesLoading,
  };
};

export default connect(mapStateToProps, {
  getAllMerchants,
  getMerchantShopsAction,
  getAllShops,
  getShopTerminalsAction,
  getTransactionTypesAction,
})(ShopTotals);

ShopTotals.propTypes = {
  getAllMerchants: PropTypes.func,
  getAllShops: PropTypes.func,
  getMerchantShopsAction: PropTypes.func,
  merchants: PropTypes.array,
  shops: PropTypes.array,
};
