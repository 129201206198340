//import LogOut from "components/UI/Logout";
import * as privileges from "../config/privileges";
import Shop from "../views/Shop";
import RolesPrivileges from "../views/RolePrivilege";
import Gateway from "../views/Gateway";
import GroupDetail from "../components/Group/Detail";
import PartnerDetail from "../components/Partner/Detail";
import MerchantDetail from "../components/Merchant/Detail";
import AdminDetail from "../components/Admin/Detail";
import RateDetail from "../components/Rate/Detail";
import RolesDetail from "../components/Role/Detail";
import Rate from "views/Rate";
import Admin from "../views/Admin";
import Merchant from "../views/Merchant";
import Group from "../views/Group";
import Partner from "../views/Partner";
import GatewayDetail from "../components/Gateway/Detail";
import ShopDetail from "../components/Shop/Detail";
import AdminDashboard from "../views/AdminDashboard";
import GroupDashboard from "../views/GroupDashboard";
import PartnerDashboard from "../views/PartnerDashboard";
import MerchantDashboard from "../views/MerchantDashboard";
import { getRoles } from "../services/paymentBackendAPI/backendPlatform";
import TransactionHistory from "../views/TransactionHistory";
import BlackList from "../views/BlackList/Rules";
import GlobalBlackList from "views/BlackList/Global";
import MerchantsBlackList from "views/BlackList/Merchants";
import Currencies from "views/Currency";
import Services from "views/Service";
import BlackListDetail from "components/BlackList/Rules/Detail";
import TransactionsLogs from "views/TransactionLogs";
import MerchantsLogs from "views/MerchantsLogs";
import GroupsLogs from "views/GroupsLogs";
import PartnersLogs from "views/PartnersLogs";
import TransactionTemplates from "views/TransactionTemplate";
import TransactionTemplateDetail from "components/Transaction/Template/Detail";
import TransactionForm from "views/TransactionForm";
import DailyReport from "views/DailyReport";
import UserAccount from "views/UserAccount";
import HistoryStepDetail from "../components/Transaction/History/HistoryStepDetail";
import HistoryParamsDetail from "../components/Transaction/History/HistoryParamsDetail";
import ShopTotals from "views/BasicReport";
import Orders from "../views/Orders";
import OrderDetail from "components/Orders/Detail";
import { isRoute } from "helpers/isRouteAvailable";
import TerminalDetail from "../components/Shop/Terminal/TerminalDetail";
import AdminsLogs from "../views/AdminsLogs";
// import Account from "../views/Account";
import Chargeback from "../views/Chargeback";
import ServiceLogs from "../views/ServiceLog";
import Statement from "../views/Statement";
import StatementCreator from "../components/Statement/Creator";
import StatementDetail from "../components/Statement/Detail";
import AuditMain from "../views/Audit/UsersAuditTable/index";
import TerminalAudit from "../views/Audit/TerminalsAuditTable";
import TransactionAudit from "../views/Audit/TransactionAuditTable";
import ShopAudit from "../views/Audit/ShopAuditTable";
// import AccountAudit from "../views/Audit/AccountAuditTable";
import GatewayAudit from "../views/Audit/GatewayAuditTable";
import CurrencyAudit from "../views/Audit/CurrencyAuditTable";
import ServiceAudit from "../views/Audit/ServiceAuditTable";
import ChargebackDetail from "../components/Chargeback/Detail";
import Merge from "../components/Statement/Merge";
import MismatchTransaction from "../views/MismatchTransactions";
import MismatchDetail from "../components/MismatchTransactions/Detail";
import TransactionAndCommissionReport from "../views/TransactionAndCommissionReport";
import RateTemplate from "../views/RateTemplate";
import RateTemplateDetail from "../components/Rate/Template/Detail";
import RateRevisionDetail from "../components/Rate/Revisions/Detail";
import AdditionalFee from "../views/AdditionalFee";
import NewForm from "../components/Transaction/Form/NewForm";
import ErrorReport from "../views/ErrorReport";
import GeographicReport from "../views/GeographicReport";
import HistogramReport from "../views/HistogramReport";
import MerchantsAuditDetail from "../components/Audit/UsersTables/Merchants/Detail";
import GroupsAuditDetail from "../components/Audit/UsersTables/Groups/Detail";
import PartnersAuditDetail from "../components/Audit/UsersTables/Partners/Detail";
import LoginAuditDetail from "../components/Audit/UsersTables/Logins/Detail";

const adminDashboard = [
  {
    path: "/dashboard",
    name: "Dashboard",
    report: true,
    alias: privileges.ADMINDASHBOARD,
    icon: "icon-dashboard",
    component: AdminDashboard,
  },
  {
    collapse: true,
    name: "Users",
    icon: "icon-users",
    state: "users",
    views: [
      {
        path: "/users/merchants",
        name: "Merchants",
        alias: privileges.MERCHANTS,
        component: Merchant,
      },
      {
        path: "/users/groups",
        name: "Groups",
        alias: privileges.GROUPS,
        component: Group,
      },
      {
        path: "/users/partners",
        name: "Partners",
        alias: privileges.PARTNERS,
        component: Partner,
      },
      {
        path: "/users/admins",
        name: "Admins",
        alias: privileges.ADMINS,
        component: Admin,
      },
    ],
  },
  {
    path: "/shops",
    name: "Shops",
    icon: "icon-shops",
    alias: privileges.SHOPS,
    component: Shop,
  },
  {
    collapse: true,
    name: "Transactions",
    icon: "icon-transactions",
    state: "transactions",
    views: [
      {
        path: "/transactions/history",
        name: "All transactions",
        alias: privileges.TRANSACTIONSHISTORY,
        component: TransactionHistory,
      },
      {
        path: "/transactions/templates",
        name: "Templates",
        alias: privileges.TRANSACTIONTEMPLATES,
        component: TransactionTemplates,
      },
      {
        path: "/transactions/orders",
        name: "Orders",
        report: true,
        alias: privileges.ORDERS,
        component: Orders,
      },
      {
        path: "/transactions/chargebacks",
        name: "Chargebacks",
        alias: privileges.CHARGEBACKS,
        component: Chargeback,
      },
      {
        path: "/transactions/mismatch",
        name: "Mismatch Transaction",
        alias: privileges.STATEMENTS,
        component: MismatchTransaction,
      },
      {
        path: "/transactions/statements",
        name: "Statements",
        alias: privileges.STATEMENTS,
        component: Statement,
      },
    ],
  },

  {
    collapse: true,
    name: "System",
    icon: "icon-system",
    state: "system",
    views: [
      {
        path: "/gateways",
        name: "Gateways",
        alias: privileges.GATEWAYS,
        component: Gateway,
      },
      {
        path: "/rates",
        name: "Rates",
        alias: privileges.RATES,
        component: Rate,
      },
      {
        path: "/rate/template",
        name: "Rates templates",
        icon: "pe-7s-cash",
        alias: privileges.RATES,
        component: RateTemplate,
      },
      {
        path: "/currencies",
        name: "Currencies",
        alias: privileges.CURRENCIES,
        component: Currencies,
      },
      {
        path: "/services",
        name: "Services",
        alias: privileges.SERVICES,
        component: Services,
      },
      {
        path: "/additional-fees",
        name: "Additional fees",
        alias: privileges.STATEMENTS,
        component: AdditionalFee,
      },
    ],
  },
  {
    collapse: true,
    name: "Reports",
    icon: "icon-reports",
    state: "reports",
    views: [
      {
        path: "/report/daily",
        name: "Daily Report",
        report: true,
        alias: privileges.DAILYREPORT,
        component: DailyReport,
      },
      {
        path: "/report/basic",
        name: "Basic Report",
        report: true,
        alias: privileges.BASICREPORT,
        component: ShopTotals,
      },
      {
        path: "/report/transaction_and_commission",
        name: "Transaction & Commission",
        report: true,
        alias: privileges.TRANSACTIONANDCOMISSION,
        component: TransactionAndCommissionReport,
      },
      {
        path: "/report/error",
        name: "Error Report",
        report: true,
        alias: privileges.TRANSACTIONERRORS,
        component: ErrorReport,
      },
      {
        path: "/report/transaction_histogram",
        name: "Histogram Report",
        report: true,
        alias: privileges.TRANSACTIONHISTOGRAM,
        component: HistogramReport,
      },
      {
        path: "/report/geographic",
        name: "Geographic Report",
        report: true,
        alias: "TRANSACTIONGEO",
        component: GeographicReport,
      },
    ],
  },
  {
    collapse: true,
    name: "Logs",
    icon: "icon-logs",
    state: "logs",
    views: [
      {
        path: "/logs/admins",
        name: "Admins logs",
        log: true,
        alias: "ADMIN",
        component: AdminsLogs,
      },
      {
        path: "/logs/merchants",
        name: "Merchants logs",
        log: true,
        alias: "MERCHANT",
        component: MerchantsLogs,
      },
      {
        path: "/logs/groups",
        name: "Groups logs",
        log: true,
        alias: "GROUP",
        component: GroupsLogs,
      },
      {
        path: "/logs/partners",
        name: "Partners logs",
        log: true,
        alias: "PARTNER",
        component: PartnersLogs,
      },
      {
        path: "/logs/transactions",
        name: "Transactions logs",
        log: true,
        alias: "TRANSACTION",
        component: TransactionsLogs,
      },
      {
        path: "/logs/service",
        name: "Service logs",
        log: true,
        alias: privileges.SERVICESLOG,
        component: ServiceLogs,
      },
    ],
  },
  {
    collapse: true,
    name: "Audit tables",
    icon: "icon-audit_tables",
    state: "audit",
    views: [
      {
        path: "/audit/user_table",
        name: "Users",
        audit: true,
        alias: privileges.USERSAUDIT,
        component: AuditMain,
      },
      {
        path: "/audit/shop_table",
        name: "Shops",
        audit: true,
        alias: privileges.SHOPS,
        component: ShopAudit,
      },
      {
        path: "/audit/terminal_table",
        name: "Terminals",
        audit: true,
        alias: privileges.TERMINALS,
        component: TerminalAudit,
      },
      {
        path: "/audit/transaction_table",
        name: "Transactions",
        audit: true,
        alias: privileges.TRANSACTIONAUDIT,
        component: TransactionAudit,
      },
      {
        path: "/audit/gateway_table",
        name: "Gateways",
        audit: true,
        alias: privileges.GATEWAYS,
        component: GatewayAudit,
      },
      {
        path: "/audit/currency_table",
        name: "Currencies",
        audit: true,
        alias: privileges.CURRENCIES,
        component: CurrencyAudit,
      },
      {
        path: "/audit/service_table",
        name: "Services",
        audit: true,
        alias: privileges.SERVICESETTINGS,
        component: ServiceAudit,
      },
    ],
  },
  {
    collapse: true,
    name: "Security",
    icon: "icon-security",
    state: "security",
    views: [
      {
        path: "/blacklist",
        name: "BlackList rules",
        alias: privileges.BLACKLISTS,
        component: BlackList,
      },
      {
        path: "/globalblacklist",
        name: "Global BlackList",
        alias: privileges.GLOBALBLACKLISTS,
        component: GlobalBlackList,
      },
      {
        path: "/merchantsblacklist",
        name: "Merchants BlackList",
        alias: privileges.BLACKLISTS,
        component: MerchantsBlackList,
      },
      {
        path: "/roles",
        name: "Roles / Privileges",
        alias: privileges.ROLES,
        component: RolesPrivileges,
      },
    ],
  },
  {
    path: "/sendtransaction",
    name: "Test Form",
    icon: "icon-test_form",
    form: true,
    alias: "TRANSACTIONTEST",
    component: NewForm,
  },
  { redirect: true, path: "/", to: "/dashboard", name: "Dashboard" },
];

const adminNonNav = [
  {
    name: "Admin detailed",
    path: "/about/admin/:id",
    alias: privileges.ADMINDETAIL,
    component: AdminDetail,
  },
  {
    name: "Group detailed",
    path: "/about/group/:id",
    alias: privileges.GROUPDETAIL,
    component: GroupDetail,
  },
  {
    name: "Partner detailed",
    path: "/about/partner/:id",
    alias: privileges.PARTNERDETAIL,
    component: PartnerDetail,
  },
  {
    name: "Merchant detailed",
    path: "/about/merchant/:id",
    alias: privileges.MERCHANTDETAIL,
    component: MerchantDetail,
  },
  {
    name: "Gateway detailed",
    path: "/about/gateway/:id",
    alias: privileges.GATEWAYDETAIL,
    component: GatewayDetail,
  },
  {
    name: "Rate detailed",
    path: "/about/rates/:id",
    alias: privileges.RATEDETAIL,
    component: RateDetail,
  },
  {
    name: "about",
    path: "/about/rate/template/:id",
    alias: privileges.RATEDETAIL,
    component: RateTemplateDetail,
  },
  {
    name: "about",
    path: "/about/rate/:rateid/revision/:id",
    alias: privileges.RATEDETAIL,
    component: RateRevisionDetail,
  },
  {
    name: "Role detailed",
    path: "/about/role/:id",
    alias: privileges.ROLESDETAIL,
    component: RolesDetail,
  },
  {
    name: "Terminal detailed",
    path: "/about/:shopId/terminal/:id",
    alias: privileges.SHOPTERMINALDETAIL,
    component: TerminalDetail,
  },
  {
    name: "Terminal detailed",
    path: "/about/terminal/:id",
    alias: privileges.SHOPTERMINALDETAIL,
    component: TerminalDetail,
  },
  {
    name: "Shop detailed",
    path: "/about/shop/:id",
    alias: privileges.SHOPDETAIL,
    component: ShopDetail,
  },
  {
    name: "Blacklist detailed",
    path: "/about/blacklist/:id",
    alias: privileges.BLACKLISTDETAIL,
    component: BlackListDetail,
  },
  {
    name: "Template detailed",
    path: "/about/transactiontemplate/:id",
    alias: privileges.TRANSACTIONTEMPLATES,
    component: TransactionTemplateDetail,
  },
  {
    name: "Processing detailed",
    path: "/about/processing/:id",
    alias: privileges.TRANSACTIONSHISTORY,
    component: HistoryStepDetail,
  },
  {
    name: "Step detailed",
    path: "/about/steps/:id",
    alias: privileges.TRANSACTIONTEMPLATES,
    component: HistoryParamsDetail,
  },
  {
    name: "useraccount",
    path: "/useraccount",
    alias: privileges.USERPROFILE,
    component: UserAccount,
  },
  {
    name: "Order detailed",
    path: "/about/order/:id",
    report: true,
    alias: privileges.ORDERDETAIL,
    component: OrderDetail,
  },
  {
    name: "Interim statement",
    execute: true,
    path: "/interim/statement",
    alias: privileges.STATEMENTS,
    component: StatementCreator,
  },
  {
    name: "Payable statement",
    execute: true,
    path: "/payable/statement",
    alias: privileges.STATEMENTS,
    component: Merge,
  },
  {
    name: "Statement detailed",
    path: "/about/statement/:id",
    alias: privileges.STATEMENTDETAIL,
    component: StatementDetail,
  },
  {
    name: "Chargeback detailed",
    path: "/about/chargeback/:id",
    alias: privileges.CHARGEBACKS,
    component: ChargebackDetail,
  },
  {
    name: "Mismatch detailed",
    path: "/about/mismatch/:id",
    alias: privileges.CHARGEBACKS,
    component: MismatchDetail,
  },
  {
    name: "Merchant audit detailed",
    path: "/about/audit/merchant/:id",
    audit: true,
    alias: "MERCHANTS",
    component: MerchantsAuditDetail,
  },
  {
    name: "Group audit detailed",
    path: "/about/audit/group/:id",
    audit: true,
    alias: "GROUPS",
    component: GroupsAuditDetail,
  },
  {
    name: "Partners audit detailed",
    path: "/about/audit/partner/:id",
    audit: true,
    alias: "PARTNERS",
    component: PartnersAuditDetail,
  },
  {
    name: "Logins audit detailed",
    path: "/about/audit/login/:id",
    audit: true,
    alias: "LOGINS",
    component: LoginAuditDetail,
  },
];

const groupDashboard = [
  {
    path: "/groupdashboard",
    name: "Dashboard",
    icon: "icon-dashboard",
    report: true,
    alias: privileges.GROUPDASHBOARD,
    component: GroupDashboard,
  },
  {
    path: "/merchants",
    name: "Merchants",
    icon: "icon-users",
    alias: privileges.MERCHANTS,
    component: Merchant,
  },
  {
    path: "/shops",
    name: "Shops",
    icon: "icon-shops",
    alias: privileges.GROUPSHOP,
    component: Shop,
  },
  {
    path: "/orders",
    name: "Orders",
    report: true,
    alias: privileges.ORDERS,
    icon: "pe-7s-cart",
    component: Orders,
  },
  {
    path: "/statements",
    name: "Statements",
    alias: privileges.STATEMENTS,
    icon: "pe-7s-note2",
    component: Statement,
  },
  {
    path: "/report/daily",
    name: "Daily Report",
    report: true,
    alias: privileges.DAILYREPORT,
    component: DailyReport,
  },
  {
    path: "/report/basic",
    name: "Basic Report",
    report: true,
    alias: privileges.BASICREPORT,
    component: ShopTotals,
  },
  {
    path: "/report/error",
    name: "Error Report",
    report: true,
    alias: privileges.TRANSACTIONERRORS,
    component: ErrorReport,
  },
  {
    path: "/report/geographic",
    name: "Geographic Report",
    report: true,
    alias: "TRANSACTIONGEO",
    component: GeographicReport,
  },
  {
    path: "/logs",
    name: "Transaction logs",
    icon: "icon-logs",
    log: true,
    alias: privileges.TRANSACTION,
    component: TransactionsLogs,
  },

  { redirect: true, path: "/", to: "/groupdashboard", name: "Dashboard" },
];

const groupNonNav = [
  {
    name: "about",
    path: "/about/merchant/:id",
    alias: privileges.MERCHANTDETAIL,
    component: MerchantDetail,
  },
  {
    name: "about",
    path: "/about/:shopId/terminal/:id",
    alias: privileges.SHOPTERMINALDETAIL,
    component: TerminalDetail,
  },
  {
    name: "about",
    path: "/about/shop/:id",
    alias: privileges.SHOPDETAIL,
    component: ShopDetail,
  },
  {
    name: "useraccount",
    path: "/useraccount",
    alias: privileges.USERPROFILE,
    component: UserAccount,
  },
  {
    name: "about",
    path: "/about/order/:id",
    report: true,
    alias: privileges.ORDERDETAIL,
    component: OrderDetail,
  },
  {
    name: "statement",
    path: "/about/statement/:id",
    alias: privileges.STATEMENTDETAIL,
    component: StatementDetail,
  },
];

const merchantDashboard = [
  {
    path: "/merchantdashboard",
    name: "Dashboard",
    icon: "icon-dashboard",
    report: true,
    alias: privileges.MERCHANTDASHBOARD,
    component: MerchantDashboard,
  },
  {
    path: "/shops",
    name: "Shops",
    icon: "icon-shops",
    alias: privileges.MERCHANTSHOP,
    component: Shop,
  },
  {
    path: "/orders",
    name: "Orders",
    report: true,
    alias: privileges.ORDERS,
    icon: "pe-7s-cart",
    component: Orders,
  },
  // {
  //   path: "/accounts",
  //   name: "Accounts",
  //   alias: privileges.MERCHANTACCOUNT,
  //   icon: "pe-7s-id",
  //   component: Account
  // },
  {
    path: "/statements",
    name: "Statements",
    alias: privileges.STATEMENTS,
    icon: "pe-7s-note2",
    component: Statement,
  },
  {
    path: "/report/daily",
    name: "Daily Report",
    report: true,
    alias: privileges.DAILYREPORT,
    component: DailyReport,
  },
  {
    path: "/report/basic",
    name: "Basic Report",
    report: true,
    alias: privileges.BASICREPORT,
    component: ShopTotals,
  },

  {
    path: "/report/error",
    name: "Error Report",
    report: true,
    alias: privileges.TRANSACTIONERRORS,
    component: ErrorReport,
  },
  {
    path: "/report/geographic",
    name: "Geographic Report",
    report: true,
    alias: "TRANSACTIONGEO",
    component: GeographicReport,
  },
  {
    collapse: true,
    name: "Security",
    icon: "icon-security",
    state: "security",
    views: [
      {
        path: "/merchantsblacklist",
        name: "Merchants BlackList",
        alias: privileges.MERCHANTBLACKLIST,
        component: MerchantsBlackList,
      },
    ],
  },
  {
    path: "/sendtransaction",
    name: "Test Form",
    form: true,
    alias: "TRANSACTIONTEST",
    icon: "icon-test_form",
    component: TransactionForm,
  },
  {
    path: "/logs",
    name: "Transaction logs",
    icon: "icon-logs",
    log: true,
    alias: privileges.TRANSACTION,
    component: TransactionsLogs,
  },
  { redirect: true, path: "/", to: "/merchantdashboard", name: "Dashboard" },
];

const merchantNonNav = [
  {
    name: "about",
    path: "/about/shop/:id",
    alias: privileges.SHOPDETAIL,
    component: ShopDetail,
  },
  {
    name: "about",
    path: "/about/:shopId/terminal/:id",
    alias: privileges.SHOPTERMINALDETAIL,
    component: TerminalDetail,
  },
  {
    name: "useraccount",
    path: "/useraccount",
    alias: privileges.USERPROFILE,
    component: UserAccount,
  },
  {
    name: "about",
    path: "/about/blacklist/:id",
    alias: privileges.BLACKLISTDETAIL,
    component: BlackListDetail,
  },
  {
    name: "about",
    path: "/about/order/:id",
    report: true,
    alias: privileges.ORDERDETAIL,
    component: OrderDetail,
  },
  {
    name: "statement",
    path: "/about/statement/:id",
    alias: privileges.STATEMENTDETAIL,
    component: StatementDetail,
  },
];

const partnerDashboard = [
  {
    path: "/partnerdashboard",
    name: "Dashboard",
    icon: "icon-dashboard",
    report: true,
    alias: privileges.PARTNERDASHBOARD,
    component: PartnerDashboard,
  },
  {
    path: "/groups",
    name: "Groups",
    icon: "icon-users",
    alias: privileges.GROUPS,
    component: Group,
  },
  {
    path: "/merchants",
    name: "Merchants",
    icon: "icon-users",
    alias: privileges.MERCHANTS,
    component: Merchant,
  },
  {
    path: "/shops",
    name: "Shops",
    icon: "icon-shops",
    alias: privileges.PARTNERSHOP,
    component: Shop,
  },
  {
    path: "/orders",
    name: "Orders",
    report: true,
    alias: privileges.ORDERS,
    icon: "pe-7s-cart",
    component: Orders,
  },
  {
    path: "/statements",
    name: "Statements",
    alias: privileges.STATEMENTS,
    icon: "pe-7s-note2",
    component: Statement,
  },
  {
    path: "/report/daily",
    name: "Daily Report",
    report: true,
    alias: privileges.DAILYREPORT,
    component: DailyReport,
  },
  {
    path: "/report/basic",
    name: "Basic Report",
    report: true,
    alias: privileges.BASICREPORT,
    component: ShopTotals,
  },

  {
    path: "/report/error",
    name: "Error Report",
    report: true,
    alias: privileges.TRANSACTIONERRORS,
    component: ErrorReport,
  },
  {
    path: "/report/geographic",
    name: "Geographic Report",
    report: true,
    alias: "TRANSACTIONGEO",
    component: GeographicReport,
  },
  {
    path: "/logs",
    name: "Transaction logs",
    icon: "icon-logs",
    log: true,
    alias: privileges.TRANSACTION,
    component: TransactionsLogs,
  },

  { redirect: true, path: "/", to: "/partnerdashboard", name: "Dashboard" },
];

const partnerNonNav = [
  {
    name: "about",
    path: "/about/group/:id",
    alias: privileges.GROUPDETAIL,
    component: GroupDetail,
  },
  {
    name: "about",
    path: "/about/:shopId/terminal/:id",
    alias: privileges.SHOPTERMINALDETAIL,
    component: TerminalDetail,
  },
  {
    name: "about",
    path: "/about/merchant/:id",
    alias: privileges.MERCHANTDETAIL,
    component: MerchantDetail,
  },
  {
    name: "about",
    path: "/about/shop/:id",
    alias: privileges.SHOPDETAIL,
    component: ShopDetail,
  },
  {
    name: "useraccount",
    path: "/useraccount",
    alias: privileges.USERPROFILE,
    component: UserAccount,
  },
  {
    name: "about",
    path: "/about/order/:id",
    report: true,
    alias: privileges.ORDERDETAIL,
    component: OrderDetail,
  },
  {
    name: "statement",
    path: "/about/statement/:id",
    alias: privileges.STATEMENTDETAIL,
    component: StatementDetail,
  },
];

const role = getRoles()[0].role;
const isAdmin = role === "admin";
const isMerchant = role === "merchant";
const isGroup = role === "group";
const isPartner = role === "partner";

let adminDashboardRoutes = [];
let adminNonNavRoutes = [];

if (isAdmin) {
  adminDashboardRoutes = adminDashboard.map((route) => isRoute(route));
  adminNonNavRoutes = adminNonNav.map((route) => isRoute(route));
} else if (isMerchant) {
  adminDashboardRoutes = merchantDashboard.map((route) => isRoute(route));
  adminNonNavRoutes = merchantNonNav.map((route) => isRoute(route));
} else if (isGroup) {
  adminDashboardRoutes = groupDashboard.map((route) => isRoute(route));
  adminNonNavRoutes = groupNonNav.map((route) => isRoute(route));
} else if (isPartner) {
  adminDashboardRoutes = partnerDashboard.map((route) => isRoute(route));
  adminNonNavRoutes = partnerNonNav.map((route) => isRoute(route));
} else {
  adminDashboardRoutes = [];
  adminNonNavRoutes = [];
}

adminDashboardRoutes = adminDashboardRoutes.filter((route) => route);
adminNonNavRoutes = adminNonNavRoutes.filter((route) => route);

export { adminDashboardRoutes };
export { adminNonNavRoutes };

export const merchantDashboardRoutes = [];
export const merchantNonNavRoutes = [];

export const userDashboardRoutes = [];
export const userNonNavRoutes = [];
