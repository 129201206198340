import * as types from "../constants/actionTypes";

const initialState = {
  merchantHistory: [],
  groupHistory: [],
  partnerHistory: [],
  loginHistory: [],
  terminalHistory: [],
  gatewayHistory: [],
  shopHistory: [],
  currencyHistory: [],
  transactionOverviewHistory: [],
  transactionDataHistory: [],
  transactionProcessingHistory: [],
  error: false,
  loading: false,
  count: 0,

};

export default function reasons(state = initialState, action) {
  switch (action.type) {
    case types.GET_MERCHANT_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_MERCHANT_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        merchantHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_MERCHANT_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_GROUP_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_GROUP_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        groupHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_GROUP_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_PARTNER_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_PARTNER_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        partnerHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_PARTNER_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_LOGIN_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_LOGIN_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        loginHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_LOGIN_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    
    case types.GET_TERMINAL_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_TERMINAL_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        terminalHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_TERMINAL_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_SHOP_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_SHOP_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        shopHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_SHOP_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_GATEWAY_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_GATEWAY_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        gatewayHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_GATEWAY_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_CURRENCY_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_CURRENCY_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        currencyHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_CURRENCY_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_TRANSACTION_PROCESSING_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        transactionProcessingHistory: action.data.data,
        transactionProcessingHistoryCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

      
    case types.GET_TRANSACTION_DATA_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_TRANSACTION_DATA_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        transactionDataHistory: action.data.data,
        transactionDataHistoryCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_TRANSACTION_DATA_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        transactionOverviewHistory: action.data.data,
        transactionOverviewHistoryCount: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
      
    case types.GET_SERVICE_CHANGE_HISTORY:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.GET_SERVICE_CHANGE_HISTORY_SUCCEED:
      return {
        ...state,
        serviceHistory: action.data.data,
        count: action.data.count,
        loading: false,
        error: false
      };
    case types.GET_SERVICE_CHANGE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}