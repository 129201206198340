import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RateEditor from "./Editor";
import { getRates } from "../../services/paymentBackendAPI/management/rates";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import { getAllRates, deleteRateAction } from "../../actions/rates";
import { searchInRates, reset } from "../../actions/search";

const columns = [
  {
    path: "name",
    label: "Name",
    key: "name",
    content: (rate) => (
      <Link className="link" to={`/about/rates/${rate.guid}`}>
        {rate.name}
      </Link>
    ),
    textContent: (rate) => rate.name,
  },
  {
    key: "gateway_name",
    path: "gateway_name",
    label: "Gateway",
    content: (rate) => (
      <Link className="link" to={`/about/gateway/${rate.gateway_guid}`}>
        {rate.gateway_name}
      </Link>
    ),
    textContent: (rate) => rate.gateway_name,
  },
  ability.can("EXECUTE", "RATES") && {
    key: "edit",
    content: (rate) => (
      <Modal
        header="Edit rate"
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        content={RateEditor}
        contentProps={{ rate_guid: rate.guid }}
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "RATES") && {
    key: "deleteRate",
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.rates.ratesList,
    count: state.rates.count,
    table: state.store.table,
    loading: state.rates.ratesLoading,
    searchData: state.search.ratesSearch,
    isSearch: state.search.isSearch,
    keyPath: "guid",
    name: "rates",
    columns,
    columnsComponent: createColComponentForTable(columns),
    exportFunction: getRates,
  };
};

export default connect(mapStateToProps, {
  get: getAllRates,
  delete: deleteRateAction,
  search: searchInRates,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
