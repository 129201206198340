import React, { Component } from "react";
import { connect } from "react-redux";
import { addRateTemplateAction } from "../../../actions/rates";
import { showModal } from "../../../actions/modal";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { Row, Col, Button, Form } from "react-bootstrap";
import Spinner from "components/UI/Spinner";
import { getAllCurrencies } from "actions/currencies";
import MultiSelect from "components/UI/MultiSelect";
import Content from "views/Content";
import Joi from "joi-browser";
import ReactLoading from "react-loading";

class RateTemplateCreator extends Component {
  state = {
    name: "",
    errors: {},
    transaction_statusesOptions: [],
    transaction_typesOptions: [],
    card_currenciesOptions: [],
    card_typesOptions: [],
    card_schemaOptions: [],
    card_regionOptions: [],
    isPlain: false,

    // currencies: []
  };

  schema = {
    name: Joi.string().required().label("Name"),
    card_region: Joi.array().min(1).required().label("card region"),
    transaction_statuses: Joi.array()
      .min(1)
      .required()
      .label("transaction statuses"),
    transaction_types: Joi.array().min(1).required().label("transaction types"),
    card_types: Joi.array().min(1).required().label("card types"),
    card_schema: Joi.array().min(1).required().label("card schema"),
    // card_currencies: Joi.array().min(1)
    //   .required()
    //   .label("card schema"),
    isplain: Joi.bool().required().label("is plain"),
  };

  card_region = [
    { name: "ANY", guid: "ANY" },
    { name: "EU", guid: "EU" },
    { name: "Other", guid: "Other" },
  ];

  transaction_statuses = [
    { name: "ANY", guid: "ANY" },
    { name: "3Dwaiting", guid: "3Dwaiting" },
    { name: "Failed", guid: "Failed" },
    { name: "Success", guid: "Success" },
    { name: "Pending", guid: "Pending" },
    { name: "Other", guid: "Other" },
  ];

  transaction_types = [
    { name: "ANY", guid: "ANY" },
    { name: "Representment", guid: "Representment" },
    { name: "Payment", guid: "Payment" },
    { name: "Chargeback", guid: "Chargeback" },
    { name: "Reverse", guid: "Reverse" },
    { name: "Retrieval", guid: "Retrieval" },
    { name: "Charge", guid: "Charge" },
    { name: "Refund", guid: "Refund" },
    { name: "Cancel", guid: "Cancel" },
    { name: "Prearbitration", guid: "Prearbitration" },
    { name: "Recurring", guid: "Recurring" },
    { name: "Capture", guid: "Capture" },
    { name: "Authorization", guid: "Authorization" },
    { name: "Other", guid: "Other" },
  ];

  card_type = [
    { name: "ANY", guid: "ANY" },
    { name: "credit", guid: "credit" },
    { name: "debit", guid: "debit" },
    { name: "premium", guid: "premium" },
    { name: "corporate", guid: "corporate" },
    { name: "Other", guid: "Other" },
  ];

  card_schema = [
    { name: "ANY", guid: "ANY" },
    { name: "Visa", guid: "Visa" },
    { name: "MC", guid: "MC" },
    { name: "Other", guid: "Other" },
  ];

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.getAllCurrencies();
    let currencies = this.props.currencies;
    currencies = currencies.map((currency) => ({
      guid: currency.code,
      name: currency.code,
    }));
    this.setState({ currencies, loading: false });
  }

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        name: this.state.name,
        transaction_statuses: this.state.transaction_statusesOptions.map(
          (value) => value.name
        ),
        card_region: this.state.card_regionOptions.map((value) => value.name),
        card_types: this.state.card_typesOptions.map((value) => value.name),
        card_schema: this.state.card_schemaOptions.map((value) => value.name),
        transaction_types: this.state.transaction_typesOptions.map(
          (value) => value.name
        ),
        isplain: this.state.isPlain,
      },
      this.schema,
      options
    );

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChangeName = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({ [input.name]: input.value, errors });
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  onSelect = (options, name) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({ name: name, value: options });
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];

    this.setState({
      [name + "Options"]: options,
      errors,
    });
  };

  onValueChange = (e) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: "isplain",
      value: e.target.value === "Plain" ? true : false,
    });
    if (errorMessage) errors.isplain = errorMessage;
    else delete errors.isplain;

    this.setState({
      isPlain: e.target.value === "Plain" ? true : false,
      errors,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let errors = this.validate();
    this.setState({ errors: { ...this.state.errors, ...errors } || {} });
    errors = { ...this.state.errors, ...errors };
    if (Object.keys(errors).length !== 0) return;
    else {
      this.setState({ isLoading: true });
      const data = {
        name: this.state.name,
        transaction_status: this.state.transaction_statusesOptions.map(
          (value) => value.name
        ),
        card_type: this.state.card_typesOptions.map((value) => value.name),
        card_schema: this.state.card_schemaOptions.map((value) => value.name),
        transaction_type: this.state.transaction_typesOptions.map(
          (value) => value.name
        ),
        card_region: this.state.card_regionOptions.map((value) => value.name),
        is_plain: this.state.isPlain,
      };
      try {
        await this.props.addRateTemplateAction(
          data,
          this.props.currentPage,
          this.props.pageSize,
          this.props.ratesSearch
        );
        swal({
          title: "Rate template is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        swal({
          title: parsedError.error,
          text: parsedError.message,
          icon: "error",
        });
      }
    }
  };

  render() {
    const { errors } = this.state;
    if (this.state.loading)
      return (
        <Content>
          <Spinner />
        </Content>
      );
    else
      return (
        <Form>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Name*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("name")}>
                <Form.Control
                  placeholder="Enter name"
                  name="name"
                  value={this.state.name}
                  onChange={(e) => this.handleChangeName(e)}
                />
                {errors.name && (
                  <span className="validate-error">{errors.name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Transaction statuses*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group
                validationState={this.formValidation("transaction_statuses")}
              >
                <MultiSelect
                  name="transaction_statuses"
                  options={this.transaction_statuses}
                  value={this.state.transaction_statusesOptions}
                  multi={true}
                  onSelect={(e) => this.onSelect(e, "transaction_statuses")}
                />
                {errors.transaction_statuses && (
                  <span className="validate-error">
                    {errors.transaction_statuses}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Transaction types*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group
                validationState={this.formValidation("transaction_types")}
              >
                <MultiSelect
                  name="transaction_types"
                  options={this.transaction_types}
                  value={this.state.transaction_typesOptions}
                  multi={true}
                  onSelect={(e) => this.onSelect(e, "transaction_types")}
                />
                {errors.transaction_types && (
                  <span className="validate-error">
                    {errors.transaction_types}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Card types*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("card_types")}>
                <MultiSelect
                  name="card_types"
                  options={this.card_type}
                  value={this.state.card_typesOptions}
                  multi={true}
                  onSelect={(e) => this.onSelect(e, "card_types")}
                />
                {errors.card_types && (
                  <span className="validate-error">{errors.card_types}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Card schema*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("card_schema")}>
                <MultiSelect
                  name="card_schema"
                  options={this.card_schema}
                  value={this.state.card_schemaOptions}
                  multi={true}
                  onSelect={(e) => this.onSelect(e, "card_schema")}
                />
                {errors.card_schema && (
                  <span className="validate-error">{errors.card_schema}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Card region*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("card_region")}>
                <MultiSelect
                  name="card_region"
                  options={this.card_region}
                  value={this.state.card_regionOptions}
                  multi={true}
                  onSelect={(e) => this.onSelect(e, "card_region")}
                />
                {errors.card_region && (
                  <span className="validate-error">{errors.card_region}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Is plain*</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("isPlain")}>
                <input
                  type="radio"
                  name="isPlain"
                  value="Plain"
                  style={{ margin: "0 5px 0 5px" }}
                  onChange={this.onValueChange}
                />
                <label>Flat</label>
                <input
                  type="radio"
                  name="isPlain"
                  value="Percent"
                  style={{ margin: "0 5px 0 5px" }}
                  onChange={this.onValueChange}
                />
                <label>Percent</label>
                {errors.isplain && (
                  <span className="validate-error">{errors.isplain}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button className="btn btn-fill" onClick={this.handleSubmit}>
                Add
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currencies.currenciesList,
    currentPage: state.rates.currentPage,
    pageSize: state.rates.pageSize,
    ratesSearch: state.search.ratesSearch,
  };
};

export default connect(mapStateToProps, {
  showModal,
  addRateTemplateAction,
  getAllCurrencies,
})(RateTemplateCreator);

RateTemplateCreator.propTypes = {
  addRateTemplateAction: PropTypes.func,
  currencies: PropTypes.array,
  currentPage: PropTypes.number,
  getAllCurrencies: PropTypes.func,
  pageSize: PropTypes.number,
  ratesSearch: PropTypes.object,
  showModal: PropTypes.func,
};
