import * as types from "../constants/actionTypes";
import { getService, getServices, updateService, closeWorkingDayDecta } from "../services/paymentBackendAPI/management/services";

export const getAllServices = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SERVICES
    });
    try {
      const res = await getServices();
      return dispatch({
        type: types.GET_SERVICES_SUCCEED,
        data: res.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_SERVICES_FAILED
      });
    }
  };
};

export const getServiceAction = (name) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SERVICE
    });
    try {
      const res = await getService(name);
      return dispatch({
        type: types.GET_SERVICE_SUCCEED,
        data: res.data.data[0],
      });
    } catch (error) {
      return dispatch({
        type: types.GET_SERVICE_FAILED
      });
    }
  };
};

export const editServiceAction = (data) => {
  return async (dispatch) => {
    const response = await updateService(data);
    dispatch({
      type: types.EDIT_SERVICE,
      service: response.data
    });
  };
};

export const closeWorkingDayDectaAction = (data) => {
  return async (dispatch) => {
    const response = await closeWorkingDayDecta(data);
    dispatch({
      type: types.CLOSE_WORKING_DAY,
      service: response.data
    });
  };
};