import { backendReports } from "../backendPlatform";

/**
 * * Get amount groups
 * * Inputs (partner_guid)
 * * Outputs (count)
 * */
export const getAmountGroups = (partner_guid) => {
  return backendReports.get("/amount_of_groups", { params: { partner_guid } });
};

/**
 * * Get amount merchants
 * * Inputs (partner_guid, group_guid)
 * * Outputs (count)
 * */
export const getAmountMerchants = (data) => {
  return backendReports.get("/amount_of_merchants", { params: data });
};

/**
 * * Get amount partners
 * * Inputs (partner_guid, group_guid, merchant_guid)
 * * Outputs (count)
 * */
export const getAmountPartners = (partner_guid, group_guid, merchant_guid) => {
  return backendReports.get("/amount_of_partners", {
    params: { partner_guid, group_guid, merchant_guid },
  });
};

/**
 * * Get amount shops
 * * Outputs (count)
 * */
export const getAmountShops = () => {
  return backendReports.get("/amount_of_shops");
};

/**
 * * Get amount new client
 * * Inputs (from_date, to_date, days)
 * * Outputs (count)
 * */
export const getNewClients = (days, from_date, to_date) => {
  return backendReports.get("/new_clients", {
    params: { from_date, to_date, days },
  });
};

/**
 * * Get top groups
 * * Inputs (from_date, to_date, days)
 * * Outputs ([{guid, name, count}...])
 * */
export const getTopGroups = (days, count, partner_guid, from_date, to_date) => {
  return backendReports.get("/top_groups/daily", {
    params: { partner_guid, from_date, to_date, days, count },
  });
};

/**
 * * Get top merchants
 * * Inputs (partner_guid, group_guid, from_date, to_date, days, count )
 * * Outputs ([{guid, name, count}...])
 * */
export const getTopMerchants = (
  days,
  count,
  partner_guid,
  group_guid,
  from_date,
  to_date
) => {
  return backendReports.get("/top_merchants/daily", {
    params: { partner_guid, group_guid, from_date, to_date, days, count },
  });
};

/**
 * * Get top merchants
 * * Inputs (from_date, to_date, days, count)
 * * Outputs ([{guid, name, count}...])
 * */
export const getTopPartners = (days, count, from_date, to_date) => {
  return backendReports.get("/top_partners/daily", {
    params: { from_date, to_date, days, count },
  });
};

/**
 * * Get top shops
 * * Inputs (partner_guid, group_guid, merchant_guid, from_date, to_date, days, count)
 * * Outputs ([{guid, name, count}...])
 * */
export const getTopShops = (
  days,
  count,
  partner_guid,
  group_guid,
  merchant_guid,
  from_date,
  to_date
) => {
  return backendReports.get("/top_shops/daily", {
    params: {
      partner_guid,
      group_guid,
      merchant_guid,
      from_date,
      to_date,
      days,
      count,
    },
  });
};
