import { backendManagement } from "../backendPlatform";

export const getRates = (args) => {
  return backendManagement.get("/rates_v1", { params: { ...args } });
};

export const createRate = data => {
  return backendManagement.post("/rates_v1", data);
};

export const getRate = ( id ) => {
  return backendManagement.get(`/rates_v1/${id}`);
};

export const deleteRate = ({ guid, reason } ) => {
  return backendManagement.post("/rates_v1", { guid, delete: true, reason });
};

export const getOneTimeCharges = (id,  page, items) => {
  return backendManagement.get(`/charges/one_time/${id}`, { params: { items, page } });
};

export const getPeriodicCharges = (id,  page, items) => {
  return backendManagement.get(`/charges/periodic/${id}`, { params: { items, page } });
};

export const getConditionalCharges = (id,  page, items) => {
  return backendManagement.get(`/charges/conditional/${id}`, { params: { items, page } });
};

export const createOneTimeCharge = data => {
  return backendManagement.post("/charges/one_time", data);
};

export const createPeriodicCharge = data => {
  return backendManagement.post("/charges/periodic", data);
};

export const createConditionalCharge = data => {
  return backendManagement.post("/charges/conditional", data);
};

//------------------templates------------------//

export const getRateTemplates = (args) => {
  return backendManagement.get("/rates_v1/templates", { params: { ...args } });
};

export const createRateTemplate = data => {
  return backendManagement.post("/rates_v1/templates", data);
};

export const getRateTemplate = ( id ) => {
  return backendManagement.get(`/rates_v1/templates/${id}`);
};

export const deleteRateTemplate = ({ guid, reason } ) => {
  return backendManagement.post("/rates_v1/templates", { guid, delete: true, reason });
};

export const getRateTemplateFees = (guid, args) => {
  return backendManagement.get(`/rates_v1/templates/${guid}/fees`, { params: { ...args } } );
};

export const createRateTemplateFees = ( guid, data ) => {
  return backendManagement.post(`/rates_v1/templates/${guid}/fees`, data);
};

export const getRateRevisions = (guid, args) => {
  return backendManagement.get(`/rates_v1/${guid}/revisions`, { params: { ...args } } );
};

export const createRateRevision = ( guid, data ) => {
  return backendManagement.post(`/rates_v1/${guid}/revisions`, data);
};

export const getRateRevisionFees = (guid, revision_guid, args) => {
  return backendManagement.get(`/rates_v1/${guid}/revisions/${revision_guid}/fees`, { params: { ...args } } );
};

export const EditRateRevisionFees = (guid, revision_guid, data) => {
  return backendManagement.post(`/rates_v1/${guid}/revisions/${revision_guid}/fees`, data);
};