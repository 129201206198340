import * as types from "../constants/actionTypes";
import {
  createGateway,
  getGateways,
  getGateway,
  updateGateway,
  deleteGateway,
  getGatewayProps,
  addGatewayProps,
  addCurrenciesForGateway,
  deleteGatewayCurrency,
  deleteGatewayProps
} from "../services/paymentBackendAPI/management/gateways";
import { loadTable } from "./store";

export const getAllGateways = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GATEWAYS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getGateways(args);
      return dispatch({
        type: types.GET_GATEWAYS_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    } catch (error) {
      return dispatch({
        type: types.GET_GATEWAYS_FAILED
      });
    }
  };
};

export const addGatewayAction = (data, page, items, search) => {
  return async (dispatch) => {
    await createGateway(data);
    return dispatch(getAllGateways({ page, items, ...search }));
  };
};

export const getGatewayAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type:
        types.GET_GATEWAY
    });
    try {
      const response = await getGateway(data);
      return dispatch({
        type: types.GET_GATEWAY_SUCCEED,
        gateway: response.data
      });
    } catch (error) {
      dispatch({
        type:
          types.GET_GATEWAY_FAILED
      });
    }
  };
};

export const deleteGatewayAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteGateway(data);
    return dispatch(getAllGateways({ page, items, ...search }));
  };
};

export const editGatewayAction = (data) => {
  return async (dispatch) => {
    const response = await updateGateway(data);
    dispatch({
      type: types.EDIT_GATEWAY,
      gateway: response.data
    });
  };
};

export const getGatewayPropsAction = (guid) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GATEWAY_PROPS
    });
    try {
      const response = await getGatewayProps(guid);
      return dispatch({
        type: types.GET_GATEWAY_PROPS_SUCCEED,
        data: response.data.data,
        count: response.data.count
      });
    } catch (error) {
      return dispatch({
        type: types.GET_GATEWAY_PROPS_FAILED
      });
    }
  };
};

export const deleteGatewayPropsAction = (guid, data) => {
  return async (dispatch) => {
    await deleteGatewayProps(guid, data);
    return dispatch(getGatewayPropsAction(guid));
  };
};

export const addGatewayPropsAction = (guid, data) => {
  return async (dispatch) => {
    await addGatewayProps(guid, data);
    return dispatch(getGatewayPropsAction(guid));
  };
};

export const addCurrenciesForGatewayAction = (guid, data, reason) => {
  return async (dispatch) => {
    await addCurrenciesForGateway(guid, data, reason);
    return dispatch(getGatewayAction(guid));
  };
};

export const deleteGatewayCurrencyAction = (guid, data, reason) => {
  return async (dispatch) => {
    await deleteGatewayCurrency(guid, data, reason);
    return dispatch(getGatewayAction(guid));
  };
};