import React from "react";
import MerchantMain from "components/Merchant/Main";
import Content from "../Content";
import Modal from "components/UI/Modal";
// import Can from "components/Can";
import MerchantCreator from "components/Merchant/Creator";
import ability from "config/ability";

const Merchant = () => {
  return (
    <Content>
      <MerchantMain
        modalComponent={
          // <Can do="EXECUTE" on="USERMERCHANT">
          <Modal
            allowed={ability.can("EXECUTE", "USERMERCHANT")}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create merchant
              </button>
            }
            content={MerchantCreator}
            header="Create merchant"
            dialogClassName="modal-creator"
          />

          // </Can>
        }
      />
    </Content>
  );
};

export default Merchant;
