import swal from "sweetalert";
import errorImg from "assets/img/icons/error.svg";
import warningImg from "assets/img/icons/warning.svg";

const Alert = (props) => {
  switch (props.type) {
    case "error":
      swal({
        title: "ERROR",
        text: props.message,
        icon: errorImg,
        className: "error",
      });
      break;
    case "warning":
      swal({
        title: "SOMEETHING WENT WRONG",
        text: props.message,
        icon: warningImg,
        className: "warning",
      });
      break;
    case "success":
      swal({
        title: "SUCCESS",
        text: props.message,
        icon: "success",
        className: "success",
      });
      break;
    default:
      break;
  }
};

export default Alert;
