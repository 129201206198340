import * as types from "../constants/actionTypes";
import {
  getLoginChangeHistory,
  getMerchantChangeHistory,
  getGroupChangeHistory,
  getPartnerChangeHistory,
  getTerminalChangeHistory,
  getShopChangeHistory,
  getGatewayChangeHistory,
  getCurrencyChangeHistory,
  getAuditTransactionProcessingChangeHistory,
  getAuditTransactionDataChangeHistory,
  getAuditTransactionOverviewsChangeHistory,
  getAuditServiceChangeHistory
} from "../services/paymentBackendAPI/audits/reasons";

export const getLoginChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_LOGIN_CHANGE_HISTORY
    });
    try {
      const response = await getLoginChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_LOGIN_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_LOGIN_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getMerchantChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANT_CHANGE_HISTORY
    });
    try {
      const response = await getMerchantChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_MERCHANT_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_MERCHANT_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getGroupChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GROUP_CHANGE_HISTORY
    });
    try {
      const response = await getGroupChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_GROUP_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_GROUP_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getPartnerChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PARTNER_CHANGE_HISTORY
    });
    try {
      const response = await getPartnerChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_PARTNER_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_PARTNER_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getTerminalChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TERMINAL_CHANGE_HISTORY
    });
    try {
      const response = await getTerminalChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_TERMINAL_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TERMINAL_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getShopChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOP_CHANGE_HISTORY
    });
    try {
      const response = await getShopChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_SHOP_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_SHOP_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getGatewayChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GATEWAY_CHANGE_HISTORY
    });
    try {
      const response = await getGatewayChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_GATEWAY_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_GATEWAY_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getCurrencyChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CURRENCY_CHANGE_HISTORY
    });
    try {
      const response = await getCurrencyChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_CURRENCY_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_CURRENCY_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getTransactionProcessingChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_PROCESSING_CHANGE_HISTORY
    });
    try {
      const response = await getAuditTransactionProcessingChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getTransactionDataChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_DATA_CHANGE_HISTORY
    });
    try {
      const response = await getAuditTransactionDataChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_TRANSACTION_DATA_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_DATA_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getTransactionOverviewsChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY
    });
    try {
      const response = await getAuditTransactionOverviewsChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_FAILED
      });
    }
  };
};

export const getServiceChangeHistoryAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SERVICE_CHANGE_HISTORY
    });
    try {
      const response = await getAuditServiceChangeHistory(id, page, items);
      return dispatch({
        type: types.GET_SERVICE_CHANGE_HISTORY_SUCCEED,
        data: response.data,
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_SERVICE_CHANGE_HISTORY_FAILED
      });
    }
  };
};