import * as types from "../constants/actionTypes";
import { getAdditionalFees, createOrUpdateAdditionalFee, deleteAdditionalFee } from "../services/paymentBackendAPI/management/additionalFees";
import { loadTable } from "./store";

export const getAllAdditionalFees = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ADDITIONAL_FEES
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const res = await getAdditionalFees(args);
      return dispatch({
        type: types.GET_ADDITIONAL_FEES_SUCCEED,
        data: res.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    } catch (error) {
      return dispatch({
        type: types.GET_ADDITIONAL_FEES_FAILED
      });
    }
  };
};

export const addAdditionalFeeAction = (data, page, items, search) => {
  return async (dispatch) => {
    await createOrUpdateAdditionalFee(data);
    return dispatch(getAllAdditionalFees({ page, items, ...search }));
  };
};

export const editAdditionalFeeAction = (data) => {
  return async (dispatch) => {
    const response = await createOrUpdateAdditionalFee(data);
    dispatch({
      type: types.EDIT_ADDITIONAL_FEE,
      fee: response.data
    });
  };
};

export const deleteAdditionalFeeAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteAdditionalFee(data);
    return dispatch(getAllAdditionalFees({ page, items, ...search }));
  };
};

