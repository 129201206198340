import { getTransactionProcessingStepsAction } from "actions/transactions";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination/index";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table/index";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Content from "views/Content";
import {
  getTransactionDataChangeHistoryAction,
  getTransactionOverviewsChangeHistoryAction,
  getTransactionProcessingChangeHistoryAction,
} from "../../../actions/reasons";
import ability from "../../../config/ability";
import HistoryParamsDetail from "./HistoryParamsDetail";

class HistoryStepDetail extends Component {
  state = {
    transactionHistory: {},
    count: "",
    currentPage: 1,
    pageSize: 10,
    transactionProcessingPageSize: 10,
    transactionOverviewPageSize: 10,
    transactionDataPageSize: 10,

    transactionProcessingCurrentPage: 1,
    transactionOverviewCurrentPage: 1,
    transactionDataCurrentPage: 1,
  };

  columnsSteps = [
    { path: "guid", label: "ID", key: "guid" },
    { path: "name", label: "Name", key: "name" },
    { path: "type", label: "Type", key: "step_type" },
    {
      label: "Created at",
      key: "created_at",
      content: (step) =>
        moment(step.created_at).utcOffset(0).format("DD.MM.YYYY HH:mm:ss.SSS"),
      align: "center",
    },
    {
      label: "Updated at",
      key: "updated_at",
      content: (step) =>
        moment(step.updated_at).utcOffset(0).format("DD.MM.YYYY HH:mm:ss.SSS"),
      align: "center",
    },
    {
      label: "Duration",
      key: "duration",
      content: (step) =>
        step.updated_at
          ? moment(moment(step.updated_at) - moment(step.created_at))
            .utcOffset(0)
            .format("mm:ss.SSS")
          : null,
      align: "center",
    },
    { path: "status", label: "Status", key: "status", align: "center" },
    {
      path: "parameters",
      label: "Parameters",
      key: "parameters",
      align: "center",
      content: (step) =>
        step.params.length !== 0 ? (
          <Modal
            content={HistoryParamsDetail}
            contentProps={{ params: step.params, step_guid: step.guid }}
            buttonName="Show"
            button={
              <button type="button" className="btn">
                Show
              </button>
            }
          />
        ) : null,
    },
  ];

  columnsTransactionDataHistory = [
    { path: "tracking_id", label: "Tracking ID", key: "tracking_id" },
    { path: "amount", label: "Amount", key: "amount_audit" },
    { path: "currency", label: "Currency", key: "currency_audit" },
    {
      path: "card_eu",
      label: "EU card",
      key: "card_eu_audit",
      content: (transaction) => (
        <i
          className={
            transaction.card_eu
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      align: "center",
    },
    { path: "card_type", label: "Card type", key: "card_type_audit" },
    { path: "card_schema", label: "Card schema", key: "card_schema_audit" },
    { path: "card_holder", label: "Holder", key: "card_holder_audit" },
    { path: "card_number", label: "Card number", key: "card_number_audit" },
    {
      label: "Expire date",
      key: "exp_month_year_audit",
      content: (transaction) =>
        transaction.exp_month + "/" + transaction.exp_year,
    },
    { path: "bin_country", label: "Bin country", key: "bin_country_audit" },
    { path: "description", label: "Description", key: "description" },
    { path: "first_name", label: "First name", key: "first_name" },
    { path: "last_name", label: "Last name", key: "last_name" },
    { path: "email", label: "Email", key: "email_audit" },
    { path: "phone", label: "Phone", key: "phone_audit" },
    { path: "ip", label: "IP", key: "ip_audit" },
    { path: "country", label: "Country", key: "country_audit" },
    { path: "city", label: "City", key: "city_audit" },
    { path: "address", label: "Address", key: "address_audit" },
    { path: "zip", label: "Zip", key: "zip_audit" },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];

  columnsTransactionRatesHistory = [
    {
      path: "base_amount",
      label: "Base amount",
      key: "base_amount",
      align: "right",
    },
    {
      path: "to_bank_pct",
      label: "To bank (%)",
      key: "to_bank_pct",
      align: "right",
    },
    {
      path: "to_bank_fixed",
      label: "To bank (fixed)",
      key: "to_bank_fixed",
      align: "right",
    },
    {
      path: "to_processor_pct",
      label: "To processor (%)",
      key: "to_processor_pct",
      align: "right",
    },
    {
      path: "to_processor_fixed",
      label: "To processor (fixed)",
      key: "to_processor_fixed",
      align: "right",
    },
    { path: "to_client", label: "To client", key: "to_client", align: "right" },
    { path: "hold", label: "Hold", key: "hold", align: "right" },
    {
      path: "hold_date",
      label: "Hold date",
      key: "hold_date",
      align: "center",
    },
    {
      path: "hold_flag",
      label: "Hold flag",
      key: "hold_flag",
      align: "center",
    },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (group) =>
        moment(group.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];

  columnsTransactionProcessingHistory = [
    {
      path: "date_time",
      label: "Datetime",
      key: "date_time",
      content: (transaction) =>
        transaction.date_time &&
        moment(transaction.date_time)
          .utcOffset(0)
          .format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "transaction_type", label: "Type", key: "transactionType" },
    {
      key: "active",
      content: (transaction) => (
        <i
          className={
            transaction.active
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Active",
      align: "center",
    },
    { path: "parent_guid", label: "Parent guid", key: "parent_guid" },
    {
      path: "payment_status",
      label: "Payment Status",
      key: "status",
      align: "center",
      content: (order) => (
        <Badge className={`badge-order-${order.payment_status}`}>
          {order.payment_status}
        </Badge>
      ),
    },
    { path: "shop_name", label: "Shop", key: "shop_name" },
    {
      path: "terminal_guid",
      label: "Terminal guid",
      key: "terminal_guid_audit",
    },
    {
      path: "terminal_name",
      label: "Terminal name",
      key: "terminal_name_audit",
    },
    {
      key: "test",
      content: (transaction) => (
        <i
          className={
            transaction.test
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Test",
      align: "center",
    },
    {
      path: "status",
      label: "Status",
      key: "status",
      align: "center",
      content: (order) =>
        order.status === "Success" ? (
          <span style={{ color: "green" }}>Success</span>
        ) : order.status === "Failed" ? (
          <span style={{ color: "red" }}>Failed</span>
        ) : (
          <span style={{ color: "orange" }}>{order.status}</span>
        ),
    },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];

  async componentDidMount() {
    await this.props.getTransactionProcessingStepsAction(
      this.props.match.params.id,
      1,
      this.state.pageSize
    );
    // await this.props.getTransactionChangeHistoryAction(this.props.match.params.id, 1, this.state.transactionProcessingPageSize, 1, this.state.transactionDataPageSize, 1, this.state.transactionOverviewPageSize);

    ability.can("AUDIT", "TRANSACTIONPROCESSINGS") &&
      (await this.props.getTransactionProcessingChangeHistoryAction(
        this.props.match.params.id,
        1,
        this.state.transactionProcessingPageSize
      ));
    ability.can("AUDIT", "TRANSACTIONDATA") &&
      (await this.props.getTransactionDataChangeHistoryAction(
        this.props.match.params.id,
        1,
        this.state.transactionDataPageSize
      ));
    ability.can("AUDIT", "TRANSACTIONOVERVIEWS") &&
      (await this.props.getTransactionOverviewsChangeHistoryAction(
        this.props.match.params.id,
        1,
        this.state.transactionOverviewPageSize
      ));

    this.setState({
      count: this.props.count,
    });
    if (this.props.error) {
      this.setState({
        transactionHistory: {},
      });
    } else
      this.setState({
        transactionDataHistory: this.props.transactionDataHistory,
        transactionOverviewHistory: this.props.transactionOverviewHistory,
        transactionProcessingHistory: this.props.transactionProcessingHistory,
      });
  }

  handlePageChange = async (page) => {
    const guid = this.props.match.params.id;
    await this.props.getTransactionProcessingStepsAction(
      guid,
      page,
      this.state.pageSize
    );
    await this.props.getTransactionProcessingParamsAction(guid);
    this.setState({
      count: this.props.count,
      currentPage: page,
    });
  };

  handleHistoryTransactionDataPageChange = async (page) => {
    await this.props.getTransactionDataChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.transactionDataPageSize
    );
    this.setState({
      transactionDataCurrentPage: page,
      transactionDataHistory: this.props.transactionDataHistory,
    });
  };

  handleHistoryTransactionOverviewDataPageChange = async (page) => {
    await this.props.getTransactionOverviewsChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.transactionOverviewPageSize
    );
    this.setState({
      transactionOverviewCurrentPage: page,
      transactionOverviewHistory: this.props.transactionOverviewHistory,
    });
  };

  handleHistoryTransactionProcessingDataPageChange = async (page) => {
    await this.props.getTransactionProcessingChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.transactionProcessingPageSize
    );
    this.setState({
      transactionProcessingCurrentPage: page,
      transactionProcessingHistory: this.props.transactionProcessingHistory,
    });
  };

  getPagedData = () => {
    const { count } = this.state;
    let { pageSize } = this.state;
    const pagesCount = count
      ? count / pageSize + (1 && !!(count % pageSize))
      : 0;
    return { pagesCount };
  };

  historygetPagedTransactionData = () => {
    const { transactionDataHistoryCount } = this.props;
    let { transactionDataPageSize } = this.state;
    const transactionDataHistoryPagesCount =
      transactionDataHistoryCount / transactionDataPageSize +
      (1 && !!(transactionDataHistoryCount % transactionDataPageSize));
    return { transactionDataHistoryPagesCount };
  };

  historygetPagedTransactionOverviewData = () => {
    const { transactionOverviewHistoryCount } = this.props;
    let { transactionOverviewPageSize } = this.state;
    const transactionOverviewHistoryPagesCount =
      transactionOverviewHistoryCount / transactionOverviewPageSize +
      (1 && !!(transactionOverviewHistoryCount % transactionOverviewPageSize));
    return { transactionOverviewHistoryPagesCount };
  };

  historygetPagedTransactionProcessingData = () => {
    const { transactionProcessingHistoryCount } = this.props;
    let { transactionProcessingPageSize } = this.state;
    const transactionProcessingHistoryPagesCount =
      transactionProcessingHistoryCount / transactionProcessingPageSize +
      (1 &&
        !!(transactionProcessingHistoryCount % transactionProcessingPageSize));
    return { transactionProcessingHistoryPagesCount };
  };

  render() {
    const steps = this.props.steps;
    const { transactionDataHistoryPagesCount } =
      this.historygetPagedTransactionData();
    const { transactionOverviewHistoryPagesCount } =
      this.historygetPagedTransactionOverviewData();
    const { transactionProcessingHistoryPagesCount } =
      this.historygetPagedTransactionProcessingData();
    const {
      currentPage,
      transactionDataCurrentPage,
      transactionOverviewCurrentPage,
      transactionProcessingCurrentPage,
    } = this.state;
    const {
      transactionDataHistory,
      transactionOverviewHistory,
      transactionProcessingHistory,
    } = this.state;
    const { pagesCount } = this.getPagedData();
    if (this.props.loading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5">
                Transaction guid: {this.props.match.params.id}
              </Card.Header>
              <Card.Body>
                <Card.Title>Steps</Card.Title>

                <Table
                  columns={this.columnsSteps}
                  data={steps}
                  keyPath="guid"
                  disableSearch
                  disableSort
                />
                <Pagination
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                  pageSize={this.state.pageSize}
                  count={this.props.count}
                />
              </Card.Body>
            </Card>
          </Row>

          {ability.can("AUDIT", "TRANSACTIONPROCESSINGS") && (
            <>
              {this.props.transactionHistoryloading ? (
                <Content>
                  <Spinner />
                </Content>
              ) : (
                <>
                  <Row style={{ marginLeft: "0px" }}>
                    <Card>
                      <Card.Body>
                        <Card.Title>Transaction data history</Card.Title>
                        <Row>
                          <Col md={12} sm={12} xs={12}>
                            <Table
                              columns={this.columnsTransactionDataHistory}
                              data={
                                transactionDataHistory
                                  ? transactionDataHistory
                                  : []
                              }
                              disableSearch
                              disableSort
                            />
                            <Pagination
                              pagesCount={transactionDataHistoryPagesCount}
                              currentPage={transactionDataCurrentPage}
                              onPageChange={
                                this.handleHistoryTransactionDataPageChange
                              }
                              pageSize={this.state.transactionDataPageSize}
                              count={this.props.transactionDataHistoryCount}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>
                  <Row style={{ marginLeft: "0px" }}>
                    <Card>
                      <Card.Body>
                        <Card.Title>Transaction rates history</Card.Title>

                        <Row>
                          <Col md={12} sm={12} xs={12}>
                            <Table
                              columns={this.columnsTransactionRatesHistory}
                              data={
                                transactionOverviewHistory
                                  ? transactionOverviewHistory
                                  : []
                              }
                              disableSearch
                              disableSort
                            />
                            <Pagination
                              pagesCount={transactionOverviewHistoryPagesCount}
                              currentPage={transactionOverviewCurrentPage}
                              onPageChange={
                                this
                                  .handleHistoryTransactionOverviewDataPageChange
                              }
                              pageSize={this.state.transactionOverviewPageSize}
                              count={this.props.transactionOverviewHistoryCount}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>
                  <Row style={{ marginLeft: "0px" }}>
                    <Card>
                      <Card.Body>
                        <Card.Title>Transaction processing history</Card.Title>

                        <Row>
                          <Col md={12} sm={12} xs={12}>
                            <Table
                              columns={this.columnsTransactionProcessingHistory}
                              data={
                                transactionProcessingHistory
                                  ? transactionProcessingHistory
                                  : []
                              }
                              disableSearch
                              disableSort
                            />
                            <Pagination
                              pagesCount={
                                transactionProcessingHistoryPagesCount
                              }
                              currentPage={transactionProcessingCurrentPage}
                              onPageChange={
                                this
                                  .handleHistoryTransactionProcessingDataPageChange
                              }
                              pageSize={
                                this.state.transactionProcessingPageSize
                              }
                              count={
                                this.props.transactionProcessingHistoryCount
                              }
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>
                </>
              )}
            </>
          )}
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    steps: state.transactions.transactionProcessingSteps,
    count: state.transactions.transactionProcessingStepsCount,
    loading: state.transactions.transactionProcessingStepsLoading,

    transactionOverviewHistory: state.reasons.transactionOverviewHistory,
    transactionOverviewHistoryCount:
      state.reasons.transactionOverviewHistoryCount,

    transactionDataHistory: state.reasons.transactionDataHistory,
    transactionDataHistoryCount: state.reasons.transactionDataHistoryCount,

    transactionProcessingHistory: state.reasons.transactionProcessingHistory,
    transactionProcessingHistoryCount:
      state.reasons.transactionProcessingHistoryCount,

    transactionHistoryloading: state.reasons.loading,
    error: state.reasons.error,
  };
};

export default connect(mapStateToProps, {
  getTransactionProcessingStepsAction,
  getTransactionProcessingChangeHistoryAction,
  getTransactionDataChangeHistoryAction,
  getTransactionOverviewsChangeHistoryAction,
})(HistoryStepDetail);

HistoryStepDetail.propTypes = {
  count: PropTypes.number,
  error: PropTypes.string,
  getTransactionDataChangeHistoryAction: PropTypes.func,
  getTransactionOverviewsChangeHistoryAction: PropTypes.func,
  getTransactionProcessingChangeHistoryAction: PropTypes.func,
  getTransactionProcessingParamsAction: PropTypes.func,
  getTransactionProcessingStepsAction: PropTypes.func,
  loading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  steps: PropTypes.array,
  transactionDataHistory: PropTypes.array,
  transactionDataHistoryCount: PropTypes.number,
  transactionHistoryloading: PropTypes.bool,
  transactionOverviewHistory: PropTypes.array,
  transactionOverviewHistoryCount: PropTypes.number,
  transactionProcessingHistory: PropTypes.array,
  transactionProcessingHistoryCount: PropTypes.number,
};
