import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import {
  deleteCurrencyAction,
  getAllCurrencies,
} from "../../actions/currencies";
import { reset, searchInCurrencies } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { getCurrencies } from "../../services/paymentBackendAPI/management/currencies";
import CurrencyCreator from "./Creator";
import CurrencyEditor from "./Editor";
import CurrencyHistory from "./History";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";

const columns = [
  { path: "code", label: "Code", key: "currencyCode" },
  { path: "name", label: "Name", key: "currencyName" },
  { path: "number", label: "Number", key: "currencyNumber" },
  {
    path: "rate_to_eur",
    label: "Rate (EUR)",
    key: "currencyRate",
    align: "right",
  },
  {
    path: "exchange_markup_value",
    label: "Exchange markup value",
    key: "exchange_markup_value",
    align: "right",
  },
  {
    label: "Exchange markup type",
    key: "exchange_markup_type",
    align: "right",
    content: (currency) => (currency.isFlat ? "Flat" : "Percent"),
  },
  ability.can("AUDIT", "CURRENCIES") && {
    label: "History",
    key: "history",
    align: "center",
    content: (currency) => (
      <Modal
        content={CurrencyHistory}
        contentProps={{ guid: currency.guid }}
        buttonName="Show"
        dialogClassName="modal-creator"
        button={
          <button type="button" className="btn btn-table">
            Show
          </button>
        }
      />
    ),
    colWidth: 5,
  },
  ability.can("EXECUTE", "CURRENCIES") && {
    key: "edit",
    content: (currency) => (
      <Modal
        header="Edit currency"
        action="Save"
        content={CurrencyEditor}
        contentProps={{ guid: currency.guid, type: "currency" }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "CURRENCIES") && {
    key: "delete",
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.currencies.currenciesList,
    count: state.currencies.count,
    table: state.store.table,
    loading: state.currencies.currenciesLoading,
    searchData: state.search.currenciesSearch,
    isSearch: state.search.isSearch,
    name: "currencies",
    keyPath: "guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    modal: <CurrencyCreator />,
    exportFunction: getCurrencies,
  };
};

export default connect(mapStateToProps, {
  get: getAllCurrencies,
  delete: deleteCurrencyAction,
  search: searchInCurrencies,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
