import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteGatewayAction, getAllGateways } from "../../actions/gateways";
import { reset, searchInGateways } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { getGateways } from "../../services/paymentBackendAPI/management/gateways";
import GatewayCreator from "./Creator";
import GatewayEditor from "./Editor";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";

const columns = [
  {
    path: "name",
    label: "Name",
    key: "guid",
    content: (gateway) => (
      <Link className="link" to={`/about/gateway/${gateway.guid}`}>
        {gateway.name}
      </Link>
    ),
    textContent: (gateway) => gateway.name,
  },
  { path: "description", label: "Description" },
  ability.can("EXECUTE", "GATEWAYS") && {
    key: "edit",
    content: (gateway) => (
      <Modal
        header="Edit gateway"
        action="Save"
        content={GatewayEditor}
        contentProps={{ guid: gateway.guid, type: "gateway" }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "GATEWAYS") && {
    key: "delete",
    content: () => (
      <i className="far fa-trash-alt icon red" style={{ cursor: "pointer" }} />
    ),
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.gateways.gatewaysList,
    count: state.gateways.count,
    table: state.store.table,
    loading: state.gateways.gatewaysLoading,
    searchData: state.search.gatewaysSearch,
    isSearch: state.search.isSearch,
    name: "gateways",
    keyPath: "guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    modal: <GatewayCreator />,
    exportFunction: getGateways,
  };
};

export default connect(mapStateToProps, {
  get: getAllGateways,
  delete: deleteGatewayAction,
  search: searchInGateways,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
