import React from "react";
import PropTypes from "prop-types";
import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

export const ExportButton = (props) => {
  const downloadPdf = () => {
    const header = props.columns.map((col) => col.label);
    const unwrapped = props.data[0].collapsed
      ? props.data
        .map((item) =>
          item.collapsed.map((row) => ({ date: item.date, ...row }))
        )
        .flat()
      : props.data;
    const data = unwrapped.map((row) =>
      props.columns.map((col) => row[col.path])
    );

    const pdf = new jsPDF("landscape");
    pdf.text(150, 10, props.name, "center");
    pdf.autoTable({
      head: [ header ],
      body: data,
      theme: "grid",
      marginLeft: 20,
      marginRight: 20,
      styles: {
        halign: "center",
      },
      headStyles: {
        fontSize: 9,
        fillColor: [ 70, 179, 80 ],
      },
      bodyStyles: {
        fontSize: 9,
      },
    });
    pdf.save(`${props.name}.pdf`);
  };

  const downloadCsv = () => {
    const header = props.columns.map((col) => col.label);
    const unwrapped = props.data[0].collapsed
      ? props.data
        .map((item) =>
          item.collapsed.map((row) => ({ date: item.date, ...row }))
        )
        .flat()
      : props.data;
    const data = unwrapped.map((row) =>
      props.columns.map((col) => row[col.path])
    );

    const blob = new Blob([ header.join(","), "\n", data.join("\n") ], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, `${props.name}.csv`);
  };

  const downloadXls = () => {
    const header = props.columns.map((col) => col.label);
    const unwrapped = props.data[0].collapsed
      ? props.data
        .map((item) =>
          item.collapsed.map((row) => ({ date: item.date, ...row }))
        )
        .flat()
      : props.data;
    const data = unwrapped.map((row) =>
      props.columns.map((col) => row[col.path])
    );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([ header ]);
    XLSX.utils.sheet_add_aoa(ws, data, { origin: "A2" });
    XLSX.utils.book_append_sheet(wb, ws, props.name);
    XLSX.writeFile(wb, `${props.name}.xls`);
  };

  return props.data && props.data.length > 0 ? (
    <React.Fragment>
      <button
        id={props.id}
        key={props.name}
        className="btn btn-fill search-button"
        onClick={downloadCsv}
        style={{ margin: "5px", ...props.style }}
      >
        CSV
      </button>
      <button
        id={props.id}
        key={props.name}
        className="btn btn-fill search-button"
        onClick={downloadPdf}
        style={{ margin: "5px", ...props.style }}
      >
        PDF
      </button>
      <button
        id={props.id}
        key={props.name}
        className="btn btn-fill search-button"
        onClick={downloadXls}
        style={{ margin: "5px", ...props.style }}
      >
        XLS
      </button>
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

ExportButton.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  id: PropTypes.string,
  name: PropTypes.string,
};
