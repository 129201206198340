import {
  addBlackListRuleAction,
  deleteBlackListRuleAction,
  getBlackListItemAction,
} from "actions/blacklists";
import Select from "components/UI/MultiSelect";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table/index";
import ability from "config/ability";
import { countries } from "constants/countries";
import Joi from "joi-browser";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import Content from "views/Content";

class BlackListDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  componentDidMount = async () => {
    await this.props.getBlackListItemAction(this.props.match.params.id);
  };

  delete = async (value, reason, type) => {
    await this.props.deleteBlackListRuleAction({
      blacklist_rule_guid: this.props.match.params.id,
      value,
      delete: true,
      type,
      reason,
    });
    await this.props.getBlackListItemAction(this.props.match.params.id);
  };

  click = () => {
    if (this.state.value !== "") {
      const request = async () => {
        console.log(this.state.value);
        await this.props.addBlackListRuleAction({
          blacklist_rule_guid: this.props.match.params.id,
          type:  this.props.blackItem.type,
          value: this.state.value,
        });
        await this.props.getBlackListItemAction(this.props.match.params.id);
        this.setState({
          value: "",
        });
      };
      if (this.props.blackItem.type === "card") {
        const valid = Joi.validate(this.state.value, Joi.string().creditCard());
        if (valid.error)
          swal({
            title: `${valid.error}`,
            icon: "warning",
          });
        else {
          request();
        }
      } else if (this.props.blackItem.type === "ip") {
        const valid = Joi.validate(
          this.state.value,
          Joi.string().ip({ version: [ "ipv4", "ipv6" ] })
        );
        if (valid.error)
          swal({
            title: `${valid.error}`,
            icon: "warning",
          });
        else {
          request();
        }
      } else if (this.props.blackItem.type === "country") {
        const valid = Joi.validate(
          this.state.value,
          Joi.string()
            .regex(/^[A-Z]{2}$/).error(() => {
              return {
                message:
                  "Country must be Alpha-2 code",
              };
            })
            .required()
        );
        if (valid.error)
          swal({
            title: `${valid.error}`,
            icon: "warning",
          });
        else {
          request();
        }
      } else if (this.props.blackItem.type === "mask") {
        const valid = Joi.validate(this.state.value, Joi.string().required());
        if (valid.error)
          swal({
            title: `${valid.error}`,
            icon: "warning",
          });
        else {
          request();
        }
      } else if (this.props.blackItem.type === "bin") {
        const valid = Joi.validate(
          this.state.value,
          Joi.string()
            .regex(/^[0-9]{6}$/).error(() => {
              return {
                message:
                  "Bin is invalid",
              };
            })
            .required()
        );
        if (valid.error)
          swal({
            title: `${valid.error}`,
            icon: "warning",
          });
        else {
          request();
        }
      }
    } else
      swal({
        title: "Value cannot be null",
        icon: "warning",
      });
  };

  onSelect = (e) => {
    this.setState({
      value: e.name,
    });
  };

  change = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  columns = [
    {
      content: (value) => value,
      label: "Value",
    },
    ability.can("DELETE", "BLACKLISTS") && {
      key: "delete",
      align: "center",
      content: (item) =>
        this.props.blackItem.isEditable === undefined ||
          this.props.blackItem.isEditable === true ? (
              <i
                className="far fa-trash-alt red icon"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  swal({
                    title: "Are you sure?",
                    text:
                  "Once deleted, you will not be able to recover this. Type reason below.",
                    content: "input",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((value) => {
                    if (value) {
                 
                      this.delete(item.value, value, this.props.blackItem.type);
                      swal("Deleted", {
                        icon: "success",
                        button: false,
                        timer: 2000,
                      });
                    }
                  })
                }
              />
            ) : null,
      label: "Delete",
    },
  ];

  render() {
    const blackItem = this.props.blackItem;
    if (this.props.itemLoading)
      return (
        <Content style={{ height: "75vh" }}>

          <Spinner />
        </Content>
      );
    else
      return (
        <Content>

          <Card>
            <Card.Body>
              <Card.Title>Info</Card.Title>
              <Row>
                <Col md={6} sm={6} xs={6} className="right">
                  <label>Guid:</label>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <span>{blackItem.guid}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={6} xs={6} className="right">
                  <label>Name:</label>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <span>{blackItem.name}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={6} xs={6} className="right">
                  <label>Type:</label>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <span>{blackItem.type}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={6} xs={6} className="right">
                  <label>Description:</label>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <span>{blackItem.description}</span>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Values</Card.Title>
              {ability.can("EXECUTE", "BLACKLISTS") &&
                (blackItem.isEditable === undefined ||
                  blackItem.isEditable === true) && (
                <Row>
                  <Col className="col">
                    <Form.Label>
                      {blackItem.type === "card"
                        ? "card"
                        : blackItem.type === "ip"
                          ? "IP"
                          : blackItem.type === "country"
                            ? "Country"
                            : blackItem.type === "mask"
                              ? "Credit card mask"
                              : blackItem.type === "phone"
                                ? "Phone number"
                                : "Credit card BIN "}
                    </Form.Label>
                  </Col>
                  <Col className="col">
                    <Form.Group>
                      {blackItem.type === "country" ? (
                        <Select
                          multi={false}
                          name="country_select"
                          options={countries}
                          onSelect={this.onSelect}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          onChange={this.change}
                          value={this.state.value}
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col className="col">
                    <i
                      src="fas fa-plus"
                      className="fas fa-plus"
                      style={{ cursor: "pointer", color: "green" }}
                      onClick={this.click}
                      value=""
                    />
                  </Col>
                </Row>
              )}
              <Table
                disableSearch
                disableSort
                columns={this.columns}
                data={blackItem.values}
              />
            </Card.Body>
          </Card>

        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    blackItem: state.blacklist.blackItem,
    rules: state.blacklist.blackListRules,
    itemLoading: state.blacklist.itemLoading,
  };
};

export default connect(mapStateToProps, {
  getBlackListItemAction,
  addBlackListRuleAction,
  deleteBlackListRuleAction,
})(BlackListDetail);

BlackListDetail.propTypes = {
  addBlackListRuleAction: PropTypes.func,
  blackItem: PropTypes.object,
  deleteBlackListRuleAction: PropTypes.func,
  getBlackListItemAction: PropTypes.func,
  itemLoading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};
