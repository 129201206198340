import React from "react";
import Content from "../../Content";
import ShopAuditMain from "components/Audit/ShopTable/Main";

const ShopAudit = () => {
  return (
    <Content>
      <ShopAuditMain />
    </Content>
  );
};

export default ShopAudit;
