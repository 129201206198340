import * as types from "../constants/actionTypes";
import {
  getUsersLogs,
  getAdminsLogs,
  getMerchantsLogs,
  getGoupsLogs,
  getPartnerLogs,
  getTransactionsStepsLogs,
  getTransactionsLogs,
  getDectaRecotServiceLogs,
  getСhargebackServiceLogs,
  getLoginExpiredServiceLogs,
  getCurrencyExchangeServiceLogs,
  getDeleteCardDataServiceLogs,
  getRecurringServiceLogs
} from "../services/paymentBackendAPI/logs/logs";
import { loadTable } from "./store";

export const getUsersLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USERS_LOGS
    });
    try {
      const response = await getUsersLogs(args);
      dispatch({
        type: types.GET_USERS_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_USERS_LOGS_FAILED
      });
    }
  };
};

export const getAdminsLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ADMINS_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getAdminsLogs(args);
      dispatch({
        type: types.GET_ADMINS_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_ADMINS_LOGS_FAILED
      });
    }
  };
};

export const getMerchantsLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANTS_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getMerchantsLogs(args);
      dispatch({
        type: types.GET_MERCHANTS_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_MERCHANTS_LOGS_FAILED
      });
    }
  };
};

export const getGroupLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_GROUP_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getGoupsLogs(args);
      dispatch({
        type: types.GET_GROUP_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_GROUP_LOGS_FAILED
      });
    }
  };
};

export const getPartnerLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PARTNER_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getPartnerLogs(args);
      dispatch({
        type: types.GET_PARTNER_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_PARTNER_LOGS_FAILED
      });
    }
  };
};


export const getTransactionsLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTIONS_LOGS
    });
    try {
      const response = await getTransactionsLogs(args);
      dispatch({
        type: types.GET_TRANSACTIONS_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TRANSACTIONS_LOGS_FAILED
      });
    }
  };
};

export const getTransactionsStepsLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTIONS_STEPS_LOGS
    });
    try {
      const response = await getTransactionsStepsLogs(args);
      return dispatch({
        type: types.GET_TRANSACTIONS_STEPS_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TRANSACTIONS_STEPS_LOGS_FAILED
      });
    }
  };
};

export const getDectaRecotServiceLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_DECTA_RECOT_SERVICE_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getDectaRecotServiceLogs(args);
      return dispatch({
        type: types.GET_DECTA_RECOT_SERVICE_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_DECTA_RECOT_SERVICE_LOGS_FAILED
      });
    }
  };
};

export const getChargebackServiceLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CHARGEBACK_SERVICE_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getСhargebackServiceLogs(args);
      return dispatch({
        type: types.GET_CHARGEBACK_SERVICE_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_CHARGEBACK_SERVICE_LOGS_FAILED
      });
    }
  };
};

export const getLoginExpiredServiceLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_LOGIN_EXPIRED_SERVICE_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getLoginExpiredServiceLogs(args);
      return dispatch({
        type: types.GET_LOGIN_EXPIRED_SERVICE_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_LOGIN_EXPIRED_SERVICE_LOGS_FAILED
      });
    }
  };
};

export const getCurrencyExchangeServiceLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CURRENCY_EXCHANGE_SERVICE_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getCurrencyExchangeServiceLogs(args);
      return dispatch({
        type: types.GET_CURRENCY_EXCHANGE_SERVICE_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_CURRENCY_EXCHANGE_SERVICE_LOGS_FAILED
      });
    }
  };
};

export const getDeleteCardDataServiceLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_DELETE_CARD_DATA_SERVICE_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getDeleteCardDataServiceLogs(args);
      return dispatch({
        type: types.GET_DELETE_CARD_DATA_SERVICE_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_DELETE_CARD_DATA_SERVICE_LOGS_FAILED
      });
    }
  };
};

export const getRecurringServiceLogsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RECURRING_SERVICE_LOGS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getRecurringServiceLogs(args);
      return dispatch({
        type: types.GET_RECURRING_SERVICE_LOGS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_RECURRING_SERVICE_LOGS_FAILED
      });
    }
  };
};