import React, { Component } from "react";
import { connect } from "react-redux";
import { getReportShopHoldsAction } from "../../actions/transactions";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import { parseString } from "helpers/parseString";

class ShopHolds extends Component {
  state = {
    hold: "",
  };

  componentDidMount = async () => {
    await this.props.getReportShopHoldsAction({
      shop_guid: this.props.shopGuid,
    });
    const { amount } = this.props.shopHolds;

    this.setState({ hold: amount });

    let { hold } = this.state;
    const holdLength = hold ? hold.length : 0;

    const holdAmount = parseString(hold, holdLength);

    this.setState({
      hold: holdAmount,
    });
  };

  render() {
    const { loading, shopHolds, classname } = this.props;
    const { hold } = this.state;

    if (loading) return <Spinner />;
    else
      return (
        <div>
          <div className="header">Holds</div>
          <div className={classname}>€ {shopHolds ? hold : 0}</div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    shopHolds: state.transactions.shopHolds,
    loading: state.transactions.shopHoldsLoading,
  };
};

export default connect(mapStateToProps, {
  getReportShopHoldsAction,
})(ShopHolds);

ShopHolds.propTypes = {
  classname: PropTypes.string,
  getReportShopHoldsAction: PropTypes.func,
  loading: PropTypes.bool,
  shopGuid: PropTypes.string,
  shopHolds: PropTypes.object,
};
