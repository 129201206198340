import * as types from "../constants/actionTypes";


export const hideSidebar = () => {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_SIDEBAR
    });
  };
};

export const showSidebar = () => {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_SIDEBAR
    });
  };
};

export const inverseSidebar = () => {
  return (dispatch) => {
    dispatch({
      type: types.INVERSE_SIDEBAR
    });
  };
};

export const onCollapseItemClick = (state) => {
  return (dispatch) => {
    dispatch({
      type: types.COLLAPSE_ITEM_CLICK,
      state
    });
  };
};