import {
  GET_TRANSACTIONS_CURRENCIES,
  ADD_TRANSACTION,
  EDIT_TRANSACTION,
  ACTIVATE_TRANSACTION,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCEED,
  GET_TRANSACTION_FAILED,
  GET_TRANSACTION_RATES,
  GET_TRANSACTION_RATES_SUCCEED,
  GET_TRANSACTION_RATES_FAILED,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCEED,
  GET_TRANSACTIONS_FAILED,
  GET_TRANSACTION_TEMPLATE,
  GET_MERCHANT_TRANSACTIONS,
  ADD_PARAM_TRANSACTION,
  GET_TRANSACTION_TEMPLATES,
  GET_TRANSACTION_TEMPLATES_SUCCEED,
  GET_TRANSACTION_TEMPLATES_FAILED,
  CLEAN_PARAMS,
  GET_TRANSACTION_STEPS,
  GET_TRANSACTION_STEPS_SUCCEED,
  GET_TRANSACTION_STEPS_FAILED,
  GET_STEP,
  GET_TRANSACTION_PROCESSING_STEPS,
  GET_TRANSACTION_PROCESSING_STEPS_SUCCEED,
  GET_TRANSACTION_PROCESSING_PARAMS,
  GET_TRANSACTIONS_CURRENCIES_SUCCEED,
  GET_TRANSACTIONS_CURRENCIES_FAILED,
  GET_TRANSACTIONS_FOR_GATEWAY,
  GET_TRANSACTIONS_FOR_GATEWAY_SUCCEED,
  GET_TRANSACTIONS_FOR_GATEWAY_FAILED,
  GET_TRANSACTION_HOLDS,
  GET_TRANSACTION_HOLDS_SUCCEED,
  GET_TRANSACTION_HOLDS_FAILED,
  ADD_TRANSACTION_HOLDS,
  GET_TRANSACTION_HISTORY,
  GET_TRANSACTION_HISTORY_SUCCEED,
  GET_TRANSACTION_HISTORY_FAILED,
  GET_TRANSACTION_CURRENCIES,
  GET_TRANSACTION_CURRENCIES_SUCCEED,
  GET_TRANSACTION_CURRENCIES_FAILED,
  GET_DAILY_TRANSACTION,
  GET_DAILY_TRANSACTION_SUCCEED,
  GET_DAILY_TRANSACTION_FAILED,
  GET_ORDERS,
  GET_ORDERS_SUCCEED,
  GET_ORDERS_FAILED,
  GET_ORDER,
  GET_ORDER_SUCCEED,
  GET_ORDER_FAILED,
  GET_SHOP_TOTALS,
  GET_SHOP_TOTALS_SUCCEED,
  GET_SHOP_TOTALS_FAILED,
  GET_TOTALS,
  GET_TOTALS_SUCCEED,
  GET_TOTALS_FAILED,
  GET_TOTAL_PROCESSED,
  GET_TOTAL_PROCESSED_SUCCEED,
  GET_TOTAL_PROCESSED_FAILED,
  GET_SHOP_TOTAL_PROCESSED,
  GET_SHOP_TOTAL_PROCESSED_SUCCEED,
  GET_SHOP_TOTAL_PROCESSED_FAILED,
  GET_TOTAL_TO_CLIENT,
  GET_TOTAL_TO_CLIENT_SUCCEED,
  GET_TOTAL_TO_CLIENT_FAILED,
  GET_TOTAL_TO_PROCESSOR,
  GET_TOTAL_TO_PROCESSOR_SUCCEED,
  GET_TOTAL_TO_PROCESSOR_FAILED,
  GET_TRANSACTION_TYPE,
  GET_TRANSACTION_TYPE_SUCCEED,
  GET_TRANSACTION_TYPE_FAILED,
  GET_SHOP_TRANSACTION_TYPE,
  GET_SHOP_TRANSACTION_TYPE_SUCCEED,
  GET_SHOP_TRANSACTION_TYPE_FAILED,
  GET_BALANCE,
  GET_BALANCE_SUCCEED,
  GET_BALANCE_FAILED,
  GET_CURRENCIES_OF_TRANSACTIONS,
  GET_CURRENCIES_OF_TRANSACTIONS_SUCCEED,
  GET_CURRENCIES_OF_TRANSACTIONS_FAILED,
  GET_MERCHANT_TRANSACTIONS_SUCCEED,
  GET_MERCHANT_TRANSACTIONS_FAILED,
  GET_TRANSACTIONS_TYPES_FOR_GATEWAY,
  GET_TRANSACTIONS_TYPES_FOR_GATEWAY_SUCCEED,
  GET_TRANSACTIONS_TYPES_FOR_GATEWAY_FAILED,
  GET_REPORT_HOLDS,
  GET_REPORT_HOLDS_SUCCEED,
  GET_REPORT_HOLDS_FAILED,
  GET_REPORT_SHOP_HOLDS,
  GET_REPORT_SHOP_HOLDS_SUCCEED,
  GET_REPORT_SHOP_HOLDS_FAILED,
  GET_TRANSACTION_AMOUNT,
  GET_TRANSACTION_AMOUNT_SUCCEED,
  GET_TRANSACTION_AMOUNT_FAILED,
  GET_MISMATCH_TRANSACTION,
  GET_MISMATCH_TRANSACTION_SUCCEED,
  GET_MISMATCH_TRANSACTION_FAILED,
  GET_MISMATCH_TRANSACTIONS,
  GET_MISMATCH_TRANSACTIONS_SUCCEED,
  GET_MISMATCH_TRANSACTIONS_FAILED,
  GET_TRANSACTION_AND_COMMISSION_REPORT,
  GET_TRANSACTION_AND_COMMISSION_REPORT_SUCCEED,
  GET_TRANSACTION_AND_COMMISSION_REPORT_FAILED,
  GET_ADMIN_DASHBOARD_DATA,
  GET_ADMIN_DASHBOARD_DATA_SUCCEED,
  GET_ADMIN_DASHBOARD_DATA_FAILED,
  GET_MERCHANTS_LIMIT_DATA,
  GET_MERCHANTS_LIMIT_DATA_SUCCEED,
  GET_MERCHANTS_LIMIT_DATA_FAILED,
  GET_MERCHANTS_TOTALS_DATA,
  GET_MERCHANTS_TOTALS_DATA_SUCCEED,
  GET_MERCHANTS_TOTALS_DATA_FAILED,
  GET_ERROR_REPORT,
  GET_ERROR_REPORT_SUCCEED,
  GET_ERROR_REPORT_FAILED,
  GET_HISTOGRAM_REPORT,
  GET_GEOGRAPHIC_REPORT,
} from "../constants/actionTypes";

const initialState = {
  transactionsList: [],
  transaction: {},
  transactionRates: {},
  transactionLoading: "",
  transactionsForGateway: [],
  templatesList: [],
  transactionTypesForGateway: [],
  types: [],
  typesTransactionsOfShop: [],
  stepsList: [],
  paramsList: [],
  transactionTemplate: {
    guid: "",
    name: "",
    type: "",
    spec_rate: "",
  },
  stepsCount: 0,
  transactionSteps: [],
  step: {
    guid: "",
    name: "",
    gateway_guid: "",
  },
  order: {
    customer_details: {
      card_holder: "",
      email: "",
      card_number: "",
      phone: "",
      ip: "",
    },
    billing_address: {
      first_name: "",
      last_name: "",
      country: "",
      city: "",
      address: "",
      zip: "",
    },
    transaction_details: {
      guid: "",
      type: "",
      status: "",
      amount: "",
      currency: "",
      description: "",
      date: "",
      shop_guid: "",
      shop_name: "",
      test: true,
    },
    child_transactions: [],
  },
  transactionHistoryLoading: true,
  transactionHistory: [],
  transactionHistoryCount: 0,
  transactionCurrencyLoading: true,
  transactionCurrency: [],
  dailyTransactionLoading: true,
  dailyTransaction: [],
  ordersLoading: true,
  orders: [],
  shopTotalsLoading: true,
  shopTotals: [],
  totalsLoading: true,
  totals: [],
  totalProcessedLoading: true,
  totalProcessed: [],
  totalShopProcessedLoading: true,
  shopReports: [],
  totalToClientLoading: true,
  totalToClient: [],
  totalToProcessorLoading: true,
  totalToProcessor: [],
  transactionTypesLoading: true,
  transactionProcessingStepsLoading: true,
  balanceLoading: true,
  balance: [],
  transactionAmountLoading: true,
  amount: [],

  holdsList: [],
  holdsLoading: true,

  hold: [],

  holdAmount: "",
  holdAmountLoading: true,
  shopHolds: "",
  shopHoldsLoading: true,

  mismatchTransactions: "",
  mismatchTransactionsLoading: true,
  mismatchTransaction: "",
  mismatchTransactionLoading: true,
  transactionAndCommission: [],
  transactionAndCommissionLoading: false,

  dashboardTotals: {},
  dashboardTotalsLoading: false,
  merchantLimits: {},
  errorReport: {},
  histogramReport: {},
  geographicReport: {},
};

export default function transactions(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_TRANSACTIONS_SUCCEED: {
      return {
        ...state,
        transactionsList: action.data.data,
        count: action.data.count,
        loading: false,
        error: false,
      };
    }
    case GET_TRANSACTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_TRANSACTION:
      return {
        ...state,
        transactionLoading: true,
        error: false,
      };
    case GET_TRANSACTION_SUCCEED: {
      return {
        ...state,
        transaction: action.data,
        transactionLoading: false,
        error: false,
      };
    }
    case GET_TRANSACTION_FAILED:
      return {
        ...state,
        transactionLoading: false,
        error: true,
      };
    case GET_TRANSACTION_RATES:
      return {
        ...state,
        transactionLoading: true,
        error: false,
      };
    case GET_TRANSACTION_RATES_SUCCEED: {
      return {
        ...state,
        transactionRates: action.data,
        transactionLoading: false,
        error: false,
      };
    }
    case GET_TRANSACTION_RATES_FAILED:
      return {
        ...state,
        transactionLoading: false,
        error: true,
      };
    case EDIT_TRANSACTION: {
      let newTransactionsList = state.transactionsList
        ? state.transactionsList
        : [];
      newTransactionsList = newTransactionsList.map((transaction) => {
        if (transaction.guid === action.transaction.guid) {
          return { ...transaction, ...action.transaction };
        } else return transaction;
      });
      return { ...state, transactionsList: newTransactionsList };
    }
    case ACTIVATE_TRANSACTION: {
      let newTransactionsList = state.transactionsList
        ? state.transactionsList
        : [];
      newTransactionsList = newTransactionsList.map((transaction) => {
        if (transaction.guid === action.data.guid) {
          return { ...transaction, ...action.data };
        } else return transaction;
      });
      return { ...state, transactionsList: newTransactionsList };
    }
    case ADD_TRANSACTION:
      return { ...state, transaction: action.transaction };

    case GET_TRANSACTIONS_CURRENCIES:
      return {
        ...state,
        transactionsCurrenciesLoading: true,
      };
    case GET_TRANSACTIONS_CURRENCIES_SUCCEED:
      return {
        ...state,
        currenciesReport: action.data,
        transactionsCurrenciesLoading: false,
      };
    case GET_TRANSACTIONS_CURRENCIES_FAILED:
      return {
        ...state,
        transactionsCurrenciesLoading: false,
      };
    case GET_MERCHANT_TRANSACTIONS:
      return {
        ...state,
        merchantTransactionHistoryLoading: true,
      };
    case GET_MERCHANT_TRANSACTIONS_SUCCEED:
      return {
        ...state,
        merchantTransactionHistory: action.data.data,
        merchantTransactionHistoryCount: action.data.count,
        merchantTransactionHistoryLoading: false,
      };
    case GET_MERCHANT_TRANSACTIONS_FAILED:
      return {
        ...state,
        merchantTransactionHistoryLoading: false,
      };
    case ADD_PARAM_TRANSACTION: {
      let params = action.data.value.split("\n");
      params = params.join("");
      params = params.split("\"");
      params = params.join("\\\"");
      params = params.replace(/\s+/g, "");
      return {
        ...state,
        paramsList: [
          ...state.paramsList,
          {
            name: action.data.name,
            value: params,
          },
        ],
      };
    }
    case CLEAN_PARAMS:
      return { ...state, paramsList: [] };
    case GET_TRANSACTION_TEMPLATES:
      return {
        ...state,
        templatesLoading: true,
        error: false,
      };
    case GET_TRANSACTION_TEMPLATES_SUCCEED:
      return {
        ...state,
        templatesList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        templatesLoading: false,
        error: false,
      };
    case GET_TRANSACTION_TEMPLATES_FAILED:
      return {
        ...state,
        templatesLoading: false,
        error: true,
      };
    case GET_TRANSACTION_TEMPLATE:
      return { ...state, transactionTemplate: action.template };
    case GET_TRANSACTION_STEPS:
      return {
        ...state,
        transactionStepsLoading: true,
        error: false,
      };
    case GET_TRANSACTION_STEPS_SUCCEED: {
      let newTransactionSteps = state.transactionSteps;
      let flag = 0;
      newTransactionSteps = newTransactionSteps.map((transactionSteps) => {
        if (transactionSteps.guid === action.guid) {
          flag = 1;
          return {
            guid: action.guid,
            data: action.data.data,
            count: action.data.count,
          };
        } else return transactionSteps;
      });
      if (flag === 0) {
        newTransactionSteps = [
          ...newTransactionSteps,
          {
            guid: action.guid,
            data: action.data.data,
            count: action.data.count,
          },
        ];
      }
      return {
        ...state,
        transactionSteps: newTransactionSteps,
        transactionStepsLoading: false,
        stepsCount: action.data.count,
        error: false,
      };
    }
    case GET_TRANSACTION_STEPS_FAILED:
      return {
        ...state,
        transactionStepsLoading: false,
        error: true,
      };
    case GET_STEP:
      return { ...state, step: action.step };
    case GET_TRANSACTION_PROCESSING_STEPS:
      return {
        ...state,
        transactionProcessingStepsLoading: true,
      };
    case GET_TRANSACTION_PROCESSING_STEPS_SUCCEED: {
      return {
        ...state,
        transactionProcessingSteps: action.data.data,
        transactionProcessingStepsCount: action.data.count,
        transactionProcessingStepsLoading: false,
      };
    }
    case GET_TRANSACTION_PROCESSING_PARAMS:
      return {
        ...state,
        transactionProcessingParams: action.data.data,
        transactionProcessingParamsCount: action.data.count,
      };

    case GET_TRANSACTIONS_FOR_GATEWAY:
      return {
        ...state,
        transactionsForGatewayLoading: true,
        error: false,
      };
    case GET_TRANSACTIONS_FOR_GATEWAY_SUCCEED: {
      return {
        ...state,
        transactionsForGateway: action.data,
        transactionsForGatewayLoading: false,
        error: false,
      };
    }
    case GET_TRANSACTIONS_FOR_GATEWAY_FAILED:
      return {
        ...state,
        transactionsForGatewayLoading: false,
        error: true,
      };

    case GET_TRANSACTIONS_TYPES_FOR_GATEWAY:
      return {
        ...state,
        transactionTypesForGatewayLoading: true,
        error: false,
      };
    case GET_TRANSACTIONS_TYPES_FOR_GATEWAY_SUCCEED: {
      return {
        ...state,
        transactionTypesForGateway: action.data,
        transactionTypesForGatewayLoading: false,
        error: false,
      };
    }
    case GET_TRANSACTIONS_TYPES_FOR_GATEWAY_FAILED:
      return {
        ...state,
        transactionTypesForGatewayLoading: false,
        error: true,
      };

      /***********************( ╯°□°)╯┻┻**************************/

    case GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistoryLoading: true,
      };
    case GET_TRANSACTION_HISTORY_SUCCEED:
      return {
        ...state,
        transactionHistory: action.data,
        transactionHistoryCount: action.data.count,
        transactionHistoryLoading: false,
      };
    case GET_TRANSACTION_HISTORY_FAILED:
      return {
        ...state,
        transactionHistoryLoading: false,
      };

    case GET_TRANSACTION_CURRENCIES:
      return {
        ...state,
        transactionCurrencyLoading: true,
      };
    case GET_TRANSACTION_CURRENCIES_SUCCEED:
      return {
        ...state,
        transactionCurrency: action.data,
        transactionCurrencyLoading: false,
      };
    case GET_TRANSACTION_CURRENCIES_FAILED:
      return {
        ...state,
        transactionCurrencyLoading: false,
      };
    case GET_CURRENCIES_OF_TRANSACTIONS:
      return {
        ...state,
        currenciesOfTransactionsLoading: true,
      };
    case GET_CURRENCIES_OF_TRANSACTIONS_SUCCEED:
      return {
        ...state,
        currenciesOfTransactionsLoading: false,
        currenciesOfTransactions: action.data,
      };
    case GET_CURRENCIES_OF_TRANSACTIONS_FAILED:
      return {
        ...state,
        currenciesOfTransactionsLoading: false,
      };
    case GET_DAILY_TRANSACTION:
      return {
        ...state,
        dailyTransactionLoading: true,
      };
    case GET_DAILY_TRANSACTION_SUCCEED:
      return {
        ...state,
        dailyTransaction: action.data,
        dailyTransactionLoading: false,
      };
    case GET_DAILY_TRANSACTION_FAILED:
      return {
        ...state,
        dailyTransactionLoading: false,
      };

    case GET_ORDERS:
      return {
        ...state,
        ordersLoading: true,
      };
    case GET_ORDERS_SUCCEED:
      return {
        ...state,
        orders: action.data.data,
        ordersCount: action.data.count,
        ordersLoading: false,
      };
    case GET_ORDERS_FAILED:
      return {
        ...state,
        ordersLoading: false,
      };
    case GET_ORDER:
      return {
        ...state,
        orderLoading: true,
        error: false,
      };
    case GET_ORDER_SUCCEED:
      return {
        ...state,
        order: action.order,
        orderLoading: false,
        error: false,
      };
    case GET_ORDER_FAILED:
      return {
        ...state,
        orderLoading: false,
        error: true,
      };
    case GET_SHOP_TOTALS:
      return {
        ...state,
        shopTotalsLoading: true,
      };
    case GET_SHOP_TOTALS_SUCCEED:
      return {
        ...state,
        shopTotals: action.data,
        shopTotalsLoading: false,
      };
    case GET_SHOP_TOTALS_FAILED:
      return {
        ...state,
        shopTotalsLoading: false,
      };

    case GET_TOTALS:
      return {
        ...state,
        totalsLoading: true,
      };
    case GET_TOTALS_SUCCEED:
      return {
        ...state,
        totals: action.data,
        totalsLoading: false,
      };
    case GET_TOTALS_FAILED:
      return {
        ...state,
        totalsLoading: false,
      };

    case GET_TOTAL_PROCESSED:
      return {
        ...state,
        totalProcessedLoading: true,
      };
    case GET_TOTAL_PROCESSED_SUCCEED:
      return {
        ...state,
        totalProcessed: action.amount,
        totalProcessedLoading: false,
      };
    case GET_TOTAL_PROCESSED_FAILED:
      return {
        ...state,
        totalProcessedLoading: false,
      };

    case GET_SHOP_TOTAL_PROCESSED:
      return {
        ...state,
        totalShopProcessedLoading: true,
      };
    case GET_SHOP_TOTAL_PROCESSED_SUCCEED: {
      let newShopReports = state.shopReports ? [ ...state.shopReports ] : [];
      const index = newShopReports.indexOf(({ guid }) => guid === action.guid);

      if (index >= 0) newShopReports[index].amount = action.amount;
      else
        newShopReports.push({
          guid: action.guid,
          amount: action.amount,
        });

      return {
        ...state,
        shopReports: newShopReports,
        totalShopProcessedLoading: false,
      };
    }
    case GET_SHOP_TOTAL_PROCESSED_FAILED:
      return {
        ...state,
        totalShopProcessedLoading: false,
      };
    case GET_TOTAL_TO_CLIENT:
      return {
        ...state,
        totalToClientLoading: true,
      };
    case GET_TOTAL_TO_CLIENT_SUCCEED:
      return {
        ...state,
        totalToClient: action.data,
        totalToClientLoading: false,
      };
    case GET_TOTAL_TO_CLIENT_FAILED:
      return {
        ...state,
        totalToClientLoading: false,
      };

    case GET_TOTAL_TO_PROCESSOR:
      return {
        ...state,
        totalToProcessorLoading: true,
      };
    case GET_TOTAL_TO_PROCESSOR_SUCCEED:
      return {
        ...state,
        totalToProcessor: action.data,
        totalToProcessorLoading: false,
      };
    case GET_TOTAL_TO_PROCESSOR_FAILED:
      return {
        ...state,
        totalToProcessorLoading: false,
      };

    case GET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionTypesLoading: true,
      };
    case GET_TRANSACTION_TYPE_SUCCEED:
      return {
        ...state,
        types: action.data,
        transactionTypesLoading: false,
      };
    case GET_TRANSACTION_TYPE_FAILED:
      return {
        ...state,
        transactionTypesLoading: false,
      };

    case GET_TRANSACTION_AMOUNT:
      return {
        ...state,
        transactionAmountLoading: true,
      };
    case GET_TRANSACTION_AMOUNT_SUCCEED:
      return {
        ...state,
        amount: action.data,
        transactionAmountLoading: false,
      };
    case GET_TRANSACTION_AMOUNT_FAILED:
      return {
        ...state,
        transactionAmountLoading: false,
      };

    case GET_SHOP_TRANSACTION_TYPE:
      return {
        ...state,
        shopTransactionTypesLoading: true,
      };
    case GET_SHOP_TRANSACTION_TYPE_SUCCEED:
      return {
        ...state,
        typesTransactionsOfShop: action.data,
        shopTransactionTypesLoading: false,
      };
    case GET_SHOP_TRANSACTION_TYPE_FAILED:
      return {
        ...state,
        shopTransactionTypesLoading: false,
      };

    case GET_BALANCE:
      return {
        ...state,
        balanceLoading: true,
      };
    case GET_BALANCE_SUCCEED:
      return {
        ...state,
        balance: action.data,
        balanceLoading: false,
      };
    case GET_BALANCE_FAILED:
      return {
        ...state,
        balanceLoading: false,
      };
    case GET_TRANSACTION_HOLDS:
      return {
        ...state,
        holdsLoading: true,
        error: false,
      };
    case GET_TRANSACTION_HOLDS_SUCCEED:
      return {
        ...state,
        holdsList: action.data,
        holdsLoading: false,
        error: false,
      };
    case GET_TRANSACTION_HOLDS_FAILED:
      return {
        ...state,
        holdsLoading: false,
        error: true,
      };
    case ADD_TRANSACTION_HOLDS:
      return { ...state, hold: action.hold };
    case GET_REPORT_HOLDS:
      return {
        ...state,
        holdAmountLoading: true,
        error: false,
      };
    case GET_REPORT_HOLDS_SUCCEED:
      return {
        ...state,
        holdAmount: action.data,
        holdAmountLoading: false,
        error: false,
      };
    case GET_REPORT_HOLDS_FAILED:
      return {
        ...state,
        holdAmountLoading: false,
        error: true,
      };
    case GET_REPORT_SHOP_HOLDS:
      return {
        ...state,
        shopHoldsLoading: true,
        error: false,
      };
    case GET_REPORT_SHOP_HOLDS_SUCCEED:
      return {
        ...state,
        shopHolds: action.data,
        shopHoldsLoading: false,
        error: false,
      };
    case GET_REPORT_SHOP_HOLDS_FAILED:
      return {
        ...state,
        shopHoldsLoading: false,
        error: true,
      };

    case GET_MISMATCH_TRANSACTIONS:
      return {
        ...state,
        mismatchTransactionsLoading: true,
        error: false,
      };
    case GET_MISMATCH_TRANSACTIONS_SUCCEED:
      return {
        ...state,
        mismatchTransactions: action.data.data,
        count: action.data.count,
        mismatchTransactionsLoading: false,
        error: false,
      };
    case GET_MISMATCH_TRANSACTIONS_FAILED:
      return {
        ...state,
        mismatchTransactionsLoading: false,
        error: true,
      };

    case GET_MISMATCH_TRANSACTION:
      return {
        ...state,
        mismatchTransactionLoading: true,
        error: false,
      };
    case GET_MISMATCH_TRANSACTION_SUCCEED:
      return {
        ...state,
        mismatchTransaction: action.data,
        mismatchTransactionLoading: false,
        error: false,
      };
    case GET_MISMATCH_TRANSACTION_FAILED:
      return {
        ...state,
        mismatchTransactionLoading: false,
        error: true,
      };

    case GET_TRANSACTION_AND_COMMISSION_REPORT:
      return {
        ...state,
        transactionAndCommissionLoading: true,
        error: false,
      };
    case GET_TRANSACTION_AND_COMMISSION_REPORT_SUCCEED:
      return {
        ...state,
        transactionAndCommission: action.data,
        transactionAndCommissionLoading: false,
        error: false,
      };
    case GET_TRANSACTION_AND_COMMISSION_REPORT_FAILED:
      return {
        ...state,
        transactionAndCommissionLoading: false,
        error: true,
      };

    case GET_ADMIN_DASHBOARD_DATA:
      return {
        ...state,
        dashboardTotalsLoading: true,
        error: false,
      };
    case GET_ADMIN_DASHBOARD_DATA_SUCCEED:
      return {
        ...state,
        dashboardTotals: action.data,
        dashboardTotalsLoading: false,
        error: false,
      };
    case GET_ADMIN_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        dashboardTotalsLoading: false,
        error: true,
      };
    case GET_MERCHANTS_LIMIT_DATA:
      return {
        ...state,
        error: false,
      };
    case GET_MERCHANTS_LIMIT_DATA_SUCCEED:
      return {
        ...state,
        merchantLimits: action.data,
        error: false,
      };
    case GET_MERCHANTS_LIMIT_DATA_FAILED:
      return {
        ...state,
        error: true,
      };

    case GET_MERCHANTS_TOTALS_DATA:
      return {
        ...state,
        error: false,
      };
    case GET_MERCHANTS_TOTALS_DATA_SUCCEED:
      return {
        ...state,
        merchantTotals: action.data,
        error: false,
      };
    case GET_MERCHANTS_TOTALS_DATA_FAILED:
      return {
        ...state,
        error: true,
      };

    case GET_ERROR_REPORT:
      return {
        ...state,
        error: false,
      };
    case GET_ERROR_REPORT_SUCCEED:
      return {
        ...state,
        errorReport: action.data,
        error: false,
      };
    case GET_ERROR_REPORT_FAILED:
      return {
        ...state,
        error: true,
      };

    case GET_HISTOGRAM_REPORT:
      return {
        ...state,
        histogramReport: action.data,
      };
    case GET_GEOGRAPHIC_REPORT:
      return {
        ...state,
        geographicReport: action.data,
      };
    default:
      return state;
  }
}
