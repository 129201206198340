import {
  addShopTerminalAction,
  generateTerminalCredentialsAction,
  getShopAction,
  getShopTerminalsAction,
} from "actions/shops";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination/index";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table/index";
import ability from "config/ability";
import { parseResponse } from "helpers/parseResponse";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import swal from "sweetalert";
import Content from "views/Content";
import { getShopChangeHistoryAction } from "../../actions/reasons";
import Alert from "../UI/Alert";
import GatewayPropsEditor from "./GatewayProps/Editor";
import TerminalCreator from "./Terminal/TerminalCreator";
import TerminalEditor from "./Terminal/TerminalEditor";

class ShopDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTerminalsPage: 1,
      pageSize: 10,
      currentPageHistory: 1,
      pageSizeHistory: 10,
    };
  }

  columnsShopTerminals = [
    ability.can("READ", "SHOPTERMINALDETAIL") && {
      path: "guid",
      label: "Guid",
      key: "guid",
      content: (terminal) => (
        <Link
          className="link"
          to={{
            pathname: `/about/${this.props.match.params.id}/terminal/${terminal.guid}`,
          }}
        >
          {terminal.guid}
        </Link>
      ),
    },
    { path: "name", label: "Name" },
    { path: "gateway_name", label: "Gateway" },
    { path: "currency_code", label: "Currency" },
    {
      path: "payment_amount_limit",
      label: "Payment amount limit",
      content: (terminal) => `${terminal.payment_amount_limit / 100}`,
      align: "right",
    },
    {
      path: "monthly_amount_limit",
      label: "Monthly amount limit",
      content: (terminal) => `${terminal.monthly_amount_limit / 100}`,
      align: "right",
    },
    {
      key: "three_d",
      content: (terminal) => (
        <i
          className={
            terminal.three_d
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "3d",
      align: "center",
    },
    {
      key: "enabled",
      content: (terminal) => (
        <i
          className={
            terminal.enabled
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Enabled",
      align: "center",
    },
    {
      path: "antifraud_monitor_value",
      label: "Antifraud monitor value",
      align: "right",
    },
    {
      key: "antifraud_monitor",
      content: (terminal) => (
        <i
          className={
            terminal.antifraud_monitor
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Antifraud monitor",
      align: "center",
    },
    ability.can("READ", "TERMINALGATEWAYPROPS") && {
      label: "Properties",
      align: "center",
      content: (terminal) => (
        <Modal
          header="Properties"
          content={GatewayPropsEditor}
          contentProps={{
            gatewayGuid: terminal.gateway_guid,
            terminalGuid: terminal.guid,
          }}
          buttonName="Show"
          button={
            <button type="button" className="btn">
              Show
            </button>
          }
        />
      ),
    },

    ability.can("EXECUTE", "TERMINALGENERATECREDENTIALS") && {
      key: "generate",
      align: "center",
      content: (terminal) => (
        <i
          className="fas fa-sync icon blue"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "You want to generate a new secret and hash key",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willGenerate) => {
              if (willGenerate) {
                this.handleGenerate(terminal.guid);
                swal("Generated", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Generate new secret",
    },
    ability.can("EXECUTE", "SHOPTERMINALS") && {
      key: "edit",
      align: "center",
      content: (terminal) => (
        <Modal
          header="Edit terminal"
          button={
            <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
          }
          content={TerminalEditor}
          contentProps={{
            shopId: this.props.match.params.id,
            guid: terminal.guid,
            type: "terminal",
          }}
          dialogClassName="modal-creator"
        />
      ),
      label: "Edit",
    },

    ability.can("DELETE", "SHOPTERMINALS") && {
      key: "delete",
      align: "center",
      content: (terminal) => (
        <i
          className="far fa-trash-alt icon red"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below .",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDelete(terminal.guid, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
    },
  ];

  columnsHistory = [
    { path: "name", label: "Name", key: "name" },
    { path: "merchant_name", label: "Merchant", key: "merchant_name" },
    {
      key: "enabled",
      content: (shop) => (
        <i
          className={
            shop.enabled ? "icon-success green icon" : "icon-failed red icon"
          }
        />
      ),
      label: "Enabled",
      align: "center",
    },
    { path: "phone", label: "Phone", key: "phone" },
    { path: "email", label: "Email", key: "email" },
    { path: "url", label: "URL", key: "url" },
    { path: "risk_category", label: "Risk category", key: "risk_category" },
    { path: "note", label: "Note", key: "note" },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];

  componentDidMount = async () => {
    const token = getAuthData();
    const guid = this.props.match.params.id;
    if (token.userPayload.merchant) this.setState({ isMerchant: true });
    await this.props.getShopAction(guid);
    await this.props.getShopTerminalsAction(guid, 1, this.state.pageSize);
    ability.can("AUDIT", "SHOPS") &&
      (await this.props.getShopChangeHistoryAction(
        guid,
        1,
        this.state.pageSizeHistory
      ));
  };

  handleTerminalsPageChange = async (page) => {
    await this.props.getShopTerminalsAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      currentTerminalsPage: page,
    });
  };

  handleHistoryPageChange = async (page) => {
    await this.props.getShopChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.pageSizeHistory
    );
    this.setState({
      currentPageHistory: page,
    });
  };

  handleDelete = async (guid, reason) => {
    try {
      const data = {
        guid: guid,
        delete: "true",
        reason,
      };
      await this.props.addShopTerminalAction(this.props.match.params.id, data);
      await this.props.getShopTerminalsAction(
        this.props.match.params.id,
        1,
        this.state.pageSize
      );
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleGenerate = async (guid) => {
    try {
      await this.props.generateTerminalCredentialsAction(guid);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  getTerminalsPagedData = () => {
    const { terminalsCount } = this.props;
    let { pageSize } = this.state;
    const terminalsPagesCount = terminalsCount
      ? terminalsCount / pageSize + (1 && !!(terminalsCount % pageSize))
      : 0;
    return { terminalsPagesCount };
  };

  historygetPagedData = () => {
    const { shopHistoryCount } = this.props;
    let { pageSizeHistory } = this.state;
    const historyPagesCount =
      shopHistoryCount / pageSizeHistory +
      (1 && !!(shopHistoryCount % pageSizeHistory));
    return { historyPagesCount };
  };

  render() {
    const { currentTerminalsPage } = this.state;
    const { shop, shopHistory } = this.props;
    const shopTerminals = this.props.shopTerminals;
    const { terminalsPagesCount } = this.getTerminalsPagedData();
    const { currentPageHistory } = this.state;
    const { historyPagesCount } = this.historygetPagedData();
    if (this.props.shopLoading || this.props.shopTerminalsLoading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5">{shop.name}</Card.Header>
              <Card.Body>
                <Card.Title>Info</Card.Title>

                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>GUID:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{this.props.match.params.id}</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>display name on payment page:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.display_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>URL:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.url}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Risk category:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.risk_category}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Phone:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.phone}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Email:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.email}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Note:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.note}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Merchant:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.merchant_guid}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Created at:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>
                      {shop.created_at !== null
                        ? moment(shop.created_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Created by:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.created_by_username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Updated at:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>
                      {shop.updated_at !== null
                        ? moment(shop.updated_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Updated by:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shop.updated_by_username}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            {ability.can("READ", "SHOPTERMINALS") && (
              <Card>
                <Card.Body>
                  <Card.Title>Terminals</Card.Title>
                  {ability.can("EXECUTE", "SHOPTERMINALS") && (
                    <Modal
                      header="Create terminal"
                      content={TerminalCreator}
                      contentProps={{ guid: this.props.match.params.id }}
                      button={
                        <button
                          type="button"
                          className="btn btn-fill btn-primary"
                        >
                          Create terminal
                        </button>
                      }
                      dialogClassName="modal-creator"
                    />
                  )}
                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      <Table
                        columns={this.columnsShopTerminals}
                        data={shopTerminals}
                        keyPath="guid"
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={terminalsPagesCount}
                        currentPage={currentTerminalsPage}
                        onPageChange={this.handleTerminalsPageChange}
                        pageSize={this.state.pageSize}
                        count={this.props.terminalsCount}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            {ability.can("AUDIT", "SHOPS") && (
              <Card>
                <Card.Body>
                  <Card.Title>History</Card.Title>
                  {this.props.shopHistoryloading ? (
                    <Content>
                      <Spinner />
                    </Content>
                  ) : (
                    <Row>
                      <Col md={12} sm={12} xs={12}>
                        <Table
                          columns={this.columnsHistory}
                          data={shopHistory ? shopHistory : []}
                          disableSearch
                          disableSort
                        />
                        <Pagination
                          pagesCount={historyPagesCount}
                          currentPage={currentPageHistory}
                          onPageChange={this.handleHistoryPageChange}
                          pageSize={this.state.pageSizeHistory}
                          count={this.props.shopHistoryCount}
                        />
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            )}
          </Row>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    shop: state.shops.shop,
    shopTerminals: state.shops.shopTerminals,
    terminalsCount: state.shops.terminalsCount,
    shopLoading: state.shops.shopLoading,
    shopTerminalsLoading: state.shops.shopTerminalsLoading,
    shopHistory: state.reasons.shopHistory,
    shopHistoryloading: state.reasons.loading,
    shopHistoryCount: state.reasons.count,
  };
};

export default connect(mapStateToProps, {
  getShopAction,
  getShopTerminalsAction,
  addShopTerminalAction,
  generateTerminalCredentialsAction,
  getShopChangeHistoryAction,
})(ShopDetail);

ShopDetail.propTypes = {
  addShopTerminalAction: PropTypes.func,
  generateTerminalCredentialsAction: PropTypes.func,
  getShopAction: PropTypes.func,
  getShopChangeHistoryAction: PropTypes.func,
  getShopTerminalsAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  shop: PropTypes.object,
  shopHistory: PropTypes.array,
  shopHistoryCount: PropTypes.number,
  shopHistoryloading: PropTypes.bool,
  shopLoading: PropTypes.bool,
  shopTerminals: PropTypes.array,
  shopTerminalsLoading: PropTypes.bool,
  terminalsCount: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};
