import * as types from "../constants/actionTypes";

const initialState = {
  role_guid: "",
  rolesList: [],
  rolesWithLoginList: [],
  rolePrivileges: [],
  roleType: "",
  privilegesCount:0,
  rolesLoading: true,
  role: {
    guid: "",
    name: "",
    type: "",
    description: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: ""
  }
};

export default function roles(state = initialState, action) {
  let changedPrivileges = [];
  let rolesList = [];

  switch (action.type) {
    case types.ADD_ROLE:
      return { ...state, role_guid: action.role.guid };
    case types.UPDATE_ROLE:
      rolesList = state.rolesList;
      rolesList = rolesList.map(role => {
        if (role.guid === action.role.guid)
          return action.role;
        else
          return role;
      });
      rolesList = [ ...rolesList ];
      return { ...state, rolesList };
    case types.ADD_ROLE_PRIVILEGE:
      return { ...state, rolePrivileges: [ action.rolePrivilege ] };
    case types.UPSERT_ROLE_PRIVILEGES:
      changedPrivileges = action.data.privileges;
      rolesList = state.rolesList;
      rolesList.forEach(role => {
        if (role.guid === action.data.roleGuid) {
          changedPrivileges = changedPrivileges.forEach(privilege => {
            if (privilege.delete)
              role.rolePrivileges = role.rolePrivileges.filter(rolePrivilege => rolePrivilege.guid !== privilege.guid);
            else {
              let f = 0;
              role.rolePrivileges.forEach(rolePrivilege => {
                if (rolePrivilege.guid === privilege.guid) {
                  rolePrivilege = privilege;
                  f = 1;
                }
              });
              if (f === 0)
                role.rolePrivileges = [ ...role.rolePrivileges, role ];
            }
          });
        }
      });
      return { ...state, rolesList, changedPrivileges: [] };
    case types.GET_ROLE_PRIVILEGES:
      return {
        ...state,
        rolePrivilegesLoading: true
      };
    case types.GET_ROLE_PRIVILEGES_SUCCEED:
      return {
        ...state,
        rolePrivileges: action.data.data,
        privilegesCount: action.data.count,
        rolePrivilegesLoading: false
      };
    case types.GET_ROLE_PRIVILEGES_FAILED:
      return {
        ...state,
        rolePrivilegesLoading: false
      };
    case types.GET_ROLES:
      return {
        ...state,
        rolesLoading: true,
        error: false
      };
    case types.GET_ROLES_SUCCEED:
      return {
        ...state,
        rolesList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        rolesLoading: false,
        error: false
      };
    case types.GET_ROLES_FAILED:
      return {
        ...state,
        rolesLoading: true,
        error: true
      };
    case types.GET_ROLES_WITH_LOGIN:
      return {
        ...state,
        rolesLoading: true,
        error: false
      };
    case types.GET_ROLES_WITH_LOGIN_SUCCEED:
      return {
        ...state,
        rolesWithLoginList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        rolesLoading: false,
        error: false
      };
    case types.GET_ROLES_WITH_LOGIN_FAILED:
      return {
        ...state,
        rolesLoading: true,
        error: true
      };
    case types.GET_ROLE:
      return {
        ...state,
        roleLoading: true,
        error: false
      };
    case types.GET_ROLE_SUCCEED:
      return {
        ...state,
        role: action.role,
        roleType: action.role.type,
        roleLoading: false,
        error: false
      };
    case types.GET_ROLE_FAILED:
      return {
        ...state,
        roleLoading: false,
        error: true
      };
    case types.DELETE_ROLE: {
      let newRolesList = state.rolesList.filter(role => role.guid !== action.role.guid);
      return { ...state, rolesList: newRolesList };
    }

    case types.UPSERT_ROLE_CHANGED_PRIVILEGE: {
      let rolePrivileges = state.rolePrivileges;
      changedPrivileges = state.changedPrivileges;
      if (typeof (changedPrivileges) === "undefined")
        changedPrivileges = [];
      if (action.privilege.delete) {
        rolePrivileges = rolePrivileges.filter(privilege => privilege.guid !== action.privilege.guid);
        const newChangedPrivileges = changedPrivileges.filter(privilege => privilege.guid !== action.privilege.guid);
        if (newChangedPrivileges.length === changedPrivileges.length)
          changedPrivileges = [ ...changedPrivileges, action.privilege ];
        else
          changedPrivileges = newChangedPrivileges;
      }
      else {
        rolePrivileges = rolePrivileges.filter(privilege => !(privilege.guid === action.privilege.guid && privilege.delete));
        changedPrivileges = changedPrivileges.filter(privilege => !(privilege.guid === action.privilege.guid && privilege.delete));
        rolePrivileges = [ ...rolePrivileges, action.privilege ];
        changedPrivileges = [ ...changedPrivileges, action.privilege ];
      }
      return { ...state, rolePrivileges, changedPrivileges };
    }

    default:
      return state;
  }
}