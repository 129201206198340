import * as types from "../constants/actionTypes";

const initialState = {
  ratesList: [],
  rate_guid: "",
  rate: {},

  rateTemplatesList: [],
  template: {},
  rateRevisionFeesList: [],
  rateTemplateLoading: true,
  rateTemplatesLoading: true,
  rateRevisionFeesLoading: true,
  rateLoading: true,

  rateCurrentPage: 0,
  ratePageSize: 0,
  pageSize: 0,
  currentPage: 0,
};

export default function rates(state = initialState, action) {
  switch (action.type) {
    case types.GET_RATES:
      return {
        ...state,
        ratesLoading: true,
        error: false
      };
    case types.GET_RATES_SUCCEED:
      return {
        ...state,
        ratesList: action.data.data,
        count: action.data.count,
        rateCurrentPage: action.currentPage,
        ratePageSize: action.pageSize,
        ratesLoading: false,
        error: false
      };
    case types.GET_RATES_FAILED:
      return {
        ...state,
        ratesLoading: false,
        error: true
      };
    case types.GET_RATE:
      return {
        ...state,
        rateLoading: true,
        error: false
      };
    case types.GET_RATE_SUCCEED:
      return {
        ...state,
        rate: action.rate,
        rateLoading: false,
        error: false
      };
    case types.GET_RATE_FAILED:
      return {
        ...state,
        rateLoading: false,
        error: true
      };
    case types.EDIT_RATE: {
      let newRatesList = state.ratesList;
      newRatesList = newRatesList.map(rate => {
        if (rate.guid === action.rate.guid)
          return action.rate;
        else
          return rate;
      });
      return { ...state, ratesList: newRatesList };
    }
    case types.ADD_RATE:
      return { ...state, rate_guid: action.rate_guid };
    case types.GET_ONE_TIME_CHARGES:
      return {
        ...state,
        oneTimeLoading: true,
        error: false
      };
    case types.GET_ONE_TIME_CHARGES_SUCCEED:
      return {
        ...state,
        oneTimeCharges: action.data,
        oneTimeLoading: false,
        error: false
      };
    case types.GET_ONE_TIME_CHARGES_FAILED:
      return {
        ...state,
        oneTimeLoading: false,
        error: true
      };
    case types.GET_PERIODIC_CHARGES:
      return {
        ...state,
        periodicLoading: true,
        error: false
      };
    case types.GET_PERIODIC_CHARGES_SUCCEED:
      return {
        ...state,
        periodicCharges: action.data,
        periodicLoading: false,
        error: false
      };
    case types.GET_PERIODIC_CHARGES_FAILED:
      return {
        ...state,
        periodicLoading: false,
        error: true
      };
    case types.GET_CONDITIONAL_CHARGES:
      return {
        ...state,
        conditionalLoading: true,
        error: false
      };
    case types.GET_CONDITIONAL_CHARGES_SUCCEED:
      return {
        ...state,
        conditionalCharges: action.data,
        conditionalLoading: false,
        error: false
      };
    case types.GET_CONDITIONAL_CHARGES_FAILED:
      return {
        ...state,
        conditionalLoading: false,
        error: true
      };
    case types.ADD_ONE_TIME_CHARGE:
      return { ...state };
    case types.ADD_PERIODIC_CHARGE:
      return { ...state };
    case types.ADD_CONDITIONAL_CHARGE:
      return { ...state };
    case types.DELETE_RATE: {
      let newRatesList = state.ratesList.filter(rate => rate.shop_guid !== action.rate.shop_guid && rate.gateway_guid !== action.rate.gateway_guid && rate.currency_guid !== action.rate.currency_guid);
      return { ...state, ratesList: newRatesList };
    }
    case types.GET_RATE_TEMPLATES:
      return {
        ...state,
        rateTemplatesLoading: true,
        error: false
      };
    case types.GET_RATE_TEMPLATES_SUCCEED:
      return {
        ...state,
        rateTemplatesList: action.data.data,
        count: action.data.count,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        rateTemplatesLoading: false,
        error: false
      };
    case types.GET_RATE_TEMPLATES_FAILED:
      return {
        ...state,
        rateTemplatesLoading: false,
        error: true
      };

    case types.GET_RATE_TEMPLATE:
      return {
        ...state,
        rateTemplateLoading: true,
        error: false
      };
    case types.GET_RATE_TEMPLATE_SUCCEED:
      return {
        ...state,
        template: action.template,
        rateTemplateLoading: false,
        error: false
      };
    case types.GET_RATE_TEMPLATE_FAILED:
      return {
        ...state,
        rateTemplateLoading: false,
        error: true
      };
      // case types.EDIT_RATE: {
      //   let newRatesList = state.ratesList;
      //   newRatesList = newRatesList.map(rate => {
      //     if (rate.guid === action.rate.guid)
      //       return action.rate;
      //     else
      //       return rate;
      //   });
      //   return { ...state, ratesList: newRatesList };
      // }
    case types.ADD_RATE_TEMPLATE:
      return { ...state };


    case types.GET_RATE_TEMPLATE_FEES:
      return {
        ...state,
        rateTemplateFeesLoading: true,
        error: false
      };
    case types.GET_RATE_TEMPLATE_FEES_SUCCEED:
      return {
        ...state,
        rateTemplateFeesList: action.data.data,
        count: action.data.count,
        // currentPage: action.currentPage,
        // pageSize: action.pageSize,
        rateTemplateFeesLoading: false,
        error: false
      };
    case types.GET_RATE_TEMPLATE_FEES_FAILED:
      return {
        ...state,
        rateTemplateFeesLoading: false,
        error: true
      };

    case types.GET_RATE_REVISIONS:
      return {
        ...state,
        rateRevisionsLoading: true,
        error: false
      };
    case types.GET_RATE_REVISIONS_SUCCEED:
      return {
        ...state,
        rateRevisionsList: action.data.data,
        count: action.data.count,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        rateRevisionsLoading: false,
        error: false
      };
    case types.GET_RATE_REVISIONS_FAILED:
      return {
        ...state,
        rateRevisionsLoading: false,
        error: true
      };

    case types.GET_RATE_REVISION_FEES:
      return {
        ...state,
        rateRevisionFeesLoading: true,
        error: false
      };
    case types.GET_RATE_REVISION_FEES_SUCCEED:
      return {
        ...state,
        rateRevisionFeesList: action.data.data,
        count: action.data.count,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        rateRevisionFeesLoading: false,
        error: false
      };
    case types.GET_RATE_REVISION_FEES_FAILED:
      return {
        ...state,
        rateRevisionFeesLoading: false,
        error: true
      };

    default:
      return state;
  }
}