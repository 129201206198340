import React, { Component } from "react";
import { connect } from "react-redux";
import { getTotalProcessedAction } from "../../actions/transactions";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import { parseString } from "helpers/parseString";

class ShopTotalProcessed extends Component {
  state = {
    totalProcessed: "",
    daysCount: 7,
  };

  componentDidMount = async () => {
    await this.props.getTotalProcessedAction({
      days: this.state.daysCount,
      shop_guid: this.props.shopGuid,
    });
    const { shopReports } = this.props;

    const [ shop ] = shopReports
      ? shopReports.length
        ? shopReports.filter(({ guid }) => guid === this.props.shopGuid)
        : []
      : [];
    const amount = shop === undefined ? "" : shop.amount;
    this.setState({ totalProcessed: amount });

    let { totalProcessed } = this.state;
    const totalLength = amount ? amount.length : 0;

    const total = parseString(totalProcessed, totalLength);

    this.setState({
      totalProcessed: total,
    });
  };

  render() {
    const { loading, shopReports, classname } = this.props;
    const { totalProcessed } = this.state;
    const [ shop ] = shopReports
      ? shopReports.length
        ? shopReports.filter(({ guid }) => guid === this.props.shopGuid)
        : []
      : [];

    if (loading) return <Spinner />;
    else
      return (
        <div>
          <div className="header">Processed transactions amount</div>
          <div className={classname}>€ {shop ? totalProcessed : 0}</div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    shopReports: state.transactions.shopReports,
    loading: state.transactions.totalShopProcessedLoading,
  };
};

export default connect(mapStateToProps, {
  getTotalProcessedAction,
})(ShopTotalProcessed);

ShopTotalProcessed.propTypes = {
  classname: PropTypes.string,
  getTotalProcessedAction: PropTypes.func,
  loading: PropTypes.bool,
  shopGuid: PropTypes.string,
  shopReports: PropTypes.array,
};
