import GlobalBlacklistCreator from "components/BlackList/Global/Creator";
import GlobalBlackListMain from "components/BlackList/Global/Main";
// import Can from "components/Can";
import Modal from "components/UI/Modal";
import React from "react";
import Content from "../../Content";
import ability from "config/ability";

const GlobalBlackList = () => {
  return (
    <Content>
      <GlobalBlackListMain
        modalComponent={
          // <Can do="EXECUTE" on="GLOBALBLACKLISTS">
          <Modal
            allowed={ability.can("EXECUTE", "GLOBALBLACKLISTS")}
            header="Create global item"
            content={GlobalBlacklistCreator}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create item
              </button>
            }
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default GlobalBlackList;
