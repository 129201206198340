import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Table from "components/UI/Table/index";
import PropTypes from "prop-types";

class HistoryParamsDetail extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,
  };

  columnsParams = [
    { path: "name", label: "Name" },
    { path: "value", label: "Value" },
  ];

  async componentDidMount() {
    this.setState({
      params: this.props.params,
    });
  }

  render() {
    const params = this.props.params;

    return (
      <>

        <Card>
          <Card.Header as="h5">
            Step: {this.props.step_guid}
          </Card.Header>
          <Card.Body>
            <Card.Title>Params</Card.Title>


            <Table
              columns={this.columnsParams}
              data={params}
              disableSearch
              disableSort
            />
          </Card.Body>
        </Card>

      </>
    );
  }
}

export default HistoryParamsDetail;

HistoryParamsDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  params: PropTypes.array,
  step_guid: PropTypes.string,
};
