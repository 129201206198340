import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getRateAction,
  getAllRateRevisionsAction,
  addRateRevisionAction,
} from "actions/rates";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Content from "views/Content";
import ability from "config/ability";
import swal from "sweetalert";
import Pagination from "components/UI/Pagination/index";
import Table from "components/UI/Table";
import RateRevisionCreator from "./Revisions/Creator";
import Modal from "components/UI/Modal";
import { parseResponse } from "helpers/parseResponse";
import { Link } from "react-router-dom";

class RateDetail extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,
  };

  columns = [
    {
      path: "guid",
      label: "Guid",
      key: "guid",
      content: (revision) => (
        <Link
          className="link"
          to={`/about/rate/${revision.rate_guid}/revision/${revision.guid}`}
        >
          {revision.guid}
        </Link>
      ),
    },
    {
      path: "activation_date",
      label: "activation date",
      key: "activation_date",
    },
    { path: "currency_code", label: "currency", key: "currency_code" },
    { path: "hold_percent", label: "hold percent", key: "hold_percent" },
    { path: "hold_days", label: "hold days", key: "hold_days" },
    { path: "connection_fee", label: "Connection fee", key: "connection_fee" },
    {
      path: "terminal_registration_fee",
      label: "Terminal registration fee",
      key: "terminal_registration_fee",
    },
    ability.can("DELETE", "RATES") && {
      key: "delete",
      align: "center",
      content: (fee) => (
        <i
          className="far fa-trash-alt  icon red"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDelete(fee.guid, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
    },
  ];

  async componentDidMount() {
    await this.props.getRateAction(this.props.match.params.id);
    await this.props.getAllRateRevisionsAction(this.props.match.params.id, {
      page: 1,
      items: this.state.pageSize,
    });
  }

  updateRevisions = async () => {
    await this.props.getAllRateRevisionsAction(this.props.match.params.id, {
      page: 1,
      items: this.state.pageSize,
    });
  };

  handleDelete = async (guid, reason) => {
    try {
      await this.props.addRateRevisionAction(this.props.match.params.id, {
        guid: guid,
        delete: true,
        reason,
      });
      await this.props.getAllRateRevisionsAction(this.props.match.params.id);
    } catch (error) {
      const parsedError = parseResponse(error);
      swal({
        title: parsedError.error,
        text: parsedError.message,
        icon: "error",
      });
    }
  };

  handlePageChange = async (page) => {
    await this.props.getAllRateRevisionsAction(this.props.match.params.id, {
      page,
      items: this.state.pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  getPagedData = () => {
    const { count } = this.props;
    let { pageSize } = this.state;
    const pagesCount = count
      ? count / pageSize + (1 && !!(count % pageSize))
      : 0;
    return { pagesCount };
  };

  render() {
    const revisions = this.props.revisions;
    const { pagesCount } = this.getPagedData();

    if (this.props.rateLoading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Card>
            <Card.Body>
              <Card.Title>General Info</Card.Title>
              <Row>
                <Col className="right" md={5} lg={5} xs={5}>
                  <label>Guid: </label>
                </Col>
                <Col md={7} lg={7} xs={7}>
                  <span>{this.props.match.params.id}</span>
                </Col>
              </Row>
              <Row>
                <Col className="right" md={5} lg={5} xs={5}>
                  <label>Name: </label>
                </Col>
                <Col md={7} lg={7} xs={7}>
                  <span>{this.props.rate.name}</span>
                </Col>
              </Row>
              <Row>
                <Col className="right" md={5} lg={5} xs={5}>
                  <label>Gateway: </label>
                </Col>
                <Col md={7} lg={7} xs={7}>
                  <span>{this.props.rate.gateway_name}</span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Revisions</Card.Title>

              {this.props.rateRevisionsLoading ? (
                <Content>
                  <Spinner />
                </Content>
              ) : (
                <React.Fragment>
                  <Table
                    columns={this.columns}
                    data={revisions ? revisions : []}
                    keyPath="guid"
                    disableSearch={true}
                    disableSort={true}
                  />
                  <Pagination
                    pagesCount={pagesCount}
                    currentPage={this.state.currentPage}
                    onPageChange={this.handlePageChange}
                    pageSize={this.state.pageSize}
                    count={this.props.count}
                  />
                  <Modal
                    header="Create revision"
                    button={<button className="btn btn-fill">Create</button>}
                    content={RateRevisionCreator}
                    contentProps={{
                      guid: this.props.match.params.id,
                      updateRevisions: this.updateRevisions,
                    }}
                  />
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    rate: state.rates.rate,
    rateLoading: state.rates.rateLoading,
    revisions: state.rates.rateRevisionsList,
    count: state.rates.count,
    rateRevisionsLoading: state.rates.rateRevisionsLoading,
  };
};

export default connect(mapStateToProps, {
  getRateAction,
  getAllRateRevisionsAction,
  addRateRevisionAction,
})(RateDetail);

RateDetail.propTypes = {
  addRateRevisionAction: PropTypes.func,
  count: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  getAllRateRevisionsAction: PropTypes.func,
  getRateAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      gateway_id: PropTypes.string,
      currency_id: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  rate: PropTypes.object,
  rateLoading: PropTypes.bool,
  rateRevisionsLoading: PropTypes.bool,
  revisions: PropTypes.array,
};
