import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "components/UI/Table/index";
import Pagination from "components/UI/Pagination/index";
import { connect } from "react-redux";
import { getOrdersAction } from "actions/transactions";
import Spinner from "components/UI/Spinner/index";
import moment from "moment";
import PropTypes from "prop-types";
import { Export } from "components/Export";
import { getOrders } from "../../../services/paymentBackendAPI/reports/transactions";

export class Orders extends Component {
  state = {
    reportOrders: [],
    count: "",
    currentPage: 1,
    pageSize: 10,
    data: {
      merchant_guid_array: [],
      group_guid_array: [],
      partner_guid_array: [],
      terminal_guid_array: [],
      shop_guid_array: [],
      from_date: moment()
        .format("YYYY-MM-DDTHH:mm:ss")
        .replace(/\D\d{2}\w/, "-01T"),
      to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    },
  };

  columnsOrders = [
    { path: "guid", label: "ID" },
    { path: "status", label: "Status" },
    {
      path: "date",
      label: "Date",
      content: (order) =>
        moment(order.date).utcOffset(0).format("DD.MM.YYYY HH:mm"),
    },
    { path: "type", label: "Type" },
    { path: "amount", label: "Amount" },
    { path: "currency", label: "Currency" },
    { path: "card_number", label: "Card" },
    { path: "card_schema", label: "Card schema" },
    { path: "card_holder", label: "Card holder" },
    { path: "description", label: "Description" },
  ];

  async componentDidMount() {
    await this.props.getOrdersAction({
      page: 1,
      items: this.state.pageSize,
      ...this.state.data,
    });
    this.setState({
      reportOrders: this.props.reportOrders,
      count: this.props.count,
    });
  }

  shouldComponentUpdate = async (nextProps) => {
    if (nextProps.data !== this.props.data) {
      await this.props.getOrdersAction({
        page: 1,
        items: this.state.pageSize,
        ...nextProps.data,
      });
    }
    return true;
  };

  handlePageChange = async (page) => {
    await this.props.getOrdersAction({
      page,
      items: this.state.pageSize,
      ...this.props.data,
    });
    this.setState({
      reportOrders: this.props.reportOrders,
      count: this.props.count,
      currentPage: page,
    });
  };

  getPagedData = () => {
    const { count } = this.props;
    let { pageSize } = this.state;
    const pagesCount = count
      ? count / pageSize + (1 && !!(count % pageSize))
      : 0;
    return { pagesCount };
  };

  // handleTimeChange = (event) => {
  //   let value = event.target.value;
  //   value = moment(value).format("YYYY-MM-DDTHH:mm:ss");
  //   this.setState({ [event.target.name]: value });
  // }

  handleClick = () => {
    let data = this.getSearchData();
    this.setState({ data: data }, () => {
      this.props.getOrdersAction({
        page: 1,
        items: this.state.pageSize,
        from_date: this.state.data.from_date,
        to_date: this.state.data.to_date,
      });
    });
  };

  render() {
    const { currentPage } = this.state;
    const { pagesCount } = this.getPagedData();
    return (
      <div className="card-for-basic-report">
        <Row>
          <Col md={6} sm={6} xs={6}>
            {/* <label style={{ marginRight: "5px" }}>Date range</label> */}
            {/* <DateRangePicker
              onCallback={this.onDateChange}
              initialSettings={{
                // startDate:  moment(this.state.from_date).format("DD.MM.YYYY"),
                // endDate: this.props.currentSearchData.to_date ? moment(this.props.currentSearchData.to_date).format("DD.MM.YYYY") : undefined,
                locale: {
                  format: "DD.MM.YYYY",
                },
              }}
            >
              <input
                type="text"
                className="text-input daterange-input form-control"
              />
            </DateRangePicker> */}
          </Col>
          {/* <Col md={3} sm={3} xs={3}>
            <label style={{ marginRight: "5px" }}>to</label>
            <input
              type="date"
              className="datetimepicker"
              name="toDate"
              onChange={this.handleTimeChange} >
            </input>

          </Col> */}
          <Col md={3} sm={3} xs={3}>
            {/* <CustomButton className="btn" onClick={this.handleClick}>
              Find
            </CustomButton> */}
          </Col>
          <Col md={3} sm={3} xs={3}>
            <Export
              id={"totals"}
              // searchData={this.getSearchData()}
              exportFunction={getOrders}
              style={{ float: "right" }}
              columns={this.columnsOrders}
              name={"BasicReportOrders"}
            />
          </Col>
        </Row>
        {this.props.reportOrdersLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="content" style={{ overflow: "auto" }}>
              <Row>
                <Col md={12}>
                  <div className="table">
                    <Table
                      columns={this.columnsOrders}
                      data={this.props.reportOrders}
                      disableSearch
                      disableSort
                    />
                    <Pagination
                      pagesCount={pagesCount}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                      pageSize={this.state.pageSize}
                      count={this.props.count}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reportOrders: state.transactions.orders,
    count: state.transactions.ordersCount,
    reportOrdersLoading: state.transactions.ordersLoading,
  };
};

export default connect(mapStateToProps, {
  getOrdersAction,
})(Orders);

Orders.propTypes = {
  count: PropTypes.number,
  getOrdersAction: PropTypes.func,
  reportOrders: PropTypes.array,
  reportOrdersLoading: PropTypes.bool,
};
