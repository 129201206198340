import {
  POP_ITEM_HISTORY, POP_LOCATION,
  PUSH_ITEM_HISTORY, PUSH_LOCATION,
  RESET_HISTORY
} from "../constants/actionTypes";

const initialState = {
  history: [ "/dashboard" ],
  navHistory: [ { location: "/dashboard", name: "Dashboard" } ]

};
let arrayHistory = [];

export default function history(state = initialState, action) {
  switch (action.type) {
    case PUSH_LOCATION:
      arrayHistory = state.history;
      arrayHistory.push(action.payload);
      return {
        ...state,
        history: arrayHistory
      };
    case POP_LOCATION:
      arrayHistory = state.history;
      arrayHistory.pop();
      return {
        ...state,
        history: arrayHistory
      };

    case POP_ITEM_HISTORY:
      arrayHistory = state.navHistory;
      arrayHistory.pop();
      return {
        ...state,
        navHistory: arrayHistory
      };
    case PUSH_ITEM_HISTORY: {
    // arrayHistory = state.navHistory;
    // arrayHistory.push({ name: action.name, location: action.location });
      let flag = false;
      state.navHistory.forEach(item => {
        if ( JSON.stringify(item) === JSON.stringify({ name: action.name, location: action.location }) ) {
          flag = true;
        }
      });
      return {
        ...state,
        navHistory: flag ? state.navHistory : [ ...state.navHistory, { name: action.name, location: action.location } ]
      };
    }
    case RESET_HISTORY:
      return {
        ...state,
        navHistory: action.parentName !== "" ? [ { name: action.parentName, location: "" }, { name: action.name, location: action.location } ] : [ { name: action.name, location: action.location } ]
      };

    default: return state;
  }
}