import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import PropTypes from "prop-types";

export const HorizontalBarChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  return (
    <>
      <HorizontalBar
        data={data}
        height={props.height}
        options={props.options}
      />
    </>
  );
};

HorizontalBarChart.propTypes = {
  datasets: PropTypes.array,
  height: PropTypes.number,
  labels: PropTypes.array,
  options: PropTypes.object,
};
