import React from "react";
import Content from "../Content";
import OrdersMain from "components/Orders/Main";

const Orders = () => {
  return (
    <Content>
      <OrdersMain />
    </Content>
  );
};

export default Orders;
