export const parseString = (str, length) => {
  switch (length) {
    case 0: {
      str = "0";
      break;
    }
    case 1: {
      str = "0.0" + str;
      break;
    }
    case 2: {
      str = "0." + str;
      break;
    }
    default: {
      str = str.slice(0, length - 2) + "." + str.slice(length - 2);
      break;
    }
  }
  return str;
};
