import React from "react";
import { connect } from "react-redux";
import { HorizontalBarChart } from "../UI/HorizontalBarChart";
import { getTopPartnersAction } from "actions/partners";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";

class HorizontalBarChartTopPartners extends React.Component {
  state = {
    daysCount: 7,
    topPartnersCount: 10,
    loading: true,
  };

  componentDidMount = () => {
    if (this.props.daily)
      this.props.getTopPartnersAction(1, this.state.topPartnersCount);
    else
      this.props.getTopPartnersAction(
        this.state.daysCount,
        this.state.topPartnersCount
      );
  };

  render() {
    if (this.props.loading) return <Spinner />;
    else {
      const labelsPie = this.props.topPartners.map((partner) => partner.name);

      const datasetsPie = [
        {
          label: "transactions",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.props.topPartners.map((partner) => partner.count),
        },
      ];

      return (
        <div>
          <HorizontalBarChart
            name="Top partners"
            labels={labelsPie}
            datasets={datasetsPie}
            options={{
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
            height={50}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    topPartners: state.partners.topPartners,
    loading: state.partners.topPartnersLoading,
  };
};

export default connect(mapStateToProps, {
  getTopPartnersAction,
})(HorizontalBarChartTopPartners);

HorizontalBarChartTopPartners.propTypes = {
  daily: PropTypes.string,
  getTopPartnersAction: PropTypes.func,
  loading: PropTypes.bool,
  topPartners: PropTypes.array,
};
