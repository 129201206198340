import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reset, searchInOrders } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import { getOrdersAction } from "../../actions/transactions";
import AbstractComponent from "../../factories/Main";
import { cutGuid } from "../../helpers/cutGuid";
import { getOrders } from "../../services/paymentBackendAPI/reports/transactions";
import { Copy } from "../UI/CopyToClipboard";

// const isAdmin = localStorage.getItem("isAdmin");

let columns = [
  {
    path: "guid",
    label: "ID",
    key: "guid",
    content: (order) => (
      <>
        <Copy text={order.guid} />
        <Link className="link" to={`/about/order/${order.guid}`}>
          {cutGuid(order.guid)}
        </Link>
      </>
    ),
    textContent: (order) => order.guid,
    style: { minWidth: "120px" },
    colWidth: 7,
  },
  {
    path: "status",
    label: "Status",
    key: "orderStatus",
    content: (order) => (
      <Badge className={`badge-order-${order.status.toLowerCase()}`}>
        {order.status}
      </Badge>
    ),
    textContent: (order) => order.status,
    align: "center",
    colWidth: 7,
  },
  {
    path: "date",
    label: "Date",
    content: (order) => moment(order.date).format("DD.MM.YYYY | HH:mm"),
    align: "center",
    // style: { minWidth: "120px" },
    colWidth: 12,
  },
  { path: "type", label: "Type", key: "orderTransactionType", colWidth: 9 },
  {
    path: "payment_status",
    label: "Payment Status",
    key: "paymentStatus",
    content: (order) => (
      <Badge className={`badge-order-${order.payment_status}`}>
        {order.payment_status}
      </Badge>
    ),
    textContent: (order) => order.payment_status,
    align: "center",
    colWidth: 6,
  },
  {
    path: "amount",
    key: "amount",
    label: "Amount",
    content: (order) => order.amount,
    align: "right",
    colWidth: 6,
  },
  {
    path: "currency",
    label: "Currency",
    key: "currency",
    align: "center",
    colWidth: 5,
  },
  {
    path: "card_schema",
    label: "Card schema",
    key: "card_schema_order",
    colWidth: 6,
  },
  { path: "card_holder", label: "Card holder", colWidth: 6 },
  {
    path: "card_number",
    label: "Credit card",
    content: (num) =>
      num.card_number.substr(0, 3) +
      "****" +
      num.card_number.substr(num.card_number.length - 4, 4),
    colWidth: 7,
  },
  {
    path: "merchant_name",
    label: "merchant name",
    key: "merchant",
    colWidth: 6,
    content: (order) => (
      <>
        <Link className="link" to={`/about/merchant/${order.merchant_guid}`}>
          {order.merchant_name}
        </Link>
      </>
    ),
    textContent: (order) => order.merchant_name,
  },
  {
    path: "shop_name",
    label: "Shop",
    key: "shop",
    colWidth: 6,
    content: (order) => (
      <>
        <Link className="link" to={`/about/shop/${order.shop_guid}`}>
          {order.shop_name}
        </Link>
      </>
    ),
    textContent: (order) => order.shop_name,
  },
  {
    path: "tracking_id",
    label: "merchant Tracking ID",
    key: "tracking_id",
    colWidth: 7,
  },
  // {
  //   path: "hold_flag",
  //   label: "Approve",
  //   key: isAdmin ? "approveAdmin" : "approve",
  //   align: "center",
  //   content: (order) =>
  //     order.hold_flag === null &&
  //     order.approve_flag === null &&
  //     order.hold_date === null ? null : order.hold_flag &&
  //       !order.approve_flag ? (
  //       <span>Approved</span>
  //     ) : (
  //       <span>{moment(order.hold_date).utcOffset(7).format("DD.MM.YY")}</span>
  //     ),
  //   textContent: (order) =>
  //     order.hold_flag === null &&
  //     order.approve_flag === null &&
  //     order.hold_date === null
  //       ? null
  //       : order.hold_flag && !order.approve_flag
  //       ? "Approved"
  //       : moment(order.hold_date).utcOffset(7).format("DD.MM.YY"),
  //   colWidth: 6,
  // },
];

const searchColumns = [
  {
    key: "ip",
    label: "Ip"
  },
  {
    key: "bank_id",
    label: "Bank Id"
  },
  {
    key: "arn",
    label: "ARN"
  }
];

const mapStateToProps = (state) => {
  return {
    data: state.transactions.orders,
    count: state.transactions.ordersCount,
    table: state.store.table,
    loading: state.transactions.ordersLoading,
    searchData: state.search.ordersSearch,
    isSearch: state.search.isSearch,
    name: "orders",
    keyPath: "guid",
    columns,
    // columnsComponent: createColComponentForTable(columns),
    searchColumns,
    notAllSizePage: true,
    exportFunction: getOrders,
  };
};

export default connect(mapStateToProps, {
  get: getOrdersAction,
  search: searchInOrders,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
