import * as types from "../constants/actionTypes";

const initialState = {
  gateway_guid: "",
  gatewaysList: [],
  gatewayProps: [],
  gateway: {
    guid: "",
    name: "",
    description: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: ""
  },
  gatewaysLoading:false,
  gatewayLoading:false,
  propsLoading: false,
  error:false
};

export default function gateways(state = initialState, action) {
  switch (action.type) {
    case types.ADD_GATEWAY:
      return {
        ...state,
        gatewaysList: [ ...state.gatewaysList, action.gateway ],
        gateway_guid: action.gateway.guid
      };
    case types.GET_GATEWAYS:
      return {
        ...state,
        gatewaysLoading: true,
        error: false
      };
    case types.GET_GATEWAYS_SUCCEED:
      return {
        ...state,
        gatewaysList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        gatewaysLoading: false,
        error: false
      };
    case types.GET_GATEWAYS_FAILED:
      return {
        ...state,
        gatewaysLoading: false,
        error: true
      };
    case types.GET_GATEWAY:
      return {
        ...state,
        gateway:{},
        gatewayLoading: true,
        error: false
      };
    case types.GET_GATEWAY_SUCCEED:
      return {
        ...state,
        gateway: action.gateway,
        gatewayLoading: false,
        error: false
      };
    case types.GET_GATEWAY_FAILED:
      return {
        ...state,
        gatewayLoading: false,
        error: true
      };
    case types.EDIT_GATEWAY: {
      let newGatewaysList = state.gatewaysList;
      newGatewaysList = newGatewaysList.map(gateway => {
        if (gateway.guid === action.gateway.guid)
          return action.gateway;
        else
          return gateway;
      });
      return { ...state, gatewaysList: newGatewaysList };
    }
    case types.DELETE_GATEWAY:{
      let newGatewaysList = state.gatewaysList.filter(gateway => gateway.guid !== action.gateway.guid);
      return { ...state, gatewaysList: newGatewaysList };}
    case types.GET_GATEWAY_PROPS:
      return { 
        ...state, 
        propsLoading: true, 
      };
    case types.GET_GATEWAY_PROPS_SUCCEED:{
      const properties = action.data.map( (item, index) => {
        return { ...item, number: index+1 };
      });
      return { 
        ...state, 
        propsLoading: false,
        gatewayProps: properties,
        gatewayPropsCount: action.count
      };
    }
    case types.GET_GATEWAY_PROPS_FAILED:
      return { 
        ...state, 
        propsLoading: false, 
      };
    default:
      return state;
  }
}
