import { backendManagement } from "../backendPlatform";

export const getPrivileges = (args) => {
  return backendManagement.get("/privileges", { params: { ...args } });
};

export const createPrivilege = data => {
  return backendManagement.post("/privileges", data);
};

export const updatePrivilege = data => {
  return backendManagement.post("/privileges", data);
};

export const getPrivilege = guid => {
  return backendManagement.get(`/privileges/${guid}`);
};

export const deletePrivilege = ({ guid, reason }) => {
  return backendManagement.post("/privileges", { guid, delete: true, reason });
};