import React from "react";
import PartnerMain from "components/Partner/Main";
import Content from "../Content";
import Modal from "components/UI/Modal";
// import Can from "components/Can";
import PartnerCreator from "components/Partner/Creator";
import ability from "config/ability";

const Partner = () => {
  return (
    <Content>
      <PartnerMain
        modalComponent={
          // <Can do="EXECUTE" on="USERPARTNER">
          <Modal
            allowed={ability.can("EXECUTE", "USERPARTNER")}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create partner
              </button>
            }
            content={PartnerCreator}
            header="Create partner"
            dialogClassName="modal-creator"
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default Partner;
