import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getRoleAction,
  getRolePrivilegesAction,
  upsertChangedPrivilegeAction,
} from "../../actions/roles";
import Spinner from "components/UI/Spinner";

class RolePrivilegesManagement extends Component {
  state = {
    roleGuid: "",
    roleType: "",
    allPrivileges: [],
    rolePrivileges: [],
    notRolePrivileges: [],
    changedPrivileges: [],
    showMore: false,
    filteredRolePrivileges: [],
    filteredNotRolePrivileges: [],
    query: "",
  };

  componentDidMount = async () => {
    const allPrivileges = this.props.privileges;
    const rolePrivileges = this.props.rolePrivileges;
    let notRolePrivileges;
    this.setState({ allPrivileges, roleGuid: this.props.role_guid });
    if (!rolePrivileges || rolePrivileges.length === 0) {
      this.setState({
        showMore: true,
        filteredNotRolePrivileges: allPrivileges,
        notRolePrivileges: allPrivileges,
      });
    } else {
      let rolePrivilegesGuids = [];
      for (let i = 0; i < rolePrivileges.length; i += 1) {
        rolePrivilegesGuids = [ ...rolePrivilegesGuids, rolePrivileges[i].guid ];
      }
      notRolePrivileges = allPrivileges.filter(
        (privilege) => !rolePrivilegesGuids.includes(privilege.guid)
      );
      this.setState({
        rolePrivileges,
        notRolePrivileges,
        filteredRolePrivileges: rolePrivileges,
        filteredNotRolePrivileges: notRolePrivileges,
      });
    }
  };

  handleDelete = async (e) => {
    const guid = e.target.id;
    const rolePrivileges = this.state.rolePrivileges.filter(
      (privilege) => privilege.guid !== guid
    );
    const privilege = this.state.rolePrivileges.filter(
      (privilege) => privilege.guid === guid
    );
    const notRolePrivileges = [ privilege[0], ...this.state.notRolePrivileges ];
    this.setState({
      notRolePrivileges,
      rolePrivileges,
    });
    const newDel = {
      guid,
      delete: true,
    };
    this.setState({
      changedPrivileges: [ ...this.state.changedPrivileges, newDel ],
    });
    await this.props.upsertChangedPrivilegeAction(newDel);
    this.filterPrivileges(this.state.query);
  };

  handleAdd = async (e) => {
    const guid = e.target.id;
    const notRolePrivileges = this.state.notRolePrivileges.filter(
      (privilege) => privilege.guid !== guid
    );
    const privilege = this.state.notRolePrivileges.filter(
      (privilege) => privilege.guid === guid
    );
    const rolePrivileges = [ ...this.state.rolePrivileges, privilege[0] ];
    this.setState({
      notRolePrivileges,
      rolePrivileges,
    });
    const newAdd = {
      guid,
    };
    this.setState({
      changedPrivileges: [ ...this.state.changedPrivileges, newAdd ],
    });
    await this.props.upsertChangedPrivilegeAction(newAdd);
    this.filterPrivileges(this.state.query);
  };

  handleMore = () => {
    this.setState({
      showMore: true,
    });
  };

  filterPrivileges = (query) => {
    const filteredRolePrivileges = this.state.rolePrivileges.filter(
      (privilege) => {
        return privilege.name.toLowerCase().includes(query.toLowerCase());
      }
    );
    const filteredNotRolePrivileges = this.state.notRolePrivileges.filter(
      (privilege) => {
        return privilege.name.toLowerCase().includes(query.toLowerCase());
      }
    );
    if (!query) {
      this.setState({
        query,
        filteredRolePrivileges: this.state.rolePrivileges,
        filteredNotRolePrivileges: this.state.notRolePrivileges,
      });
    } else {
      this.setState({
        query,
        filteredRolePrivileges,
        filteredNotRolePrivileges,
      });
    }
  };

  handleSearch = (event) => {
    const query = event.target.value;
    this.filterPrivileges(query);
  };

  render() {
    const {
      filteredRolePrivileges,
      filteredNotRolePrivileges,
      showMore,
    } = this.state;
    let row = [];
    if (
      this.props.rolePrivilegesLoading ||
      this.props.privilegesLoading ||
      this.props.roleLoading
    )
      return <Spinner />;
    else if (
      filteredRolePrivileges.length === 0 &&
      filteredNotRolePrivileges.length === 0
    ) {
      const note = (
        <div>
          <n />
          <label htmlFor="">There are no privileges</label>
        </div>
      );

      return (
        <div>
          <div className="search-box">
            <form className="form-inline md-form form-sm mt-0">
              <input
                className="form-control form-control-sm ml-3 w-75"
                type="text"
                placeholder="Search"
                aria-label="Search"
                value={this.state.query}
                onChange={this.handleSearch}
              />
              {"    "}
              <i className="fas fa-search" aria-hidden="true"></i>
            </form>
          </div>
          <ul className="list-group">{note}</ul>
        </div>
      );
    }

    filteredRolePrivileges.forEach((item, index) => {
      row = [
        ...row,
        <li
          className="list-group-item"
          style={{ textAlign: "right", backgroundColor: "#87cefa" }}
          key={index}
        >
          {item.name + " "}
          <i
            id={item.guid}
            className="far fa-trash-alt"
            style={{ cursor: "pointer", color: "red", marginLeft: "10px" }}
            onClick={this.handleDelete}
          />
        </li>,
      ];
    });

    if (showMore) {
      let newRow = [];
      filteredNotRolePrivileges.forEach((item, index) => {
        newRow = [
          ...newRow,
          <li
            className="list-group-item"
            style={{ textAlign: "right" }}
            key={index}
          >
            {item.name + " "}
            <i
              id={item.guid}
              className="fas fa-plus"
              style={{
                cursor: "pointer",
                color: "#1e90ff",
                marginLeft: "10px",
              }}
              onClick={this.handleAdd}
            />
          </li>,
        ];
      });
      row = [ ...row, newRow ];
    } else {
      row = [
        ...row,
        <i
          className="fas fa-ellipsis-h fa-lg"
          style={{
            color: "black",
            cursor: "pointer",
            marginLeft: "45%",
            marginTop: "10px",
          }}
          onClick={this.handleMore}
          key={0}
        />,
      ];
    }
    return (
      <div>
        <div className="search-box">
          <form className="form-inline md-form form-sm mt-0">
            <input
              className="form-control form-control-sm ml-3 w-75"
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={this.state.query}
              onChange={this.handleSearch}
            />
            {"    "}
            <i className="fas fa-search" aria-hidden="true"></i>
          </form>
        </div>
        <ul className="list-group">{row}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.roles.role,
    roleLoading: state.roles.roleLoading,
    rolePrivileges: state.roles.rolePrivileges,
    privileges: state.privileges.privilegesList,
    rolePrivilegesLoading: state.roles.rolePrivilegesLoading,
    privilegesLoading: state.privileges.privilegesLoading,
  };
};

export default connect(mapStateToProps, {
  getRoleAction,
  getRolePrivilegesAction,
  upsertChangedPrivilegeAction,
})(RolePrivilegesManagement);

RolePrivilegesManagement.propTypes = {
  privileges: PropTypes.array,
  privilegesLoading: PropTypes.bool,
  roleLoading: PropTypes.bool,
  rolePrivileges: PropTypes.array,
  rolePrivilegesLoading: PropTypes.bool,
  role_guid: PropTypes.string,
  upsertChangedPrivilegeAction: PropTypes.func,
};
