import React from "react";
import Content from "../Content";
import TransactionLogsMain from "components/Log/Transaction/Main";

const TransactionsLogs = (props) => {
  return (
    <Content>
      <TransactionLogsMain name="Transaction logs" {...props} />
    </Content>
  );
};

export default TransactionsLogs;
