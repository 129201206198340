import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import { getAllPartners } from "actions/partners";
import { getGroupAction, editGroupAction } from "actions/groups";
import { showModal } from "actions/modal";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse.js";
import Spinner from "components/UI/Spinner/index";
import swal from "sweetalert";
import { getPartnerAction } from "actions/partners";
import ReactLoading from "react-loading";
import MultiSelect from "components/UI/MultiSelect";
import Joi from "joi-browser";
import Alert from "../UI/Alert";
import { numberFormatter } from "../../helpers/numberFormatter";

class GroupEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        group_guid: "",
        group_name: "",
        group_type: "",
        partner_guid: "",
        monthly_amount_limit: "",
      },
      oldData: {
        group_name: "",
        group_type: "",
        partner_guid: "",
        monthly_amount_limit: "",
      },
      token: {},
      isLoading: false,
      groupLoading: false,
      isAdmin: false,
      errors: {},
      needReason: false,
      reason: "",

      group_nameValidation: false,
      group_typeValidation: false,
      monthly_amount_limitValidation: false,
      reasonValidation: false,
    };
  }
  schema = {
    group_guid: Joi.string().required(),
    group_name: Joi.string().required().label("Group name"),
    group_type: Joi.string().required().label("Group type"),
    monthly_amount_limit: Joi.string()
      .required()
      .max(15)
      .label("Monthly amount limit"),
  };

  async componentDidMount() {
    this.setState({ groupLoading: true });
    const token = getAuthData().userPayload;
    this.setState({ token });
    const isAdmin = localStorage.getItem("isAdmin");
    this.setState({ isAdmin });
    await this.props.getGroupAction(this.props.guid);
    const { group } = this.props;
    if (isAdmin) {
      await this.props.getAllPartners();
      this.props.partners.map((item) => {
        item.name = item.partner_name;
        item.guid = item.partner_guid;
        return item;
      });
      this.props.partners.unshift({ name: "Without partner", guid: "1" });
      this.setState({ partners: this.props.partners });
      let partnerOption = group.partner_guid
        ? this.props.partners.filter(
          (option) => option.partner_guid === group.partner_guid
        )[0]
        : "";
      this.setState({
        data: { ...this.state.data, partner_name: partnerOption.name },
        oldData: {
          group_name: group.group_name,
          group_type: group.group_type,
          partner_guid: group.partner_guid,
          monthly_amount_limit: group.monthly_amount_limit / 100,
        },
        partnerOption,
        group_nameValidation: group.group_name ? true : false,
        group_typeValidation: group.group_type ? true : false,
        monthly_amount_limitValidation: group.monthly_amount_limit
          ? true
          : false,
      });
    }

    this.setState({
      data: {
        ...this.state.data,
        ...group,
        monthly_amount_limit: group.monthly_amount_limit / 100,
      },
      token,
      groupLoading: false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = {
        group_guid: this.state.data.group_guid,
        group_name: this.state.data.group_name,
        group_type: this.state.data.group_type,
        monthly_amount_limit: this.state.data.monthly_amount_limit,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        group_guid: this.state.data.group_guid,
        group_name: this.state.data.group_name,
        group_type: this.state.data.group_type,
        monthly_amount_limit: this.state.data.monthly_amount_limit,
      };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state.data };
    let changedData = false;
    data.monthly_amount_limit = this.state.data.monthly_amount_limit.replace(
      ".",
      ""
    );
    if (data.monthly_amount_limit === "000") {
      data.monthly_amount_limit = "0";
    }

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState(
      {
        data,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  onSelectPartnerGroup = (option) => {
    let { data } = this.state;
    data.partner_guid = option.guid;
    data.partner_name = option.name;
    this.setState({ data }, () => this.showReason());
  };

  handleChangeNumber = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = numberFormatter(input.value);
    this.setState({ data, errors }, () => this.showReason());
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });

          const data = {
            group_guid: this.props.guid,
            group_name: this.state.data.group_name,
            group_type: this.state.data.group_type,
            monthly_amount_limit: (
              +this.state.data.monthly_amount_limit * 100
            ).toString(),
            reason: this.state.needReason ? this.state.reason : undefined,
          };

          if (
            this.state.data.partner_guid &&
            this.state.data.partner_guid !== "1"
          ) {
            data.partner_guid = this.state.data.partner_guid;
            data.partner_name = this.state.data.partner_name;
          } else if (this.state.data.partner_guid === "1")
            data.partner_guid = "";
          else data.partner_guid = undefined;
          await this.props.editGroupAction(data);
          swal({
            title: "Group is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;
    const { data } = this.state;
    if (this.state.groupLoading || this.props.groupLoading) return <Spinner />;
    else
      return (
        <React.Fragment>
          {/* <div className="card">
            <div className="content">
              <label>ID: {this.props.guid}</label>
            </div>
          </div> */}

          <Form onSubmit={this.handleSubmit} autoComplete="off">
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Group Name:</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("group_name")}>
                  <Form.Control
                    name="group_name"
                    type="text"
                    value={data.group_name}
                    onChange={this.handleChange}
                  />
                  {errors.group_name && (
                    <span className="validate-error">{errors.group_name}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Group Type:</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("group_type")}>
                  <Form.Control
                    name="group_type"
                    type="text"
                    value={this.state.data.group_type}
                    onChange={this.handleChange}
                  />
                  {errors.group_type && (
                    <span className="validate-error">{errors.group_type}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {this.state.isAdmin && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Partner</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <MultiSelect
                      name="Partners"
                      options={this.state.partners ? this.state.partners : []}
                      multi={false}
                      onSelect={this.onSelectPartnerGroup}
                      defaultValue={this.state.partnerOption}
                      // styles={{
                      //   menu: (styles) => ({ ...styles, zIndex: 999 }),
                      // }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {this.state.isAdmin === "true" && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Monthly amount limit</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group
                    validationState={this.formValidation(
                      "monthly_amount_limit"
                    )}
                  >
                    <InputGroup>
                      <Form.Control
                        name="monthly_amount_limit"
                        type="number"
                        step="0.01"
                        value={this.state.data.monthly_amount_limit}
                        onChange={(e) => this.handleChangeNumber(e)}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="monthly_amount_limit">
                          €
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.monthly_amount_limit && (
                      <span className="validate-error">
                        {errors.monthly_amount_limit}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            {this.state.needReason && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Reason</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      placeholder="Enter reason"
                      name="reason"
                      value={this.state.reason}
                      onChange={this.handleChangeReason}
                    />
                    {errors.reason && (
                      <span className="validate-error">{errors.reason}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button
                  className={
                    this.state.group_nameValidation &&
                    this.state.group_typeValidation &&
                    this.state.monthly_amount_limitValidation &&
                    (!this.state.needReason || this.state.reasonValidation)
                      ? "btn btn-fill btn-success"
                      : "btn btn-fill"
                  }
                  type="submit"
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </React.Fragment>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    partners: state.partners.partnersList,
    group: state.groups.group,
    groupLoading: state.groups.groupLoading,
  };
};

export default connect(mapStateToProps, {
  getGroupAction,
  editGroupAction,
  showModal,
  getAllPartners,
  getPartnerAction,
})(GroupEditor);

GroupEditor.propTypes = {
  editGroupAction: PropTypes.func,
  getAllPartners: PropTypes.func,
  getGroupAction: PropTypes.func,
  group: PropTypes.object,
  groupLoading: PropTypes.bool,
  guid: PropTypes.string,
  partners: PropTypes.array,
  showModal: PropTypes.func,
};
