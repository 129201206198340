import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import { getPartnerAction, editPartnerAction } from "actions/partners";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse.js";
import Spinner from "components/UI/Spinner/index";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import Alert from "../UI/Alert";

class PartnerEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      data: {
        partner_guid: "",
        partner_name: "",
        partner_type: "",
      },
      oldData: {
        partner_guid: "",
        partner_name: "",
        partner_type: "",
      },
      select: [],
      isLoading: false,
      errors: {},
      needReason: false,
      reason: "",
    };
  }

  schema = {
    partner_guid: Joi.string().required(),
    partner_name: Joi.string().required().label("Partner name"),
    partner_type: Joi.string().required().label("Partner type"),
  };

  async componentDidMount() {
    await this.props.getPartnerAction(this.props.guid);
    const { partner, groups } = this.props;
    this.setState({
      label: "Groups",
      data: {
        partner_guid: partner.partner_guid,
        partner_name: partner.partner_name,
        partner_type: partner.partner_type,
      },
      oldData: {
        partner_guid: partner.partner_guid,
        partner_name: partner.partner_name,
        partner_type: partner.partner_type,
      },
      select: groups,

      partner_nameValidation: partner.partner_name ? true : false,
      partner_typeValidation: partner.partner_type ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = { ...this.state.data, reason: this.state.reason };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = { ...this.state.data };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state.data };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState(
      {
        data,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });
          const data = {
            partner_guid: this.props.guid,
            partner_name: this.state.data.partner_name,
            partner_type: this.state.data.partner_type,
            reason: this.state.needReason ? this.state.reason : undefined,
          };
          await this.props.editPartnerAction(data);
          swal({
            title: "Partner is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;
    const { data } = this.state;
    if (
      this.props.groupsLoading ||
      this.props.partnerLoading ||
      this.props.partnerGroupsLoading
    ) {
      return <Spinner />;
    } else
      return (
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Name:</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("partner_name")}>
                <Form.Control
                  name="partner_name"
                  type="text"
                  value={data.partner_name}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.partner_name && (
                  <span className="validate-error">{errors.partner_name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Type:</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("partner_type")}>
                <Form.Control
                  name="partner_type"
                  type="text"
                  value={this.state.data.partner_type}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.partner_type && (
                  <span className="validate-error">{errors.partner_type}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          {this.state.needReason && (
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Reason</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    placeholder="Enter reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChangeReason}
                  />
                  {errors.reason && (
                    <span className="validate-error">{errors.reason}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.partner_nameValidation &&
                  this.state.partner_typeValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    partner: state.partners.partner,
    partnerLoading: state.partners.partnerLoading,
  };
};

export default connect(mapStateToProps, {
  getPartnerAction,
  editPartnerAction,
  showModal,
})(PartnerEditor);

PartnerEditor.propTypes = {
  editPartnerAction: PropTypes.func,
  getPartnerAction: PropTypes.func,
  groups: PropTypes.array,
  groupsLoading: PropTypes.bool,
  guid: PropTypes.string,
  partner: PropTypes.object,
  partnerGroupsLoading: PropTypes.bool,
  partnerLoading: PropTypes.bool,
  showModal: PropTypes.func,
};
