import React, { Component } from "react";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { Button, Form } from "react-bootstrap";
import { addCurrencyAction } from "actions/currencies";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import ReactLoading from "react-loading";
import { Col, Row } from "react-bootstrap";
import Alert from "../UI/Alert";

class CurrencyCreator extends Component {
  state = {
    data: {
      name: "",
      code: "",
      number: "",
      rate: "",
      exchange_markup_value: "",
      isFlat: false,
    },
    errors: {},
    isLoading: false,
  };

  schema = {
    name: Joi.string().required().min(1).max(100).trim().label("Name"),
    code: Joi.string()
      .required()
      .min(0)
      .max(100)
      .regex(/^[A-Z]{3}$/)
      .error(() => {
        return {
          message: "Code must be a valid.",
        };
      })
      .label("Code"),
    number: Joi.string()
      .required()
      .regex(/^[0-9]{3}$/)
      .error(() => {
        return {
          message: "Number must be a valid.",
        };
      })
      .label("Number"),
    rate: Joi.number()
      .required()
      .error(() => {
        return {
          message: "Rate must be a valid.",
        };
      })
      .label("Rate"),
    exchange_markup_value: Joi.number()
      .required()
      .min(0)
      .max(100)
      .error(() => {
        return {
          message: "Exchange markup percent must be a valid.",
        };
      })
      .label("Exchange markup percent"),
    isFlat: Joi.boolean().required().label("Type"),
  };

  validate = () => {
    const options = { abortEarly: false };
    let data = {
      name: this.state.data.name,
      code: this.state.data.code,
      number: this.state.data.number,
      rate: this.state.data.rate,
      exchange_markup_value: this.state.data.exchange_markup_value,
      isFlat: this.state.data.isFlat,
    };
    let schema = this.schema;

    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
      errors,
    });
  };

  handleChangeSwitch = () => {
    const data = { ...this.state.data };
    data.isFlat = !data.isFlat;

    this.setState({
      data,
    });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      try {
        const { name, code, number, rate, exchange_markup_value, isFlat } =
          this.state.data;
        await this.props.addCurrencyAction(
          {
            name,
            code,
            number,
            rate_to_eur: rate,
            exchange_markup_value,
            isFlat,
          },
          this.props.currentPage,
          this.props.pageSize
        );
        swal({
          title: "Currency is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  renderInput = (label, value, name, type = "text") => {
    return (
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>{label}*</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group validationState={this.formValidation(name)}>
            <Form.Control
              // placeholder={label.toLowerCase()}
              type={type}
              name={name}
              value={value}
              onChange={this.handleChange}
            />
            {this.state.errors[name] && (
              <span className="validate-error">{this.state.errors[name]}</span>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <Form>
        {this.renderInput("Name", this.state.data.name, "name")}
        {this.renderInput("Code", this.state.data.code, "code")}
        {this.renderInput("Number", this.state.data.number, "number")}
        {this.renderInput("Rate to EUR", this.state.data.rate, "rate")}

        {this.renderInput(
          "Exchange markup",
          this.state.data.exchange_markup_value,
          "exchange_markup_value",
          "number"
        )}

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Markup type</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Check
              type="switch"
              id="custom-switch"
              onChange={this.handleChangeSwitch}
              label={this.state.data.isFlat ? "Flat" : "Percent"}
            />
            {this.state.errors.isFlat && (
              <span className="validate-error">{this.state.errors.isFlat}</span>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button
                  className="btn btn-fill btn-success"
                  type="submit"
                  onClick={this.doSubmit}
                >
                  Add
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default connect(null, {
  addCurrencyAction,
  showModal,
})(CurrencyCreator);

CurrencyCreator.propTypes = {
  addCurrencyAction: PropTypes.func,
  showModal: PropTypes.func,
};
