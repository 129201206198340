import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import {
  getAllAdditionalFees,
  deleteAdditionalFeeAction,
} from "../../actions/additionalFee";
import { reset, searchInCurrencies } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import AdditionalFeeCreator from "./Creator";
import AdditionalFeeEditor from "./Editor";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";

const columns = [
  { path: "fee_name", label: "Name", key: "fee_name" },
  {
    path: "enabled",
    key: "enabled",
    label: "Active",
    content: (fee) => (
      <i
        className={
          fee.enabled ? "icon-success icon green" : "icon-failed icon red"
        }
      />
    ),
    textContent: (fee) => (fee.enabled ? "Yes" : "No"),
    align: "center",
    colWidth: 4,
  },
  ability.can("EXECUTE", "STATEMENTS") && {
    key: "edit",
    content: (fee) => (
      <Modal
        header="Edit fee"
        action="Save"
        content={AdditionalFeeEditor}
        contentProps={{ guid: fee.guid, fee, type: "currency" }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.additionalFees.additionalFees,
    count: state.additionalFees.count,
    table: state.store.table,
    loading: state.additionalFees.loading,
    searchData: state.search.currenciesSearch,
    isSearch: state.search.isSearch,
    name: "statements",
    keyPath: "guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    modal: <AdditionalFeeCreator />,
    // exportFunction: getAllAdditionalFees,
  };
};

export default connect(mapStateToProps, {
  get: getAllAdditionalFees,
  delete: deleteAdditionalFeeAction,
  search: searchInCurrencies,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
