import * as types from "../constants/actionTypes";
import { getCurrencies, getCurrency, createCurrency, updateCurrency, deleteCurrency, getCurrencyRates } from "../services/paymentBackendAPI/management/currencies";
import { getDailyTransaction } from "services/paymentBackendAPI/reports/transactions";
import { loadTable } from "./store";

export const getAllCurrencies = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CURRENCIES
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const res = await getCurrencies(args);
      return dispatch({
        type: types.GET_CURRENCIES_SUCCEED,
        data: res.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    } catch (error) {
      return dispatch({
        type: types.GET_CURRENCIES_FAILED
      });
    }
  };
};

export const getCurrencyAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CURRENCY
    });
    try {
      const res = await getCurrency(id);
      return dispatch({
        type: types.GET_CURRENCY_SUCCEED,
        data: res.data,
      });
    } catch (error) {
      return dispatch({
        type: types.GET_CURRENCY_FAILED
      });
    }
  };
};

export const addCurrencyAction = (data, page, items, search) => {
  return async (dispatch) => {
    await createCurrency(data);
    return dispatch(getAllCurrencies({ page, items, ...search }));
  };
};

export const editCurrencyAction = (data) => {
  return async (dispatch) => {
    const response = await updateCurrency(data);
    dispatch({
      type: types.EDIT_CURRENCY,
      currency: response.data
    });
  };
};

export const deleteCurrencyAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteCurrency(data);
    return dispatch(getAllCurrencies({ page, items, ...search }));
  };
};


export const getReportDailyCurrencyAction = () => {
  return async dispatch => {
    dispatch({
      type: types.GET_REPORT_DAILY_CURRENCY,
    });
    try {
      const response = await getDailyTransaction();
      return dispatch({
        type: types.GET_REPORT_DAILY_CURRENCY_SUCCEED,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: types.GET_REPORT_DAILY_CURRENCY_FAILED,
      });
      throw error;
    }
  };
};

export const getCurrencyRatesAction = (from_currency, to_currency) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CURRENCY_RATES
    });
    try {
      const res = await getCurrencyRates(from_currency, to_currency);
      return dispatch({
        type: types.GET_CURRENCY_RATES_SUCCEED,
        data: res.data,
      });
    } catch (error) {
      return dispatch({
        type: types.GET_CURRENCY_RATES_FAILED
      });
    }
  };
};