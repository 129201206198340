import { setCurrentSearchData } from "actions/search";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      currentPage: 1,
      pageSize: 100,
      isSearch: false,
      showReset: false,
      name: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      value: this.props.currentSearchData[this.props.value],
      showReset: this.props.currentSearchData[this.props.value] ? true : false,
    });
  };

  handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      await this.props.get({
        page: 1,
        items: this.state.pageSize,
        ...this.props.searchData,
      });
      this.props.updateData(this.state.name);
      this.props.updateCurrentPage();
    }
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.reset) {
      return {
        value: "",
      };
    }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.search({
      [this.props.value]: event.target.value,
    });
    this.props.setCurrentSearchData({
      [this.props.value]: event.target.value,
    });
  };

  handleReset = async () => {
    await this.props.setCurrentSearchData({
      [this.props.value]: "",
    });
    await this.props.search({
      [this.props.value]: undefined,
    });
    await this.props.get({
      page: 1,
      items: this.state.pageSize,
      ...this.props.searchData,
    });
    this.props.updateCurrentPage();
  };

  handleTimeChange = async (event) => {
    const value = event.target.value;
    this.setState({ value });
    await this.props.search({
      [this.props.value]: moment(value).format("YYYY-MM-DDTHH:mm:ss"),
    });
    this.props.setCurrentSearchData({
      [this.props.value]: value,
    });
    await this.props.get({
      page: 1,
      items: this.state.pageSize,
      ...this.props.searchData,
    });
    this.props.updateCurrentPage();
  };

  render() {
    const onChange = this.props.type
      ? this.handleTimeChange
      : this.handleChange;
    return (
      <div
        className={
          this.props.isSearch
            ? "searchOpen search search-input"
            : "searchClosed search search-input"
        }
      >
        <InputGroup>
          <Form.Control
            value={this.state.value}
            onChange={onChange}
            onKeyPress={this.handleKeyPress}
            placeholder={
              this.props.value ? this.props.value.replace(/_/g, " ") : "search"
            }
          />
          <InputGroup.Append>
            <i
              className="icon-delete icon"
              onClick={this.handleReset}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Append>
        </InputGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.search.reset,
    isSearch: state.search.isSearch,
    currentSearchData: state.search.currentSearchData,
  };
};

export default connect(mapStateToProps, {
  setCurrentSearchData,
})(Search);

Search.propTypes = {
  currentSearchData: PropTypes.object,
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  reset: PropTypes.bool,
  search: PropTypes.func,
  searchData: PropTypes.object,
  setCurrentSearchData: PropTypes.func,
  type: PropTypes.string,
  updateCurrentPage: PropTypes.func,
  updateData: PropTypes.func,
  value: PropTypes.string,
};
