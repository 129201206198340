import Can from "components/Can";
import StatementMain from "components/Statement/Main";
import React from "react";
import { NavLink } from "react-router-dom";
import Content from "../Content";
import ability from "config/ability";

const Statement = () => {
  return (
    <Content>
      <StatementMain
        modalComponent={
          <React.Fragment
            allowed={
              ability.can("EXECUTE", "STATEMENTS") ||
              ability.can("EXECUTE", "MERGESTATEMENTS")
            }
          >
            <Can do="EXECUTE" on="STATEMENTS">
              <NavLink to="/interim/statement">
                <button
                  type="button"
                  className="btn btn-fill btn-primary"
                  // style={{ marginBottom: "11px" }}
                >
                  Interim statement
                </button>
              </NavLink>
            </Can>
            <Can do="EXECUTE" on="MERGESTATEMENTS">
              <NavLink to="/payable/statement">
                <button type="button" className="btn btn-fill btn-primary">
                  Payable statement
                </button>
              </NavLink>
            </Can>
          </React.Fragment>
        }
      />
    </Content>
  );
};
export default Statement;
