import { reset, searchInTransactionsTemplates } from "actions/search";
import {
  deleteTransactionTemplateAction,
  getTransactionTemplatesAction,
} from "actions/transactions";
import AbstractComponent from "factories/Main";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { resetPage, setNewTable } from "../../../actions/store";
import { cutGuid } from "../../../helpers/cutGuid";
import { getTransactionTemplates } from "../../../services/paymentBackendAPI/management/transactions";
import { Copy } from "../../UI/CopyToClipboard";

const columns = [
  {
    path: "guid",
    label: "ID",
    key: "guid",
    content: (template) => (
      <>
        <Copy text={template.guid} />
        <Link
          className="link"
          to={`/about/transactiontemplate/${template.guid}`}
        >
          {cutGuid(template.guid)}
        </Link>
      </>
    ),
    textContent: (template) => template.guid,
    style: { minWidth: "120px" },
  },
  { path: "name", label: "Name" },
  { path: "type", label: "Type", key: "template_type" },
  {
    path: "created_at",
    label: "Datetime",
    content: (transaction) =>
      moment(transaction.created_at).utcOffset(0).format("DD.MM.YYYY HH:mm"),
    align: "center",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.transactions.templatesList,
    count: state.transactions.count,
    table: state.store.table,
    searchData: state.search.transactionsTemplatesSearch,
    isSearch: state.search.isSearch,
    loading: state.transactions.templatesLoading,
    name: "transactiontemplates",
    keyPath: "guid",
    columns,
    exportFunction: getTransactionTemplates,
  };
};

export default connect(mapStateToProps, {
  get: getTransactionTemplatesAction,
  delete: deleteTransactionTemplateAction,
  search: searchInTransactionsTemplates,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
