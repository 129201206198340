import { backendManagement } from "../backendPlatform";

export const getGateways = (args) => {
  return backendManagement.get("/gateways", { params: { ...args } });
};

export const createGateway = data => {
  return backendManagement.post("/gateways", data);
};

export const getGateway = guid => {
  return backendManagement.get(`/gateways/${guid}`);
};

export const updateGateway = data => {
  return backendManagement.post("/gateways", data);
};

export const deleteGateway = ({ guid, reason }) => {
  return backendManagement.post("/gateways", { guid, delete: true, reason });
};

export const getGatewayProps = guid => {
  return backendManagement.get(`/gateways/${guid}/props`);
};

export const addGatewayProps = (guid, data) => {
  return backendManagement.post(`/gateways/${guid}/props`,data);
};

export const deleteGatewayProps = (guid, data) => {
  return backendManagement.post(`/gateways/${guid}/props`,data);
};

export const getGatewayPropsByName = (guid, name) => {
  return backendManagement.get(`/gateways/${guid}/props/${name}`);
};

export const addCurrenciesForGateway = (guid, data) => {
  return backendManagement.post(`/gateways/${guid}/currencies`, data);
};

export const deleteGatewayCurrency = (gatewayGuid, guid, reason) => {
  return backendManagement.post(`/gateways/${gatewayGuid}/currencies`, { guid, delete: true, reason });
};