import React from "react";
import { connect } from "react-redux";
import PieChart from "components/UI/PieChart";
import Spinner from "components/UI/Spinner";
import PropTypes from "prop-types";

class PieChartTransactionsStatuses extends React.Component {
  render() {
    if (this.props.loading) return <Spinner />;
    else {
      let success = 0;
      let failed = 0;
      this.props.data.forEach((transaction) => {
        success += parseInt(transaction.success, 10);
        failed += parseInt(transaction.failed, 10);
      });
      const datasetsPie =
        success > 0 || failed > 0
          ? [
              {
                data: [ success, failed ],
                backgroundColor: [ "rgba(75,192,192,1)", "rgba(255,99,132,1)" ],
              },
            ]
          : [
              {
                data: [ 1 ],
                backgroundColor: "#CCC",
              },
            ];

      const labelsPie =
        datasetsPie[0].data.length === 1
          ? [ "There are no transactions yet" ]
          : [ "Success", "Failed" ];

      return (
        <PieChart
          name="Passability"
          labels={labelsPie}
          datasets={datasetsPie}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.transactions.dailyTransaction,
    loading: state.transactions.dailyTransactionLoading,
  };
};

export default connect(mapStateToProps, {})(PieChartTransactionsStatuses);

PieChartTransactionsStatuses.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
