import { backendManagement } from "../backendPlatform";

export const addTransactionStep = (data) => {
  return backendManagement.post("/steps", data);
};

export const getStep = guid => {
  return backendManagement.get(`/steps/${guid}`);
};

export const getParamsForStep = guid => {
  return backendManagement.get(`/steps/${guid}/params`);
};