import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import { backendManagement } from "../backendPlatform";

export const getUserAccount = () => {
  const userGuid = getAuthData().userPayload.loginGuid;
  return backendManagement.get(`/users/profile/${userGuid}`);
};

export const updateUserAccount = data => {
  const userGuid = getAuthData().userPayload.loginGuid;
  return backendManagement.post(`/users/profile/${userGuid}`, data);
};