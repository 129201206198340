import * as types from "../constants/actionTypes";

const initialState = {
  admin: {
    guid: "",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone: "",
    enabled: "",
    last_login: "",
    locked: "",
    credentials_expire_at: "",
    auth_type: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: ""
  },
  adminLoading: false,
  adminRolesLoading: false,

  admin_guid: "",

  adminsList: [],
  adminsLoading: false,
  adminRoles: [],

  error: false
};

export default function admins(state = initialState, action) {
  switch (action.type) {
    case types.GET_ADMINS:
      return {
        ...state,
        adminsLoading: true,
        error: false
      };
    case types.GET_ADMINS_SUCCEED:
      return {
        ...state,
        adminsList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        adminsLoading: false,
        error: false
      };
    case types.GET_ADMINS_FAILED:
      return {
        ...state,
        adminsLoading: false,
        error: true
      };
    case types.ADD_ADMIN:
      return { ...state, admin_guid: action.admin.guid };
    case types.GET_ADMIN:
      return {
        ...state,
        adminLoading: true,
        error: false
      };
    case types.GET_ADMIN_SUCCEED:
      return {
        ...state,
        admin: action.admin,
        adminLoading: false,
        error: false
      };
    case types.GET_ADMIN_FAILED:
      return {
        ...state,
        adminLoading: false,
        error: true
      };
    case types.GET_ADMIN_ROLES:
      return {
        ...state,
        adminRolesLoading: true,
        error: false
      };
    case types.GET_ADMIN_ROLES_SUCCEED:
      return {
        ...state,
        adminRoles: action.roles,
        adminRolesLoading: false,
        error: false
      };
    case types.GET_ADMIN_ROLES_FAILED:
      return {
        ...state,
        adminRolesLoading: false,
        error: true
      };
    case types.ADD_ADMIN_ROLE:
      return { ...state, adminRoles: state.adminRoles.push(action.data) };
    default:
      return state;
  }
}