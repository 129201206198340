import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect/index";
import { connect } from "react-redux";
import { addShopAction } from "actions/shops";
import { getAllMerchants } from "actions/merchants";
import { showModal } from "actions/modal";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import PhoneInput from "../UI/PhoneInput";
import CreatableMultiSelect from "components/UI/CreatableSelect";
import Alert from "../UI/Alert";

export class ShopCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      risk: "",
      selectedMerchant: "",
      merchantGuid: "",
      merchantName: "",
      display_name: "",
      merchants: [],
      email: "",
      url: "",
      phone: "",
      note: "",
      enabled: true,
      isMerchant: false,
      token: getAuthData().userPayload,
      isLoading: false,
      errors: {},

      nameValidation: false,
      riskValidation: false,
      merchantValidation: false,
      urlValidation: false,
      emailValidation: false,
      phoneValidation: false,
    };
  }

  schema = {
    name: Joi.string().required().label("Name"),
    risk: Joi.string().required().label("Risk"),
    selectedMerchant: Joi.string().required().label("Merchant"),
    email: Joi.string().required().email().label("Email"),
    url: Joi.array()
      .items(Joi.string().uri().not().empty().required().label("Url"))
      .required()
      .label("Url"),
    phone: Joi.string().required().min(5).label("Phone"),
    note: Joi.any(),
    display_name: Joi.any(),
  };

  async componentDidMount() {
    if (this.state.token.merchant !== undefined)
      this.setState({
        selectedMerchant: this.state.token.merchant.merchant_guid,
        isMerchant: true,
      });
    else {
      await this.props.getAllMerchants();
      const merchants = this.props.merchants.map((item) => {
        item.name = item.merchant_name;
        item.guid = item.merchant_guid;
        return item;
      });
      this.setState({
        merchants,
      });
    }
  }

  validate = () => {
    const options = { abortEarly: false };
    let arr = this.state.url;
    let url = arr.length
      ? arr.map((option) => {
        return option.label;
      })
      : [];
    let { error } = Joi.validate(
      {
        name: this.state.name,
        risk: this.state.risk,
        selectedMerchant: this.state.selectedMerchant,
        email: this.state.email,
        url: url,
        phone: this.state.phone,
      },
      this.schema,
      options
    );

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (name === "email") {
      if (
        this.state.errors[name] ||
        this.state[name] === "" ||
        this.state.errorEmailExists
      )
        return "error";
      else return "success";
    } else if (this.state.errors[name] || this.state[name] === "")
      return "error";
    else return "success";
  };

  onSelectMerchant = (option) => {
    const errors = { ...this.state.errors };
    delete errors.selectedMerchant;

    this.setState({
      selectedMerchant: option.guid,
      errors,
      merchantValidation: true,
    });
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name: "phone", value: value });
    if (errorMessage) errors.phone = errorMessage;
    else delete errors.phone;

    this.setState({
      phone: value,
      errors,
      phoneValidation: errorMessage ? false : true,
    });
  };

  onSelectRisk = (option) => {
    const errors = { ...this.state.errors };
    delete errors.risk;

    this.setState({
      risk: option.name,
      errors,
      riskValidation: true,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      [input.name]: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleEnabledCheckbox = () => {
    this.setState({
      enabled: !this.state.enabled,
    });
  };

  handleChangeSelect = (options) => {
    const errors = { ...this.state.errors };
    let value = options.map((item) => {
      return item.label;
    });
    const errorMessage = this.validateProperty({ name: "url", value: value });
    if (errorMessage) errors.url = errorMessage;
    else delete errors.url;

    this.setState({
      url: options,
      errors,
      urlValidation: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      const enabled = this.state.enabled ? 1 : 0;
      let arr = this.state.url;
      let url = arr.map((option) => {
        return option.label;
      });

      let data = {
        guid: this.props.guid,
        name: this.state.name,
        display_name: this.state.display_name,
        risk_category: this.state.risk,
        merchant_guid: this.state.selectedMerchant,
        email: this.state.email,
        description: this.state.description,
        url: url,
        phone: this.state.phone,
        note: this.state.note,
        enabled: enabled,
        enable_checkout: 1,
        checkout_method: "",
        antiFraudMonitor: 1,
        antiFraudMonitorValue: 1,
      };
      try {
        await this.props.addShopAction(
          data,
          this.props.currentPage,
          this.props.pageSize,
          this.props.shopsSearch
        );
        swal({
          title: "Shop is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { merchants, isMerchant } = this.state;
    const risks = [
      { guid: 1, name: "Low" },
      { guid: 2, name: "Medium" },
      { guid: 3, name: "High" },
    ];
    const { errors } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off">
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Name*:</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group validationState={this.formValidation("name")}>
              <Form.Control
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
              />
              {errors.name && (
                <span className="validate-error">{errors.name}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>
              {" "}
              Display name
              <i className="far fa-question-circle help-tip">
                <p>Display name on payment page</p>
              </i>
              :
            </Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group validationState={this.formValidation("display_name")}>
              <Form.Control
                name="display_name"
                type="text"
                value={this.state.display_name}
                onChange={this.handleChange}
              />
              {errors.display_name && (
                <span className="validate-error">{errors.display_name}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Risk category*:</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group>
              <MultiSelect
                name="risk"
                options={risks}
                multi={false}
                onSelect={this.onSelectRisk}
              />
              {errors.risk && (
                <span className="validate-error">{errors.risk}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Enable*:</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <input
              type="checkbox"
              id="enabledCheckbox"
              checked={this.state.enabled}
              onChange={this.handleEnabledCheckbox}
            />
          </Col>
        </Row>

        {!isMerchant && (
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Merchant*:</Form.Label>
            </Col>
            <Col md={8} sm={8} xs={7}>
              <Form.Group>
                <MultiSelect
                  name="merchant"
                  options={merchants}
                  multi={false}
                  onSelect={this.onSelectMerchant}
                />
                {errors.selectedMerchant && (
                  <span className="validate-error">
                    {errors.selectedMerchant}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>URL*:</Form.Label>
            <i className="far fa-question-circle help-tip">
              <p>
                Select options or type to create new option. Example URL:
                http://your_url.com/
              </p>
            </i>
          </Col>

          <Col md={8} sm={8} xs={7}>
            <Form.Group>
              <CreatableMultiSelect
                name="url"
                value={this.state.url ? this.state.url : []}
                handleChange={this.handleChangeSelect}
              />
              {errors.url && (
                <span className="validate-error">{errors.url}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Email*:</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group validationState={this.formValidation("email")}>
              <Form.Control
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.handleChange}
              />
              {errors.email && (
                <span className="validate-error">{errors.email}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Phone*:</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group validationState={this.formValidation("phone")}>
              {/* <Form.Control
                    name="phone"
                    type="text"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  /> */}
              <PhoneInput
                name="phone"
                placeholder="Enter phone number"
                value={this.state.phone}
                onChange={(e) => this.onChangeNumber(e)}
              />
              {errors.phone && (
                <span className="validate-error">{errors.phone}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Note:</Form.Label>
          </Col>
          <Col md={8} sm={8} xs={7}>
            <Form.Group>
              <Form.Control
                name="note"
                type="text"
                value={this.state.note}
                onChange={(e) => this.handleChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>

        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className={
                this.state.nameValidation &&
                this.state.riskValidation &&
                this.state.merchantValidation &&
                this.state.urlValidation &&
                this.state.emailValidation &&
                this.state.phoneValidation
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
            >
              Save
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    shopGuid: state.shops.shopGuid,
    currentPage: state.shops.currentPage,
    pageSize: state.shops.pageSize,

    shopsSearch: state.search.shopsSearch,
  };
};

export default connect(mapStateToProps, {
  addShopAction,
  getAllMerchants,
  showModal,
})(ShopCreator);

ShopCreator.propTypes = {
  addShopAction: PropTypes.func,
  currentPage: PropTypes.number,
  getAllMerchants: PropTypes.func,
  guid: PropTypes.string,

  merchants: PropTypes.array,
  pageSize: PropTypes.number,
  shopsSearch: PropTypes.object,
  showModal: PropTypes.func,
};
