import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reset, searchInShops } from "../../actions/search";
import { deleteShopAction, getAllShops } from "../../actions/shops";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import { getShops } from "../../services/paymentBackendAPI/management/shops";
import ShopCreator from "./Creator";
import ShopEditor from "./Editor";

let columns = [
  {
    path: "merchant_name",
    label: "Merchant name",
    key: "merchant_name",
    content: (shop) => (
      <Link className="link" to={`/about/merchant/${shop.merchant_guid}`}>
        {shop.merchant_name}
      </Link>
    ),
    textContent: (shop) => shop.merchant_name,
  },
  {
    path: "name",
    label: "Shop Name",
    key: "guid",
    content: (shop) => (
      <Link className="link" to={`/about/shop/${shop.guid}`}>
        {shop.name}
      </Link>
    ),
    textContent: (shop) => shop.name,
  },
  {
    path: "terminal_count",
    label: "Terminal count",
    key: "terminal_count",
  },

  {
    key: "enabled",
    content: (shop) => (
      <i
        className={
          shop.enabled ? "icon-success icon green" : "icon-failed icon red"
        }
      />
    ),
    label: "Status",
    textContent: (shop) => (shop.enabled ? "Enabled" : "Disabled"),
    align: "center",
    colWidth: 4,
  },
  ability.can("EXECUTE", "SHOPS") && {
    key: "edit",
    content: (shop) => (
      <Modal
        header="Edit shop"
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        content={ShopEditor}
        contentProps={{ guid: shop.guid, type: "shop" }}
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "SHOPS") && {
    key: "delete",
    label: "Delete",
    colWidth: 4,
  },
];

if (getAuthData() && getAuthData().userPayload.merchant) {
  columns.splice(0, 1);
}

const mapStateToProps = (state) => {
  return {
    data: state.shops.shopsList,
    count: state.shops.count,
    table: state.store.table,
    searchData: state.search.shopsSearch,
    isSearch: state.search.isSearch,
    name: "shops",
    columns,
    columnsComponent: createColComponentForTable(columns, 50),
    tableWidth: 50,
    keyPath: "guid",
    loading: state.shops.shopsLoading,
    modal: <ShopCreator />,
    exportFunction: getShops,
  };
};

export default connect(mapStateToProps, {
  get: getAllShops,
  delete: deleteShopAction,
  search: searchInShops,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
