import PropTypes from "prop-types";
import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import HeaderLinks from "./HeaderLinks.jsx";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBack = () => {
    window.history.back();
  };

  render() {
    return (
      <>
        <Navbar className="nav-bar container-fluid" expand="lg">
          {/* <Navbar.Header> */}
          <Navbar.Brand>
            {this.props.navHistory.map((item, index) => {
              if (item.location !== "") {
                return (
                  <React.Fragment key={item.name}>
                    &nbsp; <NavLink to={item.location}>{item.name}</NavLink>
                    {index !== this.props.navHistory.length - 1 ? (
                      <i className="fas fa-angle-right " />
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                );
              }
              return index !== this.props.navHistory.length - 1 ? (
                <React.Fragment key={item.name}>
                  {item.name}
                  <i
                    className="fas fa-angle-right "
                    style={{ width: "20px", textAlign: "center" }}
                  />
                </React.Fragment>
              ) : (
                <>{item.name}</>
              );
            })}
          </Navbar.Brand>
          {/* </Navbar.Header> */}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <HeaderLinks />
          </Navbar.Collapse>
        </Navbar>

        {/* <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  navHistory: state.history.navHistory,
});

export default withRouter(connect(mapStateToProps, {})(Header));

Header.propTypes = {
  navHistory: PropTypes.array,
};
