import moment from "moment";
import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";

function DatePicker({ start, end, callback }) {

  const onDateChange = (start, end) => {
    callback && callback(start, end);
  };
  return (
    <Row  >
      <Col md={3} sm={4} xs={4} className="form-label">
        <Form.Label>Date range</Form.Label>
      </Col>
      <Col md={5}>
        <Form.Group>
          <DateRangePicker
            onCallback={onDateChange}
            initialSettings={{
              startDate: moment(start).format(
                "DD.MM.YYYY"
              ),
              endDate: moment(end).format(
                "DD.MM.YYYY"
              ),
              locale: {
                format: "DD.MM.YYYY",
              },
            }}
          >
            <input
              type="text"
              className="text-input daterange-input form-control"
            />
          </DateRangePicker>
        </Form.Group>
      </Col>
    </Row >
  );
}

export default DatePicker;