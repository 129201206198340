import * as types from "../constants/actionTypes";

const initialState = {
  shopsList: [],
  count: -1,
  shopGuid: "",
  gatewayTerminals: [],
  shopTerminals: [],
  gatewayProps: [],
  reportSpops: [],
  topShops: [],
  shopLoading: false,
  shopsLoading: false,
  gatewayPropsLoading: false,
  shop: {
    guid: "",
    merchant_guid: "",
    merchant_name: "",
    name: "",
    url: "",
    email: "",
    phone: "",
    enabled: false,
    note: "",
    enable_checkout: false,
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: "",
  },
  shopTerminal: {
    billing_descriptor: "",
    routing_string: "",
    payment_amount_limit: "",
    monthly_amount_limit: "",
    secret: "",
    hash_key: "No hash-key",
    three_d: false,
    supported_brands: "",
    currency_guid: "",
    gateway_guid: "",
    generate_statement: false,
    enabled: false,
    enable_checkout: false,
    checkout_method: "",
    antifraud_monitor: false,
    antifraud_monitor_value: "",
    used_amount: "",
    used_transaction_count: "",
    unused_amount: "",
    unused_transaction_count: "",
    used_percent: "",
    used_count_percent: "",
    unused_percent: "",
    unused_count_percent: "",
  },
};

export default function shops(state = initialState, action) {
  switch (action.type) {
    case types.GET_SHOPS:
      return {
        ...state,
        shopsLoading: true,
        error: false,
      };
    case types.GET_SHOPS_SUCCEED:
      return {
        ...state,
        shopsList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        shopsLoading: false,
        error: false,
        shopGateways: [],
      };
    case types.GET_SHOPS_FAILED:
      return {
        ...state,
        shopsLoading: false,
        error: true,
      };
    case types.GET_SHOP:
      return {
        ...state,
        shopLoading: true,
        error: false,
      };
    case types.GET_SHOP_SUCCEED:
      return {
        ...state,
        shop: action.shop,
        shopLoading: false,
        error: false,
      };
    case types.GET_SHOP_FAILED:
      return {
        ...state,
        shopLoading: false,
        error: true,
      };
    case types.DELETE_SHOP: {
      let newShopsList = state.shopsList.filter(
        (shop) => shop.guid !== action.shop.guid
      );
      return { ...state, shopsList: newShopsList };
    }
    case types.ADD_SHOP:
      return { ...state, shopGuid: action.shop.guid };
    case types.EDIT_SHOP: {
      let newShopsList = state.shopsList || [];
      newShopsList = newShopsList.map((shop) => {
        if (shop.guid === action.shop.guid) return action.shop;
        else return shop;
      });
      if (newShopsList.length !== 0)
        return { ...state, shopsList: newShopsList };
      return state;
    }
    case types.GET_SHOP_TERMINALS:
      return {
        ...state,
        shopTerminalsLoading: true,
        error: false,
      };
    case types.GET_SHOP_TERMINALS_SUCCEED:
      return {
        ...state,
        shopTerminals: action.data.data,
        terminalsCount: action.data.count,
        shopTerminalsLoading: false,
        error: false,
      };
    case types.GET_SHOP_TERMINALS_FAILED:
      return {
        ...state,
        shopTerminalsLoading: false,
        error: true,
      };
    case types.GET_SHOP_TERMINAL:
      return {
        ...state,
        shopTerminalLoading: true,
        error: false,
      };
    case types.GET_SHOP_TERMINAL_SUCCEED:
      return {
        ...state,
        shopTerminal: action.shopTerminal,
        shopTerminalLoading: false,
        error: false,
      };
    case types.GET_SHOP_TERMINAL_FAILED:
      return {
        ...state,
        shopTerminalLoading: false,
        error: true,
      };
    case types.GENERATE_TERMINAL_CREDENTIALS:
      return {
        ...state,
      };
    case types.UPSERT_SHOP_GATEWAY: {
      let shopGateways = state.shopGateways || [];
      let changedGateways = state.changedGateways;
      if (typeof changedGateways === "undefined") changedGateways = [];
      if (action.gateway.delete) {
        shopGateways = shopGateways.filter(
          (gateway) => gateway.guid !== action.gateway.guid
        );
        const newChangedGateways = changedGateways.filter(
          (gateway) => gateway.guid !== action.gateway.guid
        );
        if (newChangedGateways.length === changedGateways.length)
          changedGateways = [ ...changedGateways, action.gateway ];
        else changedGateways = newChangedGateways;
      } else {
        shopGateways = shopGateways.filter(
          (gateway) => !(gateway.guid === action.gateway.guid && gateway.delete)
        );
        changedGateways = changedGateways.filter(
          (gateway) => !(gateway.guid === action.gateway.guid && gateway.delete)
        );
        shopGateways = [ ...shopGateways, action.gateway ];
        changedGateways = [ ...changedGateways, action.gateway ];
      }
      return { ...state, shopGateways, changedGateways };
    }
    case types.GET_TERMINALS_BY_GATEWAY:
      return {
        ...state,
        gatewayTerminalsLoading: true,
      };
    case types.GET_TERMINALS_BY_GATEWAY_SUCCEED:
      return {
        ...state,
        gatewayTerminals: action.data.data,
        terminalsCount: action.data.count,
        gatewayTerminalsLoading: false,
      };
    case types.GET_TERMINALS_BY_GATEWAY_FAILED:
      return {
        ...state,
        gatewayTerminalsLoading: false,
      };
    case types.GET_SHOPS_BY_MERCHANT:
      return {
        ...state,
        shopsList: action.data.data,
        count: action.data.count,
      };
    case types.GET_SHOPS_BY_GROUP:
      return { ...state, shopsList: action.data };
    case types.GET_SHOPS_BY_PARTNER:
      return { ...state, shopsList: action.data };
    case types.GET_AMOUNT_SHOPS_FOR_GROUP:
      return { ...state, count: action.count };
    case types.EDIT_SHOP_GATEWAY_DATA: {
      let shopGateways = state.shopGateways || [];
      let changedGateways = state.changedGateways;
      if (
        typeof changedGateways === "undefined" ||
        changedGateways.length === 0
      ) {
        changedGateways = [ action.changedGatewayData ];
      } else {
        changedGateways = changedGateways.map((gateway) => {
          if (gateway.guid === action.changedGatewayData.guid)
            return action.changedGatewayData;
          else return gateway;
        });
      }
      shopGateways = shopGateways.map((gateway) => {
        if (gateway.guid === action.changedGatewayData.guid)
          return action.changedGatewayData;
        else return gateway;
      });
      return { ...state, shopGateways, changedGateways };
    }
    case types.UPSERT_SHOP_GATEWAYS:
      return { ...state, changedGateways: [], shopGateways: [] };
    case types.ADD_SHOP_TERMINAL:
      return { ...state, shopTerminals: [ action.shopTerminal ] };
    case types.GET_AMOUNT_SHOPS_FOR_PARTNER:
      return { ...state, count: action.count };
    case types.GET_SHOP_GATEWAY_PROPS:
      return {
        ...state,
        gatewayPropsLoading: true,
      };
    case types.GET_SHOP_GATEWAY_PROPS_SUCCEED:
      return {
        ...state,
        gatewayPropsLoading: false,
        gatewayProps: action.data.data,
      };
    case types.GET_SHOP_GATEWAY_PROPS_FAILED:
      return {
        ...state,
        gatewayPropsLoading: false,
      };
    case types.GET_GATEWAYS_SUCCEED:
      return {
        ...state,
        shopGateways: [],
        changedGateways: [],
      };
    case types.GET_REPORT_SHOP_TOTALS:
      return {
        ...state,
        reportShopsLoading: true,
        error: false,
      };
    case types.GET_REPORT_SHOP_TOTALS_SUCCEED:
      return {
        ...state,
        reportSpops: action.data.data,
        reportsCount: action.data.count,
        reportShopsLoading: false,
        error: false,
      };
    case types.GET_TOP_SHOPS:
      return {
        ...state,
        topShopsLoading: true,
      };
    case types.GET_TOP_SHOPS_SUCCEED:
      return {
        ...state,
        topShops: action.data,
        topShopsLoading: false,
      };
    case types.GET_TOP_SHOPS_FAILED:
      return {
        ...state,
        topShopsLoading: false,
      };
    default:
      return state;
  }
}
