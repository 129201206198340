import React from "react";
import { connect } from "react-redux";
import Joi from "joi-browser";
import CustomForm from "components/UI/Form/index";
import { Col, Row, Form } from "react-bootstrap";
import { addBlackListMerchantItemAction } from "actions/blacklists";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Alert from "../../UI/Alert";

class BlackListCreator extends CustomForm {
  state = {
    data: {
      name: "",
      type: "",
      policy: "",
      description: "",
    },
    cardValues: [],
    countryValues: [],
    ipValues: [],
    maskValues: [],
    binValues: [],
    phoneValues: [],
    types: [
      { name: "ip", label: "ip" },
      { name: "card", label: "card" },
      { name: "country", label: "Country" },
      { name: "mask", label: "Credit card mask" },
      { name: "bin", label: "Credit card BIN" },
      { name: "phone", label: "Phone number" },
    ],
    policies: [
      { name: "allow", label: "allow" },
      { name: "deny", label: "deny" },
    ],
    value: "",
    valid: false,
    errors: {},
    isLoading: false,
  };

  schema = {
    name: Joi.string().required().min(0).max(100).label("Name"),
    type: Joi.string().required().min(0).max(100).label("Type"),
    description: Joi.string().required().min(0).max(100).label("Description"),
    policy: Joi.string().required().min(0).max(100).label("Policy"),
  };

  doSubmit = async () => {
    try {
      this.setState({ isLoading: true });
      if (
        this.state.cardValues.length !== 0 ||
        this.state.ipValues.length !== 0 ||
        this.state.countryValues.length !== 0 ||
        this.state.maskValues.length !== 0 ||
        this.state.binValues.length !== 0 ||
        this.state.phoneValues.length !== 0
      ) {
        const name = this.state.data.name;
        const type = this.state.data.type;
        const description = this.state.data.description;
        const blacklist_type = this.state.data.policy;
        let values = [];
        if (type === "card") values = this.state.cardValues;
        else if (type === "country") values = this.state.countryValues;
        else if (type === "ip") values = this.state.ipValues;
        else if (type === "mask") values = this.state.maskValues;
        else if (type === "bin") values = this.state.binValues;
        else if (type === "phone") values = this.state.phoneValues;
        await this.props.addBlackListMerchantItemAction({
          name,
          type,
          description,
          blacklist_type,
          values,
        });
        swal({
          title: "Record is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } else {
        this.setState({ isLoading: false });
        swal({
          title: "Please, enter value and press \"+\" icon",
          icon: "warning",
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleValueChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  cardOrIp = () => {
    if (this.state.data.type === "card")
      return (
        <Row>
          <Col md={11} sm={11}>
            {
              <div>
                <Form.Label htmlFor="value">Card</Form.Label>
                <Form.Group>
                  <Form.Control
                    name="value"
                    type="text"
                    label="Card"
                    onChange={this.handleValueChange}
                    value={this.state.value}
                  />
                </Form.Group>
              </div>
            }
          </Col>
          <Col md={1} sm={1}>
            <i
              type="button"
              src="fas fa-plus"
              className="fas fa-plus"
              style={{ cursor: "pointer", color: "green", marginTop: "30px" }}
              onClick={this.click}
              value=""
            />
          </Col>
        </Row>
      );
    else if (this.state.data.type === "ip")
      return (
        <Row>
          <Col md={11} sm={11}>
            {
              <div>
                <Form.Label htmlFor="value">IP</Form.Label>
                <Form.Group>
                  <Form.Control
                    name="value"
                    type="text"
                    label="IP"
                    onChange={this.handleValueChange}
                    value={this.state.value}
                  />
                </Form.Group>
              </div>
            }
          </Col>
          <Col md={1} sm={1}>
            <i
              type="button"
              src="fas fa-plus"
              className="fas fa-plus"
              style={{ cursor: "pointer", color: "green", marginTop: "30px" }}
              onClick={this.click}
              value=""
            />
          </Col>
        </Row>
      );
    else if (this.state.data.type === "country")
      return (
        <Row>
          <Col md={11} sm={11}>
            {
              <div>
                <Form.Label htmlFor="value">Country</Form.Label>
                <Form.Group>
                  <Form.Control
                    name="value"
                    type="text"
                    label="IP"
                    onChange={this.handleValueChange}
                    value={this.state.value}
                  />
                </Form.Group>
              </div>
            }
          </Col>
          <Col md={1} sm={1}>
            <i
              type="button"
              src="fas fa-plus"
              className="fas fa-plus"
              style={{ cursor: "pointer", color: "green", marginTop: "30px" }}
              onClick={this.click}
              value=""
            />
          </Col>
        </Row>
      );
    else if (this.state.data.type === "mask")
      return (
        <Row>
          <Col md={11} sm={11}>
            {
              <div>
                <Form.Label htmlFor="value">Credit card mask</Form.Label>
                <Form.Group>
                  <Form.Control
                    name="value"
                    type="text"
                    label="mask"
                    onChange={this.handleValueChange}
                    value={this.state.value}
                  />
                </Form.Group>
              </div>
            }
          </Col>
          <Col md={1} sm={1}>
            <i
              type="button"
              src="fas fa-plus"
              className="fas fa-plus"
              style={{ cursor: "pointer", color: "green", marginTop: "30px" }}
              onClick={this.click}
              value=""
            />
          </Col>
        </Row>
      );
    else if (this.state.data.type === "bin")
      return (
        <Row>
          <Col md={11} sm={11}>
            {
              <div>
                <Form.Label htmlFor="value">Credit card BIN</Form.Label>
                <Form.Group>
                  <Form.Control
                    name="value"
                    type="text"
                    label="bin"
                    onChange={this.handleValueChange}
                    value={this.state.value}
                  />
                </Form.Group>
              </div>
            }
          </Col>
          <Col md={1} sm={1}>
            <i
              type="button"
              src="fas fa-plus"
              className="fas fa-plus"
              style={{ cursor: "pointer", color: "green", marginTop: "30px" }}
              onClick={this.click}
              value=""
            />
          </Col>
        </Row>
      );
    else if (this.state.data.type === "phone")
      return (
        <Row>
          <Col md={11} sm={11}>
            {
              <div>
                <Form.Label htmlFor="value">Phone</Form.Label>
                <Form.Group>
                  <Form.Control
                    name="value"
                    type="text"
                    label="phone"
                    onChange={this.handleValueChange}
                    value={this.state.value}
                  />
                </Form.Group>
              </div>
            }
          </Col>
          <Col md={1} sm={1}>
            <i
              type="button"
              src="fas fa-plus"
              className="fas fa-plus"
              style={{ cursor: "pointer", color: "green", marginTop: "30px" }}
              onClick={this.click}
              value=""
            />
          </Col>
        </Row>
      );
    else return <div></div>;
  };

  valid = () => {
    if (this.state.data.type === "card") {
      const valid = Joi.validate(this.state.value, Joi.string().creditCard());
      if (valid.error)
        swal({
          title: `${valid.error}`,
          icon: "warning",
        });
      else
        this.setState({
          valid: true,
        });
    } else if (this.state.data.type === "ip") {
      const valid = Joi.validate(
        this.state.value,
        Joi.string().ip({ version: [ "ipv4", "ipv6" ] })
      );
      if (valid.error)
        swal({
          title: `${valid.error}`,
          icon: "warning",
        });
      else
        this.setState({
          valid: true,
        });
    } else if (this.state.data.type === "country") {
      const valid = Joi.validate(
        this.state.value,
        Joi.string()
          .regex(/^[A-Z]{2}$/)
          .error(() => {
            return {
              message: "Country must be Alpha-2 code",
            };
          })
          .required()
      );
      if (valid.error)
        swal({
          title: `${valid.error}`,
          icon: "warning",
        });
      else
        this.setState({
          valid: true,
        });
    } else if (this.state.data.type === "mask") {
      const valid = Joi.validate(this.state.value, Joi.string().required());
      if (valid.error)
        swal({
          title: `${valid.error}`,
          icon: "warning",
        });
      else
        this.setState({
          valid: true,
        });
    } else if (this.state.data.type === "bin") {
      const valid = Joi.validate(
        this.state.value,
        Joi.string()
          .regex(/^[0-9]{6}$/)
          .error(() => {
            return {
              message: "Bin is invalid",
            };
          })
          .required()
      );
      if (valid.error)
        swal({
          title: `${valid.error}`,
          icon: "warning",
        });
      else
        this.setState({
          valid: true,
        });
    } else if (this.state.data.type === "Phone") {
      const valid = Joi.validate(
        this.state.value,
        Joi.string()
          .regex(/^[0-9]{5,16}$/)
          .error(() => {
            return {
              message: "Phone must contain only digits",
            };
          })
          .required()
      );
      if (valid.error)
        swal({
          title: `${valid.error}`,
          icon: "warning",
        });
      else
        this.setState({
          valid: true,
        });
    }
  };

  click = async () => {
    await this.valid();
    if (this.state.value !== "" && this.state.valid === true) {
      await this.change(this.state.value);
      this.setState({
        value: "",
      });
    }
  };

  change = (val) => {
    if (this.state.data.type === "card")
      this.setState((prevState) => {
        return {
          cardValues: [ ...prevState.cardValues, val ],
          valid: false,
        };
      });
    else if (this.state.data.type === "ip")
      this.setState((prevState) => {
        return {
          ipValues: [ ...prevState.ipValues, val ],
          valid: false,
        };
      });
    else if (this.state.data.type === "country")
      this.setState((prevState) => {
        return {
          countryValues: [ ...prevState.countryValues, val ],
          valid: false,
        };
      });
    else if (this.state.data.type === "mask")
      this.setState((prevState) => {
        return {
          maskValues: [ ...prevState.maskValues, val ],
          valid: false,
        };
      });
    else if (this.state.data.type === "bin")
      this.setState((prevState) => {
        return {
          binValues: [ ...prevState.binValues, val ],
          valid: false,
        };
      });
    else if (this.state.data.type === "phone")
      this.setState((prevState) => {
        return {
          phoneValues: [ ...prevState.phoneValues, val ],
          valid: false,
        };
      });
  };

  delete = (e) => {
    if (this.state.data.type === "card") {
      let cardValues = this.state.cardValues;
      cardValues.splice(e.target.name, 1);
      this.setState({
        cardValues,
      });
    } else if (this.state.data.type === "ip") {
      let ipValues = this.state.ipValues;
      ipValues.splice(e.target.name, 1);
      this.setState({
        ipValues,
      });
    } else if (this.state.data.type === "country") {
      let countryValues = this.state.countryValues;
      countryValues.splice(e.target.name, 1);
      this.setState({
        countryValues,
      });
    } else if (this.state.data.type === "mask") {
      let maskValues = this.state.maskValues;
      maskValues.splice(e.target.name, 1);
      this.setState({
        maskValues,
      });
    } else if (this.state.data.type === "bin") {
      let binValues = this.state.binValues;
      binValues.splice(e.target.name, 1);
      this.setState({
        binValues,
      });
    } else if (this.state.data.type === "phone") {
      let phoneValues = this.state.phoneValues;
      phoneValues.splice(e.target.name, 1);
      this.setState({
        phoneValues,
      });
    }
  };

  render() {
    let row = [];
    let indexCard = 0;
    let indexValue = 0;
    if (this.state.data.type === "card") {
      this.state.cardValues.forEach((item) => {
        row = [
          ...row,
          <li
            className="list-group-item"
            style={{ textAlign: "right", backgroundColor: "#87cefa" }}
            key={indexCard}
          >
            {item + " "}
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", color: "red" }}
              name={indexCard}
              onClick={this.delete}
            />
          </li>,
        ];
        indexCard++;
      });
    } else if (this.state.data.type === "ip") {
      this.state.ipValues.forEach((item) => {
        row = [
          ...row,
          <li
            className="list-group-item"
            style={{ textAlign: "right", backgroundColor: "#87cefa" }}
            key={indexValue}
          >
            {item + " "}
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", color: "red" }}
              name={indexValue}
              onClick={this.delete}
            />
          </li>,
        ];
        indexValue++;
      });
    } else if (this.state.data.type === "country") {
      this.state.countryValues.forEach((item) => {
        row = [
          ...row,
          <li
            className="list-group-item"
            style={{ textAlign: "right", backgroundColor: "#87cefa" }}
            key={indexValue}
          >
            {item + " "}
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", color: "red" }}
              name={indexValue}
              onClick={this.delete}
            />
          </li>,
        ];
        indexValue++;
      });
    } else if (this.state.data.type === "mask") {
      this.state.maskValues.forEach((item) => {
        row = [
          ...row,
          <li
            className="list-group-item"
            style={{ textAlign: "right", backgroundColor: "#87cefa" }}
            key={indexValue}
          >
            {item + " "}
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", color: "red" }}
              name={indexValue}
              onClick={this.delete}
            />
          </li>,
        ];
        indexValue++;
      });
    } else if (this.state.data.type === "bin") {
      this.state.binValues.forEach((item) => {
        row = [
          ...row,
          <li
            className="list-group-item"
            style={{ textAlign: "right", backgroundColor: "#87cefa" }}
            key={indexValue}
          >
            {item + " "}
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", color: "red" }}
              name={indexValue}
              onClick={this.delete}
            />
          </li>,
        ];
        indexValue++;
      });
    } else if (this.state.data.type === "phone") {
      this.state.phoneValues.forEach((item) => {
        row = [
          ...row,
          <li
            className="list-group-item"
            style={{ textAlign: "right", backgroundColor: "#87cefa" }}
            key={indexValue}
          >
            {item + " "}
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", color: "red" }}
              name={indexValue}
              onClick={this.delete}
            />
          </li>,
        ];
        indexValue++;
      });
    } else row = [];
    return (
      <div className="card">
        <div className="content">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col>{this.renderInput("name", "Name")}</Col>
            </Row>
            <Row>
              <Col>{this.renderSelect("type", "Type", this.state.types)}</Col>
            </Row>
            <Row>
              <Col>
                {this.renderSelect("policy", "Policy", this.state.policies)}
              </Col>
            </Row>
            <Row>
              <Col>{this.renderInput("description", "Description")}</Col>
            </Row>
            <Row>
              <Col>{this.cardOrIp()}</Col>
            </Row>
            {row}
            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                this.renderButton("Add")
              )}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.transactions.order,
  };
};

export default connect(mapStateToProps, {
  addBlackListMerchantItemAction,
  showModal,
})(BlackListCreator);

BlackListCreator.propTypes = {
  addBlackListMerchantItemAction: PropTypes.func,
  showModal: PropTypes.func,
};
