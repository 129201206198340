import * as types from "../constants/actionTypes";

const initialState = {
  merchants: [],
  merchantsCount: [],
  merchantsTables: [],
  merchantsTablesCount: "",
  groups: [],
  groupsCount: [],
  groupsTables: [],
  groupsTablesCount: "",
  partners: [],
  partnersCount: [],
  partnersTables: [],
  partnersTablesCount: "",
  logins: [],
  loginsCount: [],
  adminsTables: [],
  adminsTablesCount: "",
  loginsTables: [],
  loginsTablesCount: "",
  terminalsTables: [],
  terminalsTablesCount: "",
  transactionTables: [],
  transactionTablesCount: "",
  shopTables: [],
  shopTablesCount: "",
  currencyTables: [],
  currencyTablesCount: "",
  gatewayTables: [],
  gatewayTablesCount: "",
  accountTables: [],
  accountTablesCount: "",
  serviceTables: [],
  serviceTablesCount: "",

  loading: false,
  error: false,
};

export default function audit(state = initialState, action) {
  switch (action.type) {
    case types.GET_AUDIT_MERCHANT_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_MERCHANT_TABLES_SUCCEED:
      return {
        ...state,
        merchantsTables: action.data.data,
        merchantsTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_MERCHANT_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_MERCHANT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_MERCHANT_SUCCEED:
      return {
        ...state,
        merchants: action.data.data,
        merchantsCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_MERCHANT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_GROUPS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_GROUPS_SUCCEED:
      return {
        ...state,
        groups: action.data.data,
        groupsCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_GROUP_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_GROUP_TABLES_SUCCEED:
      return {
        ...state,
        groupsTables: action.data.data,
        groupsTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_GROUP_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_PARTNERS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_PARTNERS_SUCCEED:
      return {
        ...state,
        partners: action.data.data,
        partnersCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_PARTNERS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_LOGINS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_LOGINS_SUCCEED:
      return {
        ...state,
        logins: action.data.data,
        loginsCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_LOGINS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_PARTNER_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_PARTNER_TABLES_SUCCEED:
      return {
        ...state,
        partnersTables: action.data.data,
        partnersTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_PARTNER_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_ADMIN_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_ADMIN_TABLES_SUCCEED:
      return {
        ...state,
        adminsTables: action.data.data,
        adminsTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_ADMIN_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_LOGIN_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_LOGIN_TABLES_SUCCEED:
      return {
        ...state,
        loginsTables: action.data.data,
        loginsTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_LOGIN_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_TERMINAL_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_TERMINAL_TABLES_SUCCEED:
      return {
        ...state,
        terminalsTables: action.data.data,
        terminalsTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_TERMINAL_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_TRANSACTION_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_TABLES_SUCCEED:
      return {
        ...state,
        transactionTables: action.data.data,
        transactionTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_SHOP_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_SHOP_TABLES_SUCCEED:
      return {
        ...state,
        shopTables: action.data.data,
        shopTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_SHOP_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_AUDIT_CURRENCY_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_CURRENCY_TABLES_SUCCEED:
      return {
        ...state,
        currencyTables: action.data.data,
        currencyTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_CURRENCY_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_GATEWAY_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_GATEWAY_TABLES_SUCCEED:
      return {
        ...state,
        gatewayTables: action.data.data,
        gatewayTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_GATEWAY_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_ACCOUNT_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_ACCOUNT_TABLES_SUCCEED:
      return {
        ...state,
        accountTables: action.data.data,
        accountTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_ACCOUNT_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_TRANSACTION_PROCESSING_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_PROCESSING_TABLES_SUCCEED:
      return {
        ...state,
        transactionProcessingTables: action.data.data,
        transactionProcessingTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_PROCESSING_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_TRANSACTION_DATA_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_DATA_TABLES_SUCCEED:
      return {
        ...state,
        transactionDataTables: action.data.data,
        transactionDataTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_DATA_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_SUCCEED:
      return {
        ...state,
        transactionOverviewsTables: action.data.data,
        transactionOverviewsTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_AUDIT_SERVICE_TABLES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_AUDIT_SERVICE_TABLES_SUCCEED:
      return {
        ...state,
        serviceTables: action.data.data,
        serviceTablesCount: action.data.count,
        loading: false,
        error: false,
      };
    case types.GET_AUDIT_SERVICE_TABLES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
