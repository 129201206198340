import {
  INVERSE_SEARCH,
  RESET_SEARCH,
  INPUT_SEARCH_IN_ADMINS_LOGS,
  INPUT_SEARCH_IN_MERCHANTS_LOGS,
  INPUT_SEARCH_IN_GROUPS_LOGS,
  INPUT_SEARCH_IN_PARTNERS_LOGS,
  INPUT_SEARCH_IN_TRANSACTIONS_LOGS,
  INPUT_SEARCH_IN_SERVICE_LOGS,
  INPUT_SEARCH_IN_ACCOUNTS,
  INPUT_SEARCH_IN_BLACKLIST,
  INPUT_SEARCH_IN_CARDS,
  INPUT_SEARCH_IN_CURRENCIES,
  INPUT_SEARCH_IN_CHARGEBACKS,
  INPUT_SEARCH_IN_GATEWAYS,
  INPUT_SEARCH_IN_GROUPS,
  INPUT_SEARCH_IN_LOGINS,
  INPUT_SEARCH_IN_MERCHANTS,
  INPUT_SEARCH_IN_PARTNERS,
  INPUT_SEARCH_IN_PRIVILEGES,
  INPUT_SEARCH_IN_RATES,
  INPUT_SEARCH_IN_ROLES,
  INPUT_SEARCH_IN_SHOPS,
  INPUT_SEARCH_IN_TRANSACTIONS,
  INPUT_SEARCH_IN_MERCHANT_TRANSACTIONS,
  INPUT_SEARCH_IN_TRANSACTIONS_TEMPLATES,
  INPUT_SEARCH_IN_GLOBAL_BLACKLIST,
  INPUT_SEARCH_IN_MERCHANTS_BLACKLIST,
  INPUT_SEARCH_IN_ADMINS,
  INPUT_SEARCH_IN_ORDERS,
  INPUT_SEARCH_IN_STATEMENTS,
  INPUT_SEARCH_IN_MERCHANTS_AUDIT_TABLE,
  INPUT_SEARCH_IN_GROUPS_AUDIT_TABLE,
  INPUT_SEARCH_IN_PARTNERS_AUDIT_TABLE,
  INPUT_SEARCH_IN_LOGINS_AUDIT_TABLE,
  INPUT_SEARCH_IN_SHOPS_AUDIT_TABLE,
  INPUT_SEARCH_IN_TERMINALS_AUDIT_TABLE,
  INPUT_SEARCH_IN_GATEWAYS_AUDIT_TABLE,
  INPUT_SEARCH_IN_ACCOUNTS_AUDIT_TABLE,
  INPUT_SEARCH_IN_CURRENCIES_AUDIT_TABLE,
  INPUT_SEARCH_IN_TRANSACTION_OVERVIEWS_AUDIT_TABLE,
  INPUT_SEARCH_IN_TRANSACTION_PROCESSING_AUDIT_TABLE,
  INPUT_SEARCH_IN_TRANSACTION_DATA_AUDIT_TABLE,
  INPUT_SEARCH_IN_SERVICE_AUDIT_TABLE,
  INPUT_SEARCH_IN_MISMATCH_TRANSACTION_TABLE,
  SET_CURRENT_SEARCH_DATA,

} from "../constants/actionTypes";

const initialState = {
  isSearch: true,
  reset: false,
  merchantsLogsSearch: {},
  serviceLogsSearch: {},
  accountsSearch: {},
  blacklistSearch: {},
  globalBlacklistSearch: {},
  merchantsBlacklistSearch: {},
  cardsSearch: {},
  currenciesSearch: {},
  chargebacksSearch: {},
  gatewaysSearch: {},
  groupsSearch: {},
  loginsSearch: {},
  merchantsSearch: {},
  partnersSearch: {},
  privilegesSearch: {},
  ratesSearch: {},
  rolesSearch: {},
  shopsSearch: {},
  transactionsSearch: {},
  ordersSearch: {},
  adminsSearch: {},
  statementsSearch: {},
  merchantTransactionsSearch: {},
  transactionsTemplatesSearch: {},
  merchantAuditSearch: {},
  groupAuditSearch: {},
  partnerAuditSearch: {},
  loginAuditSearch: {},
  currentSearchData: {},
  shopAuditSearch: {},
  terminalAuditSearch: {},
  currencyAuditSearch: {}, 
  accountAuditSearch: {},
  gatewayAuditSearch: {},
  transactionAuditSearch: {},
  transactionOverviewAuditSearch: {},
  transactionProcessingAuditSearch: {},
  transactionDataAuditSearch: {},
  serviceAuditSearch: {},
  mismatchTransactionSearch: {}
};

export default function search(state = initialState, action) {
  switch(action.type) {
    case INVERSE_SEARCH:
      return {
        ...state,
        isSearch: action.data
      };
    case RESET_SEARCH:
      return {
        ...initialState,
        reset: true,
        isSearch: true
      };
    case INPUT_SEARCH_IN_ADMINS_LOGS:
      return { 
        ...state,
        reset: false,
        adminsLogsSearch: {
          ...state.adminsLogsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_MERCHANTS_LOGS:
      return { 
        ...state,
        reset: false,
        merchantsLogsSearch: {
          ...state.merchantsLogsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_GROUPS_LOGS:
      return { 
        ...state,
        reset: false,
        groupsLogsSearch: {
          ...state.groupsLogsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_PARTNERS_LOGS:
      return { 
        ...state,
        reset: false,
        partnersLogsSearch: {
          ...state.partnersLogsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_TRANSACTIONS_LOGS:
      return {
        ...state,
        reset: false,
        transactionsLogsSearch: {
          ...state.transactionsLogsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_SERVICE_LOGS:
      return {
        ...state,
        reset: false,
        serviceLogsSearch: {
          ...state.serviceLogsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_ACCOUNTS:
      return { 
        ...state,
        reset: false,
        accountsSearch: {
          ...state.accountsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_BLACKLIST:
      return { 
        ...state,
        reset: false,
        blacklistSearch: {
          ...state.blacklistSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_GLOBAL_BLACKLIST:
      return { 
        ...state,
        reset: false,
        globalBlacklistSearch: {
          ...state.globalBlacklistSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_MERCHANTS_BLACKLIST:
      return { 
        ...state,
        reset: false,
        merchantsBlacklistSearch: {
          ...state.merchantsBlacklistSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_CARDS:
      return { 
        ...state,
        reset: false,
        cardsSearch: {
          ...state.cardsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_CURRENCIES:
      return { 
        ...state,
        reset: false,
        currenciesSearch: {
          ...state.currenciesSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_CHARGEBACKS:
      return { 
        ...state,
        reset: false,
        chargebacksSearch: {
          ...state.chargebacksSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_GATEWAYS:
      return { 
        ...state,
        reset: false,
        gatewaysSearch: {
          ...state.gatewaysSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_GROUPS:
      return { 
        ...state,
        reset: false,
        groupsSearch: {
          ...state.groupsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_ADMINS:
      return { 
        ...state,
        reset: false,
        adminsSearch: {
          ...state.adminsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_LOGINS:
      return { 
        ...state,
        reset: false,
        loginsSearch: {
          ...state.loginsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_MERCHANTS:
      return { 
        ...state,
        reset: false,
        merchantsSearch: {
          ...state.merchantsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_PARTNERS:
      return { 
        ...state,
        reset: false,
        partnersSearch: {
          ...state.partnersSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_PRIVILEGES:
      return { 
        ...state,
        reset: false,
        privilegesSearch: {
          ...state.privilegesSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_RATES:
      return { 
        ...state,
        reset: false,
        ratesSearch: {
          ...state.ratesSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_ROLES:
      return { 
        ...state,
        reset: false,
        rolesSearch: {
          ...state.rolesSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_SHOPS:
      return { 
        ...state,
        reset: false,
        shopsSearch: {
          ...state.shopsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_TRANSACTIONS:
      return { 
        ...state,
        reset: false,
        transactionsSearch: {
          ...state.transactionsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_MERCHANT_TRANSACTIONS:
      return { 
        ...state,
        reset: false,
        merchantTransactionsSearch: {
          ...state.merchantTransactionsSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_TRANSACTIONS_TEMPLATES:
      return {
        ...state,
        reset: false,
        transactionsTemplatesSearch: {
          ...state.transactionsTemplatesSearch,
          ...action.data
        }
      };
    case INPUT_SEARCH_IN_ORDERS:
      return {
        ...state,
        reset: false,
        ordersSearch: {
          ...state.ordersSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_STATEMENTS:
      return {
        ...state,
        reset: false,
        statementsSearch: {
          ...state.statementsSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_MERCHANTS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        merchantAuditSearch: {
          ...state.merchantAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_GROUPS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        groupAuditSearch: {
          ...state.groupAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_PARTNERS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        partnerAuditSearch: {
          ...state.partnerAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_LOGINS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        loginAuditSearch: {
          ...state.loginAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_SHOPS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        shopAuditSearch: {
          ...state.shopAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_TERMINALS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        terminalAuditSearch: {
          ...state.terminalAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_GATEWAYS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        gatewayAuditSearch: {
          ...state.gatewayAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_ACCOUNTS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        accountAuditSearch: {
          ...state.accountAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_CURRENCIES_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        currencyAuditSearch: {
          ...state.currenciesAuditSearch,
          ...action.data
        }
      };  
    case INPUT_SEARCH_IN_TRANSACTION_OVERVIEWS_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        transactionOverviewAuditSearch: {
          ...state.transactionOverviewAuditSearch,
          ...action.data
        }
      };  

    case INPUT_SEARCH_IN_TRANSACTION_PROCESSING_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        transactionProcessingAuditSearch: {
          ...state.transactionProcessingAuditSearch,
          ...action.data
        }
      };  

    case INPUT_SEARCH_IN_TRANSACTION_DATA_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        transactionDataAuditSearch: {
          ...state.transactionDataAuditSearch,
          ...action.data
        }
      };  

    case INPUT_SEARCH_IN_SERVICE_AUDIT_TABLE:
      return {
        ...state,
        reset: false,
        serviceAuditSearch: {
          ...state.serviceAuditSearch,
          ...action.data
        }
      }; 
     
    case INPUT_SEARCH_IN_MISMATCH_TRANSACTION_TABLE:
      return {
        ...state,
        reset: false,
        mismatchTransactionSearch: {
          ...state.mismatchTransactionSearch,
          ...action.data
        }
      }; 

    case SET_CURRENT_SEARCH_DATA:
      return {
        ...state,
        reset: false,
        currentSearchData: {
          ...state.currentSearchData,
          ...action.data
        }
      };
    default:
      return state;
  }
}