import { getTransactionsLogsAction } from "actions/logs";
import { reset, searchInTransactionsLogs } from "actions/search";
import { Export } from "components/Export";
import Modal from "components/UI/Modal";
import Select from "components/UI/MultiSelect";
import Pagination from "components/UI/Pagination";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table";
import { saveAs } from "file-saver";
import { parseResponse } from "helpers/parseResponse.js";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import { snakeCase } from "snake-case";
import ButtonFilter from "../../../factories/button";
import { cutGuid } from "../../../helpers/cutGuid";
import { getTransactionsLogs } from "../../../services/paymentBackendAPI/logs/logs";
import Alert from "../../UI/Alert";
import { Copy } from "../../UI/CopyToClipboard";

class TransactionLogs extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,
    isSearch: false,
    searchInput: false,
  };

  tryStringToJson = (str) => {
    try {
      return JSON.stringify(str, null, "\t");
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  exportData = async () => {
    const a = await getTransactionsLogs(this.props.searchData);
    if (a.data && a.data.data) {
      const header = this.columns.map((column) => column.label);
      const headerMeta = this.columns.map((column) => {
        column.path = column.path && snakeCase(column.path);
        column.key = column.key && snakeCase(column.key);
        return column;
      });
      const dataSet = a.data.data.map((item) =>
        headerMeta
          .map((column) => item[column.path] || item[column.key] || "")
          .join(",")
      );

      const blob = new Blob([ header.join(","), "\n", dataSet.join("\n") ], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, "test.csv");
    }
  };

  token = getAuthData() ? getAuthData().userPayload : {};

  Params = (data) => {
    return (
      <Card>
        <Card.Body>
          <div className="param-container">
            <span style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
              {this.tryStringToJson(data)}
            </span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  columns = [
    {
      path: "request_id",
      label: "Request guid",
      content: (transaction) => (
        <>
          <Copy text={transaction.request_id} />
          {cutGuid(transaction.request_id)}
        </>
      ),
    },
    {
      path: "createdAt",
      label: "Created at",
      align: "center",
      content: (log) =>
        moment(log.createdAt).utcOffset(0).format("DD.MM.YYYY HH:mm:ss"),
    },
    {
      path: "step_info",
      label: "Step info",
    },
    {
      path: "transaction_guid",
      label: "Transaction guid",
      key: "transaction_guid",
      content: (log) => {
        if (log.transaction_guid) {
          return (
            <>
              <Copy text={log.transaction_guid} />
              <Link
                className="link"
                to={`/about/processing/${log.transaction_guid}`}
              >
                {log.transaction_guid ? cutGuid(log.transaction_guid) : null}
              </Link>
            </>
          );
        } else return null;
      },
      textContent: (log) => log.transaction_guid,
    },
    this.token.merchant || this.token.group || this.token.partner
      ? undefined
      : {
          path: "partner_name",
          label: "Partner",
          content: (log) => (
            <Link className="link" to={`/about/partner/${log.partner_guid}`}>
              {log.partner_name}
            </Link>
          ),
          textContent: (log) => log.partner_name,
        },
    this.token.merchant || this.token.group
      ? undefined
      : {
          path: "group_name",
          label: "Group",
          content: (log) => (
            <Link className="link" to={`/about/group/${log.group_guid}`}>
              {log.group_name}
            </Link>
          ),
          textContent: (log) => log.group_name,
        },
    this.token.merchant
      ? undefined
      : {
          path: "merchant_name",
          label: "Merchant",
          content: (log) => (
            <Link className="link" to={`/about/merchant/${log.merchant_guid}`}>
              {log.merchant_name}
            </Link>
          ),
          textContent: (log) => log.merchant_name,
        },
    {
      path: "shop_name",
      label: "Shop",
      content: (log) => (
        <Link className="link" to={`/about/shop/${log.shop_guid}`}>
          {log.shop_name}
        </Link>
      ),
      textContent: (log) => log.shop_name,
    },
    {
      path: "message",
      label: "Message",
      key: "message",
      content: (transactionLog) =>
        transactionLog.message ? (
          <Modal
            dialogClassName="modal-creator"
            content={this.Params}
            contentProps={{ data: transactionLog.message }}
            button={
              <button type="button" className="btn btn-table">
                Show
              </button>
            }
          />
        ) : null,
      textContent: (transactionLog) => JSON.stringify(transactionLog.message),
      align: "center",
    },
  ];

  async componentDidMount() {
    if (this.checkState())
      await this.props.getTransactionsLogsAction({
        page: 1,
        items: this.props.count,
        transaction_guid: this.props.location.state.orderGuid,
        from_order: true,
      });
    else
      await this.props.getTransactionsLogsAction({
        page: 1,
        items: this.state.pageSize,
      });
  }

  updateData = (value) => {
    this.setState({ searchInput: value });
  };

  checkState = () => {
    if (this.props.location !== undefined) {
      let state = this.props.location.state ? this.props.location.state : null;
      return state;
    }
  };

  componentWillUnmount = () => {
    this.props.reset();
  };

  handleSelectPageSize = (e) => {
    if (e.name === "All") {
      if (this.state.isSearch || this.state.searchInput)
        this.props.getTransactionsLogsAction({
          page: 1,
          items: this.props.count,
          ...this.props.searchData,
        });
      else
        this.props.getTransactionsLogsAction({
          page: 1,
          items: this.props.count,
        });
      this.setState({
        pageSize: String(this.props.count),
        currentPage: 1,
      });
    } else {
      if (this.state.isSearch || this.state.searchInput)
        this.props.getTransactionsLogsAction({
          page: 1,
          items: e.name,
          ...this.props.searchData,
        });
      else
        this.props.getTransactionsLogsAction({
          page: 1,
          items: e.name,
        });
      this.setState({
        pageSize: e.name,
        currentPage: 1,
      });
    }
  };

  handlePageChange = async (page) => {
    if (this.state.isSearch || this.state.searchInput)
      await this.props.getTransactionsLogsAction({
        page,
        items: this.state.pageSize,
        ...this.props.searchData,
      });
    else
      await this.props.getTransactionsLogsAction({
        page,
        items: this.state.pageSize,
      });
    this.setState({
      currentPage: page,
    });
  };

  handleSearch = async () => {
    this.setState({
      isSearch: true,
      currentPage: 1,
    });
    if (this.checkState())
      await this.props.getTransactionsLogsAction({
        page: 1,
        items: this.props.count,
        transaction_guid: this.props.location.state.orderGuid,
        from_order: true,
        ...this.props.searchData,
      });
    else
      await this.props.getTransactionsLogsAction({
        page: 1,
        items: this.state.pageSize,
        ...this.props.searchData,
      });
  };

  handleReset = async () => {
    this.setState({
      isSearch: false,
      currentPage: 1,
    });
    await this.props.reset();
    if (this.checkState())
      await this.props.getTransactionsLogsAction({
        page: 1,
        items: 100,
        transaction_guid: this.props.location.state.orderGuid,
        from_order: true,
      });
    else
      await this.props.getTransactionsLogsAction({
        page: 1,
        items: this.state.pageSize,
      });
  };

  updateCurrentPage = () => {
    this.setState({ currentPage: 1 });
  };

  getPagedData = () => {
    const { pageSize } = this.state;
    const data = this.props.data ? this.props.data : [];
    const count = this.props.count;
    const pagesCount = count / pageSize + (1 && !!(count % pageSize));
    return { pagesCount, data };
  };

  styles = {
    menu: (styles) => {
      return {
        ...styles,
        top: "auto",
        bottom: "100%",
      };
    },
    width: "100px",
  };

  render() {
    const { currentPage } = this.state;
    const { pagesCount, data } = this.getPagedData();
    // const isPagination = this.checkState();
    if (this.props.loading) return <Spinner />;
    else
      return (
        <React.Fragment>
          <div className="main-table">
            <div className="group-buttons">
              <div className="main-buttons">
                <ButtonFilter />
              </div>
              <div className="secondary-buttons">
                <Export
                  searchData={this.searchData}
                  exportFunction={getTransactionsLogs}
                  columns={this.columns}
                  name={"Transaction logs"}
                />
              </div>
            </div>

            <div style={{ overflowX: "auto", width: "100%" }}>
              <Table
                columns={this.columns}
                search={this.props.searchInTransactionsLogs}
                data={data}
                get={this.props.getTransactionsLogsAction}
                searchData={this.props.searchData}
                keyPath={[ "request_id", "step_info" ]}
                updateData={this.updateData}
                updateCurrentPage={this.updateCurrentPage}
                disableSort
              />
            </div>
            <div className="table-navigation">
              <Pagination
                pagesCount={pagesCount}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
                pageSize={this.state.pageSize}
                count={this.props.count}
              />
              <div className="page-size-container">
                <label>Page size:</label>
                <Select
                  multi={false}
                  name="Select the page size"
                  options={[
                    { guid: "1", name: "10" },
                    { guid: "2", name: "20" },
                    { guid: "3", name: "50" },
                    { guid: "4", name: "100" },
                  ]}
                  onSelect={this.handleSelectPageSize}
                  isSearchable={false}
                  styles={this.styles}
                  placeholder={this.state.pageSize}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.logs.transactionsLogs,
    count: state.logs.transactionsLogsCount,
    loading: state.logs.transactionsLogsLoading,
    searchData: state.search.transactionsLogsSearch,
    isSearch: state.search.isSearch,
  };
};

export default connect(mapStateToProps, {
  getTransactionsLogsAction,
  searchInTransactionsLogs,
  reset,
})(TransactionLogs);

TransactionLogs.propTypes = {
  count: PropTypes.number,
  data: PropTypes.array,
  getTransactionsLogsAction: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  reset: PropTypes.func,
  searchData: PropTypes.array,
  searchInTransactionsLogs: PropTypes.func,
};
