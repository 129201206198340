import React from "react";
import { connect } from "react-redux";
import { HorizontalBarChart } from "../UI/HorizontalBarChart";
import { getTopMerchantsAction } from "../../actions/merchants";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Can from "components/Can";
import { Badge } from "react-bootstrap";
class HorizontalBarChartTopMerchants extends React.Component {
  state = {
    daysCount: 7,
    topMerchantsCount: 10,
    loading: true,
    width: 0,
  };

  componentDidMount = () => {
    if (this.props.daily)
      this.props.getTopMerchantsAction(1, this.state.topMerchantsCount);
    else
      this.props.getTopMerchantsAction(
        this.state.daysCount,
        this.state.topMerchantsCount
      );
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    if (this.props.loading) return <Spinner />;
    else {
      const labelsPie = this.props.topMerchants.map(
        (merchant) => merchant.name
      );

      const datasetsPie = this.props.topMerchants.map((merchant) => {
        return {
          label: merchant.name,
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: merchant.count,
        };
      });

      return (
        <div>
          <Can do="REPORT" on="CURRENCIESOFTRANSACTIONS">
            {/* <HorizontalBarChart
              labels={data.labels}
              datasets={data.datasets}
            /> */}
            <HorizontalBarChart
              name="Top merchants"
              labels={labelsPie}
              datasets={datasetsPie}
              options={{
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
              height={50}
            />
          </Can>
          <Can not do="REPORT" on="CURRENCIESOFTRANSACTIONS">
            {this.state.width > 1200 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : this.state.width > 767 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            )}
          </Can>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    topMerchants: state.merchants.topMerchants,
    loading: state.merchants.topMerchantsLoading,
  };
};

export default connect(mapStateToProps, {
  getTopMerchantsAction,
})(HorizontalBarChartTopMerchants);

HorizontalBarChartTopMerchants.propTypes = {
  daily: PropTypes.string,
  getTopMerchantsAction: PropTypes.func,
  loading: PropTypes.bool,
  topMerchants: PropTypes.array,
};
