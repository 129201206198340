import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Form, Button } from "react-bootstrap";
import {
  getUserAccountAction,
  editUserAccountAction,
} from "actions/useraccounts";
import { parseResponse } from "helpers/parseResponse";
import Spinner from "components/UI/Spinner";
import PasswordStrengthMeter from "components/UI/PasswordIndicator/PasswordStrengthMeter";
import swal from "sweetalert";
import PropTypes from "prop-types";
import Joi from "joi-browser";
import { checkUserExistsAction } from "../../actions/users";
import MultiSelect from "components/UI/MultiSelect";
import { flushTokens } from "../../services/paymentBackendAPI/backendPlatform";

class UserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guid: "",
      username: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      isPasswordConfirmed: false,
      changePassword: false,
      email: "",
      phone: "",
      authType: false,
      firstName: "",
      lastName: "",
      errors: {},
      errorUsernameExists: "",
      usernameExists: "",
      language: "en",
      oldData: {
        firstName: "",
        lastName: "",
      },
      needReason: false,
      reason: "",

      usernameValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      passwordValidation: false,
      reasonValidation: false,
    };
    this.handlePasswordChanges = this.handlePasswordChanges.bind(this);
  }

  schema = {
    guid: Joi.string().required(),
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    username: Joi.string().min(5).required().label("Username"),
    authType: Joi.any().required(),
    language: Joi.string().required().label("Language"),
  };

  passwordSchema = {
    newPassword: Joi.string()
      .required()
      .regex(
        /(?=^.{12,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
      )
      .error(() => {
        return {
          message:
            "Password must be at least 12 characters, including a number, a special, and  an uppercase letter.",
        };
      })
      .label("Password"),
  };

  async componentDidMount() {
    await this.props.getUserAccountAction();
    return this.setState({
      guid: this.props.useraccount.guid,
      username: this.props.useraccount.username,
      oldUsername: this.props.useraccount.username,
      email: this.props.useraccount.email,
      phone: this.props.useraccount.phone,
      authType: this.props.useraccount.auth_type === "login-password-mail",
      firstName: this.props.useraccount.first_name,
      lastName: this.props.useraccount.last_name,
      language: this.props.useraccount.language,
      oldData: {
        firstName: this.props.useraccount.first_name,
        lastName: this.props.useraccount.last_name,
      },

      usernameValidation: this.props.useraccount.username ? true : false,
      firstNameValidation: this.props.useraccount.first_name ? true : false,
      lastNameValidation: this.props.useraccount.last_name ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};

    if (this.state.needReason) {
      data = {
        guid: this.state.guid,
        username: this.state.username,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        authType: this.state.authType,
        language: this.state.language,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        guid: this.state.guid,
        username: this.state.username,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        authType: this.state.authType,
        language: this.state.language,
      };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);
    if (this.state.changePassword) {
      let passwordError = Joi.validate(
        { newPassword: this.state.newPassword },
        this.passwordSchema,
        options
      );

      if (passwordError.error && passwordError.error.details[0]) {
        let arrayErrors = error ? error.details : [];
        arrayErrors.push(passwordError.error.details[0]);
        if (error) {
          error.details = arrayErrors;
        } else error = { details: arrayErrors };
      }
    }

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema = {};
    if (this.schema[name]) schema = { [name]: this.schema[name] };
    else schema = { [name]: Joi.string().required() };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  showReason = (name) => {
    const oldData = { ...this.state.oldData };
    let changedData = false;
    if (name === "firstName" || name === "lastName") {
      for (let prop in oldData) {
        changedData = changedData || oldData[prop] !== this.state[prop];
      }
      this.setState({ needReason: changedData });
    }
  };

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState(
      {
        [input.name]: input.value,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason(input.name)
    );

    if (
      input.name === "username" &&
      input.value !== "" &&
      input.value !== this.state.oldUsername &&
      input.value.length > 4
    ) {
      await this.props.checkUserExistsAction({ username: input.value });

      this.setState({
        usernameExists: this.props.usernameExists,
      });

      if (this.state.usernameExists)
        this.setState({
          errorUsernameExists: "Username exists",
        });
      else
        this.setState({
          errorUsernameExists: "",
        });
    }
  };

  isPasswordConfirmed = () => {
    if (this.state.newPassword === this.state.confirmPassword) {
      this.setState({
        isPasswordConfirmed: true,
      });
      return true;
    } else {
      this.setState({
        isPasswordConfirmed: false,
      });
      return false;
    }
  };

  handlePasswordChanges({ currentTarget: input }) {
    const errors = { ...this.state.errors };
    let errorMessage;

    if (this.state.sendMail)
      errorMessage = "Password is not allowed to be empty";
    if (input.value.length < 8)
      errorMessage = "Minimum password length must be 12 characters!";
    if (!/[a-z]/.test(input.value))
      errorMessage = "Password must contain at least 1 small letter!";
    if (!/[A-Z]/.test(input.value))
      errorMessage = "Password must contain at least 1 uppercase letter!";
    if (!/[0-9]/.test(input.value))
      errorMessage = "Password must contain at least 1 number!";
    if (!/[!#$%&()*+,-./:;<=>?@[\]^_`{|}~]/.test(input.value))
      errorMessage = "Password must contain at least 1 special symbol!";

    if (errorMessage) errors.newPassword = errorMessage;
    else delete errors.newPassword;

    this.setState({ newPassword: input.value, errors });
  }

  handleChangePassword = () => {
    this.setState({
      changePassword: !this.state.changePassword,
    });
  };

  cancelChangePassword = () => {
    this.setState({
      changePassword: !this.state.changePassword,
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  handleChangeCheckbox = () => {
    this.setState({
      authType: !this.state.authType,
    });
  };

  onSelectLanguage = (option) => {
    this.setState({
      language: option.name,
    });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState(
      {
        reason: input.value,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason(input.name)
    );
  };

  formValidation = (name) => {
    if (name === "username" && this.state.errorUsernameExists) return "error";
    if (this.state.errors[name] || !this.state[name]) return "error";
    else return "success";
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else if (!this.isPasswordConfirmed())
      swal({
        title: "Passwords don't match",
        icon: "error",
      });
    else {
      const data = this.state;
      try {
        await this.props.editUserAccountAction({
          guid: this.props.useraccount.guid,
          username: data.username,
          first_name:
            data.firstName !== data.oldData.firstName
              ? data.firstName
              : undefined,
          last_name:
            data.lastName !== data.oldData.lastName ? data.lastName : undefined,
          auth_type: data.authType ? "login-password-mail" : "login-password",
          language: data.language,
          old_password:
            data.changePassword === true ? data.password : undefined,
          new_password:
            data.changePassword === true ? data.newPassword : undefined,
          reason: data.needReason ? data.reason : undefined,
        });
        swal({
          title: "Account is updated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        if (data.changePassword) {
          swal({
            title: "Please login again",
            icon: "warning",
            button: false,
            timer: 2000,
          });
          setTimeout(flushTokens, 2000);
        } else {
          await this.props.getUserAccountAction();
          this.setState({
            oldData: {
              firstName: this.props.useraccount.first_name,
              lastName: this.props.useraccount.last_name,
            },
            guid: this.props.useraccount.guid,
            username: this.props.useraccount.username,
            oldUsername: this.props.useraccount.username,
            email: this.props.useraccount.email,
            phone: this.props.useraccount.phone,
            authType:
              this.props.useraccount.auth_type === "login-password-mail",
            firstName: this.props.useraccount.first_name,
            lastName: this.props.useraccount.last_name,

            usernameValidation: this.props.useraccount.username ? true : false,
            firstNameValidation: this.props.useraccount.first_name
              ? true
              : false,
            lastNameValidation: this.props.useraccount.last_name ? true : false,
            passwordValidation: false,
            reasonValidation: false,
            isPasswordConfirmed: false,
            changePassword: false,
            errors: {},
            errorUsernameExists: "",
            usernameExists: "",
            needReason: false,
            reason: "",
          });
        }
      } catch (error) {
        const parsedError = parseResponse(error);
        swal({
          title: parsedError.error,
          text: parsedError.message,
          icon: "error",
        });
      }
    }
  };

  render() {
    const errors = this.state.errors;
    const data = this.state;
    if (this.props.isLoginLoading) {
      return <Spinner />;
    } else
      return (
        <div
          style={{
            width: "70%",
            margin: "auto",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <Form onSubmit={this.handleSubmit} autoComplete="off">
            <div className="card">
              <h3>General info</h3>
              <Col>
                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Label htmlFor="username" style={{ float: "left" }}>
                      Username:
                    </Form.Label>
                    <Form.Group
                      validationState={this.formValidation("username")}
                    >
                      <Form.Control
                        name="username"
                        type="text"
                        value={data.username}
                        onChange={(e) => this.handleChange(e)}
                      />
                      {errors.username && (
                        <span className="validate-error">
                          {errors.username}
                        </span>
                      )}
                      {this.state.errorUsernameExists && (
                        <span className="validate-error">
                          {this.state.errorUsernameExists}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <span
                      style={{
                        float: "left",
                        paddingTop: "10px",
                        color: "grey",
                      }}
                    >
                      Do you want to change your password?
                    </span>
                    {!this.state.changePassword ? (
                      <Button
                        className="btn btn-fill btn-sm"
                        onClick={this.handleChangePassword}
                        style={{ float: "right" }}
                      >
                        Change Password
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-fill btn-sm"
                        onClick={this.cancelChangePassword}
                        style={{ float: "right" }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                {this.state.changePassword ? (
                  <React.Fragment>
                    <Row>
                      <Col className="col-md-3" />
                      <Col className="col-md-6">
                        <Form.Label style={{ float: "left" }}>
                          Current Password:
                        </Form.Label>
                        <Form.Group
                          validationState={this.formValidation("password")}
                        >
                          <Form.Control
                            name="password"
                            type="password"
                            value={data.password}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="col-md-3" />
                    </Row>
                    <Row>
                      <Col className="col-md-3" />
                      <Col className="col-md-6">
                        <Form.Label style={{ float: "left" }}>
                          New Password:
                        </Form.Label>
                        <Form.Group
                          validationState={this.formValidation("newPassword")}
                        >
                          <PasswordStrengthMeter
                            name="newPassword"
                            value={data.newPassword}
                            password={data.newPassword}
                            onChange={this.handlePasswordChanges}
                          />
                          {errors.newPassword && (
                            <span className="validate-error">
                              {errors.newPassword}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col className="col-md-3" />
                    </Row>

                    <Row>
                      <Col className="col-md-3" />
                      <Col className="col-md-6">
                        <Form.Label style={{ float: "left" }}>
                          Confirm New Password:
                        </Form.Label>
                        <Form.Group
                          validationState={this.formValidation(
                            "confirmPassword"
                          )}
                        >
                          <Form.Control
                            name="confirmPassword"
                            type="password"
                            value={data.confirmPassword}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </Form.Group>
                        <span>
                          After changing the password you will need to log in
                          again
                        </span>
                      </Col>
                      <Col className="col-md-3" />
                    </Row>
                  </React.Fragment>
                ) : null}

                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Label style={{ float: "left" }}>Email:</Form.Label>
                    <Form.Group>
                      <Form.Control
                        name="email"
                        type="email"
                        value={data.email}
                        onChange={(e) => this.handleChange(e)}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Label style={{ float: "left" }}>Phone:</Form.Label>
                    <Form.Group>
                      <Form.Control
                        name="phone"
                        type="text"
                        value={data.phone}
                        onChange={(e) => this.handleChange(e)}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Label style={{ float: "left" }}>
                      Two-factor authentication:
                    </Form.Label>
                    <input
                      type="checkbox"
                      onChange={this.handleChangeCheckbox}
                      checked={this.state.authType}
                    />
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Label
                      style={{
                        float: "left",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingBottom: "-15px",
                      }}
                    >
                      Language:
                    </Form.Label>
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Group>
                      <MultiSelect
                        name="Language"
                        options={[
                          { name: "en", guid: "en" },
                          { name: "ru", guid: "ru" },
                        ]}
                        multi={false}
                        defaultValue={this.state.language}
                        onSelect={this.onSelectLanguage}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-3" />
                </Row>
                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Label style={{ float: "left" }}>Name:</Form.Label>
                    <Form.Group
                      validationState={this.formValidation("firstName")}
                    >
                      <Form.Control
                        name="firstName"
                        type="text"
                        value={data.firstName}
                        onChange={(e) => this.handleChange(e)}
                      />
                      {errors.firstName && (
                        <span className="validate-error">
                          {errors.firstName}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                <Row>
                  <Col className="col-md-3" />
                  <Col className="col-md-6">
                    <Form.Label style={{ float: "left" }}>Surname:</Form.Label>
                    <Form.Group
                      validationState={this.formValidation("lastName")}
                    >
                      <Form.Control
                        name="lastName"
                        type="text"
                        value={data.lastName}
                        onChange={(e) => this.handleChange(e)}
                      />
                      {errors.lastName && (
                        <span className="validate-error">
                          {errors.lastName}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col className="col-md-3" />
                </Row>

                {this.state.needReason && (
                  <Row>
                    <Col className="col-md-3" />
                    <Col className="col-md-6">
                      <Form.Label style={{ float: "left" }}>Reason:</Form.Label>
                      <Form.Group
                        validationState={this.formValidation("reason")}
                      >
                        <Form.Control
                          name="reason"
                          type="text"
                          value={data.reason}
                          onChange={this.handleChangeReason}
                        />
                        {errors.reason && (
                          <span className="validate-error">
                            {errors.reason}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-md-3" />
                  </Row>
                )}
              </Col>

              <div>
                <Button
                  className={
                    this.state.usernameValidation &&
                    this.state.firstNameValidation &&
                    this.state.lastNameValidation &&
                    (!this.state.changePassword ||
                      this.state.passwordValidation) &&
                    (!this.state.needReason || this.state.reasonValidation)
                      ? "btn btn-fill btn-success"
                      : "btn btn-fill"
                  }
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    useraccount: state.useraccounts.useraccount,
    isLoginLoading: state.useraccounts.userAccountLoading,
    usernameExists: state.users.users.username_exists,
  };
};

export default connect(mapStateToProps, {
  getUserAccountAction,
  editUserAccountAction,
  checkUserExistsAction,
})(UserAccount);

UserAccount.propTypes = {
  checkUserExistsAction: PropTypes.func,
  editUserAccountAction: PropTypes.func,
  getUserAccountAction: PropTypes.func,
  isLoginLoading: PropTypes.bool,
  useraccount: PropTypes.object,
  usernameExists: PropTypes.bool,
};
