import { backendManagement } from "../backendPlatform";

export const getPrivilegesByLogin = (guid) => {
  return backendManagement.get(`/logins/${guid}/privileges`);
};


// export const getLogins = (args) => {
//   return backendManagement.get("/logins", { params: { ...args } });
// };

// export const getFreeLogins = () => {
//   return backendManagement.get("/logins/free");
// };

// export const createLogin = data => {
//   return backendManagement.post("/logins", data);
// };

// export const getLogin = guid => {
//   return backendManagement.get(`/logins/${guid}`);
// };

// export const getLoginUserProfile = guid => {
//   return backendManagement.get(`/user_profiles/${guid}`);
// };

// export const updateLogin = data => {
//   return backendManagement.post("/logins", data);
// };

// export const getLoginRoles = (guid, page, items) => {
//   return backendManagement.get(`/logins/${guid}/roles`, { params: { items, page } });
// };

// export const addLoginRoles = (guid, data) => {
//   return backendManagement.post(`/logins/${guid}/roles`, data);
// };

// export const deleteLogin = guid => {
//   return backendManagement.post("/logins", { guid, delete: true });
// };