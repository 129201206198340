import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deletePartnerAction, getAllPartners } from "../../actions/partners";
import { reset, searchInPartners } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import { getPartners } from "../../services/paymentBackendAPI/management/partners";
import PartnerCreator from "./Creator";
import PartnerEditor from "./Editor";

const columns = [
  {
    path: "partner_name",
    label: "Partner name",
    content: (partner) => (
      <Link className="link" to={`/about/partner/${partner.partner_guid}`}>
        {partner.partner_name}
      </Link>
    ),
    textContent: (partner) => partner.partner_name,
  },
  { path: "partner_type", label: "Partner type" },
  { path: "username", label: "Username" },
  { path: "email", label: "Email" },
  {
    key: "enabled",
    content: (login) => (
      <i
        className={
          login.enabled ? "icon-success icon green" : "icon-failed icon red"
        }
      />
    ),
    textContent: (login) => (login.enabled ? "Enabled" : "Disabled"),
    label: "Status",
    align: "center",
    colWidth: 4,
  },
  ability.can("EXECUTE", "USERPARTNER") && {
    key: "edit",
    content: (partner) => (
      <Modal
        header="Edit partner"
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        content={PartnerEditor}
        contentProps={{ guid: partner.partner_guid, type: "partner" }}
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "USERPARTNER") && {
    key: "deletePartner",
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.partners.partnersList,
    count: state.partners.count,
    table: state.store.table,
    searchData: state.search.partnersSearch,
    isSearch: state.search.isSearch,
    loading: state.partners.partnersLoading,
    name: "USERPARTNER",
    columns,
    columnsComponent: createColComponentForTable(columns),
    keyPath: "partner_guid",
    modal: <PartnerCreator />,
    exportFunction: getPartners,
  };
};

export default connect(mapStateToProps, {
  get: getAllPartners,
  delete: deletePartnerAction,
  search: searchInPartners,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
