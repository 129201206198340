import React from "react";
import { connect } from "react-redux";
import { HorizontalBarChart } from "../UI/HorizontalBarChart";
import { getMerchantTotalsDataAction } from "../../actions/transactions";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Can from "components/Can";
import { Badge, Card, Col, Row } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

class MerchantTotalBar extends React.Component {
  state = {
    periods: [
      { name: "Period", guid: "0", value: "0", label: "Period" },
      { name: "Last week", guid: "7", value: "7", label: "Last week" },
      { name: "Last month", guid: "30", value: "30", label: "Last month" },
    ],
    selectedPeriod: { name: "Period", guid: "0", value: "0", label: "Period" },
    startDate: moment().utcOffset(0).format("YYYY-MM-DDTHH:mm:ss").replace(/\D\d{2}\w/, "-01T"),
    endDate: moment().utcOffset(0).format("YYYY-MM-DDTHH:mm:ss"),
    datasets: [],
    labels: [],
    loading: true,
    width: 0,
  };

  componentDidMount = async () => {
    await this.loadData();

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      if (this.state.selectedPeriod.value === "0") {
        await this.props.getMerchantTotalsDataAction({
          date_from: this.state.startDate,
          date_till: this.state.endDate,
        });
      } else if (this.state.selectedPeriod.value === "30") {
        await this.props.getMerchantTotalsDataAction({
          date_from: moment().utcOffset(0).format("YYYY-MM-DDTHH:mm:ss").replace(/\D\d{2}\w/, "-01T"),
          date_till: moment().utcOffset(0).endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
        });
      } else if (this.state.selectedPeriod.value === "7") {
        await this.props.getMerchantTotalsDataAction({
          last_days: this.state.selectedPeriod.value,
        });
      }
      else if (this.state.selectedPeriod.value === "1") {
        await this.props.getMerchantTotalsDataAction({
          date_from: moment().format("YYYY-MM-DDTHH:mm:ss"),
          date_till: moment().format("YYYY-MM-DDTHH:mm:ss"),
        });
      }
      let response = this.props.response.amounts || [];
      let labels = [];
      let data = [];
      response.forEach(item => {
        labels.push(item.merchant_name);
        data.push(item.merchant_amount);
      });
      let datasets = [
        {
          label: "Total",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: data,
        }
      ];

      this.setState({ labels, datasets });
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  onSelect = (option) => {
    this.setState({ selectedPeriod: option }, () => this.loadData());
  };

  onDateChange = async (start, end) => {
    this.setState({
      startDate: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
    }, () => this.loadData());
  };

  render() {

    return (
      <div className="admin-dashboard">
        <Row>
          <Col lg={12} md={12} sm={12}>

            <Card>
              <Card.Header as="h5" style={{ fontWeight: "bold", textTransform: "uppercase" }}>MERCHANT TOTAL (Turnover)</Card.Header>
              <Card.Body>

                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                    {this.state.selectedPeriod.value === "0" &&
                      <DateRangePicker
                        onCallback={this.onDateChange}
                        initialSettings={{
                          startDate: moment(this.state.startDate).format("DD.MM.YYYY"),
                          endDate: moment(this.state.endDate).format("DD.MM.YYYY"),
                          locale: {
                            format: "DD.MM.YYYY",
                          },
                        }}
                      >
                        <input
                          type="text"
                          className="text-input daterange-input form-control"
                          style={{ fontSize: "13px" }}
                        />
                      </DateRangePicker>
                    }
                  </Col >
                  <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <MultiSelect
                      name="type"
                      options={this.state.periods}
                      multi={false}
                      onSelect={this.onSelect}
                      placeholder="Select period"
                      defaultValue={this.state.selectedPeriod}
                    />
                  </Col>
                </Row>
                {this.state.loading ? (
                  <div className="loader" style={{ height: "100px" }}>
                    <Spinner />
                  </div>
                ) : (
                  <div className="chart">
                    <Can do="REPORT" on="CURRENCIESOFTRANSACTIONS">
                      <HorizontalBarChart
                        name="merchant total"
                        labels={this.state.labels}
                        datasets={this.state.datasets}
                        options={{
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        height={50}
                      />
                    </Can>
                    <Can not do="REPORT" on="CURRENCIESOFTRANSACTIONS">
                      {this.state.width > 1200 ? (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(45%)",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                            No privileges
                        </Badge>
                      ) : this.state.width > 767 ? (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(45%)",
                            marginTop: "30px",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                            No privileges
                        </Badge>
                      ) : (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(45%)",
                            marginTop: "30px",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                                No privileges
                        </Badge>
                      )}
                    </Can>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

        </Row>


      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    response: state.transactions.merchantTotals,
  };
};

export default connect(mapStateToProps, {
  getMerchantTotalsDataAction,
})(MerchantTotalBar);

MerchantTotalBar.propTypes = {
  getMerchantTotalsDataAction: PropTypes.func,
};
