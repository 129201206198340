import MerchantsBlacklistCreator from "components/BlackList/Merchants/Creator";
import BlackListCreator from "components/BlackList/Merchants/CreatorForMerchants";
import MerchantsBlackListMain from "components/BlackList/Merchants/Main";
// import Can from "components/Can";
import Modal from "components/UI/Modal";
import React from "react";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import Content from "../../Content";
import ability from "config/ability";

const MerchantsBlackList = () => {
  const token = getAuthData();
  return (
    <Content>
      <MerchantsBlackListMain
        modalComponent={
          // <Can do="EXECUTE" on="BLACKLISTS">
          <React.Fragment allowed={ability.can("EXECUTE", "BLACKLISTS")}>
            {token && token.userPayload && token.userPayload.merchant ? (
              <Modal
                header="Create item"
                content={BlackListCreator}
                button={
                  <button type="button" className="btn btn-fill btn-primary">
                    Create item
                  </button>
                }
              />
            ) : (
              <Modal
                header="Create item"
                content={MerchantsBlacklistCreator}
                button={
                  <button type="button" className="btn btn-fill btn-primary">
                    Create item
                  </button>
                }
              />
            )}
          </React.Fragment>
          // </Can>
        }
      />
    </Content>
  );
};

export default MerchantsBlackList;
