import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Params } from "../common/Params";
import Table from "components/UI/Table";
import Pagination from "components/UI/Pagination";
import moment from "moment";
import Modal from "components/UI/Modal";

export const TableAudit = ({
  data,
  count,
  handleChangePage,
  page,
  pageSize,
  rows,
}) => {
  const columnsHistory = [
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },

    { path: "author_username", label: "Author", key: "author_username" },
    {
      label: "Detail",
      key: "detail",
      align: "center",
      content: (el) => (
        <Modal
          content={Params}
          contentProps={{ rows: rows, data: el }}
          buttonName="Show"
          button={
            <button type="button" className="btn btn-table">
              Show
            </button>
          }
        />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columnsHistory}
        data={data ? data.map((item) => ({ ...item, key: uuidv4() })) : []}
        keyPath="key"
        disableSearch
        disableSort
      />
      <Pagination
        pagesCount={count / pageSize + (1 && !!(count % pageSize))}
        currentPage={page}
        onPageChange={handleChangePage}
        pageSize={pageSize}
        count={count}
      />
    </>
  );
};
