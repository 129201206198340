import React, { Component } from "react";
import Joi from "joi-browser";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import swal from "sweetalert";
import { connect } from "react-redux";
import Alert from "../../UI/Alert";
import ReactLoading from "react-loading";
import queryString from "query-string";
import {
  getAllShops,
  getShopTerminalsAction,
  getShopTerminalAction,
} from "actions/shops";
import {
  addTransactionAction,
  getTransactionTypesForGatewayAction,
} from "actions/transactions";
import config from "config/";
import { addParamsInUrl } from "helpers/addParamsInUrl";
import { parseResponse } from "helpers/parseResponse";
import { numberFormatter } from "../../../helpers/numberFormatter";

class NewForm extends Component {
  state = {
    data: {
      shop: "",
      tracking_id: "",
      terminal: "",
      amount: 100,
      currency: "",
      type: "",
      terminalSecret: "",
      terminalHashKey: "",
      transactionId: "",
      reason: "",
    },

    cardData: {
      cardNumber: "",
      verificationValue: "",
      nameOnCard: "",
      expMonth: "",
      expYear: "",
    },

    testData: {
      description: "test data",
      first_name: "John",
      last_name: "Doe",
      country: "US",
      city: "New York",
      address: "Groove st.",
      zip: "646464",
      email: "mail@mail.com",
      ip: "200.200.200.200",
      phone: "48000111222",
    },

    withCard: false,
    isNeedTransactionId: false,
    isNeedReason: false,
    isChangeTestData: false,

    errors: {},
    disabledTerminal: true,
    disabledType: true,
  };

  cardSchema = {
    cardNumber: Joi.string()
      .min(12)
      .max(19)
      .creditCard()
      .required()
      .label("Card Number"),
    verificationValue: Joi.number().required().label("Security code"),
    nameOnCard: Joi.string().required().min(0).max(150).label("Name on card"),
    expMonth: Joi.number()
      .positive()
      .min(1)
      .max(12)
      .required()
      .label("Expiry month"),
    expYear: Joi.number().positive().min(2021).required().label("Expiry year"),
  };

  mainSchema = {
    shop: Joi.string().required().min(0).label("Shop"),
    terminal: Joi.string().required().min(0).label("Terminal"),
    terminalSecret: Joi.string().required().label("Secret"),
    terminalHashKey: Joi.string().required().label("Hash Key"),
    type: Joi.string().required().label("Transaction type"),
    transactionId: Joi.string().label("Transaction ID").allow("").optional(),
    amount: Joi.number().required().label("Amount"),
    currency: Joi.string().required().label("Currency"),
    tracking_id: Joi.string().required().min(0).label("Tracking id"),
    reason: Joi.any(),
  };

  testDataSchema = {
    description: Joi.string().required().label("Description"),
    first_name: Joi.string().required().label("First Name"),
    last_name: Joi.string().required().label("Last Name"),
    country: Joi.string()
      .required()
      .regex(/^[A-Z]{2}$/)
      .label("Country")
      .error(() => {
        return {
          message: "Country must be Alpha-2 code",
        };
      }),
    city: Joi.string().required().min(0).label("City"),
    address: Joi.string().required().min(0).label("Address"),
    zip: Joi.string().required().min(0).label("Zip"),
    email: Joi.string().required().email().label("Email"),
    ip: Joi.string()
      .ip({ version: [ "ipv4", "ipv6" ] })
      .label("IP"),
    phone: Joi.string().required().regex(/^[0-9]{5,16}$/).label("Phone"),
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.props.getAllShops();
    const values = queryString.parse(window.location.hash.split("?")[1]);
    if (values.status === "succeed")
      swal({
        title: "Transaction submitted",
        text: values.guid ? `Transaction guid: ${values.guid}` : "Success",
        icon: "success",
      });
    else if (values.status === "failed")
      Alert({
        type: "error",
        message: values.guid
          ? `Transaction failed, guid: ${values.guid} `
          : "Failed",
      });
    window.location.replace(window.location.href.split("?")[0]);
    this.setState({ isLoading: false });
  }

  validate = () => {
    const options = { abortEarly: false };
    let schema = this.mainSchema;
    let data = { ...this.state.data };
    if (this.state.withCard) {
      schema = { ...schema, ...this.cardSchema };
      data = { ...data, ...this.state.cardData };
    }
    if (this.state.isChangeTestData) {
      schema = { ...schema, ...this.testDataSchema };
      data = { ...data, ...this.state.testData };
    }
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    let schema = this.mainSchema;
    if (this.state.withCard) schema = { ...schema, ...this.cardSchema };
    if (this.state.isChangeTestData)
      schema = { ...schema, ...this.testDataSchema };
    const obj = { [name]: value };
    schema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  onSelectShop = async (option) => {
    this.setState({ isLoading: true });
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: "shop",
      value: option.guid,
    });
    if (errorMessage) errors.shop = errorMessage;
    else delete errors.shop;

    const data = { ...this.state.data };
    data.shop = option.guid;

    this.setState({ data, errors });

    await this.props.getShopTerminalsAction(option.guid);
    this.setState({ disabledTerminal: false, isLoading: false });
  };

  onSelectTerminal = async (option) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: "terminal",
      value: option.guid,
    });
    if (errorMessage) errors.terminal = errorMessage;
    else delete errors.terminal;

    const array = this.props.terminals.filter(
      (terminal) => terminal.guid === option.guid
    );
    await this.props.getTransactionTypesForGatewayAction(array[0].gateway_guid);
    await this.props.getShopTerminalAction(this.state.data.shop, option.guid);

    const data = { ...this.state.data };
    data.terminal = option.guid;
    data.currency = array[0].currency_code;
    data.terminalSecret = this.props.terminal.secret;
    data.terminalHashKey = this.props.terminal.hash_key;

    const secretErrorMessage = this.validateProperty({
      name: "terminalSecret",
      value: this.props.terminal.secret,
    });
    if (secretErrorMessage) errors.terminalSecret = secretErrorMessage;
    else delete errors.terminalSecret;
    const hashKeyErrorMessage = this.validateProperty({
      name: "terminalHashKey",
      value: this.props.terminal.hash_key,
    });
    if (hashKeyErrorMessage) errors.terminalHashKey = hashKeyErrorMessage;
    else delete errors.terminalHashKey;
    const currencyErrorMessage = this.validateProperty({
      name: "currency",
      value: array[0].currency_code,
    });
    if (currencyErrorMessage) errors.currency = currencyErrorMessage;
    else delete errors.currency;
    
    this.setState({ disabledType: false, data, errors });
  };

  onSelectType = async (option) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: "type",
      value: option.name,
    });
    if (errorMessage) errors.type = errorMessage;
    else delete errors.type;

    const data = { ...this.state.data };
    data.type = option.name;
    if ([ "Refund", "Capture", "Cancel", "Reverse" ].includes(option.name))
      this.setState({
        isNeedTransactionId: true,
        withCard: false,
      });
    else if ([ "Authorization", "Payment" ].includes(option.name))
      this.setState({ isNeedTransactionId: false });
    if ([ "Refund", "Cancel", "Reverse" ].includes(option.name))
      this.setState({
        isNeedReason: true,
      });
    else if ([ "Authorization", "Payment", "Capture" ].includes(option.name))
      this.setState({ isNeedReason: false });
    this.setState({ data, errors });
  };

  handleChangeCardDataCheckbox = () => {
    this.setState({
      withCard: !this.state.withCard,
      cardData: {
        cardNumber: "",
        verificationValue: "",
        nameOnCard: "",
        expMonth: "",
        expYear: "",
      },
    });
  };

  handleChangeCardData = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const cardData = { ...this.state.cardData };
    cardData[input.name] = input.value;

    this.setState({ cardData, errors });
  };

  handleChangeTextDataCheckbox = () => {
    this.setState({
      isChangeTestData: !this.state.isChangeTestData,
    });
  };

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleChangeNumber = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = numberFormatter(input.value);

    this.setState({ data, errors });
  };

  handleChangeTestData = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const testData = { ...this.state.testData };
    testData[input.name] = input.value;

    this.setState({ testData, errors });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    try {
      let data = {};
      let type = this.state.data.type;
      switch (type) {
        case "Payment":
        case "Authorization":
          data = {
            tracking_id: this.state.data.tracking_id,
            amount: Math.round(+this.state.data.amount * 100),
            currency: this.state.data.currency,
            description: this.state.testData.description,
            type: this.state.data.type,
            billing_address: {
              first_name: this.state.testData.first_name,
              last_name: this.state.testData.last_name,
              country: this.state.testData.country,
              city: this.state.testData.city,
              address: this.state.testData.address,
              zip: this.state.testData.zip,
            },
            customer: {
              email: this.state.testData.email,
              ip: this.state.testData.ip,
              phone: this.state.testData.phone,
            },
          };
          break;
        case "Cancel":
        case "Refund":
        case "Reverse":
          data = {
            tracking_id: this.state.data.tracking_id,
            amount: Math.round(+this.state.data.amount * 100),
            type: this.state.data.type,
            transactionId: this.state.data.transactionId,
            reason: this.state.isNeedReason
              ? this.state.data.reason
              : undefined,
          };
          break;
        case "Capture":
          data = {
            tracking_id: this.state.data.tracking_id,
            amount: Math.round(+this.state.data.amount * 100),
            type: this.state.data.type,
            transactionId: this.state.data.transactionId,
          };
          break;
        default:
          break;
      }
      const credit_card = {
        number: this.state.cardData.cardNumber,
        verification_value: this.state.cardData.verificationValue,
        holder: this.state.cardData.nameOnCard,
        exp_month: this.state.cardData.expMonth,
        exp_year: this.state.cardData.expYear,
      };
      if (this.state.withCard) data.credit_card = credit_card;
      else {
        data.succeed_url = `${window.location}?status=succeed`;
        data.failed_url = `${window.location}?status=failed`;
      }

      this.setState({ isLoading: true });
      await this.props.addTransactionAction(
        data,
        this.state.data.terminal,
        this.state.data.terminalSecret,
        this.state.data.terminalHashKey
      );
      this.setState({ isLoading: false });
      let transactionGuid = "Transaction guid: " + this.props.transaction.guid;
      if (this.props.transaction.status === "Failed") {
        Alert({ type: "error", message: transactionGuid + ", Failed" });
      } else if (
        this.props.transaction.redirect_to &&
        this.props.transaction.redirect_to.url &&
        this.props.transaction.redirect_to.params
      ) {
        const redirectUrlWithoutParams = `${config.node.host}/api/v1${this.props.transaction.redirect_to.url}`;
        const redirectUrl = addParamsInUrl(
          redirectUrlWithoutParams,
          this.props.transaction.redirect_to.params
        );
        window.location.href = redirectUrl;
      } else {
        swal({
          title: "Transaction submitted",
          text: transactionGuid,
          icon: "success",
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  renderShopInput = () => {
    const shops = this.props.shops
      ? this.props.shops.map((shop) => ({
        ...shop,
        name: shop.guid + " / " + shop.name,
      }))
      : [];
    const { errors } = this.state;
    return (
      <>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Shop *</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            <MultiSelect
              name="shop"
              options={shops}
              multi={false}
              onSelect={this.onSelectShop}
              placeholder="Select shop"
            />
            {errors.shop && (
              <span className="validate-error">{errors.shop}</span>
            )}
          </Form.Group>
        </Col>
      </>
    );
  };

  renderTerminalInput = () => {
    const terminals = this.props.terminals
      ? this.props.terminals.map((terminal) => ({
        ...terminal,
        name: terminal.guid + " / " + terminal.name,
      }))
      : [];
    const { errors, disabledTerminal } = this.state;

    return (
      <>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Terminal *</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            <MultiSelect
              name="terminal"
              options={terminals}
              multi={false}
              onSelect={this.onSelectTerminal}
              placeholder="Select terminal"
              disabled={disabledTerminal ? true : false}
            />
            {errors.terminal && (
              <span className="validate-error">{errors.terminal}</span>
            )}
          </Form.Group>
        </Col>
      </>
    );
  };

  renderTypeInput = () => {
    const { errors, disabledType } = this.state;
    const typesForGateway = this.props.typesForGateway
      ? this.props.typesForGateway
      : [];

    return (
      <>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Transaction Type *</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            <MultiSelect
              name="type"
              options={typesForGateway}
              multi={false}
              onSelect={this.onSelectType}
              placeholder="Select type"
              disabled={disabledType ? true : false}
            />
            {errors.type && (
              <span className="validate-error">{errors.type}</span>
            )}
          </Form.Group>
        </Col>
      </>
    );
  };

  render() {
    const { data, testData, errors } = this.state;
    return (
      <Card>
        <Card.Body style={{ overflow: "initial" }}>
          <Form onSubmit={this.handleSubmit} autoComplete="off">
            {!this.state.isNeedTransactionId && (
              <>
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Change test data </Form.Label>
                  </Col>
                  <Col md={8}>
                    <input
                      type="checkbox"
                      id="isChangeTestData"
                      onChange={this.handleChangeTextDataCheckbox}
                    />
                  </Col>
                </Row>
                {this.state.isChangeTestData && (
                  <>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>First Name *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="first_name"
                            value={testData.first_name}
                            placeholder="first name"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.first_name && (
                            <span className="validate-error">
                              {errors.first_name}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Last Name *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="last_name"
                            value={testData.last_name}
                            placeholder="last name"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.last_name && (
                            <span className="validate-error">
                              {errors.last_name}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Country *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="country"
                            value={testData.country}
                            placeholder="country"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.country && (
                            <span className="validate-error">
                              {errors.country}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>City *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="city"
                            value={testData.city}
                            placeholder="city"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.city && (
                            <span className="validate-error">
                              {errors.city}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Address *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="address"
                            value={testData.address}
                            placeholder="address"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.address && (
                            <span className="validate-error">
                              {errors.address}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Zip *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="zip"
                            value={testData.zip}
                            placeholder="zip"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.zip && (
                            <span className="validate-error">{errors.zip}</span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Email *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="email"
                            value={testData.email}
                            placeholder="email"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.email && (
                            <span className="validate-error">
                              {errors.email}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>IP *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="ip"
                            value={testData.ip}
                            placeholder="ip"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.ip && (
                            <span className="validate-error">{errors.ip}</span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Phone *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="phone"
                            value={testData.phone}
                            placeholder="phone"
                            onChange={this.handleChangeTestData}
                          />
                          {errors.phone && (
                            <span className="validate-error">{errors.phone}</span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}

            <Row>{this.renderShopInput()}</Row>
            <Row>{this.renderTerminalInput()}</Row>
            <Row>{this.renderTypeInput()}</Row>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Amount </Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group>
                  <Form.Control
                    name="amount"
                    value={data.amount}
                    onChange={this.handleChangeNumber}
                  />
                  {errors.amount && (
                    <span className="validate-error">{errors.amount}</span>
                  )}
                  {errors.currency && (
                    <span className="validate-error">{errors.currency}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={1}>
                {data.currency ? (
                  <p style={{ marginTop: "2px", marginLeft: "5px" }}>
                    {data.currency}
                  </p>
                ) : null}
              </Col>
            </Row>
            {this.state.isNeedTransactionId && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Transaction id </Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      name="transactionId"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Tracking id</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="tracking_id"
                    value={data.tracking_id}
                    onChange={this.handleChange}
                  />
                  {errors.tracking_id && (
                    <span className="validate-error">{errors.tracking_id}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {this.state.isNeedReason && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Reason </Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      name="reason"
                      value={data.reason}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {!this.state.isNeedTransactionId && (
              <>
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Enter card data </Form.Label>
                    <i
                      className="far fa-question-circle"
                      title="You can enter card data here or on redirected page"
                    />
                  </Col>
                  <Col md={8}>
                    <input
                      type="checkbox"
                      id="applyTestAccountCheckbox"
                      onChange={this.handleChangeCardDataCheckbox}
                    />
                  </Col>
                </Row>
                {this.state.withCard && (
                  <>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Card Number *</Form.Label>
                        <i className="far fa-question-circle help-tip">
                          <p>
                            16-digit number on the front of your credit card.
                          </p>
                        </i>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="cardNumber"
                            type="number"
                            value={data.cardNumber}
                            placeholder="0000 0000 0000 0000"
                            className="no-spinners"
                            onChange={this.handleChangeCardData}
                          />
                          {errors.cardNumber && (
                            <span className="validate-error">
                              {errors.cardNumber}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Full name on card * </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="nameOnCard"
                            value={data.nameOnCard}
                            placeholder="ex. John Doe"
                            onChange={this.handleChangeCardData}
                          />
                          {errors.nameOnCard && (
                            <span className="validate-error">
                              {errors.nameOnCard}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Expiry month *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="expMonth"
                            value={data.expMonth}
                            maxLength="2"
                            onChange={this.handleChangeCardData}
                            placeholder="MM"
                          />
                          {errors.expMonth && (
                            <span className="validate-error">
                              {errors.expMonth}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Expiry year *</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="expYear"
                            value={data.expYear}
                            maxLength="4"
                            placeholder="YYYY"
                            onChange={this.handleChangeCardData}
                          />
                          {errors.expYear && (
                            <span className="validate-error">
                              {errors.expYear}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={4} xs={4} className="form-label">
                        <Form.Label>Security code * </Form.Label>
                        <i className="far fa-question-circle help-tip">
                          <p>3 digits displayed on the back of your card.</p>
                        </i>
                      </Col>
                      <Col md={8}>
                        <Form.Group>
                          <Form.Control
                            name="verificationValue"
                            value={data.verificationValue}
                            placeholder="***"
                            maxLength="3"
                            onChange={this.handleChangeCardData}
                          />
                          {errors.verificationValue && (
                            <span className="validate-error">
                              {errors.verificationValue}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            <div>
              {errors.terminalSecret && (
                <p
                  className="validate-error"
                  style={{ float: "none", textAlign: "center" }}
                >
                  {errors.terminalSecret}
                </p>
              )}

              {errors.terminalHashKey && (
                <p
                  className="validate-error"
                  style={{ float: "none", textAlign: "center" }}
                >
                  {errors.terminalHashKey}
                </p>
              )}
            </div>

            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button className="btn btn-fill btn-primary" type="submit">
                  Send
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    transaction: state.transactions.transaction,
    typesForGateway: state.transactions.transactionTypesForGateway,
    status: state.transactions.status,
    terminal: state.shops.shopTerminal,
  };
};

export default connect(mapStateToProps, {
  getAllShops,
  getShopTerminalsAction,
  addTransactionAction,
  getTransactionTypesForGatewayAction,
  getShopTerminalAction,
})(NewForm);
