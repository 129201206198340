import { getShopTerminalAction } from "actions/shops";
import Can from "components/Can/index";
import Pagination from "components/UI/Pagination";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import Content from "views/Content";
import { getTerminalChangeHistoryAction } from "../../../actions/reasons";
import ability from "../../../config/ability";
import { cutGuid } from "../../../helpers/cutGuid";
import { Progress } from "antd";

class TerminalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecretCopied: false,
      isHashKeyCopied: false,
      shopId: this.props.match.params.shopId,
      currentPageHistory: 1,
      pageSizeHistory: 10,
    };
  }

  columnsHistory = [
    {
      path: "currency_code",
      label: "Currency",
      key: "currency_code",
      align: "center",
    },
    { path: "gateway_name", label: "Gateway", key: "gateway_name" },
    {
      key: "enabled",
      align: "center",
      content: (terminal) => (
        <i
          className={
            terminal.enabled
              ? "fas fa-check-circle green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Enabled",
    },
    {
      label: "Rate guid",
      key: "rate_guid",
      content: (rate) => (rate.rate_guid ? cutGuid(rate.rate_guid) : null),
    },
    {
      key: "three_d",
      align: "center",
      content: (terminal) => (
        <i
          className={
            terminal.three_d
              ? "fas fa-check-circle green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Three d",
    },
    {
      path: "monthly_amount_limit",
      label: "Monthly amount limit",
      key: "monthly_amount_limit",
      align: "right",
    },
    {
      path: "payment_amount_limit",
      label: "Payment amount limit",
      key: "payment_amount_limit",
      align: "right",
    },
    {
      path: "transaction_count_limit",
      label: "Transaction count limit",
      key: "transaction_count_limit",
      align: "right",
    },
    {
      key: "antifraud_monitor",
      content: (terminal) => (
        <i
          className={
            terminal.antifraud_monitor
              ? "fas fa-check-circle green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Antifraud monitor",
      align: "center",
    },
    {
      path: "antifraud_monitor_value",
      label: "Antifraud monitor value",
      key: "antifraud_monitor_value",
      align: "right",
    },
    {
      path: "billing_descriptor",
      label: "Billing descriptor",
      key: "billing_descriptor",
    },
    { path: "routing_string", label: "Routing string", key: "routing_string" },
    {
      key: "generate_statement",
      content: (terminal) => (
        <i
          className={
            terminal.generate_statement
              ? "fas fa-check-circle green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Generate statement",
      align: "center",
    },
    {
      path: "supported_brands",
      label: "Supported brands",
      key: "supported_brands",
    },
    {
      path: "checkout_method",
      label: "Checkout method",
      key: "checkout_method",
    },
    {
      key: "enable_checkout",
      content: (terminal) => (
        <i
          className={
            terminal.enable_checkout
              ? "fas fa-check-circle green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Enable checkout",
      align: "center",
    },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
      align: "center",
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];

  componentDidMount = async () => {
    const token = getAuthData();
    const guid = this.props.match.params.id;
    if (token.userPayload.merchant) this.setState({ isMerchant: true });
    await this.props.getShopTerminalAction(this.state.shopId, guid);
    ability.can("AUDIT", "TERMINALS") &&
      (await this.props.getTerminalChangeHistoryAction(
        this.props.match.params.id,
        1,
        this.state.pageSizeHistory
      ));
  };

  handleHistoryPageChange = async (page) => {
    await this.props.getTerminalChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.pageSizeHistory
    );
    this.setState({
      currentPageHistory: page,
    });
  };

  getRowsFromLength = (text) => {
    if (!text) return 0;
    let res = text.length / 35 + 1;
    if (text.length % 35) res++;
    return res;
  };

  handleCopy = (target) => {
    let textField = document.createElement("textarea");
    if (target === "secret") {
      textField.innerText = this.props.shopTerminal.secret;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      this.setState({
        isSecretCopied: true,
        isHashKeyCopied: false,
      });
    } else {
      textField.innerText = this.props.shopTerminal.hash_key;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      this.setState({
        isSecretCopied: false,
        isHashKeyCopied: true,
      });
    }
    setTimeout(
      () =>
        this.setState({
          isSecretCopied: false,
          isHashKeyCopied: false,
        }),
      3000
    );
  };

  addPointInValue = (value) => {
    switch (value.length) {
      case 1: {
        value = "0.0" + value;
        break;
      }
      case 2: {
        value = "0." + value;
        break;
      }
      case 3: {
        const start = value[0];
        const end = value[1] + value[2];
        value = start + "." + end;
        break;
      }
      default: {
        value =
          value.substr(0, value.length - 2) +
          "." +
          value.substr(value.length - 2, value.length);
        break;
      }
    }
    return value;
  };

  historygetPagedData = () => {
    const { terminalHistoryCount } = this.props;
    let { pageSizeHistory } = this.state;
    const historyPagesCount =
      terminalHistoryCount / pageSizeHistory +
      (1 && !!(terminalHistoryCount % pageSizeHistory));
    return { historyPagesCount };
  };

  render() {
    const { shopTerminal, terminalHistory } = this.props;
    const { currentPageHistory } = this.state;
    const { historyPagesCount } = this.historygetPagedData();
    if (this.props.shopTerminalLoading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5">{shopTerminal.name}</Card.Header>
              <Card.Body>
                <Card.Title>Info</Card.Title>

                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>GUID:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{this.props.match.params.id}</span>
                  </Col>
                </Row>
                <Can do="EXECUTE" on="RATES">
                  {shopTerminal.rate_guid ? (
                    <Row>
                      <Col md={6} lg={6} xs={6} className="right">
                        <label>Rate:</label>
                      </Col>
                      <Col md={6} lg={6} xs={6}>
                        <Link
                          className="link"
                          to={`/about/rates/${shopTerminal.rate_guid}`}
                        >
                          {shopTerminal.rate_name}
                        </Link>
                      </Col>
                    </Row>
                  ) : null}
                </Can>
                {ability.can("READ", "TERMINALCREDENTIALS") && (
                  <React.Fragment>
                    <Row className={"row1"}>
                      <Col md={6} lg={6} xs={6} className="right">
                        <label>Secret:</label>
                      </Col>
                      <Col md={3} lg={3} xs={3}>
                        <div id="secret" className="card-shop-secret noselect">
                          {shopTerminal.secret}
                        </div>

                        {shopTerminal.hash_key !== undefined ? (
                          <Button
                            onClick={() => this.handleCopy("secret")}
                            className={"btn btn-copy"}
                          >
                            {this.state.isSecretCopied ? (
                              <div style={{ cursor: "default" }}>
                                <span style={{ color: "green" }}>✔</span>
                                Successfully copied
                              </div>
                            ) : (
                              <div>Copy secret</div>
                            )}
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                    <br />
                    <Row className={"row1"}>
                      <Col md={6} lg={6} xs={6} className="right">
                        <label>Hash-key:</label>
                      </Col>
                      <Col md={3} lg={3} xs={3}>
                        <div
                          id="hash-hey"
                          className="card-shop-secret noselect"
                        >
                          {shopTerminal.hash_key}
                        </div>
                        {shopTerminal.hash_key !== undefined ? (
                          <Button
                            onClick={() => this.handleCopy("hash-key")}
                            className={"btn btn-copy"}
                            name="hashCopyButton"
                          >
                            {this.state.isHashKeyCopied ? (
                              <div style={{ cursor: "default" }}>
                                <span style={{ color: "green" }}>✔</span>
                                Successfully copied
                              </div>
                            ) : (
                              <div>Copy hash-key</div>
                            )}
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                    <br />
                  </React.Fragment>
                )}
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Gateway:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.gateway_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Currency:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.currency_code}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>3d:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{String(shopTerminal.three_d)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Billing descriptor:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.billing_descriptor}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Routing string:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.routing_string}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Payment amount limit:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>
                      {this.addPointInValue(shopTerminal.payment_amount_limit)}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Monthly amount limit:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>
                      {this.addPointInValue(shopTerminal.monthly_amount_limit)}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Used amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {shopTerminal.used_amount}
                      {" (" + shopTerminal.used_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right"></Col>
                  <Col md={2} sm={2} xs={2} className="left">
                    <Progress
                      percent={shopTerminal.used_percent}
                      size="small"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Available amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {shopTerminal.unused_amount}
                      {" (" + shopTerminal.unused_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Used transaction count limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {shopTerminal.used_transaction_count}
                      {" (" + shopTerminal.used_count_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right"></Col>
                  <Col md={2} sm={2} xs={2} className="left">
                    <Progress
                      percent={shopTerminal.used_count_percent}
                      size="small"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Available transaction count limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {shopTerminal.unused_transaction_count}
                      {" (" + shopTerminal.unused_count_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Generate statement:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{String(shopTerminal.generate_statement)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Enabled:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{String(shopTerminal.enabled)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Checkout method:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.checkout_method}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Enable checkout:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{String(shopTerminal.enable_checkout)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Antifraud monitor value:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.antifraud_monitor_value}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Antifraud monitor:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{String(shopTerminal.antifraud_monitor)}</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Created at:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>
                      {shopTerminal.created_at !== null
                        ? moment(shopTerminal.created_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Created by:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.created_by_username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Updated at:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>
                      {shopTerminal.updated_at !== null
                        ? moment(shopTerminal.updated_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6} xs={6} className="right">
                    <label>Updated by:</label>
                  </Col>
                  <Col md={6} lg={6} xs={6}>
                    <span>{shopTerminal.updated_by_username}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>

          {ability.can("AUDIT", "TERMINALS") && (
            <Card>
              <Card.Body>
                <Card.Title>History</Card.Title>

                {this.props.terminalHistoryloading ? (
                  <Content>
                    <Spinner />
                  </Content>
                ) : (
                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      <Table
                        columns={this.columnsHistory}
                        data={terminalHistory ? terminalHistory : []}
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={historyPagesCount}
                        currentPage={currentPageHistory}
                        onPageChange={this.handleHistoryPageChange}
                        pageSize={this.state.pageSizeHistory}
                        count={this.props.terminalHistoryCount}
                      />
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          )}
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    shopTerminal: state.shops.shopTerminal,
    shopTerminalLoading: state.shops.shopTerminalLoading,
    terminalHistory: state.reasons.terminalHistory,
    terminalHistoryloading: state.reasons.loading,
    terminalHistoryCount: state.reasons.count,
  };
};

export default connect(mapStateToProps, {
  getShopTerminalAction,
  getTerminalChangeHistoryAction,
})(TerminalDetail);

TerminalDetail.propTypes = {
  getShopTerminalAction: PropTypes.func,
  getTerminalChangeHistoryAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      shopId: PropTypes.string,
    }),
  }),
  shopTerminal: PropTypes.object,
  shopTerminalLoading: PropTypes.bool,
  terminalHistory: PropTypes.array,
  terminalHistoryCount: PropTypes.number,
  terminalHistoryloading: PropTypes.bool,
};
