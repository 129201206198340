import * as types from "../constants/actionTypes";

const initialState = {
  topMerchants: [],
  merchant: {
    guid: "",
    name: "",
    type: "",
    monthly_amount_limit: "",
    monthly_fee: "",
    connection_fee: "",
    used_amount: "0",
    used_custom_amount: "0",
    used_percent: "0",
    used_custom_percent: "0",
    unused_percent: "0",
    unused_amount: "0",
    unused_custom_amount: "0",
    unused_custom_percent: "0",
    group_guid: "",
    group_name: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: "",
  },
  merchantsList: [],
  merchant_guid: "",
  warning: "",
  merchantLogins: [],
  merchantsLoading: false,
  merchantLoading: false,
  error: false,
  amountMerchants: [],
};

export default function merchants(state = initialState, action) {
  switch (action.type) {
    case types.GET_MERCHANTS:
      return {
        ...state,
        merchantsLoading: true,
        error: false,
      };
    case types.GET_MERCHANTS_SUCCEED:
      return {
        ...state,
        merchantsList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        merchantsLoading: false,
        error: false,
      };
    case types.GET_MERCHANTS_FAILED:
      return {
        ...state,
        merchantsLoading: false,
        error: true,
      };
    case types.GET_MERCHANTS_BY_PARTNER:
      return { ...state, merchantsList: action.merchants };
    case types.GET_MERCHANTS_BY_GROUP:
      return { ...state, merchantsList: action.merchants };
    case types.GET_MERCHANT:
      return {
        ...state,
        merchantLoading: true,
        error: false,
      };
    case types.GET_MERCHANT_SUCCEED:
      return {
        ...state,
        merchant: action.merchant,
        merchantLoading: false,
        error: false,
      };
    case types.GET_MERCHANT_FAILED:
      return {
        ...state,
        merchantLoading: false,
        error: true,
      };
    case types.GET_MERCHANT_LOGINS:
      return {
        ...state,
        merchantLoginsLoading: true,
      };
    case types.GET_MERCHANT_LOGINS_SUCCEED:
      return {
        ...state,
        merchantLogins: action.data.data,
        loginsCount: action.data.count,
        merchantLoginsLoading: false,
      };
    case types.GET_MERCHANT_LOGINS_FAILED:
      return {
        ...state,
        merchantLoginsLoading: false,
      };
    case types.DELETE_MERCHANT: {
      let newMerchantsList = state.merchantsList
        ? state.merchantsList.filter(
          (merchant) =>
            merchant.merchant_guid !== action.merchant.merchant_guid
        )
        : [];
      return { ...state, merchantsList: newMerchantsList };
    }
    case types.ADD_MERCHANT:
      return {
        ...state,
        merchant_guid: action.merchant.guid,
        warning: action.merchant.warning,
      };
    case types.ADD_MERCHANT_LOGIN:
      return { ...state, merchantLogins: [ action.merchantLogin ] };
    case types.EDIT_MERCHANT: {
      let newMerchantsList = state.merchantsList ? state.merchantsList : [];
      newMerchantsList = newMerchantsList.map((merchant) => {
        if (merchant.merchant_guid === action.merchant.merchant_guid) {
          return { ...merchant, ...action.merchant };
        } else return merchant;
      });
      return { ...state, merchantsList: newMerchantsList };
    }
    case types.GET_TOP_MERCHANTS:
      return {
        ...state,
        topMerchantsLoading: true,
      };
    case types.GET_TOP_MERCHANTS_SUCCEED:
      return {
        ...state,
        topMerchants: action.data,
        topMerchantsLoading: false,
      };
    case types.GET_TOP_MERCHANTS_FAILED:
      return {
        ...state,
        topMerchantsLoading: false,
      };
    case types.GET_AMOUNT_MERCHANTS_FOR_GROUP:
      return { ...state, amountMerchants: action.count };
    case types.GET_AMOUNT_MERCHANTS_FOR_PARTNER:
      return { ...state, count: action.count };
    default:
      return state;
  }
}
