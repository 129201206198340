import React, { Component } from "react";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { Form, Button } from "react-bootstrap";
import { showModal } from "../../../actions/modal";
import {
  getBlackListItemAction,
  updateBlackListItemAction,
} from "../../../actions/blacklists";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import Spinner from "components/UI/Spinner";
import ReactLoading from "react-loading";
import { Col, Row } from "react-bootstrap";
import Alert from "../../UI/Alert";

class BlackListEditor extends Component {
  state = {
    data: {
      name: "",
      description: "",
      reason: "",
    },
    oldData: {
      name: "",
      description: "",
    },

    needReason: false,
    errors: {},
    isLoading: false,
  };

  schema = {
    name: Joi.string().required().min(0).max(100).label("Name"),
    description: Joi.string().required().min(0).max(100).label("Description"),
    reason: Joi.string().required().min(0).max(100).label("Reason"),
  };

  formValidation = (name) => {
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  async componentDidMount() {
    await this.props.getBlackListItemAction(this.props.blacklist_guid);
    const blackListItem = this.props.blackListItem;
    const data = this.state.data;
    data.name = blackListItem.name;
    data.description = blackListItem.description;
    this.setState({
      data,
    });
  }

  doSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ isLoading: true });
      const guid = this.props.blacklist_guid;
      const name = this.state.data.name;
      const reason = this.state.data.reason;
      const description = this.state.data.description;
      const type = this.props.blackListItem.type;
      await this.props.updateBlackListItemAction({
        guid,
        type,
        name,
        description,
        reason,
      });
      this.setState({ isLoading: false });
      swal({
        title: "Successfully updated",
        icon: "success",
        button: false,
        timer: 2000,
      });
      await this.props.handleClose();
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
    });
  };

  renderInput = (label, value, name, type = "text") => {
    return (
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>{label}*</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group validationState={this.formValidation(name)}>
            <Form.Control
              // placeholder={label.toLowerCase()}
              type={type}
              name={name}
              value={value}
              onChange={this.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  render() {
    if (this.props.itemLoading) {
      return <Spinner />;
    } else {
      return (
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>{this.renderInput("Name", this.state.data.name, "name")}</Col>
          </Row>
          <Row>
            <Col>
              {this.renderInput(
                "Description",
                this.state.data.description,
                "description"
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {this.renderInput("Reason", this.state.data.reason, "reason")}
            </Col>
          </Row>
          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className="btn btn-fill btn-success"
                type="submit"
                onClick={this.doSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    blackListItem: state.blacklist.blackItem,
    itemLoading: state.blacklist.itemLoading,
  };
};

export default connect(mapStateToProps, {
  getBlackListItemAction,
  updateBlackListItemAction,
  showModal,
})(BlackListEditor);

BlackListEditor.propTypes = {
  blackListItem: PropTypes.object,
  getBlackListItemAction: PropTypes.func,
  showModal: PropTypes.func,
  updateBlackListItemAction: PropTypes.func,
};
