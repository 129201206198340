import * as types from "../constants/actionTypes";

const initialState = {
  servicesList: [],
  service: {},
  loading: false,
  error: false,
};

export default function services(state = initialState, action) {
  switch (action.type) {
    case types.GET_SERVICES:
      return {
        ...state,
        servicesList: [],
        servicesLoading: true,
        error: false
      };
    case types.GET_SERVICES_SUCCEED:
      return {
        ...state,
        servicesList: action.data.data,
        count: action.data.count,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        servicesLoading: false,
        error: false
      };
    case types.GET_SERVICES_FAILED:
      return {
        ...state,
        servicesLoading: false,
        error: true
      };
    case types.GET_SERVICE:
      return {
        ...state,
        service: {},
        serviceLoading: true,
        error: false
      };
    case types.GET_SERVICE_SUCCEED:
      return {
        ...state,
        service: action.data,
        serviceLoading: false,
        error: false
      };
    case types.GET_SERVICE_FAILED:
      return {
        ...state,
        serviceLoading: false,
        error: true
      };
    case types.EDIT_SERVICE: {
      let newServicesList = state.servicesList;
      newServicesList = newServicesList.map(service => {
        if (service.name === action.service.name)
          return action.service;
        else
          return service;
      });
      return { ...state, servicesList: newServicesList };
    }
    default:
      return state;
  }
}