import { backendManagement } from "../backendPlatform";

export const getAdditionalFees = (args) => {
  return backendManagement.get("/statements/additionalFeesNames", { params: { ...args } });
};

export const createOrUpdateAdditionalFee = data => {
  return backendManagement.post("/statements/additionalFeesNames", data);
};

export const deleteAdditionalFee = ({ guid, reason }) => {
  return backendManagement.post("/statements/additionalFeesNames", { guid, delete: true, reason });
};
