import React, { Component } from "react";
import { components } from "react-select";
import PropTypes from "prop-types";

export class InputOption extends Component {
  state = { isActive: false };
  onMouseDown = () => this.setState({ isActive: true });
  onMouseUp = () => this.setState({ isActive: false });
  onMouseLeave = () => this.setState({ isActive: false });

  render() {
    const {
      getStyles,
      isDisabled,
      isFocused,
      isSelected,
      children,
      innerProps,
      ...rest
    } = this.props;
    const { isActive } = this.state;

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      // alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "block ",
      padding: "6px 8px",
      margin: 0,
      textTransform: "none",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown: this.onMouseDown,
      onMouseUp: this.onMouseUp,
      onMouseLeave: this.onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input
          type="checkbox"
          checked={isSelected}
          style={{ minWidth: "10px", marginRight: "5px" }}
        />
        {children}
      </components.Option>
    );
  }
}

InputOption.propTypes = {
  Icon: PropTypes.any,
  children: PropTypes.array,
  getStyles: PropTypes.any,
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export const CustomMultiValueContainer = (props) => {
  return (
    <components.ValueContainer {...props}>
      {props.children && props.children[0] && props.children[0].length ? (
        <>
          <span key={props.selectProps.key}>
            {/* {props.children[0].length} items */}
            {/* {props.children[1]} */}
            {React.cloneElement(props.children[1], { placeholder: "Selected" })}
          </span>
        </>
      ) : (
        props.children
      )}
    </components.ValueContainer>
  );
};

CustomMultiValueContainer.propTypes = {
  children: PropTypes.array,
};

export const CustomMenu = (props) => {
  return (
    <React.Fragment>
      <components.Menu {...props}>
        <div>
          <div>{props.children}</div>

          <button
            className="btn btn-primary btn-sm btn-block"
            onClick={props.selectProps.applyFilter}
          >
            Apply
          </button>
        </div>
      </components.Menu>
    </React.Fragment>
  );
};

CustomMenu.propTypes = {
  children: PropTypes.array,
  selectProps: PropTypes.object,
};
