import moment from "moment";
import { connect } from "react-redux";
import { getAuditGatewayTablesAction } from "../../../actions/audit";
import { reset, searchInGatewaysAuditTable } from "../../../actions/search";
import { resetPage, setNewTable } from "../../../actions/store";
import AbstractComponent from "../../../factories/Main";
import { cutGuid } from "../../../helpers/cutGuid";
import { getAuditGatewayTables } from "../../../services/paymentBackendAPI/audits/audit";
import React from "react";
import { Copy } from "../../UI/CopyToClipboard";

const columns = [
  {
    path: "guid",
    label: "Guid",
    key: "guid_audit",
    content: (el) => (
      <>
        {cutGuid(el.guid)}
        <Copy text={el.guid} />
      </>
    ),
  },
  { path: "name", label: "Name", key: "name" },
  { path: "description", label: "Description", key: "description" },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (el) =>
      moment(el.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.gatewayTables,
    count: state.audit.gatewayTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.gatewayAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_gateway",
    keyPath: "guid",
    columns,
    exportFunction: getAuditGatewayTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditGatewayTablesAction,
  search: searchInGatewaysAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
