import Can from "components/Can";
import React, { Component } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import ChargebacksServiceMain from "./Chargebacks/Main";
import CurrencyExchangeServiceMain from "./CurrencyExchange/Main";
import DectaRecotServiceMain from "./Decta/Main";
import DeleteCardDataServiceMain from "./DeleteCardData/Main";
import LoginExpiredServiceMain from "./LoginExpired/Main";
import RecurringServiceMain from "./Recurring/Main";

class ServicesLogMain extends Component {
  constructor() {
    super();

    this.state = {
      type: "Decta Recot Service",
      isUpdate: false,
      labelButton: "Decta Recot Service",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  async handleSelect(e) {
    await this.setState({
      type: e,
      labelButton: e
    });
  }

  render() {
    return (
      <div>
        <DropdownButton
          as="s"
          key={this.state.labelButton}
          id={`dropdown-variants-${this.state.labelButton}`}
          title={this.state.labelButton}
        >
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Decta Recot Service">Decta Recot Service</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Chargebacks Service">Chargebacks Service</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Login Expired Service">Login Expired Service</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Currency Exchange Service">Currency Exchange Service</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Delete Card Data Service">Delete Card Data Service</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Recurring Service">Recurring Service</Dropdown.Item>
        </DropdownButton>
        {
          {
            "Decta Recot Service": <Can do="LOG" on="SERVICEDECTARECOT">
              <DectaRecotServiceMain />
            </Can>,
            "Chargebacks Service": <Can do="LOG" on="SERVICECHARGEBACK">
              <ChargebacksServiceMain />
            </Can>,
            "Login Expired Service": <Can do="LOG" on="SERVICELOGINEXPIRED">
              <LoginExpiredServiceMain />
            </Can>,
            "Currency Exchange Service": <Can do="LOG" on="SERVICECURRENCYEXCHANGE">
              <CurrencyExchangeServiceMain />
            </Can>,
            "Delete Card Data Service": <Can do="LOG" on="SERVICEDELETECARDDATA">
              <DeleteCardDataServiceMain />
            </Can>,
            "Recurring Service": <Can do="LOG" on="SERVICERECURRING">
              <RecurringServiceMain />
            </Can>,
          }[this.state.type]
        }

      </div>
    );
  }
}

export default ServicesLogMain;
