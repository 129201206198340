export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILED = "LOG_IN_FAILED";

export const LOG_OUT = "LOG_OUT";

export const GET_EMAIL_MESSAGE = "GET_EMAIL_MESSAGE";
export const CHECK_RECOVERY_TOKEN = "CHECK_RECOVERY_TOKEN";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";

export const CHECK_USER_EXISTS = "CHECK_USER_EXISTS";

export const PUSH_LOCATION = "PUSH_LOCATION";
export const POP_LOCATION = "POP_LOCATION";

export const PUSH_ITEM_HISTORY = "PUSH_ITEM_HISTORY";
export const POP_ITEM_HISTORY = "POP_ITEM_HISTORY";
export const RESET_HISTORY = "RESET_HISTORY";

export const GET_LOGINS = "GET_LOGINS";
export const GET_LOGINS_SUCCEED = "GET_LOGINS_SUCCEED";
export const GET_LOGINS_FAILED = "GET_LOGINS_FAILED";
export const GET_FREE_LOGINS = "GET_FREE_LOGINS";
export const GET_FREE_LOGINS_SUCCEED = "GET_FREE_LOGINS_SUCCEED";
export const GET_FREE_LOGINS_FAILED = "GET_FREE_LOGINS_FAILED";
export const GET_LOGIN = "GET_LOGIN";
export const GET_LOGIN_SUCCEED = "GET_LOGIN_SUCCEED";
export const GET_LOGIN_FAILED = "GET_LOGIN_FAILED";
export const GET_LOGIN_USER_PROFILE = "GET_LOGIN_USER_PROFILE";
export const GET_LOGIN_USER_PROFILE_SUCCEED = "GET_LOGIN_USER_PROFILE_SUCCEED";
export const GET_LOGIN_USER_PROFILE_FAILED = "GET_LOGIN_USER_PROFILE_FAILED";
export const DELETE_LOGIN = "DELETE_LOGIN";
export const ADD_LOGIN = "ADD_LOGIN";
export const ADD_LOGIN_ROLE = "ADD_LOGIN_ROLE";
export const EDIT_LOGIN = "EDIT_LOGIN";
export const GET_LOGIN_ROLES = "GET_LOGIN_ROLES";
export const GET_LOGIN_ROLES_SUCCEED = "GET_LOGIN_ROLES_SUCCEED";
export const GET_LOGIN_ROLES_FAILED = "GET_LOGIN_ROLES_FAILED";
export const GET_NEW_LOGINS_COUNT = "GET_NEW_LOGINS_COUNT";
export const GET_NEW_LOGINS_COUNT_SUCCEED = "GET_NEW_LOGINS_COUNT_SUCCEED";
export const GET_NEW_LOGINS_COUNT_FAILED = "GET_NEW_LOGINS_COUNT_FAILED";

export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMINS_SUCCEED = "GET_ADMINS_SUCCEED";
export const GET_ADMINS_FAILED = "GET_ADMINS_FAILED";
export const ADD_ADMIN = "ADD_ADMIN";
export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_SUCCEED = "GET_ADMIN_SUCCEED";
export const GET_ADMIN_FAILED = "GET_ADMIN_FAILED";
export const GET_ADMIN_ROLES = "GET_ADMIN_ROLES";
export const GET_ADMIN_ROLES_SUCCEED = "GET_ADMIN_ROLES_SUCCEED";
export const GET_ADMIN_ROLES_FAILED = "GET_ADMIN_ROLES_FAILED";
export const ADD_ADMIN_ROLE = "ADD_ADMIN_ROLE";

export const GET_MERCHANTS = "GET_MERCHANTS";
export const GET_MERCHANTS_SUCCEED = "GET_MERCHANTS_SUCCEED";
export const GET_MERCHANTS_FAILED = "GET_MERCHANTS_FAILED";
export const GET_MERCHANTS_BY_PARTNER = "GET_MERCHANTS_BY_PARTNER";
export const GET_MERCHANTS_BY_GROUP = "GET_MERCHANTS_BY_GROUP";
export const GET_MERCHANT = "GET_MERCHANT";
export const GET_MERCHANT_SUCCEED = "GET_MERCHANT_SUCCEED";
export const GET_MERCHANT_FAILED = "GET_MERCHANT_FAILED";
export const DELETE_MERCHANT = "DELETE_MERCHANT";
export const ADD_MERCHANT = "ADD_MERCHANT";
export const EDIT_MERCHANT = "EDIT_MERCHANT";
export const ADD_MERCHANT_LOGIN = "ADD_MERCHANT_LOGIN";
export const GET_MERCHANT_LOGINS = "GET_MERCHANT_LOGINS";
export const GET_MERCHANT_LOGINS_SUCCEED = "GET_MERCHANT_LOGINS_SUCCEED";
export const GET_MERCHANT_LOGINS_FAILED = "GET_MERCHANT_LOGINS_FAILED";
export const DELETE_MERCHANT_FROM_GROUP = "DELETE_MERCHANT_FROM_GROUP";
export const GET_MERCHANT_SHOPS = "GET_MERCHANT_SHOPS";
export const GET_TOP_MERCHANTS = "GET_TOP_MERCHANTS";
export const GET_TOP_MERCHANTS_SUCCEED = "GET_TOP_MERCHANTS_SUCCEED";
export const GET_TOP_MERCHANTS_FAILED = "GET_TOP_MERCHANTS_FAILED";
export const GET_TOP_MERCHANTS_FOR_GROUP = "GET_TOP_MERCHANTS_FOR_GROUP";
export const GET_AMOUNT_MERCHANTS_FOR_GROUP = "GET_AMOUNT_MERCHANTS_FOR_GROUP";
export const GET_AMOUNT_MERCHANTS_FOR_PARTNER =
  "GET_AMOUNT_MERCHANTS_FOR_PARTNER";

export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const ADD_ROLE_PRIVILEGE = "ADD_ROLE_PRIVILEGE";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCEED = "GET_ROLES_SUCCEED";
export const GET_ROLES_FAILED = "GET_ROLES_FAILED";
export const GET_ROLES_WITH_LOGIN = "GET_ROLES_WITH_LOGIN";
export const GET_ROLES_WITH_LOGIN_SUCCEED = "GET_ROLES_WITH_LOGIN_SUCCEED";
export const GET_ROLES_WITH_LOGIN_FAILED = "GET_ROLES_WITH_LOGIN_FAILED";
export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCEED = "GET_ROLE_SUCCEED";
export const GET_ROLE_FAILED = "GET_ROLE_FAILED";
export const GET_ROLE_PRIVILEGES = "GET_ROLE_PRIVILEGES";
export const GET_ROLE_PRIVILEGES_SUCCEED = "GET_ROLE_PRIVILEGES_SUCCEED";
export const GET_ROLE_PRIVILEGES_FAILED = "GET_ROLE_PRIVILEGES_FAILED";
export const DELETE_ROLE = "DELETE_ROLE";
export const UPSERT_ROLE_PRIVILEGES = "UPSERT_ROLE_PRIVILEGES";
export const UPSERT_ROLE_CHANGED_PRIVILEGE = "UPSERT_ROLE_CHANGED_PRIVILEGE";

export const ADD_GATEWAY = "ADD_GATEWAY";
export const GET_GATEWAYS = "GET_GATEWAYS";
export const GET_GATEWAYS_SUCCEED = "GET_GATEWAYS_SUCCEED";
export const GET_GATEWAYS_FAILED = "GET_GATEWAYS_FAILED";
export const GET_GATEWAY = "GET_GATEWAY";
export const GET_GATEWAY_SUCCEED = "GET_GATEWAY_SUCCEED";
export const GET_GATEWAY_FAILED = "GET_GATEWAY_FAILED";
export const EDIT_GATEWAY = "EDIT_GATEWAY";
export const DELETE_GATEWAY = "DELETE_GATEWAY";
export const GET_GATEWAY_PROPS = "GET_GATEWAY_PROPS";
export const GET_GATEWAY_PROPS_SUCCEED = "GET_GATEWAY_PROPS_SUCCEED";
export const GET_GATEWAY_PROPS_FAILED = "GET_GATEWAY_PROPS_FAILED";
export const ADD_GATEWAY_PROPS = "ADD_GATEWAY_PROPS";
export const DELETE_GATEWAY_PROPS = "DELETE_GATEWAY_PROPS";

export const ADD_PRIVILEGE = "ADD_PRIVILEGE";
export const EDIT_PRIVILEGE = "EDIT_PRIVILEGE";
export const GET_PRIVILEGES = "GET_PRIVILEGES";
export const GET_PRIVILEGES_SUCCEED = "GET_PRIVILEGES_SUCCEED";
export const GET_PRIVILEGES_FAILED = "GET_PRIVILEGES_FAILED";
export const GET_PRIVILEGE = "GET_PRIVILEGE";
export const GET_PRIVILEGE_SUCCEED = "GET_PRIVILEGE_SUCCEED";
export const GET_PRIVILEGE_FAILED = "GET_PRIVILEGE_FAILED";
export const DELETE_PRIVILEGE = "DELETE_PRIVILEGE";

export const ADD_GROUP = "ADD_GROUP";
export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCEED = "GET_GROUPS_SUCCEED";
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";
export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_SUCCEED = "GET_GROUP_SUCCEED";
export const GET_GROUP_FAILED = "GET_GROUP_FAILED";
export const DELETE_GROUP = "DELETE_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const GET_GROUP_LOGINS = "GET_GROUP_LOGINS";
export const GET_GROUP_LOGINS_SUCCEED = "GET_GROUP_LOGINS_SUCCEED";
export const GET_GROUP_LOGINS_FAILED = "GET_GROUP_LOGINS_FAILED";
export const ADD_GROUP_LOGIN = "ADD_GROUP_LOGIN";
export const GET_GROUP_MERCHANTS = "GET_GROUP_MERCHANTS";
export const GET_GROUP_MERCHANTS_SUCCEED = "GET_GROUP_MERCHANTS_SUCCEED";
export const GET_GROUP_MERCHANTS_FAILED = "GET_GROUP_MERCHANTS_FAILED";
export const UPSERT_GROUP_CHANGED_MERCHANT = "UPSERT_GROUP_CHANGED_MERCHANT";
export const GET_GROUP_SHOPS = "GET_GROUP_SHOPS";
export const GET_AMOUNT_GROUPS_FOR_PARTNER = "GET_AMOUNT_GROUPS_FOR_PARTNER";
export const GET_TOP_GROUPS = "GET_TOP_GROUPS";
export const GET_TOP_GROUPS_SUCCEED = "GET_TOP_GROUPS_SUCCEED";
export const GET_TOP_GROUPS_FAILED = "GET_TOP_GROUPS_FAILED";
export const RESET_GROUP_MANAGEMENT_DATA = "RESET_GROUP_MANAGEMENT_DATA";
export const RESET_MERCHANT_MANAGEMENT_DATA = "RESET_MERCHANT_MANAGEMENT_DATA";

export const ADD_PARTNER = "ADD_PARTNER";
export const GET_PARTNERS = "GET_PARTNERS";
export const GET_PARTNERS_SUCCEED = "GET_PARTNERS_SUCCEED";
export const GET_PARTNERS_FAILED = "GET_PARTNERS_FAILED";
export const GET_PARTNER = "GET_PARTNER";
export const GET_PARTNER_SUCCEED = "GET_PARTNER_SUCCEED";
export const GET_PARTNER_FAILED = "GET_PARTNER_FAILED";
export const EDIT_PARTNER = "EDIT_PARTNER";
export const DELETE_PARTNER = "DELETE_PARTNER";
export const GET_PARTNER_LOGINS = "GET_PARTNER_LOGINS";
export const GET_PARTNER_LOGINS_SUCCEED = "GET_PARTNER_LOGINS_SUCCEED";
export const GET_PARTNER_LOGINS_FAILED = "GET_PARTNER_LOGINS_FAILED";
export const ADD_PARTNER_LOGIN = "ADD_PARTNER_LOGIN";
export const GET_PARTNER_GROUPS = "GET_PARTNER_GROUPS";
export const GET_PARTNER_GROUPS_SUCCEED = "GET_PARTNER_GROUPS_SUCCEED";
export const GET_PARTNER_GROUPS_FAILED = "GET_PARTNER_GROUPS_FAILED";
export const ADD_GROUP_FROM_PARTNER = "ADD_GROUP_FROM_PARTNER";
export const DELETE_GROUP_FROM_PARTNER = "DELETE_GROUP_FROM_PARTNER";
export const UPSERT_PARTNER_CHANGED_GROUP = "UPSERT_PARTNER_CHANGED_GROUP";
export const GET_PARTNER_SHOPS = "GET_PARTNER_SHOPS";
export const GET_PARTNER_MERCHANTS = "GET_PARTNER_MERCHANTS";
export const GET_TOP_PARTNERS = "GET_TOP_PARTNERS";
export const GET_TOP_PARTNERS_SUCCEED = "GET_TOP_PARTNERS_SUCCEED";
export const GET_TOP_PARTNERS_FAILED = "GET_TOP_PARTNERS_FAILED";

export const GET_SHOPS = "GET_SHOPS";
export const GET_SHOPS_SUCCEED = "GET_SHOPS_SUCCEED";
export const GET_SHOPS_FAILED = "GET_SHOPS_FAILED";
export const GET_SHOP = "GET_SHOP";
export const GET_SHOP_SUCCEED = "GET_SHOP_SUCCEED";
export const GET_SHOP_FAILED = "GET_SHOP_FAILED";
export const ADD_SHOP = "ADD_SHOP";
export const EDIT_SHOP = "EDIT_SHOP";
export const DELETE_SHOP = "DELETE_SHOP";
export const GET_SHOP_GATEWAY_PROPS = "GET_SHOP_GATEWAY_PROPS";
export const GET_SHOP_GATEWAY_PROPS_SUCCEED = "GET_SHOP_GATEWAY_PROPS_SUCCEED";
export const GET_SHOP_GATEWAY_PROPS_FAILED = "GET_SHOP_GATEWAY_PROPS_FAILED";
export const EDIT_SHOP_GATEWAY_PROPS = "EDIT_SHOP_GATEWAY_PROPS";
export const UPSERT_SHOP_GATEWAY = "UPSERT_SHOP_GATEWAY";
export const EDIT_SHOP_GATEWAY_DATA = "EDIT_SHOP_GATEWAY_DATA";
export const UPSERT_SHOP_GATEWAYS = "UPSERT_SHOP_GATEWAYS";
export const GET_TERMINALS_BY_GATEWAY = "GET_TERMINALS_BY_GATEWAY";
export const GET_TERMINALS_BY_GATEWAY_SUCCEED =
  "GET_TERMINALS_BY_GATEWAY_SUCCEED";
export const GET_TERMINALS_BY_GATEWAY_FAILED =
  "GET_TERMINALS_BY_GATEWAY_FAILED";
export const GET_SHOPS_BY_MERCHANT = "GET_SHOPS_BY_MERCHANT";
export const GET_SHOPS_BY_GROUP = "GET_SHOPS_BY_GROUP";
export const GET_SHOPS_BY_PARTNER = "GET_SHOPS_BY_PARTNER";
export const GET_AMOUNT_SHOPS_FOR_GROUP = "GET_AMOUNT_SHOPS_FOR_GROUP";
export const GET_AMOUNT_SHOPS_FOR_PARTNER = "GET_AMOUNT_SHOPS_FOR_PARTNER";
export const GET_TOP_SHOPS = "GET_TOP_SHOPS";
export const GET_TOP_SHOPS_SUCCEED = "GET_TOP_SHOPS_SUCCEED";
export const GET_TOP_SHOPS_FAILED = "GET_TOP_SHOPS_FAILED";
export const GET_SHOP_TERMINALS = "GET_SHOP_TERMINALS";
export const GET_SHOP_TERMINALS_SUCCEED = "GET_SHOP_TERMINALS_SUCCEED";
export const GET_SHOP_TERMINALS_FAILED = "GET_SHOP_TERMINALS_FAILED";
export const GET_SHOP_TERMINAL = "GET_SHOP_TERMINAL";
export const GET_SHOP_TERMINAL_SUCCEED = "GET_SHOP_TERMINAL_SUCCEED";
export const GET_SHOP_TERMINAL_FAILED = "GET_SHOP_TERMINAL_FAILED";
export const ADD_SHOP_TERMINAL = "ADD_SHOP_TERMINAL";
export const GENERATE_TERMINAL_CREDENTIALS = "GENERATE_TERMINAL_CREDENTIALS";
export const GET_TERMINALS = "GET_TERMINALS";
export const GET_TERMINALS_SUCCEED = "GET_TERMINALS_SUCCEED";
export const GET_TERMINALS_FAILED = "GET_TERMINALS_FAILED";

export const GET_RATES = "GET_RATES";
export const GET_RATES_SUCCEED = "GET_RATES_SUCCEED";
export const GET_RATES_FAILED = "GET_RATES_FAILED";
export const GET_RATE = "GET_RATE";
export const GET_RATE_SUCCEED = "GET_RATE_SUCCEED";
export const GET_RATE_FAILED = "GET_RATE_FAILED";
export const ADD_RATE = "ADD_RATE";
export const EDIT_RATE = "EDIT_RATE";
export const DELETE_RATE = "DELETE_RATE";
export const GET_TRANSACTIONS_FOR_GATEWAY = "GET_TRANSACTIONS_FOR_GATEWAY";
export const GET_TRANSACTIONS_FOR_GATEWAY_SUCCEED =
  "GET_TRANSACTIONS_FOR_GATEWAY_SUCCEED";
export const GET_TRANSACTIONS_FOR_GATEWAY_FAILED =
  "GET_TRANSACTIONS_FOR_GATEWAY_FAILED";
export const GET_PAYMENT_STATUS_RATE = "GET_PAYMENT_STATUS_RATE";
export const GET_PAYMENT_STATUS_RATE_SUCCEED =
  "GET_PAYMENT_STATUS_RATE_SUCCEED";
export const GET_PAYMENT_STATUS_RATE_FAILED = "GET_PAYMENT_STATUS_RATE_FAILED";
export const GET_ONE_TIME_CHARGES = "GET_ONE_TIME_CHARGES";
export const GET_ONE_TIME_CHARGES_SUCCEED = "GET_ONE_TIME_CHARGES_SUCCEED";
export const GET_ONE_TIME_CHARGES_FAILED = "GET_ONE_TIME_CHARGES_FAILED";
export const GET_PERIODIC_CHARGES = "GET_PERIODIC_CHARGES";
export const GET_PERIODIC_CHARGES_SUCCEED = "GET_PERIODIC_CHARGES_SUCCEED";
export const GET_PERIODIC_CHARGES_FAILED = "GET_PERIODIC_CHARGES_FAILED";
export const GET_CONDITIONAL_CHARGES = "GET_CONDITIONAL_CHARGES";
export const GET_CONDITIONAL_CHARGES_SUCCEED =
  "GET_CONDITIONAL_CHARGES_SUCCEED";
export const GET_CONDITIONAL_CHARGES_FAILED = "GET_CONDITIONAL_CHARGES_FAILED";
export const ADD_ONE_TIME_CHARGE = "ADD_ONE_TIME_CHARGE";
export const ADD_PERIODIC_CHARGE = "ADD_PERIODIC_CHARGE";
export const ADD_CONDITIONAL_CHARGE = "ADD_CONDITIONAL_CHARGE";
export const GET_RATE_TEMPLATES = "GET_RATE_TEMPLATES";
export const GET_RATE_TEMPLATES_SUCCEED = "GET_RATE_TEMPLATES_SUCCEED";
export const GET_RATE_TEMPLATES_FAILED = "GET_RATE_TEMPLATES_FAILED";
export const GET_RATE_TEMPLATE = "GET_RATE_TEMPLATE";
export const GET_RATE_TEMPLATE_SUCCEED = "GET_RATE_TEMPLATE_SUCCEED";
export const GET_RATE_TEMPLATE_FAILED = "GET_RATE_TEMPLATE_FAILED";
export const ADD_RATE_TEMPLATE = "ADD_RATE_TEMPLATE";
export const GET_RATE_TEMPLATE_FEES = "GET_RATE_TEMPLATE_FEES";
export const GET_RATE_TEMPLATE_FEES_SUCCEED = "GET_RATE_TEMPLATE_FEES_SUCCEED";
export const GET_RATE_TEMPLATE_FEES_FAILED = "GET_RATE_TEMPLATE_FEES_FAILED";
export const ADD_RATE_TEMPLATE_FEES = "ADD_RATE_TEMPLATE_FEES";
export const GET_RATE_REVISIONS = "GET_RATE_REVISIONS";
export const GET_RATE_REVISIONS_SUCCEED = "GET_RATE_REVISIONS_SUCCEED";
export const GET_RATE_REVISIONS_FAILED = "GET_RATE_REVISIONS_FAILED";
export const GET_RATE_REVISION_FEES = "GET_RATE_REVISION_FEES";
export const GET_RATE_REVISION_FEES_SUCCEED = "GET_RATE_REVISION_FEES_SUCCEED";
export const GET_RATE_REVISION_FEES_FAILED = "GET_RATE_REVISION_FEES_FAILED";

export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCEED = "GET_CURRENCIES_SUCCEED";
export const GET_CURRENCIES_FAILED = "GET_CURRENCIES_FAILED";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_SUCCEED = "GET_CURRENCY_SUCCEED";
export const GET_CURRENCY_FAILED = "GET_CURRENCY_FAILED";
export const ADD_CURRENCY = "ADD_CURRENCY";
export const ADD_CURRENCY_SUCCEED = "ADD_CURRENCY_SUCCEED";
export const ADD_CURRENCY_FAILED = "ADD_CURRENCY_FAILED";
export const EDIT_CURRENCY = "EDIT_CURRENCY";
export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const GET_CURRENCY_RATES = "GET_CURRENCY_RATES";
export const GET_CURRENCY_RATES_SUCCEED = "GET_CURRENCY_RATES_SUCCEED";
export const GET_CURRENCY_RATES_FAILED = "GET_CURRENCY_RATES_FAILED";

export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_SUCCEED = "GET_SERVICES_SUCCEED";
export const GET_SERVICES_FAILED = "GET_SERVICES_FAILED";
export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SUCCEED = "GET_SERVICE_SUCCEED";
export const GET_SERVICE_FAILED = "GET_SERVICE_FAILED";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const CLOSE_WORKING_DAY = "CLOSE_WORKING_DAY";

export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_MANAGER_MODAL = "SHOW_MANAGER_MODAL";
export const RESET_MODAL_DATA = "RESET_MODAL_DATA";

export const GET_BLACKLIST_ITEMS = "GET_BLACKLIST_ITEMS";
export const GET_BLACKLIST_ITEMS_SUCCEED = "GET_BLACKLIST_ITEMS_SUCCEED";
export const GET_BLACKLIST_ITEMS_FAILED = "GET_BLACKLIST_ITEMS_FAILED";
export const GET_BLACKLIST_ITEM = "GET_BLACKLIST_ITEM";
export const GET_BLACKLIST_ITEM_SUCCEED = "GET_BLACKLIST_ITEM_SUCCEED";
export const GET_BLACKLIST_ITEM_FAILED = "GET_BLACKLIST_ITEM_FAILED";
export const DELETE_BLACKLIST_ITEM = "DELETE_BLACKLIST_ITEM";
export const ADD_BLACKLIST_ITEM = "ADD_BLACKLIST_ITEM";
export const UPDATE_BLACKLIST_ITEM = "UPDATE_BLACKLIST_ITEM";
export const ADD_BLACKLIST_RULE = "ADD_BLACKLIST_RULE";
export const DELETE_BLACKLIST_RULE = "DELETE_BLACKLIST_RULE";
export const GET_BLACKLIST_GLOBAL = "GET_BLACKLIST_GLOBAL";
export const GET_BLACKLIST_GLOBAL_SUCCEED = "GET_BLACKLIST_GLOBAL_SUCCEED";
export const GET_BLACKLIST_GLOBAL_FAILED = "GET_BLACKLIST_GLOBAL_FAILED";
export const GET_BLACKLIST_MERCHANTS = "GET_BLACKLIST_MERCHANTS";
export const GET_BLACKLIST_MERCHANTS_SUCCEED =
  "GET_BLACKLIST_MERCHANTS_SUCCEED";
export const GET_BLACKLIST_MERCHANTS_FAILED = "GET_BLACKLIST_MERCHANTS_FAILED";

export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const ACTIVATE_TRANSACTION = "ACTIVATE_TRANSACTION";
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCEED = "GET_TRANSACTION_SUCCEED";
export const GET_TRANSACTION_FAILED = "GET_TRANSACTION_FAILED";
export const GET_TRANSACTION_RATES = "GET_TRANSACTION_RATES";
export const GET_TRANSACTION_RATES_SUCCEED = "GET_TRANSACTION_RATES_SUCCEED";
export const GET_TRANSACTION_RATES_FAILED = "GET_TRANSACTION_RATES_FAILED";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCEED = "GET_TRANSACTIONS_SUCCEED";
export const GET_TRANSACTIONS_FAILED = "GET_TRANSACTIONS_FAILED";
export const GET_SUCCESS_FAILED_TRANSACTIONS_COUNT =
  "GET_SUCCESS_FAILED_TRANSACTIONS_COUNT";
export const GET_SUCCESS_FAILED_TRANSACTIONS_COUNT_SUCCEED =
  "GET_SUCCESS_FAILED_TRANSACTIONS_COUNT_SUCCEED";
export const GET_SUCCESS_FAILED_SHOP_TRANSACTIONS_COUNT =
  "GET_SUCCESS_FAILED_SHOP_TRANSACTIONS_COUNT";
export const GET_SUCCESS_FAILED_SHOP_TRANSACTIONS_COUNT_SUCCEED =
  "GET_SUCCESS_FAILED_SHOP_TRANSACTIONS_COUNT_SUCCEED";
export const GET_TRANSACTIONS_COUNT_FAILED = "GET_TRANSACTIONS_COUNT_FAILED";
export const GET_TRANSACTION_TYPES = "GET_TRANSACTION_TYPES";
export const GET_TRANSACTION_TYPES_SUCCEED = "GET_TRANSACTION_TYPES_SUCCEED";
export const GET_TRANSACTION_TYPES_FAILED = "GET_TRANSACTION_TYPES_FAILED";
export const GET_TRANSACTIONS_AMOUNT = "GET_TRANSACTIONS_AMOUNT";
export const GET_TRANSACTIONS_AMOUNT_SUCCEED =
  "GET_TRANSACTIONS_AMOUNT_SUCCEED";
export const GET_TRANSACTIONS_AMOUNT_FAILED = "GET_TRANSACTIONS_AMOUNT_FAILED";
export const GET_SHOP_TRANSACTIONS_AMOUNT = "GET_SHOP_TRANSACTIONS_AMOUNT";
export const GET_SHOP_TRANSACTIONS_AMOUNT_SUCCEED =
  "GET_SHOP_TRANSACTIONS_AMOUNT_SUCCEED";
export const GET_TRANSACTIONS_CURRENCIES = "GET_TRANSACTIONS_CURRENCIES";
export const GET_TRANSACTIONS_CURRENCIES_SUCCEED =
  "GET_TRANSACTIONS_CURRENCIES_SUCCEED";
export const GET_TRANSACTIONS_CURRENCIES_FAILED =
  "GET_TRANSACTIONS_CURRENCIES_FAILED";
export const GET_CURRENCIES_OF_TRANSACTIONS = "GET_CURRENCIES_OF_TRANSACTIONS";
export const GET_CURRENCIES_OF_TRANSACTIONS_SUCCEED =
  "GET_CURRENCIES_OF_TRANSACTIONS_SUCCEED";
export const GET_CURRENCIES_OF_TRANSACTIONS_FAILED =
  "GET_CURRENCIES_OF_TRANSACTIONS_FAILED";
export const GET_TRANSACTION_PROCESSING_STEPS =
  "GET_TRANSACTION_PROCESSING_STEPS";
export const GET_TRANSACTION_PROCESSING_STEPS_SUCCEED =
  "GET_TRANSACTION_PROCESSING_STEPS_SUCCEED";
export const GET_TRANSACTION_PROCESSING_PARAMS =
  "GET_TRANSACTION_PROCESSING_PARAMS";
export const GET_TRANSACTION_CARD_TYPES = "GET_TRANSACTION_CARD_TYPES";
export const GET_TRANSACTION_CARD_TYPES_SUCCEED =
  "GET_TRANSACTION_CARD_TYPES_SUCCEED";
export const GET_TRANSACTION_CARD_TYPES_FAILED =
  "GET_TRANSACTION_CARD_TYPES_FAILED";
export const GET_MISMATCH_TRANSACTIONS = "GET_MISMATCH_TRANSACTIONS";
export const GET_MISMATCH_TRANSACTIONS_SUCCEED =
  "GET_MISMATCH_TRANSACTIONS_SUCCEED";
export const GET_MISMATCH_TRANSACTIONS_FAILED =
  "GET_MISMATCH_TRANSACTIONS_FAILED";
export const GET_MISMATCH_TRANSACTION = "GET_MISMATCH_TRANSACTION";
export const GET_MISMATCH_TRANSACTION_SUCCEED =
  "GET_MISMATCH_TRANSACTION_SUCCEED";
export const GET_MISMATCH_TRANSACTION_FAILED =
  "GET_MISMATCH_TRANSACTION_FAILED";

export const GET_USERS_LOGS = "GET_USERS_LOGS";
export const GET_USERS_LOGS_SUCCEED = "GET_USERS_LOGS_SUCCEED";
export const GET_USERS_LOGS_FAILED = "GET_USERS_LOGS_FAILED";
export const GET_ADMINS_LOGS = "GET_ADMINS_LOGS";
export const GET_ADMINS_LOGS_SUCCEED = "GET_ADMINS_LOGS_SUCCEED";
export const GET_ADMINS_LOGS_FAILED = "GET_ADMINS_LOGS_FAILED";
export const GET_MERCHANTS_LOGS = "GET_MERCHANTS_LOGS";
export const GET_MERCHANTS_LOGS_SUCCEED = "GET_MERCHANTS_LOGS_SUCCEED";
export const GET_MERCHANTS_LOGS_FAILED = "GET_MERCHANTS_LOGS_FAILED";
export const GET_GROUP_LOGS = "GET_GROUP_LOGS";
export const GET_GROUP_LOGS_SUCCEED = "GET_GROUP_LOGS_SUCCEED";
export const GET_GROUP_LOGS_FAILED = "GET_GROUP_LOGS_FAILED";
export const GET_PARTNER_LOGS = "GET_PARTNER_LOGS";
export const GET_PARTNER_LOGS_SUCCEED = "GET_PARTNER_LOGS_SUCCEED";
export const GET_PARTNER_LOGS_FAILED = "GET_PARTNER_LOGS_FAILED";
export const GET_TRANSACTIONS_LOGS = "GET_TRANSACTIONS_LOGS";
export const GET_TRANSACTIONS_LOGS_SUCCEED = "GET_TRANSACTIONS_LOGS_SUCCEED";
export const GET_TRANSACTIONS_LOGS_FAILED = "GET_TRANSACTIONS_LOGS_FAILED";
export const GET_TRANSACTIONS_STEPS_LOGS = "GET_TRANSACTIONS_STEPS_LOGS";
export const GET_TRANSACTIONS_STEPS_LOGS_SUCCEED =
  "GET_TRANSACTIONS_STEPS_LOGS_SUCCEED";
export const GET_TRANSACTIONS_STEPS_LOGS_FAILED =
  "GET_TRANSACTIONS_STEPS_LOGS_FAILED";
export const GET_DECTA_RECOT_SERVICE_LOGS = "GET_DECTA_RECOT_SERVICE_LOGS";
export const GET_DECTA_RECOT_SERVICE_LOGS_SUCCEED =
  "GET_DECTA_RECOT_SERVICE_LOGS_SUCCEED";
export const GET_DECTA_RECOT_SERVICE_LOGS_FAILED =
  "GET_DECTA_RECOT_SERVICE_LOGS_FAILED";
export const GET_CHARGEBACK_SERVICE_LOGS = "GET_CHARGEBACK_SERVICE_LOGS";
export const GET_CHARGEBACK_SERVICE_LOGS_SUCCEED =
  "GET_CHARGEBACK_SERVICE_LOGS_SUCCEED";
export const GET_CHARGEBACK_SERVICE_LOGS_FAILED =
  "GET_CHARGEBACK_SERVICE_LOGS_FAILED";
export const GET_LOGIN_EXPIRED_SERVICE_LOGS = "GET_LOGIN_EXPIRED_SERVICE_LOGS";
export const GET_LOGIN_EXPIRED_SERVICE_LOGS_SUCCEED =
  "GET_LOGIN_EXPIRED_SERVICE_LOGS_SUCCEED";
export const GET_LOGIN_EXPIRED_SERVICE_LOGS_FAILED =
  "GET_LOGIN_EXPIRED_SERVICE_LOGS_FAILED";
export const GET_CURRENCY_EXCHANGE_SERVICE_LOGS =
  "GET_CURRENCY_EXCHANGE_SERVICE_LOGS";
export const GET_CURRENCY_EXCHANGE_SERVICE_LOGS_SUCCEED =
  "GET_CURRENCY_EXCHANGE_SERVICE_LOGS_SUCCEED";
export const GET_CURRENCY_EXCHANGE_SERVICE_LOGS_FAILED =
  "GET_CURRENCY_EXCHANGE_SERVICE_LOGS_FAILED";
export const GET_DELETE_CARD_DATA_SERVICE_LOGS =
  "GET_DELETE_CARD_DATA_SERVICE_LOGS";
export const GET_DELETE_CARD_DATA_SERVICE_LOGS_SUCCEED =
  "GET_DELETE_CARD_DATA_SERVICE_LOGS_SUCCEED";
export const GET_DELETE_CARD_DATA_SERVICE_LOGS_FAILED =
  "GET_DELETE_CARD_DATA_SERVICE_LOGS_FAILED";
export const GET_RECURRING_SERVICE_LOGS = "GET_RECURRING_SERVICE_LOGS";
export const GET_RECURRING_SERVICE_LOGS_SUCCEED =
  "GET_RECURRING_SERVICE_LOGS_SUCCEED";
export const GET_RECURRING_SERVICE_LOGS_FAILED =
  "GET_RECURRING_SERVICE_LOGS_FAILED";

export const INVERSE_SEARCH = "INVERSE_SEARCH";
export const RESET_SEARCH = "RESET_SEARCH";
export const INPUT_SEARCH_IN_ADMINS_LOGS = "INPUT_SEARCH_IN_ADMINS_LOGS";
export const INPUT_SEARCH_IN_MERCHANTS_LOGS = "INPUT_SEARCH_IN_MERCHANTS_LOGS";
export const INPUT_SEARCH_IN_GROUPS_LOGS = "INPUT_SEARCH_IN_GROUPS_LOGS";
export const INPUT_SEARCH_IN_PARTNERS_LOGS = "INPUT_SEARCH_IN_PARTNERS_LOGS";
export const INPUT_SEARCH_IN_TRANSACTIONS_LOGS =
  "INPUT_SEARCH_IN_TRANSACTIONS_LOGS";
export const INPUT_SEARCH_IN_SERVICE_LOGS = "INPUT_SEARCH_IN_SERVICE_LOGS";
export const SET_CURRENT_SEARCH_DATA = "SET_CURRENT_SEARCH_DATA";

export const INPUT_SEARCH_IN_ACCOUNTS = "INPUT_SEARCH_IN_ACCOUNTS";
export const INPUT_SEARCH_IN_BLACKLIST = "INPUT_SEARCH_IN_BLACKLIST";
export const INPUT_SEARCH_IN_CARDS = "INPUT_SEARCH_IN_CARDS";
export const INPUT_SEARCH_IN_CURRENCIES = "INPUT_SEARCH_IN_CURRENCIES";
export const INPUT_SEARCH_IN_CHARGEBACKS = "INPUT_SEARCH_IN_CHARGEBACKS";
export const INPUT_SEARCH_IN_GATEWAYS = "INPUT_SEARCH_IN_GATEWAYS";
export const INPUT_SEARCH_IN_GROUPS = "INPUT_SEARCH_IN_GROUPS";
export const INPUT_SEARCH_IN_LOGINS = "INPUT_SEARCH_IN_LOGINS";
export const INPUT_SEARCH_IN_MERCHANTS = "INPUT_SEARCH_IN_MERCHANTS";
export const INPUT_SEARCH_IN_PARTNERS = "INPUT_SEARCH_IN_PARTNERS";
export const INPUT_SEARCH_IN_PRIVILEGES = "INPUT_SEARCH_IN_PRIVILEGES";
export const INPUT_SEARCH_IN_RATES = "INPUT_SEARCH_IN_RATES";
export const INPUT_SEARCH_IN_ROLES = "INPUT_SEARCH_IN_ROLES";
export const INPUT_SEARCH_IN_SHOPS = "INPUT_SEARCH_IN_SHOPS";
export const INPUT_SEARCH_IN_TRANSACTIONS = "INPUT_SEARCH_IN_TRANSACTIONS";
export const INPUT_SEARCH_IN_MERCHANT_TRANSACTIONS =
  "INPUT_SEARCH_IN_MERCHANT_TRANSACTIONS";
export const INPUT_SEARCH_IN_TRANSACTIONS_TEMPLATES =
  "INPUT_SEARCH_IN_TRANSACTIONS_TEMPLATES";
export const INPUT_SEARCH_IN_MERCHANTS_BLACKLIST =
  "INPUT_SEARCH_IN_MERCHANTS_BLACKLIST";
export const INPUT_SEARCH_IN_GLOBAL_BLACKLIST =
  "INPUT_SEARCH_IN_GLOBAL_BLACKLIST";
export const INPUT_SEARCH_IN_ORDERS = "INPUT_SEARCH_IN_ORDERS";
export const INPUT_SEARCH_IN_ADMINS = "INPUT_SEARCH_IN_ADMINS";
export const INPUT_SEARCH_IN_STATEMENTS = "INPUT_SEARCH_IN_STATEMENTS";

export const INPUT_SEARCH_IN_MERCHANTS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_MERCHANTS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_GROUPS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_GROUPS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_PARTNERS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_PARTNERS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_LOGINS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_LOGINS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_SHOPS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_SHOPS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_TERMINALS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_TERMINALS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_GATEWAYS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_GATEWAYS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_ACCOUNTS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_ACCOUNTS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_CURRENCIES_AUDIT_TABLE =
  "INPUT_SEARCH_IN_CURRENCIES_AUDIT_TABLE";
export const INPUT_SEARCH_IN_TRANSACTION_OVERVIEWS_AUDIT_TABLE =
  "INPUT_SEARCH_IN_TRANSACTION_OVERVIEWS_AUDIT_TABLE";
export const INPUT_SEARCH_IN_TRANSACTION_PROCESSING_AUDIT_TABLE =
  "INPUT_SEARCH_IN_TRANSACTION_PROCESSING_AUDIT_TABLE";
export const INPUT_SEARCH_IN_TRANSACTION_DATA_AUDIT_TABLE =
  "INPUT_SEARCH_IN_TRANSACTION_DATA_AUDIT_TABLE";
export const INPUT_SEARCH_IN_SERVICE_AUDIT_TABLE =
  "INPUT_SEARCH_IN_SERVICE_AUDIT_TABLE";
export const INPUT_SEARCH_IN_MISMATCH_TRANSACTION_TABLE =
  "INPUT_SEARCH_IN_MISMATCH_TRANSACTION_TABLE";

export const GET_MERCHANT_TRANSACTIONS = "GET_MERCHANT_TRANSACTIONS";
export const GET_MERCHANT_TRANSACTIONS_SUCCEED =
  "GET_MERCHANT_TRANSACTIONS_SUCCEED";
export const GET_MERCHANT_TRANSACTIONS_FAILED =
  "GET_MERCHANT_TRANSACTIONS_FAILED";
export const ADD_PARAM_TRANSACTION = "ADD_PARAM_TRANSACTION";
export const CLEAN_PARAMS = "CLEAN_PARAMS";
export const GET_TRANSACTION_TEMPLATES = "GET_TRANSACTION_TEMPLATES";
export const GET_TRANSACTION_TEMPLATES_SUCCEED =
  "GET_TRANSACTION_TEMPLATES_SUCCEED";
export const GET_TRANSACTION_TEMPLATES_FAILED =
  "GET_TRANSACTION_TEMPLATES_FAILED";
export const GET_TRANSACTION_TEMPLATE = "GET_TRANSACTION_TEMPLATE";
export const GET_TRANSACTION_STEPS = "GET_TRANSACTION_STEPS";
export const GET_TRANSACTION_STEPS_SUCCEED = "GET_TRANSACTION_STEPS_SUCCEED";
export const GET_TRANSACTION_STEPS_FAILED = "GET_TRANSACTION_STEPS_FAILED";
export const GET_STEP = "GET_STEP";
export const GET_PARAMS_FOR_STEP = "GET_PARAMS_FOR_STEP";
export const GET_PARAMS_FOR_STEP_SUCCEED = "GET_PARAMS_FOR_STEP_SUCCEED";
export const GET_PARAMS_FOR_STEP_FAILED = "GET_PARAMS_FOR_STEP_FAILED";

export const GET_SHOP_TOTAL_PROCESSED = "GET_SHOP_TOTAL_PROCESSED";
export const GET_SHOP_TOTAL_PROCESSED_FAILED =
  "GET_SHOP_TOTAL_PROCESSED_FAILED";
export const GET_SHOP_TOTAL_PROCESSED_SUCCEED =
  "GET_SHOP_TOTAL_PROCESSED_SUCCEED";

export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const INVERSE_SIDEBAR = "INVERSE_SIDEBAR";
export const COLLAPSE_ITEM_CLICK = "COLLAPSE_ITEM_CLICK";

export const GET_USER_ACCOUNT = "GET_USER_ACCOUNT";
export const GET_USER_ACCOUNT_SUCCEED = "GET_USER_ACCOUNT_SUCCEED";
export const GET_USER_ACCOUNT_FAILED = "GET_USER_ACCOUNT_FAILED";
export const EDIT_USER_ACCOUNT = "EDIT_USER_ACCOUNT";

export const GET_REPORT_CURRENCY = "GET_REPORT_CURRENCY";
export const GET_REPORT_CURRENCY_SUCCEED = "GET_REPORT_CURRENCY_SUCCEED";
export const GET_REPORT_CURRENCY_FAILED = "GET_REPORT_CURRENCY_FAILED";

export const GET_REPORT_SHOP_TOTALS = "GET_REPORT_SHOP_TOTALS";
export const GET_REPORT_SHOP_TOTALS_SUCCEED = "GET_REPORT_SHOP_TOTALS_SUCCEED";
export const GET_REPORT_SHOP_TOTALS_FAILED = "GET_REPORT_SHOP_TOTALS_FAILED";

export const GET_REPORT_DAILY_CURRENCY = "GET_REPORT_DAILY_CURRENCY";
export const GET_REPORT_DAILY_CURRENCY_SUCCEED =
  "GET_REPORT_DAILY_CURRENCY_SUCCEED";
export const GET_REPORT_DAILY_CURRENCY_FAILED =
  "GET_REPORT_DAILY_CURRENCY_FAILED";

export const GET_REPORT_HOLDS = "GET_REPORT_HOLDS";
export const GET_REPORT_HOLDS_SUCCEED = "GET_REPORT_HOLDS_SUCCEED";
export const GET_REPORT_HOLDS_FAILED = "GET_REPORT_HOLDS_FAILED";
export const GET_REPORT_SHOP_HOLDS = "GET_REPORT_SHOP_HOLDS";
export const GET_REPORT_SHOP_HOLDS_SUCCEED = "GET_REPORT_SHOP_HOLDS_SUCCEED";
export const GET_REPORT_SHOP_HOLDS_FAILED = "GET_REPORT_SHOP_HOLDS_FAILED";
export const GET_TRANSACTION_AND_COMMISSION_REPORT =
  "GET_TRANSACTION_AND_COMMISSION_REPORT";
export const GET_TRANSACTION_AND_COMMISSION_REPORT_SUCCEED =
  "GET_TRANSACTION_AND_COMMISSION_REPORT_SUCCEED";
export const GET_TRANSACTION_AND_COMMISSION_REPORT_FAILED =
  "GET_TRANSACTION_AND_COMMISSION_REPORT_FAILED";

export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_HISTORY_SUCCEED =
  "GET_TRANSACTION_HISTORY_SUCCEED";
export const GET_TRANSACTION_HISTORY_FAILED = "GET_TRANSACTION_HISTORY_FAILED";
export const GET_TRANSACTION_CURRENCIES = "GET_TRANSACTION_CURRENCIES";
export const GET_TRANSACTION_CURRENCIES_SUCCEED =
  "GET_TRANSACTION_CURRENCIES_SUCCEED";
export const GET_TRANSACTION_CURRENCIES_FAILED =
  "GET_TRANSACTION_CURRENCIES_FAILED";
export const GET_DAILY_TRANSACTION = "GET_DAILY_TRANSACTION";
export const GET_DAILY_TRANSACTION_SUCCEED = "GET_DAILY_TRANSACTION_SUCCEED";
export const GET_DAILY_TRANSACTION_FAILED = "GET_DAILY_TRANSACTION_FAILED";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCEED = "GET_ORDERS_SUCCEED";
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCEED = "GET_ORDER_SUCCEED";
export const GET_ORDER_FAILED = "GET_ORDER_FAILED";
export const GET_SHOP_TOTALS = "GET_SHOP_TOTALS";
export const GET_SHOP_TOTALS_SUCCEED = "GET_SHOP_TOTALS_SUCCEED";
export const GET_SHOP_TOTALS_FAILED = "GET_SHOP_TOTALS_FAILED";
export const GET_TOTALS = "GET_TOTALS";
export const GET_TOTALS_SUCCEED = "GET_TOTALS_SUCCEED";
export const GET_TOTALS_FAILED = "GET_TOTALS_FAILED";
export const GET_TOTAL_PROCESSED = "GET_TOTAL_PROCESSED";
export const GET_TOTAL_PROCESSED_SUCCEED = "GET_TOTAL_PROCESSED_SUCCEED";
export const GET_TOTAL_PROCESSED_FAILED = "GET_TOTAL_PROCESSED_FAILED";
export const GET_TOTAL_TO_CLIENT = "GET_TOTAL_TO_CLIENT";
export const GET_TOTAL_TO_CLIENT_SUCCEED = "GET_TOTAL_TO_CLIENT_SUCCEED";
export const GET_TOTAL_TO_CLIENT_FAILED = "GET_TOTAL_TO_CLIENT_FAILED";
export const GET_TOTAL_TO_PROCESSOR = "GET_TOTAL_TO_PROCESSOR";
export const GET_TOTAL_TO_PROCESSOR_SUCCEED = "GET_TOTAL_TO_PROCESSOR_SUCCEED";
export const GET_TOTAL_TO_PROCESSOR_FAILED = "GET_TOTAL_TO_PROCESSOR_FAILED";
export const GET_TRANSACTION_TYPE = "GET_TRANSACTION_TYPE";
export const GET_TRANSACTION_TYPE_SUCCEED = "GET_TRANSACTION_TYPE_SUCCEED";
export const GET_TRANSACTION_TYPE_FAILED = "GET_TRANSACTION_TYPE_FAILED";
export const GET_SHOP_TRANSACTION_TYPE = "GET_SHOP_TRANSACTION_TYPE";
export const GET_SHOP_TRANSACTION_TYPE_SUCCEED =
  "GET_SHOP_TRANSACTION_TYPE_SUCCEED";
export const GET_SHOP_TRANSACTION_TYPE_FAILED =
  "GET_SHOP_TRANSACTION_TYPE_FAILED";
export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_SUCCEED = "GET_BALANCE_SUCCEED";
export const GET_BALANCE_FAILED = "GET_BALANCE_FAILED";
export const GET_TRANSACTION_AMOUNT = "GET_TRANSACTION_AMOUNT";
export const GET_TRANSACTION_AMOUNT_SUCCEED = "GET_TRANSACTION_AMOUNT_SUCCEED";
export const GET_TRANSACTION_AMOUNT_FAILED = "GET_TRANSACTION_AMOUNT_FAILED";

export const GET_ADMIN_DASHBOARD_DATA = "GET_ADMIN_DASHBOARD_DATA";
export const GET_ADMIN_DASHBOARD_DATA_SUCCEED =
  "GET_ADMIN_DASHBOARD_DATA_SUCCEED";
export const GET_ADMIN_DASHBOARD_DATA_FAILED =
  "GET_ADMIN_DASHBOARD_DATA_FAILED";

export const GET_MERCHANTS_LIMIT_DATA = "GET_MERCHANTS_LIMIT_DATA";
export const GET_MERCHANTS_LIMIT_DATA_SUCCEED =
  "GET_MERCHANTS_LIMIT_DATA_SUCCEED";
export const GET_MERCHANTS_LIMIT_DATA_FAILED =
  "GET_MERCHANTS_LIMIT_DATA_FAILED";

export const GET_MERCHANTS_TOTALS_DATA = "GET_MERCHANTS_TOTALS_DATA";
export const GET_MERCHANTS_TOTALS_DATA_SUCCEED =
  "GET_MERCHANTS_TOTALS_DATA_SUCCEED";
export const GET_ERROR_REPORT_FAILED = "GET_ERROR_REPORT_FAILED";

export const GET_ERROR_REPORT = "GET_ERROR_REPORT";
export const GET_ERROR_REPORT_SUCCEED = "GET_ERROR_REPORT_SUCCEED";
export const GET_MERCHANTS_TOTALS_DATA_FAILED =
  "GET_MERCHANTS_TOTALS_DATA_FAILED";

export const GET_TRANSACTIONS_TYPES_FOR_GATEWAY =
  "GET_TRANSACTIONS_TYPES_FOR_GATEWAY";
export const GET_TRANSACTIONS_TYPES_FOR_GATEWAY_SUCCEED =
  "GET_TRANSACTIONS_TYPES_FOR_GATEWAY_SUCCEED";
export const GET_TRANSACTIONS_TYPES_FOR_GATEWAY_FAILED =
  "GET_TRANSACTIONS_TYPES_FOR_GATEWAY_FAILED";

export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNTS_SUCCEED = "GET_ACCOUNTS_SUCCEED";
export const GET_ACCOUNTS_FAILED = "GET_ACCOUNTS_FAILED";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_SUCCEED = "ADD_ACCOUNT_SUCCEED";
export const ADD_ACCOUNT_FAILED = "ADD_ACCOUNT_FAILED";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

export const GET_TRANSACTION_HOLDS = "GET_TRANSACTION_HOLDS";
export const GET_TRANSACTION_HOLDS_SUCCEED = "GET_TRANSACTION_HOLDS_SUCCEED";
export const GET_TRANSACTION_HOLDS_FAILED = "GET_TRANSACTION_HOLDS_FAILED";
export const ADD_TRANSACTION_HOLDS = "ADD_TRANSACTION_HOLDS";

export const GET_CHARGEBACKS = "GET_CHARGEBACKS";
export const GET_CHARGEBACKS_SUCCEED = "GET_CHARGEBACKS_SUCCEED";
export const GET_CHARGEBACKS_FAILED = "GET_CHARGEBACKS_FAILED";
export const GET_CHARGEBACK = "GET_CHARGEBACK";
export const GET_CHARGEBACK_SUCCEED = "GET_CHARGEBACK_SUCCEED";
export const GET_CHARGEBACK_FAILED = "GET_CHARGEBACK_FAILED";

export const CALCULATE_STATEMENT = "CALCULATE_STATEMENT";
export const GET_STATEMENT_TRANSACTIONS = "GET_STATEMENT_TRANSACTIONS";
export const GET_STATEMENTS = "GET_STATEMENTS";
export const GET_STATEMENTS_SUCCEED = "GET_STATEMENTS_SUCCEED";
export const GET_STATEMENTS_FAILED = "GET_STATEMENTS_FAILED";
export const GET_STATEMENT = "GET_STATEMENT";
export const GET_STATEMENT_SUCCEED = "GET_STATEMENT_SUCCEED";
export const GET_STATEMENT_FAILED = "GET_STATEMENT_FAILED";
export const CHANGE_STATEMENT_STATUS = "CHANGE_STATEMENT_STATUS";
export const GET_MERCHANT_STATEMENTS = "GET_MERCHANT_STATEMENTS";
export const GET_MERCHANT_STATEMENTS_SUCCEED =
  "GET_MERCHANT_STATEMENTS_SUCCEED";
export const GET_MERCHANT_STATEMENTS_FAILED = "GET_MERCHANT_STATEMENTS_FAILED";
export const GET_STATEMENT_CURRENCY = "GET_STATEMENT_CURRENCY";
export const GET_STATEMENT_CURRENCY_SUCCEED = "GET_STATEMENT_CURRENCY_SUCCEED";
export const GET_STATEMENT_CURRENCY_FAILED = "GET_STATEMENT_CURRENCY_FAILED";
export const MERGE_STATEMENT = "MERGE_STATEMENT";

export const GET_LOGIN_CHANGE_HISTORY = "GET_LOGIN_CHANGE_HISTORY";
export const GET_LOGIN_CHANGE_HISTORY_SUCCEED =
  "GET_LOGIN_CHANGE_HISTORY_SUCCEED";
export const GET_LOGIN_CHANGE_HISTORY_FAILED =
  "GET_LOGIN_CHANGE_HISTORY_FAILED";
export const GET_MERCHANT_CHANGE_HISTORY = "GET_MERCHANT_CHANGE_HISTORY";
export const GET_MERCHANT_CHANGE_HISTORY_SUCCEED =
  "GET_MERCHANT_CHANGE_HISTORY_SUCCEED";
export const GET_MERCHANT_CHANGE_HISTORY_FAILED =
  "GET_MERCHANT_CHANGE_HISTORY_FAILED";
export const GET_GROUP_CHANGE_HISTORY = "GET_GROUP_CHANGE_HISTORY";
export const GET_GROUP_CHANGE_HISTORY_SUCCEED =
  "GET_GROUP_CHANGE_HISTORY_SUCCEED";
export const GET_GROUP_CHANGE_HISTORY_FAILED =
  "GET_GROUP_CHANGE_HISTORY_FAILED";
export const GET_PARTNER_CHANGE_HISTORY = "GET_PARTNER_CHANGE_HISTORY";
export const GET_PARTNER_CHANGE_HISTORY_SUCCEED =
  "GET_PARTNER_CHANGE_HISTORY_SUCCEED";
export const GET_PARTNER_CHANGE_HISTORY_FAILED =
  "GET_PARTNER_CHANGE_HISTORY_FAILED";
export const GET_TERMINAL_CHANGE_HISTORY = "GET_TERMINAL_CHANGE_HISTORY";
export const GET_TERMINAL_CHANGE_HISTORY_SUCCEED =
  "GET_TERMINAL_CHANGE_HISTORY_SUCCEED";
export const GET_TERMINAL_CHANGE_HISTORY_FAILED =
  "GET_TERMINAL_CHANGE_HISTORY_FAILED";
export const GET_SHOP_CHANGE_HISTORY = "GET_SHOP_CHANGE_HISTORY";
export const GET_SHOP_CHANGE_HISTORY_SUCCEED =
  "GET_SHOP_CHANGE_HISTORY_SUCCEED";
export const GET_SHOP_CHANGE_HISTORY_FAILED = "GET_SHOP_CHANGE_HISTORY_FAILED";
export const GET_GATEWAY_CHANGE_HISTORY = "GET_GATEWAY_CHANGE_HISTORY";
export const GET_GATEWAY_CHANGE_HISTORY_SUCCEED =
  "GET_GATEWAY_CHANGE_HISTORY_SUCCEED";
export const GET_GATEWAY_CHANGE_HISTORY_FAILED =
  "GET_GATEWAY_CHANGE_HISTORY_FAILED";
export const GET_CURRENCY_CHANGE_HISTORY = "GET_CURRENCY_CHANGE_HISTORY";
export const GET_CURRENCY_CHANGE_HISTORY_SUCCEED =
  "GET_CURRENCY_CHANGE_HISTORY_SUCCEED";
export const GET_CURRENCY_CHANGE_HISTORY_FAILED =
  "GET_CURRENCY_CHANGE_HISTORY_FAILED";
export const GET_TRANSACTION_PROCESSING_CHANGE_HISTORY =
  "GET_TRANSACTION_PROCESSING_CHANGE_HISTORY";
export const GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_SUCCEED =
  "GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_SUCCEED";
export const GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_FAILED =
  "GET_TRANSACTION_PROCESSING_CHANGE_HISTORY_FAILED";
export const GET_TRANSACTION_DATA_CHANGE_HISTORY =
  "GET_TRANSACTION_DATA_CHANGE_HISTORY";
export const GET_TRANSACTION_DATA_CHANGE_HISTORY_SUCCEED =
  "GET_TRANSACTION_DATA_CHANGE_HISTORY_SUCCEED";
export const GET_TRANSACTION_DATA_CHANGE_HISTORY_FAILED =
  "GET_TRANSACTION_DATA_CHANGE_HISTORY_FAILED";
export const GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY =
  "GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY";
export const GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_SUCCEED =
  "GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_SUCCEED";
export const GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_FAILED =
  "GET_TRANSACTION_OVERVIEW_CHANGE_HISTORY_FAILED";
export const GET_SERVICE_CHANGE_HISTORY = "GET_SERVICE_CHANGE_HISTORY";
export const GET_SERVICE_CHANGE_HISTORY_SUCCEED =
  "GET_SERVICE_CHANGE_HISTORY_SUCCEED";
export const GET_SERVICE_CHANGE_HISTORY_FAILED =
  "GET_SERVICE_CHANGE_HISTORY_FAILED";

export const GET_AUDIT_MERCHANT_TABLES = "GET_AUDIT_MERCHANT_TABLES";
export const GET_AUDIT_MERCHANT_TABLES_SUCCEED =
  "GET_AUDIT_MERCHANT_TABLES_SUCCEED";
export const GET_AUDIT_MERCHANT_TABLES_FAILED =
  "GET_AUDIT_MERCHANT_TABLES_FAILED";
export const GET_AUDIT_GROUP_TABLES = "GET_AUDIT_GROUP_TABLES";
export const GET_AUDIT_GROUP_TABLES_SUCCEED = "GET_AUDIT_GROUP_TABLES_SUCCEED";
export const GET_AUDIT_GROUP_TABLES_FAILED = "GET_AUDIT_GROUP_TABLES_FAILED";
export const GET_AUDIT_PARTNER_TABLES = "GET_AUDIT_PARTNER_TABLES";
export const GET_AUDIT_PARTNER_TABLES_SUCCEED =
  "GET_AUDIT_PARTNER_TABLES_SUCCEED";
export const GET_AUDIT_PARTNER_TABLES_FAILED =
  "GET_AUDIT_PARTNER_TABLES_FAILED";
export const GET_AUDIT_ADMIN_TABLES = "GET_AUDIT_ADMIN_TABLES";
export const GET_AUDIT_ADMIN_TABLES_SUCCEED = "GET_AUDIT_ADMIN_TABLES_SUCCEED";
export const GET_AUDIT_ADMIN_TABLES_FAILED = "GET_AUDIT_ADMIN_TABLES_FAILED";
export const GET_AUDIT_LOGIN_TABLES = "GET_AUDIT_LOGIN_TABLES";
export const GET_AUDIT_LOGIN_TABLES_SUCCEED = "GET_AUDIT_LOGIN_TABLES_SUCCEED";
export const GET_AUDIT_LOGIN_TABLES_FAILED = "GET_AUDIT_LOGIN_TABLES_FAILED";
export const GET_AUDIT_TERMINAL_TABLES = "GET_AUDIT_TERMINAL_TABLES";
export const GET_AUDIT_TERMINAL_TABLES_SUCCEED =
  "GET_AUDIT_TERMINAL_TABLES_SUCCEED";
export const GET_AUDIT_TERMINAL_TABLES_FAILED =
  "GET_AUDIT_TERMINAL_TABLES_FAILED";
export const GET_AUDIT_TRANSACTION_TABLES = "GET_AUDIT_TRANSACTION_TABLES";
export const GET_AUDIT_TRANSACTION_TABLES_SUCCEED =
  "GET_AUDIT_TRANSACTION_TABLES_SUCCEED";
export const GET_AUDIT_TRANSACTION_TABLES_FAILED =
  "GET_AUDIT_TRANSACTION_TABLES_FAILED";
export const GET_AUDIT_SHOP_TABLES = "GET_AUDIT_SHOP_TABLES";
export const GET_AUDIT_SHOP_TABLES_SUCCEED = "GET_AUDIT_SHOP_TABLES_SUCCEED";
export const GET_AUDIT_SHOP_TABLES_FAILED = "GET_AUDIT_SHOP_TABLES_FAILED";
export const GET_AUDIT_CURRENCY_TABLES = "GET_AUDIT_CURRENCY_TABLES";
export const GET_AUDIT_CURRENCY_TABLES_SUCCEED =
  "GET_AUDIT_CURRENCY_TABLES_SUCCEED";
export const GET_AUDIT_CURRENCY_TABLES_FAILED =
  "GET_AUDIT_CURRENCY_TABLES_FAILED";
export const GET_AUDIT_GATEWAY_TABLES = "GET_AUDIT_GATEWAY_TABLES";
export const GET_AUDIT_GATEWAY_TABLES_SUCCEED =
  "GET_AUDIT_GATEWAY_TABLES_SUCCEED";
export const GET_AUDIT_GATEWAY_TABLES_FAILED =
  "GET_AUDIT_GATEWAY_TABLES_FAILED";
export const GET_AUDIT_ACCOUNT_TABLES = "GET_AUDIT_ACCOUNT_TABLES";
export const GET_AUDIT_ACCOUNT_TABLES_SUCCEED =
  "GET_AUDIT_ACCOUNT_TABLES_SUCCEED";
export const GET_AUDIT_ACCOUNT_TABLES_FAILED =
  "GET_AUDIT_ACCOUNT_TABLES_FAILED";
export const GET_AUDIT_TRANSACTION_PROCESSING_TABLES =
  "GET_AUDIT_TRANSACTION_PROCESSING_TABLES";
export const GET_AUDIT_TRANSACTION_PROCESSING_TABLES_SUCCEED =
  "GET_AUDIT_TRANSACTION_PROCESSING_TABLES_SUCCEED";
export const GET_AUDIT_TRANSACTION_PROCESSING_TABLES_FAILED =
  "GET_AUDIT_TRANSACTION_PROCESSING_TABLES_FAILED";
export const GET_AUDIT_TRANSACTION_DATA_TABLES =
  "GET_AUDIT_TRANSACTION_DATA_TABLES";
export const GET_AUDIT_TRANSACTION_DATA_TABLES_SUCCEED =
  "GET_AUDIT_TRANSACTION_DATA_TABLES_SUCCEED";
export const GET_AUDIT_TRANSACTION_DATA_TABLES_FAILED =
  "GET_AUDIT_TRANSACTION_DATA_TABLES_FAILED";
export const GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES =
  "GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES";
export const GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_SUCCEED =
  "GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_SUCCEED";
export const GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_FAILED =
  "GET_AUDIT_TRANSACTION_OVERVIEWS_TABLES_FAILED";
export const GET_AUDIT_SERVICE_TABLES = "GET_AUDIT_SERVICE_TABLES";
export const GET_AUDIT_SERVICE_TABLES_SUCCEED =
  "GET_AUDIT_SERVICE_TABLES_SUCCEED";
export const GET_AUDIT_SERVICE_TABLES_FAILED =
  "GET_AUDIT_SERVICE_TABLES_FAILED";

export const LOAD_TABLE = "LOAD_TABLE";
export const RESET_PAGE = "RESET_PAGE";
export const SET_NEW_TABLE = "SET_NEW_TABLE";

export const GET_ADDITIONAL_FEES = "GET_ADDITIONAL_FEES";
export const GET_ADDITIONAL_FEES_SUCCEED = "GET_ADDITIONAL_FEES_SUCCEED";
export const GET_ADDITIONAL_FEES_FAILED = "GET_ADDITIONAL_FEES_FAILED";
export const EDIT_ADDITIONAL_FEE = "EDIT_ADDITIONAL_FEE";

export const GET_HISTOGRAM_REPORT = "GET_HISTOGRAM_REPORT";
export const GET_GEOGRAPHIC_REPORT = "GET_GEOGRAPHIC_REPORT";

export const GET_AUDIT_MERCHANT = "GET_AUDIT_MERCHANT";
export const GET_AUDIT_MERCHANT_SUCCEED = "GET_AUDIT_MERCHANT_SUCCEED";
export const GET_AUDIT_MERCHANT_FAILED = "GET_AUDIT_MERCHANT_FAILED";

export const GET_AUDIT_GROUPS = "GET_AUDIT_GROUPS";
export const GET_AUDIT_GROUPS_SUCCEED = "GET_AUDIT_GROUPS_SUCCEED";
export const GET_AUDIT_GROUPS_FAILED = "GET_AUDIT_GROUPS_FAILED";

export const GET_AUDIT_PARTNERS = "GET_AUDIT_PARTNERS";
export const GET_AUDIT_PARTNERS_SUCCEED = "GET_AUDIT_PARTNERS_SUCCEED";
export const GET_AUDIT_PARTNERS_FAILED = "GET_AUDIT_PARTNERS_FAILED";

export const GET_AUDIT_LOGINS = "GET_AUDIT_LOGINS";
export const GET_AUDIT_LOGINS_SUCCEED = "GET_AUDIT_LOGINS_SUCCEED";
export const GET_AUDIT_LOGINS_FAILED = "GET_AUDIT_LOGINS_FAILED";
