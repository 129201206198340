import Spinner from "components/UI/Spinner/index";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Content from "views/Content";
import {
  getAuditLoginsAction,
  getAuditAdminTablesAction,
} from "../../../../actions/audit";
import ability from "../../../../config/ability";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableAudit } from "../common/Table";

const LoginAuditDetail = () => {
  const [ page, setPage ] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();
  const history = useParams();
  const { count, data, loading } = useSelector(({ audit }) => {
    return {
      count: audit.adminsTablesCount,
      data: audit.adminsTables,
      loading: audit.loading,
    };
  });
  const { logins } = useSelector(({ audit }) => ({
    logins: audit.logins,
  }));
  const [ loginData, setLoginData ] = useState({});

  useEffect(() => {
    if (logins && logins[0]) {
      setLoginData(logins[0]);
    }
  }, [ logins ]);

  const rows = [
    {
      label: " first_name",
      path: "first_name",
    },
    {
      label: " last_name",
      path: "last_name",
    },
    {
      label: " auth_type",
      path: "auth_type",
    },
    {
      label: " email",
      path: "email",
    },
    {
      label: " phone",
      path: "phone",
    },
    {
      label: "company_address",
      path: "company_address",
    },
    {
      label: "company_name ",
      path: "company_name",
    },
    {
      label: " enabled",
      path: "enabled",
    },
    {
      label: " language",
      path: "language",
    },
    {
      label: " locked",
      path: "locked",
    },
  ];

  const loadData = async (page) => {
    dispatch(
      getAuditAdminTablesAction({
        guid: history.id,
        page,
        items: pageSize,
      })
    );
  };

  useEffect(() => {
    dispatch(getAuditLoginsAction({ guid: history.id }));
    loadData(page, pageSize);
  }, []);

  const handleChangePage = async (page) => {
    setPage(page);
    loadData(page);
  };

  return (
    <Content style={{ overflow: "auto" }}>
      <Row style={{ marginLeft: "0px" }}>
        <Card>
          <Card.Header as="h5">{loginData.username}</Card.Header>
          <Card.Body>
            <Card.Title>Info</Card.Title>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Login GUID:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{history.id}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Username:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>first name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.first_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>last name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.last_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>company address:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.company_address}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>company name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.company_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>email:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.email}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>phone:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.phone}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>enabled:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.enabled}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>enabled api:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.enabled_api}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>locked:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.locked}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>login status:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.login_status}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Last change:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.last_change}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Change count:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.change_count}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Author:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{loginData.author_name}</span>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>

      {ability.can("AUDIT", "GROUPS") && (
        <Card>
          <Card.Body>
            <Card.Title>History</Card.Title>
            {loading ? (
              <Spinner />
            ) : (
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <TableAudit
                    data={data}
                    count={count}
                    handleChangePage={handleChangePage}
                    page={page}
                    pageSize={pageSize}
                    rows={rows}
                  />
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      )}
    </Content>
  );
};

export default LoginAuditDetail;
