import React from "react";
import { ClassicSpinner } from "react-spinners-kit";
import PropTypes from "prop-types";

const Spinner = (props) => {
  return (
    <div style={props.style} className="loading">
      <ClassicSpinner color="#104A9D" />
    </div>
  );
};

export default Spinner;

Spinner.propTypes = {
  style: PropTypes.object,
};
