import * as types from "../constants/actionTypes";
import {
  getMerchants,
  getMerchant,
  getMerchantLogins,
  deleteMerchant,
  updateMerchant,
  createMerchant,
  addMerchantLogin
} from "../services/paymentBackendAPI/management/merchants";
import {
  getTopMerchants,
  getAmountMerchants
} from "../services/paymentBackendAPI/reports/entities";
import { loadTable } from "./store";

export const getAllMerchants = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANTS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getMerchants(args);
      return dispatch({
        type: types.GET_MERCHANTS_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_MERCHANTS_FAILED
      });
    }
  };
};

export const getMerchantAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANT
    });
    try {
      const response = await getMerchant(id);
      return dispatch({
        type: types.GET_MERCHANT_SUCCEED,
        merchant: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_MERCHANT_FAILED
      });
    }
  };
};

export const getMerchantLoginsAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANT_LOGINS
    });
    const response = await getMerchantLogins(id, page, items);
    dispatch({
      type: types.GET_MERCHANT_LOGINS_SUCCEED,
      data: response.data
    });
  };
};

export const deleteMerchantAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteMerchant(data);
    return dispatch(getAllMerchants({ page, items, ...search }));
  };
};

export const addMerchantAction = (data, page, items, search) => {
  return async (dispatch) => {
    const response = await createMerchant(data);
    dispatch({
      type: types.ADD_MERCHANT,
      merchant: response.data
    });
    dispatch(getAllMerchants({ page, items, ...search }));
  };
};

export const addMerchantLoginAction = (id, data) => {
  return async (dispatch) => {
    const response = await addMerchantLogin(id, data);
    dispatch({
      type: types.ADD_MERCHANT_LOGIN,
      merchantLogin: response.data
    });
  };
};

export const editMerchantAction = (data) => {
  return async (dispatch) => {
    const response = await updateMerchant(data);
    dispatch({
      type: types.EDIT_MERCHANT,
      merchant: response.data
    });
  };
};

export const getTopMerchantsAction = (days, count) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TOP_MERCHANTS,
    });
    try {
      const response = await getTopMerchants(days, count);
      return dispatch({
        type: types.GET_TOP_MERCHANTS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TOP_MERCHANTS_FAILED,
      });
      throw (error);
    }
  };
};

export const getAmountMerchantsForGroupAction = () => {
  return async (dispatch) => {
    const response = await getAmountMerchants();
    dispatch({
      type: types.GET_AMOUNT_MERCHANTS_FOR_GROUP,
      count: response.data.count
    });
  };
};


export const getAmountMerchantsForPartner = () => {
  return async (dispatch) => {
    const response = await getAmountMerchants();
    dispatch({
      type: types.GET_AMOUNT_MERCHANTS_FOR_PARTNER,
      count: response.data.count
    });
  };
};