import Joi from "joi-browser";

export const schema = {
  terminals: Joi.array().required().min(1).label("Terminals"),
  merchant_guid: Joi.string().required().label("Merchant"),
  to_date: Joi.string().required().label("To date"),
  from_date: Joi.string().required().label("From date"),
  name: Joi.string().not().empty(),
  bank_wire_fee: Joi.number().required().label("Bank wire fee"),
  statementCurrency: Joi.string()
    .trim()
    .regex(/^[A-Z]{3}$/)
    .required(),
  additional_fees: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().required().not().empty(),
        value: Joi.number().required().not().empty(),
        currency: Joi.string().required().not().empty(),
      })
    )
    .required(),
};

export const schemaMerge = {
  name: Joi.string().not().empty().label("Name"),
  merchant_guid: Joi.string().required().label("Merchant"),
  selectedStatements: Joi.array().required().min(1).label("Statements"),
  bank_wire_fee: Joi.number().required().label("Bank wire fee"),
};