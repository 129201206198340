import * as types from "../constants/actionTypes";
import { checkUserExists } from "../services/paymentBackendAPI/management/users";

export const checkUserExistsAction = (data) => {
  return async (dispatch) => {
    const response = await checkUserExists(data);
    dispatch({
      type: types.CHECK_USER_EXISTS,
      users: response.data
    });
  };
};