import * as types from "../constants/actionTypes";

export const pushItemHistory = (name, location) => {
  return dispatch => {
    dispatch({
      type: types.PUSH_ITEM_HISTORY,
      location,
      name
    });
  };
};

export const popItemHistory = () => {
  return dispatch => {
    dispatch({
      type: types.POP_ITEM_HISTORY
    });
  };
};

export const resetItemHistory = (name, location, parentName = "") => {
  return dispatch => {
    dispatch({
      type: types.RESET_HISTORY,
      location,
      name,
      parentName
    });
  };
};