import React, { Component } from "react";
import { connect } from "react-redux";
import { addRateAction, getAllRateTemplatesAction } from "../../actions/rates";
import { getTransactionTemplatesAction } from "actions/transactions";
import { showModal } from "../../actions/modal";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { Row, Col, Form, Button } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import { getAllGateways } from "actions/gateways";
import Joi from "joi-browser";
import { getAllCurrencies } from "actions/currencies";
import Alert from "../UI/Alert";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

class RateCreator extends Component {
  state = {
    gateway_guid: "",
    name: "",
    gatewayOption: "",
    gatewaysLoading: false,
    hold_percent: "",
    hold_days: "",
    connection_fee: "",
    terminal_registration_fee: "",

    activation_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    currencyOptions: "",
    templateOptions: "",
    currencies: [],
    templates: [],

    errors: {},
  };

  schema = {
    name: Joi.string().required().label("Name"),
    gateway_guid: Joi.string().required().label("Gateway"),
    activation_date: Joi.string().required().label("activation date"),
    template: Joi.string().required().label("template"),
    currency: Joi.string().required().label("currency"),
    hold_percent: Joi.number().required().label("Hold percent"),
    hold_days: Joi.number().required().label("Hold days"),
    connection_fee: Joi.number().required().label("Connection fee"),
    terminal_registration_fee: Joi.number()
      .required()
      .label("Terminal registration fee"),
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.props.getAllGateways();

    this.setState({ loading: true });
    await this.props.getAllCurrencies();
    let currencies = this.props.currencies;
    currencies = currencies.map((currency) => ({
      guid: currency.guid,
      name: currency.code,
    }));
    await this.props.getAllRateTemplatesAction();
    let templates = this.props.templates;
    templates = templates.map((template) => ({
      guid: template.guid,
      name: template.name,
    }));
    this.setState({ currencies, templates, loading: false });

    this.setState({
      loading: false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        name: this.state.name,
        gateway_guid: this.state.gateway_guid,
        activation_date: this.state.activation_date,
        template: this.state.templateOptions.value,
        currency: this.state.currencyOptions.value,
        hold_percent: this.state.hold_percent,
        hold_days: this.state.hold_days,
        connection_fee: this.state.connection_fee,
        terminal_registration_fee: this.state.terminal_registration_fee,
      },
      this.schema,
      options
    );

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChangeName = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({ [input.name]: input.value, errors });
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  onGatewaySelect = async (option) => {
    const errors = { ...this.state.errors };
    delete errors.gateway_guid;
    await this.setState({
      gateway_guid: option.guid,
      gateway_guidValidation: true,
      gatewayOption: option,
      currencyOption: {},
      errors,
    });
  };

  onSelect = (options, name) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: name,
      value: options.value,
    });
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];

    this.setState({
      [name + "Options"]: options,
      errors,
    });
  };

  handleTimeChange = (date) => {
    let value = moment(date).format("YYYY-MM-DDTHH:mm:ss");

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: "activation_date",
      value,
    });
    if (errorMessage) errors.activation_date = errorMessage;
    else delete errors.activation_date;

    this.setState({
      activation_date: value,
      errors,
      activation_dateValidation: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let errors = this.validate();
    this.setState({ errors: { ...this.state.errors, ...errors } || {} });
    errors = { ...this.state.errors, ...errors };
    if (Object.keys(errors).length !== 0) return;
    else {
      this.setState({ isLoading: true });
      const data = {
        gateway_guid: this.state.gateway_guid,
        name: this.state.name,
        activation_date: this.state.activation_date,
        template_guid: this.state.templateOptions.value,
        currency_guid: this.state.currencyOptions.value,
        hold_percent: this.state.hold_percent,
        hold_days: this.state.hold_days,
        connection_fee: this.state.connection_fee,
        terminal_registration_fee: this.state.terminal_registration_fee,
      };
      try {
        await this.props.addRateAction(
          data,
          this.props.currentPage,
          this.props.pageSize,
          this.props.ratesSearch
        );
        swal({
          title: "Rate is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const gateways = this.props.gateways ? this.props.gateways : [];
    const { errors } = this.state;
    return (
      <Form autoComplete="off" onSubmit={this.handleSubmit}>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Name*:</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("name")}>
              <Form.Control
                name="name"
                type="text"
                value={this.state.name}
                onChange={(e) => this.handleChangeName(e)}
              />
            </Form.Group>
            {this.state.errors.name && (
              <span className="validate-error">{this.state.errors.name}</span>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Gateway*:</Form.Label>
          </Col>
          <Col md={8}>
            {this.state.loading ? (
              <div>
                <ReactLoading type="cylon" color="grey" width="50px" />
              </div>
            ) : (
              <Form.Group>
                <MultiSelect
                  name="gateways"
                  options={gateways}
                  value={this.state.gatewayOption}
                  multi={false}
                  onSelect={this.onGatewaySelect}
                />
              </Form.Group>
            )}
            {this.state.errors.gateway_guid && (
              <span className="validate-error">
                {this.state.errors.gateway_guid}
              </span>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Currency*</Form.Label>
          </Col>
          <Col md={8}>
            {this.state.loading ? (
              <div>
                <ReactLoading type="cylon" color="grey" width="50px" />
              </div>
            ) : (
              <Form.Group validationState={this.formValidation("currency")}>
                <MultiSelect
                  name="currency"
                  options={this.state.currencies}
                  // value={this.state.currency_guid}
                  onSelect={(e) => this.onSelect(e, "currency")}
                />
                {errors.currency && (
                  <span className="validate-error">{errors.currency}</span>
                )}
              </Form.Group>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Template*</Form.Label>
          </Col>
          <Col md={8}>
            {this.state.loading ? (
              <div>
                <ReactLoading type="cylon" color="grey" width="50px" />
              </div>
            ) : (
              <Form.Group validationState={this.formValidation("template")}>
                <MultiSelect
                  name="template"
                  options={this.state.templates}
                  onSelect={(e) => this.onSelect(e, "template")}
                />
                {errors.template && (
                  <span className="validate-error">{errors.template}</span>
                )}
              </Form.Group>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Activation date*</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group
              validationState={this.formValidation("activation_date")}
            >
              {/* <Form.Control
                    placeholder="Enter activation date"
                    type="date"
                    name="activation_date"
                    value={this.state.activation_date}
                    onChange={e => this.handleChangeName(e)}
                  /> */}

              <DateRangePicker
                onCallback={this.handleTimeChange}
                initialSettings={{
                  singleDatePicker: true,
                  locale: {
                    format: "DD.MM.YYYY",
                  },
                  startDate: this.state.activation_date
                    ? moment(this.state.activation_date).format("DD.MM.YYYY")
                    : undefined,
                }}
              >
                <input type="text" className="text-input form-control" />
              </DateRangePicker>
              {errors.activation_date && (
                <span className="validate-error">{errors.activation_date}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Hold percent*:</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("hold_percent")}>
              <Form.Control
                name="hold_percent"
                type="number"
                value={this.state.hold_percent}
                onChange={(e) => this.handleChangeName(e)}
              />
            </Form.Group>
            {this.state.errors.hold_percent && (
              <span className="validate-error">
                {this.state.errors.hold_percent}
              </span>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Hold days*:</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("hold_days")}>
              <Form.Control
                name="hold_days"
                type="number"
                value={this.state.hold_days}
                onChange={(e) => this.handleChangeName(e)}
              />
            </Form.Group>
            {this.state.errors.hold_days && (
              <span className="validate-error">
                {this.state.errors.hold_days}
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Connection fee*:</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("connection_fee")}>
              <Form.Control
                name="connection_fee"
                type="number"
                value={this.state.connection_fee}
                onChange={(e) => this.handleChangeName(e)}
              />
            </Form.Group>
            {this.state.errors.connection_fee && (
              <span className="validate-error">
                {this.state.errors.connection_fee}
              </span>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Terminal registration fee*:</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group
              validationState={this.formValidation("terminal_registration_fee")}
            >
              <Form.Control
                name="terminal_registration_fee"
                type="number"
                value={this.state.terminal_registration_fee}
                onChange={(e) => this.handleChangeName(e)}
              />
            </Form.Group>
            {this.state.errors.terminal_registration_fee && (
              <span className="validate-error">
                {this.state.errors.terminal_registration_fee}
              </span>
            )}
          </Col>
        </Row>

        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button className="btn btn-fill" type="submit">
              Add
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currencies.currenciesList,
    templates: state.rates.rateTemplatesList,
    currentPage: state.rates.rateCurrentPage,
    pageSize: state.rates.ratePageSize,
    rates: state.rates.ratesList,
    ratesSearch: state.search.ratesSearch,
    rate_guid: state.rates.rate_guid,
    gateways: state.gateways.gatewaysList,
    gatewaysLoading: state.gateways.gatewaysLoading,
  };
};

export default connect(mapStateToProps, {
  addRateAction,
  getAllCurrencies,
  getAllRateTemplatesAction,
  getAllGateways,
  showModal,
  getTransactionTemplatesAction,
})(RateCreator);

RateCreator.propTypes = {
  addRateAction: PropTypes.func,
  currencies: PropTypes.array,
  currentPage: PropTypes.number,
  gateways: PropTypes.array,
  getAllCurrencies: PropTypes.func,

  getAllGateways: PropTypes.func,
  getAllRateTemplatesAction: PropTypes.func,
  pageSize: PropTypes.number,
  ratesSearch: PropTypes.array,
  templates: PropTypes.array,
};
