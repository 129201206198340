import React from "react";
import Content from "../../Content";
import CurrencyAuditMain from "components/Audit/CurrencyTable/Main";

const CurrencyAudit = () => {
  return (
    <Content>
      <CurrencyAuditMain />
    </Content>
  );
};

export default CurrencyAudit;
