import * as types from "../constants/actionTypes";
import {
  getUserAccount,
  updateUserAccount
} from "../services/paymentBackendAPI/management/useraccounts";


export const getUserAccountAction = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_ACCOUNT
    });
    try {
      const response = await getUserAccount();
      return dispatch({
        type: types.GET_USER_ACCOUNT_SUCCEED,
        useraccount: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_USER_ACCOUNT_FAILED
      });
    }
  };
};

export const editUserAccountAction = (data) => {
  return async (dispatch) => {
    const response = await updateUserAccount(data);
    localStorage.setItem("expiredPassword", false);
    dispatch({
      type: types.EDIT_USER_ACCOUNT,
      useraccount: response.data
    });
  };
};

