import * as types from "../constants/actionTypes";
import {
  getAccounts,
  getAccountsForMerchant,
  createAccountForMerchant,
  createAccount,
  deleteAccount,
  deleteAccountForMerchant
} from "../services/paymentBackendAPI/management/accounts";

export const getAllAccounts = (guid, args) => {
  return async dispatch => {
    dispatch({
      type: types.GET_ACCOUNTS
    });
    try {
      const res = await getAccounts(guid, args);
      return dispatch({
        type: types.GET_ACCOUNTS_SUCCEED,
        data: res.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_ACCOUNTS_FAILED
      });
    }
  };
};

export const getAllAccountsForMerchant = (args) => {
  return async dispatch => {
    dispatch({
      type: types.GET_ACCOUNTS
    });
    try {
      const res = await getAccountsForMerchant(args);
      return dispatch({
        type: types.GET_ACCOUNTS_SUCCEED,
        data: res.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_ACCOUNTS_FAILED
      });
    }
  };
};

export const addAccountForMerchantAction = (data, search) => {
  return async dispatch => {
    await createAccountForMerchant(data);
    return dispatch(getAllAccountsForMerchant({ ...search }));
  };
};

export const addAccountAction = (guid, data, search) => {
  return async dispatch => {
    await createAccount(guid, data);
    return dispatch(getAllAccounts(guid, { ...search }));
  };
};


export const deleteAccountAction = (guid, data, reason, search) => {
  return async dispatch => {
    await deleteAccount(guid, data, reason);
    return dispatch(getAllAccounts(guid, { ...search }));
  };
};


export const deleteAccountForMerchantAction = (data, search) => {
  return async dispatch => {
    await deleteAccountForMerchant(data);
    return dispatch(getAllAccountsForMerchant({ ...search }));
  };
};