import { backendManagement } from "../backendPlatform";

export const getGroups = (args) => {
  return backendManagement.get("users/groups", { params: { ...args } });
};

export const createGroup = data => {
  return backendManagement.post("users/groups", data);
};

export const getGroup = guid => {
  return backendManagement.get(`users/groups/${guid}`);
};
// with guid
export const updateGroup = data => {
  return backendManagement.post("users/groups", data);
};

export const deleteGroup = ({ guid, reason }) => {
  return backendManagement.post("users/groups", { group_guid : guid, delete: true, reason });
};

export const addGroupToPartner = (group_guid, partner_guid, reason) => {
  return backendManagement.post("users/groups", { group_guid, partner_guid, reason });
};

export const deleteGroupFromPartner = ({ guid, reason }) => {
  return backendManagement.post("users/groups", { group_guid: guid, partner_guid: "", reason });
};

export const getGroupLogins = (guid, page, items ) => {
  return backendManagement.get(`/groups/${guid}/logins`, { params: { items, page } });
};

export const getGroupMerchants = (guid, page, items) => {
  return backendManagement.get(`/groups/${guid}/merchants`, { params: { items, page } });
};

export const addGroupLogin = (guid, data) => {
  return backendManagement.post(`/groups/${guid}/logins`, data);
};
