import { backendManagement } from "../backendPlatform";

export const getAdmins = (args) => {
  return backendManagement.get("users/admins", { params: { ...args } });
};

export const createAdmin = data => {
  return backendManagement.post("users/admins", data);
};

export const getAdmin = (guid) => {
  return backendManagement.get(`users/admins/${guid}`);
};

export const deleteAdmin = ({ guid, reason }) => {
  return backendManagement.post("users/admins", { guid, delete: true, reason });
};

export const getAdminRoles = (guid) => {
  return backendManagement.get(`/users/admins/${guid}/roles`);
};

export const addAdminRole = (guid, data) => {
  return backendManagement.post(`/users/admins/${guid}/roles`, data);
};

export const deleteAdminRole = (guid, roleGuid, reason) => {
  return backendManagement.post(`/users/admins/${guid}/roles`, { guid: roleGuid, delete: true, reason });
};