import { getMismatchTransactionAction } from "actions/transactions";
import Spinner from "components/UI/Spinner";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import Content from "views/Content";

class MismatchDetail extends Component {
  state = {
    isAdmin: localStorage.getItem("isAdmin"),
  };

  async componentDidMount() {
    await this.props.getMismatchTransactionAction(this.props.match.params.id);
  }

  parameters = [
    { path: "card_number", label: "card_number" },
    { path: "merchant_name", label: "merchant_name" },
    { path: "merchant_id", label: "merchant_id" },
    { path: "card_schema", label: "card_schema" },
    { path: "amount", label: "amount" },
    { path: "currency_code", label: "currency_code" },
    { path: "tr_type", label: "tr_type" },
    { path: "proc_code", label: "proc_code" },
    { path: "issuer_country", label: "issuer_country" },
    { path: "proc_region", label: "proc_region" },
    { path: "merchant_country", label: "merchant_country" },
    { path: "tran_region", label: "tran_region" },
    { path: "card_product_type", label: "card_product_class" },
  ];

  render() {
    const mismatchTransaction = this.props.mismatchTransaction;

    if (this.props.mismatchTransactionLoading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content>
          <Row>
            <Col>
              <Card>
                <Card.Header as="h5"> Guid: {this.props.match.params.id}</Card.Header>
                <Card.Body>
                  <Card.Title>Payment info</Card.Title>

                  <Table responsive className="detailInfo">
                    <tbody>
                      <tr>
                        <th>transaction_processing_guid:</th>
                        <td>
                          {mismatchTransaction.transaction_processing_guid}
                        </td>
                      </tr>
                      <tr>
                        <th>payment_id:</th>
                        <td>{mismatchTransaction.payment_id}</td>
                      </tr>
                      <tr>
                        <th>created_at:</th>
                        <td>
                          {moment(mismatchTransaction.created_at)
                            .utcOffset(0)
                            .format("D MMM YYYY")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Info</Card.Title>


                  <Table responsive className="detailInfo">
                    <tbody>
                      <tr>
                        <th>parameters</th>
                        <th>Our </th>
                        <th>Decta </th>
                      </tr>
                      {this.parameters.map((item) => (
                        <tr key={item.path}>
                          <td>{item.label}</td>
                          <td>{mismatchTransaction[item.path]}</td>
                          <td>{mismatchTransaction.decta[item.path]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    mismatchTransaction: state.transactions.mismatchTransaction,
    mismatchTransactionLoading: state.transactions.mismatchTransactionLoading,
  };
};

export default connect(mapStateToProps, { getMismatchTransactionAction })(
  MismatchDetail
);

MismatchDetail.propTypes = {
  getMismatchTransactionAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  mismatchTransaction: PropTypes.object.isRequired,
  mismatchTransactionLoading: PropTypes.bool,
};
