import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

class StatusSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      value: "",
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.reset) {
      return {
        id: "",
      };
    }
  }

  parseValue = (key, value) => {
    if (value === "Success") {
      if (key === "enabled") return 1;
      return value;
    } else {
      if (key === "enabled") return 0;
      return value;
    }
  };

  handleClick = async (e) => {
    if (
      this.props.searchData.status === e.target.id ||
      (this.props.searchData.enabled === 1 && e.target.id === "Success") ||
      (this.props.searchData.enabled === 0 && e.target.id === "Failed")
    ) {
      await this.props.search({
        [this.props.value]: undefined,
      });
      await this.props.get({
        page: 1,
        items: 100,
      });
      this.props.updateCurrentPage();
      return 0;
    }
    if (this.state.id !== e.target.id) {
      this.setState({
        id: e.target.id,
      });
      await this.props.search({
        [this.props.value]: this.parseValue(this.props.value, e.target.id),
      });
      await this.props.get({
        page: 1,
        items: 100,
        ...this.props.searchData,
      });
      this.props.updateCurrentPage();
    } else {
      this.setState({
        id: "",
      });
      this.props.search({
        [this.props.value]: "",
      });
    }
  };

  render() {
    return (
      <div
        className={
          this.props.isSearch
            ? "searchOpen search align-center"
            : "searchClosed search align-center"
        }
      >
        <i
          id="Success"
          className="icon-success status-icon-success"
          style={
            this.props.searchData.status === "Success" ||
            this.props.searchData.enabled === 1
              ? {
                  color: "#5BB795",
                  WebkitTransform: "scale(1.5)",
                  msTransform: "scale(1.5)",
                  transform: "scale(1.5)",
                }
              : null
          }
          onClick={this.handleClick}
        />
        <i
          id="Failed"
          className="icon-failed status-icon-failed"
          style={
            this.props.searchData.status === "Failed" ||
            this.props.searchData.enabled === 0
              ? {
                  color: "#EC5E6F",
                  WebkitTransform: "scale(1.5)",
                  msTransform: "scale(1.5)",
                  transform: "scale(1.5)",
                }
              : null
          }
          onClick={this.handleClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.search.reset,
    isSearch: state.search.isSearch,
  };
};

export default connect(mapStateToProps, {})(StatusSelector);

StatusSelector.propTypes = {
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  reset: PropTypes.bool,
  search: PropTypes.func,
  searchData: PropTypes.object,
  updateCurrentPage: PropTypes.func,
  value: PropTypes.string,
};
