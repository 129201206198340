import React, { Component } from "react";
import {
  getTransactionTemplateAction,
  getTransactionStepsAction,
  getStepAction,
} from "actions/transactions";
import { connect } from "react-redux";
import { Row, Col, Tabs, Tab, Card } from "react-bootstrap";
import { parseResponse } from "helpers/parseResponse";
import { getAllGateways } from "actions/gateways";
import TabsComponent from "./TabsComponent";
import PropTypes from "prop-types";
import Content from "views/Content";
import Alert from "../../UI/Alert";

class TransactionTemplateDetail extends Component {
  componentDidMount = async () => {
    try {
      await this.props.getTransactionTemplateAction(this.props.match.params.id);
      await this.props.getAllGateways();
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    const { template } = this.props;
    return (
      <Content style={{ overflow: "auto" }}>
        <Card>
          <Card.Header as="h5">{template.name}</Card.Header>
          <Card.Body>
            <Card.Title>General Info</Card.Title>
            <Row>
              <Col md={12}>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>GUID: </label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{this.props.match.params.id}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Name:</label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{template.name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Type:</label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{template.type}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="right" md={5} lg={5} xs={5}>
                    <label>Special rate:</label>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <span>{String(template.spec_rate)}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header as="h5">{template.name}</Card.Header>
          <Card.Body>
            <Card.Title>Steps</Card.Title>

            <Tabs
              defaultActiveKey={0}
              transition={false}
              style={{ marginBottom: "0" }}
            >
              <br />
              {this.props.gateways.map((gateway, index) => (
                <Tab
                  eventKey={index}
                  key={gateway.name}
                  title={gateway.name}
                  mountOnEnter={true}
                  style={{ position: "inline-block" }}
                >
                  <TabsComponent
                    template_guid={this.props.match.params.id}
                    gateway_guid={gateway.guid}
                  />
                </Tab>
              ))}
            </Tabs>
          </Card.Body>
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    template: state.transactions.transactionTemplate,
    stepsCount: state.transactions.stepsCount,
    gateways: state.gateways.gatewaysList,
  };
};

export default connect(mapStateToProps, {
  getTransactionTemplateAction,
  getTransactionStepsAction,
  getStepAction,
  getAllGateways,
})(TransactionTemplateDetail);

TransactionTemplateDetail.propTypes = {
  gateways: PropTypes.array,
  getAllGateways: PropTypes.func,
  getTransactionTemplateAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  template: PropTypes.object,
};
