import React, { Component } from "react";
import { connect } from "react-redux";
import LineChartTransactionsAmount from "components/LineChart/TransactionsAmount";
import LineChartTransactionsCurrencies from "components/LineChart/TransactionsCurrencies";
import HorizontalBarChartTopMerchants from "components/HorizontalBarChart/TopMerchants";
import HorizontalBarChartTopShops from "components/HorizontalBarChart/TopShops";
import TotalProcessed from "components/TotalProcessed";
import { Row, Col, Tab, Tabs, Card } from "react-bootstrap";
import Content from "../Content";
import { getAmountShopsForGroup } from "../../actions/shops";
import { getTransactionHistoryAction } from "../../actions/transactions";
import { getAmountMerchantsForGroupAction } from "../../actions/merchants";
import Spinner from "components/UI/Spinner";
import PropTypes from "prop-types";

class GroupDashboard extends Component {
  componentDidMount = async () => {
    await this.props.getAmountShopsForGroup();
    await this.props.getAmountMerchantsForGroupAction();
    await this.props.getTransactionHistoryAction({ days: 7 });
  };

  render() {
    return (
      <Content>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Card>
              <Card.Header as="h5">Holds</Card.Header>
              <Card.Body>
                <LineChartTransactionsAmount />
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Card>
              <Card.Header as="h5">Amount of merchants</Card.Header>
              <Card.Body>{this.props.amountMerchants}</Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <Card>
              <Card.Header as="h5">Amount of shops</Card.Header>
              <Card.Body>{this.props.shopsAmount}</Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={12}>
            <Card>
              <Card.Header as="h5">Amount of shops</Card.Header>
              <Card.Body>
                <TotalProcessed className={"card-large-content"} />
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} sm={12}>
            {this.props.transactionsAmountLoading ? (
              <Spinner />
            ) : (
              <Card>
                <Card.Header as="h5">Total transactions</Card.Header>
                <Card.Body>{this.props.transactionsAmount}</Card.Body>
              </Card>
            )}
          </Col>

          <Col lg={6} md={12} sm={12}>
            <Card>
              <Card.Header as="h5">Transactions currency</Card.Header>
              <Card.Body>
                <LineChartTransactionsCurrencies />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey="topMerchants"
          id="top-for-group-dashboard"
          style={{ marginLeft: "9px" }}
        >
          <Tab
            eventKey="topMerchants"
            title="Top merchants"
            style={{ marginLeft: "-9px" }}
          >
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top merchants</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopMerchants />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="topShops"
            title="Top shops"
            style={{ marginLeft: "-9px" }}
          >
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top shops</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopShops />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shopsAmount: state.shops.count,
    transactionsAmount: state.transactions.transactionHistoryCount,
    transactionsAmountLoading: state.transactions.transactionHistoryLoading,
    amountMerchants: state.merchants.amountMerchants,
  };
};

export default connect(mapStateToProps, {
  getAmountShopsForGroup,
  getTransactionHistoryAction,
  getAmountMerchantsForGroupAction,
})(GroupDashboard);

GroupDashboard.propTypes = {
  amountMerchants: PropTypes.oneOfType([ PropTypes.number, PropTypes.array ]),
  getAmountMerchantsForGroupAction: PropTypes.func,
  getAmountShopsForGroup: PropTypes.func,
  getTransactionHistoryAction: PropTypes.func,
  shopsAmount: PropTypes.number,
  transactionsAmount: PropTypes.number,
  transactionsAmountLoading: PropTypes.bool,
};
