import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAuditTerminalsTablesAction } from "../../../actions/audit";
import { reset, searchInTerminalsAuditTable } from "../../../actions/search";
import { resetPage, setNewTable } from "../../../actions/store";
import AbstractComponent from "../../../factories/Main";
import { cutGuid } from "../../../helpers/cutGuid";
import { getAuditTerminalTables } from "../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../UI/CopyToClipboard";

const columns = [
  {
    path: "guid",
    label: "Guid",
    key: "guid_audit",
    content: (el) => (
      <>
        <Copy text={el.guid} />
        {cutGuid(el.guid)}
      </>
    ),
    style: { minWidth: "120px" },
  },
  { path: "name", label: "Name", key: "name", style: { minWidth: "120px" } },
  {
    path: "currency_code",
    label: "Currency",
    key: "currency_code_audit",
    align: "center",
  },
  { path: "gateway_name", label: "Gateway", key: "gateway_name" },
  {
    key: "enabled",
    content: (terminal) => (
      <i
        className={
          terminal.enabled ? "icon-success green icon" : "icon-failed red icon"
        }
      />
    ),
    label: "Enabled",
    textContent: (terminal) => (terminal.enabled ? "Enabled" : "Disabled"),
    align: "center",
  },
  {
    path: "rate_guid",
    label: "Rate guid",
    key: "rate_guid",
    content: (el) => (
      <>
        {el.rate_guid ? (
          <>

            {cutGuid(el.rate_guid)} <Copy text={el.rate_guid} />
          </>
        ) : null}
      </>
    ),
    style: { minWidth: "120px" },
  },
  {
    key: "three_d",
    content: (terminal) => (
      <i
        className={
          terminal.three_d ? "icon-success green icon" : "icon-failed red icon"
        }
      />
    ),
    label: "3d",
    textContent: (terminal) => (terminal.three_d ? "Enabled" : "Disabled"),
    align: "center",
  },
  {
    path: "monthly_amount_limit",
    label: "Monthly amount limit",
    key: "monthly_amount_limit",
    align: "right",
  },
  {
    path: "payment_amount_limit",
    label: "Payment amount limit",
    key: "payment_amount_limit",
    align: "right",
  },
  {
    path: "transaction_count_limit",
    label: "Transaction count limit",
    key: "transaction_count_limit",
    align: "right",
  },
  {
    key: "antifraud_monitor",
    content: (terminal) => (
      <i
        className={
          terminal.antifraud_monitor
            ? "icon-success green icon"
            : "icon-failed red icon"
        }
      />
    ),
    label: "Antifraud monitor",
    textContent: (terminal) =>
      terminal.antifraud_monitor ? "Enabled" : "Disabled",
    align: "center",
  },
  {
    path: "antifraud_monitor_value",
    label: "Antifraud monitor value",
    key: "antifraud_monitor_value",
    align: "right",
  },
  {
    path: "billing_descriptor",
    label: "Billing descriptor",
    key: "billing_descriptor",
  },
  { path: "routing_string", label: "Routing string", key: "routing_string" },
  {
    key: "generate_statement",
    content: (terminal) => (
      <i
        className={
          terminal.generate_statement
            ? "icon-success green icon"
            : "icon-failed red icon"
        }
      />
    ),
    label: "Generate statement",
    textContent: (terminal) =>
      terminal.generate_statement ? "Enabled" : "Disabled",
    align: "center",
  },
  {
    path: "supported_brands",
    label: "Supported brands",
    key: "supported_brands",
  },
  { path: "checkout_method", label: "Checkout method", key: "checkout_method" },
  {
    key: "enable_checkout",
    content: (terminal) => (
      <i
        className={
          terminal.enable_checkout
            ? "icon-success green icon"
            : "icon-failed red icon"
        }
      />
    ),
    label: "Enable checkout",
    textContent: (terminal) =>
      terminal.enable_checkout ? "Enabled" : "Disabled",
    align: "center",
  },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (transaction) =>
      moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.terminalsTables,
    count: state.audit.terminalsTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.terminalAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_terminal",
    keyPath: "guid",
    columns,
    exportFunction: getAuditTerminalTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditTerminalsTablesAction,
  search: searchInTerminalsAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
