import { getRecurringServiceLogsAction } from "actions/logs";
import { reset, searchInServicesLogs } from "actions/search";
import AbstractComponent from "factories/Main";
import moment from "moment";
import { connect } from "react-redux";
import { resetPage, setNewTable } from "../../../../actions/store";
import { getRecurringServiceLogs } from "../../../../services/paymentBackendAPI/logs/logs";

const columns = [
  {
    path: "time", label: "Time",
    content: log => (
      moment(log.createdAt).utcOffset(0).format("DD.MM.YYYY HH:mm:ss.SS")
    ),
    align: "center"
  },
  {
    path: "terminal_name", label: "Terminal"
  },
  {
    path: "shop_name", label: "Shop"
  },
  {
    path: "merchant_name", label: "Merchant"
  },
  {
    path: "info", label: "Info"
  },
  {
    path: "action", label: "Action", key: "action_log"
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.logs.recurringServiceLogs,
    count: state.logs.recurringServiceLogsCount,
    table: state.store.table,
    loading: state.logs.loading,
    searchData: state.search.serviceLogsSearch,
    isSearch: state.search.isSearch,
    name: "Service logs recurring",
    keyPath: "createdAt",
    columns,
    notAllSizePage: true,
    exportFunction: getRecurringServiceLogs
  };
};

export default connect(mapStateToProps, {
  get: getRecurringServiceLogsAction,
  search: searchInServicesLogs,
  resetPage,
  setNewTable,
  reset
})(AbstractComponent);
