import React from "react";
import { connect } from "react-redux";
import { HorizontalBarChart } from "../UI/HorizontalBarChart";
import { getTopShopsAction } from "../../actions/shops";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";

class HorizontalBarChartTopShops extends React.Component {
  state = {
    daysCount: 7,
    topShopsCount: 10,
    loading: true,
  };

  componentDidMount = () => {
    if (this.props.daily)
      this.props.getTopShopsAction(1, this.state.topShopsCount);
    else
      this.props.getTopShopsAction(
        this.state.daysCount,
        this.state.topShopsCount
      );
  };

  render() {
    if (this.props.loading) return <Spinner />;
    else {
      const labelsPie = this.props.topShops.map((shop) => shop.name);

      const datasetsPie = [
        {
          label: "transactions",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.props.topShops.map((shop) => shop.count),
        },
      ];

      return (
        <div>
          <HorizontalBarChart
            name="Top shops"
            labels={labelsPie}
            datasets={datasetsPie}
            options={{
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
            height={50}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    topShops: state.shops.topShops,
    loading: state.shops.topShopsLoading,
  };
};

export default connect(mapStateToProps, {
  getTopShopsAction,
})(HorizontalBarChartTopShops);

HorizontalBarChartTopShops.propTypes = {
  daily: PropTypes.string,
  getTopShopsAction: PropTypes.func,
  loading: PropTypes.bool,
  topShops: PropTypes.array,
};
