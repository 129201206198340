import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import ReactLoading from "react-loading";

function MerchantSelect({
  merchantsLoading,
  value = {},
  merchants = [],
  callback,
  errors
}) {

  const onSelect = (option) => {
    callback && callback(option);
  };

  return (
    merchantsLoading ?
        <div>
          <ReactLoading type="cylon" color="grey" />
        </div>
      :
        <Row >
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Merchant*</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group>
              <MultiSelect
                name="type"
                options={merchants}
                multi={false}
                onSelect={onSelect}
                placeholder="Select merchant"
                value={value}
              />
              {errors.merchant_guid && (
                <span className="validate-error">{errors.merchant_guid}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
  );
}

export default MerchantSelect;