import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Form, Button, Badge } from "react-bootstrap";
import {
  getPrivilegeAction,
  editPrivilegeAction,
} from "../../actions/privileges";
import { showModal } from "../../actions/modal";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Alert from "../UI/Alert";

class PrivilegeEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        guid: "",
        name: "",
        type: "",
        description: "",
        reason: "",
      },
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    await this.props.getPrivilegeAction(this.props.privilege_guid);
    const { privilege } = this.props;
    this.setState({
      data: privilege,
    });
    let data = { ...this.state.data };
    data.reason = "";
    this.setState({
      data,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (input.name === "name") data[input.name] = input.value.toUpperCase();
    else data[input.name] = input.value;
    this.setState({ data });
  };

  onSelectType = (e) => {
    let { data } = this.state;
    data.type = e.target.value;
    this.setState({ data });
  };

  formValidation = (data) => {
    if (data === "") return "error";
    else return "success";
  };

  generalValidation = () => {
    const { data } = this.state;
    return (
      this.formValidation(data.name) === "success" &&
      this.formValidation(data.description) === "success" &&
      this.formValidation(data.type) === "success" &&
      this.formValidation(data.reason) === "success"
    );
  };

  handleSubmit = async (event) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    const { data } = this.state;
    if (!this.generalValidation()) {
      this.setState({ isLoading: false });
      swal({
        title: "Please, enter information in every required field",
        icon: "warning",
      });
    } else {
      try {
        await this.props.editPrivilegeAction(data);
        swal({
          title: "Privilege is updated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { data } = this.state;
    if (this.props.privilegeLoading) return <Spinner />;
    else {
      return (
        <div>
          <div className="card">
            <div className="content">
              <label>ID: {this.props.privilege_guid}</label>
            </div>
          </div>
          <div className="card">
            <div className="header">General info</div>
            <div className="content">
              <Form onSubmit={this.handleSubmit} autoComplete="off">
                <Row className="rowEditPrivilegeType">
                  <Col className="col">
                    <Form.Label>Type:</Form.Label>
                    <Form.Group className="privilege-form-group">
                      <Badge className="badge-privilege-type">
                        {this.state.data.type ? this.state.data.type : null}
                      </Badge>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="col">
                    <Form.Label>Name:</Form.Label>
                    <Form.Group
                      validationState={this.formValidation(data.name)}
                    >
                      <Form.Control
                        name="name"
                        type="text"
                        value={data.name}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="col">
                    <Form.Label>Description:</Form.Label>
                    <Form.Group
                      validationState={this.formValidation(data.description)}
                    >
                      <Form.Control
                        name="description"
                        type="text"
                        value={data.description}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="col">
                    <Form.Label>Reason:</Form.Label>
                    <Form.Group
                      validationState={this.formValidation(data.reason)}
                    >
                      <Form.Control
                        name="reason"
                        type="text"
                        value={data.reason}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div>
                  {this.state.isLoading ? (
                    <ReactLoading type="cylon" color="grey" />
                  ) : (
                    <Button
                      className="btn btn-fill"
                      type="submit"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privileges.privilege,
    privilegeLoading: state.privileges.privilegeLoading,
  };
};

export default connect(mapStateToProps, {
  getPrivilegeAction,
  editPrivilegeAction,
  showModal,
})(PrivilegeEditor);

PrivilegeEditor.propTypes = {
  editPrivilegeAction: PropTypes.func,
  getPrivilegeAction: PropTypes.func,
  privilege: PropTypes.array,
  privilegeLoading: PropTypes.bool,
  privilege_guid: PropTypes.string,
  showModal: PropTypes.func,
};
