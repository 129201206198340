import React from "react";
import Content from "../Content";
import MismatchTransactionMain from "components/MismatchTransactions/Main";

const MismatchTransaction = () => {
  return (
    <Content>
      <MismatchTransactionMain />
    </Content>
  );
};

export default MismatchTransaction;
