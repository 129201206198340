import React from "react";
import { connect } from "react-redux";
import { HorizontalBarChart } from "../UI/HorizontalBarChart";
import { getTopGroupsAction } from "../../actions/groups";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";

class HorizontalBarChartTopGroups extends React.Component {
  state = {
    daysCount: 7,
    topGroupsCount: 10,
    loading: true,
  };

  componentDidMount = () => {
    if (this.props.daily)
      this.props.getTopGroupsAction(1, this.state.topGroupsCount);
    else
      this.props.getTopGroupsAction(
        this.state.daysCount,
        this.state.topGroupsCount
      );
  };

  render() {
    if (this.props.loading) return <Spinner />;
    else {
      const labelsPie = this.props.topGroups.map((group) => group.name);

      const datasetsPie = [
        {
          label: "transactions",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.props.topGroups.map((group) => group.count),
        },
      ];

      return (
        <div>
          <HorizontalBarChart
            name="Top groups"
            labels={labelsPie}
            datasets={datasetsPie}
            options={{
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
            height={50}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    topGroups: state.groups.topGroups,
    loading: state.groups.topGroupsLoading,
  };
};

export default connect(mapStateToProps, {
  getTopGroupsAction,
})(HorizontalBarChartTopGroups);

HorizontalBarChartTopGroups.propTypes = {
  daily: PropTypes.string,
  getTopGroupsAction: PropTypes.func,
  loading: PropTypes.bool,
  topGroups: PropTypes.array,
};
