import { editGroupAction, getAllGroups } from "actions/groups";
import Button from "components/UI/CustomButton/CustomButton";
import MultiSelect from "components/UI/MultiSelect";
import { parseResponse } from "helpers/parseResponse";
import Joi from "joi-browser";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import swal from "sweetalert";
import {
  addMerchantAction,
  addMerchantLoginAction,
} from "../../actions/merchants";
import { showModal } from "../../actions/modal";
import { getAllRoles } from "../../actions/roles";
import { checkUserExistsAction } from "../../actions/users";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import Alert from "../UI/Alert";
import PhoneInput from "../UI/PhoneInput";
import { getAllCurrencies } from "actions/currencies";
import { numberFormatter } from "../../helpers/numberFormatter";

class MerchantCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        companyAddress: "",
        name: "",
        type: "",
        monthly_fee: "",
        monthly_fee_currency: "",
        monthly_fee_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
        monthly_amount_limit: "",
        custom_amount_limit: "0",
        custom_days_limit: 0,
      },
      language: "en",
      phone: "",
      sendMail: true,
      enabled: true,
      password: "",
      showPassword: false,
      role_guid: "",

      group_guid: "",
      token: {},
      isLoading: false,
      isAdmin: "",
      role: "",
      currencies: [],

      phoneExists: "",
      emailExists: "",
      errorPhoneExists: "",
      errorEmailExists: "",
      errorsExists: {},
      errors: {},

      groups: [],
      custom_amount_limit: "0",

      emailValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      roleValidation: false,
      phoneValidation: false,
      companyNameValidation: false,
      companyAddressValidation: false,
      nameValidation: false,
      typeValidation: false,
      monthly_feeValidation: false,
      monthly_fee_dateValidation: true,
      monthly_amount_limitValidation: false,
      passwordValidation: false,
      custom_amount_limitValidation: true,
      custom_days_limitValidation: true,
    };
  }

  schema = {
    email: Joi.string().required().email().label("Email"),
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    companyName: Joi.string().required().label("Company name"),
    companyAddress: Joi.string().required().label("Company address"),
    name: Joi.string().required().label("Merchant name"),
    type: Joi.string().required().label("Merchant type"),
    monthly_fee: Joi.number().required().label("Monthly fee"),
    monthly_fee_currency: Joi.string().required().label("Monthly fee currency"),
    monthly_fee_date: Joi.string().required().label("Monthly fee date"),
    monthly_amount_limit: Joi.number().required().label("Monthly amount limit"),
    phone: Joi.string().required().min(5).label("Phone"),
    role: Joi.string().required().label("Role"),
    language: Joi.string().required().label("Language"),
    custom_amount_limit: Joi.string()
      .required()
      .max(15)
      .label("Merchant amount limit"),
    custom_days_limit: Joi.number()
      .required()
      .max(1000)
      .label("Merchant period limit (days)"),
  };

  async componentDidMount() {
    const token = getAuthData().userPayload;
    const isAdmin = localStorage.getItem("isAdmin");
    this.setState({ token, isAdmin });
    await this.props.getAllRoles({ type: "merchant" });
    if (token.partner || isAdmin) {
      await this.props.getAllGroups();
      this.props.groups.map((item) => {
        item.name = item.group_name;
        item.guid = item.group_guid;
        return item;
      });
      this.props.groups.unshift({ name: "Without group", guid: "1" });
      this.setState({ groups: this.props.groups });
    }
    await this.props.getAllCurrencies();
    let currencies = this.props.currencies;
    currencies.forEach((currency) => {
      currency.name = currency.code;
    });
    this.setState({
      currencies,
      data: {
        ...this.state.data,
        monthly_fee_currency: currencies.filter((cur) => cur.code === "EUR")[0],
      },
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        ...this.state.data,
        monthly_fee_currency: this.state.data.monthly_fee_currency.code,
        phone: this.state.phone,
        role: this.state.role_guid,
        language: this.state.language,
      },
      this.schema,
      options
    );

    if (!this.state.sendMail) {
      let schema = {
        password: Joi.string()
          .required()
          .regex(
            /(?=^.{12,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
          )
          .error(() => {
            return {
              message:
                "Password must be at least 12 characters, including a number, a special, and  an uppercase letter.",
            };
          })
          .label("Password"),
      };

      let passwordError = Joi.validate(
        { password: this.state.password },
        schema,
        options
      );
      if (
        passwordError &&
        passwordError.error &&
        passwordError.error.details[0]
      ) {
        let arrayErrors = error ? error.details : [];
        arrayErrors.push(passwordError.error.details[0]);
        error = { ...error, details: arrayErrors };
      }
    }

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleChangeNumber = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = numberFormatter(input.value);
    this.setState({ data, errors });
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name: "phone", value: value });
    if (errorMessage) errors.phone = errorMessage;
    else delete errors.phone;

    this.setState({
      phone: value,
      errors,
      phoneValidation: errorMessage ? false : true,
    });

    if (value.length > 4)
      await this.props.checkUserExistsAction({ phone: value });

    this.setState({
      phoneExists: this.props.phoneExists,
    });

    if (this.state.phoneExists)
      this.setState({
        errorPhoneExists: `Phone ${value} exists`,
        phoneValidation: false,
      });
    else
      this.setState({
        errorPhoneExists: "",
        phoneValidation: errorMessage ? false : true,
      });
  };

  onChangeEmail = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });

    const errorsExists = this.validate();
    this.setState({ errorsExists: errorsExists || {} });

    try {
      if (
        input.value.length > 8 &&
        this.state.errorsExists.email === undefined &&
        input.value.indexOf(".") !== -1
      )
        await this.props.checkUserExistsAction({ email: input.value });
    } catch {
      this.setState({
        errorEmailExists: "Email must be a valid",
      });
    }

    this.setState({
      emailExists: this.props.emailExists,
    });

    if (this.state.emailExists)
      this.setState({
        errorEmailExists: "Email exists",
        emailValidation: false,
      });
    else
      this.setState({
        errorEmailExists: "",
        emailValidation: errorMessage ? false : true,
      });
  };

  onChangePassword = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    let errorMessage;

    if (this.state.sendMail)
      errorMessage = "Password is not allowed to be empty";
    if (input.value.length < 8)
      errorMessage = "Minimum password length must be 12 characters!";
    if (!/[a-z]/.test(input.value))
      errorMessage = "Password must contain at least 1 small letter!";
    if (!/[A-Z]/.test(input.value))
      errorMessage = "Password must contain at least 1 uppercase letter!";
    if (!/[0-9]/.test(input.value))
      errorMessage = "Password must contain at least 1 number!";
    if (!/[!#$%&()*+,-./:;<=>?@[\]^_`{|}~]/.test(input.value))
      errorMessage = "Password must contain at least 1 special symbol!";

    if (errorMessage) {
      this.setState({ passwordValidation: false });
      errors[input.name] = errorMessage;
    } else {
      this.setState({ passwordValidation: true });
      delete errors[input.name];
    }

    this.setState({ password: input.value, errors });
  };

  handleEnabledCheckbox = () => {
    this.setState({
      enabled: !this.state.enabled,
    });
  };

  handleSendMailCheckbox = () => {
    this.setState({
      sendMail: !this.state.sendMail,
    });
  };

  onSelectRole = (option) => {
    const errors = { ...this.state.errors };
    delete errors.role;

    this.setState({
      role_guid: option.guid,
      errors,
      roleValidation: true,
    });
  };

  onSelectPartnerGroup = (option) => {
    this.setState({
      group_guid: option.guid,
    });
  };

  onSelectLanguage = (option) => {
    this.setState({
      language: option.name,
    });
  };

  handleTimeChange = (date) => {
    let value = moment(date).format("YYYY-MM-DDTHH:mm:ss");

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: "monthly_fee_date",
      value,
    });
    if (errorMessage) errors.monthly_fee_date = errorMessage;
    else delete errors.monthly_fee_date;

    // value = moment(value).format("YYYY-MM-DDTHH:mm:ss");
    let data = this.state.data;
    data.monthly_fee_date = value;
    this.setState({
      data,
      errors,
      monthly_fee_dateValidation: errorMessage ? false : true,
    });
  };

  onSelectCurrency = (option) => {
    this.setState({
      data: {
        ...this.state.data,
        monthly_fee_currency: option,
      },
    });
  };

  renderInput = (label, value, name, type = "text") => {
    const { errors } = this.state;
    return (
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>{label}*</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            <Form.Control
              // placeholder={label.toLowerCase()}
              type={type}
              name={name}
              value={value}
              onChange={this.handleChange}
            />
            {errors[name] && (
              <span className="validate-error">{errors[name]}</span>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { role_guid, token, group_guid } = this.state;

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      const data = {
        merchant_name: this.state.data.name,
        merchant_type: this.state.data.type,
        email: this.state.data.email,
        phone: this.state.phone,
        first_name: this.state.data.firstName,
        last_name: this.state.data.lastName,
        company_name: this.state.data.companyName,
        company_address: this.state.data.companyAddress,
        role_guid,
        password: this.state.sendMail ? undefined : this.state.password,
        send_mail: this.state.sendMail === true ? 1 : 0,
        language: this.state.language,
        enabled: this.state.enabled === true ? 1 : 0,
        monthly_fee_currency: this.state.data.monthly_fee_currency.code,
        monthly_fee: +this.state.data.monthly_fee * 100,
        monthly_fee_date: this.state.data.monthly_fee_date,
        monthly_amount_limit: (
          +this.state.data.monthly_amount_limit * 100
        ).toString(),
        custom_amount_limit: (
          +this.state.data.custom_amount_limit * 100
        ).toString(),
        custom_days_limit: this.state.data.custom_days_limit,
      };
      if (token.group) data.group_guid = token.group.group_guid;
      else if (group_guid && group_guid !== "1") data.group_guid = group_guid;
      else data.group_guid = undefined;

      try {
        await this.props.addMerchantAction(
          data,
          this.props.currentPageMerchant,
          this.props.pageSizeMerchant,
          this.props.merchantsSearch
        );
        swal({
          title: "Merchant is created",
          icon: "success",
          text: this.props.warning ? this.props.warning : "",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    let roles = this.props.roles ? this.props.roles : [];
    const { errors, currencies } = this.state;
    return (
      <React.Fragment>
        <Form>
          <Row>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Email*</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      // placeholder="example@email.com"
                      name="email"
                      value={this.state.data.email}
                      onChange={(e) => this.onChangeEmail(e)}
                    />
                    {errors.email && (
                      <span className="validate-error">{errors.email}</span>
                    )}
                    {this.state.errorEmailExists && (
                      <span className="validate-error">
                        {this.state.errorEmailExists}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {/* {this.renderInput("Email", this.state.email, "email", "email")} */}
              {this.renderInput(
                "First name",
                this.state.data.firstName,
                "firstName"
              )}
              {this.renderInput(
                "Last name",
                this.state.data.lastName,
                "lastName"
              )}
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Role*</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <MultiSelect
                      name="role_guid"
                      options={roles}
                      multi={false}
                      onSelect={this.onSelectRole}
                      // placeholder="role"
                    />
                    {errors.role && (
                      <span className="validate-error">{errors.role}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={3} xs={4} className="form-label">
                  <Form.Label>Phone*</Form.Label>
                </Col>
                <Col md={8} sm={8} xs={7}>
                  <Form.Group>
                    <PhoneInput
                      name="phone"
                      // placeholder="phone number"
                      value={this.state.phone}
                      onChange={(e) => this.onChangeNumber(e)}
                    />
                    {this.state.errorPhoneExists && (
                      <span className="validate-error">
                        {this.state.errorPhoneExists}
                      </span>
                    )}
                    {errors.phone && (
                      <span className="validate-error">{errors.phone}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {this.renderInput(
                "Company name",
                this.state.data.companyName,
                "companyName"
              )}
              {this.renderInput(
                "Company address",
                this.state.data.companyAddress,
                "companyAddress"
              )}
              {this.renderInput("Merchant name", this.state.data.name, "name")}
              {this.renderInput("Merchant type", this.state.data.type, "type")}
            </Col>

            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Merchant period limit (days)</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Group>
                    <Form.Control
                      name="custom_days_limit"
                      type="number"
                      // placeholder="Enter custom days limit"
                      value={this.state.data.custom_days_limit}
                      onChange={this.handleChange}
                    />

                    {errors.custom_days_limit && (
                      <span className="validate-error">
                        {errors.custom_days_limit}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Merchant amount limit*</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        name="custom_amount_limit"
                        type="number"
                        // placeholder="Enter custom amount limit"
                        value={this.state.data.custom_amount_limit}
                        onChange={this.handleChangeNumber}
                        aria-describedby="custom_amount_limit"
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="custom_amount_limit">
                          €
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.custom_amount_limit && (
                      <span className="validate-error">
                        {errors.custom_amount_limit}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Monthly amount limit*</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        name="monthly_amount_limit"
                        type="number"
                        // placeholder="Enter monthly amount limit"
                        value={this.state.data.monthly_amount_limit}
                        onChange={this.handleChangeNumber}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="custom_amount_limit">
                          €
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.monthly_amount_limit && (
                      <span className="validate-error">
                        {errors.monthly_amount_limit}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Monthly fee*</Form.Label>
                </Col>
                <Col md={5}>
                  <Form.Group>
                    <Form.Control
                      name="monthly_fee"
                      type="number"
                      // placeholder="Enter monthly fee"
                      value={this.state.data.monthly_fee}
                      onChange={this.handleChangeNumber}
                    />
                    {errors.monthly_fee && (
                      <span className="validate-error">
                        {errors.monthly_fee}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group style={{ paddingLeft: "25px" }}>
                    <MultiSelect
                      name="monthly_fee_currency"
                      options={currencies}
                      multi={false}
                      onSelect={this.onSelectCurrency}
                      // placeholder="Select currency"
                      value={this.state.data.monthly_fee_currency}
                    />

                    {errors.monthly_fee_currency && (
                      <span className="validate-error">
                        {errors.monthly_fee_currency}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Monthly fee date*</Form.Label>
                  <i className="far fa-question-circle help-tip">
                    <p>From this date begins the payment of monthly fee.</p>
                  </i>
                </Col>
                <Col md={5}>
                  <Form.Group>
                    <DateRangePicker
                      onCallback={this.handleTimeChange}
                      initialSettings={{
                        singleDatePicker: true,
                        locale: {
                          format: "DD.MM.YYYY",
                        },
                        startDate: this.state.monthly_fee_date
                          ? moment(this.state.monthly_fee_date).format(
                            "DD.MM.YYYY"
                          )
                          : undefined,
                      }}
                    >
                      <input type="text" className="text-input form-control" />
                    </DateRangePicker>
                    {errors.monthly_fee_date && (
                      <span className="validate-error">
                        {errors.monthly_fee_date}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ margin: "25px 0" }}>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Enable*</Form.Label>
                </Col>
                <Col md={1} sm={1} xs={1}>
                  <Form.Group>
                    <input
                      type="checkbox"
                      id="enabledCheckbox"
                      checked={this.state.enabled}
                      onChange={this.handleEnabledCheckbox}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ margin: "25px 0" }}>
                <Col md={3} sm={3} xs={4} className="form-label">
                  <Form.Label>Send mail*</Form.Label>
                  <i className="far fa-question-circle help-tip">
                    <p>
                      We will send your generated username and password to your
                      email.
                    </p>
                  </i>
                </Col>
                <Col md={1} sm={1} xs={1}>
                  <Form.Group>
                    <input
                      type="checkbox"
                      id="sendMailCheckbox"
                      checked={this.state.sendMail}
                      onChange={this.handleSendMailCheckbox}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {!this.state.sendMail && (
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Password*</Form.Label>
                  </Col>
                  <Col md={8} sm={7} xs={7}>
                    <Form.Group>
                      <Form.Control
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        // placeholder="Enter password"
                        onChange={this.onChangePassword}
                      />
                      {errors.password && (
                        <span className="validate-error">
                          {errors.password}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Button className="eye" onClick={this.handleShowPassword}>
                    {this.state.showPassword ? (
                      <span className="fa fa-eye-slash" />
                    ) : (
                      <span className="fa fa-eye" />
                    )}
                  </Button>
                </Row>
              )}
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Language</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Group>
                    <MultiSelect
                      name="Language"
                      options={[
                        { name: "en", guid: "en" },
                        { name: "ru", guid: "ru" },
                      ]}
                      multi={false}
                      defaultValue={this.state.language}
                      onSelect={this.onSelectLanguage}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {(this.state.token.partner || this.state.isAdmin) && (
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Group</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Group>
                      <MultiSelect
                        name="Groups"
                        options={this.state.groups ? this.state.groups : []}
                        multi={false}
                        onSelect={this.onSelectPartnerGroup}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <div className="modal-buttons">
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.phoneValidation &&
                  this.state.roleValidation &&
                  this.state.emailValidation &&
                  this.state.companyNameValidation &&
                  this.state.companyAddressValidation &&
                  this.state.firstNameValidation &&
                  this.state.lastNameValidation &&
                  this.state.nameValidation &&
                  this.state.typeValidation &&
                  this.state.connection_feeValidation &&
                  this.state.monthly_amount_limitValidation &&
                  this.state.monthly_feeValidation &&
                  this.state.custom_amount_limitValidation &&
                  this.state.custom_days_limitValidation &&
                  this.state.monthly_fee_dateValidation &&
                  (this.state.sendMail ? true : this.state.passwordValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
                onClick={this.handleSubmit}
              >
                Add
              </Button>
            )}
          </div>
          <Col sm={1} />
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailExists: state.users.users.email_exists,
    phoneExists: state.users.users.phone_exists,
    roles: state.roles.rolesList,
    groups: state.groups.groupsList,
    merchant_guid: state.merchants.merchant_guid,
    warning: state.merchants.warning,
    currentPageMerchant: state.merchants.currentPage,
    pageSizeMerchant: state.merchants.pageSize,
    merchantsSearch: state.search.merchantsSearch,
    currencies: state.currencies.currenciesList,
  };
};

export default connect(mapStateToProps, {
  getAllRoles,
  addMerchantAction,
  addMerchantLoginAction,
  getAllGroups,
  editGroupAction,
  checkUserExistsAction,
  showModal,
  getAllCurrencies,
})(MerchantCreator);

MerchantCreator.propTypes = {
  addMerchantAction: PropTypes.func,
  checkUserExistsAction: PropTypes.func,
  currentPageMerchant: PropTypes.number,
  emailExists: PropTypes.bool,
  getAllGroups: PropTypes.func,
  getAllRoles: PropTypes.func,
  groups: PropTypes.array,
  merchantsSearch: PropTypes.object,
  pageSizeMerchant: PropTypes.number,
  phoneExists: PropTypes.bool,
  roles: PropTypes.array,
  showModal: PropTypes.func,
  warning: PropTypes.string,
};
