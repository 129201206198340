import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { addAdminAction, getAdminAction } from "../../actions/admins";
import { showModal } from "../../actions/modal";
import { connect } from "react-redux";
import { parseResponse } from "helpers/parseResponse";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import PhoneInput from "../UI/PhoneInput";
import Spinner from "components/UI/Spinner/index";
import Joi from "joi-browser";
import { checkUserExistsAction } from "../../actions/users";
import ability from "config/ability";
import PropTypes from "prop-types";
import Alert from "../UI/Alert";

class AdminEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        guid: "",
        username: "",
        email: "",
        enabled: true,
        phone: "",
        firstName: "",
        lastName: "",
        authType: false,
      },
      oldData: {
        username: "",
        email: "",
        enabled: true,
        phone: "",
        firstName: "",
        lastName: "",
        authType: false,
      },

      needReason: false,
      reason: "",
      warning: "",
      isLoading: false,
      errors: {},
      errorUsernameExists: "",
      usernameExists: "",

      usernameValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      phoneValidation: false,
    };
  }

  schema = {
    guid: Joi.string().required(),
    username: Joi.string().min(5).required().label("Username"),
    email: Joi.string().required(),
    phone: Joi.string().required().min(5).label("Phone"),
    firstName: Joi.string().required().label("Name"),
    lastName: Joi.string().required().label("Surname"),
    enabled: Joi.boolean().required().label("Enabled"),
    authType: Joi.any().required(),
  };

  componentDidMount = async () => {
    await this.props.getAdminAction(this.props.guid);
    const { admin } = this.props;
    this.setState({
      data: {
        guid: admin.guid,
        email: admin.email,
        username: admin.username,
        firstName: admin.first_name,
        lastName: admin.last_name,
        phone: admin.phone,
        enabled: admin.enabled,
        authType: admin.auth_type === "login-password-mail",
      },
      oldData: {
        email: admin.email,
        username: admin.username,
        firstName: admin.first_name,
        lastName: admin.last_name,
        phone: admin.phone,
        enabled: admin.enabled,
        authType: admin.auth_type === "login-password-mail",
      },

      warning: admin.warning,
      oldUsername: admin.username,
      oldPhone: admin.phone,

      usernameValidation: admin.username ? true : false,
      firstNameValidation: admin.first_name ? true : false,
      lastNameValidation: admin.last_name ? true : false,
      phoneValidation: admin.phone ? true : false,
    });
  };

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = {
        guid: this.state.data.guid,
        email: this.state.data.email,
        username: this.state.data.username,
        firstName: this.state.data.firstName,
        lastName: this.state.data.lastName,
        phone: this.state.data.phone,
        enabled: this.state.data.enabled,
        authType: this.state.data.authType,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        guid: this.state.data.guid,
        email: this.state.data.email,
        username: this.state.data.username,
        firstName: this.state.data.firstName,
        lastName: this.state.data.lastName,
        phone: this.state.data.phone,
        enabled: this.state.data.enabled,
        authType: this.state.data.authType,
      };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (name === "username" && this.state.errorUsernameExists) return "error";
    if (this.state.errors[name] || this.state.data[name] === "") return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state.data };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState(
      {
        data,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );

    if (
      input.name === "username" &&
      input.value !== "" &&
      input.value !== this.state.oldData.username &&
      input.value.length > 4
    ) {
      await this.props.checkUserExistsAction({ username: input.value });

      this.setState({
        usernameExists: this.props.usernameExists,
      });

      if (this.state.usernameExists)
        this.setState({
          errorUsernameExists: "Username exists",
          usernameValidation: false,
        });
      else
        this.setState({
          errorUsernameExists: "",
          usernameValidation: errorMessage ? false : true,
        });
    }
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name: "phone", value: value });
    if (errorMessage) errors.phone = errorMessage;
    else delete errors.phone;

    let data = { ...this.state.data };
    data.phone = value;
    this.setState(
      { data, errors, phoneValidation: errorMessage ? false : true },
      () => this.showReason()
    );

    if (value !== "" && value !== this.state.oldData.phone && value.length > 4)
      await this.props.checkUserExistsAction({ phone: value });

    this.setState({
      phoneExists: this.props.phoneExists,
    });

    if (this.state.phoneExists)
      this.setState({
        errorPhoneExists: "Phone exists",
        phoneValidation: false,
      });
    else
      this.setState({
        errorPhoneExists: "",
        phoneValidation: errorMessage ? false : true,
      });
  };

  handleEnabledCheckbox = () => {
    let data = { ...this.state.data };
    data.enabled = !this.state.data.enabled;
    this.setState({ data }, () => this.showReason());
  };

  handleAuthTypeCheckbox = () => {
    let data = { ...this.state.data };
    data.authType = !this.state.data.authType;
    this.setState({ data }, () => this.showReason());
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });

          await this.props.addAdminAction({
            guid: this.state.data.guid,
            email: ability.can("EXECUTE", "USERADMINEMAIL")
              ? this.state.data.email
              : undefined,
            username: this.state.data.username,
            first_name: this.state.data.firstName,
            last_name: this.state.data.lastName,
            enabled: this.state.data.enabled === true ? 1 : 0,
            auth_type: ability.can("EXECUTE", "USERADMINAUTHTYPE")
              ? this.state.data.authType
                ? "login-password-mail"
                : "login-password"
              : undefined,
            phone: this.state.data.phone,
            reason: this.state.needReason ? this.state.reason : undefined,
          });

          swal({
            title: "Admin is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });

          this.setState({ isLoading: false });
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;

    if (this.props.adminLoading) return <Spinner />;
    else
      return (
        <Form autoComplete="off">
          {/* <div className="card">
            <div className="content">
              <label>ID: {this.props.guid}</label>
              <br />
              <label style={{ color: "red" }}>
                {this.state.warning !== undefined
                  ? `Warning: ${this.state.warning}`
                  : null}
              </label>
            </div>
          </div> */}

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Email*</Form.Label>
            </Col>
            <Col md={8} sm={8} xs={7}>
              <Form.Group validationState={this.formValidation("email")}>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  value={this.state.data.email}
                  onChange={this.handleChange}
                  readOnly={
                    ability.can("EXECUTE", "USERADMINEMAIL") ? false : true
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Username*</Form.Label>
            </Col>
            <Col md={8} sm={8} xs={7}>
              <Form.Group validationState={this.formValidation("username")}>
                <Form.Control
                  name="username"
                  type="text"
                  placeholder="Enter username"
                  value={this.state.data.username}
                  onChange={this.handleChange}
                />
                {errors.username && (
                  <span className="validate-error">{errors.username}</span>
                )}
                {this.state.errorUsernameExists && (
                  <span className="validate-error">
                    {this.state.errorUsernameExists}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Name*</Form.Label>
            </Col>
            <Col md={8} sm={8} xs={7}>
              <Form.Group validationState={this.formValidation("firstName")}>
                <Form.Control
                  name="firstName"
                  type="text"
                  placeholder="Enter name"
                  value={this.state.data.firstName}
                  onChange={this.handleChange}
                />
                {errors.firstName && (
                  <span className="validate-error">{errors.firstName}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Surname*</Form.Label>
            </Col>
            <Col md={8} sm={8} xs={7}>
              <Form.Group validationState={this.formValidation("lastName")}>
                <Form.Control
                  name="lastName"
                  type="text"
                  placeholder="Enter surname"
                  value={this.state.data.lastName}
                  onChange={this.handleChange}
                />
                {errors.lastName && (
                  <span className="validate-error">{errors.lastName}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Phone*</Form.Label>
            </Col>
            <Col md={8} sm={8} xs={7}>
              <Form.Group>
                <PhoneInput
                  name="phone"
                  placeholder="Enter phone number"
                  value={this.state.data.phone}
                  onChange={this.onChangeNumber}
                />
                {this.state.errorPhoneExists && (
                  <span className="validate-error">
                    {this.state.errorPhoneExists}
                  </span>
                )}
                {errors.phone && (
                  <span className="validate-error">{errors.phone}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <label>Enable*</label>
            </Col>
            <Col md={1} sm={1} xs={1}>
              <input
                type="checkbox"
                id="enabledCheckbox"
                checked={this.state.data.enabled}
                onChange={this.handleEnabledCheckbox}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <label>Two-factor authentication*</label>
            </Col>
            <Col md={1} sm={1} xs={1}>
              <input
                type="checkbox"
                id="enabledCheckbox"
                disabled={!ability.can("EXECUTE", "USERADMINAUTHTYPE")}
                checked={this.state.data.authType}
                onChange={this.handleAuthTypeCheckbox}
              />
            </Col>
          </Row>

          {this.state.needReason && (
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Reason</Form.Label>
              </Col>
              <Col md={8} sm={8} xs={7}>
                <Form.Group validationState={this.formValidation("reason")}>
                  <Form.Control
                    placeholder="Enter reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChangeReason}
                  />
                  {errors.reason && (
                    <span className="validate-error">{errors.reason}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.phoneValidation &&
                  this.state.usernameValidation &&
                  this.state.firstNameValidation &&
                  this.state.lastNameValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admins.admin,
    adminLoading: state.admins.adminLoading,
    loginGuid: state.logins.loginGuid,
    pageSize: state.logins.pageSize,
    currentPage: state.logins.currentPage,
    usernameExists: state.users.users.username_exists,
    phoneExists: state.users.users.phone_exists,
    loginsSearch: state.search.loginsSearch,
  };
};

export default connect(mapStateToProps, {
  addAdminAction,
  getAdminAction,
  checkUserExistsAction,
  showModal,
})(AdminEditor);

AdminEditor.propTypes = {
  addAdminAction: PropTypes.func,
  admin: PropTypes.object,
  adminLoading: PropTypes.bool,
  checkUserExistsAction: PropTypes.func,
  getAdminAction: PropTypes.func,
  guid: PropTypes.string,
  phoneExists: PropTypes.bool,
  showModal: PropTypes.func,
  usernameExists: PropTypes.bool,
};
