import React, { Component } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import PrivilegeMain from "../Privilege/Main";
import RoleMain from "../Role/Main";

class RolePrivilegeMain extends Component {
  constructor() {
    super();

    this.state = {
      type: "Role",
      isUpdate: false,
      labelButton: "Role",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  async handleSelect(e) {
    await this.setState({ type: e, labelButton: e });
  }

  render() {
    return (
      <div>
        <DropdownButton
          as="s"
          key={this.state.labelButton}
          id={`dropdown-variants-${this.state.labelButton}`}
          title={this.state.labelButton}
        >
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Role">Role</Dropdown.Item>
          <Dropdown.Item onSelect={this.handleSelect} eventKey="Privilege">Privilege</Dropdown.Item>
        </DropdownButton>

        {
          {
            "Role": <RoleMain />,
            "Privilege": <PrivilegeMain />

          }[this.state.type]
        }
      </div>
    );
  }
}

export default RolePrivilegeMain;
