import React from "react";
import { connect } from "react-redux";
import AbstractComponent from "../../../factories/Main";
import {
  getMerchantsBlackListAction,
  deleteItemMerchantsBlackListAction,
} from "../../../actions/blacklists";
import { searchInMerchantsBlacklist, reset } from "../../../actions/search";
import { Link } from "react-router-dom";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import ability from "config/ability";
import { getMerchantsBlackList } from "../../../services/paymentBackendAPI/management/blacklists";
import { resetPage, setNewTable } from "../../../actions/store";
import { createColComponentForTable } from "../../../helpers/createColComponentForTable";

const token = getAuthData();

const columns = [
  token && token.userPayload && token.userPayload.merchant
    ? null
    : {
        path: "merchant_name",
        label: "Merchant",
        key: "merchant_name",
        content: (item) => (
          <Link className="link" to={`/about/merchant/${item.merchant_guid}`}>
            {item.merchant_name}
          </Link>
        ),
        textContent: (item) => item.merchant_name,
      },
  {
    path: "shop_name",
    label: "Shop",
    key: "shop_name",
    content: (item) => (
      <Link className="link" to={`/about/shop/${item.shop_guid}`}>
        {item.shop_name}
      </Link>
    ),
    textContent: (item) => item.shop_name,
  },
  {
    path: "terminal_name",
    label: "Terminal",
    key: "terminal_name",
    content: (item) => (
      <Link className="link" to={`/about/terminal/${item.terminal_guid}`}>
        {item.terminal_name}
      </Link>
    ),
    textContent: (item) => item.terminal_name,
  },
  {
    path: "blacklist_rule_name",
    label: "Rule name",
    key: "blacklist_rule_name",
    content: (item) => (
      <Link
        className="link"
        to={`/about/blacklist/${item.blacklist_rule_guid}`}
      >
        {item.blacklist_rule_name}
      </Link>
    ),
    textContent: (item) => item.blacklist_rule_name,
  },
  { path: "type", label: "Type", key: "typeBlacklist" },
  ability.can("DELETE", "BLACKLISTS") && {
    key: "deleteMerchantsBlacklist",
    content: () => (
      <i
        className="far fa-trash-alt"
        style={{ cursor: "pointer", color: "red" }}
      />
    ),
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.blacklist.merchantsBlackList,
    count: state.blacklist.count,
    table: state.store.table,
    searchData: state.search.merchantsBlacklistSearch,
    isSearch: state.search.isSearch,
    loading: state.blacklist.merchantsBlackLoading,
    name: "BLACKLISTS",
    keyPath: "guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    exportFunction: getMerchantsBlackList,
  };
};

export default connect(mapStateToProps, {
  get: getMerchantsBlackListAction,
  delete: deleteItemMerchantsBlackListAction,
  search: searchInMerchantsBlacklist,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
