import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import ReactLoading from "react-loading";

function StatementSelect({
  statementsLoading,
  value = [],
  statements = [],
  callback
}) {
  const onSelect = (options) => {
    callback && callback(options);
  };

  return (
    <Row>
      <Col md={3} sm={4} xs={4} className="form-label">
        <Form.Label>Statements*</Form.Label>
      </Col>
      <Col md={8}>
        {statementsLoading ?
            <div>
              <ReactLoading type="cylon" color="grey" />
            </div>
          :
            <Form.Group>
              <MultiSelect
                name="statements"
                value={value}
                options={statements}
                multi={true}
                onSelect={onSelect}
                placeholder="Select statements"
              />

            </Form.Group>}
      </Col>
    </Row >
  );
}

export default StatementSelect;