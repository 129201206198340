// import Can from "components/Can";
import CloseWorkingDay from "components/Service/CloseWorkingDayDecta";
import ServiceMain from "components/Service/Main";
import Modal from "components/UI/Modal";
import React from "react";
import Content from "../Content";
import ability from "config/ability";

const Service = () => {
  return (
    <Content>
      <ServiceMain
        modalComponent={
          // <Can do="EXECUTE" on="DECTARECOT">
          <Modal
            allowed={ability.can("EXECUTE", "DECTARECOT")}
            header="Close working day"
            content={CloseWorkingDay}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Close working day for DECTA
              </button>
            }
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default Service;
