export const columnsTotal = [
  { path: "name", label: "Currency", key: "name" },
  {
    key: "turnover_count_amount",
    label: "Transactions approved",
    content: (currency) =>
      currency.turnover_count + " / " + currency.turnover_amount,
  },
  {
    key: "turnover_failed_count_amount",
    label: "Transactions declined",
    content: (currency) =>
      currency.turnover_count_failed +
      " / " +
      currency.turnover_amount_failed,
  },
  {
    key: "reversal_count_amount",
    label: "Reversal",
    content: (currency) =>
      currency.reverse_count + " / " + currency.reverse_amount,
  },
  {
    key: "refund_count_amount",
    label: "Refund",
    content: (currency) =>
      currency.refund_count + " / " + currency.refund_amount,
  },
  {
    key: "chargeback_count_amount",
    label: "Chargeback",
    content: (currency) =>
      currency.chargeback_count + " / " + currency.chargeback_amount,
  },
  {
    key: "prearbitration_count_amount",
    label: "Pre-arbitration",
    content: (currency) =>
      currency.pre_arbitration_count +
      " / " +
      currency.pre_arbitration_amount,
  },
  {
    key: "chargeback_reversal_count_amount",
    label: "Chargeback reversal",
    content: (currency) =>
      currency.chargeback_reversal_count +
      " / " +
      currency.chargeback_reversal_amount,
  },
  {
    key: "representment_count_amount",
    label: "Representment",
    content: (currency) =>
      currency.representment_count + " / " + currency.representment_amount,
  },
  {
    key: "retrieval_count_amount",
    label: "Retrieval",
    content: (currency) =>
      currency.retrieval_count + " / " + currency.retrieval_amount,
  },
  {
    key: "summary_count_amount",
    label: "Summary",
    content: (currency) =>
      currency.summary_count + " / " + currency.summary_amount,
  },
];

export const defaultRatesRows = [
  { name: "turnover_amount", label: "Turnover amount" },
  { name: "reverse_amount", label: "Reversal amount" },
  { name: "refund_amount", label: "Refund amount" },
  { name: "chargeback_amount", label: "Chargeback amount" },
  { name: "pre_arbitration_amount", label: "Prearbitration amount" },
  { name: "chargeback_reversal_amount", label: "Chargeback reversal amount" },
  { name: "representment_amount", label: "Representment amount" },
  { name: "summary_amount", label: "Summary amount" },
  // { name: "success_turnover_fee", label: "Success turnover fee" },
  {
    name: "success_turnover_fee_fixed",
    label: "Success turnover fee (fixed)",
  },
  { name: "success_turnover_fee_pct", label: "Success turnover fee (%)" },
  { name: "failed_turnover_fee", label: "Failed turnover fee" },
  { name: "refund_fee", label: "Refund fee" },
  { name: "chargeback_fee", label: "Chargeback fee" },
  { name: "connection_fee", label: "Connection fee" },
  { name: "monthly_fee", label: "Monthly fee" },
  { name: "bank_wire_fee", label: "Bank wire fee" },
  { name: "summary_fees", label: "Summary fees" },
  { name: "hold_amount", label: "Hold amount" },
  { name: "hold_return", label: "Hold return" },
  { name: "for_payout", label: "For payout" },
];

export const columnsRates = [
  {
    path: "label",
    label: "",
    key: "name",
    style: { fontWeight: "bold", textAlign: "left", whiteSpace: "nowrap" },
  },
];

export const columnsMergeRates = [
  { path: "name", label: "Name" },
  {
    path: "turnover_amount",
    label: "Turnover",
    content: (statement) =>
      statement.turnover_count + " / " + statement.turnover_amount,
  },
  {
    path: "turnover_amount_failed",
    label: "Turnover failed",
    content: (statement) =>
      statement.turnover_count_failed +
      " / " +
      statement.turnover_amount_failed,
  },
  {
    path: "summary_count",
    label: "Summary",
    content: (statement) =>
      statement.summary_count + " / " + statement.summary_amount,
  },
  {
    path: "reverse_amount",
    label: "Reverse",
    content: (statement) =>
      statement.reverse_count + " / " + statement.reverse_amount,
  },
  {
    path: "cancel_amount",
    label: "Cancel",
    content: (statement) =>
      statement.cancel_count + " / " + statement.cancel_amount,
  },
  {
    path: "chargeback_amount",
    label: "Chargeback",
    content: (statement) =>
      statement.chargeback_count + " / " + statement.chargeback_amount,
  },
  {
    path: "retrieval_amount",
    label: "Retrieval",
    content: (statement) =>
      statement.retrieval_count + " / " + statement.retrieval_amount,
  },
  {
    path: "representment_amount",
    label: "Representment",
    content: (statement) =>
      statement.representment_count + " / " + statement.representment_amount,
  },
  {
    path: "pre_arbitration_amount",
    label: "Pre arbitration",
    content: (statement) =>
      statement.pre_arbitration_count +
      " / " +
      statement.pre_arbitration_amount,
  },
  {
    path: "chargeback_reversal_amount",
    label: "Chargeback reversal",
    content: (statement) =>
      statement.chargeback_reversal_count +
      " / " +
      statement.chargeback_reversal_amount,
  },
  {
    path: "refund_amount",
    label: "Refund",
    content: (statement) =>
      statement.refund_count + " / " + statement.refund_amount,
  },
  { path: "for_payout", label: "For payout" },
];