import React, { Component } from "react";
import { Row, Col, Tab, Tabs, Card } from "react-bootstrap";
import Content from "../Content";
import HorizontalBarChartTopMerchants from "components/HorizontalBarChart/TopMerchants";
import HorizontalBarChartTopGroups from "components/HorizontalBarChart/TopGroups";
import HorizontalBarChartTopPartners from "components/HorizontalBarChart/TopPartners";
import HorizontalBarChartTopShops from "components/HorizontalBarChart/TopShops";
import DailyCardsTypes from "components/Report/Daily/CardTypes";
import DailyCurrencies from "components/Report/Daily/Currencies";
import DailyTransactionsStatuses from "components/Report/Daily/TransactionsStatuses";
import DailyTransactionsTypes from "components/Report/Daily/TransactionsTypes";
import DailyTransactionsHistory from "components/Report/Daily/TransactionsHistory";
import { getAllMerchants } from "actions/merchants";
import { getAllShops, getMerchantShopsAction } from "actions/shops";
import {
  getTransactionHistoryAction,
  getTotalProcessedDailyAction,
} from "actions/transactions";
import { parseString } from "helpers/parseString";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReportForm from "../../components/Report/ReportForm";
import { exportDailyReport } from "../../services/paymentBackendAPI/reports/transactions";
import Alert from "../../components/UI/Alert";
import moment from "moment";
import { parseResponse } from "../../helpers/parseResponse";

class DailyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        merchant_guid_array: [],
        group_guid_array: [],
        partner_guid_array: [],
        terminal_guid_array: [],
        shop_guid_array: [],
        from_date: moment()
          .format("YYYY-MM-DDTHH:mm:ss")
          .replace(/\D\d{2}\w/, "-01T"),
        to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      },
    };
  }

  async componentDidMount() {
    await this.props.getTransactionHistoryAction({ days: 1 });
    await this.props.getTotalProcessedDailyAction({ days: 1 });

    this.setState({
      totalProcessed: this.props.totalProcessed,
    });

    const totalLength = this.props.totalProcessed
      ? this.props.totalProcessed.length
      : 0;

    let { totalProcessed } = this.state;
    const total = parseString(totalProcessed, totalLength);

    this.setState({
      totalProcessed: total,
    });
  }

  onSubmitCalculate = async (data) => {
    this.setState({ isLoading: true });
    try {
      this.setState({
        data,
      });
      await this.props.getTransactionHistoryAction({ days: 1, ...data });
      await this.props.getTotalProcessedDailyAction({ days: 1, ...data });

      this.setState({
        totalProcessed: this.props.totalProcessed,
      });

      const totalLength = this.props.totalProcessed
        ? this.props.totalProcessed.length
        : 0;

      let { totalProcessed } = this.state;
      const total = parseString(totalProcessed, totalLength);

      this.setState({
        totalProcessed: total,
      });
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  export = async (data) => {
    this.setState({ exportLoading: true });
    let res;
    let a;
    let file;
    switch (data.export_document_format) {
      case "xls":
        res = await exportDailyReport(data);
        a = document.createElement("a");
        file = new Blob([ res.data ], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        a.href = URL.createObjectURL(file);
        a.download = "daily-report.xlsx";
        a.click();
        break;
      case "pdf":
        res = await exportDailyReport(data);
        a = document.createElement("a");
        a.href = res.data;
        a.download = "daily-report.pdf";
        a.click();
        break;
      case "csv":
        res = await exportDailyReport(data);
        a = document.createElement("a");
        file = new Blob([ res.data ], { type: "text/plain" });
        a.href = URL.createObjectURL(file);
        a.download = "daily-report.csv";
        a.click();
        break;

      default:
        break;
    }

    this.setState({ exportLoading: false });
  };

  render() {
    return (
      <Content>
        <ReportForm
          handleSubmit={this.onSubmitCalculate}
          isSelectCurrency={false}
          isExport
          isSelectDate={false}
          exportHandler={this.export}
        />

        <Card>
          <Card.Header as="h5">Daily report</Card.Header>
          <Card.Body>
            <DailyTransactionsHistory data={this.state.data} />
          </Card.Body>
        </Card>
        <Row>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Card>
              <Card.Header as="h5">Passability</Card.Header>
              <Card.Body>
                <DailyTransactionsStatuses />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Card>
              <Card.Header as="h5">Currency</Card.Header>
              <Card.Body>
                <DailyCurrencies params={this.state.data} />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Card>
              <Card.Header as="h5">Transactions type</Card.Header>
              <Card.Body>
                <DailyTransactionsTypes />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Card>
              <Card.Header as="h5">Card type</Card.Header>
              <Card.Body>
                <DailyCardsTypes params={this.state.data} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey="topMerchants"
          id="top-for-daily-report"
          style={{ marginLeft: "9px" }}
        >
          <Tab eventKey="topMerchants" title="Top merchants">
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Card>
                  <Card.Header as="h5">Top 10 merchants</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopMerchants daily="daily" />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="topGroups" title="Top groups">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top 10 groups</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopGroups daily="daily" />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="topPartners" title="Top partners">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top 10 partners</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopPartners daily="daily" />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="topShops" title="Top shops">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">Top 10 shops</Card.Header>
                  <Card.Body>
                    <HorizontalBarChartTopShops daily="daily" />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <Row>
          <Col md={3} sm={3} xs={3} />
          <Col md={3} sm={3} xs={3}>
            <Card>
              <Card.Header as="h5">Count transactions</Card.Header>
              <Card.Body>{this.props.transactionsAmount}</Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={3} xs={3}>
            <Card>
              <Card.Header as="h5">Amount of money processed</Card.Header>
              <Card.Body>
                $ {this.state.totalProcessed ? this.state.totalProcessed : 0}
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={3} xs={3} />
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    transactionsAmount: state.transactions.transactionHistoryCount,
    totalProcessed: state.transactions.totalProcessed,
    shops: state.shops.shopsList,
  };
};

export default connect(mapStateToProps, {
  getAllMerchants,
  getAllShops,
  getMerchantShopsAction,
  getTransactionHistoryAction,
  getTotalProcessedDailyAction,
})(DailyReport);

DailyReport.propTypes = {
  getAllMerchants: PropTypes.func,
  getAllShops: PropTypes.func,
  getMerchantShopsAction: PropTypes.func,
  getTotalProcessedDailyAction: PropTypes.func,
  getTransactionHistoryAction: PropTypes.func,
  merchants: PropTypes.array,
  shops: PropTypes.array,
  totalProcessed: PropTypes.oneOfType([ PropTypes.array, PropTypes.number ]),
  transactionsAmount: PropTypes.number,
};
