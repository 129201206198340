import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import MultiSelector from ".";
import ability from "config/ability";

class MultiSelectorContainer extends Component {
  state = {
    options: [],
  };

  async componentDidMount() {
    let optionName = this.props.optionName || "name";
    let options = this.props.options;
    if (
      this.props.getOptions &&
      ability.can("READ", this.props.abilityName || "")
    ) {
      await this.props.getOptions();
      options = this.props.options.map((item) => {
        return {
          name: item[optionName],
          guid: item[optionName],
          label: item[optionName],
          value: item[optionName],
        };
      });
    }
    this.setState({ options });
  }

  onSelect = async (options) => {
    if (options.length !== 0) {
      let array;
      if (this.props.lowerCase) {
        array = options.map((item) => {
          return item.name.toLowerCase();
        });
      } else {
        array = options.map((item) => {
          return item.name;
        });
      }

      await this.props.search({
        [this.props.value]: array,
      });
    } else if (options.length === 0) {
      await this.props.search({
        [this.props.value]: undefined,
      });
      await this.props.get({
        page: 1,
        items: 100,
        ...this.props.searchData,
      });
    }
  };

  applyFilter = async () => {
    if (
      this.props.searchData[this.props.value] &&
      this.props.searchData[this.props.value].length !== 0
    ) {
      await this.props.get({
        page: 1,
        items: 100,
        ...this.props.searchData,
      });
    }
  };

  render() {
    let selectedOptions = [];
    if (this.props.searchData[this.props.value]) {
      let name;
      selectedOptions = this.props.searchData[this.props.value].map((value) => {
        name = this.props.upperCase
          ? value.charAt(0).toUpperCase() + value.slice(1)
          : value;
        return { name: name, guid: name, label: name, value: name };
      });
    }
    return (
      <MultiSelector
        selectedOptions={selectedOptions}
        applyFilter={this.applyFilter}
        onSelect={this.onSelect}
        options={this.state.options}
        isSearch={this.props.isSearch}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSearch: state.search.isSearch,
  };
};

export default connect(mapStateToProps, {})(MultiSelectorContainer);

MultiSelectorContainer.propTypes = {
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  options: PropTypes.array,
  search: PropTypes.func,
  searchData: PropTypes.object,
  value: PropTypes.string,
};
