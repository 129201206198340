import React from "react";
import { connect } from "react-redux";
import { LineChart } from "../UI/LineChart";
import { getTransactionTypesForShopAction } from "../../actions/transactions";
import { lastNDays } from "../../services/dateTime/dateTime";
import PropTypes from "prop-types";
import moment from "moment";

class LineChartShopTransactionsAmount extends React.Component {
  state = {
    daysCount: 7,
    loading: true,
    types: {},
  };

  componentDidMount = async () => {
    if (this.props.shopGuid) {
      await this.props.getTransactionTypesForShopAction({
        days: this.state.daysCount,
        shop_guid: this.props.shopGuid,
      });
      this.setState({
        types: this.props.types,
      });
    }
  };

  render() {
    const labelsPie = lastNDays(this.state.daysCount);
    let transactionTypes = Object.keys(this.state.types);
    let datasetSuccess = [];
    let datasetFailed = [];
    let datasetPending = [];
    transactionTypes.forEach((transactionType) => {
      this.state.types[transactionType].forEach((item) => {
        const findIndex = labelsPie.findIndex(
          (itemD) => itemD === moment(item.date).format("DD.MM")
        );
        if (findIndex !== -1) {
          if (datasetSuccess[findIndex])
            datasetSuccess[findIndex] += parseInt(item.success, 10);
          else datasetSuccess[findIndex] = parseInt(item.success, 10);
          if (datasetFailed[findIndex])
            datasetFailed[findIndex] += parseInt(item.failed, 10);
          else datasetFailed[findIndex] = parseInt(item.failed, 10);
          if (datasetPending[findIndex])
            datasetPending[findIndex] += parseInt(item.pending, 10);
          else datasetPending[findIndex] = parseInt(item.pending, 10);
        }
      });
    });
    for (let i = 0; i < labelsPie.length; i++) {
      if (!datasetSuccess[i]) datasetSuccess[i] = 0;
      if (!datasetFailed[i]) datasetFailed[i] = 0;
      if (!datasetPending[i]) datasetPending[i] = 0;
    }
    const datasetsPie = [
      {
        label: "Success transactions",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "black",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: datasetSuccess,
      },
      {
        label: "Failed transactions",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255,99,132,0.4)",
        borderColor: "rgba(255,99,132,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255,99,132,1)",
        pointHoverBorderColor: "black",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: datasetFailed,
      },
      {
        label: "Pending transactions",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255, 159, 64, 0.4)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255, 159, 64, 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255, 159, 64, 1)",
        pointHoverBorderColor: "black",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: datasetPending,
      },
    ];

    return (
      <LineChart
        labels={labelsPie}
        datasets={datasetsPie}
        name={`Transactions amount of shop: ${
          this.props.shopGuid ? this.props.shopGuid : ""
        }`}
        // name="123"
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.transactions.typesTransactionsOfShop,
  };
};

export default connect(mapStateToProps, {
  getTransactionTypesForShopAction,
})(LineChartShopTransactionsAmount);

LineChartShopTransactionsAmount.propTypes = {
  getTransactionTypesForShopAction: PropTypes.func,
  shopGuid: PropTypes.string,
  types: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
};
