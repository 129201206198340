import * as types from "../constants/actionTypes";

const initialState = {
  additionalFees: [],
  loading: false,
  error: false,
};

export default function additionalFees(state = initialState, action) {
  switch (action.type) {
    case types.GET_ADDITIONAL_FEES:
      return {
        ...state,
        additionalFees: [],
        loading: true,
        error: false
      };
    case types.GET_ADDITIONAL_FEES_SUCCEED:
      return {
        ...state,
        additionalFees: action.data.data,
        count: action.data.count,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        loading: false,
        error: false
      };
    case types.GET_ADDITIONAL_FEES_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.EDIT_ADDITIONAL_FEE: {
      let arr = [ ...state.additionalFees ];
      arr = arr.map(item => {
        if (item.guid === action.fee.guid)
          return action.fee;
        else
          return item;
      });
      return { ...state, additionalFees: arr };
    }

    default:
      return state;
  }
}