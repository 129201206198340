import * as types from "../constants/actionTypes";
import {
  createPrivilege,
  deletePrivilege,
  getPrivileges,
  updatePrivilege,
  getPrivilege
} from "../services/paymentBackendAPI/management/privileges";
import { loadTable } from "./store";

export const getAllPrivileges = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PRIVILEGES
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getPrivileges(args);
      dispatch({
        type: types.GET_PRIVILEGES_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_PRIVILEGES_FAILED
      });
    }
  };
};

export const getPrivilegesWithType = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PRIVILEGES
    });
    try {
      const response = await getPrivileges(args);
      dispatch({
        type: types.GET_PRIVILEGES_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_PRIVILEGES_FAILED
      });
    }
  };
};

export const getPrivilegeAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PRIVILEGE
    });
    try {
      const response = await getPrivilege(data);
      return dispatch({
        type: types.GET_PRIVILEGE_SUCCEED,
        privilege: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_PRIVILEGE_FAILED
      });
    }
  };
};

export const editPrivilegeAction = (data) => {
  return async (dispatch) => {
    const response = await updatePrivilege(data);
    dispatch({
      type: types.EDIT_PRIVILEGE,
      privilege: response.data
    });
  };
};

export const addPrivilegeAction = (data, page, items, search) => {
  return async (dispatch) => {
    await createPrivilege(data);
    return dispatch(getAllPrivileges({ page, items, ...search }));
  };
};

export const deletePrivilegeAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deletePrivilege(data);
    return dispatch(getAllPrivileges({ page, items, ...search }));
  };
};
