import React, { Component } from "react";
import { getParamsForStepAction } from "actions/steps";
import { parseResponse } from "helpers/parseResponse";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Badge, Card } from "react-bootstrap";
import Alert from "../../../UI/Alert";

class Params extends Component {
  state = {
    isSecretCopied: false,
  };

  componentDidMount = async () => {
    try {
      await this.props.getParamsForStepAction(this.props.guid);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    const params = this.props.params;
    return params.length > 0 ? (
      <div>
        {params.map((param, index) => (
          <Card key={index}>
            <Card.Body>
              <Card.Title>{param.name}</Card.Title>

              <div className="param-container">
                <span style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                  {JSON.stringify(param.value, null, "\t")}
                </span>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
    ) : (
      <div
        style={{
          width: "100%",
          height: "60px",
          textAlign: "center",
          paddingTop: "13%",
          paddingBottom: "13%",
          marginTop: "0",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "0",
        }}
      >
        <Badge
          pill
          style={{
            position: "absolute",
            left: "calc(50% - 77px)",
            fontSize: "1.2vw",
          }}
        >
            There is no data
        </Badge>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    params: state.steps.paramsList,
  };
};

export default connect(mapStateToProps, {
  getParamsForStepAction,
})(Params);

Params.propTypes = {
  getParamsForStepAction: PropTypes.func,
  guid: PropTypes.string,
  params: PropTypes.array,
};
