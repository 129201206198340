import { combineReducers } from "redux";
import accounts from "./accounts";
import admins from "./admins";
import audit from "./audit";
import auth from "./auth";
import blacklist from "./blacklists";
import cardtypes from "./cardtypes";
import chargebacks from "./chargebacks";
import currencies from "./currencies";
import gateways from "./gateways";
import groups from "./groups";
import history from "./history";
import logins from "./logins";
import logs from "./logs";
import merchants from "./merchants";
import modal from "./modal";
import partners from "./partners";
import privileges from "./privileges";
import rates from "./rates";
import reasons from "./reasons";
import roles from "./roles";
import search from "./search";
import services from "./services";
import shops from "./shops";
import sidebar from "./sidebar";
import statements from "./statements";
import steps from "./steps";
import store from "./store";
import terminals from "./terminals";
import transactions from "./transactions";
import useraccounts from "./useraccounts";
import users from "./users";
import additionalFees from "./additionalFees";

const rootReducer = combineReducers({
  auth,
  logins,
  merchants,
  roles,
  gateways,
  groups,
  partners,
  privileges,
  rates,
  currencies,
  services,
  shops,
  modal,
  transactions,
  blacklist,
  logs,
  search,
  sidebar,
  useraccounts,
  steps,
  cardtypes,
  history,
  accounts,
  admins,
  users,
  chargebacks,
  statements,
  reasons,
  audit,
  store,
  terminals,
  additionalFees
});

export default rootReducer;