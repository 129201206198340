import React from "react";
import { connect } from "react-redux";
import { HorizontalBarChart } from "../UI/HorizontalBarChart";
import { getMerchantLimitsDataAction } from "../../actions/transactions";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Can from "components/Can";
import { Badge, Card, Col, Row } from "react-bootstrap";
import moment from "moment";

class MerchantLimitBar extends React.Component {
  state = {
    datasets: [],
    labels: [],
    loading: true,
    width: 0,
    showAllFlag: false
  };

  componentDidMount = async () => {
    await this.props.getMerchantLimitsDataAction({
      month: moment().format("YYYY-MM-DDTHH:mm:ss"),
    });
    await this.loadData();

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {

      let response = this.state.showAllFlag ? this.props.merchantLimits.limits : this.props.merchantLimits.limits.slice(0, 5);
      let labels = [];
      let data = [];
      let backgroundColors = [];
      let borderColors = [];
      let hoverBackgroundColors = [];

      response.forEach(item => {
        labels.push(item.merchant_name);
        data.push(item.used_percent);
      });
      data.forEach((item) => {
        let red = (item / 100) * 255;
        let green = 255 - ((item / 100) * 255);
        backgroundColors.push("rgba(" + red + "," + green + ",0,0.2)");
        borderColors.push("rgba(" + red + "," + green + ",0,1)");
        hoverBackgroundColors.push("rgba(" + red + "," + green + ",0,0.4)");
      });
      let datasets = [
        {
          label: labels,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
          hoverBackgroundColor: hoverBackgroundColors,
          hoverBorderColor: borderColors,
          data: data
        }
      ];


      this.setState({ labels, datasets });
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  onChange = ({ currentTarget: input }) => {
    this.setState({ [input.name]: !this.state[input.name] }, () => this.loadData());
  };

  render() {

    return (
      <div className="admin-dashboard">
        <Row>
          <Col lg={12} md={12} sm={12}>

            <Card>
              <Card.Header as="h5" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Merchants Limits</Card.Header>
              <Card.Body>

                <input
                  type="checkbox"
                  name="showAllFlag"
                  id="showAllFlag"
                  checked={this.state.showAllFlag}
                  onChange={this.onChange}
                />
                       Show all
                {this.state.loading ? (
                  <div className="loader" style={{ height: "100px" }}>
                    <Spinner />
                  </div>
                ) : (
                  <div className="chart">
                    <Can do="REPORT" on="CURRENCIESOFTRANSACTIONS">
                      <HorizontalBarChart
                        name="merchant total"
                        labels={this.state.labels}
                        datasets={this.state.datasets}
                        options={{
                          legend: {
                            display: false
                          },
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem) {
                                return tooltipItem.xLabel + "%";
                              }
                            }
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                  max: 100
                                },
                              },
                            ],
                          },
                        }
                        }
                        height={50}
                      />
                    </Can>
                    <Can not do="REPORT" on="CURRENCIESOFTRANSACTIONS">
                      {this.state.width > 1200 ? (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(45%)",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                            No privileges
                        </Badge>
                      ) : this.state.width > 767 ? (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(45%)",
                            marginTop: "30px",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                            No privileges
                        </Badge>
                      ) : (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(45%)",
                            marginTop: "30px",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                                No privileges
                        </Badge>
                      )}
                    </Can>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

        </Row>


      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    merchantLimits: state.transactions.merchantLimits,
  };
};

export default connect(mapStateToProps, {
  getMerchantLimitsDataAction,
})(MerchantLimitBar);

MerchantLimitBar.propTypes = {
  daily: PropTypes.string,
  getMerchantLimitsDataAction: PropTypes.func,
  loading: PropTypes.bool,
  topMerchants: PropTypes.array,
};
