import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Content extends Component {
  state = {
    location: "",
  };

  render() {
    return (
      <div className="content" {...this.props}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.history.history,
});

export default withRouter(
  connect(mapStateToProps, {})(Content)
);

Content.propTypes = {
  children: PropTypes.PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
};
