import * as types from "../constants/actionTypes";

const initialState = {
  terminals: [],
  count: -1,
  terminalsLoading: false
};

export default function terminals(state = initialState, action) {
  switch (action.type) {
    case types.GET_TERMINALS:
      return {
        ...state,
        terminalsLoading: true,
        error: false
      };
    case types.GET_TERMINALS_SUCCEED:
      return {
        ...state,
        terminals: action.data.data,
        count: action.data.count,
        terminalsLoading: false,
        error: false,
      };
    case types.GET_TERMINALS_FAILED:
      return {
        ...state,
        terminalsLoading: false,
        error: true
      };

    default:
      return state;
  }
}
