import React from "react";
import TransactionAndCommissionReportMain from "components/Report/TransactionAndCommission/Main";
import Content from "../Content";

const TransactionAndCommissionReport = () => {
  return (
    <Content>
      <TransactionAndCommissionReportMain />
    </Content>
  );
};
export default TransactionAndCommissionReport;
