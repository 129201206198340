import React, { Component } from "react";
import { connect } from "react-redux";
import { changeStatementStatusAction } from "actions/statements";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";

import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import swal from "sweetalert";
import Alert from "../UI/Alert";

class StatementEditor extends Component {
  async componentDidMount() {
    const status = this.props.status;
    const guid = this.props.guid;
    let changedStatus = "";
    switch (status) {
      case "Created":
        changedStatus = "Verified";
        break;
      case "Verified":
        changedStatus = "Payment Due";
        break;
      case "Payment Due":
        changedStatus = "Paid";
        break;
      default:
        changedStatus = "";
        break;
    }
    if (changedStatus) {
      try {
        await this.props.changeStatementStatusAction({
          statement_guid: guid,
          status: changedStatus,
        });
        swal({
          title: "Status is updated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
        this.props.handleClose();
      }
    } else {
      this.props.handleClose();
    }
  }
  render() {
    return <Spinner />;
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  changeStatementStatusAction,
  showModal,
})(StatementEditor);

StatementEditor.propTypes = {
  changeStatementStatusAction: PropTypes.func,
  guid: PropTypes.string,
  showModal: PropTypes.func,
  status: PropTypes.string,
};
