import { getOrderAction } from "actions/transactions";
import Modal from "components/UI/Modal";
import Spinner from "components/UI/Spinner";
import TableCustom from "components/UI/Table/index";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Content from "views/Content";
import ability from "../../config/ability";
import BlackListCreator from "./BLCreator";
import FormForOrders from "../Transaction/Form/FormForOrders";
import BLButton from "./BLButton";

class OrderDetail extends Component {
  state = {
    isAdmin: localStorage.getItem("isAdmin")
  };

  async componentDidMount() {
    await this.updateOrder();
  }

  updateOrder = async () => {
    await this.props.getOrderAction(this.props.match.params.id);
  };

  columnsChildTransactions = [
    this.state.isAdmin === "true"
      ? {
          path: "guid",
          label: "Guid",
          content: order => (
            <Link className="link" to={`/about/processing/${order.guid}`}>
              {order.guid}
            </Link>
          )
        }
      : {
          path: "guid",
          label: "Guid"
        },
    { path: "type", label: "Type" },
    {
      path: "status",
      label: "Status",
      content: order =>
        order.status === "Success" ? (
          <span style={{ color: "green" }}>Success</span>
        ) : order.status === "Failed" ? (
          <span style={{ color: "red" }}>Failed</span>
        ) : (
          <span style={{ color: "orange" }}>{order.status}</span>
        )
    },
    {
      path: "amount",
      label: "Amount",
      content: order => order.amount
    },
    {
      path: "created_at",
      label: "Created at",
      content: order =>
        moment(order.created_at).utcOffset(0).format("DD.MM.YYYY HH:mm")
    },
    { path: "created_by", label: "Created by" },
    ability.can("LOG", "TRANSACTION") && {
      label: "Action",
      key: "order_action",
      content: order => (
        <Link
          className="btn btn-primary"
          to={{
            pathname: "/logs/transactions",
            state: { orderGuid: order.guid }
          }}
        >
          View logs
        </Link>
      )
    }
  ];

  renderButton = () => {
    const order = this.props.order;
    let sumOfChild;
    if (
      order.child_transactions &&
      order.child_transactions.length &&
      order.transaction_details.payment_status === "refunded"
    ) {
      sumOfChild = this.props.order.child_transactions
        .map(value => value.amount)
        .reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        }, 0);
    }
    if (
      (order.transaction_details.type === "Payment" || "Authorization") &&
      (order.transaction_details.payment_status === "paid" ||
        (order.transaction_details.payment_status === "refunded" &&
          parseFloat(this.props.order.transaction_details.amount) > sumOfChild))
    )
      return (
        <Modal
          header="Refund transaction"
          content={FormForOrders}
          contentProps={{
            amount: order.transaction_details.amount,
            guid: this.props.match.params.id,
            terminalGuid: order.transaction_details.terminal_guid,
            shopGuid: order.transaction_details.shop_guid,
            type: "Refund",
            updateOrder: this.updateOrder
          }}
          button={
            <Button
              type="button"
              className="btn btn-primary"
              style={{ marginRight: "10px" }}
            >
              Make Refund
            </Button>
          }
        />
      );
    else if (
      order.transaction_details.type === "Authorization" &&
      order.transaction_details.payment_status === "unpaid"
    )
      return (
        <div style={{ display: "flex" }}>
          <div>
            <Modal
              header="Capture transaction"
              content={FormForOrders}
              contentProps={{
                amount: order.transaction_details.amount,
                guid: this.props.match.params.id,
                terminalGuid: order.transaction_details.terminal_guid,
                shopGuid: order.transaction_details.shop_guid,
                type: "Capture",
                updateOrder: this.updateOrder
              }}
              button={
                <Button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginRight: "10px" }}
                >
                  Make Capture
                </Button>
              }
            />
          </div>
          <div>
            <Modal
              header="Cancel transaction"
              content={FormForOrders}
              contentProps={{
                amount: order.transaction_details.amount,
                guid: this.props.match.params.id,
                terminalGuid: order.transaction_details.terminal_guid,
                shopGuid: order.transaction_details.shop_guid,
                type: "Cancel",
                updateOrder: this.updateOrder
              }}
              button={
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginRight: "10px" }}
                >
                  Make Cancel
                </button>
              }
            />
          </div>
        </div>
      );
    else return null;
  };

  render() {
    const order = this.props.order;
    const amount = order.transaction_details.amount;
    const renderBtn = this.renderButton();
    const orderGuid = order.transaction_details.guid;
    const ipFlagClasses = order.billing_address.ip_country_code
      ? ` flag ${order.billing_address.ip_country_code.toLowerCase()}`
      : "";
    const binFlagClasses = order.customer_details.bin_country
      ? ` flag ${order.customer_details.bin_country.toLowerCase()}`
      : "";
    const countryFlagClasses = order.billing_address.country
      ? ` flag ${order.billing_address.country.toLowerCase()}`
      : "";
    const { isAdmin } = this.state;
    if (this.props.orderLoading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content>
          <Row>
            <Col md={7}>
              <Card>
                <Card.Header as="h5">
                  Transaction guid: {this.props.match.params.id}
                </Card.Header>
                <Card.Body>
                  <Card.Title>Payment info</Card.Title>
                  <Table
                    responsive
                    className="detailInfo"
                    style={{ width: "80%" }}
                  >
                    <tbody>
                      <tr>
                        <th>Type:</th>
                        <td>{order.transaction_details.type}</td>
                      </tr>
                      <tr>
                        <th>Test transaction:</th>
                        <td>{order.transaction_details.test.toString()}</td>
                      </tr>
                      {order.transaction_details.three_d ? (
                        <tr>
                          <th>3D:</th>
                          <td>
                            {order.transaction_details.three_d.toString()}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <th>Status:</th>
                        <td>{order.transaction_details.status}</td>
                      </tr>
                      {order.transaction_details.status_code ? (
                        <tr>
                          <th>Error:</th>
                          <td>
                            {order.transaction_details.status_code +
                              " " +
                              order.transaction_details.status_message}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <th>Payment status:</th>
                        <td>
                          <Badge
                            className={`badge-order-${order.transaction_details.payment_status}`}
                          >
                            {order.transaction_details.payment_status}
                          </Badge>
                        </td>
                      </tr>
                      <tr>
                        <th>TrackingID:</th>
                        <td>{order.transaction_details.tracking_id}</td>
                      </tr>
                      <tr>
                        <th>Date:</th>
                        <td>
                          {moment(order.transaction_details.date)
                            .utcOffset(0)
                            .format("D MMM YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <th>Amount:</th>
                        <td>{amount}</td>
                      </tr>
                      <tr>
                        <th>Currency:</th>
                        <td>{order.transaction_details.currency}</td>
                      </tr>
                      <tr>
                        <th>Merchant:</th>
                        <td>
                          <Link
                            className="link"
                            to={`/about/merchant/${order.transaction_details.merchant_guid}`}
                          >
                            {order.transaction_details.merchant_name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>Shop:</th>
                        <td>
                          <Link
                            className="link"
                            to={`/about/shop/${order.transaction_details.shop_guid}`}
                          >
                            {order.transaction_details.shop_name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>Terminal:</th>
                        <td>
                          <Link
                            className="link"
                            to={`/about/${order.transaction_details.shop_guid}/terminal/${order.transaction_details.terminal_guid}`}
                          >
                            {order.transaction_details.terminal_name}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>Description:</th>
                        <td>{order.transaction_details.description}</td>
                      </tr>
                      {order.transaction_details.type === "Recurring" && (
                        <tr>
                          <th>Recurring:</th>
                          <td>
                            {order.transaction_details.recurring
                              ? "true"
                              : "false"}
                          </td>
                        </tr>
                      )}
                      {order.transaction_details.type === "Recurring" && (
                        <tr>
                          <th>Recurring:</th>
                          <td>
                            {order.transaction_details.recurring_period_length}
                            {order.transaction_details.recurring_period_unit}
                          </td>
                        </tr>
                      )}
                      {order.transaction_details.hold_date && (
                        <tr>
                          <th>Hold release date:</th>
                          <td>
                            {moment(order.transaction_details.hold_date)
                              .utcOffset(0)
                              .format("D MMM YYYY")}
                          </td>
                        </tr>
                      )}

                      {order.transaction_details.bank_id && (
                        <tr>
                          <th>Bank ID:</th>
                          <td>{order.transaction_details.bank_id}</td>
                        </tr>
                      )}
                      {order.transaction_details.arn && (
                        <tr>
                          <th>ARN:</th>
                          <td>{order.transaction_details.arn}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div style={{ display: "flex" }}>
                    <div>{renderBtn}</div>
                    {ability.can("LOG", "TRANSACTION") && (
                      <div>
                        {isAdmin === "true" ? (
                          <Link
                            className="btn btn-primary"
                            to={{
                              pathname: "/logs/transactions",
                              state: { orderGuid }
                            }}
                            style={{ marginRight: "10px" }}
                          >
                            View logs
                          </Link>
                        ) : (
                          <Link
                            className="btn btn-primary"
                            to={{ pathname: "/logs", state: { orderGuid } }}
                            style={{ marginRight: "10px" }}
                          >
                            View logs
                          </Link>
                        )}
                      </div>
                    )}

                    {ability.can("EXECUTE", "BLACKLISTS") && (
                      <div>
                        <Modal
                          header="Create rule"
                          content={BlackListCreator}
                          button={
                            <button type="button" className="btn btn-primary">
                              Add to Blacklist
                            </button>
                          }
                        />
                      </div>
                    )}
                  </div>

                  {/* {ability.can("LOG", "TRANSACTION") && (
                    <>
                      {isAdmin === "true" ? (
                        <Link
                          className="btn btn-primary"
                          to={{
                            pathname: "/logs/transactions",
                            state: { orderGuid },
                          }}
                          style={{ marginRight: "10px" }}
                        >
                          View logs
                        </Link>
                      ) : (
                        <Link
                          className="btn btn-primary"
                          to={{ pathname: "/logs", state: { orderGuid } }}
                          style={{ marginRight: "10px" }}
                        >
                          View logs
                        </Link>
                      )}
                    </>
                  )} */}
                  {/* <Can do="EXECUTE" on="BLACKLISTS">
                    <Modal
                      header="Create rule"
                      content={BlackListCreator }
                      button={
                        <button type="button" className="btn btn-primary">
                          Add to Blacklist
                        </button>
                      }
                    />
                  </Can> */}
                </Card.Body>
              </Card>
            </Col>
            <Col md={5}>
              <Card>
                <Card.Body>
                  <Card.Title>Customer details</Card.Title>

                  <Table responsive className="detailInfo">
                    <tbody>
                      <tr>
                        <th>Card holder:</th>
                        <td>{order.customer_details.card_holder}</td>
                        <td>
                          {/* <BLButton
                            value={order.customer_details.card_number}
                            type="mask"
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <th>Card number:</th>
                        <td>{order.customer_details.card_number}</td>
                        <td>
                          <BLButton
                            value={order.customer_details.card_number}
                            type="mask"
                            name={`order-tr-GUID-${order.transaction_details.guid}-mask`}
                            merchant_guid={
                              order.transaction_details.merchant_guid
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Card schema:</th>
                        <td>{order.customer_details.card_schema}</td>
                      </tr>
                      <tr>
                        <th>Card type:</th>
                        <td>{order.customer_details.card_type}</td>
                      </tr>
                      <tr>
                        <th>Euro card:</th>
                        <td>{order.customer_details.card_eu}</td>
                      </tr>
                      <tr>
                        <th>Email:</th>
                        <td>{order.customer_details.email}</td>
                        <td>
                          <BLButton
                            value={order.customer_details.email}
                            type="email"
                            name={`order-tr-GUID-${order.transaction_details.guid}-email`}
                            merchant_guid={
                              order.transaction_details.merchant_guid
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Phone:</th>
                        <td>{order.customer_details.phone}</td>
                      </tr>
                      <tr>
                        <th>IP:</th>
                        <td>
                          <Row>
                            <Col>
                              {order.customer_details.ip}(
                              {order.billing_address.ip_country_code})
                            </Col>
                            <Col className="react-tel-input">
                              <div className={ipFlagClasses} />
                            </Col>
                          </Row>
                        </td>
                        <td>
                          <BLButton
                            value={order.customer_details.ip}
                            type="ip"
                            name={`order-tr-GUID-${order.transaction_details.guid}-ip`}
                            merchant_guid={
                              order.transaction_details.merchant_guid
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>BIN country:</th>
                        <td>
                          <Row>
                            <Col>{order.customer_details.bin_country}</Col>
                            <Col className="react-tel-input">
                              <div className={binFlagClasses} />
                            </Col>
                          </Row>
                        </td>
                        <td>
                          <BLButton
                            value={order.customer_details.bin_country}
                            type="country"
                            name={`order-tr-GUID-${order.transaction_details.guid}-country`}
                            merchant_guid={
                              order.transaction_details.merchant_guid
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Billing address</Card.Title>
                  <Table responsive className="detailInfo">
                    <tbody>
                      <tr>
                        <th>First name:</th>
                        <td>{order.billing_address.first_name}</td>
                      </tr>
                      <tr>
                        <th>Last name:</th>
                        <td>{order.billing_address.last_name}</td>
                      </tr>
                      <tr>
                        <th>Country:</th>
                        <td>
                          <Row>
                            <Col>{order.billing_address.country}</Col>
                            <Col className="react-tel-input">
                              <div className={countryFlagClasses} />
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <th>City</th>
                        <td>{order.billing_address.city}</td>
                      </tr>
                      <tr>
                        <th>Address:</th>
                        <td>{order.billing_address.address}</td>
                      </tr>
                      <tr>
                        <th>Zip:</th>
                        <td>{order.billing_address.zip}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {order.child_transactions && (
            <Card>
              <Card.Body>
                <Card.Title>Related payments</Card.Title>
                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <TableCustom
                      columns={this.columnsChildTransactions}
                      data={order.child_transactions}
                      disableSearch
                      disableSort
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Content>
      );
  }
}

const mapStateToProps = state => {
  return {
    order: state.transactions.order,
    orderLoading: state.transactions.orderLoading
  };
};

export default connect(mapStateToProps, { getOrderAction })(OrderDetail);

OrderDetail.propTypes = {
  getOrderAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  order: PropTypes.object.isRequired,
  orderLoading: PropTypes.bool
};
