import * as types from "../constants/actionTypes";

const initialState = {
  typesReport: [],
  loading: false,
  error: false,
};

export default function cardtypes(state = initialState, action) {
  switch (action.type) {
    case types.GET_TRANSACTION_CARD_TYPES:
      return { 
        ...state, 
        loading: true 
      };
    case types.GET_TRANSACTION_CARD_TYPES_SUCCEED:
      return { 
        ...state, 
        typesReport: action.data,
        loading: false 
      };
    case types.GET_TRANSACTION_CARD_TYPES_FAILED:
      return { 
        ...state, 
        loading: false 
      };
    default:
      return state;
  }
}