import { backendManagement } from "../backendPlatform";

export const getPartners = (args) => {
  return backendManagement.get("users/partners", { params: { ...args } });
};

export const createPartner = (data) => {
  return backendManagement.post("users/partners", data);
};
// with guid
export const updatePartner = data => {
  return backendManagement.post("users/partners", data);
};

export const getPartner = guid => {
  return backendManagement.get(`users/partners/${guid}`);
};
// data = {guid, delete}
export const deletePartner = ({ guid, reason }) => {
  return backendManagement.post("users/partners", { partner_guid: guid, delete: true, reason });
};

export const getPartnerLogins = (guid, page, items) => {
  return backendManagement.get(`/partners/${guid}/logins`, { params: { items, page } });
};

export const getPartnerGroups = (guid, page, items) => {
  return backendManagement.get(`/partners/${guid}/groups`, { params: { items, page } });
};

export const addPartnerLogin = (guid, data) => {
  return backendManagement.post(`/partners/${guid}/logins`, data);
};