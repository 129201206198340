import React from "react";
import { connect } from "react-redux";
import logo from "assets/img/logo_short.png";
import PropTypes from "prop-types";
import SidebarHideButton from "../HideButton";
import { NavLink } from "react-router-dom";

const SidebarHeader = ({ isHide }) => {
  return (
    <>
      <div
        className={isHide ? "logo slideOut" : "logo slideIn"}
        style={{ position: "absolute" }}
      >
        <NavLink to="/dashboard" className="simple-text logo-mini">
          <div
            id="sidebar-header-logo"
            className={isHide ? "logo-img slideOut" : "logo-img slideIn"}
          >
            <img src={logo} alt="logo" />
          </div>
        </NavLink>
        {/* <div id="sidebar-text-wrapper">
        <span
          id="sidebar-header-text"
          className={isHide ? "simple-text logo-normal slideOut" : "simple-text logo-normal slideIn"}
        >
                Base Banking Platform
        </span>
      </div> */}
      </div>
      <SidebarHideButton />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isHide: state.sidebar.isHide,
  };
};

export default connect(mapStateToProps, null)(SidebarHeader);

SidebarHeader.propTypes = {
  isHide: PropTypes.bool,
};
