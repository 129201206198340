import { onCollapseItemClick } from "actions/sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import SidebarCollapseItemChild from "./Child";

const SidebarCollapseItem = ({
  item,
  key,
  isHide,
  onCollapseItemClick,
  collapseItemState,
  location,
}) => {
  const hideClass = isHide ? " hover-open " : " collapsed-menu ";
  const [ dropdownOpen, setOpen ] = useState(false);
  const [ activeChildComponent, setActive ] = useState(false);

  const toggle = () => {
    if (isHide) setOpen(!dropdownOpen);
  };

  const mouseOver = () => setOpen(true);
  const mouseOut = () => setOpen(false);

  useEffect(() => {
    let urlsString = "";
    item.views.forEach((child) => {
      urlsString += "#" + child.path;
    });
    if (urlsString.includes(location)) setActive(true);
    else setActive(false);
  });

  let active = dropdownOpen || activeChildComponent ? " active " : "";
  let open = item.state === collapseItemState ? " open" : "";
  let hide = isHide ? " slideOut " : " slideIn ";
  return (
    <li key={key} className={"nav-item " + hideClass + active}>
      <Dropdown
        onMouseOver={mouseOver}
        onMouseLeave={mouseOut}
        direction="right"
        isOpen={isHide ? dropdownOpen : false}
        toggle={toggle}
        onClick={() => !isHide && onCollapseItemClick(item.state)}
        aria-controls={item.state}
        aria-expanded={item.path === collapseItemState}
      >
        <DropdownToggle
          className={
            isHide ? "nav-link slideOut " + active : "nav-link slideIn" + active
          }
        >
          <i
            className={
              isHide
                ? item.icon + " menu-icon slideOut"
                : item.icon + " menu-icon slideIn"
            }
          />
          <span
            className={isHide ? "menu-title slideOut" : "menu-title slideIn"}
          >
            {item.name}
          </span>
          <i className={"fas fa-angle-right caret" + open + hide} />
        </DropdownToggle>
        <DropdownMenu
          tag="ul"
          className="nav-menu"
          id={item.state}
          style={{ top: "0px", left: "75px" }}
        >
          <li className={"nav-item menu-header"} key={key} disabled={true}>
            <p className="menu-title">{item.name}</p>
          </li>
          {item.views.map((child, key) => {
            return <SidebarCollapseItemChild key={key} child={child} />;
          })}
        </DropdownMenu>
      </Dropdown>
      <Collapse in={item.state === collapseItemState}>
        <ul
          className="nav-menu"
          id={item.state}
          // style={{ marginTop: "-5px" }}
        >
          {item.views.map((child, key) => {
            return <SidebarCollapseItemChild key={key} child={child} />;
          })}
        </ul>
      </Collapse>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    isHide: state.sidebar.isHide,
    collapseItemState: state.sidebar.collapseItemState,
    location: window.location.hash,
  };
};

export default connect(mapStateToProps, {
  onCollapseItemClick,
})(SidebarCollapseItem);

SidebarCollapseItem.propTypes = {
  collapseItemState: PropTypes.string,
  isHide: PropTypes.bool,
  item: PropTypes.object,
  key: PropTypes.string,
  location: PropTypes.string,
  onCollapseItemClick: PropTypes.func,
};
