import moment from "moment";
import { connect } from "react-redux";
import { getAuditCurrencyTablesAction } from "../../../actions/audit";
import { reset, searchInCurrenciesAuditTable } from "../../../actions/search";
import { resetPage, setNewTable } from "../../../actions/store";
import AbstractComponent from "../../../factories/Main";
import { cutGuid } from "../../../helpers/cutGuid";
import { getAuditCurrencyTables } from "../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../UI/CopyToClipboard";
import React from "react";

const columns = [
  {
    path: "guid",
    label: "Guid",
    key: "guid_audit",
    content: (el) => (
      <>
        {cutGuid(el.guid)}
        <Copy text={el.guid} />
      </>
    ),
  },
  { path: "name", label: "Name", key: "name" },
  { path: "code", label: "Code", key: "code", align: "center" },
  { path: "number", label: "Number", key: "number" },
  {
    path: "exchange_markup_percent",
    label: "Exchange markup percent",
    key: "exchange_markup_percentAudit",
    align: "right",
  },
  {
    path: "rate_to_eur",
    label: "Rate (EUR)",
    key: "rate_to_eurAudit",
    align: "right",
  },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (el) =>
      moment(el.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.currencyTables,
    count: state.audit.currencyTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.currencyAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_currency",
    keyPath: "guid",
    columns,
    exportFunction: getAuditCurrencyTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditCurrencyTablesAction,
  search: searchInCurrenciesAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
