import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import { getAllMerchants, getMerchantAction } from "../../actions/merchants";
import { getShopAction, editShopAction } from "../../actions/shops";
import { showModal } from "../../actions/modal";
import { parseResponse } from "helpers/parseResponse";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import MultiSelect from "components/UI/MultiSelect";
import Joi from "joi-browser";
import PhoneInput from "../UI/PhoneInput";
import CreatableMultiSelect from "components/UI/CreatableSelect";
import Alert from "../UI/Alert";

class ShopEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        risk_category: "",
        merchantGuid: "",
        display_name: "",
        email: "",
        url: "",
        phone: "",
        note: "",
        enabled: false,
      },
      oldData: {
        name: "",
        risk_category: "",
        merchantGuid: "",
        email: "",
        url: "",
        phone: "",
        note: "",
        display_name: "",
        enabled: false,
      },
      merchants: [],
      merchant: "",
      isMerchant: false,
      isLoading: false,
      loading: false,
      errors: {},
      needReason: false,
      reason: "",

      nameValidation: false,
      risk_categoryValidation: false,
      merchantGuidValidation: false,
      urlValidation: false,
      emailValidation: false,
      phoneValidation: false,
    };
  }

  schema = {
    name: Joi.string().required().label("Shop name"),
    risk_category: Joi.string().required().label("Risk"),
    merchantGuid: Joi.string().required().label("Merchant"),
    url: Joi.array()
      .items(Joi.string().uri().not().empty().required().label("Url"))
      .required()
      .label("Url"),
    email: Joi.string().required().email().label("Email"),
    phone: Joi.string().required().min(5).label("Phone"),
    note: Joi.any(),
    enabled: Joi.boolean().required(),
    display_name: Joi.any(),
  };

  risks = [
    { guid: 1, name: "Low" },
    { guid: 2, name: "Medium" },
    { guid: 3, name: "High" },
  ];

  async componentDidMount() {
    this.setState({ loading: true });
    const token = getAuthData();
    if (token.userPayload.merchant) this.setState({ isMerchant: true });
    await this.props.getShopAction(this.props.guid);

    const shop = this.props.shop;

    if (!this.state.isMerchant) {
      await this.props.getAllMerchants();
      const merchants = this.props.merchants.map((item) => {
        item.name = item.merchant_name;
        item.guid = item.merchant_guid;
        return item;
      });
      let merchant = merchants.filter(
        (option) => option.guid === shop.merchant_guid
      )[0];
      this.setState({
        merchants,
        merchant,
      });
    }
    let arr = shop.url.map((url) => {
      return { name: url, label: url, value: url };
    });

    this.setState({
      data: {
        name: shop.name,
        risk_category: this.risks.filter(
          (value) => shop.risk_category === value.name
        )[0],
        email: shop.email,
        url: arr,
        phone: shop.phone,
        note: shop.note,
        enabled: shop.enabled,
        merchantGuid: shop.merchant_guid,
        display_name: shop.display_name,
      },
      oldData: {
        name: shop.name,
        risk_category: this.risks.filter(
          (value) => shop.risk_category === value.name
        )[0],
        email: shop.email,
        url: arr,
        phone: shop.phone,
        note: shop.note,
        enabled: shop.enabled,
        merchantGuid: shop.merchant_guid,
        display_name: shop.display_name,
      },
      loading: false,

      nameValidation: shop.name ? true : false,
      risk_categoryValidation: shop.risk_category ? true : false,
      merchantGuidValidation: shop.merchant_guid ? true : false,
      urlValidation: shop.url ? true : false,
      emailValidation: shop.email ? true : false,
      phoneValidation: shop.phone ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};

    if (this.state.needReason) {
      data = {
        ...this.state.data,
        risk_category: this.state.data.risk_category.name,
        reason: this.state.reason,
      };
      let arr = this.state.data.url;
      let url = arr.map((option) => {
        return option.label;
      });
      data.url = url;
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        ...this.state.data,
        risk_category: this.state.data.risk_category.name,
      };
      let arr = this.state.data.url;
      let url = arr.map((option) => {
        return option.label;
      });
      data.url = url;
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  spliceMerchantsArray = () => {
    const merchants = this.state.merchants;
    const names = merchants.map((merchant) => {
      return merchant.name;
    });
    const index = names.indexOf(this.state.merchant.name);
    merchants.splice(index, 1);
    this.setState({ merchants });
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state.data[name] === "") return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    let data = { ...this.state.data };
    let changedData = false;
    if (this.state.isMerchant) {
      data.merchantGuid = this.props.shop.merchant_guid;
    } else {
      data.merchantGuid = this.state.merchant.guid;
    }
    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleSelectChange = (option) => {
    this.setState(
      {
        merchant: option,
        merchantGuid: option.guid,
      },
      () => this.showReason()
    );
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name: "phone", value: value });
    if (errorMessage) errors.phone = errorMessage;
    else delete errors.phone;

    let data = { ...this.state.data };
    data.phone = value;
    this.setState(
      { data, errors, phoneValidation: errorMessage ? false : true },
      () => this.showReason()
    );
  };

  // handleSelectChangeRisk = (e) => {
  //   let data = { ...this.state.data };
  //   data[e.target.name] = e.target.value;
  //   this.setState(
  //     {
  //       data,
  //     },
  //     () => this.showReason()
  //   );
  // };

  onSelectRisk = (option) => {
    const errors = { ...this.state.errors };
    delete errors.risk_category;

    let data = this.state.data;
    data.risk_category = option;
    this.setState(
      {
        data,
        errors,
      },
      () => this.showReason()
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    let data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState(
      {
        data,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleEnabledCheckbox = () => {
    let data = { ...this.state.data };
    data.enabled = !this.state.data.enabled;
    this.setState(
      {
        data,
      },
      () => this.showReason()
    );
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleChangeSelect = (options) => {
    const errors = { ...this.state.errors };
    let value = options.map((item) => {
      return item.label;
    });
    const errorMessage = this.validateProperty({ name: "url", value: value });
    if (errorMessage) errors.url = errorMessage;
    else delete errors.url;

    let data = this.state.data;
    data.url = options;
    this.setState(
      {
        data,
        errors,
      },
      () => this.showReason()
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });
          const data = this.state.data;
          let arr = this.state.data.url;
          let url = arr.map((option) => {
            return option.label;
          });

          const req = {
            guid: this.props.guid,
            name: data.name,
            risk_category: data.risk_category.name,
            merchant_guid: this.state.isMerchant
              ? this.props.shop.merchant_guid
              : this.state.merchant.guid,
            email: data.email,
            url: url,
            phone: data.phone,
            note: data.note ? data.note : "",
            display_name: data.display_name ? data.display_name : "",
            enabled: data.enabled ? 1 : 0,
            reason: this.state.needReason ? this.state.reason : undefined,
          };
          await this.props.editShopAction(req);
          this.setState({ data });
          swal({
            title: "Shop is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;
    const merchants = this.state.merchants;

    if (this.props.shopLoading || this.state.loading) return <Spinner />;
    else
      return (
        <Form autoComplete="off">
          <Row>
            <Col className="col-md-3">
              <Form.Label>Name*:</Form.Label>
            </Col>
            <Col className="col-md-9">
              <Form.Group validationState={this.formValidation("name")}>
                <Form.Control
                  name="name"
                  type="text"
                  value={this.state.data.name}
                  onChange={this.handleChange}
                />
                {errors.name && (
                  <span className="validate-error">{errors.name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="col-md-3">
              <Form.Label>
                Display name
                <i className="far fa-question-circle help-tip">
                  <p>Display name on payment page</p>
                </i>
                :
              </Form.Label>
            </Col>
            <Col className="col-md-9">
              <Form.Group validationState={this.formValidation("display_name")}>
                <Form.Control
                  name="display_name"
                  type="text"
                  value={this.state.data.display_name}
                  onChange={this.handleChange}
                />
                {errors.display_name && (
                  <span className="validate-error">{errors.display_name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="col-md-3">
              <Form.Label>Risk category*:</Form.Label>
            </Col>
            <Col className="col-md-9">
              <Form.Group>
                <MultiSelect
                  name="risk"
                  options={this.risks}
                  multi={false}
                  onSelect={this.onSelectRisk}
                  value={this.state.data.risk_category}
                />
                {/* <Form.Control
                  name="risk_category"
                  componentClass="select"
                  onChange={(e) => this.handleSelectChangeRisk(e)}
                  as="select"
                >
                  <option value={this.state.data.risk_category}>
                    {this.state.data.risk_category}
                  </option>
                  {risks
                    .filter((risk) => risk.name !== this.state.data.risk_category)
                    .map((risk) => {
                      return (
                        <option key={risk.name} value={risk.name}>
                          {risk.name}
                        </option>
                      );
                    })}
                </Form.Control> */}
                {errors.risk_category && (
                  <span className="validate-error">{errors.risk_category}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="col-md-3">
              <label>Enable</label>
            </Col>
            <Col className="col-md-6">
              <input
                type="checkbox"
                id="enabledCheckbox"
                checked={this.state.data.enabled}
                onChange={this.handleEnabledCheckbox}
              />
            </Col>
          </Row>

          {!this.state.isMerchant && (
            <Row>
              <Col className="col-md-3">
                <Form.Label>Merchant*:</Form.Label>
              </Col>
              <Col className="col-md-9">
                <Form.Group>
                  <MultiSelect
                    name="Merchant"
                    options={merchants ? merchants : []}
                    multi={false}
                    onSelect={this.handleSelectChange}
                    defaultValue={this.state.merchant}
                    // styles={{
                    //   menu: (styles) => ({ ...styles, zIndex: 999 }),
                    // }}
                  />
                  {errors.merchantGuid && (
                    <span className="validate-error">
                      {errors.merchantGuid}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-md-3">
              <Form.Label>URL*:</Form.Label>
              <i className="far fa-question-circle help-tip">
                <p>
                  Select options or type to create new option. Example URL:
                  http://your_url.com/
                </p>
              </i>
            </Col>
            <Col className="col-md-9">
              <Form.Group validationState={this.formValidation("url")}>
                <CreatableMultiSelect
                  name="url"
                  options={this.state.data.url ? this.state.data.url : []}
                  value={this.state.data.url ? this.state.data.url : []}
                  handleChange={this.handleChangeSelect}
                />
                {errors.url && (
                  <span className="validate-error">{errors.url}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-3">
              <Form.Label>Email*:</Form.Label>
            </Col>
            <Col className="col-md-9">
              <Form.Group validationState={this.formValidation("email")}>
                <Form.Control
                  name="email"
                  type="text"
                  value={this.state.data.email}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.email && (
                  <span className="validate-error">{errors.email}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-3">
              <Form.Label>Phone*:</Form.Label>
            </Col>
            <Col className="col-md-9">
              <Form.Group validationState={this.formValidation("phone")}>
                <PhoneInput
                  name="phone"
                  placeholder="Enter phone number"
                  value={this.state.data.phone}
                  onChange={(e) => this.onChangeNumber(e)}
                />
                {errors.phone && (
                  <span className="validate-error">{errors.phone}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-3">
              <Form.Label>Note:</Form.Label>
            </Col>
            <Col className="col-md-9">
              <Form.Group>
                <Form.Control
                  name="note"
                  type="text"
                  value={this.state.data.note}
                  onChange={(e) => this.handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>

          {this.state.needReason && (
            <Row>
              <Col className="col-md-3">
                <Form.Label>Reason</Form.Label>
              </Col>
              <Col className="col-md-9">
                <Form.Group>
                  <Form.Control
                    placeholder="Enter reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChangeReason}
                  />
                  {errors.reason && (
                    <span className="validate-error">{errors.reason}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.nameValidation &&
                  this.state.risk_categoryValidation &&
                  this.state.merchantGuidValidation &&
                  this.state.urlValidation &&
                  this.state.emailValidation &&
                  this.state.phoneValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
                onClick={this.handleSubmit}
                style={{ margin: "10px" }}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    shop: state.shops.shop,
    merchants: state.merchants.merchantsList,
    merchant: state.merchants.merchant,
    shopLoading: state.shops.shopLoading,
  };
};

export default connect(mapStateToProps, {
  getShopAction,
  editShopAction,
  getAllMerchants,
  getMerchantAction,
  showModal,
})(ShopEditor);

ShopEditor.propTypes = {
  editShopAction: PropTypes.func,
  getAllMerchants: PropTypes.func,
  getShopAction: PropTypes.func,
  guid: PropTypes.string,
  merchants: PropTypes.array,
  shop: PropTypes.object,
  shopLoading: PropTypes.bool,
  showModal: PropTypes.func,
};
