import React, { Component } from "react";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { Form, Button } from "react-bootstrap";
import {
  createItemMerchantsBlackListAction,
  getAllBlackListItems,
} from "actions/blacklists";
import { getAllMerchants } from "actions/merchants";
import { getMerchantShopsAction, getShopTerminalsAction } from "actions/shops";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import { Col, Row } from "react-bootstrap";
import Alert from "../../UI/Alert";
import MultiSelect from "components/UI/MultiSelect";

class MerchantsBlackListCreator extends Component {
  state = {
    data: {
      blacklist_rule_guid: "",
      merchant_guid: null,
      shop_guid: null,
      terminal_guid: null,
      type: "",
    },
    types: [
      { name: "allow", label: "allow", guid: "allow", value: "allow" },
      { name: "deny", label: "deny", guid: "deny", value: "deny" },
    ],
    errors: {},
    isLoading: false,
    token: getAuthData().userPayload,
  };

  schema = {
    blacklist_rule_guid: Joi.string().required().min(0).max(100).label("Rule"),
    merchant_guid: Joi.string().required().min(0).max(100).label("Merchant"),
    type: Joi.string().required().min(0).max(100).label("Type"),
  };

  componentDidMount = () => {
    this.props.getAllBlackListItems();
    if (!this.state.token.merchant) {
      this.props.getAllMerchants();
    } else {
      const data = {};
      data.merchant_guid = this.state.token.merchant.merchant_guid;
      this.setState({ data });
    }
  };

  nullToUndef = (val) => {
    return val == null ? undefined : val;
  };

  doSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ isLoading: true });
      let { blacklist_rule_guid, merchant_guid, shop_guid, terminal_guid, type } = this.state.data;

      blacklist_rule_guid = this.nullToUndef(blacklist_rule_guid);
      merchant_guid = this.nullToUndef(merchant_guid);
      shop_guid =  this.nullToUndef(shop_guid);
      terminal_guid = this.nullToUndef(terminal_guid);

      await this.props.createItemMerchantsBlackListAction(
        { blacklist_rule_guid, merchant_guid, shop_guid, terminal_guid, type },
        this.props.currentPage,
        this.props.pageSize
      );
      swal({
        title: "Record is created",
        icon: "success",
        button: false,
        timer: 2000,
      });
      await this.props.handleClose();
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  onSelect = (option, name) => {
    let data = { ...this.state.data };
    data[name] = option.guid;
    this.setState({
      data,
    });
  };

  onMerchantSelect = async (option) => {
    let data = { ...this.state.data };
    this.setState({ isLoading: true, });
    data["merchant_guid"] = option.guid;
    data["shop_guid"] = null;
    data["terminal_guid"] = null;
    await this.props.getMerchantShopsAction(option.guid);
    this.setState({ data, isLoading: false });
  };

  onShopSelect = async (option) => {
    let data = { ...this.state.data };
    this.setState({ isLoading: true, });
    data["shop_guid"] = option.guid;
    data["terminal_guid"] = null;
    await this.props.getShopTerminalsAction(option.guid);
    this.setState({ data, isLoading: false });
  };

  handleValueChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Rule*</Form.Label>
          </Col>
          <Col>
            <Form.Group>
              <MultiSelect
                name="blacklist_rule_guid"
                options={this.props.rules}
                multi={false}
                onSelect={(e) => this.onSelect(e, "blacklist_rule_guid")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>merchant*</Form.Label>
          </Col>
          <Col>
            <Form.Group>
              <MultiSelect
                name="merchant_guid"
                options={this.props.merchants.map((merchant) => {
                  merchant.guid = merchant.merchant_guid;
                  merchant.name = merchant.merchant_name;
                  return merchant;
                })}
                multi={false}
                onSelect={(e) => this.onMerchantSelect(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        {this.state.data.merchant_guid ?
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>shop*</Form.Label>
              </Col>
              <Col>
                <Form.Group>
                  <MultiSelect
                    name="shop_guid"
                    options={this.props.shops.map((shop) => {
                      return shop;
                    })}
                    value={this.state.data.shop_guid == null ? null : undefined }
                    multi={false}
                    onSelect={(e) => this.onShopSelect(e)}
                  />
                </Form.Group>
              </Col>
            </Row>:
          ""        
        }
        {
          this.state.data.shop_guid ?
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>terminal*</Form.Label>
                </Col>
                <Col>
                  <Form.Group>
                    <MultiSelect
                      name="terminal_guid"
                      options={this.props.terminals.map((terminal) => {
                        return terminal;
                      })}
                      value={this.state.data.terminal_guid == null ? null : undefined }
                      multi={false}
                      onSelect={(e) => this.onSelect(e, "terminal_guid")}
                    />
                  </Form.Group>
                </Col>
              </Row>:
            ""
        }
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>type*</Form.Label>
          </Col>
          <Col>
            <Form.Group>
              <MultiSelect
                name="type"
                options={this.state.types}
                multi={false}
                onSelect={(e) => this.onSelect(e, "type")}
              />
            </Form.Group>
          </Col>
        </Row>
        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className="btn btn-fill btn-success"
              type="submit"
              onClick={this.doSubmit}
            >
              Add
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {


  return {
    currentPage: state.blacklist.currentPage,
    pageSize: state.blacklist.pageSize,
    rules: state.blacklist.blackList,
    merchants: state.merchants.merchantsList,
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals
  };
};

export default connect(mapStateToProps, {
  createItemMerchantsBlackListAction,
  getAllBlackListItems,
  getAllMerchants,
  getMerchantShopsAction,
  getShopTerminalsAction,
  showModal,
})(MerchantsBlackListCreator);

MerchantsBlackListCreator.propTypes = {
  createItemMerchantsBlackListAction: PropTypes.func,
  getAllBlackListItems: PropTypes.func,
  getAllMerchants: PropTypes.func,
  showModal: PropTypes.func,
};
