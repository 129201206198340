import moment from "moment";
import { connect } from "react-redux";
import { getAuditTransactionDataTablesAction } from "../../../../actions/audit";
import {
  reset,
  searchInTransactionDataAuditTable,
} from "../../../../actions/search";
import { resetPage, setNewTable } from "../../../../actions/store";
import AbstractComponent from "../../../../factories/Main";
import { cutGuid } from "../../../../helpers/cutGuid";
import { getAuditTransactionDataTables } from "../../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../../UI/CopyToClipboard";
import React from "react";

const columns = [
  {
    path: "transaction_processing_guid",
    label: "Transaction processing guid",
    key: "transaction_processing_guid",
    content: (el) => {
      if (el.transaction_processing_guid) {
        return (
          <>
            <Copy text={el.transaction_processing_guid} />
            {cutGuid(el.transaction_processing_guid)}
          </>
        );
      } else return null;
    },
    style: { minWidth: "110px" },
  },
  //=
  { path: "tracking_id", label: "Tracking ID", key: "tracking_id" },
  { path: "amount", label: "Amount", key: "amount_audit", align: "right" },
  {
    path: "currency",
    label: "Currency",
    key: "currency_audit",
    align: "center",
  },
  { path: "card_eu", label: "EU card", key: "card_eu_audit" },
  { path: "card_type", label: "Card type", key: "card_type_audit" },
  { path: "card_schema", label: "Card schema", key: "card_schema_audit" },
  { path: "card_holder", label: "Holder", key: "card_holder_audit" },
  { path: "card_number", label: "Card number", key: "card_number_audit" },
  {
    label: "Expire date",
    key: "exp_month_year_audit",
    content: (transaction) =>
      transaction.exp_month + "/" + transaction.exp_year,
  },
  { path: "bin_country", label: "Bin country", key: "bin_country_audit" },
  { path: "description", label: "Description", key: "description" },
  { path: "first_name", label: "First name", key: "first_name" },
  { path: "last_name", label: "Last name", key: "last_name" },
  { path: "email", label: "Email", key: "email_audit" },
  { path: "phone", label: "Phone", key: "phone_audit" },
  { path: "ip", label: "IP", key: "ip_audit" },
  { path: "country", label: "Country", key: "country_audit" },
  { path: "city", label: "City", key: "city_audit" },
  { path: "address", label: "Address", key: "address_audit" },
  { path: "zip", label: "Zip", key: "zip_audit" },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (transaction) =>
      moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.transactionDataTables,
    count: state.audit.transactionDataTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.transactionDataAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_transaction_data",
    keyPath: "guid",
    columns,
    exportFunction: getAuditTransactionDataTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditTransactionDataTablesAction,
  search: searchInTransactionDataAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
