import React from "react";
import Content from "../Content";
import AdminLogsMain from "components/Log/Admin/Main";

const AdminsLogs = () => {
  return (
    <Content>
      <AdminLogsMain />
    </Content>
  );
};

export default AdminsLogs;