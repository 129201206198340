import { backendManagement } from "../backendPlatform";

export const getTransactions = (args) => {
  return backendManagement.get("/transactions/processing", { params: { ...args } });
};

export const getTransaction = (guid) => {
  return backendManagement.get(`/transactions/processing/${guid}`);
};

export const updateTransaction = (data) => {
  return backendManagement.post("/transactions/processing", data);
};

export const activeTransaction = (data) => {
  return backendManagement.post("/transactions/processing/active", data);
};

export const getTransactionRates = (guid) => {
  return backendManagement.get(`/transactions/processing/${guid}/rates`);
};

export const getTransactionTemplates = (args) => {
  return backendManagement.get("/transactions", { params: { ...args } });
};

export const getTransactionTemplate = guid => {
  return backendManagement.get(`/transactions/${guid}`);
};

export const addTransactionTemplate = data => {
  return backendManagement.post("/transactions", data);
};

export const bindTransactionStep = (data, guid) => {
  return backendManagement.post(`/transactions/${guid}/steps`, data);
};

export const deleteTransactionTemplate = guid => {
  return backendManagement.post("/transactions", { guid, delete: true });
};

export const getTransactionSteps = (guid, gateway_guid) => {
  return backendManagement.get(`/transactions/${guid}/steps`, { params: { gateway_guid } });
};

export const getTransactionProcessingSteps = (guid, page, items) => {
  return backendManagement.get("/steps/processing", { params: { transaction_processing_guid: guid, page, items } });
};

export const getTransactionProcessingParams = (guid, page, items) => {
  return backendManagement.get(`/steps/processing/${guid}/params`, { params: { page, items } });
};

export const getTransactionsForGateway = (guid) => {
  return backendManagement.get(`/transactions/gateways/${guid}`);
};

export const getTransactionTypesForGateway = (guid) => {
  return backendManagement.get(`gateways/${guid}/transactions`);
};

export const getMismatchTransactions = args => {
  return backendManagement.get("transactions/mismatch", { params: { ...args } } );
};

export const getMismatchTransaction = (guid) => {
  return backendManagement.get(`transactions/mismatch/${guid}`);
};