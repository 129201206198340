import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addAccountForMerchantAction,
  addAccountAction,
} from "actions/accounts";
import { getMerchantShopsAction } from "actions/shops";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import { Col, Row, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import MultiSelect from "components/UI/MultiSelect";
import Joi from "joi-browser";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";

class AccountCreator extends Component {
  state = {
    number: "",
    shop_guid: "",
    holder_name: "",
    holder_country: "",
    bank_name: "",
    bank_address: "",
    isLoading: false,
    errors: [],
    merchant_guid: "",

    numberValidation: false,
    shop_guidValidation: false,
    holder_nameValidation: false,
    holder_countryValidation: false,
    bank_nameValidation: false,
    bank_addressValidation: false,
  };

  schema = {
    number: Joi.string().required().label("Number"),
    shop_guid: Joi.string().required().label("Shop"),
    holder_name: Joi.string().required().label("Holder name"),
    holder_country: Joi.string().required().max(10).label("Holder country"),
    bank_name: Joi.string().required().label("Bank name"),
    bank_address: Joi.string().required().label("Bank address"),
  };

  async componentDidMount() {
    const token = getAuthData().userPayload;

    let merchant_guid;
    if (token.merchant) {
      merchant_guid = token.merchant.merchant_guid;
      this.setState({ merchant_guid: token.merchant.merchant_guid });
    } else {
      merchant_guid = this.props.guid;
      this.setState({ merchant_guid: this.props.guid });
    }
    await this.props.getMerchantShopsAction(merchant_guid);

    this.setState({
      shops: this.props.shops,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {
      number: this.state.number,
      shop_guid: this.state.shop_guid,
      holder_name: this.state.holder_name,
      holder_country: this.state.holder_country,
      bank_name: this.state.bank_name,
      bank_address: this.state.bank_address,
    };
    let { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  onSelectshops = (option) => {
    const errors = { ...this.state.errors };
    delete errors.shop_guid;
    this.setState({
      shop_guid: option.guid,
      errors,
      shop_guidValidation: true,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const property = input.name;
    this.setState({
      [property]: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      try {
        await this.props.addAccountAction(this.state.merchant_guid, {
          number: this.state.number,
          shop_guid: this.state.shop_guid,
          holder_name: this.state.holder_name,
          holder_country: this.state.holder_country,
          bank_name: this.state.bank_name,
          bank_address: this.state.bank_address,
        });
        swal({
          title: "Account is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        swal({
          title: parsedError.error,
          text: parsedError.message,
          icon: "error",
        });
      }
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <Form autoComplete="off">
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Number* </Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("number")}>
              <Form.Control
                name="number"
                type="string"
                value={this.state.number}
                onChange={this.handleChange}
              />
              {errors.number && (
                <span className="validate-error">{errors.number}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Holder name* </Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("holder_name")}>
              <Form.Control
                name="holder_name"
                type="string"
                value={this.state.holder_name}
                onChange={this.handleChange}
              />
              {errors.holder_name && (
                <span className="validate-error">{errors.holder_name}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Holder country* </Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("holder_country")}>
              <Form.Control
                name="holder_country"
                type="string"
                value={this.state.holder_country}
                onChange={this.handleChange}
              />
              {errors.holder_country && (
                <span className="validate-error">{errors.holder_country}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Bank name* </Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("bank_name")}>
              <Form.Control
                name="bank_name"
                type="string"
                value={this.state.bank_name}
                onChange={this.handleChange}
              />
              {errors.bank_name && (
                <span className="validate-error">{errors.bank_name}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Bank address* </Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group validationState={this.formValidation("bank_address")}>
              <Form.Control
                name="bank_address"
                type="string"
                value={this.state.bank_address}
                onChange={this.handleChange}
              />
              {errors.bank_address && (
                <span className="validate-error">{errors.bank_address}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Shops</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Group>
              <MultiSelect
                name="Shops"
                options={this.props.shops ? this.props.shops : []}
                multi={false}
                onSelect={this.onSelectshops}
              />
              {errors.shop_guid && (
                <span className="validate-error">{errors.shop_guid}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className={
                this.state.numberValidation &&
                    this.state.shop_guidValidation &&
                    this.state.holder_nameValidation &&
                    this.state.holder_countryValidation &&
                    this.state.bank_nameValidation &&
                    this.state.bank_addressValidation
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
              onClick={this.handleSubmit}
            >
                Save
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shops.shopsList,
  };
};

export default connect(mapStateToProps, {
  addAccountForMerchantAction,
  addAccountAction,
  getMerchantShopsAction,
  showModal,
})(AccountCreator);

AccountCreator.propTypes = {
  addAccountAction: PropTypes.func,
  getMerchantShopsAction: PropTypes.func,
  guid: PropTypes.string,
  shops: PropTypes.array,
  showModal: PropTypes.func,
};
