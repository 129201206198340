import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import {
  editRateRevisionFeesAction,
  getAllRateRevisionFeesAction,
} from "../../../../actions/rates";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse.js";
import Spinner from "components/UI/Spinner/index";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import { getAllCurrencies } from "actions/currencies";

class RateRevisionFeesEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fees: [],

      errors: {},
    };
  }

  columns = [
    {
      path: "transaction_type",
      label: "Transaction type",
      key: "transaction_type",
    },
    {
      path: "transaction_status",
      label: "Transaction status",
      key: "transaction_status",
    },
    { path: "card_schema", label: "Card schema", key: "card_schema" },
    { path: "card_region", label: "Card region", key: "card_region" },
    { path: "card_type", label: "Card type", key: "card_type" },
    { label: "Plain", path: "plain" },
    { path: "buy", label: "buy", key: "buy" },
    { path: "sell", label: "sell", key: "sell" },
  ];

  schema = {
    name: Joi.string().required().label("Name"),
  };
  async componentDidMount() {
    this.setState({
      fees: this.props.fees,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = { name: this.state.name, reason: this.state.reason };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = { name: this.state.name };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = {
      name: this.state.name,
    };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChangeFee = (fee_guid, path, { currentTarget: input }) => {
    let fees = this.state.fees;
    let element = fees.filter((fee) => fee.guid === fee_guid);
    let index = fees.indexOf(element[0]);
    if (index !== -1) {
      fees[index] = { ...fees[index], [path]: input.value };
    }
    this.setState({
      fees,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ isLoading: true });
      const data = this.state.fees.map((fee) => ({
        guid: fee.guid,
        buy: fee.buy,
        sell: fee.sell,
      }));
      await this.props.editRateRevisionFeesAction(
        this.props.rateId,
        this.props.revisionId,
        data
      );
      swal({
        title: "Fees is updated",
        icon: "success",
        button: false,
        timer: 2000,
      });
      await this.props.updateFees();
      await this.props.handleClose();
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      swal({
        title: parsedError.error,
        text: parsedError.message,
        icon: "error",
      });
      // }
    }
  };

  render() {
    if (this.state.loading) return <Spinner />;
    else
      return (
        <div>
          <div className="card" style={{ overflowX: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  {this.columns.map((column) => (
                    <th key={column.key} style={{ padding: "5px 8px" }}>
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.fees.map((fee) => (
                  <tr key={fee.guid}>
                    {this.columns.map((column) => (
                      <td
                        key={column.key + fee.guid}
                        style={{ padding: "5px 8px" }}
                      >
                        {column.path === "buy" || column.path === "sell" ? (
                          <input
                            type="number"
                            name={fee.guid}
                            value={fee[column.path]}
                            onChange={(e) =>
                              this.handleChangeFee(fee.guid, column.path, e)
                            }
                            style={{ width: "50px" }}
                          />
                        ) : column.path === "plain" ? (
                          fee[column.path] ? (
                            "fixed"
                          ) : (
                            "%"
                          )
                        ) : (
                          fee[column.path]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button
                  className={"btn btn-fill btn-success"}
                  onClick={this.handleSubmit}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currencies.currenciesList,
    template: state.rates.template,
  };
};

export default connect(mapStateToProps, {
  editRateRevisionFeesAction,
  getAllRateRevisionFeesAction,
  getAllCurrencies,
  showModal,
})(RateRevisionFeesEditor);

RateRevisionFeesEditor.propTypes = {
  currencies: PropTypes.array,
  editRateRevisionFeesAction: PropTypes.func,
  fees: PropTypes.array,
  getAllCurrencies: PropTypes.func,
  getAllRateRevisionFeesAction: PropTypes.func,
  rateId: PropTypes.string,
  revisionId: PropTypes.string,
  showModal: PropTypes.func,
  template: PropTypes.object,
  updateFees: PropTypes.func,
};
