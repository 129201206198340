import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { addCurrenciesForGatewayAction } from "actions/gateways";
import MultiSelect from "components/UI/MultiSelect";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import Alert from "../../UI/Alert";

export class RowAddCurrency extends Component {
  state = {
    currencies: [],
    reason: "",
    needReason: false,
  };

  onCurrenciesSelect = async (option) => {
    this.setState({
      currencies: option.guid,
    });
    this.setState({ needReason: true });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    this.setState({ reason: input.value });
  };

  handleSubmit = async () => {
    if (
      this.state.currencies === "" ||
      (this.state.reason === "" && this.state.needReason)
    )
      swal({
        title: "Please, enter information in every required field",
        icon: "warning",
      });
    else {
      try {
        const data = {
          guid: this.state.currencies,
          reason: this.state.reason,
        };
        await this.props.addCurrenciesForGatewayAction(
          this.props.gatewayGuid,
          data
        );
        swal({
          title: "Currencies is added",
          icon: "success",
          button: false,
          timer: 2000,
        });
        let { currencies, gatewayCurrencies } = this.props;
        gatewayCurrencies &&
          gatewayCurrencies.forEach((gatewayCurrency) => {
            currencies = currencies.filter(
              (currency) => currency.guid !== gatewayCurrency.guid
            );
          });
        await this.setState({
          currencies: [],
        });
      } catch (error) {
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    let currencies = this.props.currencies ? this.props.currencies : [];
    currencies.forEach((currency) => {
      currency.name = currency.code;
    });
    const { gatewayCurrencies } = this.props;
    gatewayCurrencies &&
      gatewayCurrencies.forEach((gatewayCurrency) => {
        currencies = currencies.filter(
          (currency) => currency.guid !== gatewayCurrency.guid
        );
      });

    return (
      <div style={{ textAlign: "center" }}>
        <label>Currencies: </label>
        <MultiSelect
          options={currencies}
          multi={false}
          onSelect={this.onCurrenciesSelect}
        />
        {this.state.needReason && (
          <div>
            <label>Reason</label>
            <Form.Group>
              <Form.Control
                placeholder="Enter reason"
                name="reason"
                value={this.state.reason}
                onChange={this.handleChangeReason}
              />
            </Form.Group>
          </div>
        )}
        <Button
          className="btn btn-fill"
          onClick={this.handleSubmit}
          style={{ margin: "15px" }}
        >
          Add currencies
        </Button>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  addCurrenciesForGatewayAction,
})(RowAddCurrency);
