import React, { Component } from "react";
import { connect } from "react-redux";
import { getServiceAction, editServiceAction } from "actions/services";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import { Col, Row, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import CreatableMultiSelect from "components/UI/CreatableSelect";
import Alert from "../UI/Alert";

class ServiceEditor extends Component {
  state = {
    name: "",
    startTime: "",
    options: {},

    options_name: [],
    delete_card_data_service_options: [ "delete_after", "card_data" ],
    decta_recot_service_options: [ "url" ],
    login_expired_service_options: [
      "warning_after",
      "block_after",
      "default_logins",
    ],
    currency_exchange_service_options: [ "url" ],
    chargeback_service_options: [
      "host",
      "port",
      "user",
      "password",
      "prefix",
      "serverPath",
    ],
    recurring_service: [],

    needReason: false,
    reason: "",
    isLoading: false,
    errors: {},

    urlValidation: false,
    startTimeValidation: false,
  };

  delete_card_data_serviceSchema = {
    delete_after: Joi.number()
      .integer()
      .min(0)
      .max(1000)
      .required()
      .label("Delete after"),
    card_data: Joi.array()
      .items(Joi.string().not().empty().required())
      .required(),
    startTime: Joi.string()
      .trim()
      .regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/)
      .error(() => {
        return {
          message: "Start time is invalid",
        };
      })
      .label("Start time"),
    reason: Joi.string().not().empty().required().label("Reason"),
  };
  chargeback_serviceSchema = {
    options: Joi.array()
      .required()
      .items(
        Joi.object().keys({
          host: Joi.string()
            .trim()
            .regex(
              /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/
            )
            .error(() => {
              return {
                message: "Host is invalid",
              };
            })
            .required()
            .label("Host"),
          port: Joi.number()
            .integer()
            .min(0)
            .max(65535)
            .required()
            .label("Port"),
          user: Joi.string().not().empty().required().label("User"),
          password: Joi.string().not().empty().required().label("Password"),
          prefix: Joi.string().not().empty().required().label("Prefix"),
          serverPath: Joi.string().not().empty().required().label("Path"),
        })
      ),
    startTime: Joi.string()
      .trim()
      .regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/)
      .error(() => {
        return {
          message: "Start time is invalid",
        };
      })
      .label("Start time"),
    reason: Joi.string().not().empty().required().label("Reason"),
  };
  decta_recot_serviceSchema = {
    url: Joi.string().uri().required().label("URL"),
    startTime: Joi.string()
      .trim()
      .regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/)
      .error(() => {
        return {
          message: "Start time is invalid",
        };
      })
      .label("Start time"),
    reason: Joi.string().not().empty().required().label("Reason"),
  };
  login_expired_serviceSchema = {
    warning_after: Joi.number()
      .integer()
      .min(1)
      .max(3000)
      .label("Warning after"),
    block_after: Joi.number().integer().min(1).max(3000).label("Block after"),
    default_logins: Joi.array()
      .items(Joi.string().not().empty().required())
      .required()
      .label("Default logins"),
    startTime: Joi.string()
      .trim()
      .regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/)
      .label("Start time"),
    reason: Joi.string().not().empty().required().label("Reason"),
  };
  currency_exchange_serviceSchema = {
    url: Joi.string().uri().required(),
    startTime: Joi.string()
      .trim()
      .regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/)
      .error(() => {
        return {
          message: "Start time is invalid",
        };
      })
      .label("Start time"),
    reason: Joi.string().not().empty().required().label("Reason"),
  };
  recurring_serviceSchema = {
    startTime: Joi.string()
      .trim()
      .regex(/^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/)
      .error(() => {
        return {
          message: "Start time is invalid",
        };
      })
      .label("Start time"),
    reason: Joi.string().not().empty().required().label("Reason"),
  };

  chargeback_serviceOptionsSchema = {
    host: Joi.string()
      .trim()
      .regex(
        /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/
      )
      .required()
      .error(() => {
        return {
          message: "Host is invalid",
        };
      })
      .label("Host"),
    port: Joi.number().integer().min(0).max(65535).required().label("Port"),
    user: Joi.string().not().empty().required().label("User"),
    password: Joi.string().not().empty().required().label("Password"),
    prefix: Joi.string().not().empty().required().label("Prefix"),
    serverPath: Joi.string().not().empty().required().label("Path"),
  };

  async componentDidMount() {
    await this.props.getServiceAction(this.props.name);
    const service = this.props.service;
    this.setState({
      name: service.name,
      url: service.url,
      startTime: service.start_time,
      options: service.options || {},

      startTimeValidation: service.start_time ? true : false,
    });
    Object.keys(this.state.options).forEach((key) => {
      if (key === "card_data" || key === "default_logins") {
        let arr = this.state.options[key].map((option) => {
          return { name: option, label: option, value: option };
        });
        let options = this.state.options;
        options[key] = arr;
        this.setState({ options });
      }
    });
    let options_name;
    switch (this.state.name) {
      case "chargeback-service":
        options_name = this.state.chargeback_service_options;
        break;
      case "decta-recot-service":
        options_name = this.state.decta_recot_service_options;
        break;
      case "currency-exchange-service":
        options_name = this.state.currency_exchange_service_options;
        break;
      case "login-expired-service":
        options_name = this.state.login_expired_service_options;
        break;
      case "delete-card-data-service":
        options_name = this.state.delete_card_data_service_options;
        break;
      case "recurring-service":
        options_name = this.state.recurring_service;
        break;
      default:
        break;
    }
    this.setState({ options_name });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    switch (this.state.name) {
      case "chargeback-service":
        schema = this.chargeback_serviceSchema;
        break;
      case "decta-recot-service":
        schema = this.decta_recot_serviceSchema;
        break;
      case "currency-exchange-service":
        schema = this.currency_exchange_serviceSchema;
        break;
      case "login-expired-service":
        schema = this.login_expired_serviceSchema;
        break;
      case "delete-card-data-service":
        schema = this.delete_card_data_serviceSchema;
        break;
      case "recurring-service":
        schema = this.recurring_serviceSchema;
        break;
      default:
        break;
    }
    let dataOptions = this.state.options;
    Object.keys(this.state.options).forEach((key) => {
      if (key === "card_data" || key === "default_logins") {
        let arr = this.state.options[key];
        let data = arr.map((option) => {
          return option.label;
        });
        dataOptions[key] = data;
      }
    });
    if (this.state.name === "chargeback-service") {
      data = {
        options: dataOptions,
        startTime: this.state.startTime,
        reason: this.state.reason,
      };
    } else {
      data = {
        ...dataOptions,
        startTime: this.state.startTime,
        reason: this.state.reason,
      };
    }
    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema = {};
    switch (this.state.name) {
      case "chargeback-service":
        if (
          name === "host" ||
          name === "port" ||
          name === "user" ||
          name === "password" ||
          name === "prefix" ||
          name === "serverPath"
        ) {
          schema = this.chargeback_serviceOptionsSchema;
        } else {
          schema = this.chargeback_serviceSchema;
        }
        break;
      case "decta-recot-service":
        schema = this.decta_recot_serviceSchema;
        break;
      case "currency-exchange-service":
        schema = this.currency_exchange_serviceSchema;
        break;
      case "login-expired-service":
        schema = this.login_expired_serviceSchema;
        break;
      case "delete-card-data-service":
        schema = this.delete_card_data_serviceSchema;
        break;
      case "recurring-service":
        schema = this.recurring_serviceSchema;
        break;
      default:
        break;
    }
    schema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({
      [input.name]: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleChangeOption = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    let options = this.state.options;
    options[input.name] = input.value;
    this.setState({
      options,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleChangeArrayOption = ({ currentTarget: input }) => {
    let arr = input.name.split("_");
    const name = arr[0];
    const index = arr[1];
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({ name, value: input.value });
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    let options = this.state.options;
    options[index][name] = input.value;
    this.setState({
      options,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleChangeSelectCardData = (options) => {
    const errors = { ...this.state.errors };
    let value = options.map((item) => {
      return item.label;
    });
    const errorMessage = this.validateProperty({
      name: "card_data",
      value: value,
    });
    if (errorMessage) errors.card_data = errorMessage;
    else delete errors.card_data;

    let dataOptions = this.state.options;
    dataOptions.card_data = options;
    this.setState({
      options: dataOptions,
      errors,
    });
  };

  handleChangeSelectDefaultLogins = (options) => {
    const errors = { ...this.state.errors };
    let value = options.map((item) => {
      return item.label;
    });
    const errorMessage = this.validateProperty({
      name: "default_logins",
      value: value,
    });
    if (errorMessage) errors.default_logins = errorMessage;
    else delete errors.default_logins;

    let dataOptions = this.state.options;
    dataOptions.default_logins = options;
    this.setState({
      options: dataOptions,
      errors,
    });
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  addItemInChargebacksArray = () => {
    let options = this.state.options;
    let obj = {};
    for (let index = 0; index < this.state.options_name.length; index++) {
      const name = this.state.options_name[index];
      obj[name] = "";
    }
    options.push(obj);
    this.setState({ options });
  };

  deleteArrayItem = (index) => {
    let options = this.state.options;
    options.splice(index, 1);
    this.setState({ options });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        this.setState({ isLoading: true });
        let options = this.state.options;
        let data = {};
        if (this.state.name === "chargeback-service") {
          data = {
            name: this.state.name,
            options: options,
            startTime: this.state.startTime,
            reason: this.state.reason,
          };
        } else {
          data = {
            name: this.state.name,
            ...options,
            startTime: this.state.startTime,
            reason: this.state.reason,
          };
        }
        await this.props.editServiceAction(data);
        swal({
          title: "Service is updated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  renderOptions = (name) => {
    if (name === "card_data")
      return (
        <Row>
          <Col md={4} sm={5} xs={5} className="form-label">
            <Form.Label>{name.replace(/_/g, " ")}*</Form.Label>
            <i className="far fa-question-circle help-tip">
              <p>Select options or type to create new option.</p>
            </i>
          </Col>
          <Col md={7}>
            <Form.Group validationState={this.formValidation(name)}>
              <CreatableMultiSelect
                name={name}
                options={
                  this.state.options[name] ? this.state.options[name] : []
                }
                value={this.state.options[name] ? this.state.options[name] : []}
                handleChange={this.handleChangeSelectCardData}
              />
              {this.state.errors[name] && (
                <span className="validate-error">
                  {this.state.errors[name]}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
      );
    if (name === "default_logins")
      return (
        <Row>
          <Col md={4} sm={5} xs={5} className="form-label">
            <Form.Label>{name.replace(/_/g, " ")}* </Form.Label>

            <i className="far fa-question-circle help-tip">
              <p>Select options or type to create new option.</p>
            </i>
          </Col>
          <Col md={7}>
            <Form.Group validationState={this.formValidation(name)}>
              <CreatableMultiSelect
                name={name}
                options={
                  this.state.options[name] ? this.state.options[name] : []
                }
                value={this.state.options[name] ? this.state.options[name] : []}
                handleChange={this.handleChangeSelectDefaultLogins}
              />
              {this.state.errors[name] && (
                <span className="validate-error">
                  {this.state.errors[name]}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
      );
    else
      return (
        <Row>
          <Col md={4} sm={5} xs={5} className="form-label">
            <Form.Label>{name.replace(/_/g, " ")}* </Form.Label>
          </Col>
          <Col md={7}>
            <Form.Group validationState={this.formValidation(name)}>
              <Form.Control
                name={name}
                type="string"
                value={this.state.options[name]}
                onChange={this.handleChangeOption}
              />
              {this.state.errors[name] && (
                <span className="validate-error">
                  {this.state.errors[name]}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
      );
  };
  renderArrayOptions = () => {
    const items = [];
    if (this.state.options.length) {
      for (let index = 0; index < this.state.options.length; index++) {
        let obj = this.state.options_name.map((name) => {
          return (
            <Row key={index + name}>
              <Col md={4} sm={5} xs={5} className="form-label">
                <Form.Label>{name.replace(/_/g, " ")}* </Form.Label>
              </Col>
              <Col md={7}>
                <Form.Group validationState={this.formValidation(name)}>
                  <Form.Control
                    name={name + "_" + index}
                    type="string"
                    value={this.state.options[index][name]}
                    onChange={this.handleChangeArrayOption}
                  />
                  {this.state.errors[name + "_" + index] && (
                    <span className="validate-error">
                      {this.state.errors[name + "_" + index]}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          );
        });
        items.push(
          <div style={{ border: "1px solid #cfcfcf" }}>
            <i
              className="far fa-trash-alt"
              style={{
                cursor: "pointer",
                color: "red",
                float: "right",
                marginTop: "10px",
                marginRight: "10px",
              }}
              onClick={() => {
                this.deleteArrayItem(index);
              }}
            />
            {obj}
          </div>
        );
      }
      return items;
    }
    return <></>;
  };
  render() {
    const { errors } = this.state;
    if (this.props.isLoading) return <Spinner />;
    else
      return (
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Row>
            <Col md={4} sm={5} xs={5} className="form-label">
              <Form.Label>Start Time* </Form.Label>
            </Col>
            <Col md={7}>
              <Form.Group validationState={this.formValidation("startTime")}>
                <Form.Control
                  name="startTime"
                  type="string"
                  value={this.state.startTime}
                  onChange={this.handleChange}
                />
                {errors.startTime && (
                  <span className="validate-error">{errors.startTime}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          {this.state.name === "chargeback-service" ? (
            <>
              {this.renderArrayOptions()}
              <div>
                <i
                  type="button"
                  src="fas fa-plus"
                  className="fas fa-plus"
                  style={{
                    cursor: "pointer",
                    color: "green",
                    marginTop: "30px",
                  }}
                  onClick={this.addItemInChargebacksArray}
                  value=""
                />
                {/* <Button className={"btn btn-fill"}  onClick={this.addItemInChargebacksArray}>Add item</Button>  */}
              </div>
            </>
          ) : (
            this.state.options_name.map((optionKey) =>
              this.renderOptions(optionKey)
            )
          )}
          {/* {
              Array.isArray(this.state.options) ?
                this.renderArrayOptions()
                : Object.keys(this.state.options).map(optionKey => this.renderOptions(optionKey))
            } */}

          <Row>
            <Col md={4} sm={5} xs={5} className="form-label">
              <Form.Label>Reason</Form.Label>
            </Col>
            <Col md={7}>
              <Form.Group>
                <Form.Control
                  placeholder="Enter reason"
                  name="reason"
                  value={this.state.reason}
                  onChange={this.handleChangeReason}
                />
                {errors.reason && (
                  <span className="validate-error">{errors.reason}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  Object.keys(this.state.errors).length === 0 &&
                  this.state.reason !== ""
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    service: state.services.service,
    isLoading: state.services.serviceLoading,
    error: state.services.error,
  };
};

export default connect(mapStateToProps, {
  getServiceAction,
  editServiceAction,
  showModal,
})(ServiceEditor);

ServiceEditor.propTypes = {
  editServiceAction: PropTypes.func,
  getServiceAction: PropTypes.func,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  service: PropTypes.object,
  showModal: PropTypes.func,
};
