import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { parseResponse } from "helpers/parseResponse";
import {
  getUserAccountAction,
  editUserAccountAction,
} from "actions/useraccounts";
import { flushTokens } from "../../../services/paymentBackendAPI/backendPlatform";
import PropTypes from "prop-types";

class RecoveryPassword extends Component {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",

    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,

    userGuid: "",

    isPasswordConfirmed: false,
    isPasswordChanged: false,

    errorPassword: "",
  };

  async componentDidMount() {
    await this.props.getUserAccountAction();
    this.setState({ userGuid: this.props.useraccount.guid });
  }

  isPasswordConfirmed = () => {
    if (
      this.state.newPassword === this.state.confirmPassword &&
      this.state.newPassword &&
      this.state.confirmPassword
    ) {
      this.setState({
        isPasswordConfirmed: true,
      });
      return true;
    } else {
      this.setState({
        isPasswordConfirmed: false,
      });
      return false;
    }
  };

  handleChange = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    this.setState({
      [property]: value,
    });
  };

  handleBack = () => {
    flushTokens();
    this.props.history.push("/");
    window.location.reload();
  };

  handleShowOldPassword = () => {
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  };

  handleShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.isPasswordConfirmed()) {
      this.setState({ errorPassword: "Passwords don't match. Try again" });
    } else {
      this.setState({ errorPassword: "" });
      try {
        const { newPassword, userGuid, oldPassword } = this.state;
        await this.props.editUserAccountAction({
          new_password: newPassword,
          guid: userGuid,
          old_password: oldPassword,
        });
        this.setState({ isPasswordChanged: true });
      } catch (error) {
        const parsedError = parseResponse(error);
        this.setState({
          errorPassword: parsedError.message,
        });
      }
    }
  };

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      isPasswordChanged,
      errorPassword,
    } = this.state;

    return (
      <div className="login-page">
        <div className="login-card">
          <div className="expired">
            {isPasswordChanged ? (
              <>
                <p style={{ color: "grey", textAlign: "center" }}>
                  New password set successfully
                </p>
                <div style={{ textAlign: "center" }}>
                  <Button className="button-submit" onClick={this.handleBack}>
                    Continue
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p className="header-login">Password expired</p>
                <p className="tooltip-text">
                    Password must be at least 8 characters, including a number, a
                    special, and an uppercase letter.
                </p>
                <div className="form-container">
                  <div className="form">
                    <div className="input-container">
                      <Form.Group controlId="oldPassword">
                        <Form.Control
                          placeholder="Current password"
                          name="oldPassword"
                          type={
                            this.state.showOldPassword ? "text" : "password"
                          }
                          value={oldPassword}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Form.Group>
                      <span
                        className={
                          this.state.showOldPassword
                            ? "icon-eye-stroke login-page-eye"
                            : "icon-eye login-page-eye"
                        }
                        onClick={this.handleShowOldPassword}
                      />
                    </div>
                    <div className="input-container">
                      <Form.Group controlId="newPassword">
                        <Form.Control
                          placeholder="New password"
                          name="newPassword"
                          type={
                            this.state.showNewPassword ? "text" : "password"
                          }
                          value={newPassword}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Form.Group>
                      <span
                        className={
                          this.state.showNewPassword
                            ? "icon-eye-stroke login-page-eye"
                            : "icon-eye login-page-eye"
                        }
                        onClick={this.handleShowNewPassword}
                      />
                      {errorPassword !== "" && (
                        <p className="validate-error">{errorPassword}</p>
                      )}
                    </div>
                    <div className="input-container">
                      <Form.Group controlId="confirmPassword">
                        <Form.Control
                          placeholder="Confirm password"
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          value={confirmPassword}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Form.Group>
                      <span
                        className={
                          this.state.showConfirmPassword
                            ? "icon-eye-stroke login-page-eye"
                            : "icon-eye login-page-eye"
                        }
                        onClick={this.handleShowConfirmPassword}
                      />
                    </div>
                    <Button
                      className="button-submit"
                      type="submit"
                      onClick={this.handleSubmit}
                    >
                        Continue
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    useraccount: state.useraccounts.useraccount,
  };
};

export default connect(mapStateToProps, {
  getUserAccountAction,
  editUserAccountAction,
})(RecoveryPassword);

RecoveryPassword.propTypes = {
  editUserAccountAction: PropTypes.func,
  getUserAccountAction: PropTypes.func,
  history: PropTypes.object,
  useraccount: PropTypes.object,
};
