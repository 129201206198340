import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAuditShopsTablesAction } from "../../../actions/audit";
import { reset, searchInShopsAuditTable } from "../../../actions/search";
import { resetPage, setNewTable } from "../../../actions/store";
import AbstractComponent from "../../../factories/Main";
import { cutGuid } from "../../../helpers/cutGuid";
import { getAuditShopTables } from "../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../UI/CopyToClipboard";

const columns = [
  {
    path: "guid",
    label: "Guid",
    key: "guid_audit",
    content: (el) => (
      <>
        {cutGuid(el.guid)}
        <Copy text={el.guid} />
      </>
    ),
  },
  { path: "name", label: "Name", key: "name" },
  { path: "merchant_name", label: "Merchant", key: "merchant_name" },
  {
    key: "enabled",
    content: (shop) => (
      <i
        className={
          shop.enabled ? "icon-success green icon" : "icon-failed red icon"
        }
      />
    ),
    label: "Enabled",
    textContent: (shop) => (shop.enabled ? "Enabled" : "Disabled"),
    align: "center",
  },
  { path: "phone", label: "Phone", key: "phone" },
  { path: "email", label: "Email", key: "email" },
  { path: "url", label: "URL", key: "url" },
  { path: "risk_category", label: "Risk category", key: "risk_category" },
  { path: "note", label: "Note", key: "note" },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (el) =>
      moment(el.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.shopTables,
    count: state.audit.shopTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.shopAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_shop",
    keyPath: "guid",
    columns,
    exportFunction: getAuditShopTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditShopsTablesAction,
  search: searchInShopsAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
