import * as types from "../constants/actionTypes";
import { getChargebacks, getChargeback } from "../services/paymentBackendAPI/management/chargeback";
import { loadTable } from "./store";

export const getAllChargebacks = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CHARGEBACKS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const res = await getChargebacks(args);
      return dispatch({
        type: types.GET_CHARGEBACKS_SUCCEED,
        data: res.data.data,
        count: res.data.count,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    } catch (error) {
      return dispatch({
        type: types.GET_CHARGEBACKS_FAILED
      });
    }
  };
};

export const getChargebackAction = (guid) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CHARGEBACK
    });
    try {
      const res = await getChargeback(guid);
      return dispatch({
        type: types.GET_CHARGEBACK_SUCCEED,
        data: res.data,
      });
    } catch (error) {
      return dispatch({
        type: types.GET_CHARGEBACK_FAILED
      });
    }
  };
};