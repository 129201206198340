import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteRoleAction, getAllRoles } from "../../actions/roles";
import { reset, searchInRoles } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { getRoles } from "../../services/paymentBackendAPI/management/roles";
import RoleCreator from "./Creator";
import RoleEditor from "./Editor";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";

const columns = [
  {
    path: "name",
    label: "Name",
    key: "roleName",
    content: (role) => (
      <Link className="link" to={`/about/role/${role.guid}`}>
        {role.name}
      </Link>
    ),
    textContent: (role) => role.name,
  },
  { path: "type", label: "Type", key: "typeRole" },
  { path: "description", label: "Description" },
  ability.can("EXECUTE", "ROLES") && {
    key: "edit",
    content: (role) => (
      <Modal
        header="Edit role"
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        content={RoleEditor}
        contentProps={{ role_guid: role.guid }}
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "ROLES") && {
    key: "delete",
    content: () => (
      <i
        className="far fa-trash-alt"
        style={{ cursor: "pointer", color: "red" }}
      />
    ),
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.roles.rolesList,
    count: state.roles.count,
    table: state.store.table,
    loading: state.roles.rolesLoading,
    searchData: state.search.rolesSearch,
    isSearch: state.search.isSearch,
    name: "roles",
    keyPath: "guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    modalComponent: (
      <Modal
        allowed={ability.can("EXECUTE", "ROLES")}
        button={
          <button type="button" className="btn btn-fill btn-primary">
            Create role
          </button>
        }
        content={RoleCreator}
        header="Create role"
      />
    ),
    exportFunction: getRoles,
  };
};

export default connect(mapStateToProps, {
  get: getAllRoles,
  delete: deleteRoleAction,
  search: searchInRoles,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
