import * as types from "../constants/actionTypes";
import {
  getPartners,
  getPartner,
  getPartnerLogins,
  getPartnerGroups,
  addPartnerLogin,
  updatePartner,
  deletePartner,
  createPartner,
} from "../services/paymentBackendAPI/management/partners";
import {
  deleteGroupFromPartner, addGroupToPartner
} from "../services/paymentBackendAPI/management/groups";
import { getTopPartners } from "../services/paymentBackendAPI/reports/entities";
import { loadTable } from "./store";

export const getAllPartners = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PARTNERS
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getPartners(args);
      return dispatch({
        type: types.GET_PARTNERS_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_PARTNERS_FAILED
      });
    }
  };
};

export const getPartnerAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PARTNER
    });
    try {
      const response = await getPartner(id);
      return dispatch({
        type: types.GET_PARTNER_SUCCEED,
        partner: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_PARTNER_FAILED
      });
    }
  };
};

export const editPartnerAction = (data) => {
  return async (dispatch) => {
    const response = await updatePartner(data);
    dispatch({
      type: types.EDIT_PARTNER,
      partner: response.data
    });
  };
};

export const deletePartnerAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deletePartner(data);
    return dispatch(getAllPartners({ page, items, ...search }));
  };
};

export const addGroupToPartnerAction = ({ group_guid, partner_guid, reason }) => {
  return async () => {
    await addGroupToPartner(group_guid, partner_guid, reason);
  };
};



export const getPartnerLoginsAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PARTNER_LOGINS
    });
    const response = await getPartnerLogins(id, page, items);
    dispatch({
      type: types.GET_PARTNER_LOGINS_SUCCEED,
      data: response.data
    });
  };
};

export const getPartnerGroupsAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PARTNER_GROUPS
    });
    try {
      const response = await getPartnerGroups(id, page, items);
      dispatch({
        type: types.GET_PARTNER_GROUPS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_PARTNER_GROUPS_FAILED
      });
    }
  };
};

export const deleteGroupFromPartnerAction = (data, page, items, partner_guid) => {
  return async (dispatch) => {
    await deleteGroupFromPartner(data);
    return dispatch(getPartnerGroupsAction(partner_guid, page, items));
  };
};

export const addPartnerAction = (data, page, items, search) => {
  return async (dispatch) => {
    const response = await createPartner(data);
    dispatch({
      type: types.ADD_PARTNER,
      data: response.data
    });
    return dispatch(getAllPartners({ page, items, ...search }));
  };
};

export const addPartnerLoginAction = (id, data) => {
  return async (dispatch) => {
    const response = await addPartnerLogin(id, data);
    dispatch({
      type: types.ADD_PARTNER_LOGIN,
      partnerLogin: response.data
    });
  };
};

export const upsertChangedGroupAction = (group) => {
  return (dispatch) => {
    dispatch({
      type: types.UPSERT_PARTNER_CHANGED_GROUP,
      group: group
    });
  };
};

export const getTopPartnersAction = (days, count) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TOP_PARTNERS,
    });
    try {
      const response = await getTopPartners(days, count);
      return dispatch({
        type: types.GET_TOP_PARTNERS_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      dispatch({
        type: types.GET_TOP_PARTNERS_FAILED,
      });
      throw (error);
    }
  };
};