import { setCurrentSearchData } from "actions/search";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { InputGroup } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";

class DateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: undefined,
      currentPage: 1,
      pageSize: 100,
      isSearch: false,
      name: true,
    };
  }

  componentDidMount = () => {
    this.setState({
      value: moment(this.props.currentSearchData[this.props.value]).format(
        "DD.MM.YYYY"
      ),
    });
  };

  handleReset = async () => {
    await this.props.setCurrentSearchData({
      [this.props.value]: "",
    });
    await this.props.search({
      [this.props.value]: undefined,
    });
    await this.props.get({
      page: 1,
      items: this.state.pageSize,
      ...this.props.searchData,
    });
    this.props.updateCurrentPage();
  };

  onChange = async (start) => {
    this.props.setCurrentSearchData({
      [this.props.value]: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
    });
    await this.props.search({
      [this.props.value]: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
    });
    await this.props.get({
      page: 1,
      items: this.state.pageSize,
      ...this.props.searchData,
    });
    this.props.updateCurrentPage();
  };

  render() {
    return (
      <div
        className={
          this.props.isSearch
            ? "searchOpen search search-input"
            : "searchClosed search search-input"
        }
      >
        <InputGroup>
          <DateRangePicker
            onApply={this.onChange}
            initialSettings={{
              // autoUpdateInput: false,
              singleDatePicker: true,
              startDate: this.props.currentSearchData[this.props.value]
                ? moment(this.props.currentSearchData[this.props.value]).format(
                  "DD.MM.YYYY"
                )
                : undefined,
              locale: {
                format: "DD.MM.YYYY",
              },
            }}
          >
            <input
              type="text"
              className="form-control"
              style={{ paddingLeft: "5px", minWidth: "60px" }}
            />
          </DateRangePicker>

          <InputGroup.Append>
            <i
              className="icon-delete icon"
              onClick={this.handleReset}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Append>
        </InputGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.search.reset,
    isSearch: state.search.isSearch,
    currentSearchData: state.search.currentSearchData,
  };
};

export default connect(mapStateToProps, {
  setCurrentSearchData,
})(DateSelector);

DateSelector.propTypes = {
  currentSearchData: PropTypes.object,
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  search: PropTypes.func,
  searchData: PropTypes.object,
  setCurrentSearchData: PropTypes.func,
  updateCurrentPage: PropTypes.func,
  value: PropTypes.string,
};
