import * as types from "../constants/actionTypes";
import { getParamsForStep } from "../services/paymentBackendAPI/management/steps";

// import {addTransactionParam} from "../services/paymentBackendAPI/management/params";
// import {addTransactionStep} from "../services/paymentBackendAPI/management/steps";

export const getParamsForStepAction = (guid) => {
  return async dispatch => {
    dispatch({
      type: types.GET_PARAMS_FOR_STEP
    });
    try {
      const response = await getParamsForStep(guid);
      dispatch({
        type: types.GET_PARAMS_FOR_STEP_SUCCEED,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: types.GET_PARAMS_FOR_STEP_FAILED
      });
    }
  };
};