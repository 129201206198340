import RowAddRole from "components/RowAddRole";
import Spinner from "components/UI/Spinner/index";
import Table from "components/UI/Table";
import { parseResponse } from "helpers/parseResponse";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Content from "views/Content";
import {
  deleteAdminRoleAction,
  getAdminAction,
  getAdminRolesAction,
} from "../../actions/admins";
import ability from "../../config/ability";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import Alert from "../UI/Alert";

class AdminDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.props.getAdminAction(this.props.match.params.id);
    await this.props.getAdminRolesAction(this.props.match.params.id);
    this.setState({ loading: false });
  };

  columnsRoles = [
    {
      path: "name",
      label: "Name",
      key: "name",
      content: (role) => (
        <Link className="link" to={{ pathname: `/about/role/${role.guid}` }}>
          {role.name}
        </Link>
      ),
    },
    { path: "type", label: "Type", key: "roleType" },
    { path: "description", label: "Description", key: "roleDescription" },
    ability.can("DELETE", "ADMINROLES") && {
      key: "delete",
      align: "center",
      content: (role) =>
        role.type === "system" ? null : (
          <i
            className="far fa-trash-alt red icon"
            style={{ cursor: "pointer" }}
            onClick={() =>
              swal({
                title: "Are you sure?",
                text:
                  "Once deleted, you will not be able to recover this. Type reason below.",
                content: "input",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((value) => {
                if (value) {
                  this.handleDelete(
                    this.props.match.params.id,
                    role.guid,
                    value
                  );
                  swal("Deleted", {
                    icon: "success",
                    button: false,
                    timer: 2000,
                  });
                }
              })
            }
          />
        ),
      label: "Delete",
      colWidth: 4,
    },
  ];

  handleDelete = async (guid, roleGuid, reason) => {
    try {
      await this.props.deleteAdminRoleAction(guid, roleGuid, reason);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    const { admin } = this.props;
    if (this.props.adminLoading || this.state.loading)
      return (
        <Content style={{ height: "75vh" }}>

          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5">{admin.username}</Card.Header>
              <Card.Body>
                <Card.Title>Info</Card.Title>
                {admin.warning !== undefined ? (
                  <>
                    <Row style={{ color: "red" }}>
                      <Col md={5} sm={5} xs={5}></Col>
                      <Col md={2} sm={2} xs={2}>
                        <span> Warning: {admin.warning}</span>
                      </Col>
                      <Col md={5} sm={5} xs={5}></Col>
                    </Row>
                    <br />
                  </>
                ) : null}
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>GUID:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{this.props.match.params.id}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>First name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.first_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Last name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.last_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Username:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Email:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.email}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Phone:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.phone}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Auth type:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.auth_type}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Last login:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.last_login}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Credentials expire at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.credentials_expire_at}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {admin.created_at !== null
                        ? moment(admin.created_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.created_by_username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>

                      {admin.updated_at !== null
                        ? moment(admin.updated_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{admin.updated_by_username}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            {ability.can("READ", "ADMINROLES") && (
              <Card>
                <Card.Body>
                  <Card.Title>Roles</Card.Title>
                  <Table
                    columns={this.columnsRoles}
                    data={this.props.roles ? this.props.roles : []}
                    keyPath="guid"
                    disableSearch
                    disableSort
                    columnsComponent={createColComponentForTable(
                      this.columnsRoles
                    )}
                  />
                  {ability.can("EXECUTE", "ADMINROLES") && (
                    <RowAddRole
                      type="admin"
                      guid={this.props.match.params.id}
                    />
                  )}
                </Card.Body>
              </Card>
            )}
          </Row>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admins.admin,
    roles: state.admins.adminRoles,
    adminLoading: state.admins.adminLoading,
  };
};

export default connect(mapStateToProps, {
  getAdminAction,
  getAdminRolesAction,
  deleteAdminRoleAction,
})(AdminDetail);

AdminDetail.propTypes = {
  admin: PropTypes.object,
  adminLoading: PropTypes.bool,
  deleteAdminRoleAction: PropTypes.func,
  getAdminAction: PropTypes.func,
  getAdminRolesAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  roles: PropTypes.array,
};
