import React, { Component } from "react";
import { Card, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { showModal } from "../../actions/modal";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner/index";
import { getServiceAction } from "actions/services";

class ServiceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      startTime: "",
      options: {},
    };
  }

  async componentDidMount() {
    await this.props.getServiceAction(this.props.name);
    const service = this.props.service;
    this.setState({
      name: service.name,
      startTime: service.start_time,
      options: service.options,
    });
  }

  renderArrayOptions = () => {
    const items = [];
    for (let index = 0; index < this.state.options.length; index++) {
      const element = this.state.options[index];
      let obj = Object.keys(element).map((key) => {
        return (
          <tr key={key}>
            <th>{key.replace(/_/g, " ")}:</th>
            <td>{element[key]}</td>
          </tr>
        );
      });
      items.push(obj);
    }
    return items;
  };

  render() {
    if (this.props.isLoading) {
      return <Spinner />;
    } else
      return (
        <Card>
          <Card.Header as="h5">Service: {this.props.name}</Card.Header>
          <Card.Body>
            <Card.Title>Settings</Card.Title>


            <Table responsive className="detailInfo">
              <tbody>
                <tr>
                  <th>Start time: </th>
                  <td>{this.state.startTime}</td>
                </tr>
                {Array.isArray(this.state.options)
                  ? this.renderArrayOptions()
                  : Object.keys(this.state.options).length !== 0
                    ? Object.keys(this.state.options).map((key) => {
                      return (
                        <tr key={key}>
                          <th>{key.replace(/_/g, " ")}:</th>
                          {Array.isArray(this.state.options[key]) ? (
                            <td>
                              {this.state.options[key].map((item) => {
                                return item + ", ";
                              })}
                            </td>
                          ) : (
                            <td>{this.state.options[key]}</td>
                          )}
                        </tr>
                      );
                    })
                    : null}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

      );
  }
}

const mapStateToProps = (state) => {
  return {
    service: state.services.service,
    isLoading: state.services.serviceLoading,
  };
};

export default connect(mapStateToProps, {
  getServiceAction,
  showModal,
})(ServiceHistory);

ServiceHistory.propTypes = {
  getServiceAction: PropTypes.func,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  service: PropTypes.array,
};
