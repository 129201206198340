import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "components/UI/Table/index";
import Pagination from "components/UI/Pagination/index";
import { connect } from "react-redux";
import { getShopTotalsAction } from "actions/shops";
import Spinner from "components/UI/Spinner/index";
import moment from "moment";
import PropTypes from "prop-types";
import { Export } from "components/Export";
import { getShopTotals } from "../../../services/paymentBackendAPI/reports/transactions";

export class ShopTotals extends Component {
  state = {
    shops: [],
    count: "",
    currentPage: 1,
    pageSize: 10,
    data: {
      merchant_guid_array: [],
      group_guid_array: [],
      partner_guid_array: [],
      terminal_guid_array: [],
      shop_guid_array: [],
      from_date: moment()
        .format("YYYY-MM-DDTHH:mm:ss")
        .replace(/\D\d{2}\w/, "-01T"),
      to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    },
  };

  columnsShops = [
    { path: "shop_guid", label: "ID" },
    { path: "shop_name", label: "Shop name" },
    { path: "currency", label: "Currency" },
    { path: "type", label: "Transaction type" },
    { path: "status", label: "Status" },
    { path: "amount", label: "Amount" },
    { path: "number", label: "Count" },
  ];

  async componentDidMount() {
    await this.props.getShopTotalsAction({
      page: 1,
      items: this.state.pageSize,
      ...this.state.data,
    });
    this.setState({
      shops: this.props.shops,
      count: this.props.count,
    });
  }

  shouldComponentUpdate = async (nextProps) => {
    if (nextProps.data !== this.props.data) {
      await this.props.getShopTotalsAction({
        page: 1,
        items: this.state.pageSize,
        ...nextProps.data,
      });
    }
    return true;
  };

  handlePageChange = async (page) => {
    await this.props.getShopTotalsAction({
      page,
      items: this.state.pageSize,
      ...this.props.data,
    });
    this.setState({
      shops: this.props.shops,
      count: this.props.count,
      currentPage: page,
    });
  };

  getPagedData = () => {
    const { count } = this.props;
    let { pageSize } = this.state;
    const pagesCount = count
      ? count / pageSize + (1 && !!(count % pageSize))
      : 0;
    return { pagesCount };
  };

  // handleClick = () => {
  //   this.setState({ data: data }, () => {
  //     this.props.getShopTotalsAction({
  //       page: 1,
  //       items: this.state.pageSize,
  //       from_date: this.state.data.from_date,
  //       to_date: this.state.data.to_date,
  //     });
  //   });
  //   this.setState({
  //     shops: this.props.shops,
  //     count: this.props.count,
  //   });
  // };

  render() {
    const { currentPage } = this.state;
    const { pagesCount } = this.getPagedData();
    if (this.props.reportShopsLoading)
      return (
        <>
          {/* <Row>
            <Col md={2} sm={2} xs={2}>
              <label style={{ marginRight: "5px" }}>Date rage</label>
              <DateRangePicker
                onCallback={this.onDateChange}
                initialSettings={{
                  startDate: moment(this.state.from_date).format("DD.MM.YYYY"),
                  // endDate: this.props.currentSearchData.to_date ? moment(this.props.currentSearchData.to_date).format("DD.MM.YYYY") : undefined,
                  locale: {
                    format: "DD.MM.YYYY",
                  },
                }}
              >
                <input
                  type="text"
                  className="text-input daterange-input form-control"
                />
              </DateRangePicker>
            </Col>
            {/* <Col md={2} sm={2} xs={2}>
            <label style={{ marginRight: "5px" }}>to</label>
            <input
              type="date"
              className="datetimepicker"
              name="toDate"
              onChange={this.handleTimeChange} >
            </input>

          </Col>
            <Col md={2} sm={2} xs={2}>
              <CustomButton className="btn" onClick={this.handleClick}>
                Find
              </CustomButton>
            </Col>
          </Row>
         */}
          <Spinner />
        </>
      );
    else
      return (
        <>
          <Row>
            <Col md={8} sm={8} xs={8}>
              {/* <label style={{ marginRight: "5px" }}>from</label>
              <DateRangePicker
                onCallback={this.onDateChange}
                initialSettings={{
                  // startDate:  moment(this.state.from_date).format("DD.MM.YYYY"),
                  // endDate: this.props.currentSearchData.to_date ? moment(this.props.currentSearchData.to_date).format("DD.MM.YYYY") : undefined,
                  locale: {
                    format: "DD.MM.YYYY",
                  },
                }}
              >
                <input
                  type="text"
                  className="text-input daterange-input form-control"
                />
              </DateRangePicker> */}
            </Col>
            {/* <Col md={4} sm={4} xs={4}>
            <label style={{ marginRight: "5px" }}>to</label>
            <input
              type="date"
              className="datetimepicker"
              name="toDate"
              onChange={this.handleTimeChange} >
            </input>

          </Col> */}
            {/* <Col md={2} sm={2} xs={2}>
              <CustomButton className="btn" onClick={this.handleClick}>
                Find
              </CustomButton>
            </Col> */}
            <Col md={4} sm={4} xs={4}>
              <Export
                id={"totals"}
                // searchData={this.getSearchData()}
                exportFunction={getShopTotals}
                columns={this.columnsShops}
                name={"BasicReportShopTotals"}
                style={{ float: "right" }}
              />
            </Col>
          </Row>
          {this.props.reportShopsLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="content" style={{ overflow: "auto" }}>
                <Row>
                  <Col md={12}>
                    <div className="table">
                      <Table
                        columns={this.columnsShops}
                        data={this.props.shops}
                        keyPath={[ "shop_guid", "type", "status" ]}
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={pagesCount}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                        pageSize={this.state.pageSize}
                        count={this.props.count}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shops.reportSpops,
    count: state.shops.reportsCount,
    reportShopsLoading: state.shops.reportShopsLoading,
  };
};

export default connect(mapStateToProps, {
  getShopTotalsAction,
})(ShopTotals);

ShopTotals.propTypes = {
  count: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  getShopTotalsAction: PropTypes.func,
  reportShopsLoading: PropTypes.bool,
  shops: PropTypes.array,
};
