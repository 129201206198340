export const ROLES = "ROLES";
export const ROLESDETAIL = "ROLESDETAIL";

export const USERPROFILE = "USERPROFILE";

export const ADMINS = "USERADMIN";
export const ADMINDETAIL = "USERADMINDETAIL";

export const PARTNERS = "USERPARTNER";
export const PARTNERDETAIL = "USERPARTNERDETAIL";
export const PARTNERGROUP = "PARTNERGROUP";
export const PARTNERMERCHANT = "PARTNERMERCHANT";
export const PARTNERSHOP = "PARTNERSHOP";

export const GROUPS = "USERGROUP";
export const GROUPDETAIL = "USERGROUPDETAIL";
export const GROUPLOGIN = "GROUPLOGIN";
export const GROUPMERCHANT = "GROUPMERCHANT";
export const GROUPSHOP = "GROUPSHOP";

export const MERCHANTS = "USERMERCHANT";
export const MERCHANTDETAIL = "USERMERCHANTDETAIL";
export const MERCHANTSHOP = "MERCHANTSHOP";
export const MERCHANTBLACKLIST = "MERCHANTBLACKLIST";

export const CURRENCIES = "CURRENCIES";
export const CHARGEBACKS = "CHARGEBACKS";

export const SERVICES = "SERVICES";

export const MERCHANTACCOUNT = "MERCHANTACCOUNT";
export const ACCOUNTDETAIL = "ACCOUNTDETAIL";
export const CARDS = "CARDS";
export const CARDDETAIL = "CARDDETAIL";

export const GATEWAYS = "GATEWAYS";
export const GATEWAYDETAIL = "GATEWAYDETAIL";

export const SHOPS = "SHOPS";
export const SHOPDETAIL = "SHOPDETAIL";

export const SHOPTERMINALDETAIL = "SHOPTERMINALDETAIL";

export const RATES = "RATES";
export const RATEDETAIL = "RATEDETAIL";

export const BLACKLISTS = "BLACKLISTS";
export const BLACKLISTDETAIL = "BLACKLISTDETAIL";
export const GLOBALBLACKLISTS = "GLOBALBLACKLISTS";

export const TRANSACTIONTEMPLATES = "TRANSACTIONTEMPLATES";
export const TRANSACTIONSHISTORY = "TRANSACTIONSHISTORY";

export const USERSLOGS = "USERSLOGS";
export const TRANSACTIONSLOGSMONGO = "TRANSACTIONSLOGSMONGO";

export const ORDERS = "ORDERS";
export const ORDERDETAIL = "ORDERDETAIL";

export const STATEMENTS = "STATEMENTS";
export const STATEMENTDETAIL = "STATEMENTDETAIL";

export const LOGINS = "LOGINS";
export const ACCOUNTS = "ACCOUNTS";
export const TERMINALS = "TERMINALS";
export const TRANSACTIONPROCESSINGS = "TRANSACTIONPROCESSINGS";
export const TRANSACTIONDATA = "TRANSACTIONDATA";
export const TRANSACTIONOVERVIEWS = "TRANSACTIONOVERVIEWS";
export const SERVICESETTINGS = "SERVICESETTINGS";
export const TRANSACTION = "TRANSACTION";

export const TRANSACTIONERRORS = "TRANSACTIONERRORS";
export const TRANSACTIONHISTOGRAM = "TRANSACTIONHISTOGRAM";

export const ADMINDASHBOARD =
  [ "CURRENCIESOFTRANSACTIONS",
    "DAILYTRANSACTIONS",
    "NEWCLIENTS",
    "TRANSACTIONTYPES",
    "TOTALPROCESSED",
    "TOPMERCHANTS",
  ];

export const DAILYREPORT =
  [ "DAILYTRANSACTIONS",
    "CURRENCIES",
    "TRANSACTIONHISTORY",
    "TOTALPROCESSED",
    "TOPSHOPS",
    "TOPMERCHANTS",
    "TOPGROUPS",
    "TOPPARTNERS",
  ];

export const BASICREPORT =
  [ "TRANSACTIONTYPES",
    "CURRENCIES",
    "TOTALS",
    "SHOPTOTALS",
    "ORDERS",
  ];

export const TRANSACTIONANDCOMISSION = "TRANSACTIONANDCOMISSION";

export const GROUPDASHBOARD =
  [ "TRANSACTIONTYPES",
    "TOTALPROCESSED",
    "CURRENCIESOFTRANSACTIONS",
    "TOPSHOPS",
    "TOPMERCHANTS",
    "AMOUNTOFSHOPS",
    "AMOUNTOFMERCHANTS",
    "TRANSACTIONHISTORY"
  ];

export const MERCHANTDASHBOARD =
  [ "TRANSACTIONTYPES",
    "TOTALPROCESSED",
    "CURRENCIESOFTRANSACTIONS",
    "TOPSHOPS",
    "TOPMERCHANTS",
    "AMOUNTOFSHOPS",
    "AMOUNTOFMERCHANTS",
    "TRANSACTIONHISTORY"
  ];

export const PARTNERDASHBOARD =
  [ "TRANSACTIONTYPES",
    "TOTALPROCESSED",
    "CURRENCIESOFTRANSACTIONS",
    "TOPSHOPS",
    "TOPMERCHANTS",
    "TOPGROUPS",
    "AMOUNTOFSHOPS",
    "AMOUNTOFGROUPS",
    "AMOUNTOFMERCHANTS",
    "TRANSACTIONHISTORY"
  ];

export const SERVICESLOG =
  [
    "SERVICEDECTARECOT",
    "SERVICECHARGEBACK",
    "SERVICEDELETECARDDATA",
    "SERVICECURRENCYEXCHANGE",
    "SERVICELOGINEXPIRED"
  ];

export const USERSAUDIT =
  [
    "LOGINS",
    "MERCHANTS",
    "GROUPS",
    "PARTNERS"
  ];

export const TRANSACTIONAUDIT =
  [
    "TRANSACTIONOVERVIEWS",
    "TRANSACTIONDATA ",
    "TRANSACTIONPROCESSINGS"
  ];