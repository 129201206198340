import React, { Component } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import config from "config/";
import { getAllShops, getShopTerminalsAction } from "actions/shops";
import {
  addTransactionAction,
  getTransactionTypesForGatewayAction,
} from "actions/transactions";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { addParamsInUrl } from "helpers/addParamsInUrl";
import queryString from "query-string";
import Alert from "../../UI/Alert";
import MultiSelect from "components/UI/MultiSelect";

class NewTransactionForm extends Component {
  state = {
    data: {
      shop: "",
      tracking_id: "",
      terminal: "",
      cardNumber: "",
      verificationValue: "",
      nameOnCard: "",
      expMonth: "",
      expYear: "",
      amount: 100,
      currency: "",
      type: "",
      terminalSecret: "",
      terminalHashkey: "",
      transactionId: "",
      reason: "",
    },
    withCard: false,
    showMore: true,
    checkFlag: true,
    disabledTerminal: true,
    disabledType: true,
    errorMessage: "",
    successMessage: "",
    errors: {},
    isLoading: false,
    isNeedTransactionId: false,
    isNeedReason: false,
    testData: {
      cardNumber: "4242424242424242",
      nameOnCard: "DAN BALAN",
      expMonth: "12",
      expYear: "2020",
      verificationValue: "123",
      description: "PEWPEW",
      billing_address: {
        first_name: "Ivan",
        last_name: "Vovanov",
        country: "US",
        city: "New York",
        address: "Groove st.",
        zip: "646464",
      },
      customer: {
        email: "mail@mail.com",
        ip: "200.200.200.200",
        phone: "48000111222",
      },
    },
  };

  withCardSchema = {
    shop: Joi.string().required().min(0).label("Shop"),
    terminal: Joi.string().required().min(0).label("Terminal"),
    terminalSecret: Joi.string().label("Terminal Secret"),
    terminalHashkey: Joi.string().label("Terminal Hash Key"),
    cardNumber: Joi.string()
      .min(12)
      .max(19)
      .creditCard()
      .required()
      .label("Card Number"),
    verificationValue: Joi.number().required().label("Security code"),
    nameOnCard: Joi.string().required().min(0).max(150).label("Name on card"),
    expMonth: Joi.number()
      .positive()
      .min(1)
      .max(12)
      .required()
      .label("Expiry month"),
    expYear: Joi.number().positive().min(2019).required().label("Expiry year"),
    type: Joi.string().required().label("Transaction type"),
    transactionId: Joi.string().label("Transaction ID"),
    amount: Joi.number().required().label("Amount"),
    currency: Joi.string().required().label("Currency"),
    tracking_id: Joi.string().required().min(0).label("Tracking id"),
    reason: Joi.any(),
  };

  withoutCardSchema = {
    shop: Joi.string().required().min(0).label("Shop"),
    terminal: Joi.string().required().min(0).label("Terminal"),
    terminalSecret: Joi.string().label("Shop Secret"),
    terminalHashkey: Joi.string().label("Shop Hash Key"),
    cardNumber: Joi.string().allow(""),
    verificationValue: Joi.string().allow(""),
    nameOnCard: Joi.string().allow(""),
    expMonth: Joi.string().allow(""),
    expYear: Joi.string().allow(""),
    type: Joi.string().required().label("Transaction type"),
    transactionId: Joi.string().label("Transaction ID").allow("").optional(),
    amount: Joi.number().required().label("Amount"),
    currency: Joi.string().required().label("Currency"),
    tracking_id: Joi.string().required().min(0).label("Tracking id"),
    reason: Joi.any(),
  };

  async componentDidMount() {
    await this.props.getAllShops();
    const values = queryString.parse(window.location.hash.split("?")[1]);
    if (values.status === "succeed")
      swal({
        title: "Transaction submitted",
        text: values.guid ? `Transaction guid: ${values.guid}` : "Success",
        icon: "success",
      });
    else if (values.status === "failed")
      Alert({
        type: "error",
        message: values.guid
          ? `Transaction failed, guid: ${values.guid} `
          : "Failed",
      });
    window.location.replace(window.location.href.split("?")[0]);

    if (this.props.location.state) {
      await this.props.getShopTerminalsAction(this.props.location.state.order.transaction_details.shop_guid);
      await this.props.getTransactionTypesForGatewayAction(
        this.props.terminals.filter(
          (terminal) => this.props.location.state.order.transaction_details.terminal_guid === terminal.guid
        )[0].gateway_guid
      );
      const data = { ...this.state.data };
      data.transactionId = this.props.location.state.order.transaction_details.guid;
      data.type = this.props.location.state.type;
      data.shop = this.props.location.state.order.transaction_details.shop_guid;
      data.terminal = this.props.location.state.order.transaction_details.terminal_guid;
      data.amount = this.props.location.state.order.transaction_details.amount;
      data.currency = this.props.location.state.order.transaction_details.currency;
      this.setState({
        isNeedTransactionId: true,
        withCard: false,
        data,
      });
      if ([ "Refund", "Cancel", "Reverse" ].includes(data.type))
        this.setState({
          isNeedReason: true,
        });
      else if ([ "Authorization", "Payment", "Capture" ].includes(data.type))
        this.setState({ isNeedReason: false });
    }
  }

  validate = () => {
    const options = { abortEarly: false };
    const schema = this.state.withCard
      ? this.withCardSchema
      : this.withoutCardSchema;
    const { error } = Joi.validate(this.state.data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  formValidation = (errors) => {
    if (errors) return "error";
    else return "success";
  };

  validateProperty = ({ name, value }) => {
    let schema = this.state.withCard
      ? this.withCardSchema
      : this.withoutCardSchema;
    const obj = { [name]: value };
    schema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    // if (["Refund", "Capture", "Cancel", "Reverse"].includes(input.value))
    //   this.setState({
    //     isNeedTransactionId: true,
    //     withCard: false,
    //   });
    // else if (["Authorization", "Payment"].includes(input.value))
    //   this.setState({ isNeedTransactionId: false });
    // if (["Refund", "Cancel", "Reverse"].includes(input.value))
    //   this.setState({
    //     isNeedReason: true,
    //   });
    // else if (["Authorization", "Payment", "Capture"].includes(input.value))
    //   this.setState({ isNeedReason: false });
    this.setState({ data, errors });
    // if (input.name === "shop") {
    //   await this.props.getShopTerminalsAction(input.value);
    //   this.setState({ disabledTerminal: false });
    // }
    // if (input.name === "terminal") {
    //   const array = this.props.terminals.filter(
    //     (terminal) => terminal.guid === input.value
    //   );
    //   await this.props.getTransactionTypesForGatewayAction(
    //     array[0].gateway_guid
    //   );
    //   data.currency = array[0].currency_code;
    //   this.setState({ disabledType: false, data });
    // }
  };

  onSelectType = async (option) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({ name: "type", value: option.name });
    if (errorMessage) errors.shop = errorMessage;
    else delete errors.shop;

    const data = { ...this.state.data };
    data.type = option.name;
    if ([ "Refund", "Capture", "Cancel", "Reverse" ].includes(option.name))
      this.setState({
        isNeedTransactionId: true,
        withCard: false,
      });
    else if ([ "Authorization", "Payment" ].includes(option.name))
      this.setState({ isNeedTransactionId: false });
    if ([ "Refund", "Cancel", "Reverse" ].includes(option.name))
      this.setState({
        isNeedReason: true,
      });
    else if ([ "Authorization", "Payment", "Capture" ].includes(option.name))
      this.setState({ isNeedReason: false });
    this.setState({ data, errors });
  };

  onSelectShop = async (option) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({ name: "shop", value: option.guid });
    if (errorMessage) errors.shop = errorMessage;
    else delete errors.shop;

    const data = { ...this.state.data };
    data.shop = option.guid;

    this.setState({ data, errors });

    await this.props.getShopTerminalsAction(option.guid);
    this.setState({ disabledTerminal: false });
  };

  onSelectTerminal = async (option) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({ name: "terminal", value: option.guid });
    if (errorMessage) errors.terminal = errorMessage;
    else delete errors.terminal;

    let data = { ...this.state.data };
    data.terminal = option.guid;

    this.setState({ data, errors });

    const array = this.props.terminals.filter(
      (terminal) => terminal.guid === option.guid
    );
    await this.props.getTransactionTypesForGatewayAction(
      array[0].gateway_guid
    );
    data = { ...this.state.data };
    data.currency = array[0].currency_code;
    this.setState({ disabledType: false, data });
  };

  doChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleCheck = () => {
    const { data } = this.state;
    this.setState({
      checkFlag: !this.state.checkFlag,
    });
    if (this.state.checkFlag && this.state.withCard) {
      this.setState({
        data: {
          cardNumber: this.state.testData.cardNumber,
          nameOnCard: this.state.testData.nameOnCard,
          expMonth: this.state.testData.expMonth,
          expYear: this.state.testData.expYear,
          verificationValue: this.state.testData.verificationValue,
          shop: data.shop,
          terminal: data.terminal,
          amount: data.amount,
          currency: data.currency,
          tracking_id: data.tracking_id,
          type: data.type,
          terminalSecret: data.terminalSecret,
          terminalHashkey: data.terminalHashkey,
        },
      });
    } else {
      this.setState({
        data: {
          cardNumber: "",
          nameOnCard: "",
          expMonth: "",
          expYear: "",
          verificationValue: "",
          shop: data.shop,
          terminal: data.terminal,
          type: data.type,
          amount: data.amount,
          tracking_id: data.tracking_id,
          currency: data.currency,
          terminalSecret: data.terminalSecret,
          terminalHashkey: data.terminalHashkey,
        },
      });
    }
  };

  handleChangeCardData = () => {
    const { data } = this.state;
    this.setState({
      withCard: !this.state.withCard,
      checkFlag: !this.state.withCard,
      data: {
        cardNumber: "",
        nameOnCard: "",
        expMonth: "",
        expYear: "",
        verificationValue: "",
        shop: data.shop,
        terminal: data.terminal,
        amount: data.amount,
        currency: data.currency,
        tracking_id: data.tracking_id,
        type: data.type,
        terminalSecret: data.terminalSecret,
        terminalHashkey: data.terminalHashkey,
      },
    });
  };

  handleShowMore = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  formUrlEncoded = (x) =>
    Object.keys(x).reduce(
      (p, c) => p + `&${c}=${encodeURIComponent(x[c])}`,
      ""
    );

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    try {
      let data = {};
      let type = this.state.data.type;
      switch (type) {
        case "Payment":
        case "Authorization":
          data = {
            tracking_id: this.state.data.tracking_id,
            //terminalGuid: this.state.data.terminal,
            amount: +this.state.data.amount,
            currency: this.state.data.currency,
            description: this.state.testData.description,
            type: this.state.data.type,
            billing_address: {
              first_name: this.state.testData.billing_address.first_name,
              last_name: this.state.testData.billing_address.last_name,
              country: this.state.testData.billing_address.country,
              city: this.state.testData.billing_address.city,
              address: this.state.testData.billing_address.address,
              zip: this.state.testData.billing_address.zip,
            },
            customer: {
              email: this.state.testData.customer.email,
              ip: this.state.testData.customer.ip,
              phone: this.state.testData.customer.phone,
            },
          };
          break;
        case "Cancel":
        case "Refund":
        case "Reverse":
          data = {
            tracking_id: this.state.data.tracking_id,
            amount: +this.state.data.amount,
            type: this.state.data.type,
            transactionId: this.state.data.transactionId,
            reason: this.state.isNeedReason
              ? this.state.data.reason
              : undefined,
          };
          break;
        case "Capture":
          data = {
            tracking_id: this.state.data.tracking_id,
            amount: +this.state.data.amount,
            type: this.state.data.type,
            transactionId: this.state.data.transactionId,
          };
          break;
        default:
          break;
      }
      const credit_card = {
        number: this.state.data.cardNumber,
        verification_value: this.state.data.verificationValue,
        holder: this.state.data.nameOnCard,
        exp_month: this.state.data.expMonth,
        exp_year: this.state.data.expYear,
      };
      if (this.state.withCard) data.credit_card = credit_card;
      else {
        data.succeed_url = `${window.location}?status=succeed`;
        data.failed_url = `${window.location}?status=failed`;
      }

      this.setState({ isLoading: true });
      await this.props.addTransactionAction(
        data,
        this.state.data.terminal,
        this.state.data.terminalSecret,
        this.state.data.terminalHashkey
      );
      this.setState({ isLoading: false });
      let transactionGuid = "Transaction guid: " + this.props.transaction.guid;
      if (this.props.transaction.status === "Failed") {
        Alert({ type: "error", message: transactionGuid + ", Failed" });
      } else if (
        this.props.transaction.redirect_to &&
        this.props.transaction.redirect_to.url &&
        this.props.transaction.redirect_to.params
      ) {
        const redirectUrlWithoutParams = `${config.node.host}/api/v1${this.props.transaction.redirect_to.url}`;
        const redirectUrl = addParamsInUrl(
          redirectUrlWithoutParams,
          this.props.transaction.redirect_to.params
        );
        window.location.href = redirectUrl;
      } else {
        swal({
          title: "Transaction submitted",
          text: transactionGuid,
          icon: "success",
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  renderTypeInput = () => {
    const { errors, disabledType } = this.state;
    const typesForGateway = this.props.typesForGateway
      ? this.props.typesForGateway
      : [];
    if (this.props.location.state) {
      let type = typesForGateway.filter(type => type.name === this.props.location.state.type);

      return (
        <>
          <Form.Label>Transaction Type *</Form.Label>
          <Form.Group>
            <MultiSelect
              name="type"
              options={type}
              multi={false}
              onSelect={this.onSelectType}
              placeholder="Select type"
              value={type[0]}
            />

          </Form.Group>
        </>
      );
    }

    else
      return (
        <>
          <Form.Label>Transaction Type *</Form.Label>
          <Form.Group validationState={this.formValidation(errors.type)}>
            <MultiSelect
              name="type"
              options={typesForGateway}
              multi={false}
              onSelect={this.onSelectType}
              placeholder="Select type"
              disabled={disabledType ? true : false}
            />

            {errors.type && (
              <span className="validate-error">{errors.type}</span>
            )}
          </Form.Group>
        </>
      );
  };

  renderShopInput = () => {
    const shops = this.props.shops ? this.props.shops.map(shop => ({ ...shop, name: shop.guid + " / " + shop.name })) : [];
    const { errors } = this.state;
    if (this.props.location.state) {
      let { shop_guid, shop_name } = this.props.location.state.order.transaction_details;
      let shop = {
        name: shop_guid + " / " + shop_name,
        label: shop_guid + " / " + shop_name,
        value: shop_guid,
        guid: shop_guid
      };
      return (
        <>
          <Form.Label>Shop *</Form.Label>
          <Form.Group>
            <MultiSelect
              name="shop"
              options={[ shop ]}
              multi={false}
              onSelect={this.onSelectShop}
              placeholder="Select shop"
              defaultValue={shop}
            />
          </Form.Group>
        </>
      );
    }
    else
      return (
        <>
          <Form.Label>Shop *</Form.Label>
          <Form.Group validationState={this.formValidation(errors.shop)}>
            <MultiSelect
              name="shop"
              options={shops}
              multi={false}
              onSelect={this.onSelectShop}
              placeholder="Select shop"
            />
            {errors.shop && (
              <span className="validate-error">{errors.shop}</span>
            )}
          </Form.Group>
        </>
      );
  };

  renderTerminalInput = () => {
    const terminals = this.props.terminals ? this.props.terminals.map(terminal => ({ ...terminal, name: terminal.guid + " / " + terminal.name })) : [];
    const { errors, disabledTerminal } = this.state;
    if (this.props.location.state) {
      let { terminal_guid, terminal_name } = this.props.location.state.order.transaction_details;
      let terminal = {
        name: terminal_guid + " / " + terminal_name,
        label: terminal_guid + " / " + terminal_name,
        value: terminal_guid,
        guid: terminal_guid
      };

      return (
        <>
          <Form.Label>Terminal *</Form.Label>
          <Form.Group>
            <MultiSelect
              name="terminal"
              options={[ terminal ]}
              multi={false}
              onSelect={this.onSelectTerminal}
              placeholder="Select shop"
              defaultValue={terminal}
            />
          </Form.Group>
        </>
      );
    }

    else
      return (
        <>
          <Form.Label>Terminal *</Form.Label>
          <Form.Group validationState={this.formValidation(errors.terminal)}>
            <MultiSelect
              name="terminal"
              options={terminals}
              multi={false}
              onSelect={this.onSelectTerminal}
              disabled={disabledTerminal ? true : false}
              placeholder="Select terminal"

            />
            {errors.terminal && (
              <span className="validate-error">{errors.terminal}</span>
            )}
          </Form.Group>
        </>
      );
  };

  render() {
    const { data, testData, errors } = this.state;

    const type = this.renderTypeInput();
    const shop = this.renderShopInput();
    const terminal = this.renderTerminalInput();
    return (
      <React.Fragment>
        <Card>
          <Card.Body style={{ overflow: "initial" }}>

            <Form onSubmit={this.handleSubmit} autoComplete="off">

              <Button
                className="btn btn-sm"
                onClick={this.handleShowMore}
                style={{ marginLeft: "10px" }}
              >
                Show more
              </Button>
              {!this.state.showMore ? (
                <React.Fragment>
                  <div className="content">
                    <h5 style={{ marginTop: "0px" }}>Billing Data</h5>
                    <Row>
                      <Col md={2}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Group>
                          <span>{testData.billing_address.first_name}</span>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Group>
                          <span>{testData.billing_address.last_name}</span>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Label>Country</Form.Label>
                        <Form.Group>
                          <span>{testData.billing_address.country}</span>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <Form.Label>City</Form.Label>
                        <Form.Group>
                          <span>{testData.billing_address.city}</span>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Label>Address</Form.Label>
                        <Form.Group>
                          <span>{testData.billing_address.address}</span>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Label>Zip</Form.Label>
                        <Form.Group>
                          <span>{testData.billing_address.zip}</span>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className="content">
                    <h5 style={{ marginTop: "0px" }}>Customer Data</h5>
                    <Row>
                      <Col md={2}>
                        <Form.Label>Email</Form.Label>
                        <Form.Group>
                          <span>{testData.customer.email}</span>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Label>IP</Form.Label>
                        <Form.Group>
                          <span>{testData.customer.ip}</span>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Label>Phone</Form.Label>
                        <Form.Group>
                          <span>{testData.customer.phone}</span>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className="content">
                    <h5 style={{ marginTop: "0px" }}>Payment Data</h5>
                    <Row>
                      <Col md={2}>
                        <Form.Label>Description</Form.Label>
                        <Form.Group>
                          <span>{testData.description}</span>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ) : null}
              <div className="card-container">
                <Col md={3}>{shop}</Col>
                <Col md={3}>{terminal}</Col>
                <Col md={3}>
                  <Form.Label>Terminal secret</Form.Label>
                  <Form.Group>
                    <Form.Control
                      name="terminalSecret"
                      value={this.state.terminalSecret}
                      onChange={this.handleChange}
                    />
                    {errors.terminalSecret && (
                      <span className="validate-error">
                        {errors.terminalSecret}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Label>Hash key</Form.Label>
                  <Form.Group>
                    <Form.Control
                      name="terminalHashkey"
                      value={this.state.terminalHashkey}
                      onChange={this.handleChange}
                    />
                    {errors.terminalHashkey && (
                      <span className="validate-error">
                        {errors.terminalHashkey}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </div>
              <div className="card-container">
                <Col md={3}>{type}</Col>
                {this.state.isNeedTransactionId && (
                  <Col md={3}>
                    <Form.Label>Transaction id </Form.Label>
                    <Form.Group>
                      <Form.Control
                        name="transactionId"
                        value={
                          this.props.location.state
                            ? this.props.location.state.order
                              .transaction_details.guid
                            : data.transactionId
                        }
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>
                )}

                <Col md={3}>
                  <Form.Label>Amount </Form.Label>
                  <Form.Group>
                    <Form.Control
                      name="amount"
                      value={data.amount}
                      onChange={this.handleChange}
                    />
                    {errors.amount && (
                      <span className="validate-error">{errors.amount}</span>
                    )}
                    {errors.currency && (
                      <span className="validate-error">
                        {errors.currency}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                {data.currency ? (
                  <span style={{ paddingTop: "35px" }}>{data.currency}</span>
                ) : null}
                <Col md={3}>
                  <Form.Label>Tracking id</Form.Label>
                  <Form.Group>
                    <Form.Control
                      name="tracking_id"
                      value={data.tracking_id}
                      onChange={this.handleChange}
                    />
                    {errors.tracking_id && (
                      <span className="validate-error">
                        {errors.tracking_id}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </div>

              <div className="card-container">
                {this.state.isNeedReason && (
                  <Col md={3}>
                    <Form.Label>Reason </Form.Label>
                    <Form.Group>
                      <Form.Control
                        name="reason"
                        value={data.reason}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>
                )}
              </div>

              {!this.state.isNeedTransactionId && (
                <>
                  <div className="card-grid" style={{ marginLeft: "20px" }}>
                    <input
                      type="checkbox"
                      id="applyTestAccountCheckbox"
                      onChange={this.handleChangeCardData}
                    />
                    <label>Enter card data </label>
                    <i
                      className="far fa-question-circle"
                      title="You can enter card data here or on redirected page"
                    />
                  </div>
                  {this.state.withCard && (
                    <>
                      <div
                        className="card-grid"
                        style={{ marginLeft: "20px" }}
                      >
                        <input
                          type="checkbox"
                          id="applyTestAccountCheckbox"
                          onChange={this.handleCheck}
                        />
                        <label>Apply test account settings </label>
                        <i
                          className="far fa-question-circle"
                          title="Credit card data will be automatically taken from Test
                      Account Settings section of shop settings."
                        />
                      </div>
                      <div className="card">
                        <div className="content">
                          <h5 style={{ marginTop: "0px" }}>
                            Credit Card Data
                          </h5>
                          <Row>
                            <Col md={3}>
                              <Form.Label>Card Number *</Form.Label>
                              <i className="far fa-question-circle help-tip">
                                <p>
                                  16-digit number on the front of your credit
                                  card.
                                </p>
                              </i>
                              <Form.Group
                                validationState={this.formValidation(
                                  errors.cardNumber
                                )}
                              >
                                <Form.Control
                                  name="cardNumber"
                                  type="number"
                                  value={data.cardNumber}
                                  placeholder="0000 0000 0000 0000"
                                  className="no-spinners"
                                  onChange={this.handleChange}
                                />
                                {errors.cardNumber && (
                                  <span className="validate-error">
                                    {errors.cardNumber}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Label>Full name on card * </Form.Label>
                              <Form.Group
                                validationState={this.formValidation(
                                  errors.nameOnCard
                                )}
                              >
                                <Form.Control
                                  name="nameOnCard"
                                  value={data.nameOnCard}
                                  placeholder="ex. John Doe"
                                  onChange={this.handleChange}
                                />
                                {errors.nameOnCard && (
                                  <span className="validate-error">
                                    {errors.nameOnCard}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Label>Expiry month *</Form.Label>
                              <Form.Group
                                validationState={this.formValidation(
                                  errors.expMonth
                                )}
                              >
                                <Form.Control
                                  name="expMonth"
                                  value={data.expMonth}
                                  maxLength="2"
                                  onChange={this.handleChange}
                                  placeholder="MM"
                                />
                                {errors.expMonth && (
                                  <span className="validate-error">
                                    {errors.expMonth}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>

                            <Col md={2}>
                              <Form.Label>Expiry year *</Form.Label>
                              <Form.Group
                                validationState={this.formValidation(
                                  errors.expYear
                                )}
                              >
                                <Form.Control
                                  name="expYear"
                                  value={data.expYear}
                                  maxLength="4"
                                  placeholder="YYYY"
                                  onChange={this.handleChange}
                                />
                                {errors.expYear && (
                                  <span className="validate-error">
                                    {errors.expYear}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Label>Security code * </Form.Label>
                              <i className="far fa-question-circle help-tip">
                                <p>
                                  3 digits displayed on the back of your card.
                                </p>
                              </i>
                              <Form.Group
                                validationState={this.formValidation(
                                  errors.verificationValue
                                )}
                              >
                                <Form.Control
                                  name="verificationValue"
                                  value={data.verificationValue}
                                  placeholder="***"
                                  maxLength="3"
                                  onChange={this.handleChange}
                                />
                                {errors.verificationValue && (
                                  <span className="validate-error">
                                    {errors.verificationValue}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <div>
                {this.state.isLoading ? (
                  <ReactLoading type="cylon" color="grey" />
                ) : (
                  <Button className="btn btn-fill btn-primary" type="submit">
                      Send
                  </Button>
                )}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    transaction: state.transactions.transaction,
    typesForGateway: state.transactions.transactionTypesForGateway,
    status: state.transactions.status,
  };
};

export default connect(mapStateToProps, {
  getAllShops,
  getShopTerminalsAction,
  addTransactionAction,
  getTransactionTypesForGatewayAction,
})(NewTransactionForm);

NewTransactionForm.propTypes = {
  addTransactionAction: PropTypes.func,
  getAllShops: PropTypes.func,
  getShopTerminalsAction: PropTypes.func,
  getTransactionTypesForGatewayAction: PropTypes.func,
  location: PropTypes.object,
  shops: PropTypes.array,
  terminals: PropTypes.array,
  transaction: PropTypes.object,
  typesForGateway: PropTypes.array,
};
