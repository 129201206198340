const config = {
  node: {
    // host: "https://gateway.payseven.net"

    // host: "https://front.klta.net"
    host: process.env.REACT_APP_BACKEND_HOST,
    // host: "https://gate.rusimex.com",
    // host: "http://10.2.201.24:8080",
    // host: "http://127.0.0.1:8082",
  },
  // name: "TBF",
  name: "IDEAL7",

};

export default config;
