import React from "react";
import { connect } from "react-redux";
import { LineChart } from "../UI/LineChart";
import { getCurrenciesOfTransactionsAction } from "../../actions/transactions";
import { lastNDays, formatDate } from "../../services/dateTime/dateTime";
import { borderColors, backgroundColors } from "../../constants/charts/colors";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Can from "components/Can";
import { Badge } from "react-bootstrap";

class LineChartTransactionsCurrencies extends React.Component {
  state = {
    daysCount: 7,
    loading: true,
    width: 0,
  };

  componentDidMount = () => {
    this.props.getCurrenciesOfTransactionsAction({
      days: this.state.daysCount,
    });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  getCurrenciesData = (obj, days) => {
    let res = [];
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      res.push(this.convertData(obj[keys[i]], days));
    }
    return res;
  };

  convertData = (array, days) => {
    const arrayDays = array.map((item) => formatDate(item.date));
    let res = [];
    days.forEach((day) => {
      arrayDays.includes(day)
        ? (res = [ ...res, array[arrayDays.indexOf(day)].success ])
        : (res = [ ...res, 0 ]);
    });
    return res;
  };

  render() {
    if (this.props.loading) return <Spinner />;
    else {
      const labels = lastNDays(this.state.daysCount);

      let currencies, currenciesData, datasetsPie;
      if (this.props.currenciesReport) {
        currenciesData = this.getCurrenciesData(
          this.props.currenciesReport,
          labels
        );
        currencies = Object.keys(this.props.currenciesReport);

        datasetsPie = currencies.map((currency, index) => {
          return {
            label: currency,
            fill: false,
            lineTension: 0.1,
            backgroundColor: backgroundColors[index],
            borderColor: borderColors[index],
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: borderColors[index],
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: borderColors[index],
            pointHoverBorderColor: "black",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: currenciesData[index],
          };
        });
      }

      return (
        <div>
          <Can do="REPORT" on="CURRENCIESOFTRANSACTIONS">
            <LineChart
              labels={labels}
              datasets={datasetsPie}
              name="Transactions currencies amount"
              options={{
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </Can>
          <Can not do="REPORT" on="CURRENCIESOFTRANSACTIONS">
            {this.state.width > 1200 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(40%)",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : this.state.width > 767 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                    No privileges
              </Badge>
            )}
          </Can>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currenciesReport: state.transactions.currenciesOfTransactions,
    loading: state.transactions.currenciesOfTransactionsLoading,
  };
};

export default connect(mapStateToProps, {
  getCurrenciesOfTransactionsAction,
})(LineChartTransactionsCurrencies);

LineChartTransactionsCurrencies.propTypes = {
  currenciesReport: PropTypes.object,
  getCurrenciesOfTransactionsAction: PropTypes.func,
  loading: PropTypes.bool,
};
