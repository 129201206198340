import { backendManagement, getRoles } from "../backendPlatform";

const roles = getRoles()[0];
const role = (!roles.role || roles.role === "admin") ? "" : "/" + roles.role + "s";
const roleGuid = roles.guid ? "/" + roles.guid : "";
const urlPrefix = role + roleGuid;

export const getShops = (args) => {
  return backendManagement.get(`${urlPrefix}/shops`, { params: { ...args } });
  // return backendManagement.get('/shops', { params: { ...args } });
};

export const createShop = data => {
  return backendManagement.post("/shops", data);
};
//With guid field it will update shop info
export const updateShop = data => {
  return backendManagement.post("/shops", data);
};
//With guid and delete fields it will delete shop
export const deleteShop = ({ guid, reason }) => {
  return backendManagement.post("/shops", { guid, delete: true, reason });
};

export const getShop = guid => {
  return backendManagement.get(`/shops/${guid}`);
};

// export const deleteGatewayFromShop = (guid, data) => {
//   return backendManagement.post(`/shops/${guid}/gateways`, data);
// };

export const getTerminalsByGateway = (guid, page, items) => {
  return backendManagement.get(`/gateways/${guid}/terminals`, { params: { items, page } });
};

export const getShopGatewayProps = (guid, gatewayGuid) => {
  return backendManagement.get(`/terminals/${guid}/gateway/${gatewayGuid}/props`);
};

export const editShopGatewayProps = (guid, gatewayGuid, data) => {
  return backendManagement.post(`/terminals/${guid}/gateway/${gatewayGuid}/props`, data);
};

export const getShopTerminals = (guid, page, items, search) => {
  return backendManagement.get(`/shops/${guid}/terminals`, { params: { ...search, items, page } });
};

export const getShopTerminal = (shopId, guid) => {
  return backendManagement.get(`/shops/${shopId}/terminals/${guid}`);
};

export const createShopTerminals = (guid, data) => {
  return backendManagement.post(`/shops/${guid}/terminals`, data);
};

export const generateTerminalCredentials = (guid) => {
  return backendManagement.get(`/terminals/${guid}/generate_credentials`);
};