import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import ReactLoading from "react-loading";

function ShopSelect({
  shopsLoading,
  value = [],
  shops = [],
  callback
}) {
  let [ checked, setChecked ] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [ shops ]);

  const onSelect = (options) => {
    callback && callback(options);
  };

  const onCheck = () => {
    if (!checked)
      callback && callback(shops);
    else
      callback && callback([]);
    setChecked(!checked);
  };

  return (
    <>
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Shop*</Form.Label>
        </Col>
        <Col md={8}>
          {shopsLoading ?
              <div>
                <ReactLoading type="cylon" color="grey" />
              </div>
            :
              <Form.Group>
                <MultiSelect
                  name="shops"
                  value={value}
                  options={shops}
                  multi={true}
                  onSelect={onSelect}
                  placeholder="Select shop"
                />

              </Form.Group>}
        </Col>
      </Row >
      <Row >
        <Col md={3} sm={4} xs={4} className="form-label"></Col>
        <Col md={8}>
          <Form.Group
            style={{ float: "left", marginTop: "-5px" }}
          >
            <input
              type="checkbox"
              id="enabledCheckbox"
              checked={checked}
              onChange={onCheck}
            />
            <span style={{ color: "#9A9A9A" }}>
              All shops
            </span>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default ShopSelect;