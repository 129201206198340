import * as types from "../constants/actionTypes";

const initialState = {
  useraccount: {
    guid: "",
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    auth_type: "",
    phone: "",
    warning: ""
  },
  userAccountsList: []
};

export default function useraccounts(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_ACCOUNT:
      return {
        ...state,
        userAccountLoading: true,
        error: false
      };
    case types.GET_USER_ACCOUNT_SUCCEED:
      return {
        ...state,
        useraccount: action.useraccount,
        userAccountLoading: false,
        error: false
      };
    case types.GET_USER_ACCOUNT_FAILED:
      return {
        ...state,
        userAccountLoading: false,
        error: true
      };
    case types.EDIT_USER_ACCOUNT: {
      let newUserAccountsList = state.userAccountsList;
      newUserAccountsList = newUserAccountsList.map(useraccount => {
        if (useraccount.guid === action.useraccount.guid)
          return action.useraccount;
        else
          return useraccount;
      });
      return { ...state, userAccountsList: newUserAccountsList };
    }
    default:
      return state;
  }
}