import * as types from "../constants/actionTypes";

const initialState = {
  privilegesLoading: true,
  privilegesList: []
};

export default function privileges(state = initialState, action) {
  switch (action.type) {
    case types.ADD_PRIVILEGE:
      return { ...state, privilegesList: [ ...state.privilegesList, action.privilege ], privilege_guid: action.privilege.guid };
    case types.GET_PRIVILEGES:
      return { 
        ...state, 
        privilegesLoading: true,
        error: false
      };
    case types.GET_PRIVILEGES_SUCCEED:
      return { 
        ...state, 
        privilegesList: action.data.data, 
        count: action.data.count, 
        pageSize: action.pageSize, 
        currentPage: action.currentPage,
        privilegesLoading: false,
        error: false 
      };
    case types.GET_PRIVILEGES_FAILED:
      return { 
        ...state, 
        privilegesLoading: false,
        error: true
      };
    case types.GET_PRIVILEGE:
      return {
        ...state,
        privilegeLoading: true,
        error: false
      };
    case types.GET_PRIVILEGE_SUCCEED:
      return {
        ...state,
        privilege: action.privilege,
        privilegeLoading: false,
        error: false
      };
    case types.GET_PRIVILEGE_FAILED:
      return {
        ...state,
        privilegeLoading: false,
        error: true
      };
    case types.EDIT_PRIVILEGE: {
      let newPrivilegesList = state.privilegesList;
      newPrivilegesList = newPrivilegesList.map(privilege => {
        if (privilege.guid === action.privilege.guid)
          return action.privilege;
        else
          return privilege;
      });
      return { ...state, privilegesList: newPrivilegesList };
    }
    case types.DELETE_PRIVILEGE: {
      let newPrivilegesList = state.privilegesList.filter(privilege => privilege.guid !== action.privilege.guid);
      return { ...state, privilegesList: newPrivilegesList };
    }

    default:
      return state;
  }
}