import * as types from "../constants/actionTypes";

const initialState = {
  isHide: false,
  collapseItemState: "",
  activeItemPath: "",
};

export default function sidebar(state = initialState, action) {
  switch(action.type) {
    case types.HIDE_SIDEBAR:
      return {
        ...state,
        isHide: true,
        collapseItemState:  ""
      };
    case types.SHOW_SIDEBAR:
      return {
        ...state,
        isHide: false
      };
    case types.INVERSE_SIDEBAR:
      return {
        ...state,
        isHide: !state.isHide,
        collapseItemState:  ""
      };
    case types.COLLAPSE_ITEM_CLICK:
      return {
        ...state,
        collapseItemState: action.state === state.collapseItemState ? "" : action.state
      };
    default:
      return state;
  }
}