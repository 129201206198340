import React, { Component } from "react";
import Joi from "joi-browser";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import Alert from "../../UI/Alert";
import ReactLoading from "react-loading";
import queryString from "query-string";
import { getShopTerminalAction } from "actions/shops";
import { addTransactionAction } from "actions/transactions";
import { parseResponse } from "helpers/parseResponse";
import { numberFormatter } from "../../../helpers/numberFormatter";
import { showModal } from "actions/modal";

class FormForOrder extends Component {
  state = {
    data: {
      // tracking_id: "",
      terminal: "",
      amount: "",
      currency: "",
      type: "",
      terminalSecret: "",
      terminalHashKey: "",
      transactionId: "",
      reason: "",
    },

    isNeedTransactionId: false,
    isNeedReason: false,

    errors: {},
    disabledTerminal: true,
    disabledType: true,
  };

  mainSchema = {
    terminal: Joi.string().required().min(0).label("Terminal"),
    terminalSecret: Joi.string().required().label("Secret"),
    terminalHashKey: Joi.string().required().label("Hash Key"),
    type: Joi.string().required().label("Transaction type"),
    transactionId: Joi.string().label("Transaction ID").allow("").optional(),
    amount: Joi.number().required().label("Amount"),
    currency: Joi.string().required().label("Currency"),
    // tracking_id: Joi.string().required().min(0).label("Tracking id"),
    reason: Joi.any(),
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const data = { ...this.state.data };
    const type = this.props.type;

    if ([ "Refund", "Cancel", "Reverse" ].includes(type))
      this.setState({
        isNeedReason: true,
      });
    else if ([ "Capture" ].includes(type)) this.setState({ isNeedReason: false });

    await this.props.getShopTerminalAction(
      this.props.shopGuid,
      this.props.terminalGuid
    );

    data.currency = this.props.terminal.currency_code;
    data.terminal = this.props.terminalGuid;
    data.terminalSecret = this.props.terminal.secret;
    data.terminalHashKey = this.props.terminal.hash_key;
    data.transactionId = this.props.guid;
    data.amount = this.props.amount;
    data.type = type;

    this.setState({ data });

    const values = queryString.parse(window.location.hash.split("?")[1]);
    if (values.status === "succeed")
      swal({
        title: "Transaction submitted",
        text: values.guid ? `Transaction guid: ${values.guid}` : "Success",
        icon: "success",
      });
    else if (values.status === "failed")
      Alert({
        type: "error",
        message: values.guid
          ? `Transaction failed, guid: ${values.guid} `
          : "Failed",
      });
    window.location.replace(window.location.href.split("?")[0]);
    this.setState({ isLoading: false });
  }

  validate = () => {
    const options = { abortEarly: false };
    let schema = this.mainSchema;
    let data = { ...this.state.data };

    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    let schema = this.mainSchema;
    const obj = { [name]: value };
    schema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleChangeNumber = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = numberFormatter(input.value);

    this.setState({ data, errors });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    try {
      let data = {};

      data = {
        amount: Math.round(+this.state.data.amount * 100),
        type: this.state.data.type,
        transactionId: this.state.data.transactionId,
        reason: this.state.isNeedReason ? this.state.data.reason : undefined,
      };

      data.succeed_url = `${window.location}?status=succeed`;
      data.failed_url = `${window.location}?status=failed`;

      this.setState({ isLoading: true });
      await this.props.addTransactionAction(
        data,
        this.state.data.terminal,
        this.state.data.terminalSecret,
        this.state.data.terminalHashKey
      );
      this.setState({ isLoading: false });
      let transactionGuid = "Transaction guid: " + this.props.transaction.guid;
      if (this.props.transaction.status === "Failed") {
        Alert({ type: "error", message: transactionGuid + ", Failed" });
      } else {
        swal({
          title: "Transaction submitted",
          text: transactionGuid,
          icon: "success",
        });
        await this.props.handleClose();
        await this.props.updateOrder();
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <Card>
        <Card.Body style={{ overflow: "initial" }}>
          <Form onSubmit={this.handleSubmit} autoComplete="off">
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Amount </Form.Label>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Control
                    name="amount"
                    value={data.amount}
                    onChange={this.handleChangeNumber}
                  />
                  {errors.amount && (
                    <span className="validate-error">{errors.amount}</span>
                  )}
                  {errors.currency && (
                    <span className="validate-error">{errors.currency}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={2}>
                {data.currency ? (
                  <p style={{ marginTop: "2px", marginLeft: "5px" }}>
                    {data.currency}
                  </p>
                ) : null}
              </Col>
            </Row>

            {/* <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Tracking id</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="tracking_id"
                    value={data.tracking_id}
                    onChange={this.handleChange}
                  />
                  {errors.tracking_id && (
                    <span className="validate-error">
                      {errors.tracking_id}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row> */}
            {this.state.isNeedReason && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Reason </Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      name="reason"
                      value={data.reason}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <div>
              {errors.terminalSecret && (
                <p
                  className="validate-error"
                  style={{ float: "none", textAlign: "center" }}
                >
                  {errors.terminalSecret}
                </p>
              )}

              {errors.terminalHashKey && (
                <p
                  className="validate-error"
                  style={{ float: "none", textAlign: "center" }}
                >
                  {errors.terminalHashKey}
                </p>
              )}
            </div>

            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button className="btn btn-fill btn-primary" type="submit">
                  Send
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    terminals: state.shops.shopTerminals,
    transaction: state.transactions.transaction,
    status: state.transactions.status,
    terminal: state.shops.shopTerminal,
  };
};

export default connect(mapStateToProps, {
  addTransactionAction,
  getShopTerminalAction,
  showModal,
})(FormForOrder);
