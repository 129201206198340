// import Can from "components/Can";
import RateCreator from "components/Rate/Creator";
import RateMain from "components/Rate/Main";
import Modal from "components/UI/Modal";
import React from "react";
import Content from "../Content";
import ability from "config/ability";

const Rate = () => {
  return (
    <Content>
      <RateMain
        modalComponent={
          // <Can do="EXECUTE" on="USERPARTNER">
          <Modal
            allowed={ability.can("EXECUTE", "RATES")}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create rate
              </button>
            }
            content={RateCreator}
            header="Create rate"
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default Rate;
