import { pushItemHistory, resetItemHistory } from "actions/history";
import Header from "components/UI/Header/Header";
import Sidebar from "components/UI/Sidebar";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { adminDashboardRoutes, adminNonNavRoutes } from "routes/dashboard";
import {
  getAuthData,
  updateTokens,
} from "services/paymentBackendAPI/backendPlatform";
import { Footer } from "../../components/UI/Footer/index";

class AdminDashboard extends Component {
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    adminDashboardRoutes.forEach((item) => {
      if (item.collapse) {
        item.views.forEach((prop) => {
          if (prop.path === e.history.location.pathname) {
            this.props.resetItemHistory(
              prop.name,
              e.history.location.pathname,
              item.name.toUpperCase()
            );
          }
        });
      } else {
        if (item.path === e.history.location.pathname) {
          this.props.resetItemHistory(item.name, e.history.location.pathname);
        }
      }
    });

    adminNonNavRoutes.forEach((prop) => {
      if (e.history.location.pathname.includes(prop.path.replace(":id", ""))) {
        this.props.pushItemHistory(prop.name, e.history.location.pathname);
      }
    });
  }
  constructRoute(prop, key) {
    // if (prop.notifications)
    //   return (
    //     <Route
    //       path={prop.path}
    //       key={key}
    //       render={routeProps => (
    //         <prop.component
    //           {...routeProps}
    //         />
    //       )}
    //     />
    //   );
    if (prop.redirect)
      return <Redirect from={prop.path} to={prop.to} key={key} />;
    if (!prop.logout)
      return <Route path={prop.path} component={prop.component} key={key} />;
  }

  async onMouseMove() {
    let token = getAuthData();
    if (token && token.exp) {
      let exp = new Date((token.exp - Math.round(+new Date() / 1000)) * 1000);
      if (exp.getMinutes() < 5 && token.exp) {
        await updateTokens();
        token = getAuthData();
      }
    }
  }

  render() {
    return (
      <div className="wrapper" onMouseMove={this.onMouseMove}>
        <Sidebar {...this.props} />
        <div
          id="main-panel"
          className={
            this.props.isHide ? "main-panel slideOut" : "main-panel slideIn"
          }
        >
          <Header {...this.props} />
          <Switch>
            {adminNonNavRoutes.map((prop, key) =>
              this.constructRoute(prop, key)
            )}
            {adminDashboardRoutes.map((prop, key) =>
              prop.collapse
                ? prop.views.map((item, key) => this.constructRoute(item, key))
                : this.constructRoute(prop, key)
            )}
          </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isHide: state.sidebar.isHide,
  };
};

export default connect(mapStateToProps, {
  pushItemHistory,
  resetItemHistory,
})(AdminDashboard);

AdminDashboard.propTypes = {
  isHide: PropTypes.bool,
  pushItemHistory: PropTypes.func,
  resetItemHistory: PropTypes.func,
};
