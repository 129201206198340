import React from "react";
import GroupMain from "components/Group/Main";
import Content from "../Content";
import Modal from "components/UI/Modal";
// import Can from "components/Can";
import GroupCreator from "components/Group/Creator";
import ability from "config/ability";

const Group = () => {
  return (
    <Content>
      <GroupMain
        modalComponent={
          // <Can do="EXECUTE" on="USERGROUP">
          <Modal
            allowed={ability.can("EXECUTE", "USERGROUP")}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create group
              </button>
            }
            content={GroupCreator}
            header="Create group"
            dialogClassName="modal-creator"
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default Group;
