import React from "react";
import { connect } from "react-redux";
import PieChart from "../UI/PieChart";
import PropTypes from "prop-types";
import { getTransactionTypesForShopAction } from "../../actions/transactions";

class PieChartShopTransactionsStatusesCount extends React.Component {
  state = {
    daysCount: 7,
    loading: true,
    types: {},
  };

  componentDidMount = async () => {
    if (this.props.shopGuid) {
      await this.props.getTransactionTypesForShopAction({
        days: this.state.daysCount,
        shop_guid: this.props.shopGuid,
      });
      this.setState({
        types: this.props.types,
      });
    }
  };

  render() {
    let success = 0;
    Object.keys(this.state.types).forEach((type) => {
      success += parseInt(this.state.types[type][0].success, 10);
    });
    let failed = 0;
    Object.keys(this.state.types).forEach((type) => {
      failed += parseInt(this.state.types[type][0].failed, 10);
    });
    let pending = 0;
    Object.keys(this.state.types).forEach((type) => {
      pending += parseInt(this.state.types[type][0].pending, 10);
    });

    const datasetsPie =
      success + failed + pending > 0
        ? [
            {
              data: [ success, failed, pending ],
              backgroundColor: [
                "rgba(75,192,192,1)",
                "rgba(255,99,132,1)",
                "rgba(255, 159, 64, 1)",
              ],
            },
          ]
        : [
            {
              data: [ 1 ],
              backgroundColor: "#CCC",
            },
          ];

    const labelsPie = datasetsPie
      ? datasetsPie[0].data.length === 1
        ? [ "There are no transactions yet" ]
        : [ "Success", "Failed", "Pending" ]
      : null;

    return (
      <PieChart
        name={`Transaction statuses amount of shop: ${
          this.props.shopGuid ? this.props.shopGuid : ""
        }`}
        labels={labelsPie}
        datasets={datasetsPie}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.transactions.typesTransactionsOfShop,
  };
};

export default connect(mapStateToProps, {
  getTransactionTypesForShopAction,
})(PieChartShopTransactionsStatusesCount);

PieChartShopTransactionsStatusesCount.propTypes = {
  getTransactionTypesForShopAction: PropTypes.func,
  shopGuid: PropTypes.string,
  types: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
};
