import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { getAllRateRevisionFeesAction } from "actions/rates";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Content from "views/Content";
import Table from "components/UI/Table";
import Modal from "components/UI/Modal";
import RateRevisionFeesEditor from "./Fees/Editor";
import { searchInRates } from "../../../actions/search";

class RateRevisionDetail extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,
    sortKeys: [],
    sortReversKeys: [],

    searchInput: false,
    sortKey: "",
    isSortReverse: false,
  };

  columns = [
    {
      path: "transaction_type",
      label: "Transaction type",
      key: "transaction_type",
    },
    {
      path: "transaction_status",
      label: "Transaction status",
      key: "transaction_status",
    },
    { path: "card_schema", label: "Card schema", key: "card_schema" },
    { path: "card_region", label: "Card region", key: "card_region" },
    { path: "card_type", label: "Card type", key: "card_type" },
    {
      key: "plain",
      content: (item) => (item.plain ? "fixed" : "%"),
      textContent: (item) => (item.plain ? "fixed" : "%"),
      label: "Plain",
      align: "center",
      colWidth: 4,
    },
    { path: "buy", label: "buy", key: "buy" },
    { path: "sell", label: "sell", key: "sell" },
  ];

  async componentDidMount() {
    await this.props.getAllRateRevisionFeesAction(
      this.props.match.params.rateid,
      this.props.match.params.id
    );
  }

  updateData = (value) => {
    this.setState({ searchInput: value });
  };

  updateCurrentPage = () => {
    this.setState({ currentPage: 1 });
  };

  onSort = async (sortKey) => {
    let { sortKeys, sortReversKeys } = this.state;
    let index = sortKeys.indexOf(sortKey);
    if (index !== -1) {
      sortReversKeys[index] = !sortReversKeys[index];
    } else {
      sortKeys.push(sortKey);
      sortReversKeys.push(false);
    }
    this.setState({
      sortKeys,
      sortReversKeys,
    });

    const sortData = {
      sort_col: sortKeys,
      sort_dir: sortReversKeys,
    };
    await this.props.getAllRateRevisionFeesAction(
      this.props.match.params.rateid,
      this.props.match.params.id,
      {
        ...this.props.searchData,
        ...sortData,
      }
    );
  };

  updateFees = async () => {
    await this.props.getAllRateRevisionFeesAction(
      this.props.match.params.rateid,
      this.props.match.params.id
    );
  };

  render() {
    const fees = this.props.rateRevisionFeesList;
    let { searchData } = this.props;

    if (this.props.rateLoading)
      return (
        <Content>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Card>
            <Card.Header as="h5">
              Revision {this.props.match.params.id}
            </Card.Header>
            <Card.Body>
              <Card.Title>Fees:</Card.Title>
              <div className="content">
                {this.props.rateRevisionFeesLoading ? (
                  <Content>
                    <Spinner />
                  </Content>
                ) : (
                  <React.Fragment>
                    <Table
                      columns={this.columns}
                      data={fees ? fees : []}
                      keyPath="guid"
                      onSort={this.onSort}
                      sortKey={this.state.sortKey}
                      isSortReverse={this.state.isSortReverse}
                      searchData={searchData}
                      search={this.props.search}
                      updateData={this.updateData}
                      updateCurrentPage={this.updateCurrentPage}
                      get={(data) =>
                        this.props.getAllRateRevisionFeesAction(
                          this.props.match.params.rateid,
                          this.props.match.params.id,
                          data
                        )
                      }
                    />
                    <Modal
                      header="Edit fees"
                      button={<button className="btn btn-fill">Edit</button>}
                      dialogClassName="modal-creator"
                      content={RateRevisionFeesEditor}
                      contentProps={{
                        fees,
                        revisionId: this.props.match.params.id,
                        rateId: this.props.match.params.rateid,
                        updateFees: this.updateFees,
                      }}
                    />
                  </React.Fragment>
                )}
              </div>
            </Card.Body>
          </Card>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    rateRevisionFeesList: state.rates.rateRevisionFeesList,
    count: state.rates.count,
    rateRevisionFeesLoading: state.rates.rateRevisionFeesLoading,

    searchData: state.search.ratesSearch,
  };
};

export default connect(mapStateToProps, {
  getAllRateRevisionFeesAction,
  search: searchInRates,
})(RateRevisionDetail);

RateRevisionDetail.propTypes = {
  getAllRateRevisionFeesAction: PropTypes.func,
  getRateAction: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      rateid: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  rate: PropTypes.object,
  rateRevisionFeesList: PropTypes.array,
  rateRevisionFeesLoading: PropTypes.bool,
  search: PropTypes.func,
  searchData: PropTypes.object,
};
