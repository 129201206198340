import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reset, searchInMismatchTransactionsTable } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import { getMismatchTransactionsAction } from "../../actions/transactions";
import AbstractComponent from "../../factories/Main";
import { cutGuid } from "../../helpers/cutGuid";
import { Copy } from "../UI/CopyToClipboard";

let columns = [
  {
    path: "guid",
    label: "Guid",
    key: "guid",
    content: (transaction) => (
      <>
        <Copy text={transaction.transaction_processing_guid} />
        <Link
          className="link"
          to={`/about/mismatch/${transaction.transaction_processing_guid}`}
        >
          {cutGuid(transaction.transaction_processing_guid)}
        </Link>
      </>
    ),
    style: { minWidth: "120px" },
  },
  {
    path: "guid",
    label: "Transaction Guid",
    content: (transaction) => (
      <>
        <Copy text={transaction.transaction_processing_guid} />
        <Link
          className="link"
          to={`/about/order/${transaction.transaction_processing_guid}`}
        >
          {cutGuid(transaction.transaction_processing_guid)}
        </Link>
      </>
    ),
    style: { minWidth: "120px" },
  },
  { path: "payment_id", label: "Payment ID" },
  {
    path: "card_number",
    label: "Card number",
    content: (num) =>
      num.card_number.substr(1, 4) +
      "****" +
      num.card_number.substr(num.card_number.length - 4, 4),
  },
  { path: "card_schema", label: "Card schema", key: "card_schema_mismatch" },
  { path: "amount", label: "Amount", align: "right" },
  {
    path: "currency_code",
    label: "Currency",
    key: "currency",
    align: "center",
  },
  {
    path: "currency",
    label: "Currency",
    key: "currency",
    align: "center",
    colWidth: 8,
  },
  {
    path: "card_number_decta",
    label: "Card number decta",
    content: (num) =>
      num.card_number.substr(1, 4) +
      "****" +
      num.card_number.substr(num.card_number.length - 4, 4),
  },
  { path: "amount_decta", label: "Amount decta", align: "right" },
  { path: "currency_code_decta", label: "Currency decta", align: "center" },
  { path: "card_schema_decta", label: "Card schema decta" },
  { path: "created_at", label: "Created at", align: "center" },
];

const mapStateToProps = (state) => {
  return {
    data: state.transactions.mismatchTransactions,
    count: state.transactions.count,
    table: state.store.table,
    searchData: state.search.mismatchTransactionSearch,
    isSearch: state.search.isSearch,
    loading: state.transactions.mismatchTransactionsLoading,
    name: "USERMERCHANT",
    columns,
    keyPath: "guid",
  };
};

export default connect(mapStateToProps, {
  get: getMismatchTransactionsAction,
  search: searchInMismatchTransactionsTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
