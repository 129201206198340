import { getRoleAction, getRolePrivilegesAction } from "actions/roles";
import Pagination from "components/UI/Pagination/index";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table/index";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Content from "views/Content";

class RoleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
    };
  }

  columnsRolePrivileges = [
    { path: "guid", label: "Guid" },
    { path: "name", label: "Name" },
    { path: "description", label: "Description" },
  ];

  componentDidMount = async () => {
    await this.props.getRolePrivilegesAction(
      this.props.match.params.id,
      1,
      this.state.pageSize
    );
    await this.props.getRoleAction(this.props.match.params.id);
  };

  handlePageChange = async (page) => {
    await this.props.getRolePrivilegesAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      currentPage: page,
    });
  };

  getPagedData = () => {
    const { pageSize } = this.state;
    const { count } = this.props;
    const rolePrivileges = this.props.rolePrivileges;
    const pagesCount = count / pageSize + (1 && !!(count % pageSize));
    return { pagesCount, data: rolePrivileges };
  };

  render() {
    const role = this.props.role;
    const { currentPage } = this.state;
    const { pagesCount, data: rolePrivileges } = this.getPagedData();
    if (this.props.roleLoading || this.props.privilegesLoading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Card>
            <Card.Header as="h5">{role.name}</Card.Header>
            <Card.Body>
              <Card.Title>Role Info</Card.Title>
              <Row>
                <Col md={6} lg={6} xs={6} className="right">
                  <label>GUID:</label>
                </Col>
                <Col md={6} lg={6} xs={6}>
                  <span>{this.props.match.params.id}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={6} xs={6} className="right">
                  <label>Description:</label>
                </Col>
                <Col md={6} lg={6} xs={6}>
                  <span>{role.description}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={6} xs={6} className="right">
                  <label>Created at:</label>
                </Col>
                <Col md={6} lg={6} xs={6}>
                  <span>
                    {role.created_at !== null
                      ? moment(role.created_at)
                        .utcOffset(0)
                        .format("D MMM YYYY")
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={6} xs={6} className="right">
                  <label>Created by:</label>
                </Col>
                <Col md={6} lg={6} xs={6}>
                  <span>{role.created_by_username}</span>
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={6} xs={6} className="right">
                  <label>Updated at:</label>
                </Col>
                <Col md={6} lg={6} xs={6}>
                  <span>
                    {role.updated_at !== null
                      ? moment(role.updated_at)
                        .utcOffset(0)
                        .format("D MMM YYYY")
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={6} lg={6} xs={6} className="right">
                  <label>Updated by:</label>
                </Col>
                <Col md={6} lg={6} xs={6}>
                  <span>{role.updated_by_username}</span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Privileges</Card.Title>

              <Table
                columns={this.columnsRolePrivileges}
                data={rolePrivileges ? rolePrivileges : null}
                disableSearch
                disableSort
              />
              <Pagination
                pagesCount={pagesCount}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
                pageSize={this.state.pageSize}
                count={this.props.count}
              />
            </Card.Body>
          </Card>

        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.roles.role,
    rolePrivileges: state.roles.rolePrivileges,
    count: state.roles.privilegesCount,
    roleLoading: state.roles.roleLoading,
    privilegesLoading: state.roles.rolePrivilegesLoading,
  };
};

export default connect(mapStateToProps, {
  getRoleAction,
  getRolePrivilegesAction,
})(RoleDetail);

RoleDetail.propTypes = {
  count: PropTypes.number,
  getRoleAction: PropTypes.func,
  getRolePrivilegesAction: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  privilegesLoading: PropTypes.bool,
  role: PropTypes.object,
  roleLoading: PropTypes.bool,
  rolePrivileges: PropTypes.array,
};
