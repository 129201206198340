import React, { Component } from "react";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { Form, Button } from "react-bootstrap";
import {
  createItemGlobalBlackListAction,
  getAllBlackListItems,
} from "actions/blacklists";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { Col, Row } from "react-bootstrap";
import Alert from "../../UI/Alert";
import MultiSelect from "components/UI/MultiSelect";

class GlobalBlackListCreator extends Component {
  state = {
    data: {
      blacklist_rule_guid: "",
      type: "",
    },
    types: [
      { name: "allow", guid: "allow", label: "allow" },
      { name: "deny", guid: "deny", label: "deny" },
    ],
    errors: {},
    isLoading: false,
    rules: [],
  };

  componentDidMount = () => {
    this.props.getAllBlackListItems();
  };

  schema = {
    blacklist_rule_guid: Joi.string().required().min(0).max(100).label("Rule"),
    type: Joi.string().required().min(0).max(100).label("Type"),
  };

  doSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ isLoading: true });
      const { blacklist_rule_guid, type } = this.state.data;
      await this.props.createItemGlobalBlackListAction(
        { blacklist_rule_guid, type },
        this.props.currentPage,
        this.props.pageSize
      );
      swal({
        title: "Record is created",
        icon: "success",
        button: false,
        timer: 2000,
      });
      await this.props.handleClose();
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  onSelectRule = (e) => {
    let data = this.state.data;
    data.blacklist_rule_guid = e.guid;
    this.setState({
      data,
    });
  };

  onSelectType = (e) => {
    let data = this.state.data;
    data.type = e.name;
    this.setState({
      data,
    });
  };

  render() {
    return (
      <Form>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Rule*</Form.Label>
          </Col>
          <Col>
            <Form.Group>
              <MultiSelect
                name="blacklist_rule_guid"
                options={this.props.rules}
                multi={false}
                onSelect={this.onSelectRule}
                // placeholder="role"
              />
            </Form.Group>

            {/* {this.renderSelect(
              "blacklist_rule_guid",
              "Rule",
              this.props.rules
            )} */}
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>Type*</Form.Label>
          </Col>
          <Col>
            <Form.Group>
              <MultiSelect
                name="type"
                options={this.state.types}
                multi={false}
                onSelect={this.onSelectType}
                // placeholder="role"
              />
            </Form.Group>
            {/* {this.renderSelect("type", "Type", this.state.types)} */}
          </Col>
        </Row>
        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className="btn btn-fill btn-success"
              type="submit"
              onClick={this.doSubmit}
            >
              Add
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.blacklist.currentPage,
    pageSize: state.blacklist.pageSize,
    rules: state.blacklist.blackList,
  };
};

export default connect(mapStateToProps, {
  createItemGlobalBlackListAction,
  getAllBlackListItems,
  showModal,
})(GlobalBlackListCreator);

GlobalBlackListCreator.propTypes = {
  createItemGlobalBlackListAction: PropTypes.func,
  getAllBlackListItems: PropTypes.func,
  showModal: PropTypes.func,
};
