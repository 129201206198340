import { backendReports } from "../backendPlatform";

/**
 * * Get transactions currencies
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days)
 * * Outputs ([CURRENCY_NAME: [{date, success, failed},...],...])
 * */
export const getTransactionsCurrencies = (params) => {
  return backendReports.get("/currencies", { params });
};

export const getTransactionsCurrenciesDaily = (params) => {
  return backendReports.get("/currencies/daily", { params });
};

/**
 * * Get transactions currencies
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days)
 * * Outputs ([CURRENCY_NAME: [{date, success, failed},...],...])
 * */
export const getCurrenciesOfTransactions = (params) => {
  return backendReports.get("/currencies_of_transactions", { params });
};

/**
 * * Get daily Transaction info
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid)
 * * Outputs ([ {time, type, success, failed},...])
 * */
export const getDailyTransaction = (params) => {
  return backendReports.get("/daily_transactions", { params });
};

/**
 * * Get orders
 * * Inputs (status, from_date, to_date, days, page, items)
 * * Outputs ({ count:  data: [ { guid, type, status, date: timestamp }, ... ] }
 * */
export const getOrders = (args) => {
  return backendReports.get("/orders", { params: { ...args } });
};

export const getOrder = (guid) => {
  return backendReports.get(`/orders/${guid}`);
};

/**
 * * Get shop totals
 * * Inputs ( partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days, page, items)
 * * Outputs ({ count: data: [ { shop_guid, shop_name, currency, transaction_type, status, number:  }, ... ] })
 * */
export const getShopTotals = (params) => {
  return backendReports.get("/shop_totals", { params });
};

/**
 * * Get totals
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days, page, items)
 * * Outputs ({ count:  data: [ { currency, type, status, number:  }, ... ] })
 * */
export const getTotals = (params) => {
  return backendReports.get("/totals", { params });
};

/**
 * * Get totals processed
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days)
 * * Outputs (amount)
 * */
export const getTotalProcessed = (params) => {
  return backendReports.get("/total_processed", { params });
};

export const getTotalProcessedDaily = (params) => {
  return backendReports.get("/total_processed/daily", { params });
};

/**
 * * Get transaction history
 * * Inputs (guid, status, type, partner_guid, partner_name, group_guid, group_name, merchant_guid, merchant_name, shop_guid, shop_name, account_guid, account_number, from_date, to_date, days, page, items)
 * * Outputs (count, data: [ { guid, type, status, partner_guid, partner_name, group_guid, group_name, merchant_guid, merchant_name, shop_guid, shop_name, account_guid, account_number, date, }, ... ])
 * */
export const getTransactionHistory = (args) => {
  return backendReports.get("/transaction_history/daily", {
    params: { ...args },
  });
};

/**
 * * Get transaction types
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days)
 * * Outputs ([ TRANSACTION_TYPE: [ { date, success, failed }, ... ], ... ])
 * */
export const getTransactionTypes = (params) => {
  return backendReports.get("/transaction_types", { params });
};

/**
 * * Get card types
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days)
 * * Outputs ([CARD_TYPE: [{date, success, failed},...],...])
 * */
export const getCardTypes = (params) => {
  return backendReports.get("/card_types", { params });
};

export const getCardTypesDaily = (params) => {
  return backendReports.get("/card_types/daily", { params });
};

/**
 * * Get holds
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, terminal_guid)
 * * Outputs ({ amount })
 * */
export const getHolds = (params) => {
  return backendReports.get("holds", { params });
};

/**
 * * Get transaction amount
 * * Inputs (partner_guid, group_guid, merchant_guid, shop_guid, from_date, to_date, days)
 * * Outputs ([ TRANSACTION_TYPE: [ { date, success, failed }, ... ], ... ])
 * */
export const getTransactionAmount = (params) => {
  return backendReports.get("/transaction_amount", { params });
};

/**
 * * Get transaction amount
 * * Inputs (
 * * Outputs (
 * */
export const getTransactionAndCommissionReport = (params) => {
  return backendReports.get("/transaction_and_commission", { params });
};

/**
 * * Get transaction amount
 * * Inputs (
 * * Outputs (
 * */
export const exportTransactionAndCommissionReport = (params) => {
  return backendReports.get("/transaction_and_commission/export", {
    params,
    responseType: "blob",
    headers: {
      "Content-Disposition": "attachment; filename=template.xlsx",
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });
};

export const exportBasicReport = (params) => {
  return backendReports.get(
    "/basic/export",
    params.export_document_format === "xls"
      ? {
          params,
          responseType: "blob",
          headers: {
            "Content-Disposition": "attachment; filename=template.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      : { params }
  );
};

export const exportDailyReport = (params) => {
  return backendReports.get(
    "/daily/export",
    params.export_document_format === "xls"
      ? {
          params,
          responseType: "blob",
          headers: {
            "Content-Disposition": "attachment; filename=template.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      : { params }
  );
};

export const exportGeoReport = (params) => {
  return backendReports.get(
    "/transaction_geo/export",
    params.export_document_format === "xls"
      ? {
          params,
          responseType: "blob",
          headers: {
            "Content-Disposition": "attachment; filename=template.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      : { params }
  );
};

export const getAdminDashboardComponentData = (params) => {
  return backendReports.get("/dashboard_merchant_total", { params });
};

export const getMerchantLimitsData = (params) => {
  return backendReports.get("/merchants_used_limits", { params });
};

export const getMerchantTotalsData = (params) => {
  return backendReports.get("/merchants_used_amounts", { params });
};

export const getErrorReportData = (params) => {
  return backendReports.get("/transaction_errors", { params });
};

export const getHistogramReportData = (params) => {
  return backendReports.get("/transaction_histogram", { params });
};

export const getGeographicReportData = (params) => {
  return backendReports.get("/transaction_geo", { params });
};
