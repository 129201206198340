import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination";
import Spinner from "components/UI/Spinner/index";
import Table from "components/UI/Table";
import { parseResponse } from "helpers/parseResponse";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Content from "views/Content";
import { deleteAccountAction, getAllAccounts } from "../../actions/accounts";
import {
  addMerchantLoginAction,
  getMerchantAction,
  getMerchantLoginsAction,
} from "../../actions/merchants";
import { getMerchantChangeHistoryAction } from "../../actions/reasons";
import { getAllRoles } from "../../actions/roles";
import { getAllShops } from "../../actions/shops";
import { getTerminalsAction } from "../../actions/terminals";
import ability from "../../config/ability";
import AccountCreator from "../Account/Creator";
import AccountEditor from "../Account/Editor";
import AddLogin from "../AddLogin";
import EditLogin from "../EditLogin";
import GatewayPropsEditor from "../Shop/GatewayProps/Editor";
import Alert from "../UI/Alert";
import { Progress } from "antd";
import { TableAudit } from "../Audit/UsersTables/common/Table";

class MerchantDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      currentPageShops: 1,
      pageSizeShops: 10,
      currentPageTerminals: 1,
      pageSizeTerminals: 10,
      currentPageHistory: 1,
      pageSizeHistory: 10,
      loading: false,
    };
  }

  columnsLogin = [
    { path: "username", label: "Username" },
    { path: "email", label: "Email" },
    { path: "role.name", label: "Role" },
    { path: "first_name", label: "First name" },
    { path: "last_name", label: "Last name" },
    { path: "company_name", label: "Company name" },
    { path: "company_address", label: "Company address" },
    {
      key: "enabled",
      content: (login) => (
        <i
          className={
            login.enabled ? "icon-success icon green" : "icon-failed icon red"
          }
        />
      ),
      label: "Status",
      align: "center",
    },
    ability.can("REPORT", "REASONSLOGIN") && {
      label: "History",
      key: "history",
      align: "center",
      content: (login) => (
        <Link className="link" to={`/about/audit/login/${login.guid}`}>
          <button type="button" className="btn btn-table">
            Show
          </button>
        </Link>
      ),
    },
    ability.can("EXECUTE", "MERCHANTLOGIN") && {
      key: "edit",
      content: (merchantLogin) => (
        <Modal
          dialogClassName="modal-creator"
          header="Edit merchant login"
          button={
            <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
          }
          content={EditLogin}
          contentProps={{
            id: this.props.match.params.id,
            data: merchantLogin,
            type: "merchant",
            update: this.update,
          }}
        />
      ),
      label: "Edit",
      align: "center",
    },

    ability.can("DELETE", "MERCHANTLOGIN") && {
      key: "delete",
      content: (merchantLogin) => (
        <i
          className="far fa-trash-alt red icon"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDelete(
                  merchantLogin.guid,
                  merchantLogin.role_guid,
                  value
                );
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
      align: "center",
    },
  ];

  columnsAccounts = [
    { path: "guid", label: "Guid", key: "accountGuid" },
    { path: "shop_name", label: "Shop name", key: "accountShopName" },
    { path: "number", label: "Number", key: "accountNumber" },
    { path: "holder_name", label: "Holder name", key: "accountHolderName" },
    {
      path: "holder_country",
      label: "Holder country",
      key: "accountHolderCountry",
    },
    { path: "bank_name", label: "Bank name", key: "accountBankName" },
    { path: "bank_address", label: "Bank address", key: "accountBankAddress" },
    ability.can("EXECUTE", "MERCHANTACCOUNT") && {
      key: "edit",
      content: (account) => (
        <Modal
          header="Edit account"
          action="Save"
          content={AccountEditor}
          contentProps={{
            isAdmin: true,
            guidMerchant: this.props.match.params.id,
            account,
            type: "account",
          }}
          button={
            <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
          }
        />
      ),
      label: "Edit",
      align: "center",
    },
    ability.can("DELETE", "MERCHANTACCOUNT") && {
      key: "delete",
      content: (account) => (
        <i
          className="far fa-trash-alt icon red"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDeleteAccount(
                  this.props.match.params.id,
                  account.guid,
                  value
                );
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
      align: "center",
    },
  ];

  columnsTerminals = [
    ability.can("READ", "SHOPTERMINALDETAIL") && {
      path: "guid",
      label: "Guid",
      key: "guid",
      // content: (terminal) => (
      //   <Link
      //     className="link"
      //     to={{
      //       pathname: `/about/terminal/${terminal.guid}`,
      //     }}
      //   >
      //     {terminal.guid}
      //   </Link>
      // ),
    },
    { path: "name", label: "Name" },
    { path: "gateway_name", label: "Gateway" },
    { path: "currency_code", label: "Currency", align: "center" },
    {
      path: "payment_amount_limit",
      label: "Payment amount limit",
      content: (terminal) => `${terminal.payment_amount_limit / 100}`,
      align: "right",
    },
    {
      path: "monthly_amount_limit",
      label: "Monthly amount limit",
      content: (terminal) => `${terminal.monthly_amount_limit / 100}`,
      align: "right",
    },
    {
      key: "three_d",
      content: (terminal) => (
        <i
          className={
            terminal.three_d
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "3d",
      align: "center",
    },
    {
      key: "enabled",
      content: (terminal) => (
        <i
          className={
            terminal.enabled
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Enabled",
      align: "center",
    },
    {
      path: "antifraud_monitor_value",
      label: "Antifraud monitor value",
      align: "right",
    },
    {
      key: "antifraud_monitor",
      content: (terminal) => (
        <i
          className={
            terminal.antifraud_monitor
              ? "icon-success green icon"
              : "icon-failed red icon"
          }
        />
      ),
      label: "Antifraud monitor",
      align: "center",
    },
    ability.can("READ", "TERMINALGATEWAYPROPS") && {
      label: "Properties",
      align: "center",
      content: (terminal) => (
        <Modal
          header="Properties"
          content={GatewayPropsEditor}
          contentProps={{
            gatewayGuid: terminal.gateway_guid,
            terminalGuid: terminal.guid,
          }}
          buttonName="Show"
          button={
            <button type="button" className="btn btn-table">
              Show
            </button>
          }
        />
      ),
    },
  ];

  columnsShops = [
    {
      path: "guid",
      label: "Guid",
      key: "guid",
      content: (shop) => (
        <Link
          className="link"
          to={{
            pathname: `/about/shops/${shop.guid}`,
          }}
        >
          {shop.guid}
        </Link>
      ),
    },
    { path: "name", label: "Name" },
  ];

  rows = [
    {
      label: "Merchant name",
      path: "name",
    },
    {
      label: "Merchant type",
      path: "type",
    },
    {
      label: "Group name",
      path: "group_name",
    },
    {
      label: "Group guid",
      path: "group_guid",
    },
    {
      label: "Monthly fee",
      path: "monthly_fee",
    },
    {
      label: "Monthly fee currency",
      path: "monthly_fee_currency",
    },
    {
      label: "Monthly fee date",
      path: "monthly_fee_date",
    },
    {
      label: "Monthly amount limit",
      path: "monthly_amount_limit",
    },
    {
      label: "Custom amount limit",
      path: "custom_amount_limit",
    },
    {
      label: "Custom days limit",
      path: "custom_days_limit",
    },
  ];

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.props.getMerchantLoginsAction(
      this.props.match.params.id,
      1,
      this.state.pageSize
    );
    await this.props.getMerchantAction(this.props.match.params.id);
    ability.can("AUDIT", "MERCHANTS") &&
      (await this.props.getMerchantChangeHistoryAction(
        this.props.match.params.id,
        1,
        this.state.pageSizeHistory
      ));
    ability.can("READ", "MERCHANTACCOUNT") &&
      (await this.props.getAllAccounts(this.props.match.params.id));
    ability.can("READ", "ROLES") &&
      (await this.props.getAllRoles({ type: "merchant" }));
    ability.can("READ", "SHOPTERMINALS") &&
      (await this.props.getTerminalsAction({
        merchant_guid: this.props.match.params.id,
        page: 1,
        items: this.state.pageSizeTerminals,
      }));
    ability.can("READ", "SHOPS") &&
      (await this.props.getAllShops({
        merchant_guid: this.props.match.params.id,
        page: 1,
        items: this.state.pageSizeShops,
      }));

    this.setState({ loading: false });
  };

  addPointInValue = (value) => {
    switch (value.length) {
      case 1: {
        value = "0.0" + value;
        break;
      }
      case 2: {
        value = "0." + value;
        break;
      }
      case 3: {
        const start = value[0];
        const end = value[1] + value[2];
        value = start + "." + end;
        break;
      }
      default: {
        value =
          value.substr(0, value.length - 2) +
          "." +
          value.substr(value.length - 2, value.length);
        break;
      }
    }
    return value;
  };

  handlePageChange = async (page) => {
    await this.props.getMerchantLoginsAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      currentPage: page,
    });
  };

  handleHistoryPageChange = async (page) => {
    await this.props.getMerchantChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.pageSizeHistory
    );
    this.setState({
      currentPageHistory: page,
    });
  };

  handleTerminalsPageChange = async (page) => {
    await this.props.getTerminalsAction({
      merchant_guid: this.props.match.params.id,
      page,
      items: this.state.pageSizeTerminals,
    });
    this.setState({
      currentPageTerminals: page,
    });
  };

  handleShopsPageChange = async (page) => {
    await this.props.getAllShops({
      merchant_guid: this.props.match.params.id,
      page,
      items: this.state.pageSizeShops,
    });
    this.setState({
      currentPageShops: page,
    });
  };

  handleDelete = async (loginid, roleid, reason) => {
    const data = {
      login_guid: loginid,
      role_guid: roleid,
      reason,
      delete: true,
    };
    try {
      await this.props.addMerchantLoginAction(this.props.match.params.id, data);
      await this.props.getMerchantLoginsAction(this.props.match.params.id);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleDeleteAccount = async (merchantGuid, accountNumber, reason) => {
    try {
      await this.props.deleteAccountAction(merchantGuid, accountNumber, reason);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  getPagedData = () => {
    const { count } = this.props;
    let { pageSize } = this.state;
    const pagesCount = count / pageSize + (1 && !!(count % pageSize));
    return { pagesCount };
  };

  historyGetPagedData = () => {
    const { merchantHistoryCount } = this.props;
    let { pageSizeHistory } = this.state;
    const historyPagesCount =
      merchantHistoryCount / pageSizeHistory +
      (1 && !!(merchantHistoryCount % pageSizeHistory));
    return { historyPagesCount };
  };

  terminalsGetPagedData = () => {
    const { merchantTerminalsCount } = this.props;
    let { pageSizeTerminals } = this.state;
    const terminalsPagesCount =
      merchantTerminalsCount / pageSizeTerminals +
      (1 && !!(merchantTerminalsCount % pageSizeTerminals));
    return { terminalsPagesCount };
  };

  update = (id) => {
    this.props.getMerchantAction(id);
    this.props.getMerchantLoginsAction(id);
  };

  render() {
    const { currentPage, currentPageHistory, currentPageTerminals } =
      this.state;
    const {
      merchant,
      merchantLogins,
      merchantAccounts,
      merchantHistory,
      merchantTerminals,
      merchantShops,
    } = this.props;
    const { pagesCount } = this.getPagedData();
    const { terminalsPagesCount } = this.terminalsGetPagedData();
    const { shopsPagesCount } = this.terminalsGetPagedData();
    if (this.state.loading)
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5">{merchant.merchant_name}</Card.Header>
              <Card.Body>
                <Card.Title>Info</Card.Title>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Merchant GUID:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{this.props.match.params.id}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Merchant Type:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchant.merchant_type}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Group:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchant.group_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Month limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchant.monthly_amount_limit}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Custom limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    {merchant.custom_days_limit > 0 ? (
                      <span>
                        {merchant.custom_amount_limit} per{" "}
                        {merchant.custom_days_limit} days
                      </span>
                    ) : (
                      "Disabled"
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Month fee:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {this.addPointInValue(merchant.monthly_fee.toString())}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Month fee currency:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchant.monthly_fee_currency}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Month fee date:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {merchant.monthly_fee_date !== null
                        ? moment(merchant.monthly_fee_date)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Used amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {merchant.used_amount}
                      {" (" + merchant.used_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right"></Col>
                  <Col md={2} sm={2} xs={2} className="left">
                    <Progress percent={merchant.used_percent} size="small" />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Available amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {merchant.unused_amount}
                      {" (" + merchant.unused_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Used custom amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {merchant.used_custom_amount}
                      {" (" + merchant.used_custom_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right"></Col>
                  <Col md={2} sm={2} xs={2} className="left">
                    <Progress
                      percent={merchant.used_custom_percent}
                      size="small"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Available custom amount limit:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {merchant.unused_custom_amount}
                      {" (" + merchant.unused_custom_percent + "%)"}
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {merchant.created_at !== null
                        ? moment(merchant.created_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchant.created_by_username}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {merchant.updated_at !== null
                        ? moment(merchant.updated_at)
                          .utcOffset(0)
                          .format("D MMM YYYY")
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{merchant.updated_by_username}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>

          <Card>
            <Card.Body>
              <Card.Title>Logins</Card.Title>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <Table
                    columns={this.columnsLogin}
                    data={merchantLogins ? merchantLogins : []}
                    keyPath="guid"
                    disableSearch
                    disableSort
                  />
                  <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                    pageSize={this.state.pageSize}
                    count={this.props.count}
                  />
                  {ability.can("EXECUTE", "MERCHANTLOGIN") &&
                    ability.can("READ", "ROLES") && (
                    <div style={{ textAlign: "center" }}>
                      <Modal
                        header="Add login"
                        dialogClassName="modal-creator"
                        content={AddLogin}
                        contentProps={{
                          type: "merchant",
                          id: this.props.match.params.id,
                          update: this.update,
                        }}
                        button={
                          <button type="button" className="btn btn-fill">
                              Add login
                          </button>
                        }
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {ability.can("READ", "MERCHANTACCOUNT") && (
            <Card>
              <Card.Body>
                <Card.Title>Accounts</Card.Title>
                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <Table
                      columns={this.columnsAccounts}
                      data={merchantAccounts ? merchantAccounts : []}
                      keyPath="guid"
                      disableSearch
                      disableSort
                    />
                    <div style={{ textAlign: "center" }}>
                      <Modal
                        header="Create account"
                        content={AccountCreator}
                        contentProps={{
                          isAdmin: true,
                          guid: this.props.match.params.id,
                        }}
                        button={
                          <button type="button" className="btn btn-fill">
                            Create account
                          </button>
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          {ability.can("READ", "SHOPS") && (
            <Card>
              <Card.Body>
                <Card.Title>Shops</Card.Title>
                <Row>
                  <Col md={12} sm={12} xs={12}>
                    {this.props.merchantShopsLoading ? (
                      <Content>
                        <Spinner />
                      </Content>
                    ) : (
                      <>
                        <Table
                          columns={this.columnsShops}
                          data={merchantShops ? merchantShops : []}
                          keyPath="guid"
                          disableSearch
                          disableSort
                        />
                        <Pagination
                          pagesCount={shopsPagesCount}
                          currentPage={this.state.currentPageShops}
                          onPageChange={this.handleShopsPageChange}
                          pageSize={this.state.pageSizeShops}
                          count={this.props.merchantShopsCount}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          {ability.can("READ", "SHOPTERMINALS") && (
            <Card>
              <Card.Body>
                <Card.Title>Terminals</Card.Title>
                <Row>
                  <Col md={12} sm={12} xs={12}>
                    {this.props.merchantTerminalsLoading ? (
                      <Content>
                        <Spinner />
                      </Content>
                    ) : (
                      <>
                        <Table
                          columns={this.columnsTerminals}
                          data={merchantTerminals ? merchantTerminals : []}
                          keyPath="guid"
                          disableSearch
                          disableSort
                        />
                        <Pagination
                          pagesCount={terminalsPagesCount}
                          currentPage={currentPageTerminals}
                          onPageChange={this.handleTerminalsPageChange}
                          pageSize={this.state.pageSizeTerminals}
                          count={this.props.merchantTerminalsCount}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          {ability.can("AUDIT", "MERCHANTS") && (
            <Card>
              <Card.Body>
                <Card.Title>History</Card.Title>
                {this.props.merchantHistoryloading ? (
                  <Content>
                    <Spinner />
                  </Content>
                ) : (
                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      {/* <Table
                        columns={this.columnsHistory}
                        data={merchantHistory ? merchantHistory : []}
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={historyPagesCount}
                        currentPage={currentPageHistory}
                        onPageChange={this.handleHistoryPageChange}
                        pageSize={this.state.pageSizeHistory}
                        count={this.props.merchantHistoryCount}
                      /> */}

                      <TableAudit
                        data={merchantHistory ? merchantHistory : []}
                        count={this.props.merchantHistoryCount}
                        handleChangePage={this.handleHistoryPageChange}
                        page={currentPageHistory}
                        pageSize={this.state.pageSizeHistory}
                        rows={this.rows}
                      />
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          )}
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchants.merchant,
    merchantLogins: state.merchants.merchantLogins,
    count: state.merchants.loginsCount,
    merchantLoading: state.merchants.merchantLoading,
    merchantLoginsLoading: state.merchants.merchantLoginsLoading,
    rolesLoading: state.roles.rolesLoading,
    merchantAccounts: state.accounts.accountsList,
    merchantHistory: state.reasons.merchantHistory,
    merchantHistoryCount: state.reasons.count,
    merchantHistoryloading: state.reasons.loading,
    merchantShops: state.shops.shopsList,
    merchantShopsLoading: state.shops.shopsLoading,
    merchantShopsCount: state.shops.count,
    merchantTerminals: state.terminals.terminals,
    merchantTerminalsLoading: state.terminals.terminalsLoading,
    merchantTerminalsCount: state.terminals.count,
  };
};

export default connect(mapStateToProps, {
  getMerchantAction,
  getMerchantLoginsAction,
  addMerchantLoginAction,
  getAllRoles,
  getAllAccounts,
  deleteAccountAction,
  getMerchantChangeHistoryAction,
  getAllShops,
  getTerminalsAction,
})(MerchantDetail);

MerchantDetail.propTypes = {
  addMerchantLoginAction: PropTypes.func,
  count: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  deleteAccountAction: PropTypes.func,
  getAllAccounts: PropTypes.func,
  getAllRoles: PropTypes.func,

  getMerchantAction: PropTypes.func,
  getMerchantChangeHistoryAction: PropTypes.func,
  getMerchantLoginsAction: PropTypes.func,
  getTerminalsAction: PropTypes.func,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),

  merchant: PropTypes.object,
  merchantAccounts: PropTypes.array,
  merchantHistory: PropTypes.array,
  merchantHistoryCount: PropTypes.number,
  merchantHistoryloading: PropTypes.bool,
  merchantLogins: PropTypes.array,
  merchantTerminals: PropTypes.array,
  merchantTerminalsCount: PropTypes.number,
  merchantTerminalsLoading: PropTypes.bool,
};
