import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getAuthData,
  flushTokens,
} from "../../../services/paymentBackendAPI/backendPlatform";
import apiFile from "../../../public/api.pdf";
import { withRouter } from "react-router-dom";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenTtl: 0,
      tokenUser: {},
    };
  }

  async componentDidMount() {
    let token = getAuthData();
    this.setState({
      tokenUser: token.userPayload,
    });
    setInterval(() => {
      if (token && token.exp) {
        let exp = new Date((token.exp - Math.round(+new Date() / 1000)) * 1000);
        if ((exp.getMinutes() === 0) & (exp.getSeconds() === 0)) {
          flushTokens();
        }
        if (exp.getMinutes() < 5 && token.exp) {
          token = getAuthData();
        }
        this.setState({ tokenTtl: exp });
      }
    }, 1000);
  }

  getTokenTime = () => {
    return (
      <span
        style={
          this.state.tokenTtl.getMinutes() < 1
            ? {
                color: "red",
                fontWeight: "bold",
              }
            : {}
        }
      >
        {this.state.tokenTtl.getMinutes()}:
        {(this.state.tokenTtl.getSeconds() < 10 ? "0" : "") +
          this.state.tokenTtl.getSeconds()}
      </span>
    );
  };

  render() {
    const username = localStorage.getItem("username");
    return (
      <Nav className="ml-auto">
        {this.state.tokenTtl instanceof Date ? (
          <Nav.Link eventKey="disabled" disabled id="timer">
            {"Your session will expire in "}
            {this.getTokenTime()}
          </Nav.Link>
        ) : (
          ""
        )}
        <Nav.Link
          id="profile"
          eventKey={1}
          onClick={() =>
            (window.location = process.env.PUBLIC_URL + "#/useraccount")
          }
        >
          <i className="icon-user_ico">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          <span>{` ${username || "User"}`}</span>
        </Nav.Link>
        {/* {this.state.tokenUser.merchant  || this.state.tokenUser.partner || this.state.tokenUser.group ?  */}
        <Nav.Link eventKey={2} id="docs" onClick={() => window.open(apiFile)}>
          <i className="icon-doc" />
          <span>Docs</span>
        </Nav.Link>
        <Nav.Link
          eventKey={3}
          id="logout"
          onClick={() =>
            window.location.replace(process.env.PUBLIC_URL + "#/logout")
          }
        >
          <i className="icon-logout" />
          <span>Logout</span>
        </Nav.Link>
      </Nav>
    );
  }
}

const mapStateToProps = (state) => ({
  pathHistory: state.history.history,
});

export default withRouter(
  connect(mapStateToProps, {})(HeaderLinks)
);
