import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import ReactLoading from "react-loading";

function TerminalSelect({
  terminalsLoading,
  value = [],
  terminals = [],
  callback,
  errors
}) {
  let [ checked, setChecked ] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [ terminals ]);

  const onSelect = (options) => {
    callback && callback(options);
  };

  const onCheck = () => {
    if (!checked)
      callback && callback(terminals);
    else
      callback && callback([]);
    setChecked(!checked);
  };

  return (
    <>
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>Terminals*</Form.Label>
        </Col>
        <Col md={8}>
          {terminalsLoading ?
              <div>
                <ReactLoading type="cylon" color="grey" />
              </div>
            :
              <Form.Group>
                <MultiSelect
                  name="shops"
                  value={value}
                  options={terminals}
                  multi={true}
                  onSelect={onSelect}
                  placeholder="Select shop"
                />
              </Form.Group>
          }
        </Col>
      </Row >
      <Row >
        <Col md={3} sm={4} xs={4} className="form-label"></Col>
        <Col md={8}>
          <Form.Group
            style={{ float: "left", marginTop: "-5px" }}
          >
            <input
              type="checkbox"
              id="enabledCheckbox"
              checked={checked}
              onChange={onCheck}
            />
            <span style={{ color: "#9A9A9A" }}>
              All terminals
            </span>
          </Form.Group>
          {errors.terminals && (
            <span className="validate-error">{errors.terminals}</span>
          )}
        </Col>
      </Row>
    </>
  );
}

export default TerminalSelect;