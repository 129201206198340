import React from "react";
import Content from "../Content";
import ServiceLogMain from "components/Log/Service/Main";

const ServiceLogs = () => {
  return (
    <Content>
      <ServiceLogMain />
    </Content>
  );
};

export default ServiceLogs;
