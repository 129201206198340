import { calculateStatementAction } from "actions/statements";
import Button from "components/UI/CustomButton/CustomButton";
import Spinner from "components/UI/Spinner";
import ability from "config/ability";
import { parseResponse } from "helpers/parseResponse";
import Joi from "joi-browser";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Row } from "react-bootstrap";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import swal from "sweetalert";
import Content from "views/Content";
import TableRates from "./Tables/TableRates";
import TableSummary from "./Tables/TableSummary";
import Summary from "./Summary";
import Export from "./Export";
import StatementForm from "./Form";
import Alert from "../../UI/Alert";

class StatementDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      currencies: [],

      editStatement: false,
      shops: [],
      merchants: [],
      merchantGuid: "",
      merchantName: "",
      selectedShops: [],
      terminals: [],
      selectedTerminals: [],
      selectAllTerminals: false,
      selectAllShops: false,
      from_date: "",
      to_date: "",
      setCustomName: false,
      name: "",
      setCurrencies: false,
      selectedCurrencies: [],
      selected_statement_currency: {},
      selectedCurrenciesRates: [],
      currenciesRates: [],
      reason: "",
      bank_wire_fee: "",
      additional_fees: [],

      summary: [],
      transactions: [],

      errors: {},

      statementLoaded: false,

    };
  }

  componentDidMount = async () => {
    try {
      this.setState({
        selectedCurrenciesRates: this.props.statement.currency_rates,
      });
      let sum = { ...this.props.statement };
      delete sum.terminals;
      delete sum.currencies;
      delete sum.currency_rates;
      sum.guid = "Total";
      this.setState({
        summary: sum,
      });

      this.setState({
        statementLoaded: true,
      });

    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
      const parsedError = parseResponse(error);
      swal({
        title: parsedError.error,
        text: parsedError.message,
        icon: "error",
      });
    }
  };

  handleClickEdit = () => {
    this.setState({
      editStatement: !this.state.editStatement,
    });
  };

  validateCurrencyRate = (name, value) => {
    const errors = { ...this.state.errors };
    const obj = { currency: value };
    const schema = { currency: Joi.number().min(0.1).max(10000).not().empty() };
    const { error } = Joi.validate(obj, schema);
    const errorMessage = error ? error.details[0].message : null;
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];
    this.setState({
      errors,
      [name + "Validation"]: errorMessage ? false : true,
    });
  };


  handleSubmitSave = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else if (!this.state.statementLoaded) return;
    else {
      this.setState({ isLoading: true });
      let bank_wire_fee =
        this.state.bank_wire_fee.toString().replace(/^0+/, "") + "00";
      if (bank_wire_fee.includes(".")) {
        let index = bank_wire_fee.indexOf(".");
        bank_wire_fee =
          bank_wire_fee.slice(0, index) + bank_wire_fee.slice(index, index + 3);
        bank_wire_fee = bank_wire_fee.replace(".", "");
      }
      try {
        const data = {
          guid: this.props.guid,
          merchant_guid: this.state.merchantGuid,
          terminals: this.state.selectedTerminals.map((element) => {
            return element.guid;
          }),
          from_date: this.state.from_date,
          to_date: this.state.to_date,
          name: this.state.name,
          save: 1,
          statement_currency: this.state.selected_statement_currency.name,
          currency_rates: this.state.selectedCurrenciesRates,
          bank_wire_fee: bank_wire_fee === "00" ? "0" : bank_wire_fee,
          additional_fees: this.state.additional_fees.map((item) => ({
            ...item,
            currency: item.currency.code,
          })),
          reason: this.state.reason,
        };
        await this.props.calculateStatementAction(data);
        swal({
          title: "Statement is updated",
          icon: "success",
          text: this.props.warning ? this.props.warning : "",
          button: false,
          timer: 2000,
        });

        this.setState({
          summary: { ...this.props.statements },
        });

        let sum = this.state.summary;
        delete sum.terminals;
        delete sum.currencies;
        sum.guid = "Total";

        this.setState({
          summary: sum,
          statementLoaded: true,
          isLoading: false,
          transactionsLoading: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        swal({
          title: parsedError.error,
          text: parsedError.message,
          icon: "error",
        });
      }
    }
  };

  save = async (data) => {
    try {
      this.setState({
        statementLoaded: false,
        isLoading: true,
      });
      await this.props.calculateStatementAction(data);
      swal({
        title: "Statement is calculated",
        icon: "success",
        text: this.props.warning ? this.props.warning : "",
        button: false,
        timer: 2000,
      });

      this.setState({
        statementLoaded: true,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    let { summary } = this.state;
    if (!this.state.statementLoaded || this.props.statementLoading)
      return (
        <Content>
          <Spinner />
        </Content>
      );
    else
      return (
        this.state.statementLoaded && (
          <Content>
            <Card>
              <Card.Header as="h5">{summary.name}</Card.Header>
              <Card.Body>
                {ability.can("EXECUTE", "STATEMENTS") && (
                  <Button
                    className="btn btn-fill"
                    onClick={this.handleClickEdit}
                  >
                    {this.state.editStatement ? "Close Editing" : "Edit"}
                  </Button>
                )}

                {this.state.editStatement && (
                  <div>
                    <StatementForm
                      statement={this.props.statement}
                      guid={this.props.guid}
                      // calculate={this.calculate}
                      save={this.save}
                      isLoading={this.state.isLoading}
                    />
                  </div>
                )}

                <Export
                  guid={this.props.guid}
                  name={summary.name}
                />

              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                {this.state.isLoading ? (
                  <Row>
                    <div>
                      <ReactLoading
                        type="cylon"
                        color="grey"
                        height={50}
                        width={50}
                      />
                    </div>
                  </Row>
                ) : (
                  <>
                    <Card.Title>Info</Card.Title>
                    <Summary
                      guid={this.props.guid}
                      summary={summary}
                      selectedCurrenciesRates={this.state.selectedCurrenciesRates}
                      statement_currency_code={this.props.statement.statement_currency_code}
                    />
                    <TableSummary
                      currencies={this.props.statement.currencies}
                      statement_currency_code={this.props.statement.statement_currency_code}
                    />
                    <TableRates
                      additional_fees_names={this.props.statement.additional_fees_names}
                      currencies={this.props.statement.currencies}
                      statement_currency_code={this.props.statement.statement_currency_code}
                      entityData={this.props.statement.entityData}
                    />
                  </>
                )}
              </Card.Body>
            </Card>
          </Content>
        )
      );
  }
}

const mapStateToProps = (state) => {
  return {
    statement: state.statements.statement,

    statementLoading: state.statements.statementLoading,


  };
};

export default connect(mapStateToProps, {
  calculateStatementAction,

})(StatementDetail);

StatementDetail.propTypes = {
  calculateStatementAction: PropTypes.func,
  guid: PropTypes.string,
  statement: PropTypes.object,
  statementLoading: PropTypes.bool,
};
