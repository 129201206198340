import { getLoginExpiredServiceLogsAction } from "actions/logs";
import { reset, searchInServicesLogs } from "actions/search";
import AbstractComponent from "factories/Main";
import moment from "moment";
import { connect } from "react-redux";
import { resetPage, setNewTable } from "../../../../actions/store";
import { cutGuid } from "../../../../helpers/cutGuid";
import { getLoginExpiredServiceLogs } from "../../../../services/paymentBackendAPI/logs/logs";
import { Copy } from "../../../UI/CopyToClipboard";
import React from "react";

const columns = [
  {
    path: "time", label: "Time",
    content: log => (
      moment(log.createdAt).utcOffset(0).format("DD.MM.YYYY HH:mm:ss.SS")
    ),
    align: "center"
  },
  {
    path: "login_guid", label: "Login guid", content: transaction => (
      <>
        {cutGuid(transaction.login_guid)}
        <Copy text={transaction.login_guid} />
      </>
    )
  },
  {
    path: "username", label: "Username"
  },
  {
    path: "action", label: "Action", key: "action_log"
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.logs.loginExpiredServiceLogs,
    count: state.logs.loginExpiredServiceLogsCount,
    table: state.store.table,
    loading: state.logs.loading,
    searchData: state.search.serviceLogsSearch,
    isSearch: state.search.isSearch,
    name: "Service logs login expired",
    keyPath: "createdAt",
    columns,
    notAllSizePage: true,
    exportFunction: getLoginExpiredServiceLogs
  };
};

export default connect(mapStateToProps, {
  get: getLoginExpiredServiceLogsAction,
  search: searchInServicesLogs,
  resetPage,
  setNewTable,
  reset
})(AbstractComponent);
