import React, { Component } from "react";
import { connect } from "react-redux";
import { getReportHoldsAction } from "../../actions/transactions";
//import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Can from "components/Can";
import { Badge } from "react-bootstrap";
import { parseString } from "helpers/parseString";

class Holds extends Component {
  state = {
    holds: "",
    width: 0,
  };

  componentDidMount = async () => {
    await this.props.getReportHoldsAction();
    this.setState({ holds: this.props.hold.amount });

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    const holdLength = this.props.hold.amount
      ? this.props.hold.amount.length
      : 0;
    let { holds } = this.state;

    const hold = parseString(holds, holdLength);

    this.setState({
      holds: hold,
    });
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { loading, classname } = this.props;
    const { holds } = this.state;

    if (loading) return <Spinner />;
    else if (holds !== undefined)
      return (
        <div>
          <Can do="REPORT" on="HOLDS">
            <div className={classname}>€ {holds ? holds : 0}</div>
          </Can>
          <Can not do="REPORT" on="HOLDS">
            {this.state.width > 1200 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(30%)",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : this.state.width > 767 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(40%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            )}
          </Can>
        </div>
      );
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    hold: state.transactions.holdAmount,
    loading: state.transactions.holdAmountLoading,
  };
};

export default connect(mapStateToProps, {
  getReportHoldsAction,
})(Holds);
