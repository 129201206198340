import { backendManagement } from "../backendPlatform";

export const getServices = () => {
  return backendManagement.get("/services");
};

export const getService = name => {
  return backendManagement.get(`/services?name=${name}`);
};

export const updateService = data => {
  return backendManagement.post("/services", data);
};

export const closeWorkingDayDecta = data => {
  return backendManagement.post("/decta_recot", data);
};