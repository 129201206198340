import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import {
  deletePrivilegeAction,
  getAllPrivileges,
} from "../../actions/privileges";
import { reset, searchInPrivileges } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { getPrivileges } from "../../services/paymentBackendAPI/management/privileges";
import PrivilegeEditor from "./Editor";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";

const getString = (array) => {
  let string = "";
  if (array) {
    array.forEach((item) => {
      string = string + item + ", ";
    });
    string = string.slice(0, -2);
  }
  return string;
};

const columns = [
  { path: "guid", label: "ID" },
  { path: "name", label: "Name", key: "privilegeName" },
  {
    key: "type",
    content: (privilege) => getString(privilege.type),
    label: "Type",
  },
  { path: "description", label: "Description" },
  ability.can("EXECUTE", "PRIVILEGES") && {
    key: "edit",
    content: (privilege) => (
      <Modal
        header="Edit privilege"
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        content={PrivilegeEditor}
        contentProps={{ privilege_guid: privilege.guid }}
      />
    ),
    label: "Edit",
    align: "center",
  },
  ability.can("DELETE", "PRIVILEGES") && {
    key: "delete",
    label: "Delete",
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.privileges.privilegesList,
    count: state.privileges.count,
    table: state.store.table,
    loading: state.privileges.privilegesLoading,
    searchData: state.search.privilegesSearch,
    isSearch: state.search.isSearch,
    name: "privileges",
    keyPath: "guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    // modal: <PrivilegeCreator/>,
    exportFunction: getPrivileges,
  };
};

export default connect(mapStateToProps, {
  get: getAllPrivileges,
  delete: deletePrivilegeAction,
  search: searchInPrivileges,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
