import * as types from "../constants/actionTypes";

const initialState = {
  blackList: [],
  blackItem: {
    guid: "",
    name: "",
    type: "",
    description: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: "",
    values: []
  },
  blackListRules: []
};

export default function blacklist(state = initialState, action) {
  switch (action.type) {
    case types.GET_BLACKLIST_ITEMS:
      return {
        ...state,
        itemsLoading: true,
        error: false
      };
    case types.GET_BLACKLIST_ITEMS_SUCCEED:
      return {
        ...state,
        blackList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        itemsLoading: false,
        error: false
      };
    case types.GET_BLACKLIST_ITEMS_FAILED:
      return {
        ...state,
        itemsLoading: false,
        error: true
      };
    case types.UPDATE_BLACKLIST_ITEM: {
      let newblackList = state.blackList;
      newblackList = newblackList.map(item => {
        if (item.guid === action.blackItem.guid) {
          return action.blackItem;
        }
        else {
          return item;
        }
      });
      return { ...state, blackList: newblackList };
    }
    case types.GET_BLACKLIST_ITEM:
      return {
        ...state,
        itemLoading: true,
        error: false
      };
    case types.GET_BLACKLIST_ITEM_SUCCEED:
      return {
        ...state,
        blackItem: action.blackItem,
        blackListRules: action.blackItem.values,
        itemLoading: false,
        error: false
      };
    case types.GET_BLACKLIST_ITEM_FAILED:
      return {
        ...state,
        itemLoading: false,
        error: true
      };
    case types.ADD_BLACKLIST_ITEM:
      return { ...state };
    case types.ADD_BLACKLIST_RULE:
      return { ...state, blackListRules: [ ...state.blackListRules, action.rule ] };
    case types.DELETE_BLACKLIST_RULE:
      return { ...state };
    case types.DELETE_BLACKLIST_ITEM: {
      let newblackList = state.blackList.filter(blacklist => blacklist.guid !== action.blacklist.guid);
      return { ...state, blackList: newblackList };
    }
    case types.GET_BLACKLIST_GLOBAL:
      return {
        ...state,
        globalLoading: true,
        error: false
      };
    case types.GET_BLACKLIST_GLOBAL_SUCCEED:
      return {
        ...state,
        globalBlackList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        globalLoading: false,
        error: false
      };
    case types.GET_BLACKLIST_GLOBAL_FAILED:
      return {
        ...state,
        globalLoading: false,
        error: true
      };
    case types.GET_BLACKLIST_MERCHANTS:
      return {
        ...state,
        merchantsBlackLoading: true,
        error: false
      };
    case types.GET_BLACKLIST_MERCHANTS_SUCCEED:
      return {
        ...state,
        merchantsBlackList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        merchantsBlackLoading: false,
        error: false
      };
    case types.GET_BLACKLIST_MERCHANTS_FAILED:
      return {
        ...state,
        merchantsBlackLoading: false,
        error: true
      };
    default:
      return state;
  }
}