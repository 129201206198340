import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const activePath = (path, location) => {
  return location.includes(path) ? "active" : "";
};

const SidebarCollapseItemChild = ({ child, key, isHide, location }) => {
  const className = activePath(child.path, location);

  return (
    <li className={"nav-item "} key={key}>
      <NavLink
        id="sidebar-text-icon-active"
        title={isHide ? child.name : ""}
        className={
          isHide
            ? "nav-link slideOut " + className
            : "nav-link slideIn " + className
        }
        to={child.path}
        activeClassName=""
      >
        <i className="menu-icon">•</i>

        <p className="menu-title">{child.name}</p>
      </NavLink>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    isHide: state.sidebar.isHide,
    location: window.location.hash,
  };
};

export default connect(mapStateToProps, null)(SidebarCollapseItemChild);

SidebarCollapseItemChild.propTypes = {
  child: PropTypes.object,
  isHide: PropTypes.bool,
  key: PropTypes.string,
  location: PropTypes.string,
};
