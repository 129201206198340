import { backendTransactions, backendManagement } from "../backendPlatform";
import crypto from "crypto";

export const createTransaction = (data, terminalGuid, terminalSecret, terminalHashkey) => {
  const signature = crypto.createHash("sha256").update(JSON.stringify(data) + terminalHashkey).digest("base64");
  data.signature = signature;
  return backendTransactions.post("/create", data, { auth: { "username": terminalGuid, "password": terminalSecret } } );
};

export const getTransactions = (page, items, search) => {
  return backendManagement.get("/transactions/processing", { params: { ...search, page, items } });
};

export const addTransactionTemplate = data => {
  return backendManagement.post("/transactions", data);
};

