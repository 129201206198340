import { calculateStatementAction } from "actions/statements";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import Alert from "../UI/Alert";
import TableRates from "./Components/Tables/TableRates";
import TableSummary from "./Components/Tables/TableSummary";
import StatementForm from "./Components/Form";
class StatementCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statementLoaded: false,
      isLoading: false
    };
  }

  save = async (data) => {
    try {
      this.setState({
        isLoading: true,
        statementLoaded: false,
      });
      await this.props.calculateStatementAction(data);
      swal({
        title: "Statement is created",
        icon: "success",
        text: this.props.warning ? this.props.warning : "",
        button: false,
        timer: 2000,
      });
      this.setState({
        isLoading: false,
      });
      window.location.replace(
        process.env.PUBLIC_URL + "#/transactions/statements" ||
        "#/transactions/statements"
      );
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  calculate = async (data) => {
    try {
      this.setState({
        statementLoaded: false,
        isLoading: true,
      });
      await this.props.calculateStatementAction(data);
      swal({
        title: "Statement is calculated",
        icon: "success",
        text: this.props.warning ? this.props.warning : "",
        button: false,
        timer: 2000,
      });

      this.setState({
        statementLoaded: true,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Card>
          <Card.Body>
            <Card.Title>Interim statement</Card.Title>
            <StatementForm
              calculate={this.calculate}
              save={this.save}
              isLoading={this.state.isLoading}
            />
          </Card.Body>
        </Card>
        {this.state.statementLoaded && (
          <Card>
            <Card.Body>
              <TableSummary
                currencies={this.props.statement.currencies}
                statement_currency_code={this.props.statement.statement_currency_code}
              />
              <TableRates
                additional_fees_names={this.props.statement.additional_fees_names}
                currencies={this.props.statement.currencies}
                statement_currency_code={this.props.statement.statement_currency_code}
                entityData={this.props.statement.entityData}
              />
            </Card.Body>
          </Card>

        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statement: state.statements.statement,
  };
};

export default connect(mapStateToProps, {
  calculateStatementAction,
})(StatementCreator);

StatementCreator.propTypes = {
  calculateStatementAction: PropTypes.func,
  statements: PropTypes.object,
};
