import React from "react";
import PieChart from "components/UI/PieChart";
import { getTransactionsCurrenciesAction } from "actions/transactions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { backgroundColors } from "constants/charts/colors";
import Spinner from "components/UI/Spinner";

class PieChartCurrencies extends React.Component {
  state = {
    daysCount: 7,
    loading: true,
    data: {},
  };

  componentDidMount() {
    this.props.getTransactionsCurrenciesAction(this.props.data);
  }

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.data !== this.props.data) {
      this.props.getTransactionsCurrenciesAction(nextProps.data);
    }
    return true;
  };

  render() {
    let datasetsPie = [];
    let labelsPie = [];
    let data = [];
    let colors = [];
    this.props.currencyData.forEach((item, index) => {
      data.push(item.count);
      colors.push(backgroundColors[index]);
      datasetsPie = [ { data: data, backgroundColor: colors } ];
      labelsPie = [ ...labelsPie, item.currency ];
    });
    if (this.props.loading) return <Spinner />;
    else
      return (
        <PieChart
          labels={
            labelsPie.length !== 0 ? labelsPie : [ "There are no currencies yet" ]
          }
          datasets={
            datasetsPie.length !== 0
              ? datasetsPie
              : [
                  {
                    data: [ 1 ],
                    backgroundColor: "#CCC",
                  },
                ]
          }
        />
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currencyData: state.transactions.transactionCurrency,
    loading: state.transactions.transactionCurrencyLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionsCurrenciesAction,
})(PieChartCurrencies);

PieChartCurrencies.propTypes = {
  currencyData: PropTypes.array,
  data: PropTypes.object,
  getTransactionsCurrenciesAction: PropTypes.func,
  loading: PropTypes.bool,
};
