import Spinner from "components/UI/Spinner/index";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Content from "views/Content";
import {
  getAuditPartnerAction,
  getAuditPartnerTablesAction,
} from "../../../../actions/audit";
import ability from "../../../../config/ability";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableAudit } from "../common/Table";

const PartnerAuditDetail = () => {
  const [ page, setPage ] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();
  const history = useParams();
  const { count, data, loading } = useSelector(({ audit }) => {
    return {
      count: audit.partnersTablesCount,
      data: audit.partnersTables,
      loading: audit.loading,
    };
  });
  const { partner } = useSelector(({ audit }) => ({
    partner: audit.partners,
  }));
  const [ partnerData, setPartnerData ] = useState({});

  useEffect(() => {
    if (partner && partner[0]) {
      setPartnerData(partner[0]);
    }
  }, [ partner ]);

  const rows = [
    {
      label: "Partner name",
      path: "name",
    },
    {
      label: "Partner type",
      path: "type",
    },
  ];

  const loadData = async (page) => {
    dispatch(
      getAuditPartnerTablesAction({
        guid: history.id,
        page,
        items: pageSize,
      })
    );
  };

  useEffect(() => {
    dispatch(getAuditPartnerAction({ guid: history.id }));
    loadData(page, pageSize);
  }, []);

  const handleChangePage = async (page) => {
    setPage(page);
    loadData(page);
  };

  return (
    <Content style={{ overflow: "auto" }}>
      <Row style={{ marginLeft: "0px" }}>
        <Card>
          <Card.Header as="h5">{partner.partner_name}</Card.Header>
          <Card.Body>
            <Card.Title>Info</Card.Title>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>partner GUID:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{history.id}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>partner Name:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partnerData.partner_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>partner Type:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partnerData.partner_type}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>partner status:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partnerData.partner_status}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>last change:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partnerData.last_change}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>change count:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partnerData.change_count}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>author:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partnerData.author_name}</span>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>

      {ability.can("AUDIT", "PARTNERS") && (
        <Card>
          <Card.Body>
            <Card.Title>History</Card.Title>
            {loading ? (
              <Spinner />
            ) : (
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <TableAudit
                    data={data}
                    count={count}
                    handleChangePage={handleChangePage}
                    page={page}
                    pageSize={pageSize}
                    rows={rows}
                  />
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      )}
    </Content>
  );
};

export default PartnerAuditDetail;
