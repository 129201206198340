import Modal from "components/UI/Modal";
import ability from "config/ability";
import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reset, searchInStatements } from "../../actions/search";
import {
  deleteStatementAction,
  getAllStatements,
} from "../../actions/statements";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import { cutGuid } from "../../helpers/cutGuid";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import { getStatements } from "../../services/paymentBackendAPI/management/statements";
import { Copy } from "../UI/CopyToClipboard";
import StatementEditor from "./Editor";

const columns = [
  {
    path: "guid",
    label: "ID",
    key: "guid",
    content: (statement) => (
      <>
        <Copy text={statement.guid} />
        <Link className="link" to={`/about/statement/${statement.guid}`}>
          {cutGuid(statement.guid)}
        </Link>
      </>
    ),
    textContent: (statement) => statement.guid,
    style: { minWidth: "120px" },
  },
  { path: "name", label: "Name", key: "statementName" },
  {
    path: "merchant_name",
    label: "Merchant",
    key: "merchant_name",
    content: (statement) => (
      <Link className="link" to={`/about/merchant/${statement.merchant_guid}`}>
        {statement.merchant_name}
      </Link>
    ),
    textContent: (statement) => statement.merchant_name,
  },
  {
    path: "group_name",
    label: "Group",
    key: "group_name",
    content: (statement) => (
      <Link className="link" to={`/about/group/${statement.group_guid}`}>
        {statement.group_name}
      </Link>
    ),
    textContent: (statement) => statement.group_name,
  },
  {
    path: "statement_currency_code",
    label: "Currency",
    key: "currency",
    align: "center",
  },
  {
    path: "summary_amount",
    label: "Summary amount",
    key: "summary_amount",
    align: "right",
  },
  {
    path: "summary_count",
    label: "Summary count",
    key: "summary_count",
    align: "center",
  },
  {
    path: "status",
    label: "Statement status",
    key: "statementPaymentStatus",
    content: (statement) => (
      <Badge
        className={`badge-statement-${statement.status
          .replace(" ", "")
          .toLowerCase()}`}
      >
        {statement.status}
      </Badge>
    ),
    textContent: (statement) => statement.status,
    align: "center",
  },
  {
    label: "Parent",
    key: "parent_statement",
    content: (statement) => (
      <Link className="link" to={`/about/statement/${statement.parent_guid}`}>
        {statement.parent_name}
      </Link>
    ),
    textContent: (statement) => statement.parent_name,
  },
  {
    path: "from_date",
    label: "From date",
    key: "from_date",
    content: (statement) =>
      moment(statement.from_date).utcOffset(0).format("DD.MM.YYYY"),
    align: "center",
  },
  {
    path: "to_date",
    label: "To date",
    key: "to_date",
    content: (statement) =>
      moment(statement.to_date).utcOffset(0).format("DD.MM.YYYY"),
    align: "center",
  },

  {
    key: "edit",
    align: "center",
    content: (statement) =>
      statement.status === "Created" &&
      ability.can("EXECUTE", "STATEMENTSTATUSVERIFIED") ? (
            <Modal
              header="Change status"
              button={
                <button
                  type="button"
                  className="btn btn-table btn-fill btn-status verified"
                >
              Verified
                </button>
              }
              content={StatementEditor}
              contentProps={{ guid: statement.guid, status: statement.status }}
            />
          ) : statement.status === "Verified" &&
        ability.can("EXECUTE", "STATEMENTSTATUSPAYMENTDUE") ? (
                <Modal
                  header="Change status"
                  button={
                    <button
                      type="button"
                      className="btn btn-table btn-fill btn-status paymentdue"
                    >
              Payment Due
                    </button>
                  }
                  content={StatementEditor}
                  contentProps={{ guid: statement.guid, status: statement.status }}
                />
              ) : statement.status === "Payment Due" &&
        ability.can("EXECUTE", "STATEMENTSTATUSPAID") &&
        statement.merge_statement_flag === true ? (
                    <Modal
                      header="Change status"
                      button={
                        <button
                          type="button"
                          className="btn btn-table btn-fill btn-status paid"
                        >
              Paid
                        </button>
                      }
                      content={StatementEditor}
                      contentProps={{ guid: statement.guid, status: statement.status }}
                    />
                  ) : null,
    label: "Edit",
  },
  ability.can("DELETE", "STATEMENTS") && {
    key: "delete",
    content: () => (
      <i
        className="far fa-trash-alt"
        style={{ cursor: "pointer", color: "red" }}
      />
    ),
    label: "Delete",
    colWidth: 4,
  },
];

if (getAuthData() && getAuthData().userPayload.merchant) columns.splice(2, 2);
if (getAuthData() && getAuthData().userPayload.group) columns.splice(3, 1);

const mapStateToProps = (state) => {
  return {
    data: state.statements.statements,
    count: state.statements.count,
    table: state.store.table,
    loading: state.statements.statementLoading,
    searchData: state.search.statementsSearch,
    isSearch: state.search.isSearch,
    name: "statements",
    columns,
    columnsComponent: createColComponentForTable(columns),
    exportFunction: getStatements,
  };
};

export default connect(mapStateToProps, {
  get: getAllStatements,
  delete: deleteStatementAction,
  search: searchInStatements,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
