import Select from "components/UI/MultiSelect";
import PropTypes from "prop-types";
import React from "react";
import {
  CustomMenu,
  CustomMultiValueContainer,
  InputOption,
} from "../Components";

const MultiSelector = (props) => {
  return (
    <div
      id="search-wrapper"
      className={
        props.isSearch
          ? "searchOpen search search-input"
          : "searchClosed search search-input"
      }
    >
      <Select
        multi={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        value={props.selectedOptions}
        onSelect={props.onSelect}
        options={props.options}
        applyFilter={props.applyFilter}
        onChange={props.onSelect}
        components={{
          Option: InputOption,
          ValueContainer: CustomMultiValueContainer,
          Menu: CustomMenu,
        }}
      />
    </div>
  );
};

export default MultiSelector;

MultiSelector.propTypes = {
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  options: PropTypes.array,
  search: PropTypes.func,
  searchData: PropTypes.object,
  value: PropTypes.string,
};
