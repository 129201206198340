import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "components/UI/Table/index";
import Pagination from "components/UI/Pagination/index";
import { connect } from "react-redux";
import { getTotalsAction } from "actions/transactions";
import Spinner from "components/UI/Spinner/index";
import moment from "moment";
import PropTypes from "prop-types";
import { Export } from "components/Export";
import {
  getAllShops,
  getMerchantShopsAction,
  getShopTerminalsAction,
} from "actions/shops";
import { getTotals } from "../../../services/paymentBackendAPI/reports/transactions";

export class Totals extends Component {
  state = {
    detailedTotals: [],
    count: "",
    currentPage: 1,
    pageSize: 10,
    data: {
      merchant_guid_array: [],
      group_guid_array: [],
      partner_guid_array: [],
      terminal_guid_array: [],
      shop_guid_array: [],
      from_date: moment()
        .format("YYYY-MM-DDTHH:mm:ss")
        .replace(/\D\d{2}\w/, "-01T"),
      to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    },
  };

  columnsDetailedTotals = [
    { path: "currency", label: "Currency" },
    { path: "type", label: "Transaction type" },
    { path: "status", label: "Status" },
    { path: "amount", label: "Amount" },
    { path: "number", label: "Count" },
  ];

  async componentDidMount() {
    await this.props.getTotalsAction({
      page: 1,
      items: this.state.pageSize,
      ...this.state.data,
    });
    this.setState({
      detailedTotals: this.props.detailedTotals,
      count: this.props.count,
    });
  }

  shouldComponentUpdate = async (nextProps) => {
    if (nextProps.data !== this.props.data) {
      await this.props.getTotalsAction({
        page: 1,
        items: this.state.pageSize,
        ...nextProps.data,
      });
    }
    return true;
  };

  handlePageChange = async (page) => {
    await this.props.getTotalsAction({
      page,
      items: this.state.pageSize,
      ...this.state.data,
    });
    this.setState({
      detailedTotals: this.props.detailedTotals,
      count: this.props.count,
      currentPage: page,
    });
  };

  getPagedData = () => {
    const { count } = this.props;
    let { pageSize } = this.state;
    const pagesCount = count
      ? count / pageSize + (1 && !!(count % pageSize))
      : 0;
    return { pagesCount };
  };

  onSubmitCalculate = (data) => {
    this.setState({ data: data }, () => {
      this.props.getTotalsAction({
        page: 1,
        items: this.state.pageSize,
        ...data,
      });
    });
  };

  render() {
    const { currentPage } = this.state;
    const { pagesCount } = this.getPagedData();
    return (
      <div>
        <React.Fragment>
          {/* <ReportForm handleSubmit={this.onSubmitCalculate} /> */}
          <Row>
            <Col md={8} sm={8} xs={8}></Col>
            <Col md={4} sm={4} xs={4}>
              <Export
                id={"totals"}
                // searchData={this.getSearchData()}
                exportFunction={getTotals}
                columns={this.columnsDetailedTotals}
                name={"BasicReportTotals"}
                style={{ float: "right" }}
              />
            </Col>
          </Row>

          {this.props.detailedTotalsLoading ? (
            <Spinner />
          ) : (
            <>
              {/* <div className="header">Totals</div> */}
              <div className="content" style={{ overflow: "auto" }}>
                <Row>
                  <Col md={12}>
                    <div className="table">
                      <Table
                        columns={this.columnsDetailedTotals}
                        data={this.props.detailedTotals}
                        keyPath={[ "currency", "type", "status" ]}
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={pagesCount}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                        pageSize={this.state.pageSize}
                        count={this.props.count}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    detailedTotals: state.transactions.totals.data,
    count: state.transactions.totals.count,
    detailedTotalsLoading: state.transactions.totalsLoading,
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    merchants: state.merchants.merchantsList,
    merchantsLoading: state.merchants.merchantsLoading,
  };
};

export default connect(mapStateToProps, {
  getTotalsAction,
  getAllShops,
  getMerchantShopsAction,
  getShopTerminalsAction,
})(Totals);

Totals.propTypes = {
  count: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  detailedTotals: PropTypes.array,
  detailedTotalsLoading: PropTypes.bool,
  getAllShops: PropTypes.func,
  getMerchantShopsAction: PropTypes.func,
  getTotalsAction: PropTypes.func,
  merchants: PropTypes.array,
  merchantsLoading: PropTypes.bool,
  shops: PropTypes.array,
};
