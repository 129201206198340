import * as types from "../constants/actionTypes";

const initialState = {
  partnersList: [],
  partnerLogins: [],
  partnerGroups: [],
  partnerMerchants: [],
  topPartners: [],
  partner: {
    guid: "",
    name: "",
    type: "",
    created_at: "",
    created_by: "",
    updated_at: "",
    updated_by: "",
    groups: []
  },
  changedGroups: [],
  partnerLoading: false,
  partnersLoading: false,
  error: false,
  partnerGroupsLoading: false,
  partnerGroupsLoadingError: false,
};

export default function partners(state = initialState, action) {
  switch (action.type) {
    case types.ADD_PARTNER:
      return { ...state, partner_guid: action.data.guid };
    case types.ADD_PARTNER_LOGIN:
      return { ...state, partnerLogins: [ action.partnerLogin ] };
    case types.GET_PARTNERS:
      return {
        ...state,
        partnersLoading: true,
        error: false
      };
    case types.GET_PARTNERS_SUCCEED:
      return {
        ...state,
        partnersList: action.data.data,
        count: action.data.count,
        pageSize: action.pageSize,
        currentPage: action.currentPage,
        partnersLoading: false,
        error: false
      };
    case types.GET_PARTNERS_FAILED:
      return {
        ...state,
        partnersLoading: false,
        error: true
      };
    case types.GET_PARTNER:
      return {
        ...state,
        partnerLoading: true,
        error: false
      };
    case types.GET_PARTNER_SUCCEED:
      return {
        ...state,
        partner: action.partner,
        partnerLoading: false,
        error: false
      };
    case types.GET_PARTNER_FAILED:
      return {
        ...state,
        partnerLoading: false,
        error: true
      };
    case types.EDIT_PARTNER: {
      let newPartnersList = state.partnersList;
      newPartnersList = newPartnersList.map(partner => {
        if (partner.partner_guid === action.partner.partner_guid)
          return { ...partner, ...action.partner };
        else
          return partner;
      });
      return { ...state, partnersList: newPartnersList };
    }
    case types.DELETE_PARTNER: {
      let newPartnersList = state.partnersList.filter(partner => partner.partner_guid !== action.partner.partner_guid);
      return { ...state, partnersList: newPartnersList };
    }
    case types.GET_PARTNER_LOGINS:
      return { 
        ...state, 
        partnerLoginsLoading: true 
      };
    case types.GET_PARTNER_LOGINS_SUCCEED:
      return { 
        ...state, 
        partnerLogins: action.data.data, 
        loginsCount: action.data.count,
        partnerLoginsLoading: false 
      };
    case types.GET_PARTNER_LOGINS_FAILED:
      return { 
        ...state, 
        partnerLoginsLoading: false 
      };
    case types.GET_PARTNER_GROUPS:
      return {
        ...state,
        partnerGroupsLoading: true,
        partnerGroupsLoadingError: false,
      };
    case types.GET_PARTNER_GROUPS_SUCCEED:
      return {
        ...state,
        partnerGroups: action.data.data,
        groupsCount: action.data.count,
        partnerGroupsLoading: false,
        partnerGroupsLoadingError: false,
      };
    case types.GET_PARTNER_GROUPS_FAILED:
      return {
        ...state,
        partnerGroupsLoading: false,
        partnerGroupsLoadingError: true,
      };
    case types.DELETE_GROUP_FROM_PARTNER:{
      let newGroupsList = state.partnerGroups ? state.partnerGroups : [];
      newGroupsList = newGroupsList.filter(group => group.guid !== action.group.guid);
      return { ...state, partnerGroups: newGroupsList };
    }
    case types.UPSERT_PARTNER_CHANGED_GROUP:{
      let partnerGroups = state.partnerGroups;
      let changedGroups = state.changedGroups;

      if (typeof (changedGroups) === "undefined")
        changedGroups = [];
      if (action.group.delete) {
        partnerGroups = partnerGroups.filter(group => group.guid !== action.group.guid);
        const newChangedGroups = changedGroups.filter(group => group.guid !== action.group.guid);
        if (newChangedGroups.length === changedGroups.length)
          changedGroups = [ ...changedGroups, action.group ];
        else
          changedGroups = newChangedGroups;
      }
      else {
        partnerGroups = partnerGroups.filter(group => !(group.guid === action.group.guid && group.delete));
        changedGroups = changedGroups.filter(group => !(group.guid === action.group.guid && group.delete));
        partnerGroups = [ ...partnerGroups, action.group ];
        changedGroups = [ ...changedGroups, action.group ];
      }
      return { ...state, partnerGroups, changedGroups };
    
    }
    case types.GET_PARTNER_MERCHANTS:
      return { ...state, partnerMerchants: action.data.data, merchantsCount: action.data.count };
    case types.GET_TOP_PARTNERS:
      return { 
        ...state, 
        topPartnersLoading: true 
      };
    case types.GET_TOP_PARTNERS_SUCCEED:
      return { 
        ...state, 
        topPartners: action.data,
        topPartnersLoading: false 
      };
    case types.GET_TOP_PARTNERS_FAILED:
      return { 
        ...state, 
        topPartnersLoading: false
      };
    case types.RESET_GROUP_MANAGEMENT_DATA:
      return {
        ...state,
        partnerGroups: [],
        changedGroups: []
      };
    default:
      return state;
  }
}
