import React, { Component } from "react";
import MultiSelect from "components/UI/MultiSelect";
import { daysSinceTo, lastNDays } from "../../services/dateTime/dateTime";
import Can from "components/Can";
import { LineChart } from "../UI/LineChart";
import { borderColors, backgroundColors } from "../../constants/charts/colors";
import Spinner from "components/UI/Spinner";
import { Badge, Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { getAdminDashboardComponentDataAction } from "../../actions/transactions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getAllMerchants } from "actions/merchants";

class AdminDashboardComponent extends Component {
  state = {
    periods: [
      { name: "Period", guid: "0", value: "0", label: "Period" },
      { name: "Last week", guid: "7", value: "7", label: "Last week" },
      { name: "Last month", guid: "30", value: "30", label: "Last month" },
      { name: "Today", guid: "1", value: "1", label: "Today" },
    ],
    selectedPeriod: { name: "Period", guid: "0", value: "0", label: "Period" },
    merchants: [],
    selectedMerchants: [],
    startDate: moment()
      .utcOffset(0)
      .format("YYYY-MM-DDTHH:mm:ss")
      .replace(/\D\d{2}\w/, "-01T"),
    endDate: moment().utcOffset(0).format("YYYY-MM-DDTHH:mm:ss"),

    summary: {
      totallyProcessed: [],
      costOfProcessedPaymentsTotal: [],
      grandTotal: [],
      rateTotal: [],
      refundTotal: [],
      chargebackTotal: [],
    },

    totallyProcessedFlag: true,
    grandTotalFlag: true,
    rateFlag: false,
    costOfProcessedPaymentsFlag: false,
    refundFlag: false,
    chargebackFlag: false,

    merchantData: [],

    width: 0,

    labelsPie: [],
    datasets: [],
    loading: false,
  };

  async componentDidMount() {
    await this.props.getAllMerchants();
    let merchants = this.props.merchants.map((item) => {
      item.name = item.merchant_name;
      item.guid = item.merchant_guid;
      return item;
    });
    merchants = merchants.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.guid === item.guid)
    );
    this.setState({ merchants });
    this.loadData();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  loadData = async () => {
    this.setState({ loading: true });
    try {
      this.setState({
        merchantData: [],
      });
      let merchants = this.state.selectedMerchants
        ? this.state.selectedMerchants.map((merchant) => merchant.guid)
        : [];
      if (this.state.selectedPeriod.value === "0") {
        this.setState({
          labelsPie: daysSinceTo(
            new Date(this.state.startDate),
            new Date(this.state.endDate)
          ),
        });
        await this.props.getAdminDashboardComponentDataAction({
          date_from: this.state.startDate,
          date_till: this.state.endDate,
          merchant_guid: merchants,
        });
      } else if (this.state.selectedPeriod.value === "30") {
        this.setState({
          labelsPie: daysSinceTo(
            new Date(moment().startOf("month")),
            new Date(moment().endOf("month"))
          ),
        });
        await this.props.getAdminDashboardComponentDataAction({
          date_from: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
          date_till: moment().endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
          merchant_guid: merchants,
        });
      } else if (this.state.selectedPeriod.value === "7") {
        this.setState({
          labelsPie: lastNDays(this.state.selectedPeriod.value),
        });
        await this.props.getAdminDashboardComponentDataAction({
          last_days: this.state.selectedPeriod.value,
          merchant_guid: merchants,
        });
      } else if (this.state.selectedPeriod.value === "1") {
        this.setState({
          labelsPie: [
            "00:00",
            "1:00",
            "2:00",
            "3:00",
            "4:00",
            "5:00",
            "6:00",
            "7:00",
            "8:00",
            "9:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        });
        await this.props.getAdminDashboardComponentDataAction({
          date_from: moment().format("YYYY-MM-DDTHH:mm:ss"),
          date_till: moment().format("YYYY-MM-DDTHH:mm:ss"),
          merchant_guid: merchants,
        });
      }

      let response = this.props.response || {};

      let summary = {};
      summary.totallyProcessed =
        response.total?.totally_processed?.map((item) => {
          return `${item.amount} ${item.currency} (${item.passed_amount_percent}%) count ${item.transaction_count} (${item.passed_count_percent}%) `;
        }) || [];

      summary.costOfProcessedPaymentsTotal =
        response.total?.percent_fees
          ?.filter((item) => item.amount !== 0 && item.currency !== null)
          .map((item) => {
            return `${item.amount} ${item.currency} `;
          }) || [];

      summary.grandTotal =
        response.total?.total_fees
          ?.filter((item) => item.amount !== 0 && item.currency !== null)
          .map((item) => {
            return `${item.amount} ${item.currency} `;
          }) || [];

      summary.rateTotal =
        response.total?.fixed_fees
          ?.filter((item) => item.amount !== 0 && item.currency !== null)
          .map((item) => {
            return `${item.amount} ${item.currency} `;
          }) || [];

      summary.refundTotal =
        response.total?.refund
          ?.filter((item) => item.amount !== 0 && item.currency !== null)
          .map((item) => {
            return `${item.amount} ${item.currency} `;
          }) || [];

      summary.chargebackTotal =
        response.total?.chargeback
          ?.filter((item) => item.amount !== 0 && item.currency !== null)
          .map((item) => {
            return `${item.amount} ${item.currency} `;
          }) || [];

      let data = [];
      response.data &&
        response.data.forEach((item) => {
          if (this.state.totallyProcessedFlag) {
            // if (item.totally_processed.filter((c) => c > 0).length)
            data.push({
              label: item.merchant_name + " processed " + item.currency,
              values: item.totally_processed,
            });
          }
          if (this.state.grandTotalFlag) {
            // if (item.grand_total.filter((c) => c > 0).length)
            data.push({
              label: item.merchant_name + " grand total " + item.currency,
              values: item.grand_total,
            });
          }
          if (this.state.costOfProcessedPaymentsFlag) {
            // if (item.percent_fees.filter((c) => c > 0).length)
            data.push({
              label: item.merchant_name + " percent fee " + item.currency,
              values: item.percent_fees,
            });
          }
          if (this.state.rateFlag) {
            // if (item.fixed_fees.filter((c) => c > 0).length)
            data.push({
              label: item.merchant_name + " fix fee " + item.currency,
              values: item.fixed_fees,
            });
          }
          if (this.state.refundFlag) {
            // if (item.refund.filter((c) => c > 0).length)
            data.push({
              label: item.merchant_name + " refund " + item.currency,
              values: item.refund,
            });
          }
          if (this.state.chargebackFlag) {
            // if (item.chargeback.filter((c) => c > 0).length)
            data.push({
              label: item.merchant_name + " chargeback " + item.currency,
              values: item.chargeback,
            });
          }
        });

      let datasets = data.map((arr, index) => {
        return {
          label: arr.label,
          fill: false,
          lineTension: 0.1,
          backgroundColor: backgroundColors[index % backgroundColors.length],
          borderColor: borderColors[index % borderColors.length],
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: borderColors[index % borderColors.length],
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor:
            backgroundColors[index % backgroundColors.length],
          pointHoverBorderColor: "black",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: arr.values,
        };
      });
      this.setState({ datasets, data, summary });
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  onChange = ({ currentTarget: input }) => {
    this.setState({ [input.name]: !this.state[input.name] }, () =>
      this.loadData()
    );
  };

  onSelect = (option) => {
    this.setState({ selectedPeriod: option }, () => this.loadData());
  };

  onSelectMerchant = (options) => {
    this.setState({ selectedMerchants: options }, () => this.loadData());
  };

  onDateChange = async (start, end) => {
    this.setState(
      {
        startDate: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => this.loadData()
    );
  };

  render() {
    return (
      <div className="admin-dashboard">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <Card.Header
                as="h5"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Turnover
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6} />
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <MultiSelect
                      name="merchants"
                      options={this.state.merchants}
                      multi={true}
                      onSelect={this.onSelectMerchant}
                      placeholder="Select merchants"
                      defaultValue={this.state.selectedMerchants}
                    />
                  </Col>
                </Row>
                <div>
                  <input
                    type="checkbox"
                    name="totallyProcessedFlag"
                    id="totally"
                    checked={this.state.totallyProcessedFlag}
                    onChange={this.onChange}
                  />
                  Totally processed:
                  <p>
                    <span>
                      Summary:
                      {" " + this.state.summary.totallyProcessed.join(" / ")}
                    </span>
                    {/* <span>{this.state.summary.percent}%</span> */}
                  </p>
                  {/* {this.state.merchantData.map((data) => {
                    return (
                      <p key={data.merchant_guid}>
                        {this.state.totallyProcessedFlag && (
                          <span>
                            {data.merchantName + " " + data.totallyProcessed}{" "}
                          </span>
                        )}
                        {this.state.totallyProcessedFlag && (
                          <span> {" / " + data.percent}%</span>
                        )}
                      </p>
                    );
                  })} */}
                </div>

                <Card>
                  <Card.Body>
                    <div>
                      <input
                        type="checkbox"
                        name="grandTotalFlag"
                        id="grandTotalFlag"
                        checked={this.state.grandTotalFlag}
                        onChange={this.onChange}
                      />
                      Grand total:
                      <p>
                        <span>
                          Summary:{" "}
                          {this.state.grandTotalFlag &&
                            this.state.summary.grandTotal.join(" / ")}
                        </span>
                      </p>
                      {/* {this.state.merchantData.map((data) => {
                        return (
                          <p key={data.merchant_guid}>
                            {this.state.grandTotalFlag && (
                              <span>
                                {" "}
                                {data.merchantName +
                                  ": " +
                                  data.grandTotalTotal}{" "}
                              </span>
                            )}
                          </p>
                        );
                      })} */}
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="costOfProcessedPaymentsFlag"
                        id="costOfProcessedPaymentsFlag"
                        checked={this.state.costOfProcessedPaymentsFlag}
                        onChange={this.onChange}
                      />
                      Cost of processed payments:
                      <p>
                        <span>
                          Summary:{" "}
                          {this.state.costOfProcessedPaymentsFlag &&
                            this.state.summary.costOfProcessedPaymentsTotal.join(
                              " / "
                            )}
                        </span>
                      </p>
                      {/* {this.state.merchantData.map((data) => {
                        return (
                          <p key={data.merchant_guid}>
                            {this.state.costOfProcessedPaymentsFlag && (
                              <span>
                                {" "}
                                {data.merchantName +
                                  ": " +
                                  data.costOfProcessedPaymentsTotal}{" "}
                              </span>
                            )}
                          </p>
                        );
                      })} */}
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="rateFlag"
                        id="rateFlag"
                        checked={this.state.rateFlag}
                        onChange={this.onChange}
                      />
                      Successful transactions commissions:
                      <p>
                        <span>
                          Summary:{" "}
                          {this.state.rateFlag &&
                            this.state.summary.rateTotal.join(" / ")}
                        </span>
                      </p>
                      {/* {this.state.merchantData.map((data) => {
                        return (
                          <p key={data.merchant_guid}>
                            {this.state.rateFlag && (
                              <span>
                                {" "}
                                {data.merchantName + ": " + data.rateTotal}{" "}
                              </span>
                            )}
                          </p>
                        );
                      })} */}
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="refundFlag"
                        id="refundFlag"
                        checked={this.state.refundFlag}
                        onChange={this.onChange}
                      />
                      Cost of refunds:
                      <p>
                        <span>
                          Summary:{" "}
                          {this.state.refundFlag &&
                            this.state.summary.refundTotal.join(" / ")}
                        </span>
                      </p>
                      {/* {this.state.merchantData.map((data) => {
                        return (
                          <p key={data.merchant_guid}>
                            {this.state.refundFlag && (
                              <span>
                                {" "}
                                {data.merchantName +
                                  ": " +
                                  data.refundTotal}{" "}
                              </span>
                            )}
                          </p>
                        );
                      })} */}
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="chargebackFlag"
                        id="chargebackFlag"
                        checked={this.state.chargebackFlag}
                        onChange={this.onChange}
                      />
                      Cost of chargebacks:
                      <p>
                        <span>
                          Summary:{" "}
                          {this.state.chargebackFlag &&
                            this.state.summary.chargebackTotal.join(" / ")}
                        </span>
                      </p>
                      {/* {this.state.merchantData.map((data) => {
                        return (
                          <p key={data.merchant_guid}>
                            {this.state.chargebackFlag && (
                              <span>
                                {" "}
                                {data.merchantName +
                                  ": " +
                                  data.chargebackTotal}{" "}
                              </span>
                            )}
                          </p>
                        );
                      })} */}
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <Card.Header
                as="h5"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Total
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                    {this.state.selectedPeriod.value === "0" && (
                      <DateRangePicker
                        onCallback={this.onDateChange}
                        initialSettings={{
                          startDate: moment(this.state.startDate).format(
                            "DD.MM.YYYY"
                          ),
                          endDate: moment(this.state.endDate).format(
                            "DD.MM.YYYY"
                          ),
                          locale: {
                            format: "DD.MM.YYYY",
                          },
                        }}
                      >
                        <input
                          type="text"
                          className="text-input daterange-input form-control"
                          style={{ fontSize: "13px" }}
                        />
                      </DateRangePicker>
                    )}
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <MultiSelect
                      name="type"
                      options={this.state.periods}
                      multi={false}
                      onSelect={this.onSelect}
                      placeholder="Select period"
                      defaultValue={this.state.selectedPeriod}
                    />
                  </Col>
                </Row>

                {this.state.loading ? (
                  <div className="loader" style={{ height: "100px" }}>
                    <Spinner />
                  </div>
                ) : (
                  <div className="chart">
                    <Can do="REPORT" on="TRANSACTIONAMOUNT">
                      <LineChart
                        labels={this.state.labelsPie}
                        datasets={this.state.datasets}
                        name="Transactions amount"
                        // options={{
                        //   scales: {
                        //     yAxes: [
                        //       {
                        //         ticks: {
                        //           beginAtZero: true,
                        //         },
                        //       },
                        //     ],
                        //   },
                        // }}
                      />
                    </Can>
                    <Can not do="REPORT" on="TRANSACTIONAMOUNT">
                      {this.state.width > 1200 ? (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(40%)",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                          No privileges
                        </Badge>
                      ) : (
                        <Badge
                          pill
                          style={{
                            position: "absolute",
                            left: "calc(45%)",
                            marginTop: "30px",
                            top: "40%",
                            fontSize: "1.2vw",
                          }}
                        >
                          No privileges
                        </Badge>
                      )}
                    </Can>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.transactions.dashboardTotals,
    merchants: state.merchants.merchantsList,
  };
};

export default connect(mapStateToProps, {
  getAdminDashboardComponentDataAction,
  getAllMerchants,
})(AdminDashboardComponent);
