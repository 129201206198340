import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  createItemMerchantsBlackListAction,
  addBlackListItemAction,
} from "../../actions/blacklists";
import { parseResponse } from "../../helpers/parseResponse";
import Alert from "../UI/Alert";

export default function BLButton({ value, name, type, merchant_guid }) {
  const dispatch = useDispatch();
  const onClick = async () => {
    swal({
      title: "Are you sure?",
      text: "This can only be deleted in the Blacklist rules.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (confirmValue) => {
      if (confirmValue)
        try {
          let response = await dispatch(
            addBlackListItemAction({
              name,
              type,
              description: `${name} created from order`,
              values: [ value ],
            })
          );
          await dispatch(
            createItemMerchantsBlackListAction({
              blacklist_rule_guid: response.guid,
              merchant_guid,
              type,
            })
          );
          swal({
            title: "Record is created",
            icon: "success",
            button: false,
            timer: 2000,
          });
        } catch (error) {
          console.log(error);
          const parsedError = parseResponse(error);
          Alert({
            type: "error",
            message:
              parsedError.message + " \n Check this rule in blacklist rules",
          });
        }
    });
  };
  return (
    <Button onClick={onClick} variant="outline-primary">
      Add to black list
    </Button>
  );
}
