import {
  deleteItemGlobalBlackListAction,
  getBlackListGlobalAction,
} from "actions/blacklists";
import { reset, searchInGlobalBlacklist } from "actions/search";
import ability from "config/ability";
import AbstractComponent from "factories/Main";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { resetPage, setNewTable } from "../../../actions/store";
import { getBlackListGlobal } from "../../../services/paymentBackendAPI/management/blacklists";
import { createColComponentForTable } from "../../../helpers/createColComponentForTable";

const columns = [
  {
    path: "blacklist_rule_name",
    label: "Rule name",
    key: "blacklist_rule_name",
    content: (item) => (
      <Link
        className="link"
        to={`/about/blacklist/${item.blacklist_rule_guid}`}
      >
        {item.blacklist_rule_name}
      </Link>
    ),
    textContent: (item) => item.blacklist_rule_name,
  },
  { path: "type", label: "Type", key: "typeBlacklist" },
  ability.can("DELETE", "GLOBALBLACKLISTS") && {
    key: "deleteGlobalBlacklist",
    content: () => (
      <i className="far fa-trash-alt icon red" style={{ cursor: "pointer" }} />
    ),
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.blacklist.globalBlackList,
    count: state.blacklist.count,
    table: state.store.table,
    searchData: state.search.globalBlacklistSearch,
    isSearch: state.search.isSearch,
    loading: state.blacklist.globalLoading,
    name: "GLOBALBLACKLISTS",
    keyPath: "blacklist_rule_guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    exportFunction: getBlackListGlobal,
  };
};

export default connect(mapStateToProps, {
  get: getBlackListGlobalAction,
  delete: deleteItemGlobalBlackListAction,
  search: searchInGlobalBlacklist,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
