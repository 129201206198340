import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteMerchantAction, getAllMerchants } from "../../actions/merchants";
import { reset, searchInMerchants } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import { getMerchants } from "../../services/paymentBackendAPI/management/merchants";
import MerchantCreator from "./Creator";
import MerchantEditor from "./Editor";

let columns = [
  {
    path: "merchant_name",
    label: "Merchant name",
    content: (merchant) => (
      <Link className="link" to={`/about/merchant/${merchant.merchant_guid}`}>
        {merchant.merchant_name}
      </Link>
    ),
    textContent: (merchant) => merchant.merchant_name,
  },
  { path: "merchant_type", label: "Merchant type" },
  { path: "group_name", label: "Group" },
  { path: "partner_name", label: "Partner" },
  {
    path: "gateways",
    label: "Gateways",
    key: "gateways",
    content: (merchant) => merchant.gateways.join(", "),
  },
  { path: "username", label: "Username" },
  { path: "email", label: "Email" },
  {
    key: "enabled",
    content: (login) => (
      <i
        className={
          login.enabled ? "icon-success green icon" : "icon-failed red icon"
        }
      />
    ),
    label: "Status",
    textContent: (login) => (login.enabled ? "Enabled" : "Disabled"),
    align: "center",
    colWidth: 4,
  },
  ability.can("EXECUTE", "USERMERCHANT") && {
    key: "edit",
    content: (merchant) => (
      <Modal
        header="Edit merchant"
        action="Save"
        content={MerchantEditor}
        contentProps={{ guid: merchant.merchant_guid }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        dialogClassName="modal-creator"
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "USERMERCHANT") && {
    key: "deleteMerchant",
    label: "Delete",
    colWidth: 4,
  },
];

if (getAuthData() && getAuthData().userPayload.group) {
  columns.splice(2, 1);
}

const mapStateToProps = (state) => {
  return {
    data: state.merchants.merchantsList,
    count: state.merchants.count,
    table: state.store.table,
    searchData: state.search.merchantsSearch,
    isSearch: state.search.isSearch,
    loading: state.merchants.merchantsLoading,
    name: "USERMERCHANT",
    columns,
    columnsComponent: createColComponentForTable(columns),
    keyPath: "merchant_guid",
    modal: <MerchantCreator />,
    exportFunction: getMerchants,
  };
};

export default connect(mapStateToProps, {
  get: getAllMerchants,
  delete: deleteMerchantAction,
  search: searchInMerchants,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
