import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteBlackListItemAction,
  getAllBlackListItems,
} from "../../../actions/blacklists";
import { reset, searchInBlacklist } from "../../../actions/search";
import { resetPage, setNewTable } from "../../../actions/store";
import AbstractComponent from "../../../factories/Main";
import { getBlackListItems } from "../../../services/paymentBackendAPI/management/blacklists";
import BlackListCreator from "./Creator";
import BlackListEditor from "./Editor";
import { createColComponentForTable } from "../../../helpers/createColComponentForTable";

const columns = [
  {
    path: "name",
    label: "Name",
    key: "blacklistName",
    content: (item) => (
      <Link className="link" to={`/about/blacklist/${item.guid}`}>
        {item.name}
      </Link>
    ),
    textContent: (item) => item.name,
  },
  { path: "type", label: "Type", key: "typeBlacklistRules" },
  { path: "description", label: "Description" },
  {
    key: "edit",
    content: (item) => (
      <Modal
        header="Edit rule"
        action="Save"
        content={BlackListEditor}
        contentProps={{ blacklist_guid: item.guid, type: "blacklist" }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
      />
    ),
    label: "Edit",
    colWidth: 4,
  },
  ability.can("DELETE", "BLACKLISTVALUE") && {
    key: "delete",
    label: "Delete",
    colWidth: 4,
  },
];

const mapStateToProps = (state) => {
  return {
    data: state.blacklist.blackList,
    count: state.blacklist.count,
    table: state.store.table,
    searchData: state.search.blacklistSearch,
    isSearch: state.search.isSearch,
    loading: state.blacklist.itemsLoading,
    name: "BLACKLISTS",
    keyPath: "guid",
    columns,
    columnsComponent: createColComponentForTable(columns),
    modal: <BlackListCreator />,
    exportFunction: getBlackListItems,
  };
};

export default connect(mapStateToProps, {
  get: getAllBlackListItems,
  delete: deleteBlackListItemAction,
  search: searchInBlacklist,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
