import * as types from "../constants/actionTypes";
import {
  getRates,
  createRate,
  deleteRate,
  getRate,
  getOneTimeCharges,
  getPeriodicCharges,
  getConditionalCharges,
  createOneTimeCharge,
  createPeriodicCharge,
  createConditionalCharge,
  getRateTemplates,
  createRateTemplate,
  getRateTemplate,
  deleteRateTemplate,
  getRateTemplateFees,
  createRateTemplateFees,
  getRateRevisions,
  createRateRevision,
  getRateRevisionFees,
  EditRateRevisionFees
} from "../services/paymentBackendAPI/management/rates";
import { loadTable } from "./store";

export const getAllRates = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RATES
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getRates(args);
      return dispatch({
        type: types.GET_RATES_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    } catch (error) {
      return dispatch({
        type: types.GET_RATES_FAILED
      });
    }
  };
};

export const getRateAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RATE
    });
    try {
      const response = await getRate(id);
      return dispatch({
        type: types.GET_RATE_SUCCEED,
        rate: response.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_RATE_FAILED
      });
    }
  };
};


export const addRateAction = (data, page = 1, items = 100, search) => {
  return async (dispatch) => {
    const response = await createRate(data);
    dispatch(
      {
        type: types.ADD_RATE,
        rate_guid: response.data.guid
      });
    dispatch(getAllRates({ page, items, ...search }));
  };
};


export const getOneTimeChargesAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ONE_TIME_CHARGES
    });
    try {
      const response = await getOneTimeCharges(id, page, items);
      return dispatch({
        type: types.GET_ONE_TIME_CHARGES_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_ONE_TIME_CHARGES_FAILED
      });
    }
  };
};

export const getPeriodicChargesAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_PERIODIC_CHARGES
    });
    try {
      const response = await getPeriodicCharges(id, page, items);
      return dispatch({
        type: types.GET_PERIODIC_CHARGES_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_PERIODIC_CHARGES_FAILED
      });
    }
  };
};

export const getConditionalChargesAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CONDITIONAL_CHARGES
    });
    try {
      const response = await getConditionalCharges(id, page, items);
      return dispatch({
        type: types.GET_CONDITIONAL_CHARGES_SUCCEED,
        data: response.data
      });
    }
    catch (error) {
      return dispatch({
        type: types.GET_CONDITIONAL_CHARGES_FAILED
      });
    }
  };
};

export const addOneTimeChargeAction = (data) => {
  return async () => {
    await createOneTimeCharge(data);
  };
};

export const addPeriodicChargeAction = (data) => {
  return async () => {
    await createPeriodicCharge(data);
  };
};

export const addConditionalChargeAction = (data) => {
  return async () => {
    await createConditionalCharge(data);
  };
};


export const deleteRateAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteRate(data);
    return dispatch(getAllRates({ page, items, ...search }));
  };
};

export const getAllRateTemplatesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RATE_TEMPLATES
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getRateTemplates(args);
      return dispatch({
        type: types.GET_RATE_TEMPLATES_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page
      });
    } catch (error) {
      return dispatch({
        type: types.GET_RATE_TEMPLATES_FAILED
      });
    }
  };
};

export const getRateTemplateAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RATE_TEMPLATE
    });
    try {
      const response = await getRateTemplate(id);
      return dispatch({
        type: types.GET_RATE_TEMPLATE_SUCCEED,
        template: response.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_RATE_TEMPLATE_FAILED
      });
    }
  };
};


export const addRateTemplateAction = (data, page, items, search) => {
  return async (dispatch) => {
    const response = await createRateTemplate(data);
    dispatch(
      {
        type: types.ADD_RATE_TEMPLATE,
        rate_guid: response.data.guid
      });
    dispatch(getAllRateTemplatesAction({ page, items, ...search }));
  };
};

export const deleteRateTemplateAction = (data, page, items, search) => {
  return async (dispatch) => {
    await deleteRateTemplate(data);
    return dispatch(getAllRateTemplatesAction({ page, items, ...search }));
  };
};

export const getAllRateTemplateFeesAction = (id, args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RATE_TEMPLATE_FEES
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getRateTemplateFees(id, args);
      return dispatch({
        type: types.GET_RATE_TEMPLATE_FEES_SUCCEED,
        data: response.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_RATE_TEMPLATE_FEES_FAILED
      });
    }
  };
};

export const addRateTemplateFeesAction = (guid, data) => {
  return async (dispatch) => {
    const response = await createRateTemplateFees(guid, data);
    dispatch(
      {
        type: types.ADD_RATE_TEMPLATE_FEES,
        rate_guid: response.data.guid
      });
  };
};

export const getAllRateRevisionsAction = (id, args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RATE_REVISIONS
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getRateRevisions(id, args);
      return dispatch({
        type: types.GET_RATE_REVISIONS_SUCCEED,
        data: response.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_RATE_REVISIONS_FAILED
      });
    }
  };
};

export const addRateRevisionAction = (guid, data) => {
  return async (dispatch) => {
    const response = await createRateRevision(guid, data);
    dispatch(
      {
        type: types.ADD_RATE_TEMPLATE_FEES,
        rate_guid: response.data.guid
      });
  };
};

export const getAllRateRevisionFeesAction = (id, revisionId, args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_RATE_REVISION_FEES
    });
    try {
      // args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getRateRevisionFees(id, revisionId, args);
      return dispatch({
        type: types.GET_RATE_REVISION_FEES_SUCCEED,
        data: response.data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_RATE_REVISION_FEES_FAILED
      });
    }
  };
};

export const editRateRevisionFeesAction = (id, revisionId, data) => {
  return async () => {
    await EditRateRevisionFees(id, revisionId, data);
  };
};