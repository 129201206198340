import React from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const Input = ({ name, label, error, formValidation, ...rest }) => {
  return (
    <Col className="col">
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <Form.Group validationState={formValidation(error, name)}>
        <Form.Control {...rest} name={name} id={name} />
        {error && <span className="validate-error">{error}</span>}
      </Form.Group>
    </Col>
  );
};

export default Input;

Input.propTypes = {
  error: PropTypes.string,
  formValidation: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
};
