import * as types from "../constants/actionTypes";
import {
  getTransactionsCurrencies,
  getDailyTransaction,
  getOrders,
  getOrder,
  getShopTotals,
  getTotals,
  getTotalProcessed,
  getTransactionHistory,
  getTransactionTypes,
  getCurrenciesOfTransactions,
  getHolds,
  getTransactionAmount,
  getTransactionAndCommissionReport,
  getAdminDashboardComponentData,
  getMerchantLimitsData,
  getMerchantTotalsData,
  getErrorReportData,
  getHistogramReportData,
  getGeographicReportData,
  getTransactionsCurrenciesDaily,
  getTotalProcessedDaily,
} from "../services/paymentBackendAPI/reports/transactions";
import { createTransaction } from "../services/paymentBackendAPI/transactions/transactions";
import {
  getTransactions,
  getTransaction,
  getTransactionRates,
  updateTransaction,
  activeTransaction,
  getTransactionTemplates,
  deleteTransactionTemplate,
  getTransactionTemplate,
  getTransactionSteps,
  getTransactionProcessingSteps,
  getTransactionProcessingParams,
  getTransactionsForGateway,
  getTransactionTypesForGateway,
  getMismatchTransactions,
  getMismatchTransaction,
} from "../services/paymentBackendAPI/management/transactions";
import { getStep } from "../services/paymentBackendAPI/management/steps";
import { loadTable } from "./store";

export const getAllTransactions = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTIONS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getTransactions(args);
      dispatch({
        type: types.GET_TRANSACTIONS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTIONS_FAILED,
      });
    }
  };
};

export const getTransactionAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION,
    });
    try {
      const response = await getTransaction(args);
      dispatch({
        type: types.GET_TRANSACTION_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_FAILED,
      });
    }
  };
};

export const editTransactiontAction = (data) => {
  return async (dispatch) => {
    const response = await updateTransaction(data);
    dispatch({
      type: types.EDIT_TRANSACTION,
      transaction: response.data,
    });
  };
};

export const ActivatedTransactiontAction = (data) => {
  return async (dispatch) => {
    const response = await activeTransaction(data);
    dispatch({
      type: types.ACTIVATE_TRANSACTION,
      data: response.data,
    });
  };
};

export const getTransactionRatesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_RATES,
    });
    try {
      const response = await getTransactionRates(args);
      dispatch({
        type: types.GET_TRANSACTION_RATES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_RATES_FAILED,
      });
    }
  };
};

export const getMerchantTransactionsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANT_TRANSACTIONS,
    });
    try {
      const response = await getTransactionHistory(args);
      return dispatch({
        type: types.GET_MERCHANT_TRANSACTIONS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_MERCHANT_TRANSACTIONS_FAILED,
      });
      throw error;
    }
  };
};

export const addTransactionAction = (
  data,
  terminalGuid,
  terminalSecret,
  terminalHashkey
) => {
  return async (dispatch) => {
    const response = await createTransaction(
      data,
      terminalGuid,
      terminalSecret,
      terminalHashkey
    );
    dispatch({
      type: types.ADD_TRANSACTION,
      transaction: response.data,
    });
  };
};

export const addParamTransaction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_PARAM_TRANSACTION,
      data,
    });
  };
};

export const cleanParams = () => {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAN_PARAMS,
    });
  };
};

export const getTransactionTemplatesAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_TEMPLATES,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getTransactionTemplates(args);
      dispatch({
        type: types.GET_TRANSACTION_TEMPLATES_SUCCEED,
        data: response.data,
        pageSize: args && args.items,
        currentPage: args && args.page,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_TEMPLATES_FAILED,
      });
    }
  };
};

export const deleteTransactionTemplateAction = (data, page, items) => {
  return async (dispatch) => {
    await deleteTransactionTemplate(data);
    return dispatch(getTransactionTemplatesAction({ page, items }));
  };
};

export const getTransactionTemplateAction = (id) => {
  return async (dispatch) => {
    const response = await getTransactionTemplate(id);
    dispatch({
      type: types.GET_TRANSACTION_TEMPLATE,
      template: response.data,
    });
  };
};

export const getTransactionStepsAction = (id, gateway_guid) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_STEPS,
    });
    try {
      const response = await getTransactionSteps(id, gateway_guid);
      dispatch({
        type: types.GET_TRANSACTION_STEPS_SUCCEED,
        data: response,
        guid: gateway_guid,
      });
    } catch (error) {
      return dispatch({
        type: types.GET_TRANSACTION_STEPS_FAILED,
      });
    }
  };
};

export const getStepAction = (id) => {
  return async (dispatch) => {
    const response = await getStep(id);
    dispatch({
      type: types.GET_STEP,
      step: response.data,
    });
  };
};

export const getTransactionProcessingStepsAction = (id, page, items) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_PROCESSING_STEPS,
    });
    const response = await getTransactionProcessingSteps(id, page, items);
    dispatch({
      type: types.GET_TRANSACTION_PROCESSING_STEPS_SUCCEED,
      data: response.data,
    });
  };
};

export const getTransactionProcessingParamsAction = (id, page, items) => {
  return async (dispatch) => {
    const response = await getTransactionProcessingParams(id, page, items);
    dispatch({
      type: types.GET_TRANSACTION_PROCESSING_PARAMS,
      data: response.data,
    });
  };
};

export const getTransactionsForGatewayAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTIONS_FOR_GATEWAY,
    });
    try {
      const response = await getTransactionsForGateway(id);
      dispatch({
        type: types.GET_TRANSACTIONS_FOR_GATEWAY_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      return dispatch({
        type: types.GET_TRANSACTIONS_FOR_GATEWAY_FAILED,
      });
    }
  };
};

export const getTransactionTypesForGatewayAction = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTIONS_TYPES_FOR_GATEWAY,
    });
    try {
      const response = await getTransactionTypesForGateway(id);
      dispatch({
        type: types.GET_TRANSACTIONS_TYPES_FOR_GATEWAY_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      return dispatch({
        type: types.GET_TRANSACTIONS_TYPES_FOR_GATEWAY_FAILED,
      });
    }
  };
};

/***********************( ╯°□°)╯┻┻**************************/

export const getTransactionHistoryAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_HISTORY,
    });
    try {
      const response = await getTransactionHistory(params);
      return dispatch({
        type: types.GET_TRANSACTION_HISTORY_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_HISTORY_FAILED,
      });
      throw error;
    }
  };
};

export const getTransactionsCurrenciesAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_CURRENCIES,
    });
    try {
      const response = await getTransactionsCurrencies(data);
      return dispatch({
        type: types.GET_TRANSACTION_CURRENCIES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_CURRENCIES_FAILED,
      });
      throw error;
    }
  };
};

export const getTransactionsCurrenciesDailyAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_CURRENCIES,
    });
    try {
      const response = await getTransactionsCurrenciesDaily(data);
      return dispatch({
        type: types.GET_TRANSACTION_CURRENCIES_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_CURRENCIES_FAILED,
      });
      throw error;
    }
  };
};

export const getCurrenciesOfTransactionsAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_CURRENCIES_OF_TRANSACTIONS,
    });
    try {
      const response = await getCurrenciesOfTransactions(data);
      return dispatch({
        type: types.GET_CURRENCIES_OF_TRANSACTIONS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_CURRENCIES_OF_TRANSACTIONS_FAILED,
      });
      throw error;
    }
  };
};

export const getDailyTransactionAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_DAILY_TRANSACTION,
    });
    try {
      const response = await getDailyTransaction(data);
      return dispatch({
        type: types.GET_DAILY_TRANSACTION_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_DAILY_TRANSACTION_FAILED,
      });
      throw error;
    }
  };
};

export const getOrdersAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ORDERS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getOrders(args);
      return dispatch({
        type: types.GET_ORDERS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_ORDERS_FAILED,
      });
      throw error;
    }
  };
};

export const getOrderAction = (guid) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ORDER,
    });
    try {
      const response = await getOrder(guid);
      return dispatch({
        type: types.GET_ORDER_SUCCEED,
        order: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_ORDER_FAILED,
      });
      throw error;
    }
  };
};

export const getShopTotalsAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOP_TOTALS,
    });
    try {
      const response = await getShopTotals(data);
      return dispatch({
        type: types.GET_SHOP_TOTALS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_SHOP_TOTALS_FAILED,
      });
      throw error;
    }
  };
};

export const getTotalsAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TOTALS,
    });
    try {
      const response = await getTotals(data);
      return dispatch({
        type: types.GET_TOTALS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TOTALS_FAILED,
      });
      throw error;
    }
  };
};

export const getTotalProcessedAction = (data) => {
  return async (dispatch) => {
    if (data.shop_guid) {
      try {
        dispatch({
          type: types.GET_SHOP_TOTAL_PROCESSED,
        });

        const response = await getTotalProcessed(data);
        return dispatch({
          type: types.GET_SHOP_TOTAL_PROCESSED_SUCCEED,
          amount: response.data.amount,
          guid: data.shop_guid,
        });
      } catch (error) {
        dispatch({
          type: types.GET_SHOP_TOTAL_PROCESSED_FAILED,
        });
        throw error;
      }
    } else {
      try {
        dispatch({
          type: types.GET_TOTAL_PROCESSED,
        });

        const response = await getTotalProcessed(data);
        return dispatch({
          type: types.GET_TOTAL_PROCESSED_SUCCEED,
          amount: response.data.amount,
        });
      } catch (error) {
        dispatch({
          type: types.GET_TOTAL_PROCESSED_FAILED,
        });
        throw error;
      }
    }
  };
};

export const getTotalProcessedDailyAction = (data) => {
  return async (dispatch) => {
    if (data.shop_guid) {
      try {
        dispatch({
          type: types.GET_SHOP_TOTAL_PROCESSED,
        });

        const response = await getTotalProcessedDaily(data);
        return dispatch({
          type: types.GET_SHOP_TOTAL_PROCESSED_SUCCEED,
          amount: response.data.amount,
          guid: data.shop_guid,
        });
      } catch (error) {
        dispatch({
          type: types.GET_SHOP_TOTAL_PROCESSED_FAILED,
        });
        throw error;
      }
    } else {
      try {
        dispatch({
          type: types.GET_TOTAL_PROCESSED,
        });

        const response = await getTotalProcessedDaily(data);
        return dispatch({
          type: types.GET_TOTAL_PROCESSED_SUCCEED,
          amount: response.data.amount,
        });
      } catch (error) {
        dispatch({
          type: types.GET_TOTAL_PROCESSED_FAILED,
        });
        throw error;
      }
    }
  };
};

export const getTransactionTypesAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_TYPE,
    });
    try {
      const response = await getTransactionTypes(data);
      return dispatch({
        type: types.GET_TRANSACTION_TYPE_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_TYPE_FAILED,
      });
      throw error;
    }
  };
};

export const getTransactionAmountAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_AMOUNT,
    });
    try {
      const response = await getTransactionAmount(data);
      return dispatch({
        type: types.GET_TRANSACTION_AMOUNT_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_AMOUNT_FAILED,
      });
      throw error;
    }
  };
};

export const getTransactionTypesForShopAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SHOP_TRANSACTION_TYPE,
    });
    try {
      const response = await getTransactionTypes(data);
      return dispatch({
        type: types.GET_SHOP_TRANSACTION_TYPE_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_SHOP_TRANSACTION_TYPE_FAILED,
      });
      throw error;
    }
  };
};

export const getReportHoldsAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_REPORT_HOLDS,
    });
    try {
      const response = await getHolds(params);
      return dispatch({
        type: types.GET_REPORT_HOLDS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_REPORT_HOLDS_FAILED,
      });
      throw error;
    }
  };
};

export const getReportShopHoldsAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_REPORT_SHOP_HOLDS,
    });
    try {
      const response = await getHolds(params);
      return dispatch({
        type: types.GET_REPORT_SHOP_HOLDS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_REPORT_SHOP_HOLDS_FAILED,
      });
      throw error;
    }
  };
};

export const getMismatchTransactionsAction = (args) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MISMATCH_TRANSACTIONS,
    });
    try {
      args && args.page && dispatch(loadTable(args.page, args.items));
      const response = await getMismatchTransactions(args);
      return dispatch({
        type: types.GET_MISMATCH_TRANSACTIONS_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_MISMATCH_TRANSACTIONS_FAILED,
      });
      throw error;
    }
  };
};

export const getMismatchTransactionAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MISMATCH_TRANSACTION,
    });
    try {
      const response = await getMismatchTransaction(params);
      return dispatch({
        type: types.GET_MISMATCH_TRANSACTION_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_MISMATCH_TRANSACTION_FAILED,
      });
      throw error;
    }
  };
};

export const getTransactionAndCommissionReportAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_TRANSACTION_AND_COMMISSION_REPORT,
    });
    try {
      const response = await getTransactionAndCommissionReport(params);
      return dispatch({
        type: types.GET_TRANSACTION_AND_COMMISSION_REPORT_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_TRANSACTION_AND_COMMISSION_REPORT_FAILED,
      });
      throw error;
    }
  };
};

export const getAdminDashboardComponentDataAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ADMIN_DASHBOARD_DATA,
    });
    try {
      const response = await getAdminDashboardComponentData(params);
      return dispatch({
        type: types.GET_ADMIN_DASHBOARD_DATA_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_ADMIN_DASHBOARD_DATA_FAILED,
      });
      throw error;
    }
  };
};

export const getMerchantLimitsDataAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANTS_LIMIT_DATA,
    });
    try {
      const response = await getMerchantLimitsData(params);
      return dispatch({
        type: types.GET_MERCHANTS_LIMIT_DATA_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_MERCHANTS_LIMIT_DATA_FAILED,
      });
      throw error;
    }
  };
};

export const getMerchantTotalsDataAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MERCHANTS_TOTALS_DATA,
    });
    try {
      const response = await getMerchantTotalsData(params);
      return dispatch({
        type: types.GET_MERCHANTS_TOTALS_DATA_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_MERCHANTS_TOTALS_DATA_FAILED,
      });
      throw error;
    }
  };
};

export const getErrorReportAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_ERROR_REPORT,
    });
    try {
      const response = await getErrorReportData(params);
      return dispatch({
        type: types.GET_ERROR_REPORT_SUCCEED,
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: types.GET_ERROR_REPORT_FAILED,
      });
      throw error;
    }
  };
};

export const getHistogramReportAction = (params) => {
  return async (dispatch) => {
    const response = await getHistogramReportData(params);
    return dispatch({
      type: types.GET_HISTOGRAM_REPORT,
      data: response.data,
    });
  };
};

export const getGeographicReportAction = (params) => {
  return async (dispatch) => {
    const response = await getGeographicReportData(params);
    return dispatch({
      type: types.GET_GEOGRAPHIC_REPORT,
      data: response.data,
    });
  };
};
