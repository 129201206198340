import Modal from "components/UI/Modal";
import { parseResponse } from "helpers/parseResponse.js";
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAuditServiceTablesAction } from "../../../actions/audit";
import { reset, searchInServiceAuditTable } from "../../../actions/search";
import { resetPage, setNewTable } from "../../../actions/store";
import AbstractComponent from "../../../factories/Main";
import { getAuditServiceTables } from "../../../services/paymentBackendAPI/audits/audit";

let tryObjToStr = (obj) => {
  try {
    return JSON.stringify(obj, true, 4);
  } catch (error) {
    const parsedError = parseResponse(error);
    swal({
      title: parsedError.error,
      text: parsedError.message,
      icon: "error",
    });
  }
};

const Params = (data) => {
  return (
    <Card>
      <Card.Body>
        <div className="param-container">
          <span style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            {tryObjToStr(data)}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

const columns = [
  { path: "name", label: "Name", key: "name" },
  { path: "start_time", label: "Start time", key: "start_time_audit" },
  {
    path: "options",
    label: "Options",
    key: "options_audit",
    align: "center",
    content: (item) =>
      item.options ? (
        <Modal
          content={Params}
          contentProps={{ data: item.options }}
          button={
            <button type="button" className="btn">
              Show
            </button>
          }
        />
      ) : null,
  },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (transaction) =>
      moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.serviceTables,
    count: state.audit.serviceTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.serviceAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_service",
    keyPath: "guid",
    columns,
    exportFunction: getAuditServiceTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditServiceTablesAction,
  search: searchInServiceAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
