import { Export } from "components/Export";
import Select from "components/UI/MultiSelect";
import Spinner from "components/UI/Spinner";
import ability from "config/ability";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import React, { Component } from "react";
import swal from "sweetalert";
import Pagination from "../components/UI/Pagination";
import Table from "../components/UI/Table";
import { getAuthData } from "../services/paymentBackendAPI/backendPlatform";
import ButtonFilter from "./button";

export default class AbstractComponent extends Component {
  state = {
    isSearch: false,
    searchInput: false,
    sortKey: "",
    isSortReverse: false,
  };

  componentDidMount = async () => {
    let page = this.props.table.page;
    let pageSize = this.props.table.pageSize;
    if (this.props.name !== this.props.table.name) {
      this.props.setNewTable(this.props.name);
      page = 1;
      pageSize = 100;
      this.props.reset();
    }
    const sortData = this.state.sortKey
      ? {
          sort_col: this.state.sortKey,
          sort_dir: this.state.isSortReverse,
        }
      : undefined;
    if (this.props.name !== "accounts")
      await this.props.get({
        page: page,
        items: pageSize,
        ...this.props.searchData,
        ...sortData,
      });
    else
      await this.props.get({
        ...this.props.searchData,
        ...sortData,
      });
  };

  componentWillUnmount = () => {
    // this.props.reset();
  };

  updateData = (value) => {
    this.setState({ searchInput: value });
  };

  updateCurrentPage = () => {
    this.props.resetPage();
  };

  onSort = (sortKey) => {
    this.setState({
      sortKey,
      isSortReverse: !this.state.isSortReverse,
    });
    const sortData = {
      sort_col: sortKey,
      sort_dir: !this.state.isSortReverse,
    };
    this.props.get({
      page: this.props.table.page,
      items: this.props.table.pageSize,
      ...this.props.searchData,
      ...sortData,
    });
  };

  handleSortReset = () => {
    this.setState({
      sortKey: "",
      isSortReverse: false,
    });
    const sortData = {
      sort_col: undefined,
      sort_dir: undefined,
    };
    this.props.get({
      page: this.props.table.page,
      items: this.props.table.pageSize,
      ...this.props.searchData,
      ...sortData,
    });
  };

  handleSelectPageSize = (e) => {
    const sortData = this.state.sortKey
      ? {
          sort_col: this.state.sortKey,
          sort_dir: this.state.isSortReverse,
        }
      : undefined;
    if (e.name === "All") {
      this.props.get({
        page: 1,
        items: this.props.count,
        ...this.props.searchData,
        ...sortData,
      });
    } else {
      this.props.get({
        page: 1,
        items: e.name,
        ...this.props.searchData,
        ...sortData,
      });
    }
  };

  handlePageChange = async (page) => {
    const sortData = this.state.sortKey
      ? {
          sort_col: this.state.sortKey,
          sort_dir: this.state.isSortReverse,
        }
      : undefined;
    await this.props.get({
      page,
      items: this.props.table.pageSize,
      ...this.props.searchData,
      ...sortData,
    });
  };

  handleSearch = async () => {
    this.setState({
      isSearch: true,
    });
    this.props.resetPage();
    const sortData = this.state.sortKey
      ? {
          sort_col: this.state.sortKey,
          sort_dir: this.state.isSortReverse,
        }
      : undefined;
    await this.props.get({
      page: 1,
      items: this.props.table.pageSize,
      ...this.props.searchData,
      ...sortData,
    });
  };

  handleReset = async () => {
    this.setState({
      isSearch: false,
    });
    this.props.resetPage();
    await this.props.reset();
    const sortData = this.state.sortKey
      ? {
          sort_col: this.state.sortKey,
          sort_dir: this.state.isSortReverse,
        }
      : undefined;
    await this.props.get({
      page: 1,
      items: this.props.table.pageSize,
      ...sortData,
    });
  };

  handleDelete = async (id, reason) => {
    try {
      let currentPage = this.props.table.page;
      if (this.props.data.length === 1) {
        currentPage = currentPage === 1 ? currentPage : currentPage - 1;
      }
      await this.props.delete(
        {
          guid: id,
          reason,
        },
        currentPage,
        this.props.table.pageSize,
        this.props.searchData
      );
    } catch (error) {
      const parsedError = parseResponse(error);
      swal({
        title: parsedError.error,
        text: parsedError.message,
        icon: "error",
      });
    }
  };

  handleDeleteTwoGuids = async (id, guid, reason) => {
    try {
      let currentPage = this.props.table.page;
      if (this.props.data.length === 1) {
        currentPage = currentPage === 1 ? currentPage : currentPage - 1;
      }
      await this.props.delete(
        { id, guid, reason },
        currentPage,
        this.props.table.pageSize,
        this.props.searchData
      );
    } catch (error) {
      const parsedError = parseResponse(error);
      swal({
        title: parsedError.error,
        text: parsedError.message,
        icon: "error",
      });
    }
  };

  getPagedData = () => {
    const { pageSize } = this.props.table;
    let data = this.props.data ? this.props.data : [];
    if (this.props.name === "statements") {
      data = data.map((item) => {
        return {
          ...item,
          colored: item.merge_statement_flag === true ? true : false,
        };
      });
    }
    const count = this.props.count;
    const pagesCount = count / pageSize + (1 && !!(count % pageSize));
    return { pagesCount, data };
  };

  addDeleteField = (columns) => {
    let loginGuid = "";
    if (getAuthData()) loginGuid = getAuthData().userPayload.loginGuid;
    let newColumns = columns;
    if (newColumns[newColumns.length - 1].key === "delete")
      return [
        ...newColumns.slice(0, -1),
        ability.can("DELETE", this.props.name.toUpperCase()) && {
          key: "delete",
          content: (item) =>
            loginGuid !== item.guid ? (
              <i
                className="far fa-trash-alt icon red"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  swal({
                    title: "Are you sure?",
                    text:
                      "Once deleted, you will not be able to recover this. Type reason below .",
                    content: "input",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((value) => {
                    if (value) {
                      this.handleDelete(item.guid, value);
                      swal("Deleted", {
                        icon: "success",
                        button: false,
                        timer: 2000,
                      });
                    }
                  })
                }
              />
            ) : null,
          label: "Delete",
          align: "center",
        },
      ];
    else if (newColumns[newColumns.length - 1].key === "deleteRate") {
      return [
        ...newColumns.slice(0, -1),
        ability.can("EXECUTE", this.props.name.toUpperCase()) && {
          key: "deleteRate",
          content: (rate) =>
            rate.default ? null : (
              <i
                className="far fa-trash-alt icon red"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  swal({
                    title: "Are you sure?",
                    text:
                      "Once deleted, you will not be able to recover this. Type reason below .",
                    content: "input",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((value) => {
                    if (value) {
                      this.handleDelete(rate.guid, value);
                      swal("Deleted", {
                        icon: "success",
                        button: false,
                        timer: 2000,
                      });
                    }
                  })
                }
              />
            ),
          label: "Delete",
          align: "center",
        },
      ];
    } else if (
      newColumns[newColumns.length - 1].key === "deleteGlobalBlacklist"
    ) {
      return [
        ...newColumns.slice(0, -1),
        ability.can("EXECUTE", this.props.name.toUpperCase()) && {
          key: "delete",
          content: (item) => (
            <i
              className="far fa-trash-alt  icon red"
              style={{ cursor: "pointer" }}
              onClick={() =>
                swal({
                  title: "Are you sure?",
                  text:
                    "Once deleted, you will not be able to recover this. Type reason below .",
                  content: "input",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((value) => {
                  if (value) {
                    this.handleDelete(item.blacklist_rule_guid, value);
                    swal("Deleted", {
                      icon: "success",
                      button: false,
                      timer: 2000,
                    });
                  }
                })
              }
            />
          ),
          label: "Delete",
          align: "center",
        },
      ];
    } else if (
      newColumns[newColumns.length - 1].key === "deleteMerchantsBlacklist"
    ) {
      return [
        ...newColumns.slice(0, -1),
        ability.can("EXECUTE", this.props.name.toUpperCase()) && {
          key: "delete",
          content: (item) =>
            item.isEditable === undefined || item.isEditable === true ? (
              <i
                className="far fa-trash-alt icon red"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  swal({
                    title: "Are you sure?",
                    text:
                      "Once deleted, you will not be able to recover this. Type reason below .",
                    content: "input",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((value) => {
                    if (value) {
                      this.handleDelete(
                        item.guid,
                        value
                      );
                      swal("Deleted", {
                        icon: "success",
                        button: false,
                        timer: 2000,
                      });
                    }
                  })
                }
              />
            ) : null,
          label: "Delete",
          align: "center",
        },
      ];
    } else if (newColumns[newColumns.length - 1].key === "deleteMerchant") {
      return [
        ...newColumns.slice(0, -1),
        ability.can("EXECUTE", this.props.name.toUpperCase()) && {
          key: "delete",
          content: (item) => (
            <i
              className="far fa-trash-alt icon red"
              style={{ cursor: "pointer" }}
              onClick={() =>
                swal({
                  title: "Are you sure?",
                  text:
                    "Once deleted, you will not be able to recover this. Type reason below .",
                  content: "input",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((value) => {
                  if (value) {
                    this.handleDelete(item.merchant_guid, value);
                    swal("Deleted", {
                      icon: "success",
                      button: false,
                      timer: 2000,
                    });
                  }
                })
              }
            />
          ),
          label: "Delete",
          align: "center",
        },
      ];
    } else if (newColumns[newColumns.length - 1].key === "deleteGroup") {
      return [
        ...newColumns.slice(0, -1),
        ability.can("EXECUTE", this.props.name.toUpperCase()) && {
          key: "delete",
          content: (item) => (
            <i
              className="far fa-trash-alt  icon red"
              style={{ cursor: "pointer" }}
              onClick={() =>
                swal({
                  title: "Are you sure?",
                  text:
                    "Once deleted, you will not be able to recover this. Type reason below .",
                  content: "input",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((value) => {
                  if (value) {
                    this.handleDelete(item.group_guid, value);
                    swal("Deleted", {
                      icon: "success",
                      button: false,
                      timer: 2000,
                    });
                  }
                })
              }
            />
          ),
          label: "Delete",
          align: "center",
        },
      ];
    } else if (newColumns[newColumns.length - 1].key === "deletePartner") {
      return [
        ...newColumns.slice(0, -1),
        ability.can("EXECUTE", this.props.name.toUpperCase()) && {
          key: "delete",
          content: (item) => (
            <i
              className="far fa-trash-alt  icon red"
              style={{ cursor: "pointer" }}
              onClick={() =>
                swal({
                  title: "Are you sure?",
                  text:
                    "Once deleted, you will not be able to recover this. Type reason below .",
                  content: "input",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((value) => {
                  if (value) {
                    this.handleDelete(item.partner_guid, value);
                    swal("Deleted", {
                      icon: "success",
                      button: false,
                      timer: 2000,
                    });
                  }
                })
              }
            />
          ),
          label: "Delete",
          align: "center",
        },
      ];
    } else return newColumns;
  };

  getOptionsPageSize = () => {
    if (this.props.notAllSizePage)
      return [
        { guid: "1", name: "10" },
        { guid: "2", name: "20" },
        { guid: "3", name: "50" },
        { guid: "4", name: "100" },
      ];
    else
      return [
        { guid: "1", name: "10" },
        { guid: "2", name: "20" },
        { guid: "3", name: "50" },
        { guid: "4", name: "100" },
        { guid: "5", name: "All" },
      ];
  };

  render() {
    let columns = this.addDeleteField(this.props.columns);
    const { pagesCount, data } = this.getPagedData();
    let {
      modalComponent,
      searchData,
      exportFunction,
      columns: initColumns,
      name,
      search,
      keyPath,
      get,
      count,
    } = this.props;

    if (this.props.loading)
      return (
        <div style={{ height: "75vh" }}>

          <Spinner />
        </div>
      );
    else
      return (
        <React.Fragment>
          <div className="main-table">
            <div className="group-buttons">
              <div className="main-buttons">
                {modalComponent && modalComponent.props.allowed ? (
                  <div> {modalComponent} </div>
                ) : null}
                <ButtonFilter />
              </div>

              <div className="secondary-buttons">
                <Export
                  searchData={searchData}
                  exportFunction={exportFunction}
                  columns={initColumns}
                  name={name}
                />
              </div>
            </div>

            <div
              style={{ overflowX: "auto", width: "100%", minHeight: "300px" }}
            >
              <Table
                columns={columns}
                searchColumns={this.props.searchColumns}
                columnsComponent={this.props.columnsComponent}
                tableWidth={this.props.tableWidth}
                search={search}
                data={data}
                keyPath={keyPath}
                get={get}
                searchData={searchData}
                updateData={this.updateData}
                updateCurrentPage={this.updateCurrentPage}
                onSort={this.onSort}
                handleSortReset={this.handleSortReset}
                sortKey={this.state.sortKey}
                isSortReverse={this.state.isSortReverse}
                disableSearch={this.props.disableSearch}
              />
            </div>

            <div className="table-navigation">
              <Pagination
                pagesCount={pagesCount}
                currentPage={this.props.table.page}
                onPageChange={this.handlePageChange}
                pageSize={this.props.table.pageSize}
                count={count}
              />

              {this.props.name !== "accounts" && (
                <div className="page-size-container">
                  <label>Page size:</label>

                  <Select
                    multi={false}
                    name="Select the page size"
                    options={this.getOptionsPageSize()}
                    onSelect={this.handleSelectPageSize}
                    isSearchable={false}
                    placeholder={this.props.table.pageSize}
                  />
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
  }
}

AbstractComponent.propTypes = {
  columns: PropTypes.array,
  columnsComponent: PropTypes.array,
  count: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  data: PropTypes.array,
  delete: PropTypes.func,
  disableSearch: PropTypes.bool,
  exportFunction: PropTypes.func,
  get: PropTypes.func,
  keyPath: PropTypes.string,
  loading: PropTypes.bool,
  modalComponent: PropTypes.object,
  name: PropTypes.string,
  notAllSizePage: PropTypes.bool,
  reset: PropTypes.func,
  resetPage: PropTypes.func,
  search: PropTypes.func,
  searchData: PropTypes.object,
  setNewTable: PropTypes.func,
  table: PropTypes.object,
};
