import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import { getGatewayAction, editGatewayAction } from "../../actions/gateways";
import { showModal } from "../../actions/modal";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse.js";
import Spinner from "components/UI/Spinner/index";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import Alert from "../UI/Alert";

class GatewayEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      data: {},
      oldData: {},
      select: [],
      selectedValue: [],
      isLoading: false,
      errors: {},
      needReason: false,
      reason: "",

      nameValidation: false,
      descriptionValidation: false,
    };
  }

  schema = {
    name: Joi.string().required().label("Gateway name"),
    description: Joi.string().required().label("Description"),
  };

  async componentDidMount() {
    await this.props.getGatewayAction(this.props.guid);
    const { gateway } = this.props;
    this.setState({
      data: gateway,
      oldData: gateway,
      nameValidation: gateway.name ? true : false,
      descriptionValidation: gateway.description ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = {
        name: this.state.data.name,
        description: this.state.data.description,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        name: this.state.data.name,
        description: this.state.data.description,
      };
      schema = this.schema;

      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state.data[name] === "") return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState(
      {
        data,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });
          const data = {
            guid: this.props.guid,
            name: this.state.data.name,
            description: this.state.data.description,
            reason: this.state.needReason ? this.state.reason : undefined,
          };
          try {
            await this.props.editGatewayAction(data);
            swal({
              title: "Gateway is updated",
              icon: "success",
              button: false,
              timer: 2000,
            });
          } catch (error) {
            this.setState({ isLoading: false });
            const parsedError = parseResponse(error);
            Alert({ type: "error", message: parsedError.message });
          }
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (err) {
        this.setState({ isLoading: false });
        throw err;
      }
    }
  };

  render() {
    const { errors } = this.state;
    if (this.props.gatewayLoading) {
      return <Spinner />;
    } else
      return (
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Name:</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("name")}>
                <Form.Control
                  name="name"
                  type="text"
                  value={this.state.data.name}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.name && (
                  <span className="validate-error">{errors.name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Description:</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("description")}>
                <Form.Control
                  name="description"
                  type="text"
                  value={this.state.data.description}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.description && (
                  <span className="validate-error">{errors.description}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          {this.state.needReason && (
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Reason</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    placeholder="Enter reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChangeReason}
                  />
                  {errors.reason && (
                    <span className="validate-error">{errors.reason}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.nameValidation &&
                  this.state.descriptionValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    gateway: state.gateways.gateway,
    gatewayLoading: state.gateways.gatewayLoading,
  };
};

export default connect(mapStateToProps, {
  getGatewayAction,
  editGatewayAction,
  showModal,
})(GatewayEditor);

GatewayEditor.propTypes = {
  editGatewayAction: PropTypes.func,
  gateway: PropTypes.object,
  gatewayLoading: PropTypes.bool,
  getGatewayAction: PropTypes.func,
  guid: PropTypes.string,
  showModal: PropTypes.func,
};
