// import Can from "components/Can";
import GatewayCreator from "components/Gateway/Creator";
import GatewayMain from "components/Gateway/Main";
import Modal from "components/UI/Modal";
import React from "react";
import Content from "../Content";
import ability from "config/ability";

const Gateway = () => {
  return (
    <Content>
      <GatewayMain
        modalComponent={
          // <Can do="EXECUTE" on="GATEWAYS">
          <Modal
            allowed={ability.can("EXECUTE", "GATEWAYS")}
            header="Create gateway"
            content={GatewayCreator}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create gateway
              </button>
            }
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default Gateway;
