import React from "react";
import Content from "../../Content";
import TerminalAuditMain from "components/Audit/TerminalTable/Main";

const TerminalAudit = () => {
  return (
    <Content>
      <TerminalAuditMain />
    </Content>
  );
};

export default TerminalAudit;
