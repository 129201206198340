/* eslint-disable react/jsx-key */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) {
      return column.content(item);
    }
    return _.get(item, column.path);
  };

  createKey = (item, column = "", groupValue = "") => {
    let keyPath = this.props.keyPath;
    let guid = "";

    if (groupValue) {
      guid = item[keyPath] || item.guid;
      return guid + groupValue + (column.path || column.key);
    } else {
      if (Array.isArray(keyPath)) {
        if (column) {
          for (let index = 0; index < keyPath.length; index++) {
            guid += item[keyPath[index]];
          }
          return guid + (column.path || column.key);
        } else {
          for (let index = 0; index < keyPath.length; index++) {
            guid += item[keyPath[index]];
          }
          return guid;
        }
      } else {
        if (column) {
          guid = item[keyPath] || item.guid;
          return guid + (column.path || column.key);
        } else {
          guid = item[keyPath] || item.guid;
          return guid;
        }
      }
    }
  };

  render() {
    const { data, columns, foot, tfootData, collapse } = this.props;
    return (
      <>
        <tbody key={window.location}>
          {data.map((item) => {
            if (item.collapsed && item.collapsed.length && collapse) {
              return item.collapsed.map((collapsedItem, index) => {
                return (
                  <tr
                    key={this.createKey(item)}
                    style={
                      item.colored === true
                        ? { backgroundColor: "AliceBlue" }
                        : {}
                    }
                  >
                    {columns.map((column) => {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          item,
                          column.path
                        ) &&
                        index === 0
                      ) {
                        return (
                          <td
                            key={this.createKey(item, column)}
                            style={column.style ? column.style : null}
                            rowSpan={item.collapsed.length}
                          >
                            {this.renderCell(item, column)}
                          </td>
                        );
                      } else if (
                        Object.prototype.hasOwnProperty.call(
                          collapsedItem,
                          column.path
                        )
                      ) {
                        return (
                          <td
                            key={this.createKey(collapsedItem, column)}
                            style={column.style ? column.style : null}
                          >
                            {this.renderCell(collapsedItem, column)}
                          </td>
                        );
                      }
                      return null;
                    })}
                  </tr>
                );
              });
            } else {
              return (
                <tr
                  key={this.createKey(item)}
                  style={
                    item.colored === true
                      ? { backgroundColor: "AliceBlue" }
                      : {}
                  }
                >
                  {columns.map((column) => (
                    <td
                      key={this.createKey(item, column)}
                      style={
                        column.align
                          ? { textAlign: column.align, ...column.style }
                          : column.style
                      }
                    >
                      {this.renderCell(item, column)}
                    </td>
                  ))}
                </tr>
              );
            }
          })}
        </tbody>

        {foot ? (
          <tfoot
            key={window.location + "tfoot"}
            style={{ textAlign: "center" }}
          >
            {tfootData.map((item) => (
              <tr
                key={
                  item.request_id ||
                  item.guid ||
                  item.merchant_guid ||
                  item.group_guid ||
                  item.partner_guid ||
                  item.createdAt ||
                  item.blacklist_rule_guid
                }
              >
                {columns.map((column) => (
                  <td
                    key={this.createKey(item, column)}
                    style={{
                      fontWeight: "bold",
                      borderTop: "2px solid #9A9A9A",
                      backgroundColor: "rgb(224, 224, 224)",
                    }}
                  >
                    {this.renderCell(item, column)}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSearch: state.search.isSearch,
  };
};

export default connect(mapStateToProps, null)(TableBody);

TableBody.propTypes = {
  collapse: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  foot: PropTypes.bool,
  keyPath: PropTypes.string,
  tfootData: PropTypes.array,
};
