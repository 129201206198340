import {
  addPartnerLoginAction,
  deleteGroupFromPartnerAction,
  getPartnerAction,
  getPartnerGroupsAction,
  getPartnerLoginsAction,
} from "actions/partners";
import { getAllRoles } from "actions/roles";
import Modal from "components/UI/Modal";
import Pagination from "components/UI/Pagination/index";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table/index";
import ability from "config/ability";
import { parseResponse } from "helpers/parseResponse";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Content from "views/Content";
import { getPartnerChangeHistoryAction } from "../../actions/reasons";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import AddLogin from "../AddLogin";
import { TableAudit } from "../Audit/UsersTables/common/Table";
import EditLogin from "../EditLogin";
import RowAddMerchant from "../RowAddUser";
import Alert from "../UI/Alert";

class PartnerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLoginsPage: 1,
      currentGroupsPage: 1,
      pageSize: 10,
      currentPageHistory: 1,
      pageSizeHistory: 10,
    };
  }

  columnsPartnerGroups = [
    { path: "guid", label: "Guid" },
    { path: "name", label: "Group" },
    ability.can("DELETE", "GROUPFROMPARTNER") && {
      key: "delete",
      align: "center",
      content: (group) => (
        <i
          className="far fa-trash-alt red icon"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDeleteGroup(group.guid, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete from the partner",
      colWidth: 15,
    },
  ];

  rows = [
    {
      label: "Partner name",
      path: "name",
    },
    {
      label: "Partner type",
      path: "type",
    },
  ];

  columnsLoginsForPartner = [
    { path: "username", label: "Username" },
    { path: "email", label: "Email" },
    { path: "role.name", label: "Role" },
    { path: "first_name", label: "First name" },
    { path: "last_name", label: "Last name" },
    { path: "company_name", label: "Company name" },
    { path: "company_address", label: "Company address" },
    {
      key: "enabled",
      content: (login) => (
        <i
          className={
            login.enabled ? "icon-success green icon" : "icon-failed red icon"
          }
        />
      ),
      label: "Status",
      align: "center",
      colWidth: 4,
    },
    ability.can("REPORT", "REASONSLOGIN") && {
      label: "History",
      key: "history",
      align: "center",
      content: (login) => (
        <Link className="link" to={`/about/audit/login/${login.guid}`}>
          <button type="button" className="btn btn-table">
            Show
          </button>
        </Link>
      ),
      colWidth: 5,
    },
    ability.can("EXECUTE", "PARTNERLOGIN") && {
      key: "edit",
      align: "center",
      content: (partnerLogin) => (
        <Modal
          dialogClassName="modal-creator"
          header="Edit partner login"
          button={
            <i className="icon-edit gray icon" style={{ cursor: "pointer" }} />
          }
          content={EditLogin}
          contentProps={{
            id: this.props.match.params.id,
            data: partnerLogin,
            type: "partner",
            update: this.update,
          }}
        />
      ),
      label: "Edit",
      colWidth: 4,
    },

    ability.can("DELETE", "PARTNERLOGIN") && {
      key: "delete",
      align: "center",
      content: (partnerLogin) => (
        <i
          className="far fa-trash-alt red icon"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDelete(
                  partnerLogin.guid,
                  partnerLogin.role_guid,
                  value
                );
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
      colWidth: 4,
    },
  ];

  componentDidMount = async () => {
    await this.props.getPartnerAction(this.props.match.params.id);
    await this.props.getPartnerGroupsAction(
      this.props.match.params.id,
      1,
      this.state.pageSize
    );
    await this.props.getPartnerLoginsAction(
      this.props.match.params.id,
      1,
      this.state.pageSize
    );
    ability.can("AUDIT", "PARTNERS") &&
      (await this.props.getPartnerChangeHistoryAction(
        this.props.match.params.id,
        1,
        this.state.pageSizeHistory
      ));
    await this.props.getAllRoles({ type: "partner" });
    const isAdmin = localStorage.getItem("isAdmin");
    this.setState({ isAdmin });
  };

  handleDeleteGroup = async (guid, reason) => {
    try {
      await this.props.deleteGroupFromPartnerAction(
        { guid, reason },
        this.state.currentGroupsPage,
        this.state.pageSize,
        this.props.match.params.id
      );
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleDelete = async (loginid, roleid, reason) => {
    try {
      await this.props.addPartnerLoginAction(this.props.match.params.id, {
        login_guid: loginid,
        role_guid: roleid,
        delete: true,
        reason,
      });
      await this.props.getPartnerLoginsAction(this.props.match.params.id);
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  handleLoginsPageChange = async (page) => {
    await this.props.getGroupLoginsAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      currentLoginsPage: page,
    });
  };

  handleGroupsPageChange = async (page) => {
    await this.props.getPartnerGroupsAction(
      this.props.match.params.id,
      page,
      this.state.pageSize
    );
    this.setState({
      currentGroupsPage: page,
    });
  };

  handleHistoryPageChange = async (page) => {
    await this.props.getPartnerChangeHistoryAction(
      this.props.match.params.id,
      page,
      this.state.pageSizeHistory
    );
    this.setState({
      currentPageHistory: page,
    });
  };

  update = async (id) => {
    await this.props.getPartnerAction(id);
    await this.props.getPartnerGroupsAction(id);
    await this.props.getPartnerLoginsAction(id);
  };

  getLoginsPagedData = () => {
    const { loginsCount } = this.props;
    let { pageSize } = this.state;
    const loginsPagesCount = loginsCount
      ? loginsCount / pageSize + (1 && !!(loginsCount % pageSize))
      : 0;
    return { loginsPagesCount };
  };

  getGroupsPagedData = () => {
    const { groupsCount } = this.props;
    let { pageSize } = this.state;
    const groupsPagesCount = groupsCount
      ? groupsCount / pageSize + (1 && !!(groupsCount % pageSize))
      : 0;
    return { groupsPagesCount };
  };

  historygetPagedData = () => {
    const { partnerHistoryCount } = this.props;
    let { pageSizeHistory } = this.state;
    const historyPagesCount =
      partnerHistoryCount / pageSizeHistory +
      (1 && !!(partnerHistoryCount % pageSizeHistory));
    return { historyPagesCount };
  };

  render() {
    const { partner } = this.props;
    const { currentLoginsPage, currentGroupsPage, currentPageHistory } =
      this.state;
    const { loginsPagesCount } = this.getLoginsPagedData();
    const { groupsPagesCount } = this.getGroupsPagedData();
    const { partnerLogins, partnerGroups, partnerHistory } = this.props;
    if (
      this.props.partnerLoginsLoading ||
      this.props.partnerLoading ||
      this.props.partnerGroupsLoading
    )
      return (
        <Content style={{ height: "75vh" }}>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Header as="h5">
                {partner ? partner.partner_name : null}
              </Card.Header>
              <Card.Body>
                <Card.Title>Info</Card.Title>

                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Partner GUID:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{this.props.match.params.id}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Partner Type:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partner ? partner.partner_type : null}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {partner
                        ? partner.created_at !== null
                          ? moment(partner.created_at)
                            .utcOffset(0)
                            .format("D MMM YYYY")
                          : null
                        : null}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Created by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partner ? partner.created_by_username : null}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated at:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>
                      {partner
                        ? partner.updated_at !== null
                          ? moment(partner.updated_at)
                            .utcOffset(0)
                            .format("D MMM YYYY")
                          : null
                        : null}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} xs={6} className="right">
                    <label>Updated by:</label>
                  </Col>
                  <Col md={6} sm={6} xs={6} className="left">
                    <span>{partner ? partner.updated_by_username : null}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Body>
                <Card.Title>Groups</Card.Title>
                <Table
                  columns={this.columnsPartnerGroups}
                  data={partnerGroups ? partnerGroups : null}
                  keyPath="guid"
                  disableSearch
                  disableSort
                  columnsComponent={createColComponentForTable(
                    this.columnsPartnerGroups
                  )}
                />
                <Pagination
                  pagesCount={groupsPagesCount}
                  currentPage={currentGroupsPage}
                  onPageChange={this.handleGroupsPageChange}
                  pageSize={this.state.pageSize}
                  count={this.props.groupsCount}
                />
                {ability.can("EXECUTE", "USERGROUP") &&
                  !this.props.partnerGroupsLoading && (
                  <RowAddMerchant
                    id={partner.partner_guid}
                    type="group"
                    update={this.update}
                  />
                )}
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            <Card>
              <Card.Body>
                <Card.Title>Logins</Card.Title>
                <Table
                  columns={this.columnsLoginsForPartner}
                  data={partnerLogins ? partnerLogins : []}
                  keyPath="guid"
                  disableSearch
                  disableSort
                  columnsComponent={createColComponentForTable(
                    this.columnsLoginsForPartner
                  )}
                />
                <Pagination
                  pagesCount={loginsPagesCount}
                  currentPage={currentLoginsPage}
                  onPageChange={this.handleLoginsPageChange}
                  pageSize={this.state.pageSize}
                  count={this.props.loginsCount}
                />
                {/* <Can do="EXECUTE" on="PARTNERLOGIN">
                  <Col md={3} sm={3} xs={3} />
                  <Col md={6} sm={6} xs={6}>
                    <RowAddLogin
                      type="partner"
                      id={this.props.match.params.id}
                      update={this.update}
                    />
                  </Col>
                  <Col md={3} sm={3} xs={3} />
                </Can> */}
                {ability.can("EXECUTE", "PARTNERLOGIN") &&
                  ability.can("READ", "ROLES") && (
                  <Row>
                    <Col md={5} sm={5} xs={5} />
                    <Col md={2} sm={2} xs={2}>
                      <Modal
                        header="Add login"
                        dialogClassName="modal-creator"
                        content={AddLogin}
                        contentProps={{
                          type: "partner",
                          id: this.props.match.params.id,
                          update: this.update,
                        }}
                        button={
                          <button type="button" className="btn btn-fill">
                              Add login
                          </button>
                        }
                      />
                    </Col>
                    <Col md={5} sm={5} xs={5} />
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ marginLeft: "0px" }}>
            {ability.can("AUDIT", "PARTNERS") && (
              <Card>
                <Card.Body>
                  <Card.Title>History</Card.Title>
                  {this.props.partnerHistoryloading ? (
                    <Content>
                      <Spinner />
                    </Content>
                  ) : (
                    <Row>
                      <Col md={12} sm={12} xs={12}>
                        {/* <Table
                          columns={this.columnsHistory}
                          data={partnerHistory ? partnerHistory : []}
                          disableSearch
                          disableSort
                        />
                        <Pagination
                          pagesCount={historyPagesCount}
                          currentPage={currentPageHistory}
                          onPageChange={this.handleHistoryPageChange}
                          pageSize={this.state.pageSizeHistory}
                          count={this.props.partnerHistoryCount}
                        /> */}

                        <TableAudit
                          data={partnerHistory ? partnerHistory : []}
                          count={this.props.partnerHistoryCount}
                          handleChangePage={this.handleHistoryPageChange}
                          page={currentPageHistory}
                          pageSize={this.state.pageSizeHistory}
                          rows={this.rows}
                        />
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            )}
          </Row>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    partner: state.partners.partner,
    partnerGroups: state.partners.partnerGroups,
    groupsCount: state.partners.groupsCount,
    partnerLogins: state.partners.partnerLogins,
    loginsCount: state.partners.loginsCount,
    partnerLoading: state.partners.partnerLoading,
    partnerLoginsLoading: state.partners.partnerLoginsLoading,
    partnerGroupsLoading: state.partners.partnerGroupsLoading,
    rolesLoading: state.roles.rolesLoading,
    partnerHistory: state.reasons.partnerHistory,
    partnerHistoryCount: state.reasons.count,
    partnerHistoryloading: state.reasons.loading,
  };
};

export default connect(mapStateToProps, {
  getPartnerAction,
  getPartnerLoginsAction,
  addPartnerLoginAction,
  deleteGroupFromPartnerAction,
  getPartnerGroupsAction,
  getAllRoles,
  getPartnerChangeHistoryAction,
})(PartnerDetail);

PartnerDetail.propTypes = {
  addPartnerLoginAction: PropTypes.func,
  deleteGroupFromPartnerAction: PropTypes.func,
  getAllRoles: PropTypes.func,
  getGroupLoginsAction: PropTypes.func,
  getPartnerAction: PropTypes.func,
  getPartnerChangeHistoryAction: PropTypes.func,
  getPartnerGroupsAction: PropTypes.func,
  getPartnerLoginsAction: PropTypes.func,
  groupsCount: PropTypes.number,
  loginsCount: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  partner: PropTypes.object,
  partnerGroups: PropTypes.array,
  partnerGroupsLoading: PropTypes.bool,
  partnerHistory: PropTypes.array,
  partnerHistoryCount: PropTypes.number,
  partnerHistoryloading: PropTypes.bool,
  partnerLoading: PropTypes.bool,
  partnerLogins: PropTypes.array,
  partnerLoginsLoading: PropTypes.bool,
};
