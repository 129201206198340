import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Table from "components/UI/Table/index";
import { defaultRatesRows, columnsRates as columnsRatesDefault } from "./columnsAndRows";
import { colors } from "./colors";

function TableRates({ additional_fees_names, currencies, statement_currency_code, entityData }) {
  let [ columnsRates, setColumnsRates ] = useState([ ...columnsRatesDefault ]);
  let [ collapsedHeader, setCollapsedHeader ] = useState([ { name: "", collapse: [ "" ] } ]);
  let [ ratesRows, setRatesRows ] = useState([ ...defaultRatesRows ]);

  useEffect(() => {
    setColumnsRates([ ...columnsRatesDefault ]);
    setCollapsedHeader([ { name: "", collapse: [ "" ] } ]);
    setRatesRows([ ...defaultRatesRows ]);
    let arr = [ ...ratesRows ];

    if (additional_fees_names && additional_fees_names.length) {
      let spliceArr = arr.splice(16, 4);
      additional_fees_names.forEach(name => {
        arr.push({ name, label: name });
      });
      arr.push(...spliceArr);
    }

    let columns = [ ...columnsRates ];
    let header = [ ...collapsedHeader ];
    let rows = [ ...arr ];
    for (let index = 0; index < currencies.length; index++) {
      try {
        let value = currencies[index];
        columns.push(
          {
            label: "Merchant",
            path: value.name,
            style: {
              borderLeftStyle: "solid",
              borderLeftWidth: "2px",
              borderLeftColor: "Gray",
            },
          },
          { label: "Processor", path: value.name + "_processor" },
          { label: "Acquirer", path: value.name + "_bank" }
        );
        header.push({
          name:
            value.name === "Summary"
              ? "Summary (" +
              statement_currency_code +
              ")"
              : value.name,
          collapse: [ "Merchant", "Processor", "Acquirer" ],
          style: { backgroundColor: colors[index] },
        });

        for (let i = 0; i < rows.length; i++) {
          let element = rows[i];
          if (defaultRatesRows.map(row => row.name).includes(rows[i].name)) {
            let currencyData = entityData[value.name];
            let currencyDataProcessor = entityData[value.name + "_processor"];
            let currencyDataAcquiring = entityData[value.name + "_bank"];
            if (
              value.name === "Summary" ||
              value.name === statement_currency_code
            ) {
              element = {
                ...element,
                [value.name]: currencyData[element.name] ? currencyData[element.name] : "-",
                [value.name + "_processor"]: currencyDataProcessor[element.name] ? currencyDataProcessor[element.name] : "-",
                [value.name + "_bank"]: currencyDataAcquiring[element.name] ? currencyDataAcquiring[element.name] : "-",
              };
            } else {
              element = {
                ...element,
                [value.name]: currencyData[element.name] || currencyData[element.name + "_eur"]
                  ? currencyData[element.name] +
                  " (" +
                  currencyData[element.name + "_eur"] +
                  ")"
                  : "-",
                [value.name + "_processor"]: currencyDataProcessor[element.name] || currencyDataProcessor[element.name + "_eur"]
                  ? currencyDataProcessor[element.name] +
                  " (" +
                  currencyDataProcessor[element.name + "_eur"] +
                  ")"
                  : "-",
                [value.name + "_bank"]: currencyDataAcquiring[element.name] || currencyDataAcquiring[element.name + "_eur"]
                  ? currencyDataAcquiring[element.name] +
                  " (" +
                  currencyDataAcquiring[element.name + "_eur"] +
                  ")"
                  : "-",
              };
            }
            if (
              element.name === "summary_amount" ||
              element.name === "for_payout" ||
              element.name === "summary_fees"
            )
              element = { ...element, colored: true };
            rows[i] = element;
          }
          else {
            let feesData = entityData[value.name].additional_fees || {};
            let feesDataProcessor = entityData[value.name + "_processor"].additional_fees || {};
            let feesDataAcquiring = entityData[value.name + "_bank"].additional_fees || {};
            if (
              value.name === "Summary" ||
              value.name === statement_currency_code
            ) {
              element = {
                ...element,
                [value.name]: feesData[element.name] ? feesData[element.name] : "-",
                [value.name + "_processor"]: feesDataProcessor[element.name] ? feesDataProcessor[element.name] : "-",
                [value.name + "_bank"]: feesDataAcquiring[element.name] ? feesDataAcquiring[element.name] : "-",
              };
            } else {
              element = {
                ...element,
                [value.name]: feesData[element.name] || feesData[element.name + "_eur"]
                  ? feesData[element.name] +
                  " (" +
                  feesData[element.name + "_eur"] +
                  ")" : "-",
                [value.name + "_processor"]: feesDataProcessor[element.name] || feesDataProcessor[element.name + "_eur"]
                  ? feesDataProcessor[element.name] +
                  " (" +
                  feesDataProcessor[element.name + "_eur"] +
                  ")" : "-",
                [value.name + "_bank"]: feesDataAcquiring[element.name] || feesDataAcquiring[element.name + "_eur"]
                  ? feesDataAcquiring[element.name] +
                  " (" +
                  feesDataAcquiring[element.name + "_eur"] +
                  ")" : "-",
              };
            }
            rows[i] = element;
          }

        }
      } catch (error) {
        console.log(error);
      }
    }
    setColumnsRates([ ...columns ]);
    setCollapsedHeader([ ...header ]);
    setRatesRows([ ...rows ]);

  }, [ additional_fees_names, currencies, statement_currency_code, entityData ]);

  return (
    <>
      <Card.Title style={{ marginTop: "15px" }}>Rates</Card.Title>
      <Table
        columns={columnsRates}
        data={ratesRows}
        disableSearch
        disableSort
        keyPath="name"
        collapsedHeader={collapsedHeader}
      />
    </>
  );
}

export default TableRates;