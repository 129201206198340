import React, { Component } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { addGatewayPropsAction, getGatewayPropsAction } from "actions/gateways";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import swal from "sweetalert";
import Alert from "../../UI/Alert";

class PropertyCreator extends Component {
  state = {
    data: {
      name: "",
      label: "",
      bytea_flag: false,
      reason: "",
    },
    errors: {},
  };

  schema = {
    name: Joi.string().required().min(0).label("Name"),
    reason: Joi.string().required().label("Reason"),
    label: Joi.string().required().min(0).label("Label"),
  };

  handleCheckbox = () => {
    this.setState({
      data: {
        ...this.state.data,
        bytea_flag: !this.state.data.bytea_flag,
      },
    });
  };

  formValidation = (name) => {
    if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
    });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    const { data } = this.state;

    try {
      await this.props.addGatewayPropsAction(this.props.guid, data);
      swal({
        title: "Property is created",
        icon: "success",
        button: false,
        timer: 2000,
      });
      await this.props.handleClose();
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  renderInput = (label, value, name, type = "text") => {
    return (
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>{label}*</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group validationState={this.formValidation(name)}>
            <Form.Control
              // placeholder={label.toLowerCase()}
              type={type}
              name={name}
              value={value}
              onChange={this.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <Form autoComplete="off">
        {this.renderInput("Name", this.state.data.name, "name")}
        {/* {this.renderInput("name", "Name")} */}
        <Col>{this.renderInput("Label", this.state.data.label, "label")}</Col>
        {/* {this.renderInput("label", "Label")} */}
        <Col>
          {this.renderInput("Reason", this.state.data.reason, "reason")}
        </Col>
        {/* {this.renderInput("reason", "Reason")} */}

        <Row>
          <Col md={1} sm={1} xs={1}>
            <input
              type="checkbox"
              id="checkbox"
              checked={this.state.data.bytea_flag}
              onChange={this.handleCheckbox}
            />
          </Col>
          <Col md={4} sm={4} xs={4}>
            <label>Encrypt property*</label>
          </Col>
        </Row>

        <div>
          <Button
            className={
              this.state.name === "" ||
              this.state.label === "" ||
              this.state.reason === ""
                ? "btn btn-fill"
                : "btn btn-fill btn-success"
            }
            type="submit"
            onClick={this.doSubmit}
          >
            Add property
          </Button>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageSize: state.gateways.pageSize,
    currentPage: state.gateways.currentPage,
  };
};

export default connect(mapStateToProps, {
  addGatewayPropsAction,
  getGatewayPropsAction,
  showModal,
})(PropertyCreator);

PropertyCreator.propTypes = {
  addGatewayPropsAction: PropTypes.func,
  getGatewayPropsAction: PropTypes.func,
  showModal: PropTypes.func,
};
