import React, { Component } from "react";
import { connect } from "react-redux";
import Joi from "joi-browser";
import { Button, Form } from "react-bootstrap";
import { addAdditionalFeeAction } from "actions/additionalFee";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { parseResponse } from "helpers/parseResponse";
import ReactLoading from "react-loading";
import { Col, Row } from "react-bootstrap";
import Alert from "../UI/Alert";

class CurrencyCreator extends Component {
  state = {
    data: {
      name: "",
      enabled: true,
    },
    errors: {},
    isLoading: false,
  };

  schema = {
    name: Joi.string().required().min(1).max(100).trim().label("Name"),
    enabled: Joi.boolean().label("Active"),
  };

  validate = () => {
    const options = { abortEarly: false };
    let data = {
      name: this.state.data.name,
      enabled: this.state.data.enabled,
    };
    let schema = this.schema;

    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
      errors,
    });
  };

  handleChangeActive = () => {
    const data = { ...this.state.data };
    data.enabled = !this.state.data.enabled;
    this.setState({ data });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      try {
        const { name, enabled } = this.state.data;
        await this.props.addAdditionalFeeAction(
          { fee_name: name, enabled },
          this.props.currentPage,
          this.props.pageSize
        );
        swal({
          title: "Currency is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  renderInput = (label, value, name, type = "text", handleChange) => {
    return (
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>{label}*</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group>
            <Form.Control
              // placeholder={label.toLowerCase()}
              type={type}
              name={name}
              value={value}
              onChange={handleChange ? handleChange : this.handleChange}
            />
            {this.state.errors[name] && (
              <span className="validate-error">{this.state.errors[name]}</span>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <Form>
        <Row>
          <Col>{this.renderInput("Name", this.state.data.name, "name")}</Col>
        </Row>
        <Row>
          <Col md={3} sm={4} xs={4} className="form-label">
            <label>Active*</label>
          </Col>
          <Col md={1} sm={1} xs={1}>
            <input
              type="checkbox"
              id="enabled"
              checked={this.state.data.enabled}
              onChange={this.handleChangeActive}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button
                  className="btn btn-fill btn-success"
                  type="submit"
                  onClick={this.doSubmit}
                >
                  Add
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default connect(null, {
  addAdditionalFeeAction,
  showModal,
})(CurrencyCreator);

CurrencyCreator.propTypes = {
  addAdditionalFeeAction: PropTypes.func,
  showModal: PropTypes.func,
};
