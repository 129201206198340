import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import { connect } from "react-redux";

import { parseResponse } from "helpers/parseResponse";
import { setNewPasswordAction, checkRecoveryTokenAction } from "actions/auth";
import Spinner from "components/UI/Spinner";
import Content from "views/Content";
import Loading from "components/UI/Spinner";
import PropTypes from "prop-types";

class RecoveryPassword extends Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    token: "",

    statusToken: "",
    showNewPassword: false,
    showConfirmPassword: false,

    isTokenTrue: false,
    isTokenBroken: false,
    isPasswordConfirmed: false,

    errorPassword: "",
    loading: false,
  };

  async componentDidMount() {
    const token = this.props.match.params.token;
    this.setState({ token });

    await this.props.checkRecoveryTokenAction({ token });
    this.setState({ statusToken: this.props.statusToken });

    if (this.state.statusToken === "true") {
      this.setState({ isTokenTrue: true });
    } else if (this.state.statusToken === "Token does not exist") {
      this.setState({ isTokenBroken: true });
    }
  }

  isPasswordConfirmed = () => {
    if (this.state.newPassword === this.state.confirmPassword) {
      this.setState({
        isPasswordConfirmed: true,
      });
      return true;
    } else {
      this.setState({
        isPasswordConfirmed: false,
      });
      return false;
    }
  };

  handleChange = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    this.setState({
      [property]: value,
    });
  };

  handleBack = () => {
    this.props.history.push("/");

    window.location.reload();
  };

  handleShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.isPasswordConfirmed()) {
      this.setState({ errorPassword: "Passwords don't match. Try again" });
    } else {
      this.setState({ errorPassword: "", loading: true });
      try {
        const { newPassword, token } = this.state;
        await this.props.setNewPasswordAction({ password: newPassword, token });
        swal({
          title: `${this.props.statusPassword}`,
          icon: "success",
        });
        this.setState({ loading: false });
      } catch (error) {
        const parsedError = parseResponse(error);
        this.setState({
          errorPassword: parsedError.message,
          loading: false,
        });
      }
    }
  };

  render() {
    const {
      newPassword,
      confirmPassword,
      statusToken,
      isTokenTrue,
      isTokenBroken,
      errorPassword,
    } = this.state;
    const { statusPassword } = this.props;

    if (statusToken === "")
      return (
        <Content>
          <Spinner />
        </Content>
      );
    else
      return (
        <div className="login-page">
          <div className="login-card">
            <div className="expired">
              <p className="header-login">Recovery password</p>

              {statusPassword === "Password changed" ? (
                <>
                  <p style={{ color: "grey", textAlign: "center" }}>
                    New password set successfully
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <Button className="button-submit" onClick={this.handleBack}>
                      Return to sign in
                    </Button>
                  </div>
                </>
              ) : isTokenTrue ? (
                <>
                  <p className="tooltip-text">
                    Password must be at least 8 characters, including a number,
                    a special, and an uppercase letter.
                  </p>
                  <div className="form-container">
                    <div className="form">
                      <div className="input-container">
                        <Form.Group controlId="newEmail">
                          <Form.Control
                            placeholder="New password"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => this.handleChange(e)}
                            type={
                              this.state.showNewPassword ? "text" : "password"
                            }
                          />
                        </Form.Group>
                        <span
                          className={
                            this.state.showNewPassword
                              ? "icon-eye-stroke login-page-eye"
                              : "icon-eye login-page-eye"
                          }
                          onClick={this.handleShowNewPassword}
                        />
                      </div>
                      <div className="input-container">
                        <Form.Group controlId="confirmEmail">
                          <Form.Control
                            placeholder="Confirm password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => this.handleChange(e)}
                            type={
                              this.state.showConfirmPassword
                                ? "text"
                                : "password"
                            }
                          />
                        </Form.Group>
                        <span
                          className={
                            this.state.showConfirmPassword
                              ? "icon-eye-stroke login-page-eye"
                              : "icon-eye login-page-eye"
                          }
                          onClick={this.handleShowConfirmPassword}
                        />
                        {errorPassword !== "" && (
                          <p className="validate-error">{errorPassword}</p>
                        )}
                      </div>

                      {this.state.loading ? (
                        <button className="button-submit loading" disabled>
                          <Loading />
                        </button>
                      ) : (
                        <Button
                          className="button-submit"
                          onClick={this.handleSubmit}
                        >
                            Continue
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              ) : isTokenBroken ? (
                <p className="tooltip-text">
                  It looks like you clicked on an invalid password reset link.
                  Please try again.
                </p>
              ) : (
                <p className="tooltip-text">
                        The token for your account has expired. Please change your
                        password at your earliest convenience.
                </p>
              )}
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    statusToken: state.auth.statusToken,
    statusPassword: state.auth.statusPassword,
  };
};

export default connect(mapStateToProps, {
  setNewPasswordAction,
  checkRecoveryTokenAction,
})(RecoveryPassword);

RecoveryPassword.propTypes = {
  checkRecoveryTokenAction: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  setNewPasswordAction: PropTypes.func,
  statusPassword: PropTypes.string,
  statusToken: PropTypes.string,
};
