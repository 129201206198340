import React, { Component } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Button from "components/UI/CustomButton/CustomButton";
import MultiSelect from "components/UI/MultiSelect";
import PhoneInput from "../UI/PhoneInput";
import { connect } from "react-redux";
import { addPartnerAction } from "../../actions/partners";
import { getAllRoles } from "../../actions/roles";
import { checkUserExistsAction } from "../../actions/users";
import { showModal } from "../../actions/modal";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import Joi from "joi-browser";
import Alert from "../UI/Alert";

class PartnerCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        companyAddress: "",
        name: "",
        type: "",
      },
      language: "en",
      role_guid: "",
      phone: "",
      sendMail: true,
      enabled: true,
      password: "",
      showPassword: false,

      phoneExists: "",
      emailExists: "",
      errorPhoneExists: "",
      errorEmailExists: "",
      errorsExists: {},
      errors: {},

      token: {},
      isLoading: false,
      isAdmin: "",
      role: "",

      emailValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      roleValidation: false,
      phoneValidation: false,
      companyNameValidation: false,
      companyAddressValidation: false,
      nameValidation: false,
      typeValidation: false,
      passwordValidation: false,
    };
  }

  schema = {
    email: Joi.string().required().email().label("Email"),
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    companyName: Joi.string().required().label("Company name"),
    companyAddress: Joi.string().required().label("Company address"),
    name: Joi.string().required().label("Partner name"),
    type: Joi.string().required().label("Partner type"),
    phone: Joi.string().required().min(5).label("Phone"),
    role: Joi.string().required().label("Role"),
    language: Joi.string().required().label("Language"),
  };

  async componentDidMount() {
    const token = getAuthData().userPayload;
    const isAdmin = localStorage.getItem("isAdmin");
    this.setState({ token, isAdmin });
    await this.props.getAllRoles({ type: "partner" });
  }

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      {
        ...this.state.data,
        phone: this.state.phone,
        role: this.state.role_guid,
        language: this.state.language,
      },
      this.schema,
      options
    );

    if (!this.state.sendMail) {
      let schema = {
        password: Joi.string()
          .required()
          .regex(
            /(?=^.{12,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
          )
          .error(() => {
            return {
              message:
                "Password must be at least 12 characters, including a number, a special, and  an uppercase letter.",
            };
          })
          .label("Password"),
      };

      let passwordError = Joi.validate(
        { password: this.state.password },
        schema,
        options
      );
      if (
        passwordError &&
        passwordError.error &&
        passwordError.error.details[0]
      ) {
        let arrayErrors = error ? error.details : [];
        arrayErrors.push(passwordError.error.details[0]);
        error = { ...error, details: arrayErrors };
      }
    }

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (name === "email") {
      if (
        this.state.errors[name] ||
        this.state.data[name] === "" ||
        this.state[name] === "" ||
        this.state.errorEmailExists
      )
        return "error";
      else return "success";
    } else if (
      this.state.errors[name] ||
      this.state.data[name] === "" ||
      this.state[name] === ""
    )
      return "error";
    else return "success";
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({
      data,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name: "phone", value: value });
    if (errorMessage) errors.phone = errorMessage;
    else delete errors.phone;

    this.setState({
      phone: value,
      errors,
      phoneValidation: errorMessage ? false : true,
    });

    if (value.length > 4)
      await this.props.checkUserExistsAction({ phone: value });

    this.setState({
      phoneExists: this.props.phoneExists,
    });

    if (this.state.phoneExists)
      this.setState({
        errorPhoneExists: `Phone ${value} exists`,
      });
    else
      this.setState({
        errorPhoneExists: "",
      });
  };

  onChangeEmail = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });

    const errorsExists = this.validate();
    this.setState({ errorsExists: errorsExists || {} });

    try {
      if (
        input.value.length > 8 &&
        this.state.errorsExists.email === undefined &&
        input.value.indexOf(".") !== -1
      )
        await this.props.checkUserExistsAction({ email: input.value });
    } catch {
      this.setState({
        errorEmailExists: "Email must be a valid",
      });
    }

    this.setState({
      emailExists: this.props.emailExists,
    });

    if (this.state.emailExists)
      this.setState({
        errorEmailExists: "Email exists",
        emailValidation: false,
      });
    else
      this.setState({
        errorEmailExists: "",
        emailValidation: errorMessage ? false : true,
      });
  };

  onChangePassword = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    let errorMessage;

    if (this.state.sendMail)
      errorMessage = "Password is not allowed to be empty";
    if (input.value.length < 8)
      errorMessage = "Minimum password length must be 12 characters!";
    if (!/[a-z]/.test(input.value))
      errorMessage = "Password must contain at least 1 small letter!";
    if (!/[A-Z]/.test(input.value))
      errorMessage = "Password must contain at least 1 uppercase letter!";
    if (!/[0-9]/.test(input.value))
      errorMessage = "Password must contain at least 1 number!";
    if (!/[!#$%&()*+,-./:;<=>?@[\]^_`{|}~]/.test(input.value))
      errorMessage = "Password must contain at least 1 special symbol!";

    if (errorMessage) {
      this.setState({ passwordValidation: false });
      errors[input.name] = errorMessage;
    } else {
      this.setState({ passwordValidation: true });
      delete errors[input.name];
    }

    this.setState({ password: input.value, errors });
  };

  handleEnabledCheckbox = () => {
    this.setState({
      enabled: !this.state.enabled,
    });
  };

  handleSendMailCheckbox = () => {
    this.setState({
      sendMail: !this.state.sendMail,
    });
  };

  onSelectRole = (option) => {
    const errors = { ...this.state.errors };
    delete errors.role;

    this.setState({
      role_guid: option.guid,
      errors,
      roleValidation: true,
    });
  };

  onSelectLanguage = (option) => {
    this.setState({
      language: option.name,
    });
  };

  renderInput = (label, value, name, type = "text") => {
    const { errors } = this.state;
    return (
      <Row>
        <Col md={3} sm={4} xs={4} className="form-label">
          <Form.Label>{label}*</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Group validationState={this.formValidation(name)}>
            <Form.Control
              placeholder={`Enter ${label.toLowerCase()}`}
              type={type}
              name={name}
              value={value}
              onChange={this.handleChange}
            />
            {errors[name] && (
              <span className="validate-error">{errors[name]}</span>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { role_guid } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      this.setState({ isLoading: true });
      const data = {
        partner_name: this.state.data.name,
        partner_type: this.state.data.type,
        email: this.state.data.email,
        phone: this.state.phone,
        first_name: this.state.data.firstName,
        last_name: this.state.data.lastName,
        company_name: this.state.data.companyName,
        company_address: this.state.data.companyAddress,
        role_guid,
        password: this.state.sendMail ? undefined : this.state.password,
        language: this.state.language,
        send_mail: this.state.sendMail === true ? 1 : 0,
        enabled: this.state.enabled === true ? 1 : 0,
      };
      try {
        await this.props.addPartnerAction(
          data,
          this.props.currentPagePartner,
          this.props.pageSizePartner,
          this.props.partnersSearch
        );
        swal({
          title: "Partner is created",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    let roles = this.props.roles ? this.props.roles : [];
    if (!this.state.isAdmin)
      roles = this.props.roles
        ? this.props.roles.filter((role) => role.name === "partnerRole")
        : [];
    const { errors } = this.state;

    return (
      <Form>
        <Row>
          <Col md={6} sm={6} xs={6}>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Email*</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("email")}>
                  <Form.Control
                    placeholder="Enter email"
                    // type="email"
                    name="email"
                    value={this.state.email}
                    onChange={(e) => this.onChangeEmail(e)}
                  />
                  {errors.email && (
                    <span className="validate-error">{errors.email}</span>
                  )}
                  {this.state.errorEmailExists && (
                    <span className="validate-error">
                      {this.state.errorEmailExists}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {/* {this.renderInput("Email", this.state.email, "email", "email")} */}
            {this.renderInput("First name", this.state.firstName, "firstName")}
            {this.renderInput("Last name", this.state.lastName, "lastName")}

            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Phone*</Form.Label>
              </Col>
              <Col md={8} sm={8} xs={7}>
                <Form.Group>
                  <PhoneInput
                    name="phone"
                    placeholder="Enter phone number"
                    value={this.state.phone}
                    onChange={(e) => this.onChangeNumber(e)}
                  />
                  {this.state.errorPhoneExists && (
                    <span className="validate-error">
                      {this.state.errorPhoneExists}
                    </span>
                  )}
                  {errors.phone && (
                    <span className="validate-error">{errors.phone}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {this.renderInput(
              "Company name",
              this.state.companyName,
              "companyName"
            )}
            {this.renderInput(
              "Company address",
              this.state.companyAddress,
              "companyAddress"
            )}
          </Col>

          <Col md={6} sm={6} xs={6}>
            {this.renderInput("Partner name", this.state.name, "name")}
            {this.renderInput("Partner type", this.state.type, "type")}
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Role*</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <MultiSelect
                    name="Roles"
                    options={roles}
                    multi={false}
                    onSelect={this.onSelectRole}
                    placeholder="Select role"
                  />
                  {errors.role && (
                    <span className="validate-error">{errors.role}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ margin: "25px 0" }}>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Enable*</Form.Label>
              </Col>
              <Col md={1} sm={1} xs={1}>
                <Form.Group>
                  <input
                    type="checkbox"
                    id="enabledCheckbox"
                    checked={this.state.enabled}
                    onChange={this.handleEnabledCheckbox}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ margin: "25px 0" }}>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Send mail*</Form.Label>
                <i className="far fa-question-circle help-tip">
                  <p>
                    We will send your generated username and password to your
                    email.
                  </p>
                </i>
              </Col>
              <Col md={1} sm={1} xs={1}>
                <Form.Group>
                  <input
                    type="checkbox"
                    id="sendMailCheckbox"
                    checked={this.state.sendMail}
                    onChange={this.handleSendMailCheckbox}
                  />
                </Form.Group>
              </Col>
            </Row>

            {!this.state.sendMail && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Password*</Form.Label>
                </Col>
                <Col md={8} sm={7} xs={7}>
                  <Form.Group validationState={this.formValidation("password")}>
                    <Form.Control
                      name="password"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.password}
                      placeholder="Enter password"
                      onChange={this.onChangePassword}
                    />
                    {errors.password && (
                      <span className="validate-error">{errors.password}</span>
                    )}
                  </Form.Group>
                </Col>
                <Button className="eye" onClick={this.handleShowPassword}>
                  {this.state.showPassword ? (
                    <span className="fa fa-eye-slash" />
                  ) : (
                    <span className="fa fa-eye" />
                  )}
                </Button>
              </Row>
            )}
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Language</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <MultiSelect
                    name="Language"
                    options={[
                      { name: "en", guid: "en" },
                      { name: "ru", guid: "ru" },
                    ]}
                    multi={false}
                    defaultValue={this.state.language}
                    onSelect={this.onSelectLanguage}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <div>
          {this.state.isLoading ? (
            <ReactLoading type="cylon" color="grey" />
          ) : (
            <Button
              className={
                this.state.phoneValidation &&
                this.state.roleValidation &&
                this.state.emailValidation &&
                this.state.companyNameValidation &&
                this.state.companyAddressValidation &&
                this.state.firstNameValidation &&
                this.state.lastNameValidation &&
                this.state.nameValidation &&
                this.state.typeValidation &&
                (this.state.sendMail ? true : this.state.passwordValidation)
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
              onClick={this.handleSubmit}
            >
              Add
            </Button>
          )}
        </div>
        <Col sm={1} />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailExists: state.users.users.email_exists,
    phoneExists: state.users.users.phone_exists,
    roles: state.roles.rolesList,
    currentPagePartner: state.partners.currentPage,
    pageSizePartner: state.partners.pageSize,
    partnersSearch: state.search.partnersSearch,
  };
};

export default connect(mapStateToProps, {
  getAllRoles,
  checkUserExistsAction,
  addPartnerAction,
  showModal,
})(PartnerCreator);

PartnerCreator.propTypes = {
  addPartnerAction: PropTypes.func,
  checkUserExistsAction: PropTypes.func,
  currentPagePartner: PropTypes.number,
  emailExists: PropTypes.bool,
  getAllRoles: PropTypes.func,
  pageSizePartner: PropTypes.number,
  partnersSearch: PropTypes.object,
  phoneExists: PropTypes.bool,
  roles: PropTypes.array,
  showModal: PropTypes.func,
};
