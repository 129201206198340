import Modal from "components/UI/Modal";
import ability from "config/ability";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteGroupAction, getAllGroups } from "../../actions/groups";
import { reset, searchInGroups } from "../../actions/search";
import { resetPage, setNewTable } from "../../actions/store";
import AbstractComponent from "../../factories/Main";
import { createColComponentForTable } from "../../helpers/createColComponentForTable";
import { getAuthData } from "../../services/paymentBackendAPI/backendPlatform";
import { getGroups } from "../../services/paymentBackendAPI/management/groups";
import GroupCreator from "./Creator";
import GroupEditor from "./Editor";

let columns = [
  {
    path: "group_name",
    label: "Group name",
    content: (group) => (
      <Link className="link" to={`/about/group/${group.group_guid}`}>
        {group.group_name}
      </Link>
    ),
    textContent: (group) => group.group_name,
  },
  { path: "group_type", label: "Group type" },
  { path: "partner_name", label: "Partner" },
  { path: "username", label: "Username" },
  { path: "email", label: "Email" },
  {
    key: "enabled",
    content: (login) => (
      <i
        className={
          login.enabled ? "icon-success icon green" : "icon-failed icon red"
        }
      />
    ),
    textContent: (login) => (login.enabled ? "Enabled" : "Disabled"),
    label: "Status",
    align: "center",
    colWidth: 4,
  },
  ability.can("EXECUTE", "USERGROUP") && {
    key: "edit",
    content: (group) => (
      <Modal
        header="Edit group"
        content={GroupEditor}
        contentProps={{ guid: group.group_guid, type: "gateway" }}
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("DELETE", "USERGROUP") && {
    key: "deleteGroup",
    label: "Delete",
    colWidth: 4,
  },
];

if (getAuthData() && getAuthData().userPayload.partner) {
  columns.splice(2, 1);
}

const mapStateToProps = (state) => {
  return {
    data: state.groups.groupsList,
    count: state.groups.count,
    table: state.store.table,
    searchData: state.search.groupsSearch,
    isSearch: state.search.isSearch,
    loading: state.groups.groupsLoading,
    name: "USERGROUP",
    columns,
    columnsComponent: createColComponentForTable(columns),
    keyPath: "group_guid",
    modal: <GroupCreator />,
    exportFunction: getGroups,
  };
};

export default connect(mapStateToProps, {
  get: getAllGroups,
  delete: deleteGroupAction,
  search: searchInGroups,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
