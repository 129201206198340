import React from "react";
import { Col, Row, Form } from "react-bootstrap";

function Input({ label, name, type = "text", value, errors, callback }) {

  const handleChange = ({ currentTarget: input }) => {
    callback && callback(input);
  };

  return (
    <Row >
      <Col md={3} sm={4} xs={4} className="form-label">
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col md={8} sm={7} xs={7}>
        <Form.Group>
          <Form.Control
            name={name}
            type={type}
            value={value}
            placeholder={"Enter " + label}
            onChange={handleChange}
          />
          {errors[name] && (
            <span className="validate-error">
              {errors[name]}
            </span>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}

export default Input;