import React from "react";

import { Card, Row, Col, Form } from "react-bootstrap";
import Content from "../Content";

import CustomSelect from "components/UI/MultiSelect";
import CustomButton from "components/UI/Button";
import { getAllMerchants } from "actions/merchants";
import {
  getAllShops,
  getMerchantShopsAction,
  getShopTerminalsAction,
} from "actions/shops";
import { getHistogramReportAction } from "actions/transactions";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Table from "components/UI/Table/index";

class HistogramReport extends React.Component {
  state = {
    partnerGuid: null,
    groupGuid: null,
    merchantGuid: null,
    shop_guid: null,
    terminal_guid: null,
    startDate: moment()
      .utcOffset(0)
      .format("YYYY-MM-DDTHH:mm:ss")
      .replace(/\D\d{2}\w/, "-01T"),
    endDate: moment().utcOffset(0).format("YYYY-MM-DDTHH:mm:ss"),
    datasets: [],
    datasetsPie: [],
    labels: [],
    loading: true,
    selectedMerchant: {
      guid: "1",
      name: "All merchants",
      label: "All merchants",
    },
    selectedShop: { guid: "1", name: "All shops", label: "All shops" },
    selectedTerminal: {
      guid: "1",
      name: "All terminals",
      label: "All terminals",
    },
    merchants: [],
  };

  componentDidMount = async () => {
    await this.loadData();
    await this.props.getAllMerchants();
    await this.props.getAllShops();
    let merchants = [];
    merchants = this.props.merchants.map((item) => {
      item.name = item.merchant_name;
      item.guid = item.merchant_guid;
      return item;
    });
    merchants.push({
      guid: "1",
      name: "All merchants",
      label: "All merchants",
    });
    this.setState({
      merchants,
    });
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      let req = {
        partner_guid: this.state.partnerGuid,
        group_guid: this.state.groupGuid,
        merchant_guid: this.state.merchantGuid,
        shop_guid: this.state.shop_guid,
        terminal_guid: this.state.terminal_guid,
        from_date: this.state.startDate,
        to_date: this.state.endDate,
      };
      await this.props.getHistogramReportAction(req);

      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  handleSelectMerchant = async (option) => {
    this.setState({
      selectedMerchant: option,
      selectedShop: { guid: "1", name: "All shops", label: "All shops" },
      merchantGuid:
        option.guid !== "" && option.guid !== "1" ? option.guid : null,
    });
    if (option.guid !== "" && option.guid !== "1") {
      await this.props.getMerchantShopsAction(option.guid);
    } else {
      await this.props.getAllShops();
    }
  };

  handleSelectShop = async (option) => {
    if (option.guid !== "" && option.guid !== "1")
      await this.props.getShopTerminalsAction(option.guid);
    this.setState({
      selectedShop: option,
      selectedTerminal: {
        guid: "1",
        name: "All terminals",
        label: "All terminals",
        value: "1",
      },
      shop_guid: option.guid !== "" && option.guid !== "1" ? option.guid : null,
    });
  };

  handleSelectTerminal = async (option) => {
    this.setState({
      selectedTerminal: option,
      terminal_guid:
        option.guid !== "" && option.guid !== "1" ? option.guid : null,
    });
  };

  onDateChange = async (start, end) => {
    this.setState(
      {
        startDate: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => this.loadData()
    );
  };

  handleClick = async () => {
    await this.loadData();
  };

  render() {
    const shopsForSelect = [
      ...this.props.shops,
      { guid: "1", name: "All shops", label: "All shops", value: "1" },
    ];
    const terminalsForSelect = [
      ...this.props.terminals,
      { guid: "1", name: "All terminals", label: "All terminals", value: "1" },
    ];
    return (
      <Content>
        <Card>
          <Card.Body style={{ overflow: "visible" }}>
            <Row>
              <Col md={6} sm={6} xs={6}>
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Merchant</Form.Label>
                  </Col>
                  <Col md={8} sm={7} xs={7}>
                    <Form.Group>
                      <CustomSelect
                        multi={false}
                        name="selectedMerchant"
                        options={this.state.merchants}
                        value={this.state.selectedMerchant}
                        onSelect={this.handleSelectMerchant}
                        placeholder="Select merchant..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Shop</Form.Label>
                  </Col>
                  <Col md={8} sm={7} xs={7}>
                    <Form.Group>
                      <CustomSelect
                        multi={false}
                        name="selectedShop"
                        options={shopsForSelect}
                        value={this.state.selectedShop}
                        onSelect={this.handleSelectShop}
                        placeholder="Select shop..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6} xs={6}>
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Terminal</Form.Label>
                  </Col>
                  <Col md={8} sm={7} xs={7}>
                    <Form.Group>
                      <CustomSelect
                        multi={false}
                        name="selectedTerminal"
                        options={terminalsForSelect}
                        value={this.state.selectedTerminal}
                        onSelect={this.handleSelectTerminal}
                        placeholder="Select terminal..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <Row>
                  <Col md={3} sm={4} xs={4} className="form-label">
                    <Form.Label>Date range</Form.Label>
                  </Col>
                  <Col md={8} sm={7} xs={7}>
                    <Form.Group>
                      <DateRangePicker
                        onCallback={this.onDateChange}
                        initialSettings={{
                          startDate: this.state.startDate
                            ? moment(this.state.startDate).format("DD.MM.YYYY")
                            : undefined,
                          endDate: this.state.endDate
                            ? moment(this.state.endDate).format("DD.MM.YYYY")
                            : undefined,
                          locale: {
                            format: "DD.MM.YYYY",
                          },
                        }}
                      >
                        <input
                          type="text"
                          className="text-input daterange-input form-control"
                        />
                      </DateRangePicker>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginLeft: "15px" }}>
              <Col md={2} sm={2} xs={2}>
                <CustomButton className="btn" onClick={this.handleClick}>
                  Find
                </CustomButton>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Table
              columns={[
                {
                  path: "week_day",
                  label: "Week day:",
                },
                { path: "day_hour", label: "Day hour" },
                { path: "transaction_count", label: "Transaction count" },
              ]}
              data={this.props.response.data || []}
              keyPath="err_code"
              disableSearch
              disableSort
            />
          </Card.Body>
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    shops: state.shops.shopsList,
    terminals: state.shops.shopTerminals,
    response: state.transactions.histogramReport,
  };
};

export default connect(mapStateToProps, {
  getHistogramReportAction,
  getAllMerchants,
  getMerchantShopsAction,
  getAllShops,
  getShopTerminalsAction,
})(HistogramReport);

HistogramReport.propTypes = {
  getHistogramReportAction: PropTypes.func,
};
