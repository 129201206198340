import React from "react";
import ChargebackMain from "components/Chargeback/Main";
import Content from "../Content";

const Chargeback = () => {
  return (
    <Content>
      <ChargebackMain />
    </Content>
  );
};

export default Chargeback;
