import { backendManagement } from "../backendPlatform";

export const getCurrencies = (args) => {
  return backendManagement.get("/currencies", { params: { ...args } });
};

export const createCurrency = data => {
  return backendManagement.post("/currencies", data);
};

export const getCurrency = guid => {
  return backendManagement.get(`/currencies/${guid}`);
};

export const updateCurrency = data => {
  return backendManagement.post("/currencies", data);
};

export const deleteCurrency = ({ guid, reason }) => {
  return backendManagement.post("/currencies", { guid, delete: true, reason });
};

export const getCurrencyRates = ( from_currency, to_currency ) => {
  return backendManagement.get(`/currencies/exchange/${from_currency}/to/${to_currency}`);
};