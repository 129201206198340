import moment from "moment";


export const formatDate = (date) => {
  return moment(date).format("DD.MM");
};

const daysBetweenTwoDates = (date1, date2) => {
  return Math.abs((date2 - date1) / (1000 * 60 * 60 * 24));
};

export const lastNDays = (N) => {

  let result = [];
  for (let i = 0; i < N; i++) {
    let d = new Date();
    d.setDate(d.getDate() - i);

    result = [ formatDate(d), ...result ];
  }
  return result;
};


/*
  EXAMPLE:
    const since = new Date('7/13/2010'); (MM/DD/YYYY)
    const to = new Date('12/15/2010');
*/
export const daysSinceTo = (since, to) => {
  let result = [ moment(to).format("DD.MM") ];
  let N = daysBetweenTwoDates(since.setHours(0), to.setHours(0));
  let d = to;
  for (let i = 0; i < Math.floor(N); i++) {

    d.setDate(d.getDate() - 1);
    result.unshift(formatDate(d));
  }

  return result;
};



export const JSDateToDateTime = (inDate) => {
  const GMTPlusSeven = 7;   // Tomsk timezone
  let date = new Date(inDate.valueOf() + GMTPlusSeven * 60 * 60000);     // GMT+7
  date = date.getUTCFullYear() + "-" +
    ("00" + (date.getUTCMonth() + 1)).slice(-2) + "-" +
    ("00" + date.getUTCDate()).slice(-2) + " " +
    ("00" + date.getUTCHours()).slice(-2) + ":" +
    ("00" + date.getUTCMinutes()).slice(-2) + ":" +
    ("00" + date.getUTCSeconds()).slice(-2);
  return date;
};