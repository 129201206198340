import { setCurrentSearchData } from "actions/search";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";

class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: undefined,
      to_date: undefined,
      currentPage: 1,
      pageSize: 100,
      isSearch: false,
      name: true,
    };
  }

  componentDidMount = () => {
    this.setState({
      from_date: moment(this.props.currentSearchData.from_date).format(
        "DD.MM.YYYY"
      ),
      to_date: moment(this.props.currentSearchData.to_date).format(
        "DD.MM.YYYY"
      ),
    });
  };

  // handleKeyPress = async e => {
  //   if (e.key === "Enter") {
  //     await this.props.get({
  //       page: 1,
  //       items: this.state.pageSize,
  //       ...this.props.searchData,
  //     });
  //     this.props.updateData(this.state.name);
  //     this.props.updateCurrentPage();
  //   }
  // }

  // static getDerivedStateFromProps(nextProps) {
  //   if (nextProps.reset) {
  //     return {
  //       from_date: ""
  //     };
  //   }
  // }

  handleReset = async () => {
    await this.props.setCurrentSearchData({
      from_date: "",
      to_date: "",
    });
    await this.props.search({
      from_date: undefined,
      to_date: undefined,
    });
    await this.props.get({
      page: 1,
      items: this.state.pageSize,
      ...this.props.searchData,
    });
    this.props.updateCurrentPage();
  };

  onChange = async (start, end) => {
    this.props.setCurrentSearchData({
      from_date: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
      to_date: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
    });
    await this.props.search({
      from_date: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
      to_date: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
    });
    await this.props.get({
      page: 1,
      items: this.state.pageSize,
      ...this.props.searchData,
    });
    this.props.updateCurrentPage();
  };

  render() {
    return (
      <div
        className={
          this.props.isSearch
            ? "searchOpen search search-input"
            : "searchClosed search search-input"
        }
      >
        <InputGroup>
          <DateRangePicker
            onCallback={this.onChange}
            initialSettings={{
              startDate: this.props.currentSearchData.from_date
                ? moment(this.props.currentSearchData.from_date).format(
                  "DD.MM.YYYY"
                )
                : undefined,
              endDate: this.props.currentSearchData.to_date
                ? moment(this.props.currentSearchData.to_date).format(
                  "DD.MM.YYYY"
                )
                : undefined,
              locale: {
                format: "DD.MM.YYYY",
              },
            }}
          >
            <Form.Control
              type="text"
              style={{
                paddingLeft: "5px",
                //  minWidth: "145px"
              }}
            />
          </DateRangePicker>
          <InputGroup.Append>
            <i
              className="icon-delete icon"
              onClick={this.handleReset}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Append>
        </InputGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.search.reset,
    isSearch: state.search.isSearch,
    currentSearchData: state.search.currentSearchData,
  };
};

export default connect(mapStateToProps, {
  setCurrentSearchData,
})(DateRangeSelector);

DateRangeSelector.propTypes = {
  currentSearchData: PropTypes.object,
  get: PropTypes.func,
  isSearch: PropTypes.bool,
  search: PropTypes.func,
  searchData: PropTypes.object,
  setCurrentSearchData: PropTypes.func,
  updateCurrentPage: PropTypes.func,
};
