import React from "react";
import { connect } from "react-redux";
import { inverseSidebar } from "actions/sidebar";
import PropTypes from "prop-types";

const SidebarHideButton = ({ isHide, inverseSidebar }) => {
  return (
    <div
      id="sidebar-hide-button-div"
      className={isHide ? "slideOut" : "slideIn"}
      onClick={async () => await inverseSidebar()}
    >
      <i
        id="sidebar-hide-button"
        className={isHide ? "icon-sidebar slideOut" : "icon-sidebar slideIn"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isHide: state.sidebar.isHide,
  };
};

export default connect(mapStateToProps, {
  inverseSidebar,
})(SidebarHideButton);

SidebarHideButton.propTypes = {
  inverseSidebar: PropTypes.func,
  isHide: PropTypes.bool,
};
