import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import MultiSelect from "components/UI/MultiSelect";
import swal from "sweetalert";
import { connect } from "react-redux";

import { parseResponse } from "helpers/parseResponse";
import { getEmailMessageAction } from "actions/auth";
import ReCAPTCHA from "react-google-recaptcha";
import Joi from "joi-browser";
import PhoneInput from "../PhoneInput";
import Loading from "components/UI/Spinner";
import PropTypes from "prop-types";

import Alert from "components/UI/Alert";
const recaptchaRef = React.createRef();

class ConfirmEmail extends Component {
  state = {
    fieldName: "email",
    fieldValue: "",
    verified: null,
    value: null,
    validation: false,
    errors: {},
    loading: false,
  };

  schema = {
    email: Joi.string().required().email().label("Email"),
    username: Joi.string().required().label("Username"),
    phone: Joi.string().required().min(5).label("Phone"),
  };

  validate = () => {
    const options = { abortEarly: false };
    let name = this.state.fieldName;
    let value = this.state.fieldValue;
    let schema = this.schema[name];
    let { error } = Joi.validate({ [name]: value }, schema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      if (item.path[0]) {
        errors[item.path[0]] = item.message;
      }
    }
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name]) return "error";
    else return "success";
  };

  handleChange = (e) => {
    const value = e.target.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: this.state.fieldName,
      value,
    });
    if (errorMessage) errors[this.state.fieldName] = errorMessage;
    else delete errors[this.state.fieldName];

    this.setState({
      fieldValue: value,
      errors,
      validation: errorMessage ? false : true,
    });
  };

  onChangeNumber = async (value) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: this.state.fieldName,
      value,
    });
    if (errorMessage) errors[this.state.fieldName] = errorMessage;
    else delete errors[this.state.fieldName];

    this.setState({
      fieldValue: value,
      errors,
      validation: errorMessage ? false : true,
    });
  };

  handleBack = () => {
    this.props.history.push("/");
    window.location.reload();
  };

  onActionSelect = (option) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: option.name,
      value: this.state.fieldValue,
    });
    if (errorMessage) errors[option.name] = errorMessage;
    else delete errors[option.name];

    for (let key in errors) {
      if (key !== option.name) {
        delete errors[key];
      }
    }

    this.setState({
      fieldName: option.name,
      errors,
      validation: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.verified) {
      try {
        this.setState({ loading: true });
        const { fieldName, fieldValue } = this.state;
        await this.props.getEmailMessageAction({
          [fieldName]: fieldValue,
          ["g-recaptcha-response"]: this.state.value,
        });
        swal({
          title: `${this.props.status}`,
          icon: "success",
        });
        this.setState({ loading: false });
      } catch (error) {
        const parsedError = parseResponse(error);
        this.setState({ loading: false });
        Alert({ type: "error", message: parsedError.message });
        recaptchaRef.current.reset();
      }
    }
  };

  onChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      this.setState({ verified: true, value: recaptchaValue });
    } else this.setState({ verified: false });
  };

  render() {
    const actions = [
      {
        guid: 0,
        name: "email",
      },
      {
        guid: 1,
        name: "phone",
      },
      {
        guid: 2,
        name: "username",
      },
    ];
    const { errors } = this.state;
    return (
      <div className="login-page">
        <div className="login-card">
          <div className="forgot">
            <p className="header-login">Forgot password</p>

            {!this.props.status && (
              <>
                <p className="tooltip-text">
                  Enter your user account's verified email, phone or username
                  and we will send you a password reset link.
                </p>
                <form>
                  <div className="inputs-forgot">
                    <Form.Group>
                      <MultiSelect
                        name="forgot-select"
                        options={actions}
                        multi={false}
                        onSelect={this.onActionSelect}
                        defaultValue={this.state.fieldName}
                      />
                    </Form.Group>

                    <Form.Group>
                      {this.state.fieldName === "phone" ? (
                        <PhoneInput
                          name="phone"
                          placeholder="Enter phone number"
                          value={this.state.fieldValue}
                          onChange={(e) => this.onChangeNumber(e)}
                        />
                      ) : (
                        <Form.Control
                          placeholder="Enter data"
                          name="recoveryData"
                          value={this.state.fieldValue}
                          onChange={(e) => this.handleChange(e)}
                        />
                      )}
                      {errors.phone && (
                        <span className="validate-error">{errors.phone}</span>
                      )}
                      {errors.username && (
                        <span className="validate-error">
                          {errors.username}
                        </span>
                      )}
                      {errors.email && (
                        <span className="validate-error">{errors.email}</span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="form-container">
                    <div className="form">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Ld-BugUAAAAAP97FBGlgH0aSt_s0A2bP3TbbWad"
                        onChange={this.onChange}
                        className="captcha"
                      />

                      {this.state.loading ? (
                        <button className="button-submit loading" disabled>
                          <Loading />
                        </button>
                      ) : (
                        <Button
                          className="button-submit"
                          onClick={this.handleSubmit}
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </>
            )}
            {this.props.status && (
              <>
                <p style={{ color: "grey", textAlign: "center" }}>
                  Check your email for a link to reset your password. If it
                  doesn’t appear within a few minutes, check your spam folder.
                </p>
                <Button className="button-submit" onClick={this.handleBack}>
                  Return to sign in
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.auth.status,
  };
};

export default connect(mapStateToProps, {
  getEmailMessageAction,
})(ConfirmEmail);

ConfirmEmail.propTypes = {
  getEmailMessageAction: PropTypes.func,
  history: PropTypes.object,
  status: PropTypes.bool,
};
