import React from "react";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
import { LineChart } from "../UI/LineChart";
import { lastNDays } from "../../services/dateTime/dateTime";
import PropTypes from "prop-types";
import { getTransactionAmountAction } from "../../actions/transactions";
import Spinner from "components/UI/Spinner";
import moment from "moment";
import Can from "components/Can";

class LineChartTransactionsAmount extends React.Component {
  state = {
    daysCount: 7,
    loading: true,
    width: 0,
  };

  componentDidMount() {
    this.props.getTransactionAmountAction({ days: this.state.daysCount });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    if (this.props.loading) return <Spinner />;
    else {
      const labelsPie = lastNDays(this.state.daysCount);
      let dates = this.props.amount.date
        ? this.props.amount.date.map((date) => {
          return moment(date).utcOffset(0).format("DD.MM");
        })
        : [];

      let datasetSuccess = [];
      let datasetFailed = [];
      let datasetPending = [];

      dates.forEach((date, index) => {
        const findIndex = labelsPie.findIndex((itemD) => itemD === date);
        if (findIndex !== -1) {
          datasetSuccess[findIndex] = parseFloat(
            this.props.amount.success[index],
            10
          );
          datasetFailed[findIndex] = parseFloat(
            this.props.amount.failed[index],
            10
          );
          datasetPending[findIndex] = parseFloat(
            this.props.amount.pending[index],
            10
          );
        }
      });
      for (let i = 0; i < labelsPie.length; i++) {
        if (!datasetSuccess[i]) datasetSuccess[i] = 0;
        if (!datasetFailed[i]) datasetFailed[i] = 0;
        if (!datasetPending[i]) datasetPending[i] = 0;
      }

      const datasetsPie = [
        {
          label: "Success transactions",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "black",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: datasetSuccess,
        },
        {
          label: "Failed transactions",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255,99,132,0.4)",
          borderColor: "rgba(255,99,132,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(255,99,132,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255,99,132,1)",
          pointHoverBorderColor: "black",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: datasetFailed,
        },
        {
          label: "Pending transactions",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255, 159, 64, 0.4)",
          borderColor: "rgba(255, 159, 64, 1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(255, 159, 64, 1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255, 159, 64, 1)",
          pointHoverBorderColor: "black",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: datasetPending,
        },
      ];

      return (
        <div>
          <Can do="REPORT" on="TRANSACTIONAMOUNT">
            <LineChart
              labels={labelsPie}
              datasets={datasetsPie}
              name="Transactions amount"
              options={{
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </Can>
          <Can not do="REPORT" on="TRANSACTIONAMOUNT">
            {this.state.width > 1200 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(40%)",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                  No privileges
              </Badge>
            )}
          </Can>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    amount: state.transactions.amount,
    loading: state.transactions.transactionAmountLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionAmountAction,
})(LineChartTransactionsAmount);

LineChartTransactionsAmount.propTypes = {
  amount: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  getTransactionAmountAction: PropTypes.func,
  loading: PropTypes.bool,
};
