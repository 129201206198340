import * as types from "../constants/actionTypes";

const initialState = {
  table: {
    tableName: "",
    page: 1,
    pageSize: 100,
    filters: {}
  },
  form: {}
};

export default function store(state = initialState, action) {
  switch (action.type) {

    case types.LOAD_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          page: action.page,
          pageSize: action.size,
        }
      };

    case types.SET_NEW_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          name: action.name,
          page: 1,
          pageSize: 100,
          filters: {}
        }
      };

    case types.RESET_PAGE:
      return {
        ...state,
        table: {
          ...state.table,
          page: 1,
        }
      };

    default:
      return state;
  }
}