import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { inverseSearch } from "../../../../actions/search";
import Search from "../../Search/index";
import ActiveSelector from "../../SearchSelectors/ActiveSelector";
import BoolSelector from "../../SearchSelectors/BoolSelector";
import DateRangeSelector from "../../SearchSelectors/DateRangeSelector";
import DateSelector from "../../SearchSelectors/DateSelector";
import StatusSelector from "../../SearchSelectors/StatusSelector";
import ActionSelector from "../../SearchSelectors/ActionSelector";
import MultiSelectorContainer from "../../SearchSelectors/MultiSelector/container";
import { getAllGateways } from "actions/gateways";
import { getAllCurrencies } from "actions/currencies";
import { getTerminalsAction } from "actions/terminals";
import { getAllShops } from "actions/shops";
import { getAllMerchants } from "actions/merchants";
import ability from "config/ability";
import NumberSearch from "../../SearchSelectors/Number";
class TableHeader extends Component {
  render() {
    const isAdmin = localStorage.getItem("isAdmin");
    const notSearchLabels = [
      "Edit",
      "Delete",
      "Body",
      "Request",
      "Response",
      "Parameters",
      "Headers",
      "Description",
      "Params",
      "Steps",
      "Created by",
      "Message",
      "Datetime",
      "View",
      "Success transaction",
      "Success EU card",
      "Success non EU card",
      "Decline transaction",
      "Reason code",
      "Chargeback process date",
      "Chargeback amount",
      "Transaction amount",
      "Transaction process date",
      "Default",
      "Rates",
      "Info",
      "Deactivate",
      "Summary amount",
      "Summary count",
      "rate_to_eurAudit",
      "Reason",
      "Connection fee",
      "Monthly fee",
      "Merchant amount limit",
      "Merchant period limit (days)",
      "Monthly amount limit",
      "Author",
      "Auth type",
      "First name",
      "Last name",
      "Language",
      "Risk category",
      "Note",
      "Three d",
      "Rate guid",
      "Monthly amount limit",
      "Payment amount limit",
      "Transaction count limit",
      "Antifraud monitor value",
      "Billing descriptor",
      "Antifraud monitor",
      "Supported brands",
      "Checkout method",
      "Enable checkout",
      "Routing string",
      "Generate statement",
      "Bank address",
      "Bank name",
      "Holder country",
      "Holder name",
      "Tracking ID",
      "Base amount",
      "To bank",
      "To client",
      "To processor",
      "Hold",
      "Hold date",
      "Hold flag",
      "History",
    ];

    const notSearchKeys = [
      "approveAdmin",
      "order_action",
      "company_addressHistory",
      "company_nameHistory",
      "currency_name_audit",
      "transaction_guid_audit",
      "amount_audit",
      "currency_audit",
      "card_eu_audit",
      "card_type_audit",
      "card_schema_audit",
      "card_holder_audit",
      "card_number_audit",
      "exp_month_year_audit",
      "bin_country_audit",
      "email_audit",
      "phone_audit",
      "ip_audit",
      "country_audit",
      "city_audit",
      "address_audit",
      "zip_audit",
      "serviceName",
      "serviceUrl",
      "serviceStartTime",
      "start_time_audit",
      "url_audit",
      "options_audit",
      "detail_service",
      "parent_statement",
    ];

    const notSortLabels = [
      "Edit",
      "Delete",
      "ID",
      "Status",
      "Description",
      "Request guid",
      "Datetime",
      "IP",
      "Admin guid",
      "URL",
      "Headers",
      "Parameters",
      "Request",
      "Response",
      "Status code",
      "Merchant guid",
      "Created at",
      "Partner guid",
      "Group guid",
      "Created by",
      "Amount",
      "Currency",
      "Shop",
      "Terminal",
      "Date",
      "Payment Status",
      "Credit card",
      "Card holder",
      "Card type",
      "Card schema",
      "Success transaction",
      "Success EU card",
      "Success non EU card",
      "Decline transaction",
      "Default",
      "Approve",
      "Reason",
      "Chargeback amount",
      "Chargeback process date",
      "Transaction amount",
      "Transaction process date",
      "Rates",
      "Time",
      "Merchant id",
      "Action",
      "Info",
      "Active",
      "Deactivate",
      "rate_to_eurAudit",
      "Reason",
      "Connection fee",
      "Monthly fee",
      "Merchant amount limit",
      "Merchant period limit (days)",
      "Monthly amount limit",
      "Author",
      "Auth type",
      "First name",
      "Last name",
      "Language",
      "Risk category",
      "Note",
      "Enabled",
      "Locked",
      "URL",
      "Three d",
      "Rate guid",
      "Monthly amount limit",
      "Payment amount limit",
      "Transaction count limit",
      "Antifraud monitor value",
      "Billing descriptor",
      "Antifraud monitor",
      "Supported brands",
      "Checkout method",
      "Enable checkout",
      "Routing string",
      "Generate statement",
      "Bank address",
      "Bank name",
      "Holder country",
      "Holder name",
      "Parent guid",
      "Test",
      "Tracking ID",
      "Base amount",
      "To bank",
      "To client",
      "To processor",
      "Hold",
      "Hold date",
      "Hold flag",
      "History",
    ];

    const notSortKeys = [
      "privilegeName",
      "accountNumber",
      "accountHolderName",
      "accountHolderCountry",
      "accountBankName",
      "accountBankAddress",
      "accountGuid",
      "transaction_processing_guid",
      "chargeback_merchant_name",
      "to_date",
      "from_date",
      "company_addressHistory",
      "company_nameHistory",
      "guid_audit",
      "roleType",
      "step_type",
      "transactionType",
      "template_type",
      "number_audit",
      "transaction_guid_audit",
      "terminal_guid_audit",
      "terminal_name_audit",
      "amount_audit",
      "currency_audit",
      "card_eu_audit",
      "card_type_audit",
      "card_schema_audit",
      "card_holder_audit",
      "card_number_audit",
      "exp_month_year_audit",
      "bin_country_audit",
      "email_audit",
      "phone_audit",
      "ip_audit",
      "country_audit",
      "city_audit",
      "address_audit",
      "zip_audit",
      "serviceName",
      "serviceUrl",
      "serviceStartTime",
      "start_time_audit",
      "url_audit",
      "tracking_id",
      "reason_code",
      "options_audit",
      "detail_service",
      "parent_statement",
      "gateways",
    ];

    const optionsTypesRole = [
      { guid: "System", name: "System" },
      { guid: "Merchant", name: "Merchant" },
      { guid: "Group", name: "Group" },
      { guid: "Partner", name: "Partner" },
    ];

    const optionsTypesTransaction = [
      { guid: "Payment", name: "Payment" },
      { guid: "Authorization", name: "Authorization" },
      { guid: "Cancel", name: "Cancel" },
      { guid: "Refund", name: "Refund" },
      { guid: "Capture", name: "Capture" },
      { guid: "Reverse", name: "Reverse" },
    ];

    const optionsOrdersTypesTransaction = [
      { guid: "Payment", name: "Payment" },
      { guid: "Authorization", name: "Authorization" },
      { guid: "Recurring", name: "Recurring" },
    ];

    const paymentStatus = [
      { guid: "Paid", name: "Paid" },
      { guid: "Unpaid", name: "Unpaid" },
      { guid: "Aborted", name: "Aborted" },
      { guid: "Refunded", name: "Refunded" },
      { guid: "Subscribed", name: "Subscribed" },
      { guid: "Cancelled", name: "Cancelled" },
    ];

    const optionsTypesBlacklist = [
      { guid: "Deny", name: "Deny" },
      { guid: "Allow", name: "Allow" },
    ];

    const optionsTypesBlacklistRules = [
      { guid: "Ip", name: "Ip" },
      { guid: "Card", name: "Card" },
      { guid: "Country", name: "Country" },
      { guid: "Mask", name: "Mask" },
      { guid: "Bin", name: "Bin" },
    ];

    const optionsActions = [
      { guid: "created", name: "created" },
      { guid: "updated", name: "updated" },
      { guid: "deleted", name: "deleted" },
    ];

    const optionsCurrencies = [
      { guid: "RUB", name: "RUB" },
      { guid: "EUR", name: "EUR" },
      { guid: "USD", name: "USD" },
      { guid: "SEK", name: "SEK" },
      { guid: "ILS", name: "ILS" },
      { guid: "PLN", name: "PLN" },
    ];

    let statementPaymentStatus = [
      { guid: "Payment Due", name: "Payment Due" },
      { guid: "Paid", name: "Paid" },
    ];
    if (isAdmin) {
      statementPaymentStatus = [
        { guid: "Created", name: "Created" },
        { guid: "Verified", name: "Verified" },
        { guid: "Payment Due", name: "Payment Due" },
        { guid: "Paid", name: "Paid" },
      ];
    }

    const orderStatus = [
      { guid: "Success", name: "Success" },
      { guid: "Failed", name: "Failed" },
      { guid: "Pending", name: "Pending" },
      { guid: "3Dwaiting", name: "3Dwaiting" },
    ];

    return (
      <thead>
        <tr style={{ textAlign: "center" }}>
          {this.props.collapsedHeader
            ? this.props.collapsedHeader.map((item) => (
              <th
                key={item.name}
                colSpan={item.collapse.length}
                style={
                  item.style
                    ? {
                        ...item.style,
                        textAlign: "center",
                        color: "black",
                        fontWeight: "bold",
                      }
                    : {
                        textAlign: "center",
                        color: "black",
                        fontWeight: "bold",
                      }
                }
              >
                {item.name}
              </th>
            ))
            : null}
        </tr>

        {!this.props.disableSearch && (
          <tr className="search-row">
            {this.props.columns.map((column) => (
              <th
                key={column.path || column.key}
                className={this.props.isSearch ? "searchOpen" : "searchClosed"}
              >
                {notSearchLabels.includes(column.label) ||
                notSearchKeys.includes(column.key) ? null : [
                    "createdAt",
                    "date",
                    "created_at",
                    "time",
                  ].includes(column.path) ? (
                        <DateRangeSelector
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          updateCurrentPage={this.props.updateCurrentPage}
                        />
                      ) : [ "From", "To" ].includes(column.label) ? (
                        <Search
                          type="datetime-local"
                          value={column.path}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          updateData={this.props.updateData}
                          updateCurrentPage={this.props.updateCurrentPage}
                        />
                      ) : [ "From date", "To date" ].includes(column.label) ? (
                        <DateSelector
                          value={column.path}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          updateCurrentPage={this.props.updateCurrentPage}
                        />
                      ) : column.key === "orderStatus" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={orderStatus}
                        />
                      ) : column.label === "Status" || column.label === "Enabled" ? (
                        <StatusSelector
                          name={column.label}
                          value={column.path || column.key}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          updateCurrentPage={this.props.updateCurrentPage}
                        />
                      ) : column.label === "Locked" || column.label === "Test" ? (
                        <BoolSelector
                          name={column.label}
                          value={column.path || column.key}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          updateCurrentPage={this.props.updateCurrentPage}
                        />
                      ) : column.key === "statementPaymentStatus" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={statementPaymentStatus}
                        />
                      ) : column.label === "Active" ? (
                        <ActiveSelector
                          name={column.label}
                          value={column.path || column.key}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          updateCurrentPage={this.props.updateCurrentPage}
                        />
                      ) : column.key === "typeRole" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          lowerCase={true}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={optionsTypesRole}
                        />
                      ) : column.key === "orderTransactionType" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={optionsOrdersTypesTransaction}
                        />
                      ) : column.key === "transactionType" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={optionsTypesTransaction}
                        />
                      ) : column.key === "typeBlacklist" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={optionsTypesBlacklist}
                        />
                      ) : column.key === "typeBlacklistRules" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={optionsTypesBlacklistRules}
                        />
                      ) : column.key === "action" ? (
                        <ActionSelector
                          value={column.path || column.key}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={optionsActions}
                        />
                      ) : column.key === "currency" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          optionName="code"
                          searchData={this.props.searchData}
                          getOptions={this.props.getAllCurrencies}
                          options={this.props.currencies || optionsCurrencies}
                          abilityName="CURRENCIES"
                        />
                      ) : column.key === "paymentStatus" ? (
                        <MultiSelectorContainer
                          value={column.path}
                          lowerCase={true}
                          upperCase={true}
                          search={this.props.search}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          options={paymentStatus}
                        />
                      ) : column.key === "gateways" ? (
                        <MultiSelectorContainer
                          value={column.path || column.key}
                          search={this.props.search}
                          upperCase={false}
                          get={this.props.get}
                          searchData={this.props.searchData}
                          getOptions={this.props.getAllGateways}
                          options={this.props.gateways}
                          abilityName="GATEWAYS"
                        />
                      ) : column.key === "terminal" &&
                  ability.can("READ", "SHOPTERMINALS") ? (
                            <MultiSelectorContainer
                              value={"terminals_array"}
                              upperCase={false}
                              search={this.props.search}
                              get={this.props.get}
                              searchData={this.props.searchData}
                              getOptions={this.props.getTerminalsAction}
                              options={this.props.terminals || []}
                              abilityName="SHOPTERMINALS"
                            />
                          ) : column.key === "shop" && ability.can("READ", "SHOPS") ? (
                            <MultiSelectorContainer
                              value={"shops_array"}
                              upperCase={false}
                              search={this.props.search}
                              get={this.props.get}
                              searchData={this.props.searchData}
                              getOptions={this.props.getAllShops}
                              options={this.props.shops || []}
                              abilityName="SHOPS"
                            />
                          ) : column.key === "merchant" &&
                  ability.can("READ", "USERMERCHANT") ? (
                                <MultiSelectorContainer
                                  value={"merchants_array"}
                                  upperCase={false}
                                  search={this.props.search}
                                  get={this.props.get}
                                  searchData={this.props.searchData}
                                  getOptions={this.props.getAllMerchants}
                                  options={this.props.merchants || []}
                                  optionName="merchant_name"
                                  abilityName="USERMERCHANT"
                                />
                              ) : column.key === "amount" ? (
                                <NumberSearch
                                  value={"amount"}
                                  upperCase={false}
                                  search={this.props.search}
                                  get={this.props.get}
                                  searchData={this.props.searchData}
                                  updateData={this.props.updateData}
                                  updateCurrentPage={this.props.updateCurrentPage}
                                />
                              ) : (
                                <Search
                                  value={column.path || column.key}
                                  search={this.props.search}
                                  get={this.props.get}
                                  searchData={this.props.searchData}
                                  updateData={this.props.updateData}
                                  updateCurrentPage={this.props.updateCurrentPage}
                                />
                              )}
              </th>
            ))}
          </tr>
        )}

        <tr className="title-row">
          {this.props.columns.map((column) => (
            <th
              key={column.path || column.key}
              className="table-header-title"
              style={column.align ? { textAlign: column.align } : {}}
            >
              {this.props.disableSort ||
              notSortLabels.includes(column.label) ||
              notSortKeys.includes(column.key) ? (
                    column.label
                  ) : (
                    <span
                      onClick={() => this.props.onSort(column.path)}
                      className="button-inline"
                    >
                      {column.label}
                    </span>
                  )}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSearch: state.search.isSearch,
    gateways: state.gateways.gatewaysList,
    currencies: state.currencies.currenciesList,
    terminals: state.terminals.terminals,
    shops: state.shops.shopsList,
    merchants: state.merchants.merchantsList,
  };
};

export default connect(mapStateToProps, {
  inverseSearch,
  getAllGateways,
  getAllCurrencies,
  getTerminalsAction,
  getAllShops,
  getAllMerchants,
})(TableHeader);

TableHeader.propTypes = {
  collapsedHeader: PropTypes.array,
  columns: PropTypes.array,
  currencies: PropTypes.array,
  disableSearch: PropTypes.bool,
  disableSort: PropTypes.bool,
  gateways: PropTypes.array,
  get: PropTypes.func,
  getAllCurrencies: PropTypes.func,
  getAllGateways: PropTypes.func,
  isSearch: PropTypes.bool,
  onSort: PropTypes.func,
  search: PropTypes.func,
  searchData: PropTypes.object,
  updateCurrentPage: PropTypes.func,
  updateData: PropTypes.func,
};
