import React from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const Select = ({ name, label, options, error, formValidation, ...rest }) => {
  return (
    <Col className="col">
      <Form.Label>{label}</Form.Label>
      <Form.Group validationState={formValidation(error)}>
        <Form.Control {...rest} name={name} id={name} componentClass="select">
          <option value="">{options.name}</option>
          {options.map((option) => (
            <option key={option.guid} value={option.guid}>
              {option.name}
            </option>
          ))}
        </Form.Control>
        {error && <span className="validate-error">{error}</span>}
      </Form.Group>
    </Col>
  );
};

export default Select;

Select.propTypes = {
  error: PropTypes.string,
  formValidation: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.node,
  options: PropTypes.array,
};
