import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export default function CustomModal({
  button,
  header,
  content: Content,
  contentProps,
  dialogClassName = "",
}) {
  const [ show, setShow ] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <span onClick={() => handleShow()}>{button}</span>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName={dialogClassName ? dialogClassName : ""}
        >
          <Modal.Header closeButton>
            <Modal.Title>{header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Content handleClose={handleClose} {...contentProps} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
