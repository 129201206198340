import { backendManagement, getRoles } from "../backendPlatform";

const roles = getRoles()[0];
const roleGuid = roles.guid ? roles.guid : "";

// roles.role === "admin" ? merchantGuid : roleGuid

export const getAccounts = (guid, args) => {
  return backendManagement.get(`/merchants/${guid}/accounts`, { params: { ...args } });
};

export const getAccountsForMerchant = (args) => {
  return backendManagement.get(`/merchants/${roleGuid}/accounts`, { params: { ...args } });
};

export const createAccount = (guid, data) => {
  return backendManagement.post(`/merchants/${guid}/accounts`, data);
};

export const createAccountForMerchant = (data) => {
  return backendManagement.post(`/merchants/${roleGuid}/accounts`, data);
};

export const deleteAccount = (merchantGuid, guid, reason) => {
  return backendManagement.post(`/merchants/${merchantGuid}/accounts`, { guid, delete: true, reason });
};

export const deleteAccountForMerchant = (guid) => {
  return backendManagement.post(`/merchants/${roleGuid}/accounts`, { guid, delete: true });
};