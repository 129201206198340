import BlacklistCreator from "components/BlackList/Rules/OldCreator";
import AdvancedBlacklistCreator from "components/BlackList/Rules/Creator";
import BlackListMain from "components/BlackList/Rules/Main";
// import Can from "components/Can";
import Modal from "components/UI/Modal";
import React from "react";
import Content from "../../Content";
import ability from "config/ability";
import { Row } from "react-bootstrap";

const BlackList = () => {
  return (
    <Content>
      <BlackListMain
        modalComponent={
          <Row allowed={ability.can("EXECUTE", "BLACKLISTS")}>
            <Modal
              header="Create rule"
              content={BlacklistCreator}
              button={
                <button type="button" className="btn btn-fill btn-primary">
                  Create rule
                </button>
              }
            />
            <Modal
              header="Create rule"
              content={AdvancedBlacklistCreator}
              button={
                <button type="button" className="btn btn-fill btn-primary">
                  Advanced Create rule
                </button>
              }
            />
          </Row>
        }
      />
    </Content>
  );
};

export default BlackList;
