import React from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import { LineChart } from "components/UI/LineChart";
import { borderColors, backgroundColors } from "constants/charts/colors";
import PropTypes from "prop-types";
import { getDailyTransactionAction } from "actions/transactions";
import Spinner from "components/UI/Spinner";

class LineChartTransactionsHistory extends React.Component {
  state = {
    daysCount: 7,
    loading: true,
  };

  componentDidMount = () => {
    this.props.getDailyTransactionAction();
  };

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.data !== this.props.data)
      this.props.getDailyTransactionAction(nextProps.data);
    return true;
  };

  render() {
    const labels = [
      "1:00",
      "2:00",
      "3:00",
      "4:00",
      "5:00",
      "6:00",
      "7:00",
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
      "00:00",
    ];
    let datasetsLabels = this.props.transactionHistory.length === 0 ? [] : {};
    let data = {};
    this.props.transactionHistory.forEach((transaction) => {
      datasetsLabels[transaction.type] = "";
    });
    datasetsLabels = Object.keys(datasetsLabels);
    datasetsLabels.forEach((item) => {
      data[item] = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
    });
    this.props.transactionHistory.forEach((transaction) => {
      if (transaction.time === 0 || transaction.time === "0")
        data[transaction.type][0] += transaction.success;
      else data[transaction.type][transaction.time - 1] += transaction.success;
    });
    let datasetsPie = datasetsLabels.map((item, index) => {
      return {
        label: item,
        fill: false,
        lineTension: 0.1,
        backgroundColor: backgroundColors[index],
        borderColor: borderColors[index],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: borderColors[index],
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: borderColors[index],
        pointHoverBorderColor: "black",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data[item],
      };
    });

    return (
      <div style={{ padding: 30 }}>
        <div className="header">Transactions</div>
        {this.props.loading ? (
          <Spinner />
        ) : (
          <Row>
            <LineChart
              labels={labels}
              datasets={datasetsPie}
              name="Transactions"
              options={{
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactionHistory: state.transactions.dailyTransaction,
    loading: state.transactions.dailyTransactionLoading,
  };
};

export default connect(mapStateToProps, {
  getDailyTransactionAction,
})(LineChartTransactionsHistory);

LineChartTransactionsHistory.propTypes = {
  data: PropTypes.object,
  getDailyTransactionAction: PropTypes.func,
  loading: PropTypes.bool,
  transactionHistory: PropTypes.array,
};
