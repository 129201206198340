import React, { Component } from "react";
import { connect } from "react-redux";
import { getCurrencyAction, editCurrencyAction } from "actions/currencies";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import { Col, Row, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";
import Alert from "../UI/Alert";

class CurrencyEditor extends Component {
  state = {
    name: "",
    code: "",
    number: "",
    rate: "",
    exchange_markup_value: "",
    isLoading: false,
    errors: {},
    needReason: false,
    isFlat: false,
    reason: "",

    oldData: {
      name: "",
      code: "",
      number: "",
      rate: "",
      exchange_markup_value: "",
      isFlat: false,
    },

    nameValidation: false,
    codeValidation: false,
    numberValidation: false,
    rateValidation: false,
  };

  schema = {
    name: Joi.string().required().min(0).max(100).label("Name"),
    code: Joi.string()
      .required()
      .min(0)
      .max(100)
      .regex(/^[A-Z]{3}$/)
      .error(() => {
        return {
          message: "Code must be a valid.",
        };
      })
      .label("Code"),
    number: Joi.string()
      .required()
      .regex(/^[0-9]{3}$/)
      .error(() => {
        return {
          message: "Number must be a valid.",
        };
      })
      .label("Number"),
    rate: Joi.number().required().label("Rate"),
    exchange_markup_value: Joi.number()
      .required()
      .min(0)
      .error(() => {
        return {
          message: "Exchange markup percent must be a valid.",
        };
      })
      .label("Exchange markup percent"),
    isFlat: Joi.boolean().required().label("Type"),
  };

  async componentDidMount() {
    await this.props.getCurrencyAction(this.props.guid);
    const currency = this.props.currency;
    this.setState({
      name: currency.name,
      code: currency.code,
      number: currency.number,
      rate: currency.rate_to_eur,
      exchange_markup_value: currency.exchange_markup_value,
      isFlat: currency.isFlat,

      oldData: {
        name: currency.name,
        code: currency.code,
        number: currency.number,
        rate: currency.rate_to_eur.toString(),
        exchange_markup_value: currency.exchange_markup_value,
        isFlat: currency.isFlat,
      },

      nameValidation: currency.name ? true : false,
      codeValidation: currency.code ? true : false,
      numberValidation: currency.number ? true : false,
      rateValidation: currency.rate_to_eur ? true : false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = {
        name: this.state.name,
        code: this.state.code,
        number: this.state.number,
        rate: this.state.rate,
        exchange_markup_value: this.state.exchange_markup_value,
        isFlat: this.state.isFlat,
        reason: this.state.reason,
      };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = {
        name: this.state.name,
        code: this.state.code,
        number: this.state.number,
        rate: this.state.rate,
        exchange_markup_value: this.state.exchange_markup_value,
        isFlat: this.state.isFlat,
      };
      schema = this.schema;

      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = { ...this.state };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState(
      {
        [input.name]: input.value,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleChangeSwitch = () => {
    this.setState(
      {
        isFlat: !this.state.isFlat,
      },
      () => this.showReason()
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });
          await this.props.editCurrencyAction({
            guid: this.props.guid,
            name: this.state.name,
            code: this.state.code,
            number: this.state.number,
            rate_to_eur: this.state.rate,
            exchange_markup_value: this.state.exchange_markup_value,
            isFlat: this.state.isFlat,
            reason: this.state.needReason ? this.state.reason : undefined,
          });
          swal({
            title: "Currency is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;
    if (this.props.isLoading) return <Spinner />;
    else
      return (
        <React.Fragment>
          <Form autoComplete="off">
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Name* </Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("name")}>
                  <Form.Control
                    name="name"
                    type="string"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  {errors.name && (
                    <span className="validate-error">{errors.name}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Code* </Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("code")}>
                  <Form.Control
                    name="code"
                    type="string"
                    value={this.state.code}
                    onChange={this.handleChange}
                  />
                  {errors.code && (
                    <span className="validate-error">{errors.code}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Number* </Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("number")}>
                  <Form.Control
                    name="number"
                    type="string"
                    value={this.state.number}
                    onChange={this.handleChange}
                  />
                  {errors.number && (
                    <span className="validate-error">{errors.number}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Exchange markup percent* </Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group
                  validationState={this.formValidation("exchange_markup_value")}
                >
                  <Form.Control
                    name="exchange_markup_value"
                    type="string"
                    value={this.state.exchange_markup_value}
                    onChange={this.handleChange}
                  />
                  {errors.exchange_markup_value && (
                    <span className="validate-error">
                      {errors.exchange_markup_value}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Markup type</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  onChange={this.handleChangeSwitch}
                  checked={this.state.isFlat}
                  label={this.state.isFlat ? "Flat" : "Percent"}
                />
                {errors.isFlat && (
                  <span className="validate-error">{errors.isFlat}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Rate* </Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group validationState={this.formValidation("rate")}>
                  <Form.Control
                    name="rate"
                    type="string"
                    value={this.state.rate}
                    onChange={this.handleChange}
                  />
                  {errors.rate && (
                    <span className="validate-error">{errors.rate}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {this.state.needReason && (
              <Row>
                <Col md={3} sm={4} xs={4} className="form-label">
                  <Form.Label>Reason</Form.Label>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      placeholder="Enter reason"
                      name="reason"
                      value={this.state.reason}
                      onChange={this.handleChangeReason}
                    />
                    {errors.reason && (
                      <span className="validate-error">{errors.reason}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Form>
          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.nameValidation &&
                  this.state.codeValidation &&
                  this.state.numberValidation &&
                  this.state.rateValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </React.Fragment>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state.currencies.currency,
    isLoading: state.currencies.currencyLoading,
    error: state.currencies.error,
  };
};

export default connect(mapStateToProps, {
  getCurrencyAction,
  editCurrencyAction,
  showModal,
})(CurrencyEditor);

CurrencyEditor.propTypes = {
  currency: PropTypes.object,
  editCurrencyAction: PropTypes.func,
  getCurrencyAction: PropTypes.func,
  guid: PropTypes.string,
  isLoading: PropTypes.bool,
  showModal: PropTypes.func,
};
