import { reset, searchInTransactions } from "actions/search";
import { getAllTransactions } from "actions/transactions";
import Modal from "components/UI/Modal";
import ability from "config/ability";
import AbstractComponent from "factories/Main";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { resetPage, setNewTable } from "../../../actions/store";
import { createColComponentForTable } from "../../../helpers/createColComponentForTable";
import { cutGuid } from "../../../helpers/cutGuid";
import { getTransactions } from "../../../services/paymentBackendAPI/management/transactions";
import ActiveTransactionModal from "./ActiveTransactionModal";
import TransactionEditor from "./Editor";
import RatesDetail from "./ratesDetail";
import { Copy } from "../../UI/CopyToClipboard";

const columns = [
  {
    path: "guid",
    label: "ID",
    key: "guid",
    content: (transaction) => (
      <>
        <Copy text={transaction.guid} />
        <Link className="link" to={`/about/processing/${transaction.guid}`}>
          {cutGuid(transaction.guid)}
        </Link>
      </>
    ),
    textContent: (transaction) => transaction.guid,
    colWidth: 9,
    style: { minWidth: "120px" },
  },
  {
    path: "transaction_type",
    label: "Type",
    key: "transactionType",
    colWidth: 10,
  },
  {
    path: "status",
    key: "status",
    label: "Status",
    content: (transaction) => (
      <i
        className={
          transaction.status === "Success"
            ? "icon-success icon green"
            : transaction.status === "Failed"
              ? "icon-failed icon red"
              : "far fa-pause-circle icon orange"
        }
      />
    ),
    textContent: (transaction) => transaction.status,
    align: "center",
    colWidth: 4,
  },
  {
    path: "active",
    key: "active",
    label: "Active",
    content: (transaction) => (
      <i
        className={
          transaction.active
            ? "icon-success icon green"
            : "icon-failed icon red"
        }
      />
    ),
    textContent: (transaction) => (transaction.active ? "Yes" : "No"),
    align: "center",
    colWidth: 4,
  },
  { path: "amount", key: "amount", label: "Amount", align: "right" },
  {
    path: "currency",
    label: "Currency",
    key: "currency",
    align: "center",
    colWidth: 8,
  },
  { path: "shop_name", key: "shop", label: "Shop" },
  { path: "terminal_name", key: "terminal", label: "Terminal" },
  {
    path: "created_at",
    label: "Created at",
    content: (transaction) =>
      moment(transaction.created_at)
        .utcOffset(0)
        .format("DD.MM.YYYY  |  HH:mm"),
    align: "center",
    colWidth: 12,
  },
  ability.can("READ", "TRANSACTIONSPROCESSINGRATES") && {
    label: "Rates",
    key: "rates",
    align: "center",
    content: (transaction) => (
      <Modal
        content={RatesDetail}
        contentProps={{ guid: transaction.guid }}
        buttonName="Show"
        button={
          <button type="button" className="btn btn-table">
            Show
          </button>
        }
      />
    ),
    colWidth: 5,
  },
  ability.can("EXECUTE", "TRANSACTIONSPROCESSING") && {
    key: "edit",
    content: (transaction) => (
      <Modal
        header="Edit transaction"
        button={
          <i className="icon-edit icon gray" style={{ cursor: "pointer" }} />
        }
        content={TransactionEditor}
        contentProps={{ data: transaction, type: "transaction" }}
        dialogClassName="modal-creator"
      />
    ),
    label: "Edit",
    align: "center",
    colWidth: 4,
  },
  ability.can("EXECUTE", "TRANSACTIONSPROCESSINGACTIVE") && {
    key: "deactivate",
    align: "center",
    content: (transaction) => (
      <Modal
        content={ActiveTransactionModal}
        contentProps={{ guid: transaction.guid, active: transaction.active }}
        buttonName={transaction.active ? "deactivate" : "activate"}
        button={
          <button type="button" className="btn btn-table btn-danger">
            {transaction.active ? "Deactivate" : "Activate"}
          </button>
        }
      />
    ),
    label: "Deactivate",
    colWidth: 7,
  },
];

const searchColumns = [
  {
    key: "first_name",
    label: "First Name"
  },
  {
    key: "last_name",
    label: "Last Name"
  },
  {
    key: "card_number",
    label: "Card Number"
  },
  {
    key: "bank_id",
    label:"Bank Id"
  },
  {
    key: "arn",
    label: "ARN"
  },
  {
    key: "ip",
    label: "IP"
  }
];

const mapStateToProps = (state) => {
  return {
    data: state.transactions.transactionsList,
    count: state.transactions.count,
    table: state.store.table,
    loading: state.transactions.loading,
    searchData: state.search.transactionsSearch,
    isSearch: state.search.isSearch,
    name: "transaction history",
    keyPath: "guid",
    columns,
    searchColumns,
    columnsComponent: createColComponentForTable(columns),
    notAllSizePage: true,
    exportFunction: getTransactions,
  };
};

export default connect(mapStateToProps, {
  get: getAllTransactions,
  search: searchInTransactions,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
