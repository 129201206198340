import { backendAuth, setTokens, flushTokens } from "../backendPlatform";

const login = async data => {
  const response = await backendAuth.post("/login", data);
  if(response.data.accessToken && response.data.refreshToken)
    await setTokens(response.data.accessToken, response.data.refreshToken, response.data.credentials_expired, response.data.first_time_login, response.data.username);
  return response;
};

const logout = async () => {
  await backendAuth.post("/logout");
  flushTokens();
  return Promise.resolve();
};

const  getEmailMessage = async (data) => {
  return await backendAuth.post("/forgot", data);
};

const checkRecoveryToken = async (data) => {
  return await backendAuth.post("/check/recovery", data);
};

const setNewPassword = async (data) => {
  return await backendAuth.post("/recovery", data);
};

const validate = () => backendAuth.get("/validate");

export default { login, logout, validate, getEmailMessage, checkRecoveryToken, setNewPassword };