import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import {
  getShopGatewayPropsAction,
  editShopGatewayPropsAction,
} from "actions/shops";
import { showModal } from "actions/modal";
import { parseResponse } from "helpers/parseResponse";
import { getAuthData } from "services/paymentBackendAPI/backendPlatform";
import PropTypes from "prop-types";
import Loading from "components/UI/Spinner/index";
import swal from "sweetalert";
import ability from "config/ability";
import Alert from "../../UI/Alert";

class GatewayPropsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gatewayProps: [],
      changedGatewayProps: [],
      gatewayPropsValidation: [],
      files: "",
    };
  }

  async componentDidMount() {
    const token = getAuthData();
    if (token.userPayload.merchant) this.setState({ isMerchant: true });
    await this.props.getShopGatewayPropsAction(
      this.props.terminalGuid,
      this.props.gatewayGuid
    );
    const gatewayProps = JSON.parse(JSON.stringify(this.props.gatewayProps));
    const changedGatewayProps = JSON.parse(
      JSON.stringify(this.props.gatewayProps)
    );
    const gatewayPropsValidation = changedGatewayProps.map((prop) => {
      return { value: prop.value ? true : false, name: prop.name };
    });
    this.setState({
      gatewayProps,
      changedGatewayProps,
      gatewayPropsValidation,
    });
  }

  handleChange = (e) => {
    const property = e.target.name;
    const { changedGatewayProps, gatewayPropsValidation } = this.state;
    let index = changedGatewayProps.findIndex((prop) => {
      return prop.name === property;
    });
    changedGatewayProps[index].value = e.target.value;
    index = gatewayPropsValidation.findIndex((prop) => {
      return prop.name === property;
    });
    gatewayPropsValidation[index].value = e.target.value ? true : false;
    this.setState({ changedGatewayProps, gatewayPropsValidation });
  };

  handleChangeFile = (e) => {
    const property = e.target.name;
    const { changedGatewayProps, gatewayPropsValidation } = this.state;
    let index = changedGatewayProps.findIndex((prop) => {
      return prop.name === property;
    });
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = function () {
      changedGatewayProps[index].value = reader.result;
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    changedGatewayProps[index].value = "";

    index = gatewayPropsValidation.findIndex((prop) => {
      return prop.name === property;
    });
    gatewayPropsValidation[index].value = e.target.value ? true : false;
    this.setState({
      changedGatewayProps,
      gatewayPropsValidation,
      files: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (
      this.state.gatewayPropsValidation.some(
        (element) => element.value === false
      )
    ) {
      swal({
        title: "Please, enter information in every required field",
        icon: "warning",
      });
    } else {
      try {
        const { gatewayProps, changedGatewayProps } = this.state;
        let difference = [];
        gatewayProps.forEach((property, index) => {
          if (
            changedGatewayProps[index].value !== property.value &&
            changedGatewayProps[index].value === ""
          ) {
            let propToDelete = changedGatewayProps[index];
            propToDelete.delete = "true";
            difference.push(propToDelete);
          } else if (changedGatewayProps[index].value !== property.value)
            difference.push(changedGatewayProps[index]);
        });
        const { terminalGuid, gatewayGuid } = this.props;
        difference.forEach(async (property) => {
          await this.props.editShopGatewayPropsAction(
            terminalGuid,
            gatewayGuid,
            property
          );
        });
        swal({
          title: "Properties successfully updated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  getPropsLayout = () => {
    const changedGatewayProps = this.state.changedGatewayProps.filter(
      (property) => !property.delete
    );

    const layout = changedGatewayProps.map((prop) => {
      return (
        <Row key={prop.name}>
          <Col md={3} sm={4} xs={4} className="form-label">
            <Form.Label>{prop.label}:</Form.Label>
          </Col>
          {prop.bytea_flag && prop.value && (
            <Col md={3} sm={4} xs={4} className="form-label">
              <a
                href={prop.value}
                download="key.p12"
                style={{ textDecoration: "underline" }}
              >
                Download file
              </a>
            </Col>
          )}

          <Col md={8}>
            <Form.Group>
              <Form.Control
                name={prop.name}
                type={prop.bytea_flag ? "file" : "text"}
                accept={prop.bytea_flag ? ".p12" : undefined}
                value={prop.bytea_flag ? this.state.files : prop.value}
                onChange={(e) =>
                  prop.bytea_flag
                    ? this.handleChangeFile(e)
                    : this.handleChange(e)
                }
                readOnly={
                  ability.can("EXECUTE", "TERMINALGATEWAYPROPS") ? false : true
                }
              />
            </Form.Group>
          </Col>
        </Row>
      );
    });
    return layout;
  };

  render() {
    if (this.props.gatewayPropsLoading) return <Loading />;
    else if (this.props.gatewayProps.length === 0)
      return (
        <Row>
          <Col>
            <div style={{ textAlign: "center" }}>
              <label>Gateway has no configurable properties</label>
            </div>
          </Col>
        </Row>
      );
    else
      return (
        <Form autoComplete="off">
          {this.getPropsLayout()}
          <div>
            <Button
              className={
                !this.state.gatewayPropsValidation.some(
                  (element) => element.value === false
                )
                  ? "btn btn-fill btn-success"
                  : "btn btn-fill"
              }
              type="submit"
              onClick={this.handleSubmit}
              style={{ margin: "10px" }}
            >
              Save
            </Button>
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    gatewayPropsLoading: state.shops.gatewayPropsLoading,
    gatewayProps: state.shops.gatewayProps,
  };
};

export default connect(mapStateToProps, {
  showModal,
  getShopGatewayPropsAction,
  editShopGatewayPropsAction,
})(GatewayPropsEditor);

GatewayPropsEditor.propTypes = {
  editShopGatewayPropsAction: PropTypes.func,
  gatewayGuid: PropTypes.string,
  gatewayProps: PropTypes.array,
  gatewayPropsLoading: PropTypes.bool,
  getShopGatewayPropsAction: PropTypes.func,
  showModal: PropTypes.func,
  terminalGuid: PropTypes.string,
};
