import React from "react";
import Content from "../Content";
import TransactionTemplateMain from "components/Transaction/Template/Main";

const TransactionTemplate = () => {
  return (
    <Content>
      <TransactionTemplateMain />
    </Content>
  );
};

export default TransactionTemplate;
