import { getTransactionStepsAction } from "actions/transactions";
import Modal from "components/UI/Modal";
import Spinner from "components/UI/Spinner";
import Table from "components/UI/Table/index";
import { parseResponse } from "helpers/parseResponse";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Alert from "../../UI/Alert";
import Params from "../Step/Param/Main";

class TabsComponent extends Component {
  state = {
    currentStepsPage: 1,
    pageSize: 10,
    gateway_guid: "",
  };

  componentDidMount = async () => {
    try {
      await this.props.getTransactionStepsAction(
        this.props.template_guid,
        this.props.gateway_guid
      );
    } catch (error) {
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  columnsTransactionSteps = [
    { path: "guid", label: "ID" },
    { path: "number", label: "Number" },
    { path: "name", label: "Name" },
    {
      label: "Parameters",
      align: "center",
      content: (step) => (
        <Modal
          header={"Params " + step.name}
          content={Params}
          contentProps={{ guid: step.guid }}
          buttonName="Show"
          button={
            <button type="button" className="btn">
              Show
            </button>
          }
        />
      ),
    },
  ];

  render() {
    const transactionSteps = this.props.transactionSteps
      ? this.props.transactionSteps.length
        ? this.props.transactionSteps.filter(
          (steps) => steps.guid === this.props.gateway_guid
        )
        : []
      : [];
    return this.props.loading ? (
      <Spinner />
    ) : (
      <div>
        <Table
          columns={this.columnsTransactionSteps}
          data={transactionSteps.length ? transactionSteps[0].data : []}
          disableSearch
          disableSort
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactionSteps: state.transactions.transactionSteps,
    stepsCount: state.transactions.stepsCount,
    loading: state.transactions.transactionStepsLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionStepsAction,
})(TabsComponent);

TabsComponent.propTypes = {
  gateway_guid: PropTypes.string,
  getTransactionStepsAction: PropTypes.func,
  loading: PropTypes.bool,
  template_guid: PropTypes.string,
  transactionSteps: PropTypes.array,
};
