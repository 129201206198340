// import Can from "components/Can";
import AdditionalFeeCreator from "components/AdditionalFee/Creator";
import AdditionalFeeMain from "components/AdditionalFee/Main";
import Modal from "components/UI/Modal";
import React from "react";
import Content from "../Content";
import ability from "config/ability";

const AdditionalFee = () => {
  return (
    <Content>
      <AdditionalFeeMain
        modalComponent={
          // <Can do="EXECUTE" on="CURRENCIES">
          <Modal
            allowed={ability.can("EXECUTE", "CURRENCIES")}
            header="Create currency"
            content={AdditionalFeeCreator}
            button={
              <button type="button" className="btn btn-fill btn-primary">
                Create additional fee
              </button>
            }
          />
          // </Can>
        }
      />
    </Content>
  );
};

export default AdditionalFee;
