import { getAllCurrencies } from "actions/currencies";
import { getAllMerchants } from "actions/merchants";
import { getTransactionAndCommissionReportAction } from "actions/transactions";
import Table from "components/UI/Table/index";
import { parseResponse } from "helpers/parseResponse";
import Joi from "joi-browser";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { exportTransactionAndCommissionReport } from "services/paymentBackendAPI/reports/transactions";
import { ExportButton } from "../../ExportButton";
import Alert from "../../UI/Alert";
import ReportForm from "../ReportForm";

class TransactionAndCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        merchant_guid_array: [],
        group_guid_array: [],
        partner_guid_array: [],
        terminal_guid_array: [],
        shop_guid_array: [],
        from_date: moment()
          .format("YYYY-MM-DDTHH:mm:ss")
          .replace(/\D\d{2}\w/, "-01T"),
        to_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      },
      transactionAndCommission: { fees: {}, summary: [] },

      summary: [],
      currencyFees: {},
      summaryFees: [],
      viewProcessed: false,
    };
  }

  schema = {
    merchant_guid: Joi.string().required().label("Merchant"),
    to_date: Joi.string().required().label("To date"),
    from_date: Joi.string().required().label("From date"),
  };

  summaryColumns = [
    {
      path: "date",
      label: "TRX date",
      content: (line) =>
        line.date === "Summary"
          ? line.date
          : moment(line.date).format("DD.MM.YYYY"),
    },
    { path: "currency_code", label: "Currency" },
    { path: "success_turnover_amount", label: "Success payment sum" },
    { path: "failed_turnover_amount", label: "Failed payment sum" },
    { path: "refund_amount", label: "Refund sum" },
    { path: "chargeback_amount", label: "Chargeback sum" },
    { path: "chargeback_reversal_amount", label: "Chargeback reversal sum" },
    { path: "prearbitration_amount", label: "Prearbitration sum" },
    { path: "representment_amount", label: "Representment sum" },
    { path: "retrieval_amount", label: "Retrieval sum" },
    { path: "reverse_amount", label: "Reverse sum" },
  ];

  processorColumns = [
    {
      path: "date_time",
      label: "TRX date",
      content: (line) =>
        line.date_time === "Summary"
          ? line.date_time
          : moment(line.date_time).format("DD.MM.YYYY"),
    },
    {
      path: "success_turnover_merchant_fixed_fee",
      label: "Success turnover fee sum (fixed)",
    },
    {
      path: "success_turnover_merchant_percent_fee",
      label: "Success turnover fee sum (%)",
    },
    { path: "refund_merchant_fee", label: "Refund fee sum" },
    { path: "failed_turnover_merchant_fee", label: "Decline fee sum" },
    { path: "chargeback_merchant_fee", label: "Chargeback fee sum" },
    { path: "monthly_fee", label: "Monthly fee sum" },
    { path: "bank_wire_fee", label: "BankWire Fee sum" },
    { path: "connection_fee", label: "Connection Fee sum" },
  ];

  bankColumnsForCurrency = [
    {
      path: "date_time",
      label: "TRX date",
      content: (line) =>
        line.date_time === "Summary"
          ? line.date_time
          : moment(line.date_time).format("DD.MM.YYYY"),
    },
    {
      path: "success_turnover_bank_fixed_fee",
      label: "Success turnover fee sum (fixed)",
    },
    {
      path: "success_turnover_bank_percent_fee",
      label: "Success turnover fee sum (%)",
    },
    { path: "refund_bank_fee", label: "Refund fee sum" },
    { path: "failed_turnover_bank_fee", label: "Decline fee sum" },
    { path: "chargeback_bank_fee", label: "Chargeback fee sum" },
    { label: "Monthly fee sum", content: () => 0 },
    { path: "bank_wire_fee", label: "BankWire Fee sum" },
    { path: "connection_fee", label: "Connection Fee sum" },
  ];

  bankColumns = [
    {
      path: "date_time",
      label: "TRX date",
      content: (line) =>
        line.date_time === "Summary"
          ? line.date_time
          : moment(line.date_time).format("DD.MM.YYYY"),
    },
    {
      path: "success_turnover_bank_fixed_fee",
      label: "Success turnover fee sum (fixed)",
    },
    {
      path: "success_turnover_bank_percent_fee",
      label: "Success turnover fee sum (%)",
    },
    { path: "refund_bank_fee", label: "Refund fee sum" },
    { path: "failed_turnover_bank_fee", label: "Decline fee sum" },
    { path: "chargeback_bank_fee", label: "Chargeback fee sum" },
    { path: "monthly_fee", label: "Monthly fee sum" },
    { path: "bank_wire_fee", label: "BankWire Fee sum" },
    { path: "connection_fee", label: "Connection Fee sum" },
  ];

  handleCheckbox = () => {
    this.setState({
      viewProcessed: !this.state.viewProcessed,
    });
    this.onSubmitCalculate(this.state.data);
  };

  onSubmitCalculate = async (data) => {
    this.setState({ isLoading: true, data: { ...data } });
    try {
      await this.props.getTransactionAndCommissionReportAction({
        ...data,
        show_empty_rows: this.state.viewProcessed,
      });

      let summary = this.props.transactionAndCommission.summary;
      this.setState({
        transactionAndCommission: this.props.transactionAndCommission,
        summary: summary,
        // summaryFees: this.props.transactionAndCommission.summary_fee,
        isLoading: false,
      });

      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      const parsedError = parseResponse(error);
      Alert({ type: "error", message: parsedError.message });
    }
  };

  export = async (data) => {
    this.setState({ exportLoading: true });
    let res = await exportTransactionAndCommissionReport(data);
    let a = document.createElement("a");
    const file = new Blob([ res.data ], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    a.href = URL.createObjectURL(file);
    a.download =
      data.merchant_guid_array.map((m) => m.merchant_guid + "_") +
      moment(this.state.from_date).format("DD_MM_YYYY") +
      "_" +
      moment(this.state.to_date).format("DD_MM_YYYY") +
      ".xlsx";
    a.click();
    this.setState({ exportLoading: false });
  };

  render() {
    return (
      <React.Fragment>
        <ReportForm
          handleSubmit={this.onSubmitCalculate}
          button="Full report to XLS"
          onButtonClick={this.export}
        />
        <div className="card" style={{ marginTop: "15px" }}>
          <div className="card-body">
            <Row>
              <Col md={9} sm={9} xs={9}></Col>
              <Col md={3} sm={3} xs={3}>
                <div style={{ float: "right", margin: "15px" }}>
                  <input
                    type="checkbox"
                    id="enabledCheckbox"
                    checked={this.state.viewProcessed}
                    onChange={this.handleCheckbox}
                  />
                  View processed (Show empty rows)
                </div>
              </Col>
            </Row>
            {/*
            <Row>
              <Col md={9} sm={9} xs={9}></Col>
              <Col md={3} sm={3} xs={3}>
                <Button
                  onClick={this.export}
                  style={{ margin: "15px", float: "right" }}
                >
                  Full report to XLS
                </Button>
              </Col>
            </Row> */}

            <Row>
              <Col md={8} sm={8} xs={8}>
                <div className="header">Summary</div>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <ExportButton
                  name="Summary"
                  columns={this.summaryColumns}
                  data={this.state.summary}
                  style={{ float: "right" }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12} xs={12}>
                {this.state.isLoading ? (
                  <div>
                    <ReactLoading type="cylon" color="grey" />
                  </div>
                ) : (
                  <Table
                    columns={this.summaryColumns}
                    data={this.state.summary}
                    keyPath="transaction_processing_guid"
                    disableSearch
                    disableSort
                    collapse={true}
                  />
                )}
              </Col>
            </Row>

            {this.state.transactionAndCommission.merchantFees &&
              this.state.transactionAndCommission.merchantFees.map((data) => (
                <>
                  <Row>
                    <Col md={8} sm={8} xs={8}>
                      <div className="header">
                        TBF with customer ({data.currency})
                      </div>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                      <ExportButton
                        name={`TBF with customer (${data.currency})`}
                        columns={this.processorColumns}
                        data={data.collapsed}
                        style={{ float: "right" }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      {this.state.isLoading ? (
                        <div>
                          <ReactLoading type="cylon" color="grey" />
                        </div>
                      ) : (
                        <Table
                          columns={this.processorColumns}
                          data={data.collapsed}
                          keyPath="transaction_processing_guid"
                          disableSearch
                          disableSort
                        />
                      )}
                    </Col>
                  </Row>
                </>
              ))}

            <Row>
              <Col md={8} sm={8} xs={8}>
                <div className="header">TBF with customer (Summary)</div>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <ExportButton
                  name="TBF with customer (Summary)"
                  columns={this.processorColumns}
                  data={this.state.transactionAndCommission.merchantFeesSumm}
                  style={{ float: "right" }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12} xs={12}>
                {this.state.isLoading ? (
                  <div>
                    <ReactLoading type="cylon" color="grey" />
                  </div>
                ) : (
                  <Table
                    columns={this.processorColumns}
                    data={this.state.transactionAndCommission.merchantFeesSumm}
                    keyPath="transaction_processing_guid"
                    disableSearch
                    disableSort
                    collapse={true}
                  />
                )}
              </Col>
            </Row>

            {this.state.transactionAndCommission.bankFees &&
              this.state.transactionAndCommission.bankFees.map((data) => (
                <>
                  <Row>
                    <Col md={8} sm={8} xs={8}>
                      <div className="header">
                        Decta with TBF ({data.currency})
                      </div>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                      <ExportButton
                        name={`Decta with TBF (${data})`}
                        columns={this.bankColumnsForCurrency}
                        data={data.collapsed}
                        style={{ float: "right" }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      {this.state.isLoading ? (
                        <div>
                          <ReactLoading type="cylon" color="grey" />
                        </div>
                      ) : (
                        <Table
                          columns={this.bankColumnsForCurrency}
                          data={data.collapsed}
                          keyPath="transaction_processing_guid"
                          disableSearch
                          disableSort
                        />
                      )}
                    </Col>
                  </Row>
                </>
              ))}

            <Row>
              <Col md={8} sm={8} xs={8}>
                <div className="header">Decta with TBF (Summary)</div>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <ExportButton
                  name={"Decta with TBF (Summary)"}
                  columns={this.bankColumns}
                  data={this.state.transactionAndCommission.bankFeesSumm}
                  style={{ float: "right" }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12} xs={12}>
                {this.state.isLoading ? (
                  <div>
                    <ReactLoading type="cylon" color="grey" />
                  </div>
                ) : (
                  <Table
                    columns={this.bankColumns}
                    data={this.state.transactionAndCommission.bankFeesSumm}
                    keyPath="transaction_processing_guid"
                    disableSearch
                    disableSort
                    collapse={true}
                  />
                )}
              </Col>
            </Row>
            {/*
            <Row>
              <Col md={12} sm={12} xs={12}>
                <div className="header">TBF with customer</div>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12} xs={12}>
                {this.state.isLoading ?
                  <div><ReactLoading type='cylon' color='grey' /></div>
                  :
                  <Table
                    columns={this.processorColumns}
                    data={this.state.transactionAndCommission}
                    keyPath="transaction_processing_guid"
                    disableSearch
                    disableSort
                  />}
              </Col>
            </Row>


            <Row>
              <Col md={12} sm={12} xs={12}>
                <div className="header">Decta with TBF</div>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12} xs={12}>
                {this.state.isLoading ?
                  <div><ReactLoading type='cylon' color='grey' /></div>
                  :
                  <Table
                    columns={this.bankColumns}
                    data={this.state.transactionAndCommission}
                    keyPath="transaction_processing_guid"
                    disableSearch
                    disableSort
                  />}
              </Col>
            </Row>
 */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchantsList,
    count: state.statements.count,
    currencies: state.currencies.currenciesList,
    transactionAndCommission: state.transactions.transactionAndCommission,
  };
};

export default connect(mapStateToProps, {
  getAllMerchants,
  getAllCurrencies,
  getTransactionAndCommissionReportAction,
})(TransactionAndCommission);

TransactionAndCommission.propTypes = {
  count: PropTypes.string,
  currencies: PropTypes.array,
  getAllCurrencies: PropTypes.func,
  getAllMerchants: PropTypes.func,
  getTransactionAndCommissionReportAction: PropTypes.func,
  merchants: PropTypes.array,
  transactionAndCommission: PropTypes.object,
};
