import moment from "moment";
import React from "react";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { getAuditTransactionProcessingTablesAction } from "../../../../actions/audit";
import {
  reset,
  searchInTransactionProcessingAuditTable,
} from "../../../../actions/search";
import { resetPage, setNewTable } from "../../../../actions/store";
import AbstractComponent from "../../../../factories/Main";
import { cutGuid } from "../../../../helpers/cutGuid";
import { getAuditTransactionProcessingTables } from "../../../../services/paymentBackendAPI/audits/audit";
import { Copy } from "../../../UI/CopyToClipboard";

const columns = [
  {
    path: "date_time",
    label: "Datetime",
    key: "date_time",
    content: (transaction) =>
      moment(transaction.date_time)
        .utcOffset(0)
        .format("DD.MM.YYYY HH:mm:ss:SSS"),
  },
  {
    path: "guid",
    label: "Guid",
    key: "guid_audit",
    content: (transaction) => {
      if (transaction.guid) {
        return (
          <>
            <Copy text={transaction.guid} />
            {cutGuid(transaction.guid)}
          </>
        );
      } else return null;
    },
    style: { minWidth: "110px" },
  },
  {
    path: "transaction_guid",
    label: "Transaction guid",
    key: "transaction_guid_audit",
    content: (transaction) => {
      if (transaction.transaction_guid) {
        return (
          <>
            <Copy text={transaction.transaction_guid} />
            {cutGuid(transaction.transaction_guid)}
          </>
        );
      } else return null;
    },
  },
  { path: "transaction_type", label: "Type", key: "transactionType" },
  {
    key: "active",
    content: (transaction) => (
      <i
        className={
          transaction.active
            ? "icon-success green icon"
            : "icon-failed red icon"
        }
      />
    ),
    label: "Active",
    align: "center",
  },
  {
    path: "parent_guid",
    label: "Parent guid",
    key: "parent_guid",
    content: (transaction) => {
      if (transaction.parent_guid) {
        return (
          <>
            <Copy text={transaction.parent_guid} />
            {cutGuid(transaction.parent_guid)}
          </>
        );
      } else return null;
    },
    style: { minWidth: "110px" },
  },
  {
    path: "payment_status",
    label: "Payment Status",
    key: "status",
    content: (order) => (
      <Badge className={`badge-order-${order.payment_status}`}>
        {order.payment_status}
      </Badge>
    ),
    align: "center",
  },
  { path: "shop_name", label: "Shop", key: "shop_name" },
  {
    path: "terminal_guid",
    label: "Terminal guid",
    key: "terminal_guid_audit",
    content: (transaction) => {
      if (transaction.terminal_guid) {
        return (
          <>
            <Copy text={transaction.terminal_guid} />
            {cutGuid(transaction.terminal_guid)}
          </>
        );
      } else return null;
    },
    style: { minWidth: "110px" },
  },
  { path: "terminal_name", label: "Terminal name", key: "terminal_name_audit" },
  {
    key: "test",
    content: (transaction) => (
      <i
        className={
          transaction.test ? "icon-success green icon" : "icon-failed red icon"
        }
      />
    ),
    label: "Test",
    align: "center",
  },
  {
    path: "status",
    label: "Status",
    key: "status",
    align: "center",
    content: (order) =>
      order.status === "Success" ? (
        <span style={{ color: "green" }}>Success</span>
      ) : order.status === "Failed" ? (
        <span style={{ color: "red" }}>Failed</span>
      ) : (
        <span style={{ color: "orange" }}>{order.status}</span>
      ),
  },
  {
    path: "time",
    label: "Time",
    key: "time",
    content: (transaction) =>
      moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    align: "center",
  },
  { path: "reason", label: "Reason", key: "reason" },
  { path: "action", label: "Action", key: "action" },
  { path: "author_username", label: "Author", key: "author_username" },
];

const mapStateToProps = (state) => {
  return {
    data: state.audit.transactionProcessingTables,
    count: state.audit.transactionProcessingTablesCount,
    table: state.store.table,
    loading: state.audit.loading,
    searchData: state.search.transactionProcessingAuditSearch,
    isSearch: state.search.isSearch,
    name: "audit_transaction_processing",
    keyPath: "guid",
    columns,
    exportFunction: getAuditTransactionProcessingTables,
  };
};

export default connect(mapStateToProps, {
  get: getAuditTransactionProcessingTablesAction,
  search: searchInTransactionProcessingAuditTable,
  resetPage,
  setNewTable,
  reset,
})(AbstractComponent);
