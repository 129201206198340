import React from "react";
import Content from "../Content";
import TransactionFormMain from "components/Transaction/Form/Main";

const TransactionForm = (props) => {
  return (
    <Content>
      <TransactionFormMain {...props} />
    </Content>
  );
};

export default TransactionForm;
