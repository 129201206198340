import { Button } from "react-bootstrap";
import React, { useState } from "react";
import ReactLoading from "react-loading";

function Export({ guid, name, exportStatement }) {

  let [ loading, setLoading ] = useState(false);

  const exportPDF = async () => {
    setLoading(true);
    let res = await exportStatement({ guid, type: "pdf" });
    let a = document.createElement("a");
    a.href = res.data;
    a.download = name + "_" + guid + ".pdf";
    a.click();
    setLoading(false);
  };

  const exportCSV = async () => {
    setLoading(true);
    let res = await exportStatement({ guid, type: "csv" });
    let a = document.createElement("a");
    const file = new Blob([ res.data ], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = name + "_" + guid + ".csv";
    a.click();
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <div>
          <ReactLoading
            type="cylon"
            color="grey"
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={{ marginTop: "10px" }}>
          <Button onClick={exportPDF}>Export to pdf</Button>
          <Button
            onClick={exportCSV}
            style={{ marginLeft: "10px" }}
          >
            Export to csv
          </Button>
        </div>
      )}
    </div>
  );
}

export default Export;