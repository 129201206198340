import React from "react";
import Content from "../../Content";
import UsersAuditMain from "components/Audit/UsersTables/Main";

const UsersAudit = () => {
  return (
    <Content>
      <UsersAuditMain />
    </Content>
  );
};

export default UsersAudit;
