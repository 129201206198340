import { backendManagement } from "../backendPlatform";

export const getRoles = (args) => {
  return backendManagement.get("/roles", { params: { ...args } });
};

export const getRolesWithLogin = (page, items, login_guid) => {
  return backendManagement.get("/roles", { params: { login_guid, items, page } });
};

export const createRole = data => {
  return backendManagement.post("/roles", data);
};

// with guid
export const updateRole = data => {
  return backendManagement.post("/roles", data);
};

export const getRole = guid => {
  return backendManagement.get(`/roles/${guid}`);
};
// data = {guid, delete}
export const deleteRole = ({ guid, reason }) => {
  return backendManagement.post("/roles", { guid, delete: true, reason });
};

export const getPrivilegesFromRole = (guid, page, items) => {
  return backendManagement.get(`/roles/${guid}/privileges`, { params: { items, page } });
};

export const addPrivilegeToRole = (guid, data) => {
  return backendManagement.post(`/roles/${guid}/privileges`, data);
};

export const deletePrivilegeFromRole = (guid, data) => {
  return backendManagement.post(`/roles/${guid}/privileges`, data);
};

export const upsertPrivilegeToRole = (guid, data) => {
  return backendManagement.post(`/roles/${guid}/privileges`, data);
};
