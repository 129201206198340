import React from "react";
import Content from "../../Content";
import TransactionAuditMain from "components/Audit/TransactionTable/Main";

const TransactionAudit = () => {
  return (
    <Content>
      <TransactionAuditMain />
    </Content>
  );
};

export default TransactionAudit;
