import React, { Component } from "react";
import PropTypes from "prop-types";
import { Creatable } from "react-select";

export default class CreatableMulti extends Component {
  render() {
    return (
      <Creatable
        isMulti
        onChange={this.props.handleChange}
        options={this.props.options}
        value={this.props.value}
        className={this.props.name}
        classNamePrefix="select"
      />
    );
  }
}

CreatableMulti.propTypes = {
  handleChange: PropTypes.array,
  options: PropTypes.array,
  value: PropTypes.array,
};
