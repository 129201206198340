import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getRateTemplateAction,
  getAllRateTemplateFeesAction,
  addRateTemplateFeesAction,
} from "actions/rates";
import PropTypes from "prop-types";
import Spinner from "components/UI/Spinner";
import Content from "views/Content";
import Table from "components/UI/Table";
import Modal from "components/UI/Modal";
import FeesCreator from "./Fees/Creator";
import swal from "sweetalert";
import ability from "config/ability";
import { parseResponse } from "helpers/parseResponse";
import { searchInRates } from "../../../actions/search";
import { Card } from "react-bootstrap";

class RateTemplateDetail extends Component {
  state = {
    currentPage: 1,
    pageSize: 10,

    searchInput: false,

    sortKeys: [],
    sortReversKeys: [],
  };

  columns = [
    {
      path: "transaction_type",
      label: "Transaction type",
      key: "transaction_type",
    },
    {
      path: "transaction_status",
      label: "Transaction status",
      key: "transaction_status",
    },
    { path: "card_schema", label: "Card schema", key: "card_schema" },
    { path: "card_region", label: "Card region", key: "card_region" },
    { path: "card_type", label: "Card type", key: "card_type" },
    {
      key: "plain",
      path: "plain",
      content: (item) => (item.plain ? "fixed" : "%"),
      textContent: (item) => (item.plain ? "fixed" : "%"),
      label: "Plain",
      align: "center",
      colWidth: 4,
    },
    ability.can("DELETE", "RATES") && {
      key: "delete",
      content: (fee) => (
        <i
          className="far fa-trash-alt icon red"
          style={{ cursor: "pointer" }}
          onClick={() =>
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this. Type reason below.",
              content: "input",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                this.handleDelete(fee.guid, value);
                swal("Deleted", {
                  icon: "success",
                  button: false,
                  timer: 2000,
                });
              }
            })
          }
        />
      ),
      label: "Delete",
      align: "center",
    },
  ];

  async componentDidMount() {
    await this.props.getRateTemplateAction(this.props.match.params.id);
    await this.props.getAllRateTemplateFeesAction(this.props.match.params.id);
  }

  updateData = (value) => {
    this.setState({ searchInput: value });
  };

  updateCurrentPage = () => {
    this.setState({ currentPage: 1 });
  };

  onSort = async (sortKey) => {
    let { sortKeys, sortReversKeys } = this.state;
    let index = sortKeys.indexOf(sortKey);
    if (index !== -1) {
      sortReversKeys[index] = !sortReversKeys[index];
    } else {
      sortKeys.push(sortKey);
      sortReversKeys.push(false);
    }
    this.setState({
      sortKeys,
      sortReversKeys,
    });

    const sortData = {
      sort_col: sortKeys,
      sort_dir: sortReversKeys,
    };
    await this.props.getAllRateTemplateFeesAction(this.props.match.params.id, {
      ...this.props.searchData,
      ...sortData,
    });
  };

  updateFees = async () => {
    await this.props.getAllRateTemplateFeesAction(this.props.match.params.id);
  };

  handleDelete = async (guid, reason) => {
    try {
      await this.props.addRateTemplateFeesAction(this.props.match.params.id, {
        guid: guid,
        delete: true,
        reason,
      });
      await this.props.getAllRateTemplateFeesAction(this.props.match.params.id);
    } catch (error) {
      const parsedError = parseResponse(error);
      swal({
        title: parsedError.error,
        text: parsedError.message,
        icon: "error",
      });
    }
  };

  render() {
    const template = this.props.template;
    const templateFees = this.props.templateFees;
    let { searchData } = this.props;

    if (this.props.rateTemplateLoading)
      return (
        <Content>
          <Spinner />
        </Content>
      );
    else
      return (
        <Content style={{ overflow: "auto" }}>
          <Card>
            <Card.Header as="h5">
              Name: {template ? template.name : null}
            </Card.Header>
            <Card.Body>
              <Card.Title>Fees</Card.Title>
              {this.props.rateTemplateFeesLoading ? (
                <Content>
                  <Spinner />
                </Content>
              ) : (
                <React.Fragment>
                  <Modal
                    header="Create fee"
                    button={
                      <button
                        className="btn btn-fill"
                        style={{ marginBottom: "15px" }}
                      >
                        Create
                      </button>
                    }
                    content={FeesCreator}
                    contentProps={{
                      guid: this.props.match.params.id,
                      updateFees: this.updateFees,
                    }}
                  />
                  <Table
                    columns={this.columns}
                    data={templateFees ? templateFees : []}
                    keyPath="guid"
                    onSort={this.onSort}
                    sortKey={this.state.sortKey}
                    isSortReverse={this.state.isSortReverse}
                    searchData={searchData}
                    search={this.props.search}
                    updateData={this.updateData}
                    updateCurrentPage={this.updateCurrentPage}
                    get={(data) =>
                      this.props.getAllRateTemplateFeesAction(
                        this.props.match.params.id,
                        data
                      )
                    }
                  />
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
        </Content>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    template: state.rates.template,
    templateFees: state.rates.rateTemplateFeesList,
    rateTemplateFeesLoading: state.rates.rateTemplateFeesLoading,
    feesCount: state.rates.count,
    rateTemplateLoading: state.rates.rateTemplateLoading,
    searchData: state.search.ratesSearch,
  };
};

export default connect(mapStateToProps, {
  getRateTemplateAction,
  getAllRateTemplateFeesAction,
  addRateTemplateFeesAction,
  search: searchInRates,
})(RateTemplateDetail);

RateTemplateDetail.propTypes = {
  addRateTemplateFeesAction: PropTypes.func,
  getAllRateTemplateFeesAction: PropTypes.func,
  getRateTemplateAction: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  rateTemplateFeesLoading: PropTypes.bool,
  rateTemplateLoading: PropTypes.bool,
  search: PropTypes.func,
  searchData: PropTypes.object,

  template: PropTypes.object,
  templateFees: PropTypes.array,
};
