import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { showModal } from "../../actions/modal";
import PropTypes from "prop-types";
import Pagination from "components/UI/Pagination";
import Table from "components/UI/Table";
import Spinner from "components/UI/Spinner/index";
import { getCurrencyChangeHistoryAction } from "../../actions/reasons";
import Content from "views/Content";
import moment from "moment";

class CurrencyHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      history: [],
    };
  }

  columnsHistory = [
    { path: "guid", label: "Guid", key: "guid_audit" },
    { path: "name", label: "Name", key: "name" },
    { path: "code", label: "Code", key: "code" },
    { path: "number", label: "Number", key: "number" },
    { path: "rate_to_eur", label: "Rate (EUR)", key: "rate_to_eur" },
    {
      path: "exchange_markup_percent",
      label: "Exchange markup percent",
      key: "exchange_markup_percent",
    },
    {
      path: "time",
      label: "Time",
      key: "time",
      content: (transaction) =>
        moment(transaction.time).utcOffset(0).format("DD.MM.YYYY HH:mm:ss:SSS"),
    },
    { path: "reason", label: "Reason", key: "reason" },
    { path: "action", label: "Action", key: "action" },
    { path: "author_username", label: "Author", key: "author_username" },
  ];
  async componentDidMount() {
    await this.props.getCurrencyChangeHistoryAction(
      this.props.guid,
      1,
      this.state.pageSize
    );
    if (this.props.error) {
      this.setState({
        history: [],
      });
    } else
      this.setState({
        history: this.props.currencyHistory,
      });
  }

  componentWillUnmount() {
    this.setState({
      history: [],
    });
  }

  handleLoginsPageChange = async (page) => {
    await this.props.getCurrencyChangeHistoryAction(
      this.props.guid,
      page,
      this.state.pageSize
    );
    this.setState({
      currentPage: page,
      history: this.props.currencyHistory,
    });
  };

  getPagedData = () => {
    const { count } = this.props;
    let { pageSize } = this.state;
    const pagesCount = count / pageSize + (1 && !!(count % pageSize));
    return { pagesCount };
  };

  render() {
    const { currentPage } = this.state;
    const { history } = this.state;
    const { pagesCount } = this.getPagedData();
    if (this.props.currencyHistoryloading) {
      return <Spinner />;
    } else
      return (
        <div className="card">
          <div className="header">History</div>
          <div className="content">
            {this.props.currencyHistoryloading ? (
              <Content>
                <Spinner />
              </Content>
            ) : (
              <React.Fragment>
                {history.length !== 0 ? (
                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      <Table
                        columns={this.columnsHistory}
                        data={history ? history : []}
                        disableSearch
                        disableSort
                      />
                      <Pagination
                        pagesCount={pagesCount}
                        currentPage={currentPage}
                        onPageChange={this.handleLoginsPageChange}
                        pageSize={this.state.pageSize}
                        count={this.props.count}
                      />
                    </Col>
                  </Row>
                ) : (
                  <div>
                    <b>Sorry, this currency doesn`t have history</b>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currencyHistory: state.reasons.currencyHistory,
    currencyHistoryloading: state.reasons.loading,
    count: state.reasons.count,
    error: state.reasons.error,
  };
};

export default connect(mapStateToProps, {
  getCurrencyChangeHistoryAction,
  showModal,
})(CurrencyHistory);

CurrencyHistory.propTypes = {
  count: PropTypes.number,
  currencyHistory: PropTypes.array,
  currencyHistoryloading: PropTypes.bool,
  error: PropTypes.bool,
  getCurrencyChangeHistoryAction: PropTypes.func,
  guid: PropTypes.string,
};
