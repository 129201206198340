/* eslint-disable no-alert */
/* eslint-disable no-console */
import { getPrivilegesByLogin } from "./management/logins";
import axios from "axios";
import config from "../../config/";
import publicKey from "../../config/publicKey";
import jwt from "jsonwebtoken";
import { parseResponse } from "../../helpers/parseResponse";


const managementApiUrl = `${config.node.host}/api/v1/management`;
const reportApiUrl = `${config.node.host}/api/v1/reports`;
const logsApiUrl = `${config.node.host}/api/v1/logs`;
const auditsApiUrl = `${config.node.host}/api/v1/audits`;
const authApiUrl = `${config.node.host}/api/v1/auth`;
const transactionsApiUrl = `${config.node.host}/api/v1/transactions`;
const commonApiUrl = `${config.node.host}/api/v1`;

//non-uses tokens
export const backendAuth = axios.create({ baseURL: authApiUrl });
export const backendTransactions = axios.create({ baseURL: transactionsApiUrl });
//requires token
export const backendReports = axios.create({ baseURL: reportApiUrl });
export const backendLogs = axios.create({ baseURL: logsApiUrl });
export const backendAudits = axios.create({ baseURL: auditsApiUrl });
export const backendManagement = axios.create({ baseURL: managementApiUrl });
export const backendCommon = axios.create({ baseURL: commonApiUrl });


export const flushTokens = (error) => {
  backendManagement.defaults.headers.common["Authorization"] = undefined;
  backendReports.defaults.headers.common["Authorization"] = undefined;
  backendLogs.defaults.headers.common["Authorization"] = undefined;
  backendAudits.defaults.headers.common["Authorization"] = undefined;
  backendCommon.defaults.headers.common["Authorization"] = undefined;
  // localStorage.removeItem("auth");
  // localStorage.removeItem("isLoggedIn");
  // localStorage.removeItem("permissions");
  localStorage.clear();
  localStorage.setItem("error", error || "");
  window.location.reload();
};

export const setPermissionsAndAdminFlag = async (accessToken) => {
  const token = jwt.decode(accessToken, publicKey, { algorithm: "RS256" });
  const { data: { isAdmin, privileges } } = await getPrivilegesByLogin(token.userPayload.loginGuid);

  // let arr = [];
  // for (let i = 0; i < token.userPayload.roles.length; i += 1){
  //   if (token.userPayload.roles[i] !== null) {
  //     arr = await addPermissionsInArray(arr, token.userPayload.roles[i]);
  //     console.log(arr);
  //   }
  // }
  // if (token.userPayload.merchant && token.userPayload.merchant.role_guid)
  //   arr = await addPermissionsInArray(arr, token.userPayload.merchant.role_guid);
  // if (token.userPayload.group && token.userPayload.group.role_guid)
  //   arr = await addPermissionsInArray(arr, token.userPayload.group.role_guid);
  // if (token.userPayload.partner && token.userPayload.partner.role_guid)
  //   arr = await addPermissionsInArray(arr, token.userPayload.partner.role_guid);
  localStorage.setItem("permissions", JSON.stringify(privileges));
  localStorage.setItem("isAdmin", isAdmin);
};

export const setTokens = async (accessToken, refreshToken, credentials_expired, first_time_login, username) => {
  try {
    jwt.verify(accessToken, publicKey, { algorithm: "RS256" });
    jwt.verify(refreshToken, publicKey, { algorithm: "RS256" });
    const auth = { accessToken, refreshToken };
    backendManagement.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    backendReports.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    backendLogs.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    backendAudits.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    backendCommon.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    if (credentials_expired)
      localStorage.setItem("expiredPassword", true);
    else
      localStorage.setItem("expiredPassword", false);

    if (first_time_login)
      localStorage.setItem("firstTimeLogin", true);
    else
      localStorage.setItem("firstTimeLogin", false);
    // setUsername(accessToken);

    if (username) {
      localStorage.setItem("username", username);
    }

    localStorage.setItem("auth", JSON.stringify(auth));
    localStorage.setItem("isLoggedIn", true);

    // await isAdmin(accessToken);
    await setPermissionsAndAdminFlag(accessToken);
  } catch (error) {
    if (error.response.status !== 423) {
      alert(error);
    }
  }
};

export const getTokens = () => JSON.parse(localStorage.getItem("auth"));

export const getAuthData = () => {
  try {
    if (!localStorage.getItem("isLoggedIn")) return;
    const { accessToken } = getTokens();
    return jwt.decode(accessToken, publicKey, { algorithm: "RS256" });
  } catch (error) {
    console.log("Catch error in get auth data ");
    console.log(error);

    flushTokens("Something went wrong");
  }
};

// const addPermissionsInArray = async (arr, roleGuid) => {
//   const permissions = await getPrivilegesByLogin(roleGuid);
//   permissions.data.data.forEach(permission => {
//     arr = [ ...arr, { name: permission.name } ];
//   });
//   return arr;
// };

// const setUsername = async (accessToken) => {
//   const decoded = jwt.decode(accessToken);
//   const { data } = await backendManagement.get(`/logins/${decoded.userPayload.loginGuid}`);
//   if(data && data.username)
//     localStorage.setItem("username", data.username);
// };

// const isAdmin = async (accessToken) => {

//   const token = jwt.decode(accessToken, publicKey, { algorithm: "RS256" });
//   if(token.userPayload) {
//     let name= "";
//     for (let index = 0; index < token.userPayload.roles.length; index++) {
//       name = await getRole(token.userPayload.roles[index]);
//       name.data.type === "admin" && localStorage.setItem("isAdmin", true);
//     }
//   }
// };

export const getPermissions = () => {
  return JSON.parse(localStorage.getItem("permissions"));
};

export const getRoles = () => {
  const token = getAuthData();
  let data = [ { role: null } ];
  if (token) {
    if (localStorage.getItem("isAdmin")) {
      data = [ { role: "admin" } ];
    }
    if (token.userPayload && token.userPayload.partner) {
      data = [ { role: "partner", guid: token.userPayload.partner.partner_guid } ];
    }
    else if (token.userPayload && token.userPayload.group) {
      data = [ { role: "group", guid: token.userPayload.group.group_guid } ];
    }
    else if (token.userPayload && token.userPayload.merchant) {
      data = [ { role: "merchant", guid: token.userPayload.merchant.merchant_guid } ];
    }
  }
  return data;

};

export const issueToken = () => {
  const refreshToken = getTokens().refreshToken;
  return backendAuth.post("/token", { refreshToken });
};



const initAccessToken = () => {
  const tokens = getTokens();
  if (tokens && tokens.accessToken) {
    backendManagement.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
    backendReports.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
    backendLogs.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
    backendAudits.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
    backendCommon.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
  }
};

let isAlreadyFetchingAccessToken = false;
export const updateTokens = async () => {
  if (localStorage.getItem("isLoggedIn")) {
    const token = getAuthData();
    const exp = new Date((token.exp - Math.round(+new Date() / 1000)) * 1000);
    if (exp.getMinutes() < 5)
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        try {
          const response = await issueToken();
          isAlreadyFetchingAccessToken = false;
          setTokens(response.data.accessToken, response.data.refreshToken);
        } catch (err) {
          console.log("Catch error in update tokens");
          console.log(err);
          flushTokens("Token expired");
        }
      }
  }
};


const successResponseInterceptor = async originResponse => {
  if (localStorage.getItem("isLoggedIn")) {
    const token = getAuthData();
    const exp = new Date((token.exp - Math.round(+new Date() / 1000)) * 1000);
    if (exp.getMinutes() < 5)
      //delete async await from Promise
      return new Promise(resolve => {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          try {
            const response = issueToken();
            isAlreadyFetchingAccessToken = false;
            setTokens(response.data.accessToken, response.data.refreshToken);
            return resolve(originResponse);
          } catch (err) {
            console.log("Catch error in successResponseInterceptor");
            console.log(err);
            flushTokens("Token expired");
          }
        } else return resolve(originResponse);
      });
  }
  return originResponse;
};

// let isAlreadyFetchingAccessToken = false;
const tokenRefreshInterceptor = error => {
  // if (!error.response && localStorage.getItem("isLoggedIn")){
  //   flushTokens();
  // }

  if (error.response) {
    // if (error.config.__isRetryRequest)
    // {
    //   console.log("Catch error in tokenRefreshInterceptor");
    //   console.log(error.config.__isRetryRequest);
    //   flushTokens();
    // }

    if (localStorage.getItem("isLoggedIn") && error.response.status === 401) {
      console.log("Catch error in tokenRefreshInterceptor");
      console.log(error.response.status);
      flushTokens("Unauthorized");
    }
    /*
    else if (localStorage.getItem("isLoggedIn") && error.response.status === 401) {
      return new Promise(async resolve => {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          try {
            const { config } = error;
            const response = await issueToken();
            isAlreadyFetchingAccessToken = false;
            setTokens(response.data.accessToken, response.data.refreshToken);
            config.headers.Authorization = "Bearer " + response.data.accessToken;
            config.baseURL = undefined;
            config.__isRetryRequest = true;
            return resolve(axios.request(error.config));
          } catch (err) {
            flushTokens();
          }
        } else {
          error.config.baseURL = undefined;
          return await setTimeout(() => {
            error.config.headers.Authorization = `Bearer ${getTokens().accessToken}`;
            return resolve(axios.request(error.config));
          }, 2500);
        }
      });
    } */
    else if (localStorage.getItem("isLoggedIn") && error.response.status === 403) {
      console.log("Catch error in tokenRefreshInterceptor");
      console.log(error.response.status);
      flushTokens(parseResponse(error).message);
    }
  }
  return Promise.reject(error);
};
backendAuth.interceptors.response.use(successResponseInterceptor, tokenRefreshInterceptor);
backendManagement.interceptors.response.use(successResponseInterceptor, tokenRefreshInterceptor);
backendReports.interceptors.response.use(successResponseInterceptor, tokenRefreshInterceptor);
backendLogs.interceptors.response.use(successResponseInterceptor, tokenRefreshInterceptor);
backendAudits.interceptors.response.use(successResponseInterceptor, tokenRefreshInterceptor);
backendCommon.interceptors.response.use(successResponseInterceptor, tokenRefreshInterceptor);

initAccessToken();
