import React from "react";
import Content from "../Content";
import TransactionHistoryMain from "components/Transaction/History/Main";

const TransactionHistory = () => {
  return (
    <Content>
      <TransactionHistoryMain />
    </Content>
  );
};

export default TransactionHistory;
