import React from "react";
import { connect } from "react-redux";
import PieChart from "../UI/PieChart";
import Spinner from "components/UI/Spinner";
import PropTypes from "prop-types";
import Can from "components/Can";
import { Badge } from "react-bootstrap";
import { getTransactionTypesAction } from "../../actions/transactions";

class PieChartTransactionsStatusesCount extends React.Component {
  state = {
    loading: true,
    width: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.loading !== nextProps.loading) {
      return {
        loading: nextProps.loading,
      };
    }
    return null;
  }

  async componentDidMount() {
    await this.props.getTransactionTypesAction({ days: this.state.daysCount });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    if (this.state.loading) return <Spinner />;
    else {
      let success = 0;
      let failed = 0;
      let pending = 0;
      Object.keys(this.props.types).forEach((type) => {
        this.props.types[type].forEach((typeItem) => {
          success += parseInt(typeItem.success, 10);
          failed += parseInt(typeItem.failed, 10);
          pending += parseInt(typeItem.pending, 10);
        });
      });

      const datasetsPie =
        success + failed + pending > 0
          ? [
              {
                data: [ success, failed, pending ],
                backgroundColor: [
                  "rgba(75,192,192,1)",
                  "rgba(255,99,132,1)",
                  "rgba(255, 159, 64, 1)",
                ],
              },
            ]
          : [
              {
                data: [ 1 ],
                backgroundColor: "#CCC",
              },
            ];

      const labelsPie = datasetsPie
        ? datasetsPie[0].data.length === 1
          ? [ "There are no transactions yet" ]
          : [ "Success", "Failed", "Pending" ]
        : null;

      return (
        <div className="content">
          <Can do="REPORT" on="TRANSACTIONTYPES">
            <PieChart
              labels={labelsPie}
              datasets={datasetsPie}
              name="Transactions statuses amount"
            />
          </Can>
          <Can not do="REPORT" on="TRANSACTIONTYPES">
            {this.state.width > 1200 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(30%)",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : this.state.width > 767 ? (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(40%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                No privileges
              </Badge>
            ) : (
              <Badge
                pill
                style={{
                  position: "absolute",
                  left: "calc(45%)",
                  marginTop: "30px",
                  top: "40%",
                  fontSize: "1.2vw",
                }}
              >
                    No privileges
              </Badge>
            )}
          </Can>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.transactions.types,
    loading: state.transactions.transactionTypesLoading,
  };
};

export default connect(mapStateToProps, {
  getTransactionTypesAction,
})(PieChartTransactionsStatusesCount);

PieChartTransactionsStatusesCount.propTypes = {
  getTransactionTypesAction: PropTypes.func,
  loading: PropTypes.bool,
  types: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
};
