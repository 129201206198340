import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import {
  addRateTemplateAction,
  getRateTemplateAction,
} from "../../../actions/rates";
import { showModal } from "actions/modal";
import PropTypes from "prop-types";
import { parseResponse } from "helpers/parseResponse.js";
import Spinner from "components/UI/Spinner/index";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import Joi from "joi-browser";

class RateTemplateEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",

      oldData: {},
      currencies: [],
      errors: {},
    };
  }

  schema = {
    name: Joi.string().required().label("Name"),
  };
  async componentDidMount() {
    this.setState({ loading: true });

    await this.props.getRateTemplateAction(this.props.guid);

    this.setState({
      name: this.props.template.name,
      oldData: {
        name: this.props.template.name,
      },
      loading: false,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let data = {};
    let schema = {};
    if (this.state.needReason) {
      data = { name: this.state.name, reason: this.state.reason };
      schema = this.schema;
      schema.reason = Joi.string().required().label("Reason");
    } else {
      data = { name: this.state.name };
      schema = this.schema;
      if (schema.reason) {
        delete schema.reason;
      }
    }
    let { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    if (name === "reason")
      schema = {
        reason: Joi.string().required().label("Reason"),
      };
    else schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  showReason = () => {
    const oldData = { ...this.state.oldData };
    const data = {
      name: this.state.name,
    };
    let changedData = false;

    for (let prop in oldData) {
      changedData = changedData || oldData[prop] !== data[prop];
    }
    this.setState({ needReason: changedData });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState(
      {
        [input.name]: input.value,
        errors,
        [input.name + "Validation"]: errorMessage ? false : true,
      },
      () => this.showReason()
    );
  };

  handleChangeReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({
      reason: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        if (this.state.needReason) {
          this.setState({ isLoading: true });
          const data = {
            guid: this.props.guid,
            name: this.state.name,
            reason: this.state.needReason ? this.state.reason : undefined,
          };
          await this.props.addRateTemplateAction(data);
          swal({
            title: "Template is updated",
            icon: "success",
            button: false,
            timer: 2000,
          });
          await this.props.handleClose();
        } else {
          await this.props.handleClose();
        }
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        swal({
          title: parsedError.error,
          text: parsedError.message,
          icon: "error",
        });
      }
    }
  };

  render() {
    const { errors } = this.state;
    if (this.props.loading) return <Spinner />;
    else
      return (
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Row>
            <Col md={3} sm={4} xs={4} className="form-label">
              <Form.Label>Name:</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Group validationState={this.formValidation("name")}>
                <Form.Control
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={(e) => this.handleChange(e)}
                />
                {errors.name && (
                  <span className="validate-error">{errors.name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          {this.state.needReason && (
            <Row>
              <Col md={3} sm={4} xs={4} className="form-label">
                <Form.Label>Reason</Form.Label>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    placeholder="Enter reason"
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChangeReason}
                  />
                  {errors.reason && (
                    <span className="validate-error">{errors.reason}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}

          <div>
            {this.state.isLoading ? (
              <ReactLoading type="cylon" color="grey" />
            ) : (
              <Button
                className={
                  this.state.partner_nameValidation &&
                  this.state.partner_typeValidation &&
                  (!this.state.needReason || this.state.reasonValidation)
                    ? "btn btn-fill btn-success"
                    : "btn btn-fill"
                }
                type="submit"
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currencies.currenciesList,
    template: state.rates.template,
    loading: state.rates.rateTemplateLoading,
  };
};

export default connect(mapStateToProps, {
  addRateTemplateAction,
  getRateTemplateAction,
  showModal,
})(RateTemplateEditor);

RateTemplateEditor.propTypes = {
  addRateTemplateAction: PropTypes.func,
  currencies: PropTypes.array,
  getRateTemplateAction: PropTypes.func,
  guid: PropTypes.string,
  loading: PropTypes.bool,
  showModal: PropTypes.func,
  template: PropTypes.object,
};
