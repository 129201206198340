import React, { Component } from "react";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { parseResponse } from "helpers/parseResponse";
import { showModal } from "../../../actions/modal";
import { ActivatedTransactiontAction } from "../../../actions/transactions";
import Alert from "../../UI/Alert";

class TransactionEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      errors: {},
      active: true,
    };
  }

  schema = {
    reason: Joi.string().required().label("Reason"),
  };

  componentDidMount() {
    this.setState({
      active: this.props.active,
    });
  }

  validate = () => {
    const options = { abortEarly: false };
    let { error } = Joi.validate(
      { reason: this.state.reason },
      this.schema,
      options
    );
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    let schema;
    schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  formValidation = (name) => {
    if (this.state.errors[name] || this.state[name] === "") return "error";
    else return "success";
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({
      [input.name]: input.value,
      errors,
      [input.name + "Validation"]: errorMessage ? false : true,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    else {
      try {
        this.setState({ isLoading: true });
        const data = this.state;
        let active = !data.active;
        let req = {
          guid: this.props.guid,
          reason: data.reason,
          active: active ? 1 : 0,
        };
        await this.props.ActivatedTransactiontAction(req);
        this.setState({ data });
        swal({
          title: data.active
            ? "Transaction is deactivated"
            : "Transaction is activated",
          icon: "success",
          button: false,
          timer: 2000,
        });
        await this.props.handleClose();
      } catch (error) {
        this.setState({ isLoading: false });
        const parsedError = parseResponse(error);
        Alert({ type: "error", message: parsedError.message });
      }
    }
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="card">
          <div className="content">
            <label>ID: {this.props.guid}</label>
          </div>
        </div>
        <div className="card">
          <Form autoComplete="off">
            <Row>
              <Col className="col-md-3">
                <Form.Label>Reason*:</Form.Label>
              </Col>
              <Col className="col-md-9">
                <Form.Group validationState={this.formValidation("reason")}>
                  <Form.Control
                    name="reason"
                    type="text"
                    value={this.state.reason}
                    onChange={this.handleChange}
                  />
                  {errors.reason && (
                    <span className="validate-error">{errors.reason}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <div>
              {this.state.isLoading ? (
                <ReactLoading type="cylon" color="grey" />
              ) : (
                <Button
                  className={
                    this.state.reasonValidation
                      ? "btn btn-fill btn-success"
                      : "btn btn-fill"
                  }
                  type="submit"
                  onClick={this.handleSubmit}
                  style={{ margin: "10px" }}
                >
                  {this.state.active ? "Deactivate" : "Activate"}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transactions.transaction,
    transactionLoading: state.transactions.transactionLoading,
  };
};

export default connect(mapStateToProps, {
  ActivatedTransactiontAction,
  showModal,
})(TransactionEditor);

TransactionEditor.propTypes = {
  ActivatedTransactiontAction: PropTypes.func,
  active: PropTypes.bool,
  guid: PropTypes.string,
  showModal: PropTypes.func,
};
